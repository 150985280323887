var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "clientGameList" } }, [
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "box box-primary", staticStyle: { background: "none" } },
        [
          _c(
            "div",
            { staticClass: "box-body" },
            [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    {
                      staticClass: "select-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.開發商編號,
                              expression: "searchData.開發商編號",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectUnit", required: "required" },
                          on: {
                            click: function ($event) {
                              return _vm.resetData()
                            },
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "開發商編號",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: 0 } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._l(_vm.companyList, function (item, index) {
                            return _c(
                              "option",
                              { domProps: { value: item.編號 } },
                              [_vm._v(" " + _vm._s(item.開發商名稱) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.遊戲名稱,
                          expression: "searchData.遊戲名稱",
                        },
                      ],
                      staticClass: "form-control pull-right",
                      attrs: {
                        name: "table_search",
                        placeholder: "搜尋關鍵字",
                        type: "text",
                      },
                      domProps: { value: _vm.searchData.遊戲名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "遊戲名稱",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm.searchData.遊戲名稱
                      ? _c("i", {
                          staticClass: "fa fa-close",
                          on: {
                            click: function ($event) {
                              _vm.searchData.遊戲名稱 = ""
                            },
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.resetData()
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-search" })]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _c("p", { staticClass: "data-num" }, [
                      _c("span", [_vm._v("資料筆數：")]),
                      _c("span", { attrs: { id: "total" } }, [
                        _vm._v(_vm._s(_vm.total)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sort dropdown-toggle",
                            attrs: {
                              type: "button",
                              "data-bs-toggle": "dropdown",
                              "aria-expanded": "false",
                            },
                            on: {
                              click: function ($event) {
                                _vm.dropdown =
                                  _vm.dropdown == "none" ? "block" : "none"
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "icon-sort" }),
                            _vm._v(" 排序依據 "),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            style: { display: `${_vm.dropdown}` },
                          },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: [
                                    "dropdown-item",
                                    _vm.searchData.排序條件 == 1
                                      ? "active"
                                      : "",
                                  ],
                                  attrs: { href: "javaScript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetData(1, 1)
                                    },
                                  },
                                },
                                [_vm._v(" 更新時間 ")]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: [
                                    "dropdown-item",
                                    _vm.searchData.排序條件 == 2
                                      ? "active"
                                      : "",
                                  ],
                                  attrs: { href: "javaScript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetData(2, 1)
                                    },
                                  },
                                },
                                [_vm._v(" 建立時間 ")]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: [
                                    "dropdown-item",
                                    _vm.searchData.排序條件 == 3 &&
                                    _vm.searchData.排序順序 == 2
                                      ? "active"
                                      : "",
                                  ],
                                  attrs: { href: "javaScript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetData(3, 2)
                                    },
                                  },
                                },
                                [_vm._v(" A to Z ")]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  class: [
                                    "dropdown-item",
                                    _vm.searchData.排序條件 == 3 &&
                                    _vm.searchData.排序順序 == 1
                                      ? "active"
                                      : "",
                                  ],
                                  attrs: { href: "javaScript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetData(3, 1)
                                    },
                                  },
                                },
                                [_vm._v(" Z to A ")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm.total == 0
                ? _c("Empty", {
                    attrs: {
                      title: "找不到結果",
                      desc: "請改用其他關鍵字或移除排序條件",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "g-list table-responsive mt-20" },
                [
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      { staticClass: "g-card" },
                      [_c("GameCard", { attrs: { item: item } })],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading || _vm.isFirst,
                          expression: "loading || isFirst",
                        },
                      ],
                      staticClass: "loading-box",
                    },
                    [_c("div", { staticClass: "loading" })]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }