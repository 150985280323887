var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _vm._m(0),
      _c("midify", { ref: "midify", attrs: { proplogin: false } }),
      _c(
        "div",
        {
          staticClass: "modal fade",
          staticStyle: { "z-index": "999" },
          attrs: { id: "attention-mail" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._m(2),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.midify()
                      },
                    },
                  },
                  [_vm._v("前往設定信箱")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default pull-right",
                    attrs: {
                      id: "attention-dismiss",
                      "data-dismiss": "modal",
                      type: "button",
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content home" }, [
      _c("h1", { staticStyle: { color: "#3c8dbc" }, attrs: { id: "test" } }, [
        _vm._v("⇦請點選左側列表進行操作"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-red" }, [
        _c("i", { staticClass: "fa fa-warning" }),
        _vm._v(" 注意 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("尚未設定信箱，請盡快設定")]),
      _c("p", [_vm._v("( 當忘記密碼時將會傳送新密碼至您所設定信箱 )")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }