var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RedeemPrizesList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("獎項兌換列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("獎項兌換管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("獎項兌換列表")]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "input-group mr-20" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("門號："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.玩家識別值,
                      expression: "searchData.玩家識別值",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋玩家識別值、門號",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.玩家識別值 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "玩家識別值",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group mr-20" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("真實姓名："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.真實姓名,
                      expression: "searchData.真實姓名",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋真實姓名",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.真實姓名 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "真實姓名", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("活動："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.活動編號,
                        expression: "searchData.活動編號",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "活動編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [_vm._v("全部")]),
                    _vm._l(_vm.activityList, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.活動編號 } },
                        [_vm._v(_vm._s(item.活動名稱))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("獎項："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.獎項名稱,
                        expression: "searchData.獎項名稱",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "獎項名稱",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                    _vm._l(_vm.adwardList, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.獎項名稱 } },
                        [_vm._v(_vm._s(item.獎項名稱))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("狀態："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.狀態,
                        expression: "searchData.狀態",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "狀態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [_vm._v("全部")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("未填單")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("未處理")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("已處理")]),
                    _c("option", { attrs: { value: "3" } }, [
                      _vm._v("停止兌換"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group mr-20" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("填單開始日期："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _vm._m(0),
                    _c("DatePicker", {
                      attrs: { propValue: _vm.searchData.填單開始時間 },
                      on: {
                        "update:propValue": function ($event) {
                          return _vm.$set(
                            _vm.searchData,
                            "填單開始時間",
                            $event
                          )
                        },
                        "update:prop-value": function ($event) {
                          return _vm.$set(
                            _vm.searchData,
                            "填單開始時間",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("填單結束日期："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _vm._m(1),
                    _c("DatePicker", {
                      attrs: { propValue: _vm.searchData.填單結束時間 },
                      on: {
                        "update:propValue": function ($event) {
                          return _vm.$set(
                            _vm.searchData,
                            "填單結束時間",
                            $event
                          )
                        },
                        "update:prop-value": function ($event) {
                          return _vm.$set(
                            _vm.searchData,
                            "填單結束時間",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "text mt-15" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.redeemPrizes.total)),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-group fl mr-20 snmMT w100-md mt-15-md" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning w100-md",
                      attrs: { type: "button" },
                      on: { click: _vm.clickSearch },
                    },
                    [_vm._v("查詢")]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "form-group fl mr-20 snmMT w100-md mt-15-md" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger w100-md",
                      attrs: { type: "button" },
                      on: { click: _vm.clearBtn },
                    },
                    [_vm._v("清除")]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "form-group fl snmMT w100-md mt-15-md" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success w100-md",
                      attrs: { type: "button" },
                      on: { click: _vm.exportOrders },
                    },
                    [_vm._v("匯出")]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(2),
                      _vm.redeemPrizes.total > 0
                        ? _vm._l(
                            _vm.redeemPrizes.datas,
                            function (item, index) {
                              return _c("tr", { key: item.id }, [
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.活動編號)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.玩家識別值)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.獎項名稱)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.中獎時間)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.填單時間)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.真實姓名)),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.地址))]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.連絡電話)),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(_vm.GetStatus(item.狀態))),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    _vm._s(
                                      item.handleUId + " " + item.handleUName
                                    )
                                  ),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  item.files.length > 0
                                    ? _c("input", {
                                        attrs: {
                                          type: "button",
                                          value: "檢視",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImg(item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.備註)),
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editorItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("編輯")]
                                  ),
                                ]),
                              ])
                            }
                          )
                        : [_vm._m(3)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.redeemPrizes.total > 0,
                  expression: "redeemPrizes.total > 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.redeemPrizes.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("PictureView", {
          ref: "pictureview",
          on: { refreshList: _vm.GetQueryAll },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("活動編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "3%" } }, [
        _vm._v("門號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("獎項名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("中獎時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("填單時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("真實姓名"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("地址"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("連絡電話"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "3%" } }, [
        _vm._v("處理人員"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "3%" } }, [
        _vm._v("圖片"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("備註"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "14" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }