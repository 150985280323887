var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PlayerHistoryList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("異動歷程紀錄")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("異動歷程紀錄"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.操作項目,
                      expression: "searchData.操作項目",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "操作項目",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.searchData.pageNum = 1
                        _vm.searchData.操作動作 = ""
                        _vm.searchData.動作 = ""
                        _vm.getDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.$t("所有操作"))),
                  ]),
                  _vm._l(_vm.actions, function (value, key) {
                    return _c("option", { domProps: { value: key } }, [
                      _vm._v(_vm._s(key)),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.操作動作,
                      expression: "searchData.操作動作",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "操作動作",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.searchData.pageNum = 1
                        _vm.searchData.動作 = ""
                        _vm.getDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.$t("所有子操作"))),
                  ]),
                  _vm._l(_vm.subactions, function (value, key) {
                    return _c("option", { domProps: { value: value } }, [
                      _vm._v(_vm._s(value)),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.動作,
                      expression: "searchData.動作",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "動作",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        _vm.searchData.pageNum = 1
                        _vm.getDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.$t("所有動作"))),
                  ]),
                  _c("option", { attrs: { value: "新增" } }, [
                    _vm._v(_vm._s(_vm.$t("新增"))),
                  ]),
                  _c("option", { attrs: { value: "異動" } }, [
                    _vm._v(_vm._s(_vm.$t("異動"))),
                  ]),
                  _c("option", { attrs: { value: "修改" } }, [
                    _vm._v(_vm._s(_vm.$t("修改"))),
                  ]),
                  _c("option", { attrs: { value: "刪除" } }, [
                    _vm._v(_vm._s(_vm.$t("刪除"))),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "form-group",
                staticStyle: { width: "max-content", "max-width": "100%" },
              },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("操作時間")) + "： "),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group datetime" },
                  [
                    _c("div", {
                      staticClass: "input-group-addon fa fa-calendar",
                    }),
                    _c("DateRangePicker", {
                      attrs: {
                        propStartDate: _vm.searchData.開始時間,
                        propEndDate: _vm.searchData.結束時間,
                        propTimePicker: true,
                        displayFormat: "YYYY/MM/DD HH:mm",
                        format: "YYYY-MM-DD HH:mm:ss",
                      },
                      on: {
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "btns mb-10" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.getDataList()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("查詢")))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c("table", { staticClass: "table table-bordered text-center" }, [
              _c("thead", [
                _c("tr", { staticClass: "bg-gray" }, [
                  _c(
                    "th",
                    { staticStyle: { "min-width": "92px", width: "auto" } },
                    [_vm._v(_vm._s(_vm.$t("操作時間")))]
                  ),
                  _c(
                    "th",
                    { staticStyle: { "min-width": "80px", width: "auto" } },
                    [_vm._v(_vm._s(_vm.$t("操作者")))]
                  ),
                  _c(
                    "th",
                    { staticStyle: { "min-width": "105px", width: "auto" } },
                    [_vm._v(_vm._s(_vm.$t("異動項目")))]
                  ),
                  _c(
                    "th",
                    { staticStyle: { "min-width": "50px", width: "auto" } },
                    [_vm._v(_vm._s(_vm.$t("動作")))]
                  ),
                  _c(
                    "th",
                    { staticStyle: { "min-width": "300px", width: "35%" } },
                    [_vm._v(_vm._s(_vm.$t("原內容")))]
                  ),
                  _c(
                    "th",
                    { staticStyle: { "min-width": "300px", width: "35%" } },
                    [_vm._v(_vm._s(_vm.$t("新內容")))]
                  ),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _vm.loading
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "10" } }, [
                          _vm._v(_vm._s(_vm.$t("查詢中"))),
                        ]),
                      ])
                    : _vm.datas.length <= 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "10" } }, [
                          _vm._v(_vm._s(_vm.$t("查無資料"))),
                        ]),
                      ])
                    : _vm._l(_vm.datas, function (item, index) {
                        return _c(
                          "tr",
                          { key: index + item.操作者 + item.操作時間 },
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.操作時間))),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.操作者))]),
                            _c("td", [_vm._v(_vm._s(item.操作動作))]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t(item.動作))),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "text-left",
                                staticStyle: { "vertical-align": "top" },
                              },
                              [
                                !_vm.isNullJSON(item.原內容)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "json-content",
                                        class: { open: item.openJSON },
                                      },
                                      [
                                        _c("button", {
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleJSON($event)
                                            },
                                          },
                                        }),
                                        _c("pre", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.prettyJSON(item.原內容)
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-left",
                                staticStyle: { "vertical-align": "top" },
                              },
                              [
                                item.新內容 && item.新內容.status == "0"
                                  ? _c("p", { staticClass: "mb-5" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.$t("操作失敗")) + "："
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(item.新內容.message) +
                                          " (" +
                                          _vm._s(item.新內容.stateCode) +
                                          ") "
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.isNullJSON(item.新內容)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "json-content",
                                        class: { open: item.openJSON },
                                      },
                                      [
                                        _c("button", {
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleJSON($event)
                                            },
                                          },
                                        }),
                                        item.新內容.data
                                          ? _c("pre", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.prettyJSON(
                                                    item.新內容.data
                                                  )
                                                ),
                                              },
                                            })
                                          : _c("pre", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.prettyJSON(item.新內容)
                                                ),
                                              },
                                            }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }