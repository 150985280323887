import { render, staticRenderFns } from "./MemberHonorDialog.vue?vue&type=template&id=d8497886"
import script from "./MemberHonorDialog.vue?vue&type=script&lang=js"
export * from "./MemberHonorDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\WorkSpace\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8497886')) {
      api.createRecord('d8497886', component.options)
    } else {
      api.reload('d8497886', component.options)
    }
    module.hot.accept("./MemberHonorDialog.vue?vue&type=template&id=d8497886", function () {
      api.rerender('d8497886', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/XSW/components/MemberHonorDialog.vue"
export default component.exports