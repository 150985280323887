var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲介紹列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲介紹編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c("p", { staticClass: "pull-left color-red ml-5 mt-10" }, [
              _vm._v(" *請依序編輯內容 "),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.edit.gid)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.遊戲分類,
                            expression: "edit.遊戲分類",
                          },
                        ],
                        staticClass: "form-control select-part",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "遊戲分類",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.updateTypeClassID,
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v(" 請選擇分類 ")]
                        ),
                        _vm._l(_vm.newTypeClass, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              attrs: { class_id: item.classId },
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(" " + _vm._s(item.className) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.遊戲名稱,
                          expression: "edit.遊戲名稱",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.遊戲名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "遊戲名稱", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.遊戲英文名稱,
                          expression: "edit.遊戲英文名稱",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.遊戲英文名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "遊戲英文名稱",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.遊戲簡介,
                          expression: "edit.遊戲簡介",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "summary", placeholder: "內容建議100字內" },
                      domProps: { value: _vm.edit.遊戲簡介 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "遊戲簡介", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.edit.遊戲分類 && _vm.edit.遊戲英文名稱
                  ? _c("div", [
                      _c("div", { staticClass: "form-group image-part" }, [
                        _vm._m(5),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _vm.logo1
                            ? _c(
                                "div",
                                { staticClass: "pic d-inline chooseBox" },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    attrs: { title: "刪除檔案" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(_vm.logo1.id)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.logo1.filesUrl,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.logo1
                            ? _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "logo1",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event, "logo1")
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "help-block" }, [
                            _vm._v(" 尺寸188*208px,背景透明png "),
                          ]),
                          _c("div", { staticClass: "help-block" }, [
                            _vm.isCreate
                              ? _c("span", [
                                  _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group image-part" }, [
                        _vm._m(6),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _vm.logo2
                            ? _c(
                                "div",
                                { staticClass: "pic d-inline chooseBox" },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    attrs: { title: "刪除檔案" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(_vm.logo2.id)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.logo2.filesUrl,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.logo2
                            ? _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "logo2",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event, "logo2")
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "checkbox" }, [
                            _c(
                              "label",
                              { staticClass: "check-game" },
                              [
                                _c("iCheckCheckBox", {
                                  attrs: { propChecked: _vm.edit.是否出現首頁 },
                                  on: {
                                    "update:checked": function ($event) {
                                      _vm.edit.是否出現首頁 = $event["checked"]
                                    },
                                  },
                                }),
                                _vm._v("顯示於首頁最新遊戲列表中 "),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "checkbox" }, [
                            _c(
                              "label",
                              { staticClass: "check-name" },
                              [
                                _c("iCheckCheckBox", {
                                  attrs: {
                                    propChecked: _vm.edit.是否隱藏遊戲名稱,
                                  },
                                  on: {
                                    "update:checked": function ($event) {
                                      _vm.edit.是否隱藏遊戲名稱 =
                                        $event["checked"]
                                    },
                                  },
                                }),
                                _vm._v("隱藏遊戲名稱 "),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "help-block" }, [
                            _vm._v(" 尺寸222*448px, 背景透明png "),
                          ]),
                          _c("div", { staticClass: "help-block" }, [
                            _vm.isCreate
                              ? _c("span", [
                                  _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group image-part" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label",
                            attrs: { for: "trademark" },
                          },
                          [_vm._v("商標Logo ")]
                        ),
                        _c("div", { staticClass: "col-sm-9" }, [
                          _vm.trademark
                            ? _c(
                                "div",
                                { staticClass: "pic d-inline chooseBox" },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    attrs: { title: "刪除檔案" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(_vm.trademark.id)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.trademark.filesUrl,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.trademark
                            ? _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "trademark",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event, "trademark")
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "help-block" }, [
                            _vm._v(" 尺寸123*67px,背景透明png "),
                          ]),
                          _c("div", { staticClass: "help-block" }, [
                            _vm.isCreate
                              ? _c("span", [
                                  _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" 遊戲影片ID： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.遊戲影片ID,
                          expression: "edit.遊戲影片ID",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "填入代碼即可。ex:hjXrL7CuAvc",
                      },
                      domProps: { value: _vm.edit.遊戲影片ID },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "遊戲影片ID", $event.target.value)
                        },
                      },
                    }),
                    _vm._m(7),
                  ]),
                ]),
                _vm.edit.遊戲影片ID
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(" 預覽： "),
                      ]),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _vm._v(" 影片連結： " + _vm._s(_vm.videoUrl) + " "),
                        _c("div", { staticClass: "video-container" }, [
                          _c("iframe", {
                            attrs: {
                              width: "700",
                              height: "395",
                              src: _vm.videoUrl,
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "unboxingText" },
                    },
                    [_vm._v(" 開箱文標題： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.開箱文文字,
                          expression: "edit.開箱文文字",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "unboxingText", type: "text" },
                      domProps: { value: _vm.edit.開箱文文字 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "開箱文文字", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "unboxingLink" },
                    },
                    [_vm._v(" 開箱文連結： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.開箱文連結,
                          expression: "edit.開箱文連結",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "unboxingLink", type: "text" },
                      domProps: { value: _vm.edit.開箱文連結 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "開箱文連結", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(8),
                  _vm.edit.遊戲分類 && _vm.edit.遊戲英文名稱
                    ? _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          attrs: {
                            multiple: "multiple",
                            accept: "image/*",
                            id: "infoImg",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event, "infoImg")
                            },
                          },
                        }),
                        _c("div", { staticClass: "help-block mb-10-md" }, [
                          _vm._v(" *圖片格式:寬930，背景透明png。"),
                          _c("br"),
                          _vm.infos.length > 0
                            ? _c("span", [
                                _vm._v("*拖移可改變圖片順序。 * 建議數量 "),
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("15"),
                                ]),
                                _vm._v(
                                  " 張內。 (系統自帶透明度灰底，方便觀看圖片內容) "
                                ),
                              ])
                            : _vm._e(),
                          _vm.isCreate
                            ? _c("span", [
                                _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.infos
                          ? _c(
                              "div",
                              { staticClass: "row gameGallery mt-10" },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "list-group gameGallery",
                                    attrs: {
                                      animation: "500",
                                      list: _vm.infos,
                                      disabled: !_vm.enabled,
                                      "ghost-class": "ghost",
                                    },
                                    on: {
                                      start: function ($event) {
                                        _vm.dragging = true
                                      },
                                      end: function ($event) {
                                        _vm.dragging = false
                                        _vm.getGalleryOrder()
                                      },
                                    },
                                  },
                                  _vm._l(_vm.infos, function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "col-sm-4 col-xs-6 chooseBox",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "img-close fa fa-fw fa-close",
                                          attrs: {
                                            "data-id": item.id,
                                            title: "刪除檔案",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DeletePic(item.id)
                                            },
                                          },
                                        }),
                                        _c("img", {
                                          attrs: {
                                            src: item.filesUrl,
                                            "data-id": item.id,
                                          },
                                        }),
                                        _c("p", [
                                          _vm._v(
                                            " id:" +
                                              _vm._s(item.id) +
                                              " 順序:" +
                                              _vm._s(item.info) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _c("div", { staticClass: "col-sm-10 pt-10" }, [
                        _vm._v(" (請填寫遊戲分類,遊戲名稱 ，再上傳圖片) "),
                      ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.遊戲操作說明,
                            expression: "edit.遊戲操作說明",
                          },
                        ],
                        staticClass: "form-control select-part",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "遊戲操作說明",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v(" 請選擇操作說明版本 ")]
                        ),
                        _vm._l(_vm.operationTypes, function (item) {
                          return _c(
                            "option",
                            { key: item.gid, domProps: { value: item.gid } },
                            [_vm._v(" " + _vm._s(item.version) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "help-block" },
                      [
                        _vm._v(" *進入 "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/Xin/GameOperationList",
                              target: "_blank",
                            },
                          },
                          [_vm._v("遊戲操作說明列表")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "Limits" },
                    },
                    [_vm._v("遊戲館限制： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "Limits editor" },
                      [
                        _c("tinymce", {
                          model: {
                            value: _vm.edit.遊戲館限制,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "遊戲館限制", $$v)
                            },
                            expression: "edit.遊戲館限制",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-group mb-0",
                    attrs: { id: "DisconnectionRules" },
                  },
                  [
                    _vm._m(10),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      _vm._l(_vm.edit.斷線規則, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "w100 disconContnet" },
                          [
                            _vm._m(11, true),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.標題,
                                  expression: "item.標題",
                                },
                              ],
                              staticClass: "form-control mb-10 d-c",
                              attrs: { placeholder: "因素", rows: "3" },
                              domProps: { value: item.標題 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "標題", $event.target.value)
                                },
                              },
                            }),
                            _c("div", { staticClass: "clear w100" }),
                            _vm._m(12, true),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.內容,
                                  expression: "item.內容",
                                },
                              ],
                              staticClass: "form-control mb-10 d-c",
                              attrs: { placeholder: "處理", rows: "3" },
                              domProps: { value: item.內容 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "內容", $event.target.value)
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: index !== 0,
                                    expression: "index !== 0",
                                  },
                                ],
                                staticClass: "btn btn-danger mb-10",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDiscon(index)
                                  },
                                },
                              },
                              [_vm._v(" 刪除 ")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c("div", { staticClass: "form-group mb-30" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }),
                  _c("div", { staticClass: "col-sm-10 disconContnet" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addDiscon()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" 新增規則 "),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("description： "),
                  ]),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.description,
                          expression: "edit.description",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "網頁的描述" },
                      domProps: { value: _vm.edit.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "description", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("keyword： "),
                  ]),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.keyword,
                          expression: "edit.keyword",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "網頁的關鍵字", type: "text" },
                      domProps: { value: _vm.edit.keyword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "keyword", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(13),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.排序,
                          expression: "edit.排序",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputOrderNum", type: "number" },
                      domProps: { value: _vm.edit.排序 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "排序", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(14),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "true",
                            message: "上架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.是否發佈,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "是否發佈", $$v)
                            },
                            expression: "edit.是否發佈",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "false",
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.是否發佈,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "是否發佈", $$v)
                            },
                            expression: "edit.是否發佈",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(15),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(16),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.啟用時間 },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.啟用時間 = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.啟用時間 = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(17),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(18),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(19),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.測試時間 },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.測試時間 = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.測試時間 = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(20),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(true)
                        },
                      },
                    },
                    [_vm._v(" 預覽 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [_c("span", { staticClass: "text-red" }, [_vm._v("*")]), _vm._v("Gid： ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 遊戲分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("中文名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("英文名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "summary" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("遊戲簡介： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "logo1" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("格狀Logo： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "logo2" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("長形Logo： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v(" ID位置: https://www.youtube.com/watch?v="),
      _c("span", { staticClass: "color-red" }, [_vm._v("8zf6Wi2BtsE")]),
      _c("br"),
      _vm._v(" v= 後面文字 8zf6Wi2BtsE 就是影片ID "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "infoImg" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("遊戲說明圖： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 遊戲操作說明： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("斷線規則 - "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "control-label pt-0 mb-10 mr-5" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("因素： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "control-label pt-0 mb-10 mr-5" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("處理： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 正式開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "StartTime", type: "text" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 測試開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "testStartTime", type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }