var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("序號管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("活動列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box topBox-activityManger" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增活動 ")]
            ),
            _c("div", { staticClass: "date-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("活動期間："),
              ]),
              _c("div", { staticClass: "input-group date datetime" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propTimePicker: true,
                        propEndDate: _vm.searchData.local.endTime,
                        propStartDate: _vm.searchData.local.startTime,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "endTime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "endTime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "startTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "startTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "input-group search-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋活動代碼、活動名稱",
                  type: "text",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.activity.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.activity.total > 0
                      ? _vm._l(_vm.activity.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.vendorActivityCode)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.activityCode)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.activityTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.ruleType == ""
                                      ? "無"
                                      : item.ruleType == "1帳1次"
                                      ? "1帳多次"
                                      : item.ruleType
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.isStop == "1" ? "下架" : "上架")
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.startTime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.endTime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.createUName) +
                                  " " +
                                  _vm._s(item.createUId)
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.createTime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.settingAwards(item)
                                    },
                                  },
                                },
                                [_vm._v("獎項設定")]
                              ),
                              _vm._v("   "),
                              !_vm.checkTime(item)
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        })
                      : [_vm._m(2)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activity.total > 0,
                expression: "activity.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.activity.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "3%" } }, [
        _vm._v("原廠活動代碼"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "3%" } }, [
        _vm._v("活動代碼"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("活動名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("規則"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "3%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("活動開始時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("活動結束時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("建立者"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("建立時間"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }