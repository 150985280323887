var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "activityEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(_vm._s(_vm.type == "normal" ? "常態活動" : "非常態活動")),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("遊戲管理")]),
          _c("li", [_vm._v("活動機制")]),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.type == "normal" ? "常態活動" : "非常態活動")),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.$route.params.name)),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "col-sm-2 control-label text-nowrap" },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.type == "normal" ? "時間：" : "活動名稱："
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _vm.type == "normal"
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.活動時間,
                                    expression: "data.活動時間",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  required: "required",
                                  type: "text",
                                  maxlength: "50",
                                },
                                domProps: { value: _vm.data.活動時間 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "活動時間",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.data.活動時間.length) + " / 50"
                                ),
                              ]),
                            ]
                          : [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.活動名稱,
                                    expression: "data.活動名稱",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  required: "required",
                                  type: "text",
                                  maxlength: "50",
                                },
                                domProps: { value: _vm.data.活動名稱 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      "活動名稱",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.data.活動名稱.length) + " / 50"
                                ),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm.type == "abnormal"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.活動說明,
                                expression: "data.活動說明",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              required: "required",
                              type: "text",
                              rows: "4",
                              maxlength: "200",
                            },
                            domProps: { value: _vm.data.活動說明 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.data,
                                  "活動說明",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.data.活動說明.length) + " / 200"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.type == "normal" ? "週期活動：" : "詳細說明："
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            model: {
                              value: _vm.data.內容,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "內容", $$v)
                              },
                              expression: "data.內容",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning pull-left mr-10",
                        on: {
                          click: function ($event) {
                            return _vm.preview()
                          },
                        },
                      },
                      [_vm._v(" 預覽 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-left",
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(" 儲存 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default pull-right",
                        on: {
                          click: function ($event) {
                            return _vm.backList()
                          },
                        },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { ref: "preview", staticClass: "modal fade" }, [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-striped table-bordered",
                    attrs: { id: "classTypes" },
                  },
                  [
                    _c("tbody", [
                      _c(
                        "tr",
                        { staticStyle: { "background-color": "#FEE94E" } },
                        [
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: {
                                "min-width": "100px",
                                width: "30%",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.type == "normal" ? "時間" : "活動名稱"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: {
                                "min-width": "150px",
                                width: "70%",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.type == "normal"
                                      ? "週期活動"
                                      : "活動名稱"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.data.活動名稱 || _vm.data.活動時間)
                          ),
                        ]),
                        _vm.type == "normal"
                          ? _c("td", {
                              domProps: { innerHTML: _vm._s(_vm.data.內容) },
                            })
                          : _c("td", [_vm._v(_vm._s(_vm.data.活動說明))]),
                      ]),
                    ]),
                  ]
                ),
                _vm.type == "abnormal"
                  ? _c(
                      "table",
                      {
                        staticClass: "table table-striped table-bordered",
                        attrs: { id: "classTypes" },
                      },
                      [
                        _c("tbody", [
                          _vm._m(2),
                          _c("tr", [
                            _c("td", {
                              domProps: { innerHTML: _vm._s(_vm.data.內容) },
                            }),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動簡述： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-center" }, [_vm._v("預覽")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticStyle: { "background-color": "#FEE94E" } }, [
      _c("th", { staticClass: "text-nowrap text-center" }, [
        _vm._v("詳細說明"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }