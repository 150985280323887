var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "BlackQuery" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("發票驗證維護")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("發票驗證維護")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-header" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._v("驗證功能： "),
              _vm.驗證發票API狀態 == "True" || _vm.驗證發票API狀態 == "False"
                ? [
                    _c(
                      "span",
                      {
                        class:
                          _vm.驗證發票API狀態 != "False" ? "font-disable" : "",
                      },
                      [_vm._v("停止驗證")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "ckbx-style-8 ckbx toggleInput",
                        staticStyle: {
                          "padding-left": "5px",
                          "padding-right": "5px",
                        },
                        on: {
                          click: function ($event) {
                            _vm.upd驗證發票API狀態()
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.驗證發票API狀態,
                              expression: "驗證發票API狀態",
                            },
                          ],
                          attrs: {
                            "false-value": "False",
                            "true-value": "True",
                            name: "ckbx-style-8",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.驗證發票API狀態)
                              ? _vm._i(_vm.驗證發票API狀態, null) > -1
                              : _vm._q(_vm.驗證發票API狀態, "True"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.驗證發票API狀態,
                                $$el = $event.target,
                                $$c = $$el.checked ? "True" : "False"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.驗證發票API狀態 = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.驗證發票API狀態 = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.驗證發票API狀態 = $$c
                              }
                            },
                          },
                        }),
                        _c("label", {
                          staticClass: "mystyle",
                          attrs: { for: "check" },
                        }),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        class:
                          _vm.驗證發票API狀態 != "True" ? "font-disable" : "",
                      },
                      [_vm._v("開啟驗證")]
                    ),
                  ]
                : [_vm._v(" 狀態錯誤 ")],
            ],
            2
          ),
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticStyle: { "padding-left": "100px" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.VerifyEInvoice()
                    },
                  },
                },
                [_vm._v("驗證API")]
              ),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }