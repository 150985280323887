import { render, staticRenderFns } from "./SendMessageBtn.vue?vue&type=template&id=813a6d38&scoped=true"
import script from "./SendMessageBtn.vue?vue&type=script&lang=js"
export * from "./SendMessageBtn.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/default/message.css?vue&type=style&index=0&id=813a6d38&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813a6d38",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\WorkSpace\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('813a6d38')) {
      api.createRecord('813a6d38', component.options)
    } else {
      api.reload('813a6d38', component.options)
    }
    module.hot.accept("./SendMessageBtn.vue?vue&type=template&id=813a6d38&scoped=true", function () {
      api.rerender('813a6d38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/MessageSystem/MessageSystemComponents/SendMessageBtn.vue"
export default component.exports