var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popForm",
      staticClass: "modal fade",
      attrs: { "data-backdrop": "static" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { "aria-label": "Close", type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title" }, [_vm._v("匯入中獎發票")]),
          ]),
          _c("div", { ref: "uploadForm", staticClass: "box-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(0),
              _c("input", {
                ref: "invoiceTypeA",
                attrs: { name: "file", type: "file" },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(1),
              _c("input", {
                ref: "invoiceTypeX",
                attrs: { name: "file", type: "file" },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _vm._m(2),
              _c("input", {
                ref: "invoiceTypeZ",
                attrs: { name: "file", type: "file" },
              }),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _vm._v(" 從財政部下載後解碼出來的文字檔進行匯入 "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.readExcel()
                    },
                  },
                },
                [_vm._v("確定匯入")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide()
                    },
                  },
                },
                [_vm._v("關閉")]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              ref: "processForm",
              staticClass: "box-body",
              staticStyle: { display: "none" },
            },
            [
              _c(
                "div",
                { staticClass: "form-group logDiv" },
                _vm._l(_vm.logs, function (item, i) {
                  return _c(
                    "div",
                    {
                      key: i + item,
                      staticStyle: { "white-space": "pre-line" },
                    },
                    [_c("label", [_vm._v(_vm._s(item))])]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  ref: "switchFormBtn",
                  staticClass: "modal-footer",
                  staticStyle: { display: "none" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default pull-right",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.匯入結束()
                        },
                      },
                    },
                    [_vm._v("匯入結束")]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("匯入A檔"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("匯入X檔"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("匯入Z檔"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }