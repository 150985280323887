var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive mt-10" }, [
    _c(
      "table",
      {
        staticClass: "table table-bordered table-flow",
        attrs: { id: "classTypes" },
      },
      [
        _c(
          "tbody",
          [
            _vm._m(0),
            _vm._l(_vm.data, function (list, index) {
              return [
                list.sub.length == 0
                  ? [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            class: { "td-hover": _vm.hasDetail(list.id) },
                            attrs: { colspan: "2" },
                          },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetail(list.id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(list.title) + " ")]
                            ),
                          ]
                        ),
                        _c("td"),
                      ]),
                    ]
                  : _vm._e(),
                _vm._l(list.sub, function (sub, s_index) {
                  return [
                    sub.id == 0
                      ? [
                          _c("tr", { class: { "bg-gray": index % 2 == 1 } }, [
                            s_index == 0
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    class: {
                                      "td-hover": _vm.hasDetail(list.id),
                                    },
                                    attrs: {
                                      rowspan: _vm.setRowspan(list),
                                      colspan: _vm.setColspan(list),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetail(list.id)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(list.title) + " ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.setColspan(list) == 1 ? _c("td") : _vm._e(),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "span",
                                {
                                  class: {
                                    "has-link": _vm.hasDetail(sub.extend[0].id),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetail(sub.extend[0].id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(sub.extend[0].title) + " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      : sub.extend.length == 0
                      ? [
                          _c("tr", { class: { "bg-gray": index % 2 == 1 } }, [
                            s_index == 0
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    class: {
                                      "td-hover": _vm.hasDetail(list.id),
                                    },
                                    attrs: { rowspan: _vm.setRowspan(list) },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "has-link": _vm.hasDetail(list.id),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetail(list.id)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(list.title) + " ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                class: { "td-hover": _vm.hasDetail(sub.id) },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "has-link": _vm.hasDetail(sub.id),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDetail(sub.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(sub.title) + " ")]
                                ),
                              ]
                            ),
                            _c("td"),
                          ]),
                        ]
                      : [
                          _vm._l(sub.extend, function (extend, e_index) {
                            return [
                              _c(
                                "tr",
                                { class: { "bg-gray": index % 2 == 1 } },
                                [
                                  s_index == 0 && e_index == 0
                                    ? _c(
                                        "td",
                                        {
                                          staticClass: "text-center",
                                          class: {
                                            "td-hover": _vm.hasDetail(list.id),
                                          },
                                          attrs: {
                                            rowspan: _vm.setRowspan(list),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "has-link": _vm.hasDetail(
                                                  list.id
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDetail(list.id)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(list.title) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  e_index == 0
                                    ? _c(
                                        "td",
                                        {
                                          staticClass: "text-center",
                                          class: {
                                            "td-hover": _vm.hasDetail(sub.id),
                                          },
                                          attrs: { rowspan: sub.extend.length },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "has-link": _vm.hasDetail(
                                                  sub.id
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDetail(sub.id)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(sub.title) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      class: {
                                        "td-hover": _vm.hasDetail(extend.id),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "has-link": _vm.hasDetail(
                                              extend.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDetail(extend.id)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(extend.title) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                  ]
                }),
              ]
            }),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        ref: "detail",
        staticClass: "modal fade",
        attrs: { id: "activityContent" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-xl" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(1),
              _c("h4", { staticClass: "modal-title text-center" }, [
                _vm._v(_vm._s(_vm.detail.項目名稱)),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.detail.詳細說明) },
              }),
              _c("p", { staticClass: "is-bottom" }, [_vm._v("已經到底了")]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "tr",
      {
        staticClass: "text-center",
        staticStyle: { "background-color": "#FEE94E" },
      },
      [
        _c("td", { staticStyle: { width: "50%" }, attrs: { colspan: "2" } }, [
          _vm._v("機制項目"),
        ]),
        _c("td", { staticStyle: { width: "50%" } }, [_vm._v("延伸機制或活動")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }