var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "articleEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("遊戲內容")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("分析管理 ")]),
          _c("li", [_vm._v("文章列表")]),
          _c("li", { staticClass: "active" }, [_vm._v("文章內容")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "box-body",
                    staticStyle: { "max-width": "none" },
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div"),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.addData.遊戲編號,
                                expression: "addData.遊戲編號",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.addData,
                                  "遊戲編號",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("請選擇App"),
                            ]),
                            _vm._l(_vm.gameList, function (game, index) {
                              return _c(
                                "option",
                                { domProps: { value: game.編號 } },
                                [_vm._v(" " + _vm._s(game.遊戲名稱) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.addData.文章標題,
                                  expression: "addData.文章標題",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "inputTitle",
                                required: "required",
                                type: "text",
                                maxlength: "50",
                              },
                              domProps: { value: _vm.addData.文章標題 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.addData,
                                    "文章標題",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.addData.文章標題.length) + " / 50"
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mb-20",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                staticStyle: { "max-width": "320px" },
                                attrs: { src: _vm.addData.封面 },
                              }),
                              _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "AnnouncementPic",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event)
                                  },
                                },
                              }),
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v(
                                  "上限為 5 MB 的 PNG、JPG 檔，直式或橫式圖片，最長邊不超過 320 px。"
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.addData.文章摘要,
                              expression: "addData.文章摘要",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputTitle",
                            required: "required",
                            type: "text",
                            maxlength: "100",
                          },
                          domProps: { value: _vm.addData.文章摘要 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addData,
                                "文章摘要",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.addData.文章摘要.length) + " / 100"
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(5),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("span", [
                          _vm._v(
                            "※ 在內文中，段落標題設定為 h2 ，會在前台成為段落選單的選項。"
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "editor mt-5" },
                          [
                            _c("tinymce", {
                              model: {
                                value: _vm.addData.內文,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "內文", $$v)
                                },
                                expression: "addData.內文",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "inputTitle" },
                        },
                        [_vm._v(" 發布時間： ")]
                      ),
                      _c("div", { staticClass: "col-sm-10" }, [
                        !_vm.addData.發佈時間
                          ? _c("p", { staticStyle: { "margin-top": "6px" } }, [
                              _vm._v("未發布"),
                            ])
                          : _vm._e(),
                        _c("p", { staticStyle: { "margin-top": "6px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.addData.發佈時間,
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning pull-left mr-10",
                        on: {
                          click: function ($event) {
                            return _vm.preview()
                          },
                        },
                      },
                      [_vm._v(" 預覽 ")]
                    ),
                    !_vm.addData.發佈時間
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary pull-left mr-10",
                            attrs: {
                              disabled:
                                _vm.addData.遊戲編號 == 0 ||
                                _vm.addData.文章標題 == "" ||
                                _vm.addData.封面 == "" ||
                                _vm.addData.文章摘要 == "" ||
                                _vm.addData.內文 == "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.save(0)
                              },
                            },
                          },
                          [_vm._v(" 草稿 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-left",
                        attrs: {
                          disabled:
                            _vm.addData.遊戲編號 == 0 ||
                            _vm.addData.文章標題 == "" ||
                            _vm.addData.封面 == "" ||
                            _vm.addData.文章摘要 == "" ||
                            _vm.addData.內文 == "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.save(1)
                          },
                        },
                      },
                      [_vm._v(" 發佈 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default pull-right",
                        on: {
                          click: function ($event) {
                            return _vm.backList()
                          },
                        },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            ref: "preview",
            staticClass: "modal fade",
            attrs: { id: "detailAlert" },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(6),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal",
                      attrs: { name: "announcementForm" },
                    },
                    [
                      _c("div", { staticClass: "box-body" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(7),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { "padding-top": "7px" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.setGameName(_vm.addData.遊戲編號)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(8),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { "padding-top": "7px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.addData.文章標題) + " ")]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(9),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { "padding-top": "7px" },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.addData.圖示 != "",
                                    expression: "addData.圖示 != ''",
                                  },
                                ],
                                staticStyle: {
                                  height: "60px",
                                  cursor: "pointer",
                                },
                                attrs: { src: _vm.addData.封面 },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(10),
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { "padding-top": "7px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.addData.文章摘要) + " ")]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(11),
                          _c("div", {
                            staticClass: "mb-5",
                            domProps: { innerHTML: _vm._s(_vm.addData.內文) },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box with-border" }, [
      _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
        _vm._v("文章內容"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" App： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 文章標題： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 文章封面： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 文章摘要： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內文： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-center" }, [_vm._v("預覽")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 遊戲： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 文章標題： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 文章封面： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 文章摘要： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 內文： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }