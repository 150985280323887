var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("購點欠費紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("購點欠費紀錄")]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("查詢日期："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DateRangePicker", {
                        attrs: {
                          propStartDate: _vm.searchData.startTime,
                          propEndDate: _vm.searchData.endTime,
                        },
                        on: {
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("搜尋時間條件："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.dateType,
                        expression: "searchData.dateType",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    attrs: { required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "dateType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("新增時間"),
                    ]),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("退款時間"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("交易方式："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.交易方式,
                        expression: "searchData.交易方式",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    attrs: { required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "交易方式",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                    _c("option", { attrs: { value: "IOS" } }, [_vm._v("IOS")]),
                    _c("option", { attrs: { value: "Android" } }, [
                      _vm._v("Android"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("服務名稱："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.服務編號,
                        expression: "searchData.服務編號",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    attrs: { required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "服務編號",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.serviceOptions, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.廠商編號 } },
                      [_vm._v(" " + _vm._s(item.名稱) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("是否繳款："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.是否繳款,
                        expression: "searchData.是否繳款",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    attrs: { required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "是否繳款",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [_vm._v("全部")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("未繳款")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("已繳款")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("訂單編號："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.訂單編號,
                      expression: "searchData.訂單編號",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.searchData.訂單編號 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "訂單編號", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("儲值角色："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.儲值角色,
                      expression: "searchData.儲值角色",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.searchData.儲值角色 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "儲值角色", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("門號："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.門號,
                      expression: "searchData.門號",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.searchData.門號 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "門號", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.clickSearch },
                  },
                  [_vm._v(" 查詢 ")]
                ),
              ]),
              _vm.list.length > 0
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: { click: _vm.exportOwedList },
                      },
                      [_vm._v(" 匯出 ")]
                    ),
                  ])
                : _vm._e(),
              _vm.hasEditPermission
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning btn-add",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addData()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                    ),
                  ])
                : _vm._e(),
              _vm.hasEditPermission
                ? _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.importData },
                      },
                      [_vm._v(" 匯入資料 ")]
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "data-num" }, [
                _c("span", [_vm._v("總筆數：")]),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { id: "total" },
                  },
                  [_vm._v(_vm._s(_vm.total))]
                ),
                _c("span", [_vm._v("總金額：")]),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { id: "amount" },
                  },
                  [_vm._v(_vm._s(_vm.amount))]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", { staticClass: "text-nowrap" }, [
                          _c("span", {
                            staticClass: "state icheckbox_flat-green",
                            class: _vm.批次處理.是否全選 ? "checked" : "",
                            on: {
                              click: function ($event) {
                                _vm.批次處理.是否全選 = !_vm.批次處理.是否全選
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              class: _vm.是否至少一筆被勾選 ? "" : "disabled",
                              attrs: { type: "button" },
                              on: { click: _vm.clickBatch },
                            },
                            [_vm._v("批次處理")]
                          ),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("訂單編號"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("服務編號"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("交易方式"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("儲值角色"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("金額"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("是否繳款"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("平台收據時間"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("退款時間"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("凍結日期"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("繳費時間"),
                        ]),
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("新增時間"),
                        ]),
                        _vm.hasEditPermission
                          ? _c(
                              "th",
                              {
                                staticClass: "text-nowrap text-center",
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(" 功能 ")]
                            )
                          : _vm._e(),
                      ]),
                      _vm.list.length > 0
                        ? _vm._l(_vm.list, function (item) {
                            return _c("tr", { key: item.id }, [
                              _c("td", { staticClass: "text-center" }, [
                                _c("span", {
                                  staticClass: "state icheckbox_flat-green",
                                  class: item.checked ? "checked" : "",
                                  on: {
                                    click: function ($event) {
                                      item.checked = !item.checked
                                    },
                                  },
                                }),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.訂單編號)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.服務編號)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.交易方式)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.儲值角色)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.金額)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.是否繳款 == 0 ? "否" : "是") +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        item.交易時間,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        item.退款時間,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        item.凍結日期,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        item.繳費時間,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        item.取得訂單時間,
                                        "YYYY/MM/DD HH:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm.hasEditPermission
                                ? _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editorItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 編輯 ")]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pageSize,
                      expression: "pageSize",
                    },
                  ],
                  staticClass: "form-control page-size",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.pageSize = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 10 } }, [
                    _vm._v("單頁顯示10項"),
                  ]),
                  _c("option", { domProps: { value: 20 } }, [
                    _vm._v("單頁顯示20項"),
                  ]),
                  _c("option", { domProps: { value: 40 } }, [
                    _vm._v("單頁顯示40項"),
                  ]),
                  _c("option", { domProps: { value: 100 } }, [
                    _vm._v("單頁顯示100項"),
                  ]),
                ]
              ),
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("PaymentOwedSettingTime", {
          ref: "PaymentOwedSettingTime",
          on: { refreshList: _vm.queryAll },
        }),
        _c("ImportPaymentOwedDatas", {
          ref: "ImportPaymentOwedDatas",
          on: { refreshList: _vm.queryAll },
        }),
        _c("PaymentOwedBatch", {
          ref: "PaymentOwedBatch",
          on: { refreshList: _vm.queryAll },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "13" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }