var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjGameDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("押注紀錄查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("押注紀錄查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.resetDataList()
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "inID" } },
                  [_vm._v("入口編號：")]
                ),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.入口Id,
                        expression: "searchData.入口Id",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", id: "inID", list: "listID" },
                    domProps: { value: _vm.searchData.入口Id },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "入口Id",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "datalist",
                  { attrs: { id: "listID" } },
                  _vm._l(_vm.entryData, function (item) {
                    return _c("option", { domProps: { value: item.入口Id } }, [
                      _vm._v(
                        " (" +
                          _vm._s(String(item.入口Id).padStart(5, "0")) +
                          ") " +
                          _vm._s(item.活動備註) +
                          " " +
                          _vm._s(_vm.uiDate(item.入口開始時間).split(" ")[0]) +
                          " "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._m(0),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.datas.length > 0,
                  expression: "this.datas.length > 0",
                },
              ],
              staticClass: "top-box",
            },
            [
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    width: "20%",
                    "margin-right": "1%",
                    "min-width": "200px",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "inLocation" },
                    },
                    [_vm._v("活動名稱：")]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.filters.活動名稱,
                          expression: "filters.活動名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inLocation",
                        placeholder: "全部",
                      },
                      domProps: { value: _vm.filters.活動名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filters,
                            "活動名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    width: "19%",
                    "margin-right": "1%",
                    "min-width": "200px",
                  },
                },
                [
                  _c(
                    "label",
                    { staticClass: "control-label", attrs: { for: "inArea" } },
                    [_vm._v("賽區：")]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.filters.賽區,
                          expression: "filters.賽區",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inArea",
                        placeholder: "全部",
                      },
                      domProps: { value: _vm.filters.賽區 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filters,
                            "賽區",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    width: "19%",
                    "margin-right": "1%",
                    "min-width": "200px",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "inSession" },
                    },
                    [_vm._v("場次：")]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.filters.場次,
                          expression: "filters.場次",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inSession",
                        placeholder: "全部",
                      },
                      domProps: { value: _vm.filters.場次 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filters,
                            "場次",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    width: "19%",
                    "margin-right": "1%",
                    "min-width": "200px",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "inPlayer" },
                    },
                    [_vm._v("參賽者：")]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.filters.參賽者,
                          expression: "filters.參賽者",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inPlayer",
                        placeholder: "全部",
                      },
                      domProps: { value: _vm.filters.參賽者 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filters,
                            "參賽者",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    width: "19%",
                    "margin-right": "0%",
                    "min-width": "200px",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "inCharacter" },
                    },
                    [_vm._v("角色：")]
                  ),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.filters.角色,
                          expression: "filters.角色",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inCharacter",
                        placeholder: "全部",
                      },
                      domProps: { value: _vm.filters.角色 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filters,
                            "角色",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: { float: "right", "margin-right": "0" },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "inPageSize" },
                    },
                    [_vm._v("每頁顯示比數：")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "input-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.pageSize,
                              expression: "pageSize",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "inPageSize" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.pageSize = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "10" } }, [
                            _vm._v("10"),
                          ]),
                          _c("option", { attrs: { value: "20" } }, [
                            _vm._v("20"),
                          ]),
                          _c("option", { attrs: { value: "30" } }, [
                            _vm._v("30"),
                          ]),
                          _c("option", { attrs: { value: "50" } }, [
                            _vm._v("50"),
                          ]),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v("100"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c(
              "table",
              { staticClass: "table table-bordered text-center table-striped" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "20" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.datas.length === 0 && _vm.result.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "20" } }, [
                            _vm._v(_vm._s(_vm.result.message)),
                          ]),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "20" } }, [
                            _vm._v("查無資料"),
                          ]),
                        ])
                      : _vm._l(_vm.pagedData, function (item, index) {
                          return [
                            _c("tr", [
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    String(item.entryID).padStart(5, "0")
                                  ),
                                },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(item.活動地點),
                                },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(item.matchZoneIndex + 1),
                                },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(item.場次) },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(item.betAmount),
                                },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(item.賠率) },
                              }),
                              _c("td", {
                                domProps: { textContent: _vm._s(item.參賽者) },
                              }),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(item.playerName),
                                },
                              }),
                            ]),
                          ]
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              key: _vm.pageSize,
              ref: "pagination",
              attrs: { pageLimitCount: _vm.pageSize, total: _vm.total },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btns mb-10" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v(" 查詢 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("入口編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "10%" } }, [
        _vm._v("活動名稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("賽區"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("場次"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("押注額"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("賠率"),
      ]),
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("參賽者"),
      ]),
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("角色"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }