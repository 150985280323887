var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PopForm", {
        ref: "editForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 新增/編輯資料 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("產品："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group-for-multiselect-wrap" },
                  [
                    _c("multiselect", {
                      attrs: {
                        placeholder: "請選取產品",
                        options: _vm.廠商List,
                        label: "資料",
                        "track-by": "編號",
                        "show-labels": false,
                        "allow-empty": false,
                      },
                      model: {
                        value: _vm.edit.廠商,
                        callback: function ($$v) {
                          _vm.$set(_vm.edit, "廠商", $$v)
                        },
                        expression: "edit.廠商",
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "input-group-addon",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("openFirmPopForm")
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-pencil" })]
                    ),
                  ],
                  1
                ),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("商品編號： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.商品編號,
                        expression: "edit.商品編號",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 商品編號" },
                    domProps: { value: _vm.edit.商品編號 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "商品編號",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("商品名稱： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.商品名稱,
                        expression: "edit.商品名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 商品名稱" },
                    domProps: { value: _vm.edit.商品名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "商品名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("顯示名稱： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.顯示名稱,
                        expression: "edit.顯示名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 顯示名稱" },
                    domProps: { value: _vm.edit.顯示名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "顯示名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("分類： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.分類,
                          expression: "edit.分類",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "分類",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.分類Enum, function (value, k) {
                      return _c(
                        "option",
                        { key: value, domProps: { value: value } },
                        [_vm._v(_vm._s(k))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("彈性點： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.彈性點YN,
                          expression: "edit.彈性點YN",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "彈性點YN",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: true } }, [
                        _vm._v("Y"),
                      ]),
                      _c("option", { domProps: { value: false } }, [
                        _vm._v("N"),
                      ]),
                    ]
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("ERP品號： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.ERP品號,
                        expression: "edit.ERP品號",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 ERP品號" },
                    domProps: { value: _vm.edit.ERP品號 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "ERP品號",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("ERP名稱： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.ERP名稱,
                        expression: "edit.ERP名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 ERP名稱" },
                    domProps: { value: _vm.edit.ERP名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "ERP名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("庫別： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.ERP庫別,
                          expression: "edit.ERP庫別",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "ERP庫別",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("不設定"),
                      ]),
                      _vm._l(_vm.ERP庫別Enum, function (v, k) {
                        return _c(
                          "option",
                          { key: v, domProps: { value: v } },
                          [_vm._v(_vm._s(k))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("帳務幣別： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.帳務幣別,
                          expression: "edit.帳務幣別",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "帳務幣別",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(
                      _vm.$store.getters["IPSSetting/幣別列表"],
                      function (item) {
                        return _c(
                          "option",
                          { key: item, domProps: { value: item } },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("介接廠商： "),
                ]),
                _vm.edit編號 == -1
                  ? _c(
                      "button",
                      {
                        staticClass: "form-group btn btn-warning btn-xs",
                        on: {
                          click: function ($event) {
                            _vm.add介接廠商()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                    )
                  : _vm._e(),
                _vm._l(_vm.edit.介接廠商, function (item, sn) {
                  return _c(
                    "fieldset",
                    { key: sn },
                    [
                      _c("legend", [
                        _c(
                          "div",
                          { staticClass: "input-group-for-multiselect-wrap" },
                          [
                            _c("multiselect", {
                              staticClass: "multiselect-width",
                              attrs: {
                                placeholder: "請選擇介接廠商",
                                options: _vm.介接商List,
                                label: "資料",
                                "track-by": "編號",
                                "show-labels": false,
                                "allow-empty": false,
                              },
                              model: {
                                value: item.介接商,
                                callback: function ($$v) {
                                  _vm.$set(item, "介接商", $$v)
                                },
                                expression: "item.介接商",
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "input-group-addon",
                                staticStyle: { "border-left": "0" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("openFactoryPopForm")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-pencil" })]
                            ),
                            _vm.edit編號 == -1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "input-group-addon",
                                    on: {
                                      click: function ($event) {
                                        _vm.del介接廠商(item, sn)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-close" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("label", { staticClass: "form-group" }, [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v("開立發票YN： "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.開立發票YN,
                                expression: "item.開立發票YN",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "開立發票YN",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: true } }, [
                              _vm._v("Y"),
                            ]),
                            _c("option", { domProps: { value: false } }, [
                              _vm._v("N"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("label", { staticClass: "form-group" }, [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v("停用/啟用： "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.停用YN,
                                expression: "item.停用YN",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "停用YN",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: true } }, [
                              _vm._v("停用"),
                            ]),
                            _c("option", { domProps: { value: false } }, [
                              _vm._v("啟用"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("label", { staticClass: "form-group" }, [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v("來源Key： "),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "form-group btn btn-warning btn-xs",
                          on: {
                            click: function ($event) {
                              _vm.add來源Key(item)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(" 新增 "),
                        ]
                      ),
                      _vm._l(item.來源Key, function (來源, 來源sn) {
                        return _c(
                          "label",
                          {
                            key: 來源sn,
                            staticClass: "form-group",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: item.來源Key[來源sn],
                                  expression: "item.來源Key[來源sn]",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "請輸入 來源Key",
                              },
                              domProps: { value: item.來源Key[來源sn] },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    item.來源Key,
                                    來源sn,
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "input-group-addon",
                                on: {
                                  click: function ($event) {
                                    _vm.del來源Key(item.來源Key, 來源sn)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-close" })]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("備註： "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.備註,
                        expression: "edit.備註",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "請輸入 備註" },
                    domProps: { value: _vm.edit.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "備註", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.closeForm()
                      },
                    },
                  },
                  [_vm._v("關閉")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v("儲存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }