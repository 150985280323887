var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "PlayerSettingList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("大量停權結果")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/NMJ/PlayerData" } }, [
                _vm._v("玩家資料查詢"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("大量停權結果")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              { staticClass: "top-box" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-default",
                    attrs: { to: { name: "NmjPlayerData" } },
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v(" 玩家資料 "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.updateData()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" 執行大量停權 "),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered text-center" }, [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v(_vm._s(_vm.message)),
                          ]),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v("查無控管紀錄"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [_vm._v(_vm._s(item.起始日))]),
                            _c("td", [_vm._v(_vm._s(item.操作者))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.typeStatus(
                                    item.動作,
                                    item.類型,
                                    item.期限
                                  )
                                ) + " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " + _vm._s(item.類型 ? "永久" : item.終止日)
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.原因))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(item.success) +
                                  " / " +
                                  _vm._s(item.total)
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showResult(
                                        item.大量停權結果名單
                                      )
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("playerSettingContent", {
        ref: "playerSettingContent",
        on: {
          updateList: function ($event) {
            return _vm.getDataList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("操作"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "5%" } }, [
          _vm._v("操作"),
          _c("wbr"),
          _vm._v("人員"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "5%" } }, [
          _vm._v("類型"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("期限"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("原因"),
        ]),
        _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
          _vm._v("執行結果"),
        ]),
        _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
          _vm._v("名單"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }