var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "SMSProvider" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("簡訊發送備援切換")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("簡訊發送備援切換")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("自訂簡訊門號"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-success btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.importData()
                  },
                },
              },
              [_vm._v(" 多筆匯入 ")]
            ),
          ]),
          _vm._m(0),
        ]),
      ]),
    ]),
    _c("div", { ref: "import", staticClass: "modal fade" }, [_vm._m(1)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-horizontal" }, [
      _c("div", { staticClass: "box-body" }, [
        _c("div", { staticClass: "form-group mb-20" }, [
          _c("label", { staticClass: "col-sm-1 control-label text-nowrap" }, [
            _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
            _vm._v(" 手機： "),
          ]),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("input", { staticClass: "form-control" }),
          ]),
          _c("div", { staticClass: "col-sm-5" }, [
            _vm._v(" * 國碼請以+號分隔。例如：886+0910111222 "),
          ]),
        ]),
        _c("div", { staticClass: "form-group mt-20 mb-20" }, [
          _c("label", { staticClass: "col-sm-1 control-label text-nowrap" }, [
            _c("span"),
            _vm._v(" 序號： "),
          ]),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("input", { staticClass: "form-control" }),
          ]),
          _c("div", { staticClass: "col-sm-5" }, [_vm._v(" * 序號請填半形 ")]),
        ]),
        _c("div", { staticClass: "form-group mt-20" }, [
          _c("label", { staticClass: "col-sm-1 control-label text-nowrap" }, [
            _c("span"),
            _vm._v(" 簡訊內容： "),
          ]),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("textarea", {
              staticClass: "form-control",
              attrs: { rows: "10" },
            }),
          ]),
          _c("div", { staticClass: "col-sm-5" }, [
            _vm._v(
              " * 簡訊字數上限為300字。請將#CODE#(大寫)放在您欲取代序號的位置。例如：歡迎使用moLo最新遊戲：moLo快樂送_#CODE#。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "btns text-center" }, [
          _c("label", { staticClass: "col-sm-1 control-label" }),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-lg",
                attrs: { type: "button" },
              },
              [_vm._v(" 確定送出 ")]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-dialog modal-sm" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                "aria-label": "Close",
                "data-dismiss": "modal",
                type: "button",
              },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _c("h4", { staticClass: "modal-title" }, [_vm._v("匯入簡訊資料")]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "text-center" }, [
            _c("input", { attrs: { type: "file" } }),
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mt-10 mb-10",
              staticStyle: { width: "100%" },
              attrs: { type: "button" },
            },
            [_vm._v(" 確定匯入 ")]
          ),
          _c("br"),
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-success btn-cog",
              attrs: {
                href: "/uploads/簡訊配點範例.xlsx",
                type: "button",
                download: "",
              },
            },
            [_vm._v(" 配點範例 ")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }