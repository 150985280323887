var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增/修改 支付設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "IPSSettingList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("金流設定")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "IPSSettingPayList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("支付設定")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增/修改 支付設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.支付名稱,
                          expression: "edit.支付名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.支付名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "支付名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.支付方式代碼,
                          expression: "edit.支付方式代碼",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.支付方式代碼 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "支付方式代碼",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.地區,
                            expression: "edit.地區",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "地區",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.地區列表, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.地區) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.限制幣別,
                            expression: "edit.限制幣別",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "限制幣別",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.地區列表, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.幣別) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.金流類型,
                            expression: "edit.金流類型",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "金流類型",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.金流類型設定Key, function (item, id) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.類型) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.支付品號,
                          expression: "edit.支付品號",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.支付品號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "支付品號",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.限制開關()
                  ? [
                      _c("div", { staticClass: "form-group" }, [
                        _vm._m(6),
                        _c(
                          "div",
                          { staticClass: "col-sm-8" },
                          [
                            _c("LimitControler", {
                              ref: "comp",
                              attrs: { 額外限制List: _vm.edit.額外限制 },
                              on: {
                                "update:額外限制List": function ($event) {
                                  return _vm.$set(_vm.edit, "額外限制", $event)
                                },
                                "update:額外限制list": function ($event) {
                                  return _vm.$set(_vm.edit, "額外限制", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "否",
                            name: "是否開立發票",
                          },
                          model: {
                            value: _vm.edit.是否開立發票,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "是否開立發票", $$v)
                            },
                            expression: "edit.是否開立發票",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "是",
                            name: "是否開立發票",
                          },
                          model: {
                            value: _vm.edit.是否開立發票,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "是否開立發票", $$v)
                            },
                            expression: "edit.是否開立發票",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "否",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.啟用,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "啟用", $$v)
                            },
                            expression: "edit.啟用",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "是",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.啟用,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "啟用", $$v)
                            },
                            expression: "edit.啟用",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 支付名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 支付方式代碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 地區： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 限制幣別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流類型： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 支付品號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 額外限制： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 是否開立發票： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }