var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            _vm._s(_vm.$t("社群管理-詳細")) +
              "( " +
              _vm._s(
                _vm.$t(
                  `PlatformType-${
                    _vm.PlatformType[_vm.$route.params.PlatformType].Type
                  }`
                )
              ) +
              " )"
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "/" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$router.push({
                      name: "MessageSocialManager",
                      params: _vm.$route.params,
                    })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("社群管理")))]
            ),
          ]),
          _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.$t("設定")))]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered classTypeTable" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", [_vm._v(_vm._s(_vm.$t("訊息")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("觸發時機")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("內容")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("狀態")))]),
                        _c("th", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(_vm._s(_vm.$t("功能"))),
                        ]),
                      ]),
                      _vm._l(_vm.list, function (item) {
                        return _c("tr", { key: item.SystemChatType }, [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `SystemChatType-${item.SystemChatKey}-訊息`
                                )
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `SystemChatType-${item.SystemChatKey}-觸發時機`,
                                    [item.ServiceTimeMsg]
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(item.Msg))]),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "ckbx-style-8 ckbx toggleInput",
                                on: {
                                  click: function ($event) {
                                    _vm.啟停用(item)
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: item.enable,
                                      expression: "item.enable",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: {
                                    "false-value": false,
                                    "true-value": true,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.enable)
                                      ? _vm._i(item.enable, null) > -1
                                      : item.enable,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = item.enable,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              item,
                                              "enable",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              item,
                                              "enable",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(item, "enable", $$c)
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "check" + item } }),
                              ]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    _vm.設定(
                                      _vm.$t(
                                        `SystemChatType-${item.SystemChatKey}-訊息`
                                      ),
                                      item
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("設定")))]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("MessageSocialDetailManagerPop", {
        ref: "pop",
        attrs: { Service: _vm.$store.state.projectId },
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }