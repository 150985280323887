var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "videoAlert", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-borderless" }, [
            _c("tbody", [
              _c("tr", [
                _vm._m(1),
                _c("td", [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                    },
                    [
                      _c("option", { key: "1", attrs: { value: "0" } }, [
                        _vm._v("Youtube"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(2),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.keyword.關鍵字,
                        expression: "keyword.關鍵字",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.keyword.關鍵字 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.keyword, "關鍵字", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v(" 儲存 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [
        _c("span", [_vm._v("關鍵字內容")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: {
          width: "120px",
          "vertical-align": "baseline",
          "padding-top": "15px",
        },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("關鍵字類別："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "text-right", staticStyle: { width: "120px" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("關鍵字："),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }