var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("手開發票管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("手開發票管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.condition,
                        expression: "condition",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.condition = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: 1 } }, [
                      _vm._v("一般條件搜尋"),
                    ]),
                    _c("option", { domProps: { value: 2 } }, [
                      _vm._v("搜尋發票號碼"),
                    ]),
                    _c("option", { domProps: { value: 3 } }, [
                      _vm._v("搜尋交易序號"),
                    ]),
                  ]
                ),
              ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.condition == 2,
                      expression: "(condition == 2)",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.發票號碼,
                          expression: "searchData.發票號碼",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "115px" },
                      attrs: { type: "text", placeholder: "發票號碼" },
                      domProps: { value: _vm.searchData.發票號碼 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "發票號碼",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.condition == 3,
                      expression: "(condition == 3)",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.編號,
                          expression: "searchData.編號",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "115px" },
                      attrs: { type: "text", placeholder: "交易序號" },
                      domProps: { value: _vm.searchData.編號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "編號",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.condition == 1,
                      expression: "(condition == 1)",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.時間條件,
                            expression: "searchData.時間條件",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "110px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "時間條件",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.時間條件Enum, function (item, key) {
                        return _c("option", { domProps: { value: item } }, [
                          _vm._v(_vm._s(key) + " "),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.searchData.時間條件 ==
                            _vm.時間條件Enum.建立時間,
                          expression:
                            "searchData.時間條件 == 時間條件Enum.建立時間",
                        },
                      ],
                      staticStyle: { "line-height": "34px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          staticStyle: {
                            width: "160px",
                            "margin-right": "0px",
                          },
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              config: {
                                format: "YYYY-MM-DD HH:mm:ss",
                                showTodayButton: true,
                                showClear: true,
                                useCurrent: false,
                                sideBySide: true,
                                locale: "zh-tw",
                              },
                            },
                            model: {
                              value: _vm.searchData.建立開始時間,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "建立開始時間", $$v)
                              },
                              expression: "searchData.建立開始時間",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" ~ "),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          staticStyle: { width: "160px" },
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              config: {
                                format: "YYYY-MM-DD HH:mm:ss",
                                showTodayButton: true,
                                showClear: true,
                                useCurrent: false,
                                sideBySide: true,
                                locale: "zh-tw",
                              },
                            },
                            model: {
                              value: _vm.searchData.建立結束時間,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "建立結束時間", $$v)
                              },
                              expression: "searchData.建立結束時間",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.searchData.時間條件 ==
                            _vm.時間條件Enum.發票時間,
                          expression:
                            "searchData.時間條件 == 時間條件Enum.發票時間",
                        },
                      ],
                      staticClass: "form-group",
                      staticStyle: { width: "100px" },
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          config: {
                            format: "YYYY-MM-DD",
                            showTodayButton: true,
                            showClear: true,
                            useCurrent: false,
                            sideBySide: true,
                            locale: "zh-tw",
                          },
                        },
                        model: {
                          value: _vm.searchData.發票日期,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchData, "發票日期", $$v)
                          },
                          expression: "searchData.發票日期",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("發票種類： "),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.發票種類,
                            expression: "searchData.發票種類",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "110px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "發票種類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _vm.發票種類全部 } },
                          [_vm._v("全部")]
                        ),
                        _c(
                          "option",
                          { domProps: { value: _vm.發票種類二聯全部 } },
                          [_vm._v("二聯全部")]
                        ),
                        _c(
                          "option",
                          { domProps: { value: _vm.發票種類三聯全部 } },
                          [_vm._v("三聯全部")]
                        ),
                        _vm._l(_vm.發票種類Enum, function (item, key) {
                          return _c("option", { domProps: { value: item } }, [
                            _vm._v(_vm._s(key) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.買方統編,
                          expression: "searchData.買方統編",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "90px" },
                      attrs: { type: "text", placeholder: "買方統編" },
                      domProps: { value: _vm.searchData.買方統編 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "買方統編",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.建立者,
                          expression: "searchData.建立者",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "90px" },
                      attrs: { type: "text", placeholder: "建立者" },
                      domProps: { value: _vm.searchData.建立者 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "建立者",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("是否已入排程："),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.匯入工具,
                            expression: "searchData.匯入工具",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "80px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "匯入工具",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _vm.匯入工具全部 } },
                          [_vm._v("全部")]
                        ),
                        _vm._l(_vm.匯入工具Enum, function (item, key) {
                          return _c("option", { domProps: { value: item } }, [
                            _vm._v(_vm._s(key) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("發票狀態："),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.發票狀態,
                            expression: "searchData.發票狀態",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "100px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "發票狀態",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _vm.手開發票狀態全部 } },
                          [_vm._v("全部")]
                        ),
                        _vm._l(_vm.手開發票狀態Enum, function (item, key) {
                          return _c("option", { domProps: { value: item } }, [
                            _vm._v(_vm._s(key) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _c("br"),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onSearch(1, _vm.searchData)
                      },
                    },
                  },
                  [_vm._v(" 查詢 ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.openDetail(null)
                      },
                    },
                  },
                  [_vm._v(" 新增 ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    attrs: { type: "button" },
                    on: { click: _vm.openImport },
                  },
                  [_vm._v(" Excel匯入 ")]
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.company.Open(false)
                    },
                  },
                },
                [_vm._v(" 常用代碼設定 ")]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", { staticClass: "text-center text-nowrap" }, [
                          _vm._v(" 批次處理"),
                          _c("br"),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.批次處理.操作類型,
                                  expression: "批次處理.操作類型",
                                },
                              ],
                              staticClass: "batchSelect",
                              staticStyle: { "margin-bottom": "5px" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.批次處理,
                                    "操作類型",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.批次修改操作類型Enum,
                              function (item, key) {
                                return _c(
                                  "option",
                                  { domProps: { value: item } },
                                  [_vm._v(_vm._s(key) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                          _c("br"),
                          _c("span", {
                            staticClass:
                              "state icheckbox_flat-green marginRight",
                            class: _vm.批次處理.是否全選 ? "checked" : "",
                            on: {
                              click: function ($event) {
                                _vm.批次處理.是否全選 = !_vm.批次處理.是否全選
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "button",
                                disabled: !_vm.是否至少一筆被勾選,
                              },
                              on: { click: _vm.clickBatch },
                            },
                            [_vm._v("執行")]
                          ),
                        ]),
                        _c("th", [_vm._v("是否已入排程")]),
                        _c(
                          "th",
                          {
                            on: {
                              click: function ($event) {
                                _vm.改變排序欄位("交易序號")
                              },
                            },
                          },
                          [
                            _vm.searchData.是否正序 == true
                              ? _c("i", {
                                  staticClass: "fa fa-fw fa-long-arrow-up",
                                })
                              : _vm._e(),
                            _vm.searchData.是否正序 == false
                              ? _c("i", {
                                  staticClass: "fa fa-fw fa-long-arrow-down",
                                })
                              : _vm._e(),
                            _vm._v(" 交易序號 "),
                          ]
                        ),
                        _c("th", [_vm._v("發票狀態")]),
                        _c("th", [_vm._v("發票號碼")]),
                        _c("th", [_vm._v("發票開立時間")]),
                        _c("th", [_vm._v("發票金額")]),
                        _c("th", [_vm._v("營業稅別")]),
                        _c("th", [_vm._v("銷售總額")]),
                        _c("th", [_vm._v("發票種類")]),
                        _c("th", [_vm._v("買方統編")]),
                        _vm._m(0),
                        _c("th", [_vm._v("備註")]),
                        _c("th", [_vm._v("建立者")]),
                        _c("th", [_vm._v("功能")]),
                        _vm._m(1),
                      ]),
                      _vm.list.length > 0
                        ? _vm._l(_vm.list, function (item) {
                            return _c("tr", { key: item.編號 }, [
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.該項目是否可勾選(item),
                                        expression: "該項目是否可勾選(item)",
                                      },
                                    ],
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "state icheckbox_flat-green",
                                      class: item.checked ? "checked" : "",
                                      attrs: { disabled: true },
                                      on: {
                                        click: function ($event) {
                                          item.checked = !item.checked
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("TransEnum")(
                                      item.匯入工具,
                                      _vm.匯入工具Enum
                                    )
                                  )
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.編號)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("TransEnum")(
                                      item.發票狀態,
                                      _vm.手開發票狀態Enum
                                    )
                                  )
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.發票號碼)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(_vm._f("filtersTime")(item.發票時間))
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.發票金額)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("TransEnum")(
                                      item.營業稅別,
                                      _vm.稅別Enum
                                    )
                                  ) + " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.銷售總額)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("TransEnum")(
                                      item.發票種類,
                                      _vm.發票種類Enum
                                    )
                                  )
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.統一編號)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.買方)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.備註)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.建立者)),
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-center text-nowrap" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.Open商品明細(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 商品明細 ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !item.匯入工具,
                                          expression: "!item.匯入工具",
                                        },
                                      ],
                                      staticClass: "btn bg-purple",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDetail(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.匯入工具,
                                          expression: "item.匯入工具",
                                        },
                                      ],
                                      staticClass: "btn btn-success",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDetail(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !item.匯入工具,
                                          expression: "!item.匯入工具",
                                        },
                                      ],
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          _vm.刪除(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]
                              ),
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("TransEnum")(
                                        item.列印狀態,
                                        _vm.手開發票列印狀態Enum
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.是否可列印,
                                        expression: "item.是否可列印",
                                      },
                                    ],
                                    staticClass: "btn btn-info",
                                    on: {
                                      click: function ($event) {
                                        _vm.列印發票(
                                          [item],
                                          _vm.手開發票列印狀態Enum.未曾列印
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("列印")]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.是否可列印,
                                        expression: "item.是否可列印",
                                      },
                                    ],
                                    staticClass: "btn btn-info",
                                    on: {
                                      click: function ($event) {
                                        _vm.列印發票(
                                          [item],
                                          _vm.手開發票列印狀態Enum.已列印
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("補印")]
                                ),
                              ]),
                            ])
                          })
                        : [_vm._m(2)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pageSize,
                      expression: "pageSize",
                    },
                  ],
                  staticClass: "form-control page-size",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.pageSize = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 10 } }, [
                    _vm._v("單頁顯示10項"),
                  ]),
                  _c("option", { domProps: { value: 20 } }, [
                    _vm._v("單頁顯示20項"),
                  ]),
                  _c("option", { domProps: { value: 40 } }, [
                    _vm._v("單頁顯示40項"),
                  ]),
                  _c("option", { domProps: { value: 100 } }, [
                    _vm._v("單頁顯示100項"),
                  ]),
                ]
              ),
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("InvoiceElectronicProcessPopDetail", {
        ref: "detailForm",
        on: {
          refresh: function ($event) {
            return _vm.onPageRefresh(_vm.searchData)
          },
        },
      }),
      _c("InvoiceElectronicProcessPopImport", { ref: "importForm" }),
      _c("InvoiceElectronicProcessOneClickSetting", { ref: "company" }),
      _c("div", { ref: "itemsForm", staticClass: "mymodal" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "800px",
              "margin-top": "30px",
              "margin-left": "auto",
              "margin-right": "auto",
            },
          },
          [
            _c("div", { staticClass: "box box-info" }, [
              _c("div", { staticClass: "box-header with-border" }, [
                _c("h3", { staticClass: "box-title" }),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.Close商品明細()
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-horizontal" }, [
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _c("InvoiceElectronicProcessItems", {
                      ref: "itemsForm",
                      attrs: {
                        營業稅: _vm.items.營業稅,
                        營業稅別: _vm.items.營業稅別,
                        商品明細: _vm.items.商品明細,
                        發票種類: _vm.items.發票種類,
                        Const銷售總額: _vm.items.銷售總額,
                        Const發票金額: _vm.items.發票金額,
                        isEdit: false,
                      },
                      on: {
                        "update:營業稅": function ($event) {
                          return _vm.$set(_vm.items, "營業稅", $event)
                        },
                        "update:營業稅別": function ($event) {
                          return _vm.$set(_vm.items, "營業稅別", $event)
                        },
                        "update:商品明細": function ($event) {
                          return _vm.$set(_vm.items, "商品明細", $event)
                        },
                        "update:發票種類": function ($event) {
                          return _vm.$set(_vm.items, "發票種類", $event)
                        },
                        "update:Const銷售總額": function ($event) {
                          return _vm.$set(_vm.items, "銷售總額", $event)
                        },
                        "update:const銷售總額": function ($event) {
                          return _vm.$set(_vm.items, "銷售總額", $event)
                        },
                        "update:Const發票金額": function ($event) {
                          return _vm.$set(_vm.items, "發票金額", $event)
                        },
                        "update:const發票金額": function ($event) {
                          return _vm.$set(_vm.items, "發票金額", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "box-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      staticStyle: { "margin-right": "15px" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.Close商品明細()
                        },
                      },
                    },
                    [_vm._v("關閉")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("發票抬頭"), _c("br"), _vm._v("(買受人)")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("span", [_vm._v("列印")]),
      _c("br"),
      _c(
        "a",
        {
          staticClass: "btn btn-block btn-success btn-cog",
          staticStyle: { display: "inline-block", width: "100px" },
          attrs: {
            href: "/download/手開發票熱感機注意事項.pdf",
            type: "button",
            download: "",
          },
        },
        [_c("i", { staticClass: "fa fa-fw fa-download" }), _vm._v("注意事項")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "16" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }