var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "itemPage",
      class: !_vm.item.買方 ? `page` : ``,
      staticStyle: { "font-size": "20px" },
    },
    [
      _c("div", { staticClass: "center" }, [_vm._v("交易明細")]),
      _c(
        "table",
        { staticStyle: { width: "100%" } },
        [
          _vm._m(0),
          _vm._l(_vm.item.商品明細, function (商品, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(商品.品名))]),
              _c(
                "td",
                {
                  staticClass: "right",
                  staticStyle: { "white-space": "nowrap" },
                },
                [_vm._v(_vm._s(商品.數量))]
              ),
              _c(
                "td",
                {
                  staticClass: "right",
                  staticStyle: { "white-space": "nowrap" },
                },
                [_vm._v(_vm._s(商品.單價))]
              ),
              _c(
                "td",
                {
                  staticClass: "right",
                  staticStyle: { "white-space": "nowrap" },
                },
                [_vm._v(_vm._s(商品.金額))]
              ),
            ])
          }),
          _c("tr", [
            _c(
              "td",
              {
                staticStyle: { height: "30mm", "vertical-align": "text-top" },
                attrs: { colspan: "4" },
              },
              [_vm._v("備註:" + _vm._s(_vm.item.備註))]
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v("營業稅別")]),
            _c("td", { staticClass: "right", attrs: { colspan: "3" } }, [
              _vm._v(
                _vm._s(_vm._f("TransEnum")(_vm.item.營業稅別, _vm.稅別Enum))
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("銷售額")]),
            _c("td", { staticClass: "right", attrs: { colspan: "3" } }, [
              _vm._v(_vm._s(_vm.item.銷售總額)),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("稅額")]),
            _c("td", { staticClass: "right", attrs: { colspan: "3" } }, [
              _vm._v(_vm._s(_vm.item.營業稅)),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("總計")]),
            _c("td", { staticClass: "right", attrs: { colspan: "3" } }, [
              _vm._v(" " + _vm._s(_vm.item.發票金額)),
            ]),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("品名")]),
      _c(
        "th",
        { staticClass: "right", staticStyle: { "white-space": "nowrap" } },
        [_vm._v("數量")]
      ),
      _c(
        "th",
        { staticClass: "right", staticStyle: { "white-space": "nowrap" } },
        [_vm._v("單價")]
      ),
      _c(
        "th",
        { staticClass: "right", staticStyle: { "white-space": "nowrap" } },
        [_vm._v("金額")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }