var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "playerServiceContent", staticClass: "modal fade" }, [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.data.編號 ? "編輯" : "新增") + "紀錄"),
            ]),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "playerServiceForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "table-responsive mt-0" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("服務類型")]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.data.分類,
                                  expression: "data.分類",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "服務類型", required: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "分類",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    selected: "",
                                    disabled: "",
                                    hidden: "",
                                  },
                                },
                                [_vm._v("請選擇類別")]
                              ),
                              _vm._l(_vm.types, function (name, id) {
                                return _c(
                                  "option",
                                  { key: id, domProps: { value: id } },
                                  [_vm._v(_vm._s(name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("內容")]),
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.內容,
                                expression: "data.內容",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "內容",
                              min: "1",
                              rows: "5",
                              required: "",
                            },
                            domProps: { value: _vm.data.內容 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.data, "內容", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(1),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("確認送出")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_vm._v(" 取消 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }