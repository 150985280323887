var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1369", attrs: { id: "clientGameContent" } },
    [
      _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "box box-primary",
            staticStyle: { background: "none" },
          },
          [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                {
                  staticClass: "g_back",
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [
                  _vm._m(0),
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.query.遊戲名稱) + " 遊戲介紹")]),
                    _c("p", [_vm._v(_vm._s(_vm.gameQuery.開發商名稱))]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "top-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function ($event) {
                        return _vm.showPlay()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-book" }),
                    _vm._v(" 所有玩法定義 "),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "photo",
                    on: {
                      click: function ($event) {
                        return _vm.showImg()
                      },
                    },
                  },
                  [_c("img", { attrs: { src: _vm.query.圖示, alt: "" } })]
                ),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.query.遊戲館名稱)),
                  ]),
                  _vm.query.標籤集合
                    ? _c(
                        "div",
                        { staticClass: "tag-list" },
                        _vm._l(_vm.query.標籤集合, function (tag, index) {
                          return _c(
                            "router-link",
                            {
                              staticClass: "tag btn btn-success mr-5",
                              attrs: { to: `/slot/searchTag/${tag.編號}` },
                            },
                            [_vm._v(" " + _vm._s(tag.標籤名稱) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "play-list" },
                    [
                      _c("p", [_vm._v("適用玩法定義：")]),
                      _vm._l(_vm.query.玩法集合, function (play, index) {
                        return _c(
                          "button",
                          {
                            staticClass: "btn btn-default mr-10",
                            on: {
                              click: function ($event) {
                                return _vm.showPlay(play.編號)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(play.玩法名稱) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm.query.影片連結
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary mt-10",
                          attrs: { href: _vm.query.影片連結, target: "_blank" },
                        },
                        [_vm._v("影片連結")]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "g-list table-responsive mt-20",
                  staticStyle: { "padding-bottom": "0" },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "g-card",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.showReportAlert(item.編號)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("div", { staticClass: "g-name" }, [
                            _vm._v(_vm._s(item.名稱)),
                          ]),
                          _vm._l(item.預覽視覺集合, function (media, index) {
                            return [
                              media.視覺型態 == "1" && media.是否啟用 == 1
                                ? _c("div", { staticClass: "g-photo" }, [
                                    _c("img", {
                                      attrs: { src: media.視覺路徑, alt: "" },
                                    }),
                                  ])
                                : _vm._e(),
                              media.視覺型態 == "2" && media.是否啟用 == 1
                                ? _c("div", { staticClass: "g-photo" }, [
                                    _c("iframe", {
                                      attrs: {
                                        src: `https://www.youtube.com/embed/${
                                          media.視覺路徑.split("youtu.be/")[1]
                                        }`,
                                        title: "YouTube video player",
                                        frameborder: "0",
                                        allow:
                                          "acc:elerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                                        allowfullscreen: "",
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                          _c("p", [_vm._v(_vm._s(item.預覽說明))]),
                        ],
                        2
                      ),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "loading-box",
                },
                [_c("div", { staticClass: "loading" })]
              ),
            ]),
          ]
        ),
      ]),
      _c("slotPlayContent", { ref: "slotPlayContent" }),
      _c(
        "div",
        {
          ref: "imgAlert",
          staticClass: "modal fade",
          attrs: { id: "imgAlert" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-body" }, [
                _vm._m(1),
                _c("img", { attrs: { src: _vm.query.圖示, alt: "" } }),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.total != 0
        ? _c(
            "div",
            {
              ref: "detailAlert",
              staticClass: "modal fade",
              attrs: { id: "detailAlert" },
            },
            [
              _c("div", { staticClass: "modal-dialog modal-xl" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _vm._m(2),
                    _c("h4", { staticClass: "modal-title text-center" }, [
                      _vm._v(_vm._s(_vm.content.名稱)),
                    ]),
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.content.詳細說明) },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cardLoading,
              expression: "cardLoading",
            },
          ],
          staticClass: "cardLoading",
        },
        [_c("span", [_vm._v("讀取中...")])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fa fa-chevron-left" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }