var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "white" } },
    [
      _vm._l(_vm.list, function (item) {
        return [
          item.買方
            ? _c(
                "div",
                { staticClass: "page" },
                [
                  _c("InvoiceElectronicProcessPrintMain", {
                    ref: "childComp",
                    refInFor: true,
                    attrs: { item: item },
                  }),
                  _c("InvoiceElectronicProcessPrintItem", {
                    ref: "childComp",
                    refInFor: true,
                    attrs: { item: item },
                  }),
                ],
                1
              )
            : [
                _c("InvoiceElectronicProcessPrintMain", {
                  ref: "childComp",
                  refInFor: true,
                  attrs: { item: item },
                }),
                _c("InvoiceElectronicProcessPrintItem", {
                  ref: "childComp",
                  refInFor: true,
                  attrs: { item: item },
                }),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }