var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { ref: "playerSettingContent", staticClass: "modal fade" }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _vm.tab == 1
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _vm._m(0),
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.player
                          ? _vm.unban
                            ? _vm.$t("玩家解鎖")
                            : _vm.$t("玩家控管")
                          : _vm.$t("執行大量停權")
                      )
                    ),
                  ]),
                ]),
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { name: "playerSettingForm" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "table-responsive mt-0" }, [
                        _c("table", { staticClass: "table table-bordered" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.unban
                                      ? _vm.$t("解鎖對象")
                                      : _vm.$t("控管對象")
                                  )
                                ),
                              ]),
                              _vm.player
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.data.玩家暱稱[0])),
                                  ])
                                : _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary fa fa-file-alt mt-0",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.importSN()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("匯入名單")) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "8px" },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.importFileName) + " "
                                        ),
                                      ]
                                    ),
                                    _vm.pagedTable.length > 0
                                      ? _c("div", [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table table-bordered mt-10 mb-10",
                                            },
                                            [
                                              _c("thead", [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticClass:
                                                      "bg-gray-light",
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticStyle: {
                                                          "padding-block":
                                                            ".25em",
                                                        },
                                                        attrs: {
                                                          colspan: "10",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("玩家名單")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.pagedTable,
                                                  function (row) {
                                                    return _c(
                                                      "tr",
                                                      _vm._l(
                                                        row,
                                                        function (col) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                padding:
                                                                  ".1em .5em",
                                                                width: "25%",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(col)
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.pageTotal > 1,
                                                  expression: "pageTotal > 1",
                                                },
                                              ],
                                              staticClass: "text-center",
                                            },
                                            [
                                              _c("Pagination", {
                                                ref: "pagination",
                                                attrs: {
                                                  pageLimitCount: _vm.pageSize,
                                                  total: _vm.pageTotal,
                                                },
                                                on: {
                                                  pageMethod: _vm.onPageChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]),
                            _vm.player
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("綁定門號"))),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.player.門號綁定
                                          ? _vm.player.門號綁定
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.unban
                                      ? _vm.$t("解鎖類型")
                                      : _vm.$t("控管類型")
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c("iCheckRedioButton", {
                                    staticClass: "radio-inline",
                                    attrs: {
                                      checkValue: "0",
                                      message: _vm.$t("停權"),
                                      name: "blockType",
                                    },
                                    model: {
                                      value: _vm.data.動作,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "動作", _vm._n($$v))
                                      },
                                      expression: "data.動作",
                                    },
                                  }),
                                  _c("iCheckRedioButton", {
                                    staticClass: "radio-inline ml-25",
                                    attrs: {
                                      checkValue: "1",
                                      message: _vm.$t("禁言"),
                                      name: "blockType",
                                    },
                                    model: {
                                      value: _vm.data.動作,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "動作", _vm._n($$v))
                                      },
                                      expression: "data.動作",
                                    },
                                  }),
                                  _c("iCheckRedioButton", {
                                    staticClass: "radio-inline ml-25",
                                    attrs: {
                                      checkValue: "2",
                                      message: "限制頭圖上傳",
                                      name: "blockType",
                                    },
                                    model: {
                                      value: _vm.data.動作,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "動作", _vm._n($$v))
                                      },
                                      expression: "data.動作",
                                    },
                                  }),
                                  _c("iCheckRedioButton", {
                                    staticClass: "radio-inline ml-25",
                                    attrs: {
                                      checkValue: "3",
                                      message: "限制兌換",
                                      name: "blockType",
                                    },
                                    model: {
                                      value: _vm.data.動作,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "動作", _vm._n($$v))
                                      },
                                      expression: "data.動作",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.unban ? "解鎖" : "期限")),
                              ]),
                              _vm.unban
                                ? _c(
                                    "td",
                                    [
                                      _c("iCheckRedioButton", {
                                        staticClass: "radio radioCenter",
                                        attrs: {
                                          checkValue: true,
                                          message: _vm.$t("立即解鎖"),
                                          name: "unban",
                                        },
                                        model: {
                                          value: _vm.data.解鎖,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.data, "解鎖", $$v)
                                          },
                                          expression: "data.解鎖",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "td",
                                    [
                                      _vm.data.動作 == 0
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "radio-group" },
                                              [
                                                _c("iCheckRedioButton", {
                                                  staticClass:
                                                    "radio radioCenter",
                                                  attrs: {
                                                    checkValue: "0",
                                                    message: "",
                                                    name: "timeType",
                                                  },
                                                  model: {
                                                    value: _vm.data.類型,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "類型",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "data.類型",
                                                  },
                                                }),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value: _vm.data.期限,
                                                      expression: "data.期限",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                  },
                                                  domProps: {
                                                    value: _vm.data.期限,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.data,
                                                        "期限",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(_vm._s(_vm.$t("天"))),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("iCheckRedioButton", {
                                              staticClass: "radio radioCenter",
                                              attrs: {
                                                checkValue: "2",
                                                message: "永久停權",
                                                name: "timeType",
                                              },
                                              model: {
                                                value: _vm.data.類型,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data,
                                                    "類型",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "data.類型",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.data.動作 == 1
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "radio-group" },
                                              [
                                                _c("iCheckRedioButton", {
                                                  staticClass:
                                                    "radio radioCenter",
                                                  attrs: {
                                                    checkValue: "0",
                                                    message: "",
                                                    name: "timeType",
                                                  },
                                                  model: {
                                                    value: _vm.data.類型,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "類型",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "data.類型",
                                                  },
                                                }),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value: _vm.data.期限,
                                                      expression: "data.期限",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                  },
                                                  domProps: {
                                                    value: _vm.data.期限,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.data,
                                                        "期限",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("分鐘"))
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("iCheckRedioButton", {
                                              staticClass: "radio radioCenter",
                                              attrs: {
                                                checkValue: "2",
                                                message: _vm.$t("永久禁言"),
                                                name: "timeType",
                                              },
                                              model: {
                                                value: _vm.data.類型,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data,
                                                    "類型",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "data.類型",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.data.動作 == 2
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "radio-group" },
                                              [
                                                _c("iCheckRedioButton", {
                                                  staticClass:
                                                    "radio radioCenter",
                                                  attrs: {
                                                    checkValue: "0",
                                                    message: "",
                                                    name: "timeType",
                                                  },
                                                  model: {
                                                    value: _vm.data.類型,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "類型",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression: "data.類型",
                                                  },
                                                }),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value: _vm.data.期限,
                                                      expression: "data.期限",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    min: "1",
                                                  },
                                                  domProps: {
                                                    value: _vm.data.期限,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.data,
                                                        "期限",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                                _c("span", [_vm._v("天")]),
                                              ],
                                              1
                                            ),
                                            _c("iCheckRedioButton", {
                                              staticClass: "radio radioCenter",
                                              attrs: {
                                                checkValue: "2",
                                                message: "暫時凍結(永久)",
                                                name: "timeType",
                                              },
                                              model: {
                                                value: _vm.data.類型,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data,
                                                    "類型",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "data.類型",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.data.動作 == 3
                                        ? [
                                            _c("iCheckRedioButton", {
                                              staticClass: "radio radioCenter",
                                              attrs: {
                                                checkValue: "2",
                                                message: "暫時凍結(永久)",
                                                name: "timeType",
                                              },
                                              model: {
                                                value: _vm.data.類型,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data,
                                                    "類型",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "data.類型",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("原因")]),
                              _c("td", [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.data.原因,
                                      expression: "data.原因",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      _vm.data.動作 == 0
                                        ? _vm.$t("必填")
                                        : _vm.$t("選填"),
                                    rows: "3",
                                    required: _vm.data.動作 == 0,
                                  },
                                  domProps: { value: _vm.data.原因 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.data,
                                        "原因",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _vm.player &&
                      _vm.data.動作 == 0 &&
                      _vm.player.suspensionTime * 1000 >= Date.now()
                        ? _c("span", { staticClass: "ban-info-tip mr-5" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("停權中至X", [
                                    _vm.formatTimestamp(
                                      _vm.player.suspensionTime
                                    ),
                                  ])
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.player &&
                      _vm.data.動作 == 1 &&
                      _vm.player.banSpeechTime * 1000 >= Date.now()
                        ? _c("span", { staticClass: "ban-info-tip mr-5" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("禁言中至X", [
                                    _vm.formatTimestamp(
                                      _vm.player.banSpeechTime
                                    ),
                                  ])
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.player &&
                      _vm.data.動作 == 2 &&
                      _vm.player.限制上傳頭圖時間 * 1000 >= Date.now()
                        ? _c("span", { staticClass: "ban-info-tip mr-5" }, [
                            _vm._v(
                              " 限制上傳頭圖(至 " +
                                _vm._s(
                                  _vm.formatTimestamp(
                                    _vm.player.限制上傳頭圖時間
                                  )
                                ) +
                                " ) "
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _vm.player &&
                      _vm.data.動作 == 3 &&
                      _vm.player.限制兌換時間 * 1000 >= Date.now()
                        ? _c("span", { staticClass: "ban-info-tip mr-5" }, [
                            _vm._v(
                              " 限制兌換(至 " +
                                _vm._s(
                                  _vm.formatTimestamp(_vm.player.限制兌換時間)
                                ) +
                                " ) "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mb-5",
                          attrs: { type: "submit" },
                        },
                        [_vm._v(_vm._s(_vm.$t("確認送出")))]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: { type: "button", "data-dismiss": "modal" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("取消")) + " ")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.tab == 2
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _vm._m(1),
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("大量停權結果"))),
                  ]),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-10",
                            on: {
                              click: function ($event) {
                                return _vm.exportOrders()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("匯出")))]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("執行結果")) +
                            ": " +
                            _vm._s(_vm.resultSuccess) +
                            " / " +
                            _vm._s(_vm.resultTotal) +
                            " "
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterString,
                            expression: "filterString",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "50%" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("暱稱搜尋"),
                        },
                        domProps: { value: _vm.filterString },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.filterString = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "table",
                    { staticClass: "table table-bordered mt-10 mb-10" },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "bg-gray-light" }, [
                          _c(
                            "th",
                            {
                              staticStyle: { "padding-block": ".25em" },
                              attrs: { colspan: "10" },
                            },
                            [_vm._v(_vm._s(_vm.$t("玩家名單")))]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.resultPaged, function (row) {
                          return _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  padding: ".1em .5em",
                                  width: "35%",
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "NmjPlayerData",
                                        query: { nick: row.name1 },
                                      },
                                      "data-dismiss": "modal",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.name1))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  row.status1 == 1 ? "text-green" : "text-red",
                                staticStyle: {
                                  padding: ".1em .5em",
                                  width: "15%",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.status1 == 1
                                      ? _vm.$t("成功")
                                      : _vm.$t("失敗")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  padding: ".1em .5em",
                                  width: "35%",
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "NmjPlayerData",
                                        query: { nick: row.name2 },
                                      },
                                      "data-dismiss": "modal",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.name2))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.name2,
                                    expression: "row.name2",
                                  },
                                ],
                                class:
                                  row.status2 == 1 ? "text-green" : "text-red",
                                staticStyle: {
                                  padding: ".1em .5em",
                                  width: "15%",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.status2 == 1
                                      ? _vm.$t("成功")
                                      : _vm.$t("失敗")
                                  )
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("Pagination", {
                        ref: "pagination",
                        attrs: {
                          pageLimitCount: _vm.pageSize,
                          total: _vm.pageTotal,
                        },
                        on: { pageMethod: _vm.onPageChange },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default pull-right",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("關閉")) + " ")]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("ImportData", {
        ref: "ImportData",
        attrs: { title: _vm.$t("玩家"), checks: _vm.checks },
        on: { importData: _vm.importData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }