var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "alertBox",
        staticClass: "modal fade",
        attrs: { "data-backdrop": "static" },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { "aria-label": "Close", type: "button" },
                  on: { click: _vm.closeAlert },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title text-red" }, [
                _c("i", { staticClass: "fa fa-warning" }),
                _vm._v(" " + _vm._s(_vm.alert.title) + " "),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v(_vm._s(_vm.alert.message)),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.closeAlert },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`確定`)) + " ")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        ref: "confirmBox",
        staticClass: "modal fade",
        attrs: { "data-backdrop": "static" },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { "aria-label": "Close", type: "button" },
                  on: { click: _vm.closeConfirm },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title text-red" }, [
                _c("i", { staticClass: "fa fa-warning" }),
                _vm._v(" " + _vm._s(_vm.confirm.title) + " "),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v(_vm._s(_vm.confirm.message)),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.doConfirm },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`確定`)))]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { type: "button" },
                  on: { click: _vm.closeConfirm },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`關閉`)))]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }