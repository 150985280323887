var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增-修改廠商支付設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "FirmSettingList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("廠商設定")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "FirmPaySettingList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("廠商支付金流管理")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增-修改廠商支付設定 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.showInfo.廠商,
                        expression: "edit.showInfo.廠商",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: _vm.edit.showInfo.廠商 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit.showInfo,
                          "廠商",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.金流設定主檔編號,
                          expression: "edit.金流設定主檔編號",
                        },
                      ],
                      staticClass: "selPaymentType form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "金流設定主檔編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.金流設定List, function (item) {
                      return _c(
                        "option",
                        { key: item.編號, domProps: { value: item.編號 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.金流名稱) +
                              "-" +
                              _vm._s(item.金流代碼) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.金流設定資料
                    ? _c("div", { staticClass: "payStyle" }, [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "金流名稱：" + _vm._s(_vm.金流設定資料.金流名稱)
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "金流代碼：" + _vm._s(_vm.金流設定資料.金流代碼)
                            ),
                          ]),
                          _c("li", [
                            _vm._v("啟停用： "),
                            _vm.金流設定資料.啟用 == 1
                              ? _c("span", [_vm._v("啟用")])
                              : _c("span", { staticClass: "text-red" }, [
                                  _vm._v("停用"),
                                ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.金流帳號編號,
                          expression: "edit.金流帳號編號",
                        },
                      ],
                      staticClass: "selPaymentType form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "金流帳號編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.filter金流帳號List, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.編號,
                          class: item.啟用 == 0 ? "disabled" : "",
                          domProps: { value: item.編號 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.名稱) +
                              "-" +
                              _vm._s(item.金流識別值) +
                              _vm._s(item.啟用 == 0 ? "(帳號停用中)" : "") +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.金流帳號資料
                    ? _c("div", { staticClass: "payStyle" }, [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "名稱：" + _vm._s(_vm.金流帳號資料.名稱) + " "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "金流識別值：" +
                                _vm._s(_vm.金流帳號資料.金流識別值) +
                                " "
                            ),
                          ]),
                          _c("li", [
                            _vm._v("啟停用： "),
                            _vm.金流帳號資料.啟用 == 1
                              ? _c("span", [_vm._v("啟用")])
                              : _c("span", { staticClass: "text-red" }, [
                                  _vm._v("停用"),
                                ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.金流支付設定編號,
                          expression: "edit.金流支付設定編號",
                        },
                      ],
                      staticClass: "selPaymentType form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "金流支付設定編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.filter金流支付List, function (item) {
                      return _c(
                        "option",
                        { key: item.編號, domProps: { value: item.編號 } },
                        [_vm._v(" " + _vm._s(item.支付名稱) + " ")]
                      )
                    }),
                    0
                  ),
                  _vm.上層設定
                    ? _c("div", { staticClass: "payStyle" }, [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "支付名稱：" + _vm._s(_vm.上層設定.支付名稱)
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "支付代碼：" + _vm._s(_vm.上層設定.支付方式代碼)
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "地區：" +
                                _vm._s(_vm.地區列表Key[_vm.上層設定.地區].地區)
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "限制幣別：" +
                                _vm._s(
                                  _vm.地區列表Key[_vm.上層設定.限制幣別].幣別
                                )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "金流類型：" +
                                _vm._s(
                                  _vm.金流類型設定Key[_vm.上層設定.金流類型]
                                    .類型
                                )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "是否開立發票：" +
                                _vm._s(
                                  _vm.上層設定.是否開立發票 == "1" ? "是" : "否"
                                )
                            ),
                          ]),
                          _c("li", [
                            _vm._v("啟停用： "),
                            _vm.上層設定.啟用 == 1
                              ? _c("span", [_vm._v("啟用")])
                              : _c("span", { staticClass: "text-red" }, [
                                  _vm._v("停用"),
                                ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.上層設定 && _vm.上層設定.是否支援支付品號 == 1
                ? _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group margin",
                          staticStyle: { margin: "0px" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.edit.支付品號,
                                expression: "edit.支付品號",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.edit.支付品號 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "支付品號",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("span", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-flat",
                                staticStyle: { width: "200px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.edit.支付品號 = _vm.上層設定.支付品號
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "上層設定：" + _vm._s(_vm.上層設定.支付品號)
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.限制開關,
                      expression: "限制開關",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _vm._m(5),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _c("LimitControler", {
                        ref: "comp",
                        attrs: { 額外限制List: _vm.edit.額外限制 },
                        on: {
                          "update:額外限制List": function ($event) {
                            return _vm.$set(_vm.edit, "額外限制", $event)
                          },
                          "update:額外限制list": function ($event) {
                            return _vm.$set(_vm.edit, "額外限制", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(6),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-10 control-label",
                    staticStyle: { "text-align": "left" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.edit.順序 == null ? 0 : _vm.edit.順序) +
                        " "
                    ),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v("順序請至列表修改"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(7),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流帳號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 支付方式： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 支付品號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 額外限制： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 順序： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }