var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "BANNER")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("BANNER管理列表")]),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "BANNER"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog fa fa-reply",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.Save()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bannerTypes.total > 1,
                        expression: "bannerTypes.total > 1",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.classTypeId,
                              expression: "edit.classTypeId",
                            },
                          ],
                          staticClass: "form-control select-part",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { selected: "", disabled: "" } },
                            [_vm._v("請選擇分類")]
                          ),
                          _vm._l(_vm.bannerTypes.datas, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                attrs: { class_id: item.classId },
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(" " + _vm._s(item.className) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.note,
                          expression: "edit.note",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inputTitle",
                        required: "",
                        placeholder: "自己辨識用，不出現在前端",
                      },
                      domProps: { value: _vm.edit.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "note", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitle" },
                    },
                    [_vm._v(" BN標題： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.bannerTitle,
                          expression: "edit.bannerTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "inputTitle",
                        placeholder: "選填，建議10字內",
                      },
                      domProps: { value: _vm.edit.bannerTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "bannerTitle", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group bannercontent-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "bannercontent" },
                    },
                    [_vm._v(" BN內文： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.bannerContent,
                          expression: "edit.bannerContent",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "bannercontent",
                        placeholder: "選填，建議50字內",
                      },
                      domProps: { value: _vm.edit.bannerContent },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "bannerContent",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      attrs: {
                        type: "file",
                        id: "AdPic",
                        accept: "image/jpeg, image/png, image/gif",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _vm._m(3),
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        staticStyle: { "max-height": "200px" },
                        attrs: {
                          src: _vm.previewImg,
                          draggable: "false",
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group bannerlink-part" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.bannerLink,
                          expression: "edit.bannerLink",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "inputLink", required: "" },
                      domProps: { value: _vm.edit.bannerLink },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "bannerLink", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("label", { staticClass: "col-sm-2 control-label" }),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          staticStyle: {
                            position: "relative",
                            width: "1.5em",
                            height: "1.5em",
                            "vertical-align": "sub",
                            "margin-top": "-.2em",
                            "accent-color": "#3498db",
                            cursor: "pointer",
                          },
                          attrs: { type: "checkbox" },
                          domProps: { checked: !!_vm.edit.isLinkOpen },
                          on: {
                            change: function ($event) {
                              _vm.edit.isLinkOpen =
                                +$event.currentTarget.checked
                            },
                          },
                        }),
                        _vm._v(" 另開視窗 "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.orderNum,
                          expression: "edit.orderNum",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "inputOrderNum" },
                      domProps: { value: _vm.edit.orderNum },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "orderNum",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              ref: "formalStartDate",
                              attrs: { propValue: _vm.startDate },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startDate = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startDate = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(7),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              ref: "formalEndDate",
                              attrs: { propValue: _vm.endDate },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endDate = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endDate = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(9),
                    ]),
                  ]),
                ]),
              ]),
              _vm._m(10),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "AdPic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red" }, [
        _vm._v("※檔案尺寸960×540，小於2Mb，格式JPG、PNG、GIF"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 連結： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 上架時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon fa fa-clock-o" }),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "formalStartTime" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 下架時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon fa fa-clock-o" }),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "formalEndTime" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }