var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pagination pagination-sm no-margin" },
    [
      _c(
        "li",
        {
          on: {
            click: function ($event) {
              return _vm.onPageChange(1)
            },
          },
        },
        [_vm._m(0)]
      ),
      _c(
        "li",
        {
          on: {
            click: function ($event) {
              return _vm.onPageChange("shiftPrev")
            },
          },
        },
        [_vm._m(1)]
      ),
      _vm._l(_vm.pageCount, function (n) {
        return _c(
          "li",
          {
            key: n,
            class: { active: _vm.pageStart + n - 1 == _vm.page },
            on: {
              click: function ($event) {
                return _vm.onPageChange(_vm.pageStart + n - 1)
              },
            },
          },
          [
            _c("a", { attrs: { href: "javascript:void(0)" } }, [
              _vm._v(_vm._s(_vm.pageStart + n - 1)),
            ]),
          ]
        )
      }),
      _c(
        "li",
        {
          on: {
            click: function ($event) {
              return _vm.onPageChange("shiftNext")
            },
          },
        },
        [_vm._m(2)]
      ),
      _c(
        "li",
        {
          on: {
            click: function ($event) {
              return _vm.onPageChange(_vm.pageTotal)
            },
          },
        },
        [_vm._m(3)]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("i", { staticClass: "fa fa-angle-double-left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("i", { staticClass: "fa fa-angle-left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("i", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("i", { staticClass: "fa fa-angle-double-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }