var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AlbumEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("相簿編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("相簿列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("相簿編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "div",
                  {
                    class: [
                      this.PermissionEdit
                        ? "form-group"
                        : "form-group hidestyle",
                    ],
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.名稱,
                            expression: "edit.名稱",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: !this.PermissionEdit,
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                        },
                        domProps: { value: _vm.edit.名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "名稱", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.功能模組,
                            expression: "edit.功能模組",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: !this.PermissionEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "功能模組",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇類別")]
                        ),
                        _c("option", { attrs: { value: "2D美術" } }, [
                          _vm._v("2D美術"),
                        ]),
                        _c("option", { attrs: { value: "3D美術" } }, [
                          _vm._v("3D美術"),
                        ]),
                        _c("option", { attrs: { value: "動作美術" } }, [
                          _vm._v("動作美術"),
                        ]),
                        _c("option", { attrs: { value: "娛樂美術" } }, [
                          _vm._v("娛樂美術"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                this.filetype == "image"
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(" 圖片： "),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "pic" }, [
                          _c("img", {
                            attrs: {
                              draggable: "false",
                              src: _vm.edit.filesUrl,
                            },
                          }),
                        ]),
                        _c("input", {
                          attrs: {
                            accept: "image/*",
                            id: "AnnouncementPic",
                            type: "file",
                            multiple: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event)
                            },
                          },
                        }),
                        _c("div", { staticClass: "help-block" }, [
                          _c("span", { staticClass: "text-red" }, [
                            _vm._v(_vm._s(_vm.picLimit)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row ann-imgChoose mt-10",
                            staticStyle: { margin: "0 auto" },
                          },
                          [
                            _c(
                              "dnd-zone",
                              { attrs: { "vertical-search": "" } },
                              [
                                _c(
                                  "dnd-container",
                                  {
                                    staticClass: "row",
                                    attrs: {
                                      "dnd-model": _vm.edit.相片列表,
                                      "dnd-id": "list-example",
                                    },
                                  },
                                  [
                                    _vm._l(_vm.edit.相片列表, function (card) {
                                      return [
                                        _c(
                                          "dnd-item",
                                          {
                                            key: card.applyId,
                                            attrs: {
                                              "dnd-model": card,
                                              "dnd-id": card.applyId,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-sm-3 col-xs-6 chooseBox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "img-close fa fa-fw fa-close",
                                                  staticStyle: {
                                                    height: "25px",
                                                    width: "25px",
                                                    "line-height": "20px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DeletePic(card)
                                                    },
                                                  },
                                                }),
                                                _c("img", {
                                                  attrs: { src: card.filesUrl },
                                                }),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: card.title,
                                                        expression:
                                                          "card.title",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control selectType",
                                                    staticStyle: {
                                                      "margin-top": "10px",
                                                    },
                                                    attrs: {
                                                      name: card.filesId,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            card,
                                                            "title",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.onFileChangeType(
                                                            $event
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          disabled: "disabled",
                                                          value: "",
                                                          selected: "",
                                                        },
                                                      },
                                                      [_vm._v("請選擇類別")]
                                                    ),
                                                    _vm.edit.功能模組 ==
                                                    "2D美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "角色",
                                                            },
                                                          },
                                                          [_vm._v("角色")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                      "2D美術" ||
                                                    _vm.edit.功能模組 ==
                                                      "3D美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "場景",
                                                            },
                                                          },
                                                          [_vm._v("場景")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "3D美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "武器",
                                                            },
                                                          },
                                                          [_vm._v("武器")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "3D美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "運輸載具",
                                                            },
                                                          },
                                                          [_vm._v("運輸載具")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "3D美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "寫實角色",
                                                            },
                                                          },
                                                          [_vm._v("寫實角色")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "娛樂美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "UI",
                                                            },
                                                          },
                                                          [_vm._v("UI")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "娛樂美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "ICON",
                                                            },
                                                          },
                                                          [_vm._v("ICON")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.edit.功能模組 ==
                                                    "娛樂美術"
                                                      ? _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "角色",
                                                            },
                                                          },
                                                          [_vm._v("角色")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                this.filetype == "video"
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(" 影片："),
                        _c("br"),
                        _vm._v("(來源Youtube) "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#3c8dbc",
                              color: "white",
                              width: "80px",
                              "text-align": "center",
                              display: "block",
                              padding: "8px",
                              border: "0",
                              "border-radius": "7px",
                              "margin-right": "0",
                              "margin-left": "auto",
                              "margin-top": "6px",
                              cursor: "pointer",
                            },
                            attrs: { id: "addNewVideo" },
                            on: { click: _vm.addInputFields },
                          },
                          [_vm._v("新增影片")]
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "youtubeLine" },
                          _vm._l(
                            _vm.edit.相片列表,
                            function (inputFields, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "cardstyle" },
                                [
                                  _c("div", { staticClass: "t1" }, [
                                    _c("div", { staticClass: "n1" }, [
                                      _vm._v("影片名稱"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: inputFields.title,
                                          expression: "inputFields.title",
                                        },
                                      ],
                                      staticClass: "form-control youtube_mame",
                                      attrs: { type: "text" },
                                      domProps: { value: inputFields.title },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            inputFields,
                                            "title",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "t2" }, [
                                    _c("div", { staticClass: "n1" }, [
                                      _vm._v("影片網址"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: inputFields.filesUrl,
                                          expression: "inputFields.filesUrl",
                                        },
                                      ],
                                      staticClass: "form-control youtube_text",
                                      attrs: {
                                        type: "text",
                                        placeholder: "直接填寫youtube網址",
                                      },
                                      domProps: { value: inputFields.filesUrl },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            inputFields,
                                            "filesUrl",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeInputFields(index)
                                      },
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否顯示,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否顯示", $$v)
                              },
                              expression: "edit.是否顯示",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            this.PermissionEdit ? "radio" : "radio hidestyle",
                          ],
                        },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否顯示,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否顯示", $$v)
                              },
                              expression: "edit.是否顯示",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 相簿名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 功能模組： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }