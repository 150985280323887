var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Dashboard" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("會員資料")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _vm.name
          ? _c(
              "li",
              { staticClass: "active" },
              [
                _c("router-link", { attrs: { to: { name: "H5MemberData" } } }, [
                  _vm._v("會員資料"),
                ]),
              ],
              1
            )
          : _c("li", { staticClass: "active" }, [_vm._v("會員資料")]),
        _vm.name
          ? _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.name))])
          : _vm._e(),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary mb-10" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("h2", { staticClass: "text-big text-center mt-10" }, [
            _vm._v("各版本在線人數"),
          ]),
          _c("div", { staticClass: "stats mb-10" }, [
            _c("div", { style: "color:" + _vm.chartData.colors[0] }, [
              _c("h3", { staticClass: "text-bold text-big m-0" }, [
                _vm._v("紅版(APK)"),
              ]),
              _c("p", { staticClass: "text-black text-huge text-center" }, [
                _vm._v("123456"),
              ]),
            ]),
            _c("div", { style: "color:" + _vm.chartData.colors[1] }, [
              _c("h3", { staticClass: "text-bold text-big m-0" }, [
                _vm._v("藍版(Google)"),
              ]),
              _c("p", { staticClass: "text-black text-huge text-center" }, [
                _vm._v("123456"),
              ]),
            ]),
            _c("div", { style: "color:" + _vm.chartData.colors[2] }, [
              _c("h3", { staticClass: "text-bold text-big m-0" }, [
                _vm._v("iOS版"),
              ]),
              _c("p", { staticClass: "text-black text-huge text-center" }, [
                _vm._v("123456"),
              ]),
            ]),
            _c("div", { style: "color:" + _vm.chartData.colors[3] }, [
              _c("h3", { staticClass: "text-bold text-big m-0" }, [
                _vm._v("PC版"),
              ]),
              _c("p", { staticClass: "text-black text-huge text-center" }, [
                _vm._v("123456"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "nav-tabs-custom mb-10" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [_c("highcharts", { attrs: { options: _vm.chartData } })],
          1
        ),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-tabs-custom" }, [
      _c("div", { staticClass: "box-body" }, [
        _c("h2", { staticClass: "text-big text-center mt-10" }, [
          _vm._v("金幣總數"),
        ]),
        _c("div", { staticClass: "stats mb-10 text-muted" }, [
          _c("div", { staticStyle: { "min-width": "16em" } }, [
            _c("h3", { staticClass: "text-bold text-big m-0" }, [
              _vm._v("同時在線上的人"),
            ]),
            _c("p", { staticClass: "text-black text-huge text-center" }, [
              _vm._v("123456"),
            ]),
          ]),
          _c("div", { staticStyle: { "min-width": "16em" } }, [
            _c("h3", { staticClass: "text-bold text-big m-0" }, [
              _vm._v("在線上的活躍玩家星幣總數"),
            ]),
            _c("p", { staticClass: "text-black text-huge text-center" }, [
              _vm._v("123456"),
            ]),
          ]),
          _c("div", { staticStyle: { "min-width": "16em" } }, [
            _c("h3", { staticClass: "text-bold text-big m-0" }, [
              _vm._v("不在線上的活躍玩家星幣總數"),
            ]),
            _c("p", { staticClass: "text-black text-huge text-center" }, [
              _vm._v("123456"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }