var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("儲值歷程")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("報表管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("儲值歷程")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("交易日期："),
              ]),
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propStartDate: _vm.searchData.startTime,
                        propEndDate: _vm.searchData.endTime,
                      },
                      on: {
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "startTime", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "startTime", $event)
                        },
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "endTime", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "endTime", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("交易方式："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.paymentName,
                      expression: "searchData.paymentName",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: { required: "required" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "paymentName",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.paymentOptions, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.value } },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("訂單狀態："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.orderStatus,
                      expression: "searchData.orderStatus",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: { required: "required" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "orderStatus",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "null" } }, [_vm._v("全部")]),
                  _vm._l(
                    _vm.getCurrentPaymentStatusOptions,
                    function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.value } },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("訂單編號："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.orderID,
                    expression: "searchData.orderID",
                  },
                ],
                staticClass: "selPaymentType form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.searchData.orderID },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "orderID", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("玩家識別值："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.userId,
                    expression: "searchData.userId",
                  },
                ],
                staticClass: "selPaymentType form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.searchData.userId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "userId", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.clickSearch },
                },
                [_vm._v("查詢")]
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: { click: _vm.exportOrders },
                },
                [_vm._v("匯出")]
              ),
            ]),
            _c("p", { staticClass: "data-num" }, [
              _c("span", [_vm._v("總筆數：")]),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { id: "total" },
                },
                [_vm._v(_vm._s(_vm.total))]
              ),
              _c("span", [_vm._v("不重複人數：")]),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { id: "people" },
                },
                [_vm._v(_vm._s(_vm.people))]
              ),
              _c("span", [_vm._v("總金額：")]),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { id: "amount" },
                },
                [_vm._v(_vm._s(_vm.amount))]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "table-responsive mt-10" },
            [
              _c(_vm.getCurrentPayment, {
                ref: "currentPayment",
                tag: "component",
                attrs: { orders: _vm.orders },
                on: { checkOrder: _vm.checkOrder },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }