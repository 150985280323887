var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("歷年事紀編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("歷年事紀列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("歷年事紀編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.classTypeId,
                            expression: "edit.classTypeId",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "classTypeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇分類")]
                        ),
                        _vm._l(_vm.gameTypes.datas, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(_vm._s(item.className))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" 圖片： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        attrs: { draggable: "false", src: _vm.edit.filesUrl },
                      }),
                    ]),
                    _c("input", {
                      attrs: {
                        accept: "image/*",
                        id: "Pic",
                        type: "file",
                        multiple: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _c("div", { staticClass: "help-block" }, [
                      _c("span", { staticClass: "text-red" }, [
                        _vm._v("主圖建議尺寸 " + _vm._s(_vm.imageSize) + "。"),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row ann-imgChoose mt-10" },
                      _vm._l(_vm.imgList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-sm-3 col-xs-6 chooseBox",
                          },
                          [
                            _c("div", {
                              staticClass: "img-close fa fa-fw fa-close",
                              on: {
                                click: function ($event) {
                                  return _vm.DeletePic(item)
                                },
                              },
                            }),
                            _c("img", { attrs: { src: item.imgFullFileName } }),
                            _c("div", { staticClass: "radio" }, [
                              _c("input", {
                                attrs: {
                                  type: "radio",
                                  id: "mainImg" + index,
                                  name: "mainImg",
                                },
                                domProps: { checked: item.isMain == 1 },
                                on: {
                                  change: function ($event) {
                                    return _vm.SetPicIsMain(item)
                                  },
                                },
                              }),
                              _vm._v("主圖 "),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "btn-group" },
                      _vm._l(_vm.langTypes, function (item, index) {
                        return _c(
                          "button",
                          {
                            key: index,
                            class: [
                              item.id == _vm.editLang
                                ? "btn btn-default active"
                                : "btn btn-default",
                            ],
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.ChangeLang(item.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.detailTopInfo,
                          expression: "edit.detailTopInfo",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { height: "150px" },
                      attrs: { required: "required", placeholder: "內容...." },
                      domProps: { value: _vm.edit.detailTopInfo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "detailTopInfo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputLink" },
                    },
                    [_vm._v(" 網址： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.contentUrl,
                          expression: "edit.contentUrl",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputLink",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.contentUrl },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "contentUrl", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [_c("DatePicker")],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 語系切換： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "Pic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }