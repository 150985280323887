var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomerService" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("客服處理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("客服處理")]),
      ]),
    ]),
    _c("section", { staticClass: "content customer-service" }, [
      _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _c(
            "li",
            { class: { active: _vm.searchData.module === "聯絡我們" } },
            [
              _c(
                "a",
                {
                  attrs: {
                    "aria-expanded": "true",
                    "data-toggle": "tab",
                    href: "#problem",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList("聯絡我們", "問題類別")
                    },
                  },
                },
                [_vm._v(" 聯絡我們 ")]
              ),
            ]
          ),
          _c("li", { class: { active: _vm.searchData.module === "檢舉" } }, [
            _c(
              "a",
              {
                attrs: {
                  "aria-expanded": "false",
                  "data-toggle": "tab",
                  href: "#report",
                },
                on: {
                  click: function ($event) {
                    return _vm.resetDataList("檢舉", "檢舉類別")
                  },
                },
              },
              [_vm._v(" 檢舉 ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.searchData.module === "聯絡我們" },
            },
            [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("聯絡時間："),
                  ]),
                  _c("div", { staticClass: "input-group date" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propTimePicker: true,
                            propEndDate: _vm.searchData.local.contactEndTime,
                            propStartDate:
                              _vm.searchData.local.contactStartTime,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "contactEndTime",
                                $event
                              )
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "contactEndTime",
                                $event
                              )
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "contactStartTime",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "contactStartTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.reactionType,
                          expression: "searchData.reactionType",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "reactionType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("聯絡我們")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("問題類別"),
                      ]),
                      _c("option", { attrs: { value: "LOBBY" } }, [
                        _vm._v("大廳"),
                      ]),
                      _c("option", { attrs: { value: "SLOT MACHINES" } }, [
                        _vm._v("遊戲館"),
                      ]),
                      _c("option", { attrs: { value: "PURCHASE" } }, [
                        _vm._v("儲值"),
                      ]),
                      _c("option", { attrs: { value: "GIFTS" } }, [
                        _vm._v("獎勵"),
                      ]),
                      _c("option", { attrs: { value: "CONNECTION" } }, [
                        _vm._v("連線"),
                      ]),
                      _c("option", { attrs: { value: "SUGGESTIONS" } }, [
                        _vm._v("建議"),
                      ]),
                      _c("option", { attrs: { value: "OTHERS" } }, [
                        _vm._v("其他"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.platform,
                          expression: "searchData.platform",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("聯絡我們")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("所有平台"),
                      ]),
                      _c("option", { attrs: { value: "HD安卓新藍版" } }, [
                        _vm._v("Android"),
                      ]),
                      _c("option", { attrs: { value: "HDios" } }, [
                        _vm._v("iOS"),
                      ]),
                      _c("option", { attrs: { value: "HD舊安卓紅藍版" } }, [
                        _vm._v("Editor"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.status,
                          expression: "searchData.status",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("聯絡我們")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("處理進度"),
                      ]),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("已回覆"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("未回覆"),
                      ]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("草稿")]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.reactionUId,
                        expression: "searchData.reactionUId",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "玩家UID", type: "text" },
                    domProps: { value: _vm.searchData.reactionUId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "reactionUId",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("聯絡我們")
                        },
                      },
                    },
                    [_vm._v(" 查詢 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.exportOrders },
                    },
                    [_vm._v(" 匯出 ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center min-1000" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(1),
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "13" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "13" } }, [
                                _vm._v("查詢無任何資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.datas.length - index)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.reactionTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.reactionUId))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.define.contactPlatforms[item.platform]
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.phoneModel))]),
                                _c("td", [_vm._v(_vm._s(item.systemVersion))]),
                                _c("td", [_vm._v(_vm._s(item.network))]),
                                _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(_vm._s(item.appVersion)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.define.contactTypes[item.reactionType]
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.replyUName))]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.replyTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { class: { "text-red": item.status === 1 } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.define.serviceStatus[item.status]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkContent(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.searchData.module === "檢舉" },
            },
            [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("檢舉時間："),
                  ]),
                  _c("div", { staticClass: "input-group date" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propTimePicker: true,
                            propEndDate: _vm.searchData.local.reportEndTime,
                            propStartDate: _vm.searchData.local.reportStartTime,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "reportEndTime",
                                $event
                              )
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "reportEndTime",
                                $event
                              )
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "reportStartTime",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "reportStartTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.reactionType,
                          expression: "searchData.reactionType",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "reactionType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("檢舉")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("檢舉類別"),
                      ]),
                      _c("option", { attrs: { value: "AbusiveChat" } }, [
                        _vm._v("辱罵聊天"),
                      ]),
                      _c("option", { attrs: { value: "InappropriateName" } }, [
                        _vm._v("不當名稱"),
                      ]),
                      _c(
                        "option",
                        { attrs: { value: "CheatingBottingHacking" } },
                        [_vm._v("作弊")]
                      ),
                      _c("option", { attrs: { value: "Spam" } }, [
                        _vm._v("濫用廣告"),
                      ]),
                      _c("option", { attrs: { value: "Others" } }, [
                        _vm._v("其他"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.status,
                          expression: "searchData.status",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("檢舉")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("處理進度"),
                      ]),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("已回覆"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("未回覆"),
                      ]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("草稿")]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.reactionUId,
                        expression: "searchData.reactionUId",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "玩家UID", type: "text" },
                    domProps: { value: _vm.searchData.reactionUId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "reactionUId",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("檢舉")
                        },
                      },
                    },
                    [_vm._v(" 查詢 ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center min-1000" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(3),
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "9" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "9" } }, [
                                _vm._v("查詢無任何資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.datas.length - index)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.reactionTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.reactionUId))]),
                                _c("td", [_vm._v(_vm._s(item.sufferUId))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.define.reportTypes[item.reactionType]
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.replyUName))]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.replyTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { class: { "text-red": item.status === 1 } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.define.serviceStatus[item.status]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkContent(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "box text-center no-border no-shadow mt-20" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("聯絡時間")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v(" 玩家UID ")]
      ),
      _c("th", [_vm._v("平台")]),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("手機型號")]),
      _c("th", [_vm._v("系統版本")]),
      _c("th", [_vm._v("網路環境")]),
      _c("th", [_vm._v("APP版本")]),
      _c("th", [_vm._v("問題類別")]),
      _c("th", [_vm._v("客服人員")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("回覆時間")]),
      _c("th", [_vm._v("處理進度")]),
      _c("th", [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("檢舉時間")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v(" 玩家UID ")]
      ),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v(" 被檢舉玩家UID ")]
      ),
      _c("th", [_vm._v("檢舉類別")]),
      _c("th", [_vm._v("客服人員")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("回覆時間")]),
      _c("th", [_vm._v("處理進度")]),
      _c("th", [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }