var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("雙平台額度刪除")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("雙平台額度刪除")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max1000" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "input-group mr-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.phoneNumber,
                    expression: "phoneNumber",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "玩家門號", type: "text" },
                domProps: { value: _vm.phoneNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.phoneNumber = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.AddList()
                    },
                  },
                },
                [_vm._v(" 新增 ")]
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                ref: "ImportPhoneData",
                attrs: { type: "file", id: "ImportPhoneData" },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.importData()
                    },
                  },
                },
                [_vm._v(" 匯入名單 ")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-block btn-info btn-cog",
                  attrs: {
                    href: "/uploads/刪除額度門號範例.xlsx",
                    type: "button",
                    download: "",
                  },
                },
                [_vm._v("範例下載")]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "mt-10 w-60" },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [
                  _vm._v("總數:" + _vm._s(_vm.receiverList.length)),
                ]),
              ]),
              _c("v-client-table", {
                attrs: {
                  data: _vm.receiverList,
                  columns: ["phone", "edit"],
                  options: _vm.receiverListOpts,
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function (props) {
                      return _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.OnReceiverRemove(props.row)
                            },
                          },
                        },
                        [_vm._v(" 移除 ")]
                      )
                    },
                  },
                ]),
              }),
              _c("div", { staticClass: "btns mt-20 fr" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.Del()
                      },
                    },
                  },
                  [_vm._v(" 送出 ")]
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "box-footer text-center no-border" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }