var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲操作說明列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲操作說明管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box" },
            [
              _vm.can新增
                ? _c(
                    "router-link",
                    {
                      staticClass: "btn btn-block btn-warning btn-add mb-10",
                      attrs: {
                        to: {
                          name: "AWH5GameOperationEdit",
                          params: { id: "New" },
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增說明 "),
                    ]
                  )
                : _vm._e(),
              _vm.can清暫存
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-block btn-default btn-add mb-10 mr-10",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clearCatch()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-refresh" }),
                      _vm._v(" 清除Cache "),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "select-group mb-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lang,
                        expression: "lang",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.lang = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.langs, function (lang) {
                    return _c("option", { domProps: { value: lang.id } }, [
                      _vm._v(_vm._s(lang.id + " " + lang.name)),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數: "),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered text-center" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    !_vm.total
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "9" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.content, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(item.version))]),
                            _c("td", [
                              _c("img", {
                                staticStyle: { "max-height": "120px" },
                                attrs: { src: item.image, alt: "" },
                              }),
                            ]),
                            _c("td", [
                              _c("div", {
                                staticClass: "textEllipsis text-left",
                                domProps: { innerHTML: _vm._s(item.content) },
                              }),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _c(
                                "fieldset",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                  attrs: { disabled: !_vm.can修改 },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.sort,
                                        expression: "item.sort",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: {
                                      "padding-right": "0",
                                      "text-align": "center",
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      disabled: item.isStop == 1,
                                    },
                                    domProps: { value: item.sort },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "sort",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: {
                                        type: "button",
                                        disabled: item.isStop == 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateOrder(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrows-v",
                                      }),
                                      _vm._v(" 更新 "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                },
                                [
                                  _vm.can修改
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            to: {
                                              name: "AWH5GameOperationEdit",
                                              params: { id: item.gid },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-pencil",
                                          }),
                                          _vm._v(" 修改 "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.can複製
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning",
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyItem(item.gid)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-copy",
                                          }),
                                          _vm._v(" 複製 "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.can刪除
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-close",
                                          }),
                                          _vm._v(" 刪除 "),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total,
                expression: "total",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
                type2: true,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("版本"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "10%" } }, [
        _vm._v("圖片 (內容第一張圖)"),
      ]),
      _c("th", { staticStyle: { "min-width": "200px", width: "20%" } }, [
        _vm._v("內容"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "3%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "3%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }