var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("交易明細")))]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v(_vm._s(_vm.$t("首頁"))),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.$t("交易明細"))),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("服務名稱")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.serviceID,
                        expression: "searchData.serviceID",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "serviceID",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "", disabled: "" } }, [
                      _vm._v(_vm._s(_vm.$t("請選擇服務名稱"))),
                    ]),
                    _vm._l(_vm.serverList, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.廠商編號,
                          domProps: { value: item.廠商編號 },
                        },
                        [_vm._v(_vm._s(item.名稱) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("金流")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.paymentType,
                        expression: "searchData.paymentType",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "paymentType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _vm._l(_vm.IPSList, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.金流代碼,
                          domProps: { value: item.金流代碼 },
                        },
                        [_vm._v(" " + _vm._s(item.金流名稱) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("訂單狀態")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.orderStatus,
                        expression: "searchData.orderStatus",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "orderStatus",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _c("option", { attrs: { value: "N" } }, [
                      _vm._v(_vm._s(_vm.格式化訂單狀態("N"))),
                    ]),
                    _c("option", { attrs: { value: "S" } }, [
                      _vm._v(_vm._s(_vm.格式化訂單狀態("S"))),
                    ]),
                    _c("option", { attrs: { value: "F" } }, [
                      _vm._v(_vm._s(_vm.格式化訂單狀態("F"))),
                    ]),
                    _c("option", { attrs: { value: "T" } }, [
                      _vm._v(_vm._s(_vm.格式化訂單狀態("T"))),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("配點狀態")) + "："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.givePointResult,
                        expression: "searchData.givePointResult",
                      },
                    ],
                    staticClass: "selPaymentType form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "givePointResult",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-2" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v(_vm._s(_vm.格式化配點狀態(-1))),
                    ]),
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v(_vm._s(_vm.格式化配點狀態(0))),
                    ]),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v(_vm._s(_vm.格式化配點狀態(1))),
                    ]),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v(_vm._s(_vm.格式化配點狀態(2))),
                    ]),
                  ]
                ),
              ]),
              _c("br"),
              _c("div", { staticClass: "textAreas" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.orderID,
                      expression: "searchData.orderID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    title: _vm.$t("請輸入訂單編號"),
                    placeholder: _vm.$t("訂單編號，多筆查詢用,或換行分隔"),
                    disabled:
                      _vm.searchData.vendorOrderID != "" ||
                      _vm.searchData.upstreamVendorOrderID != "",
                  },
                  domProps: { value: _vm.searchData.orderID },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "orderID",
                          $event.target.value.trim()
                        )
                      },
                      _vm.adjustHeight,
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.vendorOrderID,
                      expression: "searchData.vendorOrderID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    title: _vm.$t("請輸入金流訂單編號"),
                    placeholder: _vm.$t("金流訂單編號，多筆查詢用,或換行分隔"),
                    disabled:
                      _vm.searchData.orderID != "" ||
                      _vm.searchData.upstreamVendorOrderID != "",
                  },
                  domProps: { value: _vm.searchData.vendorOrderID },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "vendorOrderID",
                          $event.target.value.trim()
                        )
                      },
                      _vm.adjustHeight,
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.upstreamVendorOrderID,
                      expression: "searchData.upstreamVendorOrderID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    title: _vm.$t("請輸入廠商訂單編號"),
                    placeholder: _vm.$t("廠商訂單編號，多筆查詢用,或換行分隔"),
                    disabled:
                      _vm.searchData.orderID != "" ||
                      _vm.searchData.vendorOrderID != "",
                  },
                  domProps: { value: _vm.searchData.upstreamVendorOrderID },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "upstreamVendorOrderID",
                          $event.target.value.trim()
                        )
                      },
                      _vm.adjustHeight,
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.userId,
                      expression: "searchData.userId",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("玩家識別值"),
                    placeholder: _vm.$t("玩家識別值"),
                  },
                  domProps: { value: _vm.searchData.userId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "userId",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.platformId,
                      expression: "searchData.platformId",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("平台識別值"),
                    placeholder: _vm.$t("請輸入平台識別值"),
                  },
                  domProps: { value: _vm.searchData.platformId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "platformId",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.paymentDetail,
                      expression: "searchData.paymentDetail",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("請輸入儲值類型"),
                    placeholder: _vm.$t("請輸入儲值類型"),
                  },
                  domProps: { value: _vm.searchData.paymentDetail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "paymentDetail",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.InvoiceNumber,
                      expression: "searchData.InvoiceNumber",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("請輸入發票號碼"),
                    placeholder: _vm.$t("請輸入發票號碼"),
                  },
                  domProps: { value: _vm.searchData.InvoiceNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "InvoiceNumber",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.creditCardNo,
                      expression: "searchData.creditCardNo",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("請輸入信用卡卡號"),
                    placeholder: _vm.$t("請輸入信用卡卡號"),
                    disabled: _vm.searchData.paymentType != "ECPay",
                  },
                  domProps: { value: _vm.searchData.creditCardNo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "creditCardNo",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.productCode,
                      expression: "searchData.productCode",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("請輸入商品代碼"),
                    placeholder: _vm.$t("請輸入商品代碼"),
                    disabled: _vm.searchData.paymentType != "ECPay",
                  },
                  domProps: { value: _vm.searchData.productCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "productCode",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.序號,
                      expression: "searchData.序號",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  attrs: {
                    type: "text",
                    title: _vm.$t("請輸入虛擬帳號"),
                    placeholder: _vm.$t("請輸入虛擬帳號"),
                    disabled: _vm.searchData.paymentType != "CCat",
                  },
                  domProps: { value: _vm.searchData.序號 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "序號",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("br"),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("查詢日期")) + "："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DateRangePicker", {
                        ref: "dateRangePicker",
                        attrs: {
                          propStartDate: _vm.searchData.startTime,
                          propEndDate: _vm.searchData.endTime,
                        },
                        on: {
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "startTime", $event)
                          },
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "endTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onPageChange(1)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: !this.lastQueryCommand },
                    attrs: { type: "button" },
                    on: { click: _vm.exportReportBtnClick },
                  },
                  [_vm._v(" 匯出查詢結果 ")]
                ),
              ]),
              _c("hr"),
              _vm.isTEST.isDev == "development"
                ? _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-info mb-5",
                        on: {
                          click: function ($event) {
                            _vm.isTEST.isOpen = !_vm.isTEST.isOpen
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("測試用")) +
                            " (" +
                            _vm._s(_vm.$t("訂單修改狀態")) +
                            ")"
                        ),
                      ]
                    ),
                    _vm.isTEST.isOpen
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.isTEST.serviceID,
                                expression: "isTEST.serviceID",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "selPaymentType form-control mr-5",
                            attrs: {
                              type: "text",
                              width: "200",
                              placeholder: _vm.$t("請輸入服務編號"),
                            },
                            domProps: { value: _vm.isTEST.serviceID },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.isTEST,
                                  "serviceID",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.isTEST.orderID,
                                expression: "isTEST.orderID",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "selPaymentType form-control mr-5",
                            staticStyle: { width: "180px" },
                            attrs: {
                              type: "text",
                              width: "200",
                              placeholder: _vm.$t("請輸入訂單編號"),
                            },
                            domProps: { value: _vm.isTEST.orderID },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.isTEST,
                                  "orderID",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.isTEST.orderStatus,
                                expression: "isTEST.orderStatus",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "selPaymentType form-control mr-5",
                            staticStyle: { width: "180px" },
                            attrs: {
                              type: "text",
                              width: "200",
                              placeholder: _vm.$t("請輸入訂單狀態") + " ex: F",
                            },
                            domProps: { value: _vm.isTEST.orderStatus },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.isTEST,
                                  "orderStatus",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.transactionInfoUpdate(
                                    "",
                                    _vm.isTEST
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("修改訂單狀態")) + " ")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-bordered table-condensed table min-800",
                },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", [_vm._v(_vm._s(_vm.$t("編號")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("使用者資訊")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("其他資訊")))]),
                        _c("th", { staticStyle: { width: "280px" } }, [
                          _vm._v(_vm._s(_vm.$t("金額"))),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("狀態")))]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("時間")))]),
                        _vm.$user.hasPermission(
                          "API_QueryOrderList_ShowCreditCardNum"
                        )
                          ? _c("th", [_vm._v(_vm._s(_vm.$t("信用卡卡號")))])
                          : _vm._e(),
                        _c("th", [_vm._v(_vm._s(_vm.$t("功能")))]),
                      ]),
                      _vm.list.length > 0
                        ? _vm._l(_vm.list, function (item) {
                            return _c(
                              "tr",
                              {
                                key: item.訂單編號,
                                staticClass: "text-center",
                              },
                              [
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("訂單編號")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.訂單編號 ? item.訂單編號 : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("金流訂單編號")) +
                                              "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.廠商訂單編號
                                              ? item.廠商訂單編號
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("廠商訂單編號")) +
                                              "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.上游廠商訂單編號
                                              ? item.上游廠商訂單編號
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("玩家暱稱")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.玩家暱稱 ? item.玩家暱稱 : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("玩家識別值")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.玩家識別值
                                              ? item.玩家識別值
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("平台識別值")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.平台識別 ? item.平台識別 : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("使用者IP")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.使用者IP ? item.使用者IP : "-"
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("交易方式")) + "："
                                          ),
                                        ]),
                                        _vm._v(_vm._s(item.交易名稱) + " "),
                                        item.交易名稱 === null
                                          ? _c(
                                              "span",
                                              { staticClass: "text-red" },
                                              [_vm._v(_vm._s(item.交易方式))]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("儲值類型")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.儲值類型 ? item.儲值類型 : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("發票號碼")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.發票號碼 ? item.發票號碼 : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("商品代碼")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(_vm.格式化商品代碼(item)) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.$t("幣別")) + "："),
                                        ]),
                                        _vm._v(_vm._s(item.幣別) + " "),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("原始金額")) + "："
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(item.原始金額) + " "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        (item.訂單狀態 == "N" ||
                                          item.訂單狀態 == "F") &&
                                        item.儲值類型 == "CCatPay" &&
                                        !item.是否調整訂單金額
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                on: {
                                                  click: function ($event) {
                                                    item.是否調整訂單金額 = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("金額調整")) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.是否調整訂單金額
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                staticStyle: {
                                                  "min-width": "240px",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        item.調整後訂單金額,
                                                      expression:
                                                        "item.調整後訂單金額",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "number" },
                                                  domProps: {
                                                    value: item.調整後訂單金額,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        item,
                                                        "調整後訂單金額",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.transactionInfoUpdate(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.$t("確認")) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger",
                                                    on: {
                                                      click: function ($event) {
                                                        item.是否調整訂單金額 = false
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.$t("取消")) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("交易金額")) + "："
                                          ),
                                        ]),
                                        _vm._v(_vm._s(item.交易金額) + " "),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("配點金額")) + "："
                                          ),
                                        ]),
                                        _vm._v(_vm._s(item.配點金額) + " "),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("訂單狀態")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            _vm.格式化訂單狀態(item.訂單狀態)
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("通知配點狀態")) +
                                              "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            _vm.格式化配點狀態(
                                              item.通知配點狀態
                                            )
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("退貨YN")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            _vm.格式化退貨YN(item.退貨YN)
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "table-card-box text-left" },
                                    [
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("交易時間")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.交易時間
                                              ? _vm.$utils.formatTime(
                                                  item.交易時間,
                                                  "YYYY/​MM/DD HH:mm:ss"
                                                )
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("配點時間")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.配點時間
                                              ? _vm.$utils.formatTime(
                                                  item.配點時間,
                                                  "YYYY/​MM/DD HH:mm:ss"
                                                )
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-10" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("建立日期")) + "："
                                          ),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            item.建立日期
                                              ? _vm.$utils.formatTime(
                                                  item.建立日期,
                                                  "YYYY/​MM/DD HH:mm:ss"
                                                )
                                              : "-"
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _vm.$user.hasPermission(
                                  "API_QueryOrderList_ShowCreditCardNum"
                                )
                                  ? _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(item.信用卡卡號) + " "),
                                    ])
                                  : _vm._e(),
                                _c("td", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        gap: "3px",
                                        "flex-wrap": "wrap",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      /*item.退貨功能 &&*/ item.訂單狀態 == "S"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-danger",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openRefundForm(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("退貨")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openDetailForm(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("明細")) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.交易方式 == "CCat",
                                              expression:
                                                "item.交易方式 == 'CCat'",
                                            },
                                          ],
                                          staticClass: "btn btn-warning",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openCCatDetailForm(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("繳費資訊")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      item.顯示重驗按鈕
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-info",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkOrder(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("重驗證")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.訂單狀態 == "F"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-danger",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.transactionInfoReset(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("訂單重置")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          })
                        : [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: { colspan: "20" },
                                },
                                [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.total > 0 &&
                    _vm.lastQueryCommand &&
                    _vm.lastQueryCommand.cmd &&
                    _vm.lastQueryCommand.cmd.MultipleWay == 0,
                  expression:
                    "total > 0 && lastQueryCommand && lastQueryCommand.cmd && lastQueryCommand.cmd.MultipleWay == 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))]),
          ]),
        ]),
      ]),
      _c("RefoundForm", {
        ref: "refoundForm",
        on: {
          更新退貨資料: function ($event) {
            return _vm.queryAll(_vm.lastQueryCommand.cmd)
          },
        },
      }),
      _c("DetailForm", { ref: "detailForm" }),
      _c("PopForm", {
        ref: "addForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 調整下載時間區間 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "input-group date" }, [
                    _c("div", { staticClass: "input-group-addon" }, [
                      _c("i", { staticClass: "fa fa-calendar" }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          ref: "batchFormDateRangePicker",
                          attrs: {
                            propsIn: "format2",
                            propStartDate: _vm.batchData.startTime,
                            propEndDate: _vm.batchData.endTime,
                          },
                          on: {
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.batchData,
                                "startTime",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.batchData,
                                "startTime",
                                $event
                              )
                            },
                            "update:propEndDate": function ($event) {
                              return _vm.$set(_vm.batchData, "endTime", $event)
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(_vm.batchData, "endTime", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" *最長一個月 "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.exportFormBtnClick()
                      },
                    },
                  },
                  [_vm._v("下載")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }