var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("首頁")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: { name: "AWH5WebHome" } } }, [
              _vm._v("官網管理"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("首頁")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "nav-tabs-custom" }, [
        _c(
          "ul",
          { staticClass: "nav nav-tabs" },
          [
            _vm._l(_vm.tabs, function (item, index) {
              return [
                item.permission
                  ? _c(
                      "li",
                      {
                        key: index,
                        class: { active: item.component == _vm.tab },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { params: { tab: item.component } } },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "tab-content" },
          [_c("KeepAlive", [_c(_vm.tab, { tag: "component" })], 1)],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }