var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "PopForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "800px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c("div", { staticClass: "box box-info" }, [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", { staticClass: "box-title" }, [_vm._v("匯入發票")]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  disabled: this.uploadST == 1 || this.uploadST == 2,
                },
                on: { click: _vm.Close },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("請選擇要匯入的檔案")]),
                _c("input", {
                  ref: "importData",
                  attrs: {
                    type: "file",
                    accept: ".xls,.xlsx",
                    disabled: _vm.uploadST != 0,
                  },
                }),
                _c("span", { staticClass: "warning" }, [
                  _vm._v("資料中一但有其中一筆有問題，則整批失敗"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-block btn-success btn-cog",
                  staticStyle: {
                    display: "inline-block",
                    width: "120px",
                    "margin-right": "15px",
                  },
                  attrs: {
                    href: "/uploads/發票匯入範例.xlsx",
                    type: "button",
                    download: "",
                  },
                },
                [_vm._v("發票匯入範例")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: { "margin-right": "15px" },
                  attrs: {
                    type: "button",
                    disabled: this.uploadST == 1 || this.uploadST == 2,
                  },
                  on: { click: _vm.Close },
                },
                [_vm._v("關閉")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button", disabled: _vm.uploadST != 0 },
                  on: { click: _vm.readExcel },
                },
                [_vm._v("確定匯入")]
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }