var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormDialog",
        {
          ref: "rebateDialog",
          attrs: {
            title: _vm.result
              ? _vm.$t("回饋金配發結果")
              : _vm.$t(_vm.player ? "配發回饋金" : "批次配發回饋金"),
          },
          on: { submit: _vm.submit },
          scopedSlots: _vm._u(
            [
              _vm.result
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button", "data-dismiss": "modal" },
                          },
                          [_vm._v(_vm._s(_vm.$t("確定")))]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          !_vm.result
            ? _c("table", { staticClass: "table table-bordered" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t(_vm.player ? "玩家暱稱" : "匯入名單"))
                      ),
                    ]),
                    _c("td", [
                      _vm.player
                        ? _c("span", [_vm._v(_vm._s(_vm.data.暱稱[0]))])
                        : _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary mt-0",
                                staticStyle: { "vertical-align": "baseline" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.importShow()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-file-alt" }),
                                _vm._v(" " + _vm._s(_vm.$t("匯入名單")) + " "),
                              ]
                            ),
                            _c("span", { staticClass: "ml-10" }, [
                              _vm._v(_vm._s(_vm.importFileName)),
                            ]),
                            _vm.pagedTable.length > 0
                              ? _c("div", { staticClass: "mt-10" }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-data table-bordered",
                                    },
                                    [
                                      _c(
                                        "tbody",
                                        _vm._l(_vm.pagedTable, function (row) {
                                          return _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "line-height": "1.2",
                                              },
                                            },
                                            [
                                              _vm._l(row, function (col) {
                                                return [
                                                  _c("th", [
                                                    _vm._v(_vm._s(col.暱稱)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(col.回饋金)),
                                                  ]),
                                                ]
                                              }),
                                            ],
                                            2
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.pageTotal > _vm.pageSize,
                                          expression: "pageTotal > pageSize",
                                        },
                                      ],
                                      staticClass: "text-center mt-5",
                                    },
                                    [
                                      _c("Pagination", {
                                        ref: "pagination",
                                        attrs: {
                                          pageLimitCount: _vm.pageSize,
                                          total: _vm.pageTotal,
                                        },
                                        on: { pageMethod: _vm.onPageChange },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ]),
                  _vm.player
                    ? _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("配發回饋金")))]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.data.回饋金[0],
                                expression: "data.回饋金[0]",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number", min: "0", required: "" },
                            domProps: { value: _vm.data.回饋金[0] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.data.回饋金,
                                  0,
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.分類,
                              expression: "data.分類",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data,
                                "分類",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                selected: "",
                                disabled: "",
                                hidden: "",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                          ),
                          _vm._l(_vm.types, function (name, id) {
                            return _c(
                              "option",
                              { key: id, domProps: { value: name } },
                              [_vm._v(_vm._s(name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("配發原因")))]),
                    _c("td", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.data.內容,
                            expression: "data.內容",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "",
                          rows: "3",
                          required: "",
                        },
                        domProps: { value: _vm.data.內容 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data,
                              "內容",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ])
            : _c("div", [
                _vm.player
                  ? _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            _vm.result.成功.length > 0 ? "配點成功" : "配點失敗"
                          )
                        )
                      ),
                    ])
                  : _c("div", { staticClass: "nav-tabs tab-warning" }, [
                      _c("ul", { staticClass: "nav nav-tabs" }, [
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.importList == _vm.result.成功,
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.importList = _vm.result.成功
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("成功")) +
                                    ": " +
                                    _vm._s(_vm.result.成功.length)
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.importList == _vm.result.失敗,
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.importList = _vm.result.失敗
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("失敗")) +
                                    ": " +
                                    _vm._s(_vm.result.失敗.length)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "tab-content" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-data table-bordered mt-10 mb-10",
                          },
                          [
                            _c(
                              "tbody",
                              [
                                _vm.playerTable.length == 0
                                  ? _c("tr", [
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("無資料"))),
                                      ]),
                                    ])
                                  : _vm._l(_vm.pagedTable, function (row) {
                                      return _c(
                                        "tr",
                                        {
                                          staticStyle: { "line-height": "1.2" },
                                        },
                                        [
                                          _vm._l(row, function (col) {
                                            return [
                                              _c("th", [
                                                _vm._v(_vm._s(col.暱稱)),
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(col.數量)),
                                              ]),
                                            ]
                                          }),
                                        ],
                                        2
                                      )
                                    }),
                              ],
                              2
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.playerTable.length > _vm.pageSize,
                                expression: "playerTable.length > pageSize",
                              },
                            ],
                            staticClass: "text-center mt-5 mb-5",
                          },
                          [
                            _c("Pagination", {
                              ref: "pagination",
                              attrs: {
                                pageLimitCount: _vm.pageSize,
                                total: _vm.playerTable.length,
                              },
                              on: { pageMethod: _vm.onPageChange },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
              ]),
        ]
      ),
      _c("ImportData", {
        ref: "ImportData",
        attrs: { title: "回饋金配發", checks: _vm.checks },
        on: { importData: _vm.importData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }