var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動總覽")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("活動總覽")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.clickSearch()
                },
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                  attrs: {
                    to: {
                      name: "XsgGameBannerEdit",
                      params: { id: "New", searchData: _vm.searchData },
                    },
                  },
                },
                [_vm._v("新增")]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.類別,
                        expression: "searchData.類別",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "類別",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.clickSearch()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.bannerType, function (name, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: name } },
                      [_vm._v(_vm._s(name))]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", [_vm._v("資料總筆數：" + _vm._s(_vm.total))]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered AdListTable" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v(_vm._s(_vm.$t("查詢中"))),
                          ]),
                        ])
                      : _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v(_vm._s(_vm.$t(_vm.message))),
                          ]),
                        ])
                      : _vm.total === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v(_vm._s(_vm.$t("查無紀錄"))),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(index + 1))]),
                            _c("td", [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                  style: !_vm.can修改 ? "opacity:.5;" : false,
                                  attrs: { disabled: !_vm.can修改 },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.狀態,
                                        expression: "item.狀態",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": 1,
                                      "false-value": 0,
                                      id: `check${index}`,
                                      name: "ckbx-style-8",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.狀態)
                                        ? _vm._i(item.狀態, null) > -1
                                        : _vm._q(item.狀態, 1),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.狀態,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 1 : 0
                                          if (Array.isArray($$a)) {
                                            var $$v = _vm._n(null),
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "狀態",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "狀態",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "狀態", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.changeStatus(item)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: `check${index}` },
                                  }),
                                ]
                              ),
                              _c("p", [
                                _vm._v(_vm._s(item.狀態 ? "開啟" : "關閉")),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.標題))]),
                            _c("td", [
                              _c("div", { staticClass: "pic" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.getImageUrl(item, "1"),
                                    draggable: "false",
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.isTop ? "是" : ""))]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.顯示開始時間))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.顯示結束時間))),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _c(
                                "fieldset",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                  attrs: { disabled: !_vm.can修改 },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: item.排序,
                                        expression: "item.排序",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control p-5",
                                    staticStyle: {
                                      "padding-right": "0",
                                      "text-align": "center",
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      max: "",
                                      disabled: !item.狀態,
                                    },
                                    domProps: { value: item.排序 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "排序",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning btn-add",
                                      attrs: {
                                        type: "button",
                                        disabled: !item.狀態,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatus(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrows-v",
                                      }),
                                      _vm._v(" 更新 "),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "5px",
                                  },
                                },
                                [
                                  _vm.can修改
                                    ? _c(
                                        "router-link",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            to: {
                                              name: "XsgGameBannerEdit",
                                              params: {
                                                id: item.公告ID,
                                                searchData: _vm.searchData,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-pencil",
                                          }),
                                          _vm._v(" 修改 "),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.can刪除
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-close",
                                          }),
                                          _vm._v(" 刪除 "),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("pre", [_vm._v(_vm._s(_vm.datas))]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "135px", width: "10%" } }, [
        _vm._v("標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("縮圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("置頂"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("顯示開始時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("顯示結束時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }