var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("周邊景點")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("在地饗宴")]),
        _c("li", { staticClass: "active" }, [_vm._v("周邊景點")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增圖片 ")]
            ),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.banners.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "13%" } },
                        [_vm._v("分類")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "5%" } },
                        [_vm._v("主圖")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "13%" } },
                        [_vm._v("名稱")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v("建立日期")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.projectId == "omp_fantacity" ? "正式" : ""
                              ) +
                              "狀態 "
                          ),
                        ]
                      ),
                      _vm.projectId == "omp_fantacity"
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [_vm._v(" 測試狀態 ")]
                          )
                        : _vm._e(),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "5%" },
                        },
                        [_vm._v("排序")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "5%" },
                        },
                        [_vm._v(" 功能 ")]
                      ),
                    ]),
                    _vm.banners.total > 0
                      ? _vm._l(_vm.banners.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [_vm._v(_vm._s(item.className))]),
                            _c("td", [
                              _c("div", { staticClass: "pic jqimgFill" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.filesUrl,
                                    draggable: "false",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.bannerTitle))]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.GetStatus(item, false))),
                            ]),
                            _vm.projectId == "omp_fantacity"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.GetStatus(item, true)) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "td-sort text-nowrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.orderNum,
                                    expression: "item.orderNum",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  disabled: item.isStop == 1,
                                },
                                domProps: { value: item.orderNum },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "orderNum",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              item.isStop != 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateBannerOd(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 更新 ")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(0)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.banners.total > 0,
                expression: "banners.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.banners.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }