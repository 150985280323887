var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "TopupRecordList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("儲值查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("儲值查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.local.endTime,
                        propStartDate: _vm.searchData.local.startTime,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "endTime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "endTime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "startTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "startTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.userId,
                    expression: "searchData.userId",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "玩家UID", type: "text" },
                domProps: { value: _vm.searchData.userId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "userId",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.orderId,
                    expression: "searchData.orderId",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "訂單編號", type: "text" },
                domProps: { value: _vm.searchData.orderId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "orderId",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.productType,
                      expression: "searchData.productType",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "productType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("儲值項目")]),
                  _vm._l(_vm.products, function (product, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: product.productType } },
                      [_vm._v(" " + _vm._s(product.productName) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.platform,
                      expression: "searchData.platform",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "platform",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "Android" } }, [
                    _vm._v("Android"),
                  ]),
                  _c("option", { attrs: { value: "iOS" } }, [_vm._v("iOS")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.exportOrders },
                },
                [_vm._v(" 匯出 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-warning hide",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goEdit()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-cog" }),
                  _vm._v(" 儲值品項管理 "),
                ]
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", [
                _vm._v(
                  "總儲值(USD)：" +
                    _vm._s(_vm._f("currencyFormat")(_vm.totalAmount))
                ),
              ]),
              _c("p", [
                _vm._v(
                  "總儲值(TWD)：" +
                    _vm._s(_vm._f("currencyFormat")(_vm.totalAmount * 30))
                ),
              ]),
              _c("p", [_vm._v("總筆數：" + _vm._s(_vm.total))]),
              _c("p", [_vm._v("不重複人數：" + _vm._s(_vm.distinctCount))]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-bordered text-center min-1000" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "7" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "7" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.配點時間,
                                      "YYYY/MM/DD HH:mm:ss"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(item.id)),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(item.交易ID)),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.searchData.platform))]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(item.產品類別)),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(item.產品項目)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("currencyFormat")(item.點數))
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("交易時間")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v("玩家UID")]
      ),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("訂單編號")]),
      _c("th", [_vm._v("儲值平台")]),
      _c("th", [_vm._v("儲值項目")]),
      _c("th", [_vm._v("儲值品項")]),
      _c("th", [_vm._v("儲值金額")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }