var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("頻道管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("頻道管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("頻道編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("頻道編輯"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.頻道連結,
                          expression: "edit.頻道連結",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputLink",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.頻道連結 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "頻道連結", $event.target.value)
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !this.isEdit,
                            expression: "!this.isEdit",
                          },
                        ],
                        staticStyle: { color: "#fd3e3e" },
                      },
                      [_vm._v("*請填入帶@的頻道網址 https://www.youtube.com/@")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" 頻道名稱： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "span",
                      {
                        staticClass: "form-control",
                        staticStyle: { "background-color": "#e5e5e5" },
                      },
                      [_vm._v(_vm._s(_vm.edit.頻道名稱))]
                    ),
                    _c("span", { staticStyle: { color: "gray" } }, [
                      _vm._v("*送出後自動填入"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" channel ID： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "span",
                      {
                        staticClass: "form-control",
                        staticStyle: { "background-color": "#e5e5e5" },
                      },
                      [_vm._v(_vm._s(_vm.edit.channelId))]
                    ),
                    _c("span", { staticStyle: { color: "gray" } }, [
                      _vm._v("*送出後自動填入"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.巡邏人,
                              expression: "edit.巡邏人",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectUnit" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "巡邏人",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" },
                            },
                            [_vm._v("請選擇巡邏人")]
                          ),
                          _c("option", { attrs: { value: "尚未派發" } }, [
                            _vm._v("尚未派發"),
                          ]),
                          _vm._l(_vm.channelMan.result, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.人員名稱 },
                              },
                              [_vm._v(_vm._s(item.人員名稱))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.留言板狀態,
                              expression: "edit.留言板狀態",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectUnit" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "留言板狀態",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇分類")]
                          ),
                          _c("option", { key: "0", attrs: { value: "0" } }, [
                            _vm._v("關閉"),
                          ]),
                          _c("option", { key: "1", attrs: { value: "1" } }, [
                            _vm._v("開放"),
                          ]),
                          _c("option", { key: "2", attrs: { value: "2" } }, [
                            _vm._v("部分關閉"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-10",
                      staticStyle: { width: "120px" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.影片下架數,
                            expression: "edit.影片下架數",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", id: "amountInput", min: "0" },
                        domProps: { value: _vm.edit.影片下架數 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "影片下架數",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" 玩家資訊： "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _vm._l(_vm.edit.玩家資訊, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "playerbox" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-sm-2 control-label",
                                  attrs: { for: "inputtel" },
                                },
                                [_vm._v("電話：")]
                              ),
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.玩家角色電話,
                                      expression: "item.玩家角色電話",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "inputtel",
                                    required: "required",
                                    type: "text",
                                  },
                                  domProps: { value: item.玩家角色電話 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "玩家角色電話",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "col-sm-2 control-label",
                                  attrs: { for: "inputTitleID" },
                                },
                                [_vm._v("ID：")]
                              ),
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.玩家角色名稱,
                                      expression: "item.玩家角色名稱",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "inputTitleID",
                                    required: "required",
                                    type: "text",
                                  },
                                  domProps: { value: item.玩家角色名稱 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "玩家角色名稱",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "form-group text-red" }, [
                              _vm._v(" 使用頓號(、)分隔遊戲ID "),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "padding-right": "100px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "min-width": "160px",
                                      "padding-left": "18px",
                                    },
                                  },
                                  [_vm._v("版權使用規範簡訊：")]
                                ),
                                _c("div", { staticClass: "col-sm-10" }, [
                                  _c(
                                    "div",
                                    { staticClass: "radio" },
                                    [
                                      _c("iCheckRedioButton", {
                                        attrs: {
                                          checkValue: "0",
                                          message: "未寄送",
                                          name: `radioMode${index + 1}`,
                                        },
                                        model: {
                                          value: item.版權使用規範簡訊狀態,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "版權使用規範簡訊狀態",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.版權使用規範簡訊狀態",
                                        },
                                      }),
                                      _c("iCheckRedioButton", {
                                        attrs: {
                                          checkValue: "1",
                                          message: "已寄送",
                                          name: `radioMode${index + 1}`,
                                        },
                                        model: {
                                          value: item.版權使用規範簡訊狀態,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "版權使用規範簡訊狀態",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.版權使用規範簡訊狀態",
                                        },
                                      }),
                                      _c("iCheckRedioButton", {
                                        attrs: {
                                          checkValue: "2",
                                          message: "發送失敗",
                                          name: `radioMode${index + 1}`,
                                        },
                                        model: {
                                          value: item.版權使用規範簡訊狀態,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "版權使用規範簡訊狀態",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.版權使用規範簡訊狀態",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeInputFields(index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash-o",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" 刪除 "),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-warning",
                              staticStyle: { width: "100px" },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.addInputFields()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" 新增資訊 "),
                            ]
                          ),
                          _c("span", { staticStyle: { color: "gray" } }, [
                            _vm._v("*相同電話號碼的遊戲ID寫在一起"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitleMemo" },
                    },
                    [_vm._v("備註：")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.備註,
                          expression: "edit.備註",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { height: "100px" },
                      attrs: {
                        id: "inputTitleMemo",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.備註 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "備註", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-10",
                      staticStyle: { width: "120px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ckbx-style-8 ckbx toggleInput" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.檢索狀態,
                                expression: "edit.檢索狀態",
                              },
                            ],
                            attrs: {
                              "false-value": 0,
                              id: "state",
                              "true-value": 1,
                              name: "ckbx-style-8",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.edit.檢索狀態)
                                ? _vm._i(_vm.edit.檢索狀態, null) > -1
                                : _vm._q(_vm.edit.檢索狀態, 1),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.edit.檢索狀態,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? 1 : 0
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.edit,
                                        "檢索狀態",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.edit,
                                        "檢索狀態",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.edit, "檢索狀態", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "state" } }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v("送出儲存")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 頻道URL： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 巡邏人： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 留言板狀態： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 影片下架數： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 檢索狀態： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }