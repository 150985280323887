var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "g-info",
        staticStyle: { cursor: "pointer" },
        on: {
          click: function ($event) {
            return _vm.goContent(_vm.item, "slotClientGameHallList")
          },
        },
      },
      [
        _c("div", { staticClass: "g-photo" }, [
          _c("img", { attrs: { src: _vm.item.圖示, alt: "" } }),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "g-name" }, [
            _vm._v(_vm._s(_vm.item.遊戲名稱)),
          ]),
          _c("div", [
            _c("p", { staticClass: "g-company" }, [
              _vm._v(_vm._s(_vm.item.開發商名稱)),
            ]),
            _vm.$parent.searchData.排序條件 == 1
              ? _c("p", { staticClass: "g-time" }, [
                  _vm._v(
                    _vm._s(_vm._f("dateTimeFormat")(_vm.item.更新時間)) + "更新"
                  ),
                ])
              : _vm._e(),
            _vm.$parent.searchData.排序條件 == 2
              ? _c("p", { staticClass: "g-time" }, [
                  _vm._v(
                    _vm._s(_vm._f("dateTimeFormat")(_vm.item.建立時間)) + "建立"
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "g-link" }, [
      _c("div", { staticClass: "left" }, [
        _vm.item.android網址 != ""
          ? _c(
              "a",
              {
                staticClass: "btn btn-mobile mr-5",
                attrs: { href: _vm.item.android網址, target: "_blank" },
              },
              [_vm._v("Android")]
            )
          : _vm._e(),
        _vm.item.ioS網址 != ""
          ? _c(
              "a",
              {
                staticClass: "btn btn-mobile",
                attrs: { href: _vm.item.ioS網址, target: "_blank" },
              },
              [_vm._v("iOS")]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "a",
          {
            attrs: { href: "javaScript:;", title: "遊戲介紹" },
            on: {
              click: function ($event) {
                return _vm.goContent(_vm.item, "slotClientGameHallList")
              },
            },
          },
          [_c("i", { staticClass: "fa fa-gamepad" })]
        ),
        _c(
          "a",
          {
            attrs: { href: "javaScript:;", title: "活動機制" },
            on: {
              click: function ($event) {
                return _vm.goContent(_vm.item, "slotClientGameActivity")
              },
            },
          },
          [_c("i", { staticClass: "fa fa-gears" })]
        ),
        _c(
          "a",
          {
            attrs: { href: "javaScript:;", title: "集成分析" },
            on: {
              click: function ($event) {
                return _vm.goContent(_vm.item, "slotAnalyzeList")
              },
            },
          },
          [_c("i", { staticClass: "fa fa-chart-pie" })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }