var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("捐款徵信編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("捐款徵信列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("捐款徵信編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.classTypeId,
                              expression: "edit.classTypeId",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "classTypeId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.OnTypeChnage()
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇分類")]
                          ),
                          _vm._l(_vm.classTypes.datas, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(_vm._s(item.className))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.year,
                              expression: "year",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.year = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.yearOptions, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.id } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm.classId == "donationlist"
                    ? [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 control-label",
                              attrs: { for: "inputFile" },
                            },
                            [_vm._v("已有檔案：")]
                          ),
                          _c("div", { staticClass: "col-sm-10 text" }, [
                            _c(
                              "table",
                              { staticClass: "table table-bordered" },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(12, function (index) {
                                    return _c(
                                      "tr",
                                      { key: index },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(index) + " 月份"),
                                        ]),
                                        _vm.donationFileList[index] == null
                                          ? [
                                              _c("td"),
                                              _c("td", [
                                                _c("input", {
                                                  attrs: {
                                                    accept: "application/pdf",
                                                    type: "file",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onFileChange(
                                                        $event,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]
                                          : [
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm
                                                        .donationFileList[index]
                                                        .filesUrl,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.donationFileList[
                                                          index
                                                        ].name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteFile(
                                                          _vm.donationFileList[
                                                            index
                                                          ]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("刪除")]
                                                ),
                                              ]),
                                            ],
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-2 control-label" },
                            [_vm._v(" 檔案： ")]
                          ),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("div", { staticClass: "pic" }, [
                              _c("img", {
                                attrs: {
                                  draggable: "false",
                                  src: _vm.edit.filesUrl,
                                },
                              }),
                            ]),
                            _c("input", {
                              attrs: {
                                accept: "application/pdf",
                                type: "file",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onFileChange($event)
                                },
                              },
                            }),
                            _vm._m(2),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 control-label",
                              attrs: { for: "inputFile" },
                            },
                            [_vm._v("已有檔案：")]
                          ),
                          _c("div", { staticClass: "col-sm-10 text" }, [
                            _c(
                              "table",
                              { staticClass: "table table-bordered" },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(_vm.fileList, function (file, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: file.filesUrl,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(file.name))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteFile(file)
                                              },
                                            },
                                          },
                                          [_vm._v("刪除")]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: file.newName,
                                              expression: "file.newName",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "請填入新檔名",
                                          },
                                          domProps: { value: file.newName },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                file,
                                                "newName",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateFile(file)
                                            },
                                          },
                                        },
                                        [_vm._v("更新檔名")]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 年度： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v(" 文件檔案上傳格式： "),
      _c("span", { staticClass: "text-red" }, [_vm._v("(上限5MB)")]),
      _c("br"),
      _vm._v("1.PDF。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }