var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("常見問題列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("常見問題列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_vm._v(" 新增問題 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.類別,
                      expression: "searchData.類別",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "類別",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.types.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.className } },
                      [_vm._v(" " + _vm._s(item.className) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.questions.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered",
                attrs: { id: "NmjWebQuestion" },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.datas.length <= 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("查無資料")]
                          ),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            !item.isEdit
                              ? _c("td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(item.類別)),
                                ])
                              : _vm._e(),
                            !item.isEdit
                              ? _c("td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(item.排序)),
                                ])
                              : _c(
                                  "td",
                                  {
                                    staticStyle: { "vertical-align": "top" },
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _c("div", [
                                      _c("div", { staticClass: "form-group" }, [
                                        item.isEdit
                                          ? _c(
                                              "label",
                                              { attrs: { for: "類別" } },
                                              [_vm._v("類別：")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.類別,
                                                expression: "item.類別",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "類別",
                                              required: "",
                                              disabled: !item.isEdit,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  item,
                                                  "類別",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "",
                                                  selected: "",
                                                  disabled: "",
                                                  hidden: "",
                                                },
                                              },
                                              [_vm._v("請選擇類別")]
                                            ),
                                            _vm._l(
                                              _vm.types.datas,
                                              function (category) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value: category.className,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(category.className)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        item.isEdit
                                          ? _c(
                                              "label",
                                              { attrs: { for: "排序" } },
                                              [_vm._v("排序：")]
                                            )
                                          : _vm._e(),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.排序,
                                              expression: "item.排序",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "排序",
                                            type: "number",
                                            readonly: !item.isEdit,
                                            min: "1",
                                          },
                                          domProps: { value: item.排序 },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                item,
                                                "排序",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]
                                ),
                            _c(
                              "td",
                              { staticStyle: { "vertical-align": "top" } },
                              [
                                !item.isEdit
                                  ? _c("div", [
                                      _c("div", {
                                        staticClass: "question",
                                        domProps: {
                                          textContent: _vm._s(item.問題),
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "answer",
                                        domProps: {
                                          innerHTML: _vm._s(item.回答),
                                        },
                                      }),
                                    ])
                                  : _c("div", [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "問題" } },
                                          [_vm._v("問題：")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.問題,
                                              expression: "item.問題",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "問題",
                                            type: "text",
                                            required: "",
                                          },
                                          domProps: { value: item.問題 },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                item,
                                                "問題",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "回答" } },
                                            [_vm._v("回答：")]
                                          ),
                                          _c("tinymce", {
                                            attrs: {
                                              id: "回答",
                                              propLang: "zh-TW",
                                              height: "300",
                                            },
                                            model: {
                                              value: item.回答,
                                              callback: function ($$v) {
                                                _vm.$set(item, "回答", $$v)
                                              },
                                              expression: "item.回答",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                              ]
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              item.isEdit
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success",
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveItem(item, index)
                                        },
                                      },
                                    },
                                    [_vm._v(" 儲存 ")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        disabled: _vm.isEditing && !item.isEdit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(item, index)
                                        },
                                      },
                                    },
                                    [_vm._v(" 編輯 ")]
                                  ),
                              _vm._v("   "),
                              item.isEdit
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-default",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelItem(item, index)
                                        },
                                      },
                                    },
                                    [_vm._v(" 取消 ")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      attrs: {
                                        disabled: _vm.isEditing && !item.isEdit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 刪除 ")]
                                  ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.questions.total > 0,
                expression: "questions.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.questions.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "460px", width: "75%" } }, [
        _vm._v("內容"),
      ]),
      _c("th", { staticStyle: { "min-width": "140px", width: "10%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }