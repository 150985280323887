var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "BigDataAlert",
      staticClass: "modal fade",
      attrs: { id: "dataAlert" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("td", { staticStyle: { "min-width": "85px" } }, [
                        _vm._v("姓名"),
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(_vm.peopleData.target_name) + " "),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("類型")]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.array2string(_vm.peopleData.categorys)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("描述")]),
                      _c("td", [
                        _vm._v(" " + _vm._s(_vm.peopleData.description) + " "),
                      ]),
                    ]),
                    _vm._l(_vm.peopleData.channel_data, function (item, key) {
                      return _c("tr", { key: key }, [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.channelNameChart[item.platform]) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.channel_url,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(item.channel_name))]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("人物簡介")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer tc" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v(" 關閉 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }