var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "BatchForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "600px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c("div", { staticClass: "box box-info" }, [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", { staticClass: "box-title" }, [_vm._v("批次處理")]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _c("form", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "max-height": "300px", overflow: "scroll" },
                  },
                  [
                    _c("table", { staticClass: "table table-hover" }, [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.batchList, function (item) {
                          return _c("tr", { key: item.訂單編號 }, [
                            _c(
                              "td",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(item.訂單編號))]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(item.儲值角色))]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(item.金額))]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "white-space": "nowrap" } },
                              [_vm._v(_vm._s(item.log))]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "inputEmail3" },
                        },
                        [_vm._v("是否繳款：")]
                      ),
                      _vm.是否全部未處理
                        ? _c(
                            "div",
                            {
                              staticClass: "col-sm-10",
                              staticStyle: { "padding-top": "7px" },
                            },
                            [
                              _c("div", {
                                staticClass: "state iradio_flat-blue",
                                class: _vm.edit.是否繳款 ? "" : "checked",
                                on: {
                                  click: function ($event) {
                                    _vm.edit.是否繳款 = false
                                  },
                                },
                              }),
                              _vm._v("否 "),
                              _c("div", {
                                staticClass: "state iradio_flat-blue",
                                class: _vm.edit.是否繳款 ? "checked" : "",
                                on: {
                                  click: function ($event) {
                                    _vm.edit.是否繳款 = true
                                  },
                                },
                              }),
                              _vm._v("是 "),
                            ]
                          )
                        : _vm.edit.是否繳款
                        ? [
                            _c("label", { staticClass: "control-label" }, [
                              _vm._v(" 是"),
                            ]),
                          ]
                        : [
                            _c("label", { staticClass: "control-label" }, [
                              _vm._v(" 否"),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v("凍結日期："),
                      ]),
                      _vm.是否全部未處理
                        ? [
                            _c("DateTimePicker", {
                              attrs: { propDateTime: _vm.edit.凍結日期 },
                              on: {
                                "update:propDateTime": function ($event) {
                                  return _vm.$set(_vm.edit, "凍結日期", $event)
                                },
                                "update:prop-date-time": function ($event) {
                                  return _vm.$set(_vm.edit, "凍結日期", $event)
                                },
                              },
                            }),
                          ]
                        : [
                            _c("label", { staticClass: "control-label" }, [
                              _vm._v(" " + _vm._s(_vm.edit.凍結日期)),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v("繳費時間："),
                      ]),
                      _vm.是否全部未處理
                        ? [
                            _c("DateTimePicker", {
                              attrs: { propDateTime: _vm.edit.繳費時間 },
                              on: {
                                "update:propDateTime": function ($event) {
                                  return _vm.$set(_vm.edit, "繳費時間", $event)
                                },
                                "update:prop-date-time": function ($event) {
                                  return _vm.$set(_vm.edit, "繳費時間", $event)
                                },
                              },
                            }),
                          ]
                        : [
                            _c("label", { staticClass: "control-label" }, [
                              _vm._v(" " + _vm._s(_vm.edit.繳費時間)),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v("備註："),
                      ]),
                      _vm.是否全部未處理
                        ? [
                            _c("div", { staticClass: "col-sm-10" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.edit.備註,
                                    expression: "edit.備註",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { placeholder: "" },
                                domProps: { value: _vm.edit.備註 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.edit,
                                      "備註",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]
                        : [
                            _c("label", { staticClass: "control-label" }, [
                              _vm._v(" " + _vm._s(_vm.edit.備註)),
                            ]),
                          ],
                    ],
                    2
                  ),
                ],
              ],
              2
            ),
            _c("div", { staticClass: "box-footer" }, [
              _vm.是否全部未處理
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-info pull-right",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("確認送出")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("訂單編號")]),
        _c("th", [_vm._v("儲值角色")]),
        _c("th", [_vm._v("金額")]),
        _c("th", [_vm._v("處理狀態")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }