var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "btn btn-primary" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.addLimit()
              },
            },
          },
          [_vm._v("新增限制")]
        ),
      ]),
      _c(
        "table",
        { attrs: { border: "1" } },
        [
          _vm._m(0),
          _vm._l(_vm.test, function (item) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(item.限制主項目))]),
              _c("td", [_vm._v(_vm._s(item.每月上限))]),
              _c("td", [_vm._v(_vm._s(item.每周上限))]),
              _c("td", [_vm._v(_vm._s(item.每日上限))]),
              _c("td", [_vm._v(_vm._s(item.單筆上限))]),
              _c("td", [_vm._v(_vm._s(item.單筆下限))]),
            ])
          }),
        ],
        2
      ),
      _c("br"),
      _vm._l(_vm.額外限制List, function (edit) {
        return _c(
          "div",
          { key: edit.編號, staticClass: "box box-success mybox" },
          [
            _c(
              "div",
              {
                staticClass: "box-header with-border",
                staticStyle: { background: "#f4f4f4" },
              },
              [
                _c("h3", { staticClass: "box-title" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: edit.限制主項目,
                        expression: "edit.限制主項目",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control form-control2",
                    attrs: { type: "text", placeholder: "請輸入限制說明" },
                    domProps: { value: edit.限制主項目 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(edit, "限制主項目", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: edit.限制主條件,
                          expression: "edit.限制主條件",
                        },
                      ],
                      staticClass: "form-control form-control2",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              edit,
                              "限制主條件",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            _vm.reset限制(edit)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { domProps: { value: -1 } }, [
                        _vm._v("請選擇限制主條件 "),
                      ]),
                      _vm._l(_vm.金額限制項目, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.名稱) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("br"),
                ]),
                _c("div", { staticClass: "box-tools pull-right" }, [
                  _c("div", { staticClass: "btn btn-danger" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.delLimit(edit)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-times" }),
                        _vm._v("移除限制"),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "box-body" },
              [
                edit.限制主條件 == 1
                  ? _vm._l(edit.限制細項, function (item) {
                      return _c("div", { key: item.編號 }, [
                        item.是否已設
                          ? _c("span", [
                              _vm._v(" " + _vm._s(item.描述) + "："),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.參數,
                                    expression: "item.參數",
                                  },
                                ],
                                staticClass: "form-control form-control2",
                                attrs: { type: "text" },
                                domProps: { value: item.參數 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "參數", $event.target.value)
                                  },
                                },
                              }),
                              _c("span", {
                                staticClass: "img-close fa fa-fw fa-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.del(edit.限制細項, item)
                                  },
                                },
                              }),
                              _c("span", { staticClass: "red" }, [
                                _vm._v(_vm._s(item.限制細項Tip)),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(" " + _vm._s(item.描述) + "： "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-gray",
                                  staticStyle: { width: "214px" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.add(edit.限制細項, item)
                                    },
                                  },
                                },
                                [_vm._v(" 設定")]
                              ),
                            ]),
                      ])
                    })
                  : edit.限制主條件 == 2
                  ? [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: edit.判斷主條件,
                              expression: "edit.判斷主條件",
                            },
                          ],
                          staticClass: "form-control form-control2",
                          staticStyle: { width: "132px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                edit,
                                "判斷主條件",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.條件式, function (item) {
                          return _c(
                            "option",
                            { key: item.編號, domProps: { value: item.編號 } },
                            [_vm._v(_vm._s(item.描述))]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: edit.限制主參數,
                            expression: "edit.限制主參數",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control form-control2",
                        domProps: { value: edit.限制主參數 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              edit,
                              "限制主參數",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._l(edit.限制細項, function (item) {
                        return _c("div", { key: item.編號 }, [
                          item.是否已設
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.描述) + "："),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.參數,
                                      expression: "item.參數",
                                    },
                                  ],
                                  staticClass: "form-control form-control2",
                                  attrs: { type: "text" },
                                  domProps: { value: item.參數 },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "參數",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("span", {
                                  staticClass: "img-close fa fa-fw fa-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(edit.限制細項, item)
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "red" }, [
                                  _vm._v(_vm._s(item.限制細項Tip)),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(" " + _vm._s(item.描述) + "： "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-gray",
                                    staticStyle: { width: "214px" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.add(edit.限制細項, item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 設定")]
                                ),
                              ]),
                        ])
                      }),
                    ]
                  : [_vm._v(" 請選擇限制主條件 ")],
              ],
              2
            ),
          ]
        )
      }),
      _c(
        "div",
        [
          _vm._l(_vm.logs.error, function (log) {
            return _c("div", { staticClass: "red" }, [_vm._v(_vm._s(log))])
          }),
          _vm._l(_vm.logs.warning, function (log) {
            return _c("div", [_vm._v(_vm._s(log))])
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("限制主項目")]),
      _c("td", [_vm._v("每月上限")]),
      _c("td", [_vm._v("每周上限")]),
      _c("td", [_vm._v("每日上限")]),
      _c("td", [_vm._v("單筆上限")]),
      _c("td", [_vm._v("單筆下限")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }