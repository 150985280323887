var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "btn btn-primary" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.show()
              },
            },
          },
          [_vm._v("新增金額")]
        ),
      ]),
      _c("br"),
      _vm._l(_vm.儲值金額List, function (金額) {
        return _c("div", { key: 金額, staticClass: "btn btn-danger" }, [
          _c("span", [_vm._v(_vm._s(金額) + " ")]),
          _c("span", {
            staticClass: "img-close fa fa-fw fa-close",
            on: {
              click: function ($event) {
                return _vm.delOption(金額)
              },
            },
          }),
        ])
      }),
      _c("SelItemForm", {
        ref: "selIPSForm",
        on: { addOption: _vm.addOption },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }