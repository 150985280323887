var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "rewardMessageContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { width: "100px" } }, [_vm._v("發送時間")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.message.sendStartTime,
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("有效時間")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.message.sendEndTime,
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("訊息主旨")]),
                  _c("td", [_vm._v(_vm._s(_vm.message.messageTitle))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("訊息內容")]),
                  _vm.message.messageNote.startsWith("<")
                    ? _c("td", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.message.messageNote),
                          },
                        }),
                      ])
                    : _c("td", { staticStyle: { "white-space": "pre-line" } }, [
                        _vm._v(" " + _vm._s(_vm.message.messageNote) + " "),
                      ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("發送名單")]),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "250px",
                          overflow: "auto",
                        },
                      },
                      _vm._l(_vm.message.players, function (player, index) {
                        return _c("div", { key: index }, [
                          player.sendType === 1
                            ? _c("div", [
                                _vm._v(" " + _vm._s(player.playUId) + " "),
                              ])
                            : player.sendType === 2
                            ? _c("div", [
                                _vm._v(" " + _vm._s(player.playUId) + " "),
                              ])
                            : player.sendType === 3
                            ? _c("div", [
                                player.ruleType === 1
                                  ? _c("div", [
                                      _vm._v(
                                        " 等級 = " +
                                          _vm._s(player.ruleValue1) +
                                          " - " +
                                          _vm._s(player.ruleValue2) +
                                          " "
                                      ),
                                    ])
                                  : player.ruleType === 2
                                  ? _c("div", [
                                      _vm._v(
                                        " VIP = " +
                                          _vm._s(
                                            _vm.define.vipStatus[
                                              player.ruleValue1
                                            ]
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.define.vipStatus[
                                              player.ruleValue2
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : player.ruleType === 3
                                  ? _c("div", [
                                      _vm._v(
                                        " 俱樂部名稱 = " +
                                          _vm._s(player.ruleValue1) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("發送物品")]),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "250px",
                          overflow: "auto",
                        },
                      },
                      _vm._l(_vm.message.items, function (item, index) {
                        return _c("div", { key: index }, [
                          item.prizeTypeName
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(item.prizeTypeName)),
                                ]),
                                item.prizeSubType
                                  ? _c("span", [
                                      _vm._v(
                                        " - " + _vm._s(item.prizeSubType) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(" - " + _vm._s(item.prizeItemName)),
                                ]),
                                _c("span", [
                                  _vm._v(" = " + _vm._s(item.amount)),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _vm.isEdit
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.editMessage },
                },
                [_vm._v(" 編輯 ")]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("發送內容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }