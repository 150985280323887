var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PlayerHistoryList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("玩家歷程查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("玩家歷程查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "div",
              { staticClass: "form-group", staticStyle: { width: "auto" } },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("操作時間："),
                ]),
                _c("div", { staticClass: "input-group dateOnly" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DateRangePicker", {
                        attrs: {
                          propEndDate: _vm.searchData.local.endTime,
                          propStartDate: _vm.searchData.local.startTime,
                        },
                        on: {
                          "update:propEndDate": function ($event) {
                            return _vm.$set(
                              _vm.searchData.local,
                              "endTime",
                              $event
                            )
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(
                              _vm.searchData.local,
                              "endTime",
                              $event
                            )
                          },
                          "update:propStartDate": function ($event) {
                            return _vm.$set(
                              _vm.searchData.local,
                              "startTime",
                              $event
                            )
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(
                              _vm.searchData.local,
                              "startTime",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.playUId,
                    expression: "searchData.playUId",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "玩家UID", type: "text" },
                domProps: { value: _vm.searchData.playUId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "playUId",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.getDataList()
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.exportOrders()
                    },
                  },
                },
                [_vm._v(" 匯出 ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "mt-10" }, [
            !_vm.loading && _vm.result.message
              ? _c("div", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.result.message) + " "),
                ])
              : !_vm.loading && _vm.datas.length === 0
              ? _c("div", { staticClass: "text-center" }, [
                  _vm._v(" 查詢無任何資料 "),
                ])
              : _vm.texts.length
              ? _c(
                  "div",
                  { staticClass: "table-responsive" },
                  _vm._l(_vm.texts, function (text, index) {
                    return _c(
                      "table",
                      {
                        key: index,
                        staticClass:
                          "table table-bordered text-center min-1000",
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _c("tr", { staticClass: "bg-gray" }, [
                              _c("th", { attrs: { colspan: "8" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(text.title) +
                                    " (" +
                                    _vm._s(text.id) +
                                    ") "
                                ),
                              ]),
                            ]),
                            _vm._m(1, true),
                            _vm._l(text.items, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(item.position))]),
                                _c("td", [_vm._v(_vm._s(item.time))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("currencyFormat")(item.rank))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("currencyFormat")(item.gold))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currencyFormat")(item.diamond)
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currencyFormat")(item.goldMinus)
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currencyFormat")(item.goldPlus)
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.note))]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "box-footer text-center no-border" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", [_vm._v("所在位置")]),
      _c("td", [_vm._v("操作時間")]),
      _c("td", [_vm._v("當下等級")]),
      _c("td", [_vm._v("當下金幣")]),
      _c("td", [_vm._v("當下鑽石")]),
      _c("td", [_vm._v("金幣減少額")]),
      _c("td", [_vm._v("金幣增加額")]),
      _c("td", { staticStyle: { width: "35%" } }, [_vm._v("備註")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }