var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RedeemPrizesInfo" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("獎項兌換資訊")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("獎項兌換列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("獎項兌換資訊")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("獎項兌換資訊"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("label", [_vm._v(_vm._s(_vm.redeemPrize.玩家識別值))]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("label", [_vm._v(_vm._s(_vm.redeemPrize.活動編號))]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("label", [_vm._v(_vm._s(_vm.redeemPrize.獎項名稱))]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.redeemPrize.真實姓名,
                          expression: "redeemPrize.真實姓名",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.redeemPrize.真實姓名 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.redeemPrize,
                            "真實姓名",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.redeemPrize.身份證號碼,
                          expression: "redeemPrize.身份證號碼",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.redeemPrize.身份證號碼 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.redeemPrize,
                            "身份證號碼",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.redeemPrize.連絡電話,
                          expression: "redeemPrize.連絡電話",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.redeemPrize.連絡電話 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.redeemPrize,
                            "連絡電話",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.redeemPrize.郵遞區號,
                          expression: "redeemPrize.郵遞區號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.redeemPrize.郵遞區號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.redeemPrize,
                            "郵遞區號",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.redeemPrize.地址,
                          expression: "redeemPrize.地址",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.redeemPrize.地址 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.redeemPrize, "地址", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "redeemPrizeor" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.redeemPrize.備註,
                            expression: "redeemPrize.備註",
                          },
                        ],
                        staticStyle: { width: "100%", height: "200px" },
                        domProps: { value: _vm.redeemPrize.備註 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.redeemPrize,
                              "備註",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.redeemPrize.狀態,
                            expression: "redeemPrize.狀態",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.redeemPrize,
                              "狀態",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("未填單"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("待處理"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("已處理"),
                        ]),
                        _c("option", { attrs: { value: "3" } }, [
                          _vm._v("停止兌換"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save()
                        },
                      },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 玩家識別值： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 活動： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 獎項： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 真實姓名： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 身份證號碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 連絡電話： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 郵遞區號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 地址： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "redeemPrizeor" },
      },
      [_c("span", { staticClass: "text-red" }), _vm._v(" 備註： ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }