var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "RewardMessage" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("給獎訊息設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("給獎訊息設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addMessage()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.messageStatus,
                        expression: "searchData.messageStatus",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "messageStatus",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-9" } }, [
                      _vm._v("所有狀態"),
                    ]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("草稿")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("等待中")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("已發送")]),
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v("已刪除"),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "form-group", staticStyle: { width: "auto" } },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("發送時間："),
                  ]),
                  _c("div", { staticClass: "input-group date" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propTimePicker: true,
                            propEndDate: _vm.searchData.local.endTime,
                            propStartDate: _vm.searchData.local.startTime,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "endTime",
                                $event
                              )
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "endTime",
                                $event
                              )
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "startTime",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData.local,
                                "startTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.importSN()
                      },
                    },
                  },
                  [_vm._v(" 獎項匯入 ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered text-center min-1000" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(1),
                      _vm.loading
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "8" } }, [
                              _vm._v("查詢中"),
                            ]),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "8" } }, [
                              _vm._v("查詢無任何資料"),
                            ]),
                          ])
                        : _vm._l(_vm.datas, function (item, index) {
                            return _c(
                              "tr",
                              {
                                key: index,
                                class: {
                                  "bg-gray-light": item.messageStatus === -1,
                                },
                              },
                              [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.sendStartTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.sendEndTime,
                                          "YYYY/MM/DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("ellipsis")(
                                          item.messageTitle,
                                          13
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          item.messageStatus === 0
                                            ? _vm.editMessage(item)
                                            : _vm.checkContent(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.updateUName))]),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      "text-red": item.messageStatus === 0,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.define.rewardStatus[
                                            item.messageStatus
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  item.messageStatus === 2 ||
                                  new Date(item.sendStartTime) < _vm.today
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success",
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkHistory(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看 ")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("td", [
                                  item.messageStatus !== -1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteMessage(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 刪除 ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("rewardMessageContent", { ref: "rewardMessageContent" }),
      _c("fcImportData", {
        ref: "fcImportData",
        attrs: { title: "獎項", checks: _vm.checks },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("發送時間")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("有效時間")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v("訊息主旨")]
      ),
      _c("th", [_vm._v("發送內容")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("操作人")]),
      _c("th", [_vm._v("狀態")]),
      _c("th", [_vm._v("歷程明細")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }