var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 金流維護設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", { staticClass: "active" }, [_vm._v("金流維護設定 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("廠商：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sel廠商,
                      expression: "sel廠商",
                    },
                  ],
                  staticClass: "form-control form-option",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sel廠商 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v(" 全部 ")]),
                  _vm._l(_vm.廠商設定Key, function (item, key) {
                    return _c(
                      "option",
                      { key: item.編號, domProps: { value: item.編號 } },
                      [_vm._v(" " + _vm._s(item.名稱) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("金流：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sel金流,
                      expression: "sel金流",
                    },
                  ],
                  staticClass: "form-control form-option",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sel金流 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v(" 全部 ")]),
                  _vm._l(_vm.金流設定主檔Key, function (item, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [_vm._v(" " + _vm._s(item.金流名稱) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("支付：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sel支付,
                      expression: "sel支付",
                    },
                  ],
                  staticClass: "form-control form-option",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sel支付 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v(" 全部 ")]),
                  _vm._l(_vm.filterSel金流支付設定Key, function (item, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [_vm._v(" " + _vm._s(item.支付名稱) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("系統維護："),
                ]),
                _vm.一鍵維護狀態 == 0 || _vm.一鍵維護狀態 == 1
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "ckbx-style-8 ckbx toggleInput",
                          staticStyle: { "padding-top": "5px" },
                          on: {
                            click: function ($event) {
                              _vm.upd一鍵維護()
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.一鍵維護狀態,
                                expression: "一鍵維護狀態",
                              },
                            ],
                            attrs: {
                              "false-value": 0,
                              "true-value": 1,
                              name: "ckbx-style-8",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.一鍵維護狀態)
                                ? _vm._i(_vm.一鍵維護狀態, null) > -1
                                : _vm._q(_vm.一鍵維護狀態, 1),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.一鍵維護狀態,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? 1 : 0
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.一鍵維護狀態 = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.一鍵維護狀態 = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.一鍵維護狀態 = $$c
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "check" } }),
                        ]
                      ),
                    ]
                  : [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("維護狀態錯誤"),
                      ]),
                    ],
              ],
              2
            ),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  on: {
                    click: function ($event) {
                      return _vm.openPopForm(false, null)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增維護時段 "),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.pageList.length > 0
                      ? _vm._l(_vm.pageList, function (item) {
                          return _c(
                            "tr",
                            {
                              key: item.編號,
                              class: { maintain: item.isMaintain },
                            },
                            [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.編號)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.get廠商Name(item))),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text-left",
                                  staticStyle: { "vertical-align": "top" },
                                },
                                [
                                  item.金流設定主檔編號 == -1
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "34px",
                                            },
                                          },
                                          [_vm._v("全部")]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-info",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showTip(
                                                  "tipA" + item.編號
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.金流設定主檔Key[
                                                    item.金流設定主檔編號
                                                  ] &&
                                                    _vm.金流設定主檔Key[
                                                      item.金流設定主檔編號
                                                    ].金流名稱
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "payStyle",
                                            staticStyle: { display: "none" },
                                            attrs: { id: "tipA" + item.編號 },
                                          },
                                          [
                                            _c("ul", [
                                              _c("li", [
                                                _vm._v(
                                                  "金流名稱：" +
                                                    _vm._s(
                                                      _vm.金流設定主檔Key[
                                                        item.金流設定主檔編號
                                                      ] &&
                                                        _vm.金流設定主檔Key[
                                                          item.金流設定主檔編號
                                                        ].金流名稱
                                                    )
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v(
                                                  "金流代碼：" +
                                                    _vm._s(
                                                      _vm.金流設定主檔Key[
                                                        item.金流設定主檔編號
                                                      ] &&
                                                        _vm.金流設定主檔Key[
                                                          item.金流設定主檔編號
                                                        ].金流代碼
                                                    )
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v("啟停用： "),
                                                _vm.金流設定主檔Key[
                                                  item.金流設定主檔編號
                                                ] &&
                                                _vm.金流設定主檔Key[
                                                  item.金流設定主檔編號
                                                ].啟用 == 1
                                                  ? _c("span", [_vm._v("啟用")])
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "text-red",
                                                      },
                                                      [_vm._v("停用")]
                                                    ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-left",
                                  staticStyle: { "vertical-align": "top" },
                                },
                                [
                                  item.付款方式 == -1
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "34px",
                                            },
                                          },
                                          [_vm._v("全部")]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-info",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showTip(
                                                  "tipB" + item.編號
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.金流支付設定Key[
                                                    item.付款方式
                                                  ] &&
                                                    _vm.金流支付設定Key[
                                                      item.付款方式
                                                    ].支付名稱
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "payStyle",
                                            staticStyle: { display: "none" },
                                            attrs: { id: "tipB" + item.編號 },
                                          },
                                          [
                                            _c("ul", [
                                              _c("li", [
                                                _vm._v(
                                                  "支付名稱：" +
                                                    _vm._s(
                                                      _vm.金流支付設定Key[
                                                        item.付款方式
                                                      ] &&
                                                        _vm.金流支付設定Key[
                                                          item.付款方式
                                                        ].支付名稱
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v(
                                                  "支付方式代碼：" +
                                                    _vm._s(
                                                      _vm.金流支付設定Key[
                                                        item.付款方式
                                                      ] &&
                                                        _vm.金流支付設定Key[
                                                          item.付款方式
                                                        ].支付方式代碼
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v(
                                                  "限制幣別：" +
                                                    _vm._s(
                                                      _vm.金流支付設定Key[
                                                        item.付款方式
                                                      ] &&
                                                        _vm.地區列表Key[
                                                          _vm.金流支付設定Key[
                                                            item.付款方式
                                                          ].限制幣別
                                                        ].幣別
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v(
                                                  "金流類型：" +
                                                    _vm._s(
                                                      _vm.金流支付設定Key[
                                                        item.付款方式
                                                      ] &&
                                                        _vm.金流類型設定Key[
                                                          _vm.金流支付設定Key[
                                                            item.付款方式
                                                          ].金流類型
                                                        ].類型
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v(
                                                  "是否開立發票：" +
                                                    _vm._s(
                                                      _vm.金流支付設定Key[
                                                        item.付款方式
                                                      ] &&
                                                        _vm.金流支付設定Key[
                                                          item.付款方式
                                                        ].是否開立發票 == 1
                                                        ? "是"
                                                        : "否"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("li", [
                                                _vm._v("啟停用： "),
                                                _vm.金流支付設定Key[
                                                  item.付款方式
                                                ] &&
                                                _vm.金流支付設定Key[
                                                  item.付款方式
                                                ].啟用 == 1
                                                  ? _c("span", [_vm._v("啟用")])
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "text-red",
                                                      },
                                                      [_vm._v("停用")]
                                                    ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(item.開始維護時段) +
                                    "~" +
                                    _vm._s(item.結束維護時段)
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.備註)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                item.isMaintain
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stop(item)
                                          },
                                        },
                                      },
                                      [_vm._v("立刻中止")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPopForm(true, item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                              ]),
                            ]
                          )
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
    _c("div", { ref: "popForm", staticClass: "modal fade" }, [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(2),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(this.isEdit ? "修改" : "新增")),
            ]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("table", { staticStyle: { margin: "0 auto", width: "580px" } }, [
              _c("tr", [
                _vm._m(3),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.廠商設定主檔編號,
                          expression: "edit.廠商設定主檔編號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: this.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "廠商設定主檔編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _vm._l(_vm.廠商設定Key, function (item, key) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.名稱) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(4),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.金流設定主檔編號,
                          expression: "edit.金流設定主檔編號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: this.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "金流設定主檔編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _vm._l(
                        _vm.filterEdit金流設定主檔Key,
                        function (item, key) {
                          return _c(
                            "option",
                            { key: item.編號, domProps: { value: item.編號 } },
                            [_vm._v(" " + _vm._s(item.金流名稱) + " ")]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(5),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.付款方式,
                          expression: "edit.付款方式",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: this.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "付款方式",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v(" 全部 "),
                      ]),
                      _vm._l(
                        _vm.filterEdit金流支付設定Key,
                        function (item, key) {
                          return _c(
                            "option",
                            { key: item.編號, domProps: { value: item.編號 } },
                            [_vm._v(" " + _vm._s(item.支付名稱) + " ")]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(6),
                _c(
                  "td",
                  [
                    _c("DateTimePicker", {
                      attrs: { propDateTime: _vm.edit.開始維護時段 },
                      on: {
                        "update:propDateTime": function ($event) {
                          return _vm.$set(_vm.edit, "開始維護時段", $event)
                        },
                        "update:prop-date-time": function ($event) {
                          return _vm.$set(_vm.edit, "開始維護時段", $event)
                        },
                      },
                    }),
                    _c("span", { staticClass: "col-md-10 col-md-span" }, [
                      _vm._v("~"),
                    ]),
                    _c("DateTimePicker", {
                      attrs: { propDateTime: _vm.edit.結束維護時段 },
                      on: {
                        "update:propDateTime": function ($event) {
                          return _vm.$set(_vm.edit, "結束維護時段", $event)
                        },
                        "update:prop-date-time": function ($event) {
                          return _vm.$set(_vm.edit, "結束維護時段", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _vm._m(7),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.edit.備註,
                        expression: "edit.備註",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "備註", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2", align: "right" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.send()
                        },
                      },
                    },
                    [_vm._v(" 送出")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("編號")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("廠商")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("金流")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("付款方式")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("維護時段")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("備註")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { "padding-right": "10px", width: "100px" },
      },
      [_c("label", [_vm._v("廠商：")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { "padding-right": "10px", width: "100px" },
      },
      [_c("label", [_vm._v("金流：")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { "padding-right": "10px", width: "100px" },
      },
      [_c("label", [_vm._v("付款方式：")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { "padding-right": "10px", width: "100px" },
      },
      [_c("label", [_vm._v("維護時間：")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { "padding-right": "10px", width: "100px" },
      },
      [_c("label", [_vm._v("備註：")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }