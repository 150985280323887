var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "leaderboard" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("搜尋貼文")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("搜尋貼文")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              {
                staticClass: "top-box with-border pb-15 clearfix",
                attrs: { id: "BigData" },
              },
              [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.platform,
                          expression: "searchData.platform",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectSocial" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.search,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "" } }, [
                        _vm._v("請選擇平台"),
                      ]),
                      _vm._l(_vm.selectPlatformsList, function (item, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: item.classId } },
                          [_vm._v(" " + _vm._s(item.className) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "select-group" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.selectTypeList,
                        multiple: true,
                        placeholder: "請選擇類型",
                        showLabels: false,
                        max: 2,
                        "hide-selected": true,
                        searchable: true,
                      },
                      model: {
                        value: _vm.searchData.categorys,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "categorys", $$v)
                        },
                        expression: "searchData.categorys",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "input-group searchInput atLeft" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.keyWord,
                        expression: "searchData.keyWord",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "搜尋貼文關鍵字，以空格分開，最多三個",
                      type: "text",
                    },
                    domProps: { value: _vm.searchData.keyWord },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.keySearch()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.searchData, "keyWord", $event.target.value)
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.keySearch()
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-tools",
                    attrs: { "data-btn": "resetBtn", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.searchReset()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 重設 ")]
                ),
              ]
            ),
            _c("div", { staticClass: "top-box mt-15" }, [
              _c(
                "div",
                { staticClass: "btn-group ml-10 ml-0-sm" },
                _vm._l(_vm.dayAnalysis, function (item, key) {
                  return _c(
                    "button",
                    {
                      key: key,
                      staticClass: "btn btn-default",
                      class: {
                        active: _vm.searchData.dayAnalysisChoose == item.name,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.changeDays(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                0
              ),
              _c(
                "button",
                {
                  staticClass:
                    "btn mb-10-md btn-default w100-sm ml-10 ml-0-sm mt-10-sm",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.searchData.isAdvancedSearch =
                        !_vm.searchData.isAdvancedSearch
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-calendar-plus-o",
                    class: {
                      "fa-calendar-minus-o": _vm.searchData.isAdvancedSearch,
                    },
                  }),
                  _vm._v(" 自訂日期 "),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchData.isAdvancedSearch,
                      expression: "searchData.isAdvancedSearch",
                    },
                  ],
                  staticClass: "input-group advanceDate",
                },
                [
                  _c("DateRangePicker", {
                    attrs: {
                      propEndDate: _vm.searchData.advDateData.lte,
                      propStartDate: _vm.searchData.advDateData.gte,
                      propsIn: "format2",
                    },
                    on: {
                      "update:propEndDate": function ($event) {
                        return _vm.$set(
                          _vm.searchData.advDateData,
                          "lte",
                          $event
                        )
                      },
                      "update:prop-end-date": function ($event) {
                        return _vm.$set(
                          _vm.searchData.advDateData,
                          "lte",
                          $event
                        )
                      },
                      "update:propStartDate": function ($event) {
                        return _vm.$set(
                          _vm.searchData.advDateData,
                          "gte",
                          $event
                        )
                      },
                      "update:prop-start-date": function ($event) {
                        return _vm.$set(
                          _vm.searchData.advDateData,
                          "gte",
                          $event
                        )
                      },
                      restList: function ($event) {
                        return _vm.advDateSearch()
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "btn-group ml-10 mt-10" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(" " + _vm._s(_vm.leaderboardTotal) + " "),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "nav-tabs-custom boxShadow-none clearfix mt-35" },
              [
                _c(
                  "ul",
                  { staticClass: "nav nav-tabs nav-yellow" },
                  _vm._l(_vm.platformTabs, function (tab, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: { active: _vm.tabIndex == "tab" + index },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.changeSortTab(tab.value, index)
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "#tab" + index } }, [
                          _vm._v(" " + _vm._s(tab.name)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "tab-content" },
                  [
                    _vm._l(_vm.platformTabs, function (tab, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "tab-pane overflowX-auto table-responsive",
                          class: { active: _vm.tabIndex == "tab" + key },
                          attrs: { id: "#tab" + key },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "css-table post-table table" },
                            [
                              _c("div", { staticClass: "thead" }, [
                                _c("div", { staticClass: "tr" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "th",
                                      staticStyle: {
                                        width: "5%",
                                        "min-width": "60px",
                                      },
                                    },
                                    [_vm._v("排名")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "th",
                                      staticStyle: { "min-width": "100px" },
                                      attrs: { width: "5%" },
                                    },
                                    [_vm._v("姓名")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "th",
                                      staticStyle: { "min-width": "300px" },
                                      attrs: { width: "40%" },
                                    },
                                    [_vm._v("貼文")]
                                  ),
                                  _vm.f_Like_count.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("按讚數")]
                                      )
                                    : _vm._e(),
                                  _vm.f_dislike_count.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("倒讚數")]
                                      )
                                    : _vm._e(),
                                  _vm.f_comment_counts.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("留言數")]
                                      )
                                    : _vm._e(),
                                  _vm.f_view_count.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("觀看數")]
                                      )
                                    : _vm._e(),
                                  _vm.f_share_count.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("分享數")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "th",
                                      staticStyle: {
                                        width: "5%",
                                        "min-width": "80px",
                                      },
                                    },
                                    [_vm._v("互動率")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "th",
                                      staticStyle: {
                                        width: "10%",
                                        "min-width": "95px",
                                      },
                                    },
                                    [_vm._v("爬蟲時間")]
                                  ),
                                  _vm.f_FunctionTab.includes(
                                    _vm.searchData.platform
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "th",
                                          staticStyle: {
                                            width: "5%",
                                            "min-width": "80px",
                                          },
                                        },
                                        [_vm._v("功能")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm.leaderboardTotal > 0
                                ? _c(
                                    "div",
                                    { staticClass: "tbody" },
                                    _vm._l(
                                      _vm.leaderboardList,
                                      function (post, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            staticClass:
                                              "postCard tr cursor-auto",
                                          },
                                          [
                                            _c("div", { staticClass: "td" }, [
                                              _vm._v(
                                                " " + _vm._s(post.rank) + " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td text-left",
                                                attrs: { "data-lable": "姓名" },
                                              },
                                              [
                                                _vm.searchData.platform ==
                                                  "line" ||
                                                _vm.searchData.platform ==
                                                  "yahoo"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            post.publisher
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _vm.searchData.platform ==
                                                    "ptt"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(post.author) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "link",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showContent(
                                                              post
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              post.target_name
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "card-body td",
                                                attrs: {
                                                  href: "javascript:;",
                                                  "data-lable": "貼文圖",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.OpenUrl(post)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card-img jqImgFill",
                                                  },
                                                  [
                                                    _c("img", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            post.post_display_url !=
                                                            null,
                                                          expression:
                                                            "post.post_display_url != null",
                                                        },
                                                      ],
                                                      attrs: {
                                                        src: _vm.GetImgUrl(
                                                          post
                                                        ),
                                                      },
                                                    }),
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            post.post_display_url ==
                                                            null,
                                                          expression:
                                                            "post.post_display_url == null",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "fakeImg fa fa-picture-o",
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "typeIcon fa",
                                                      class: !_vm.isVideo(post)
                                                        ? "fa-sticky-note"
                                                        : "fa-play",
                                                    }),
                                                  ]
                                                ),
                                                _c("p", {
                                                  staticClass: "card-text",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.GetDataTitle(post)
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "card-date" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$utils.formatTime(
                                                          post.upload_date,
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.f_Like_count.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "td td-like",
                                                    attrs: {
                                                      "data-lable": "按讚數",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat"
                                                          )(post.like_count)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.f_dislike_count.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "td td-like",
                                                    attrs: {
                                                      "data-lable": "倒讚數",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat"
                                                          )(post.dislike_count)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.f_comment_counts.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td td-comment",
                                                    attrs: {
                                                      "data-lable": "留言數",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat"
                                                          )(post.comment_counts)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.f_view_count.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "td td-browse",
                                                    attrs: {
                                                      "data-lable": "觀看數",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat"
                                                          )(post.view_count)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.f_share_count.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "td td-browse",
                                                    attrs: {
                                                      "data-lable": "分享數",
                                                    },
                                                  },
                                                  [
                                                    _vm.searchData.platform ==
                                                    "fb"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currencyFormat2"
                                                                )(post.shares)
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currencyFormat2"
                                                                )(
                                                                  post.share_count
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td",
                                                attrs: {
                                                  "data-lable": "互動率",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.parseFloatNoRounding(
                                                        post.avg_post_probability
                                                      )
                                                    ) +
                                                    "% "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td td-crawl time",
                                                attrs: { "data-lable": "時間" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$utils.formatTime(
                                                        post.last_update,
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm.f_FunctionTab.includes(
                                              _vm.searchData.platform
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "td text-center funTdVer",
                                                    attrs: {
                                                      "data-lable": "功能",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.postKPI(
                                                              post
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" 文章KPI ")]
                                                    ),
                                                    _c("br"),
                                                    _vm.searchData.platform !==
                                                    "ptt"
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-warning",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.kolKPI(
                                                                  post
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" 網紅KPI")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm.leaderboardTotal < 1
                      ? _c("div", { staticClass: "mt-25 mb-20 tc" }, [
                          _vm._v(" 暫無資料 "),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "box-footer text-center no-border mt-20" },
                      [
                        _c("Pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.leaderboardTotal > 0,
                              expression: "leaderboardTotal > 0",
                            },
                          ],
                          ref: "pagination",
                          attrs: {
                            pageLimitCount: _vm.searchData.page_size,
                            total: _vm.leaderboardTotal,
                          },
                          on: { pageMethod: _vm.onPageChange },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c("BigDataAlert", {
        ref: "BigDataAlert",
        attrs: { "props-in": "leaderboardList" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }