var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "channelAlert", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-borderless" }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("頻道名稱：")]
                ),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "linkStyle",
                      attrs: {
                        href: _vm.channelItem.頻道連結,
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.channelItem.頻道名稱))]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fa fa-link" }),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(1),
                _c(
                  "td",
                  { staticClass: "infos" },
                  _vm._l(_vm.channelItem.玩家資訊, function (item, index) {
                    return _c("div", { key: index, staticClass: "infobox" }, [
                      _c("div", { staticClass: "infofiled" }, [
                        _vm._v(_vm._s(item.玩家角色名稱)),
                      ]),
                      _c("div", { staticClass: "infofiled" }, [
                        _vm._v(_vm._s(item.玩家角色電話)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("下架數：")]
                ),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.channelItem.影片下架數))]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("留言板：")]
                ),
                _c("td", [
                  _vm.channelItem.留言板狀態 == 0
                    ? _c("span", [_vm._v("關閉")])
                    : _vm._e(),
                  _vm.channelItem.留言板狀態 == 1
                    ? _c("span", [_vm._v("開放")])
                    : _vm._e(),
                  _vm.channelItem.留言板狀態 == 2
                    ? _c("span", [_vm._v("部分關閉")])
                    : _vm._e(),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("備註：")]
                ),
                _c("td", [_c("span", [_vm._v(_vm._s(_vm.channelItem.備註))])]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-warning",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.gochannel()
                },
              },
            },
            [_vm._v(" 前往編輯 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [
        _c("span", [_vm._v("頻道資訊")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "text-right", staticStyle: { width: "120px" } },
      [_vm._v("玩家資訊："), _c("br"), _vm._v("(ID/電話) ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }