var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("BANNER管理列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("BANNER管理列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_vm._v(" 新增 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _vm.bannerTypes.total > 1
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.classTypeId,
                          expression: "searchData.classTypeId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "classTypeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.clickSearch,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("所有分類"),
                      ]),
                      _vm._l(_vm.bannerTypes.datas, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.classTypeId } },
                          [_vm._v(" " + _vm._s(item.className) + " ")]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterStatus,
                      expression: "filterStatus",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { required: "" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.filterStatus = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("所有狀態")]),
                  _c("option", { attrs: { value: "進行中" } }, [
                    _vm._v("進行中"),
                  ]),
                  _c("option", { attrs: { value: "等待上線" } }, [
                    _vm._v("等待上線"),
                  ]),
                  _c("option", { attrs: { value: "已過期" } }, [
                    _vm._v("已過期"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.filteredBanners.length)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered AdListTable" },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.bannerTypes.total > 1,
                              expression: "bannerTypes.total > 1",
                            },
                          ],
                          staticStyle: { "min-width": "80px", width: "5%" },
                        },
                        [_vm._v("分類")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "140px", width: "5%" } },
                        [_vm._v("排序")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "120px", width: "10%" } },
                        [_vm._v("主圖")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "120px", width: "10%" } },
                        [_vm._v("名稱")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "80px", width: "5%" } },
                        [_vm._v("狀態")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "100px", width: "10%" } },
                        [_vm._v("開始時間")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "100px", width: "10%" } },
                        [_vm._v("結束時間")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "140px", width: "5%" } },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm.filteredBanners.length <= 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("無資料")]
                          ),
                        ])
                      : _vm._l(_vm.pagedBanners, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _c(
                              "td",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.bannerTypes.total > 1,
                                    expression: "bannerTypes.total > 1",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(item.className))]
                            ),
                            _c("td", { staticClass: "td-sort" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.orderNum,
                                    expression: "item.orderNum",
                                  },
                                ],
                                staticStyle: {
                                  "max-width": "54px",
                                  padding: "5px",
                                },
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  max: _vm.banners.total,
                                  disabled: item.isStop == 1,
                                },
                                domProps: { value: item.orderNum },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "orderNum",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              item.isStop != 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateBannerOd(item)
                                        },
                                      },
                                    },
                                    [_vm._v("更新")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "pic jqimgFill" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.filesUrl,
                                    draggable: "false",
                                    alt: "",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.note))]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.GetStatus(item, false))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.formalStartTime))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.formalEndTime))),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.filteredBanners.length,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }