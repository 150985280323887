var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormDialog",
        {
          ref: "memberModerateDialog",
          attrs: { title: _vm.$t(!_vm.unban ? "玩家控管" : "玩家解鎖") },
          on: { submit: _vm.submit },
        },
        [
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t((!_vm.unban ? "控管" : "解鎖") + "對象"))
                    ),
                  ]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.user.暱稱 || _vm.user.玩家識別值)),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t((!_vm.unban ? "控管" : "解鎖") + "類型"))
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("iCheckRedioButton", {
                        staticClass: "radio-inline mr-20 ml-0 mt-5 mb-5",
                        attrs: {
                          checkValue: "0",
                          message: _vm.$t(!_vm.unban ? "停權" : "復權"),
                          name: "blockType",
                        },
                        model: {
                          value: _vm.data.動作,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "動作", _vm._n($$v))
                          },
                          expression: "data.動作",
                        },
                      }),
                      _c("iCheckRedioButton", {
                        staticClass: "radio-inline mr-20 ml-0 mt-5 mb-5",
                        attrs: {
                          checkValue: "1",
                          message: _vm.$t(!_vm.unban ? "禁言" : "解除禁言"),
                          name: "blockType",
                        },
                        model: {
                          value: _vm.data.動作,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "動作", _vm._n($$v))
                          },
                          expression: "data.動作",
                        },
                      }),
                      _c("iCheckRedioButton", {
                        staticClass: "radio-inline mr-20 ml-0 mt-5 mb-5",
                        attrs: {
                          checkValue: "2",
                          message: _vm.$t(!_vm.unban ? "其他控管" : "解除鎖定"),
                          name: "blockType",
                        },
                        model: {
                          value: _vm.data.動作,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "動作", _vm._n($$v))
                          },
                          expression: "data.動作",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("限制" + (!_vm.unban ? "鎖定" : "解除")))
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "label",
                      { staticClass: "checkbox-inline mr-20 ml-0 mt-5 mb-5" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.星幣鎖交易,
                              expression: "data.星幣鎖交易",
                            },
                          ],
                          staticStyle: {
                            position: "relative",
                            width: "1.5em",
                            height: "1.5em",
                            "vertical-align": "sub",
                            "margin-top": "-.2em",
                            "accent-color": "#3498db",
                            cursor: "pointer",
                          },
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.data.星幣鎖交易)
                              ? _vm._i(_vm.data.星幣鎖交易, null) > -1
                              : _vm.data.星幣鎖交易,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.data.星幣鎖交易,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.data,
                                      "星幣鎖交易",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.data,
                                      "星幣鎖交易",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.data, "星幣鎖交易", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                !_vm.unban ? "金幣轉出鎖定" : "金幣解除禁止"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "label",
                      { staticClass: "checkbox-inline mr-20 ml-0 mt-5 mb-5" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.雙平台鎖交易,
                              expression: "data.雙平台鎖交易",
                            },
                          ],
                          staticStyle: {
                            position: "relative",
                            width: "1.5em",
                            height: "1.5em",
                            "vertical-align": "sub",
                            "margin-top": "-.2em",
                            "accent-color": "#3498db",
                            cursor: "pointer",
                          },
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.data.雙平台鎖交易)
                              ? _vm._i(_vm.data.雙平台鎖交易, null) > -1
                              : _vm.data.雙平台鎖交易,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.data.雙平台鎖交易,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.data,
                                      "雙平台鎖交易",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.data,
                                      "雙平台鎖交易",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.data, "雙平台鎖交易", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                !_vm.unban
                                  ? "雙平台購買鎖定"
                                  : "雙平台購買解除禁止"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(!_vm.unban ? "期限" : "解鎖"))),
                  ]),
                  _vm.unban
                    ? _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline",
                            attrs: {
                              checkValue: true,
                              message: _vm.$t("立即解鎖"),
                              name: "unban",
                            },
                            model: {
                              value: _vm.data.解鎖,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "解鎖", $$v)
                              },
                              expression: "data.解鎖",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("td", [
                        _vm.data.動作 == 0
                          ? _c(
                              "div",
                              { key: 0 },
                              [
                                _c(
                                  "div",
                                  { staticClass: "radio-group" },
                                  [
                                    _c("iCheckRedioButton", {
                                      staticClass: "radio radioCenter",
                                      attrs: {
                                        checkValue: "1",
                                        message: "",
                                        name: "timeType",
                                      },
                                      model: {
                                        value: _vm.data.類型,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "類型",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.類型",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.data.期限,
                                          expression: "data.期限",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "1" },
                                      domProps: { value: _vm.data.期限 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.data,
                                            "期限",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(_vm.$t("天")))]),
                                  ],
                                  1
                                ),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "3",
                                    message: _vm.$t("暫時凍結"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "2",
                                    message: _vm.$t("終止契約"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.data.動作 == 1
                          ? _c(
                              "div",
                              { key: 1 },
                              [
                                _c(
                                  "div",
                                  { staticClass: "radio-group" },
                                  [
                                    _c("iCheckRedioButton", {
                                      staticClass: "radio radioCenter",
                                      attrs: {
                                        checkValue: "1",
                                        message: "",
                                        name: "timeType",
                                      },
                                      model: {
                                        value: _vm.data.類型,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "類型",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.類型",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.data.期限,
                                          expression: "data.期限",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "1" },
                                      domProps: { value: _vm.data.期限 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.data,
                                            "期限",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("分鐘"))),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "2",
                                    message: _vm.$t("永久禁言"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { key: 2 },
                              [
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "2",
                                    message: _vm.$t("永久鎖定"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.分類,
                            expression: "data.分類",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "分類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: {
                              value: "",
                              selected: "",
                              disabled: "",
                              hidden: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                        ),
                        _vm._l(_vm.types, function (name, id) {
                          return _c(
                            "option",
                            { key: id, domProps: { value: name } },
                            [_vm._v(_vm._s(name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("原因")))]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.data.原因,
                          expression: "data.原因",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "必填",
                        rows: "2",
                        required: "",
                      },
                      domProps: { value: _vm.data.原因 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "原因", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                !_vm.unban && _vm.data.動作 != 2
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("通知")))]),
                      _c("td", [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-inline mr-20 ml-0 mt-5 mb-5",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.官網公告,
                                  expression: "data.官網公告",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.data.官網公告)
                                  ? _vm._i(_vm.data.官網公告, null) > -1
                                  : _vm.data.官網公告,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.data.官網公告,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.data,
                                          "官網公告",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.data,
                                          "官網公告",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.data, "官網公告", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" 於官網公告 "),
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-inline mr-20 ml-0 mt-5 mb-5",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.遊戲公告,
                                  expression: "data.遊戲公告",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.data.遊戲公告)
                                  ? _vm._i(_vm.data.遊戲公告, null) > -1
                                  : _vm.data.遊戲公告,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.data.遊戲公告,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.data,
                                          "遊戲公告",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.data,
                                          "遊戲公告",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.data, "遊戲公告", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" 於遊戲公告 "),
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-inline mr-20 ml-0 mt-5 mb-5",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.發簡訊,
                                  expression: "data.發簡訊",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.data.發簡訊)
                                  ? _vm._i(_vm.data.發簡訊, null) > -1
                                  : _vm.data.發簡訊,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.data.發簡訊,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.data,
                                          "發簡訊",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.data,
                                          "發簡訊",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.data, "發簡訊", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" 發送簡訊 "),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.unban && _vm.data.動作 == 0
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("通知內容")))]),
                      _c("td", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.data.通知內容,
                              expression: "data.通知內容",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "必填",
                            rows: "2",
                            required: "",
                          },
                          domProps: { value: _vm.data.通知內容 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "通知內容",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }