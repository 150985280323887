var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "ClassTypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("項目設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲管理")]),
        _c("li", [_vm._v("活動機制")]),
        _c("li", [_vm._v("機制項目")]),
        _c("li", { staticClass: "active" }, [_vm._v("項目設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("h4", { attrs: { id: "title" } }, [
            _vm._v(_vm._s(_vm.$route.params.name)),
          ]),
          _c("div", { staticClass: "top-box mt-20 mb-20" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add mr-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [_vm._v(" 新增 ")]
            ),
            _c(
              "button",
              {
                class: [
                  "btn btn-nav",
                  _vm.searchData.項目 == 1 ? "is-active" : "",
                ],
                on: {
                  click: function ($event) {
                    _vm.searchData.項目 = 1
                  },
                },
              },
              [_vm._v("主項目")]
            ),
            _c(
              "button",
              {
                class: [
                  "btn btn-nav",
                  _vm.searchData.項目 == 2 ? "is-active" : "",
                ],
                on: {
                  click: function ($event) {
                    _vm.searchData.項目 = 2
                  },
                },
              },
              [_vm._v("次項目")]
            ),
            _c(
              "button",
              {
                class: [
                  "btn btn-nav",
                  _vm.searchData.項目 == 3 ? "is-active" : "",
                ],
                on: {
                  click: function ($event) {
                    _vm.searchData.項目 = 3
                  },
                },
              },
              [_vm._v("延伸項目")]
            ),
          ]),
          _c("div", { staticClass: "text" }, [
            _c("p", { staticClass: "data-num" }, [
              _c("span", [_vm._v("資料筆數：")]),
              _c("span", { attrs: { id: "total" } }, [
                _vm._v(_vm._s(_vm.total)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                attrs: { id: "classTypes" },
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeSort()
                                },
                              },
                            },
                            [
                              _vm._v(" 名稱 "),
                              _c("i", { staticClass: "fa fa-sort" }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "100px", width: "10%" },
                        },
                        [_vm._v("啟用")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "20%" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm._l(_vm.list, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(item.項目名稱))]),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ckbx-style-8 ckbx toggleInput" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.是否啟用,
                                      expression: "item.是否啟用",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 0,
                                    id: "check" + index,
                                    "true-value": 1,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                    disabled: "",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.是否啟用)
                                      ? _vm._i(item.是否啟用, null) > -1
                                      : _vm._q(item.是否啟用, 1),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.是否啟用,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "是否啟用", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "check" + index },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(item)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item)
                                },
                              },
                            },
                            [_vm._v("編輯")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: 1, pageLimitCount: 1 },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }