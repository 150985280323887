var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("跑馬燈設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("跑馬燈設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content customer-service" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_vm._v(" 新增跑馬燈")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.searchData.類型,
                      expression: "searchData.類型",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.searchData,
                          "類型",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [
                    _vm._v("全部類型"),
                  ]),
                  _vm._l(_vm.def.marqueeType, function (option, i) {
                    return _c("option", { domProps: { value: i } }, [
                      _vm._v(_vm._s(option)),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.searchData.狀態,
                      expression: "searchData.狀態",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.searchData,
                          "狀態",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [
                    _vm._v("全部狀態"),
                  ]),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("草稿")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("立即發送")]),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("排程發送")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("已刪除")]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-bordered table-center min-800 AdListTable text-center",
              },
              [
                _vm._m(0),
                _vm.total > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.datas, function (item, index) {
                        return _c(
                          "tr",
                          {
                            key: item.id,
                            class: {
                              "bg-gray-light": /已過期|已刪除|已發送/.test(
                                _vm.GetStatus(item)
                              ),
                            },
                          },
                          [
                            _c("td", [_vm._v(_vm._s(item.id))]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "data-class": {
                                      "text-red": /已過期|已刪除|已發送/.test(
                                        _vm.GetStatus(item)
                                      ),
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.GetStatus(item)))]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.GetPlatfrom(item.顯示平台))),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.def.marqueeType[item.類型])),
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  staticClass: "text-clamp",
                                  attrs: {
                                    onclick:
                                      "this.classList.toggle('text-clamp')",
                                  },
                                },
                                [_vm._v(_vm._s(item.內容))]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(item.狀態 != 1 ? item.循環時間 : "-")
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.開始時間))),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.狀態 != 1
                                    ? _vm.uiDate(item.結束時間)
                                    : "-"
                                )
                              ),
                            ]),
                            _c(
                              "td",
                              [
                                !/已過期|已刪除|已發送/.test(
                                  _vm.GetStatus(item)
                                )
                                  ? [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editorItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      ),
                                      _vm._v("  "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v("刪除")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("tbody", [_vm._m(1)]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "box text-center no-border no-shadow mt-20" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "60px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("編號")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("狀態")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("平台")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("類型")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "160px",
              width: "20%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("內容")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("循環"), _c("wbr"), _vm._v("時間"), _c("wbr"), _vm._v("(分)")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("上線"), _c("wbr"), _vm._v("時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("下線"), _c("wbr"), _vm._v("時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "140px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("功能")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "11" } }, [_vm._v("查詢沒有資料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }