var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multiselect", {
    staticClass: "tagselect",
    class: { readonly: _vm.readonly },
    attrs: {
      options: _vm.options,
      "option-height": 100,
      name: _vm.name,
      disabled: _vm.readonly,
      multiple: "",
      "hide-selected": "",
      taggable: _vm.taggable,
      label: _vm.label,
      trackBy: _vm.label,
      placeholder: _vm.readonly
        ? "無標籤"
        : _vm.taggable
        ? "選擇或新增標籤"
        : "選擇標籤",
      selectLabel: "選擇按 Enter",
      deselectLabel: "移除按 Enter",
      selectedLabel: "已選擇",
      tagPlaceholder: "建立新標籤按 Enter ",
      showNoOptions: false,
    },
    on: { tag: _vm.addTag },
    scopedSlots: _vm._u([
      {
        key: "tag",
        fn: function ({ option, remove }) {
          return [
            _c(
              "div",
              {
                staticClass: "multiselect__tag",
                style: {
                  background: _vm.colorHash(
                    typeof option === "object" ? option[_vm.label] : option
                  ),
                },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      typeof option === "object" ? option[_vm.label] : option
                    )
                  ),
                ]),
                _c("i", {
                  staticClass: "multiselect__tag-icon",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                    mousedown: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return remove(option, $event)
                    },
                  },
                }),
              ]
            ),
          ]
        },
      },
      {
        key: "noResult",
        fn: function () {
          return [_vm._v(" 無找到相關標籤，請修改搜尋關鍵字 ")]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.modelValue,
      callback: function ($$v) {
        _vm.modelValue = $$v
      },
      expression: "modelValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }