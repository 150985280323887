var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "voteImgContent", staticClass: "modal fade newtalk" },
    [
      _c("div", { staticClass: "modal-dialog modal-sm" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.teamName,
                  expression: "data.social.teamName",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "戰隊/學校名稱" },
              domProps: { value: _vm.data.social.teamName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "teamName", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.gameName,
                  expression: "data.social.gameName",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "遊戲項目" },
              domProps: { value: _vm.data.social.gameName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "gameName", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.facebook,
                  expression: "data.social.facebook",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "Facebook" },
              domProps: { value: _vm.data.social.facebook },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "facebook", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.instagram,
                  expression: "data.social.instagram",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "Instagram" },
              domProps: { value: _vm.data.social.instagram },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "instagram", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.youtube,
                  expression: "data.social.youtube",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "youtube" },
              domProps: { value: _vm.data.social.youtube },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "youtube", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.twitch,
                  expression: "data.social.twitch",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "twitch" },
              domProps: { value: _vm.data.social.twitch },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "twitch", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.social.other,
                  expression: "data.social.other",
                },
              ],
              staticClass: "form-control mt-10",
              attrs: { type: "text", placeholder: "其它" },
              domProps: { value: _vm.data.social.other },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data.social, "other", $event.target.value)
                },
              },
            }),
            _vm._m(1),
            _c("div", { staticClass: "mt-10" }, [
              _c("button", { on: { click: _vm.uploadImg } }, [
                _vm._v("選擇檔案"),
              ]),
              _c("input", {
                staticStyle: { display: "none" },
                attrs: {
                  accept: "image/*",
                  type: "file",
                  id: "AEAImg",
                  name: "AEAImg",
                },
                on: {
                  change: function ($event) {
                    return _vm.changeImage($event)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "mt-10" }, [
              _vm.data.imageName
                ? _c("p", [_vm._v(_vm._s(_vm.data.imageName))])
                : _vm._e(),
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.data.imageUrl },
              }),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v(" 取消 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(" 送出 ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("新增連結")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "mt-10",
        staticStyle: { display: "flex", "align-items": "center" },
      },
      [
        _c("div", { staticStyle: { "min-width": "75px" } }, [
          _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
          _vm._v(" 圖片上傳 "),
        ]),
        _c("span", { staticClass: "text-red" }, [_vm._v("(圖片大小限制10MB)")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }