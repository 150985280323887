import { render, staticRenderFns } from "./ServiceList02.vue?vue&type=template&id=d652d9b2"
import script from "./ServiceList02.vue?vue&type=script&lang=js"
export * from "./ServiceList02.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\WorkSpace\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d652d9b2')) {
      api.createRecord('d652d9b2', component.options)
    } else {
      api.reload('d652d9b2', component.options)
    }
    module.hot.accept("./ServiceList02.vue?vue&type=template&id=d652d9b2", function () {
      api.rerender('d652d9b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/waninFoundation/ServiceList02.vue"
export default component.exports