var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("違規關鍵字管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("違規關鍵字管理")]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("keywordAlert", {
          ref: "keywordAlert",
          on: { submit: _vm.getKeywordsQueryAll },
        }),
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "div",
                {
                  staticClass: "btn-add",
                  staticStyle: { display: "flex", width: "150px" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.showaddkeyword()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增違規關鍵字 "),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                  },
                  [
                    _c(
                      "option",
                      { key: "1", attrs: { value: "0", select: "" } },
                      [_vm._v("Youtube")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.搜尋關鍵字,
                      expression: "searchData.搜尋關鍵字",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋違規關鍵字",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.搜尋關鍵字 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "搜尋關鍵字",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  (_vm.searchShow = 1)
                    ? _c("span", [_vm._v("資料總筆數：")])
                    : _vm._e(),
                  _vm.searchShow != 1
                    ? _c("span", [_vm._v("搜尋結果筆數：")])
                    : _vm._e(),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.data.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass: "table table-hover table-bordered",
                  staticStyle: { width: "600px" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _c(
                        "tr",
                        {
                          staticClass: "addnew",
                          class: { showup: _vm.shownewkeyword },
                        },
                        [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newkeyword,
                                  expression: "newkeyword",
                                },
                              ],
                              staticClass: "form-control pull-right",
                              attrs: {
                                name: "table_add",
                                placeholder: "輸入違規關鍵字",
                                type: "text",
                              },
                              domProps: { value: _vm.newkeyword },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.newkeyword = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text-center text-nowrap",
                              staticStyle: { "text-align": "center" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addKeyword()
                                    },
                                  },
                                },
                                [_vm._v("新增")]
                              ),
                              _vm._v("      "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addclose()
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.data.total > 0
                        ? _vm._l(_vm.data.datas, function (item, index) {
                            return _c("tr", { key: item.id }, [
                              _c(
                                "td",
                                { staticStyle: { "text-align": "left" } },
                                [_vm._v(_vm._s(item.關鍵字))]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center text-nowrap",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openVideo(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _vm._v("      "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]
                              ),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", {}, [_vm._v("關鍵字")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { width: "27%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }