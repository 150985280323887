var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header"),
      _c("SideBar"),
      _c("div", { staticClass: "control-sidebar-bg" }),
      _c(
        "div",
        { staticClass: "content-wrapper", attrs: { id: "fix-resize" } },
        [_c("router-view")],
        1
      ),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }