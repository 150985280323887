var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-select" },
    [
      _c("input", {
        ref: "input",
        attrs: {
          type: "file",
          id: _vm.id,
          multiple: _vm.multiple,
          accept: _vm.accept,
          disabled: _vm.disabled,
        },
        on: {
          change: function ($event) {
            return _vm.onChange($event)
          },
        },
      }),
      _vm._t("default"),
      _c(
        "div",
        { key: _vm.modelValue.toString(), staticClass: "image-container" },
        [
          _vm._l(_vm.files, function (image, index) {
            return [
              image.info == _vm.info && image.filesUrl != ""
                ? _c(
                    "figure",
                    { key: image.fileUrl, staticClass: "image-box" },
                    [
                      _c("div", {
                        staticClass: "image-close fa fa-fw fa-close",
                        attrs: { title: "刪除檔案" },
                        on: {
                          click: function ($event) {
                            return _vm.removeImage(index)
                          },
                        },
                      }),
                      _c("img", {
                        staticStyle: { width: "auto", height: "auto" },
                        attrs: { draggable: "false", src: image.filesUrl },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }