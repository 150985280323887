var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "main-sidebar" }, [
    _c("section", { staticClass: "sidebar" }, [
      _vm.menus
        ? _c(
            "ul",
            { staticClass: "sidebar-menu", attrs: { "data-widget": "tree" } },
            [
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/Home" } }, [
                    _c("i", { staticClass: "fa fa-fw fa-home" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("首頁")))]),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.menus, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: _vm.subMenu(item),
                    attrs: { "data-v": item.isVueMenu },
                  },
                  [
                    [
                      item.subMenu.length > 0
                        ? _c("a", { attrs: { href: "#" } }, [
                            item.menuId == "A0"
                              ? _c("i", { staticClass: "fa fa-fw fa-bullhorn" })
                              : _c("i", {
                                  staticClass: "fa fa-fw",
                                  class:
                                    _vm.includeIcon(item.menuName) ||
                                    "fa-circle",
                                }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.removeIcon(item.menuName)))
                              ),
                            ]),
                            _vm._m(0, true),
                          ])
                        : _c(
                            "router-link",
                            { attrs: { to: { path: item.menuUrl } } },
                            [
                              _c("i", {
                                staticClass: "fa fa-fw",
                                class:
                                  _vm.includeIcon(item.menuName) ||
                                  "fa-circle-o",
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t(_vm.removeIcon(item.menuName)))
                                ),
                              ]),
                            ]
                          ),
                    ],
                    [
                      _c(
                        "ul",
                        { staticClass: "treeview-menu" },
                        _vm._l(item.subMenu, function (item2, index) {
                          return _c(
                            "li",
                            { key: index, class: _vm.subMenu2(item2) },
                            [
                              [
                                item2.subMenu.length > 0
                                  ? _c("a", { attrs: { href: "#" } }, [
                                      _c("i", {
                                        staticClass: "fa fa-fw",
                                        class:
                                          _vm.includeIcon(item2.menuName) ||
                                          "fa-circle",
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.removeIcon(item2.menuName)
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._m(1, true),
                                    ])
                                  : [
                                      _c(
                                        "router-link",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item2.menuId != "AN-02",
                                              expression:
                                                "item2.menuId != 'AN-02'",
                                            },
                                          ],
                                          attrs: {
                                            to: { path: item2.menuUrl },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw",
                                            class:
                                              _vm.includeIcon(item2.menuName) ||
                                              "fa-circle-o",
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.removeIcon(item2.menuName)
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                              ],
                              [
                                _c(
                                  "ul",
                                  { staticClass: "treeview-menu" },
                                  _vm._l(
                                    item2.subMenu,
                                    function (item3, index) {
                                      return _c(
                                        "li",
                                        { key: index },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { path: item3.menuUrl },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-fw",
                                                class:
                                                  _vm.includeIcon(
                                                    item3.menuName
                                                  ) || "fa-circle-o",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t(item3.menuName))
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "pull-right-container" }, [
      _c("i", { staticClass: "fa fa-angle-left pull-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }