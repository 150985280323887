var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjGameDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("押注系統管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/GameBet" } }, [
              _vm._v("押注系統管理"),
            ]),
          ],
          1
        ),
        _c(
          "li",
          {
            class: { active: !_vm.currentEntry },
            style: _vm.currentEntry ? "cursor:pointer" : "pointer-events:none",
            on: {
              click: function ($event) {
                _vm.currentEntry = null
                _vm.currentEvent = null
                _vm.isEditing = false
                _vm.getEntryList()
              },
            },
          },
          [_vm._v("入口管理")]
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentEntry,
                expression: "currentEntry",
              },
            ],
            class: { active: !_vm.currentEvent },
            style: _vm.currentEvent ? "cursor:pointer" : "pointer-events:none",
            on: {
              click: function ($event) {
                _vm.currentEvent = null
                _vm.isEditing = false
              },
            },
          },
          [_vm._v("活動管理")]
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentEvent,
                expression: "currentEvent",
              },
            ],
            staticClass: "active",
          },
          [_vm._v("賽區編輯")]
        ),
      ]),
    ]),
    _vm.currentEntry === null
      ? _c("section", { staticClass: "content" }, [
          _c("h2", { staticClass: "text-big" }, [_vm._v("入口管理")]),
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "form",
                {
                  staticClass: "top-box",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.resetEntryList()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("查詢時間："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "input-group date" },
                        [
                          _c("div", {
                            staticClass: "input-group-addon fa fa-calendar",
                          }),
                          _c("DateRangePicker", {
                            attrs: {
                              propStartDate: _vm.entrySearchData.開始時間,
                              propEndDate: _vm.entrySearchData.結束時間,
                              propTimePicker: true,
                            },
                            on: {
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.entrySearchData,
                                  "開始時間",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.entrySearchData,
                                  "開始時間",
                                  $event
                                )
                              },
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.entrySearchData,
                                  "結束時間",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.entrySearchData,
                                  "結束時間",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mb-10",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("查詢")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add mb-10",
                      attrs: { type: "button", disabled: _vm.isEditing },
                      on: {
                        click: function ($event) {
                          return _vm.addEntry()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增入口 "),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "table-responsive mt-0" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm.entryLoading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.entryData.length === 0 && _vm.entryMessage
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v(_vm._s(_vm.entryMessage)),
                              ]),
                            ])
                          : _vm.entryData.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v("無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.entryData, function (item, index) {
                              return _c(
                                "tr",
                                {
                                  class: {
                                    "text-gray": _vm.isEditing && !item.isEdit,
                                  },
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        item.isNew
                                          ? ""
                                          : String(item.入口Id).padStart(5, "0")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        colspan: item.isEdit ? "2" : "1",
                                      },
                                    },
                                    [
                                      !item.isEdit
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.uiDate(item.入口開始時間)
                                              )
                                            ),
                                          ])
                                        : _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "input-group date",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "input-group-addon fa fa-calendar",
                                                }),
                                                _c("DateRangePicker", {
                                                  attrs: {
                                                    propStartDate:
                                                      item.入口開始時間,
                                                    propEndDate:
                                                      item.入口關閉時間,
                                                    propTimePicker: true,
                                                  },
                                                  on: {
                                                    "update:propStartDate":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          item,
                                                          "入口開始時間",
                                                          $event
                                                        )
                                                      },
                                                    "update:prop-start-date":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          item,
                                                          "入口開始時間",
                                                          $event
                                                        )
                                                      },
                                                    "update:propEndDate":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          item,
                                                          "入口關閉時間",
                                                          $event
                                                        )
                                                      },
                                                    "update:prop-end-date":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          item,
                                                          "入口關閉時間",
                                                          $event
                                                        )
                                                      },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                    ]
                                  ),
                                  !item.isEdit
                                    ? _c("td", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.uiDate(item.入口關閉時間)
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("td", [
                                    !item.isEdit
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.活動備註)),
                                        ])
                                      : _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.活動備註,
                                              expression: "item.活動備註",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "活動備註 (僅辨識用)",
                                          },
                                          domProps: { value: item.活動備註 },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                item,
                                                "活動備註",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                  ]),
                                  _c("td", [
                                    item.isEdit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveEntry(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("儲存")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { disabled: _vm.isEditing },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editEntry(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("入口編輯")]
                                        ),
                                    item.isEdit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-default ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelEntry(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning ml-5",
                                            attrs: { disabled: _vm.isEditing },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getEventList(
                                                  item.入口Id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("活動管理")]
                                        ),
                                  ]),
                                  _c("td", [
                                    item.通知遊戲
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-orange",
                                            attrs: { disabled: _vm.isEditing },
                                            on: {
                                              click: function ($event) {
                                                return _vm.notifyGameServer(
                                                  item.入口Id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("送出")]
                                        )
                                      : !item.isNew
                                      ? _c(
                                          "span",
                                          { staticClass: "text-gray" },
                                          [_vm._v("無更新")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c("Pagination", {
                  ref: "entryPagination",
                  attrs: {
                    total: _vm.entryTotal,
                    pageLimitCount: _vm.entrySearchData.pageSize,
                  },
                  on: { pageMethod: _vm.onEntryPageChange },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm.currentEvent === null
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h2", { staticClass: "text-big pull-left mt-10 mb-10" }, [
              _vm._v("活動管理 "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "(入口編號: " +
                    _vm._s(String(_vm.currentEntry).padStart(5, "0")) +
                    ")"
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                on: {
                  click: function ($event) {
                    _vm.currentEntry = null
                    _vm.isEditing = false
                    _vm.getEntryList()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回入口管理 "),
              ]
            ),
            _vm.isChanged
              ? _c("p", {
                  staticClass: "text-red pull-right mt-10 mb-10 mr-10",
                  domProps: { textContent: _vm._s(_vm.noticeMsg) },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add mb-10",
                    attrs: { type: "button", disabled: _vm.isEditing },
                    on: {
                      click: function ($event) {
                        return _vm.addEvent()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增活動 ")]
                ),
              ]),
              _c("div", { staticClass: "table-responsive mt-0" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(1),
                        _vm.eventLoading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.eventData.length === 0 && _vm.eventMessage
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v(_vm._s(_vm.eventMessage)),
                              ]),
                            ])
                          : _vm.eventData.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "20" } }, [
                                _vm._v("無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.eventData, function (item, index) {
                              return [
                                item.isEdit
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.活動地點,
                                                expression: "item.活動地點",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              rows: "3",
                                              placeholder: "活動名稱",
                                            },
                                            domProps: { value: item.活動地點 },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  item,
                                                  "活動地點",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.標語1,
                                                expression: "item.標語1",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              rows: "3",
                                              placeholder: "標語1",
                                              maxlength: "25",
                                            },
                                            domProps: { value: item.標語1 },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  item,
                                                  "標語1",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.標語2,
                                                expression: "item.標語2",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              rows: "3",
                                              placeholder: "標語2",
                                              maxlength: "30",
                                            },
                                            domProps: { value: item.標語2 },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  item,
                                                  "標語2",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("td", { attrs: { rowspan: "1" } }, [
                                        item.isNew
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.押注額,
                                                  expression: "item.押注額",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                placeholder: "押注額",
                                                min: "0",
                                                step: "1000",
                                              },
                                              domProps: { value: item.押注額 },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "押注額",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(_vm._s(item.押注額)),
                                            ]),
                                      ]),
                                      _c("td", { attrs: { rowspan: "1" } }, [
                                        item.isNew
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.賠率,
                                                  expression: "item.賠率",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                placeholder: "賠率",
                                                min: "0",
                                              },
                                              domProps: { value: item.賠率 },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "賠率",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(_vm._s(item.賠率)),
                                            ]),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.活動連結,
                                                expression: "item.活動連結",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: { rows: "3" },
                                            domProps: { value: item.活動連結 },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  item,
                                                  "活動連結",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          class: {
                                            "text-red": item.活動狀態 === false,
                                          },
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.活動狀態 === null
                                                ? "-"
                                                : item.活動狀態
                                                ? "已開啟"
                                                : "已關閉"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            rowspan: item.isNew ? "2" : "1",
                                          },
                                        },
                                        [
                                          item.isEdit
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-success",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveEvent(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("儲存")]
                                              )
                                            : _vm._e(),
                                          item.isEdit
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-default ml-5",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelEvent(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  : _c("tr", [
                                      _c("td", [_vm._v(_vm._s(item.活動地點))]),
                                      _c("td", [_vm._v(_vm._s(item.標語1))]),
                                      _c("td", [_vm._v(_vm._s(item.標語2))]),
                                      _c("td", [_vm._v(_vm._s(item.押注額))]),
                                      _c("td", [_vm._v(_vm._s(item.賠率))]),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: item.活動連結,
                                              rel: "noreferrer",
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.活動連結))]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          class: {
                                            "text-red": item.活動狀態 === false,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.活動狀態 === null
                                                ? "-"
                                                : item.活動狀態
                                                ? "已開啟"
                                                : "已關閉"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { disabled: _vm.isEditing },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editEvent(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("編輯活動")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning ml-5",
                                            attrs: { disabled: _vm.isEditing },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getCompetitionList(
                                                  item.活動Id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("編輯賽區")]
                                        ),
                                      ]),
                                    ]),
                                item.isEdit && item.isNew
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-red",
                                          staticStyle: { "min-width": "240px" },
                                          attrs: { colspan: "2" },
                                        },
                                        [
                                          _vm._v(
                                            "※壓住額及賠率送出後將不可調整"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h2", { staticClass: "text-big pull-left mt-10 mb-10" }, [
              _vm._v("編輯賽區 "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "(" +
                    _vm._s(
                      this.eventData.find((i) => i.活動Id == this.currentEvent)
                        .活動地點
                    ) +
                    ")"
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.currentEvent = null
                    _vm.isEditing = false
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回活動管理 "),
              ]
            ),
            this.eventData.find((i) => i.活動Id == this.currentEvent)
              .活動狀態 !== false
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger pull-right mr-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setEventClose()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-minus-circle" }),
                    _vm._v(" 關閉活動 "),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-success pull-right mr-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.setEventOpen()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-check-circle" }),
                    _vm._v(" 開啟活動 "),
                  ]
                ),
            _vm.isChanged
              ? _c("p", {
                  staticClass: "text-red pull-right mt-10 mb-10 mr-10",
                  domProps: { textContent: _vm._s(_vm.noticeMsg) },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "box box-primary" }, [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _vm.competitionLoading
                  ? _c(
                      "table",
                      { staticClass: "table table-bordered text-center mb-10" },
                      [_vm._m(2)]
                    )
                  : _vm.competitionData.length === 0 && _vm.competitionMessage
                  ? _c(
                      "table",
                      { staticClass: "table table-bordered text-center mb-10" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.competitionMessage))]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm.competitionData.length === 0
                  ? _c(
                      "table",
                      { staticClass: "table table-bordered text-center mb-10" },
                      [_vm._m(3)]
                    )
                  : _vm._l(
                      _vm.competitionData,
                      function (competition, competitionIndex) {
                        return _c(
                          "div",
                          { staticClass: "table-responsive mb-20 mt-10" },
                          [
                            competition != null
                              ? _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-bordered table-condensed table-striped text-center",
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      [
                                        _c(
                                          "tr",
                                          {
                                            style: `background:hsl(${
                                              (competitionIndex + 1) * 36
                                            }, 50%, 90%);`,
                                          },
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-big",
                                                style: `padding:.15em .25em;`,
                                                attrs: { colspan: "20" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "賽區 " +
                                                      (competitionIndex + 1)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tr",
                                          {
                                            staticClass: "text-nowrap",
                                            style: `background:hsl(${
                                              (competitionIndex + 1) * 36
                                            }, 50%, 80%);`,
                                          },
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "80px",
                                                  width: "5%",
                                                },
                                              },
                                              [_vm._v("場次")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "100px",
                                                  width: "10%",
                                                },
                                              },
                                              [_vm._v("玩家名稱1")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "100px",
                                                  width: "10%",
                                                },
                                              },
                                              [_vm._v("玩家名稱2")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "100px",
                                                  width: "10%",
                                                },
                                              },
                                              [_vm._v("玩家名稱3")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "100px",
                                                  width: "10%",
                                                },
                                              },
                                              [_vm._v("玩家名稱4")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "180px",
                                                  width: "10%",
                                                },
                                                attrs: { colspan: "2" },
                                              },
                                              [_vm._v("押注結束時間")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "min-width": "150px",
                                                  width: "5%",
                                                },
                                              },
                                              [_vm._v("比賽結果")]
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          competition,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              {
                                                class: {
                                                  "bg-gray-light":
                                                    _vm.isEditing &&
                                                    !item.isEdit,
                                                },
                                              },
                                              [
                                                _c("td", [
                                                  !(
                                                    item.isEdit &&
                                                    !item.是否已送出
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.場次)
                                                        ),
                                                      ])
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.場次,
                                                            expression:
                                                              "item.場次",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: `第${
                                                            index + 1
                                                          }場`,
                                                        },
                                                        domProps: {
                                                          value: item.場次,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item,
                                                              "場次",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]),
                                                _c("td", [
                                                  !(
                                                    item.isEdit &&
                                                    !item.是否已送出
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.參賽玩家[0]
                                                          )
                                                        ),
                                                      ])
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.參賽玩家[0],
                                                            expression:
                                                              "item.參賽玩家[0]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: "玩家1",
                                                          maxlength: _vm.isCJK(
                                                            item.參賽玩家[0]
                                                          )
                                                            ? 6
                                                            : 12,
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.參賽玩家[0],
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item.參賽玩家,
                                                              0,
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]),
                                                _c("td", [
                                                  !(
                                                    item.isEdit &&
                                                    !item.是否已送出
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.參賽玩家[1]
                                                          )
                                                        ),
                                                      ])
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.參賽玩家[1],
                                                            expression:
                                                              "item.參賽玩家[1]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: "玩家2",
                                                          maxlength: _vm.isCJK(
                                                            item.參賽玩家[1]
                                                          )
                                                            ? 6
                                                            : 12,
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.參賽玩家[1],
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item.參賽玩家,
                                                              1,
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]),
                                                _c("td", [
                                                  !(
                                                    item.isEdit &&
                                                    !item.是否已送出
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.參賽玩家[2]
                                                          )
                                                        ),
                                                      ])
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.參賽玩家[2],
                                                            expression:
                                                              "item.參賽玩家[2]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: "玩家3",
                                                          maxlength: _vm.isCJK(
                                                            item.參賽玩家[2]
                                                          )
                                                            ? 6
                                                            : 12,
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.參賽玩家[2],
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item.參賽玩家,
                                                              2,
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]),
                                                _c("td", [
                                                  !(
                                                    item.isEdit &&
                                                    !item.是否已送出
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.參賽玩家[3]
                                                          )
                                                        ),
                                                      ])
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.參賽玩家[3],
                                                            expression:
                                                              "item.參賽玩家[3]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: "玩家4",
                                                          maxlength: _vm.isCJK(
                                                            item.參賽玩家[3]
                                                          )
                                                            ? 6
                                                            : 12,
                                                        },
                                                        domProps: {
                                                          value:
                                                            item.參賽玩家[3],
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              item.參賽玩家,
                                                              3,
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "min-width": "80px",
                                                    },
                                                    attrs: {
                                                      colspan: item.isEdit
                                                        ? "2"
                                                        : "1",
                                                    },
                                                  },
                                                  [
                                                    !item.isEdit
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              new Date(
                                                                item.押注結束時間
                                                              ).toLocaleTimeString(
                                                                "zh-TW",
                                                                {
                                                                  hourCycle:
                                                                    "h23",
                                                                  hour: "2-digit",
                                                                  minute:
                                                                    "2-digit",
                                                                }
                                                              )
                                                            )
                                                          ),
                                                        ])
                                                      : _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "input-group time m-0",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "input-group-addon fa fa-clock-o",
                                                            }),
                                                            _c("input", {
                                                              staticClass:
                                                                "form-control timepicker",
                                                              attrs: {
                                                                type: "text",
                                                                id: "time",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                !item.isEdit
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "min-width": "80px",
                                                        },
                                                      },
                                                      [
                                                        item.結果 == ""
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-primary",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isEditing,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editCompetition(
                                                                        item,
                                                                        index,
                                                                        competitionIndex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.是否已送出
                                                                      ? "編輯時間"
                                                                      : "編輯資訊"
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.isEdit
                                                  ? _c("td", [
                                                      item.isEdit
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-success",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveCompetition(
                                                                      item,
                                                                      index,
                                                                      competitionIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("儲存")]
                                                          )
                                                        : _vm._e(),
                                                      item.isEdit
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-default ml-5",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.cancelCompetition(
                                                                      item,
                                                                      index,
                                                                      competitionIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("取消")]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _c("td", [
                                                      item.結果 == "" &&
                                                      !item.是否已送出
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "資訊未送出"
                                                              ),
                                                            ]
                                                          )
                                                        : item.結果 != ""
                                                        ? _c(
                                                            "span",
                                                            {
                                                              class: {
                                                                "text-red":
                                                                  item.結果 ==
                                                                  "取消比賽",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.結果
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.結果,
                                                                  expression:
                                                                    "item.結果",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                name: "",
                                                                id: "",
                                                              },
                                                              on: {
                                                                change: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      item,
                                                                      "結果",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setEventWinner(
                                                                      item,
                                                                      index,
                                                                      competitionIndex
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected:
                                                                      "",
                                                                    disabled:
                                                                      "",
                                                                    hidden: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "選擇結果"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                item.參賽玩家,
                                                                function (
                                                                  player,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            i,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          player
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  staticClass:
                                                                    "text-red",
                                                                  attrs: {
                                                                    value: "-1",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "取消比賽"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                    ]),
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "tr",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.eventData.find(
                                                    (i) =>
                                                      i.活動Id ==
                                                      _vm.currentEvent
                                                  ).活動狀態 !== false,
                                                expression:
                                                  "eventData.find(i=>i.活動Id == currentEvent).活動狀態 !== false",
                                              },
                                            ],
                                          },
                                          [
                                            _c("td"),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "4" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isEditing &&
                                                          competition.find(
                                                            (i) => i.isNew
                                                          ),
                                                        expression:
                                                          "isEditing && competition.find(i=>i.isNew)",
                                                      },
                                                    ],
                                                    staticClass: "text-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "※玩家名稱限制英文12字，中文6字"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isEditing &&
                                                          competition.find(
                                                            (i) => i.isEdit
                                                          ),
                                                        expression:
                                                          "isEditing && competition.find(i=>i.isEdit)",
                                                      },
                                                    ],
                                                    staticClass: "text-red",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "※日期為當天，並僅限未來時間"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  padding: ".25em",
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-success btn-sm",
                                                    attrs: {
                                                      disabled: _vm.isEditing,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addCompetition(
                                                          competitionIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-plus",
                                                    }),
                                                    _vm._v(" 新增場次 "),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.competitionData.length < 10 &&
                          _vm.eventData.find(
                            (i) => i.活動Id == _vm.currentEvent
                          ).活動狀態 !== false,
                        expression:
                          "competitionData.length < 10 && eventData.find(i=>i.活動Id == currentEvent).活動狀態 !== false",
                      },
                    ],
                    staticClass: "top-box",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-warning mb-10 mt-20",
                        attrs: { type: "button", disabled: _vm.isEditing },
                        on: {
                          click: function ($event) {
                            return _vm.addCompetition()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" 新增賽區 "),
                      ]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("入口編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "10%" } }, [
        _vm._v("入口開始"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "10%" } }, [
        _vm._v("入口關閉"),
      ]),
      _c("th", { staticStyle: { "min-width": "200px", width: "20%" } }, [
        _vm._v("活動備註"),
      ]),
      _c("th", { staticStyle: { "min-width": "190px", width: "5%" } }, [
        _vm._v("操作"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("資料送出"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("活動名稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "20%" } }, [
        _vm._v("標語1"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "20%" } }, [
        _vm._v("標語2"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("押注額"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("賠率"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "20%" } }, [
        _vm._v("活動連結"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("活動狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "190px", width: "5%" } }, [
        _vm._v("操作"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tbody", [_c("tr", [_c("td", [_vm._v("查詢中")])])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("td", [_vm._v("無資料")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }