var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "normal", attrs: { id: "ElectionPersonList" } },
    [
      _c(
        "form",
        {
          attrs: {
            id: "myForm",
            action: _vm.url,
            enctype: "application/x-www-form-urlencoded",
            method: "post",
          },
        },
        [
          _c("input", {
            attrs: { type: "hidden", id: "year", name: "year" },
            domProps: { value: _vm.searchData.year },
          }),
          _c("input", {
            attrs: { type: "hidden", id: "type", name: "type" },
            domProps: { value: _vm.searchData.type },
          }),
        ]
      ),
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("候選人管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("候選人管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("候選人列表")]),
        ]),
      ]),
      _c("section", { staticClass: "content forum-announcement" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box mb-10" }, [
              _c("div", { staticStyle: { float: "right" } }, [
                _c("div", { staticClass: "btn-custom" }, [
                  _vm._v(" 切換自訂票數 "),
                  _c(
                    "div",
                    { staticClass: "ckbx-style-8 ckbx toggleInput mb-5 ml-5" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customize,
                            expression: "customize",
                          },
                        ],
                        attrs: {
                          "false-value": false,
                          id: "tickets_type",
                          "true-value": true,
                          name: "ckbx-style-8",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.customize)
                            ? _vm._i(_vm.customize, null) > -1
                            : _vm.customize,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.customize,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.customize = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.customize = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.customize = $$c
                              }
                            },
                            function ($event) {
                              return _vm.setTicketStatus()
                            },
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "tickets_type" } }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "select-group", staticStyle: { width: "auto" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.year,
                          expression: "searchData.year",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("選擇年分"),
                      ]),
                      _c("option", { domProps: { value: 2024 } }, [
                        _vm._v("2024"),
                      ]),
                      _c("option", { domProps: { value: 2022 } }, [
                        _vm._v("2022"),
                      ]),
                      _c("option", { domProps: { value: 2020 } }, [
                        _vm._v("2020"),
                      ]),
                      _c("option", { domProps: { value: 2018 } }, [
                        _vm._v("2018"),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.type,
                        expression: "searchData.type",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getTicketStatus()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("大選類型"),
                    ]),
                    _c("option", { attrs: { value: "縣市長大選" } }, [
                      _vm._v("縣市長大選"),
                    ]),
                    _c("option", { attrs: { value: "總統大選" } }, [
                      _vm._v("總統大選"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.category,
                        expression: "searchData.category",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "category",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("大選類別"),
                    ]),
                    _vm.searchData.type == "縣市長大選"
                      ? _c("option", { attrs: { value: "市長" } }, [
                          _vm._v("市長"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "縣市長大選"
                      ? _c("option", { attrs: { value: "議員" } }, [
                          _vm._v("議員"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "總統大選"
                      ? _c("option", { attrs: { value: "總統" } }, [
                          _vm._v("總統"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "總統大選"
                      ? _c("option", { attrs: { value: "立委" } }, [
                          _vm._v("立委"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "總統大選"
                      ? _c("option", { attrs: { value: "山地原住民立委" } }, [
                          _vm._v("山地原住民立委"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "總統大選"
                      ? _c("option", { attrs: { value: "平地原住民立委" } }, [
                          _vm._v("平地原住民立委"),
                        ])
                      : _vm._e(),
                    _vm.searchData.type == "總統大選"
                      ? _c("option", { attrs: { value: "不分區立委" } }, [
                          _vm._v("不分區立委"),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "select-group", staticStyle: { width: "auto" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.candidateParty,
                          expression: "searchData.candidateParty",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "candidateParty",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("選擇黨派"),
                      ]),
                      _vm._l(_vm.goropList, function (gorup, index) {
                        return _c("option", { key: gorup.id }, [
                          _vm._v(" " + _vm._s(gorup.partyName) + " "),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm.searchData.category == "縣市長" ||
              _vm.searchData.category == "議員" ||
              _vm.searchData.category == "立委" ||
              _vm.searchData.category == "不分區立委"
                ? _c(
                    "div",
                    {
                      staticClass: "select-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.area,
                              expression: "area",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.area = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "全部" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._l(_vm.menu, function (data, key) {
                            return _c(
                              "option",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: key != "原住民",
                                    expression: "key != '原住民'",
                                  },
                                ],
                                key: key,
                                domProps: { value: key },
                              },
                              [_vm._v(" " + _vm._s(key) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              (_vm.searchData.category == "縣市長" ||
                _vm.searchData.category == "議員" ||
                _vm.searchData.category == "立委" ||
                _vm.searchData.category == "不分區立委") &&
              _vm.area != "全部"
                ? _c(
                    "div",
                    {
                      staticClass: "select-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.city,
                              expression: "searchData.city",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "city",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.setConstituency()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.countyList, function (county, key) {
                          return _c(
                            "option",
                            {
                              key: county.代碼,
                              domProps: { value: county.代碼 },
                            },
                            [_vm._v(" " + _vm._s(county.縣市) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.searchData.category == "立委" && _vm.area != "全部"
                ? _c(
                    "div",
                    {
                      staticClass: "select-group",
                      staticStyle: { width: "auto" },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.constituency,
                              expression: "searchData.constituency",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "constituency",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(
                          _vm.constituency,
                          function (constituency, index) {
                            return _c(
                              "option",
                              {
                                key: index + 1,
                                domProps: { value: index + 1 },
                              },
                              [_vm._v(" " + _vm._s(constituency) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "select-group", staticStyle: { width: "auto" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.electStatus,
                          expression: "searchData.electStatus",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "electStatus",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("狀態")]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("當選")]),
                      _c("option", { attrs: { value: "0" } }, [_vm._v("落選")]),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.candidateName,
                      expression: "searchData.candidateName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "搜尋關鍵字" },
                  domProps: { value: _vm.searchData.candidateName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "candidateName",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "text reset",
                  on: {
                    click: function ($event) {
                      return _vm.resetSearchData()
                    },
                  },
                },
                [_c("p", { staticClass: "data-num" }, [_vm._v(" 重設條件 ")])]
              ),
            ]),
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticStyle: { float: "right" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success btn-add ml-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportData()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [_vm._v(" 匯入 ")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "mt-10 mr-10",
                    staticStyle: { float: "right" },
                  },
                  [_vm._v(" *同一選舉只需匯入一份 ")]
                ),
              ]),
              _c("div", { staticClass: "text mt-5" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered AdListTable" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", { staticStyle: { "min-width": "80px" } }, [
                          _vm._v("序號"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "80px" } }, [
                          _vm._v("年分"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("大選類型"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("大選類別"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "80px" } }, [
                          _vm._v("號次"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("縣市"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("選區"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "150px" } }, [
                          _vm._v("主圖"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("姓名"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("黨派"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("票數"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "80px" } }, [
                          _vm._v("狀態"),
                        ]),
                        _vm.customize
                          ? _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("自訂票數")]
                            )
                          : _vm._e(),
                        _vm.customize
                          ? _c("th", { staticStyle: { "min-width": "80px" } }, [
                              _vm._v("自訂狀態"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "15" },
                              },
                              [_vm._v("查詢中")]
                            ),
                          ])
                        : _vm.list && _vm.list.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "15" },
                              },
                              [_vm._v("查詢無任何資料")]
                            ),
                          ])
                        : _vm._l(_vm.list, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.candidateId))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.year) + "年")]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.type))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(" " + _vm._s(item.category) + " ")]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.candidateNum))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getCountyName(item.city)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item.constituency
                                          ? "-"
                                          : _vm.constituency[
                                              item.constituency - 1
                                            ]
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: `${_vm.imgUrl}${item.candidateImage}`,
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.candidateName))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.candidateParty))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [_vm._v(_vm._s(item.tickets || 0))]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-nowrap text-center" },
                                [
                                  item.electStatus
                                    ? _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/icon-mark.png"),
                                          alt: "當選",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm.customize
                                ? _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(_vm._s(item.customizeTickets || 0))]
                                  )
                                : _vm._e(),
                              _vm.customize
                                ? _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      item.customizeElectStatus == "1"
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/icon-mark.png"),
                                              alt: "當選",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("div", { ref: "create", staticClass: "modal fade" }, [
          _c("div", { staticClass: "modal-dialog modal-sm" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { name: "announcementForm" },
                  },
                  [
                    _c("div", { staticClass: "box-body" }, [
                      _vm._m(1),
                      _c("div", [
                        _c("input", {
                          attrs: {
                            accept: ".xls,.xlsx",
                            id: "file",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "modal-footer text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { "data-dismiss": "modal", type: "button" },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.importFile()
                      },
                    },
                  },
                  [_vm._v(" 確定 ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title text-center" }, [
        _vm._v("匯入檔案"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "/uploads/大選候選人名單範例.xlsx",
            type: "button",
            download: "",
          },
        },
        [_vm._v("範例下載")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }