var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomerServiceEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.type))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/CustomerService" } }, [
              _vm._v("客服處理"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.type))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog fa fa-reply",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "customerServiceForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "table-responsive mt-10" }, [
                  _c(
                    "table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === "玩家回報",
                          expression: "type === '玩家回報'",
                        },
                      ],
                      staticClass: "table table-bordered table-striped min-600",
                    },
                    [
                      _c("tbody", [
                        _vm._m(0),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("玩家暱稱")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "35%" } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "NmjPlayerData",
                                      query: {
                                        nick: _vm.data.reactionNickname,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.data.reactionNickname))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("綁定門號")]
                          ),
                          _c("td", { staticStyle: { width: "35%" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.userData.account
                                  ? _vm.userData.account
                                  : "-"
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("回報時間"),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.uiDate(_vm.data.reactionTime))),
                          ]),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("處理進度"),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.def.serviceState[_vm.data.status])
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("問題類別"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.data.reactionType))]),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("遊戲裝置"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.data.phoneModel))]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("遊戲版本"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.data.appVersion))]),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("裝置系統版本"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.data.systemVersion))]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("玩家Email"),
                          ]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c(
                              "a",
                              { attrs: { href: `mailto:${_vm.data.email}` } },
                              [_vm._v(_vm._s(_vm.data.email))]
                            ),
                          ]),
                        ]),
                        _vm._m(1),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm._v(_vm._s(_vm.data.reactionNote)),
                          ]),
                        ]),
                        _vm.images.length > 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                      "justify-content": "flex-start",
                                    },
                                  },
                                  _vm._l(_vm.images, function (img) {
                                    return _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          margin: ".25em",
                                          cursor: "zoom-in",
                                          flex: "0 1 auto",
                                        },
                                        attrs: {
                                          href: img,
                                          target: "_blank",
                                          rel: "noopener nofollow",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            "max-height": "8em",
                                            "max-width": "100%",
                                            height: "auto",
                                          },
                                          attrs: { src: img, alt: "" },
                                        }),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === "檢舉",
                          expression: "type === '檢舉'",
                        },
                      ],
                      staticClass: "table table-bordered table-striped min-600",
                    },
                    [
                      _c("tbody", [
                        _vm._m(2),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("玩家暱稱")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "35%" } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "NmjPlayerData",
                                      query: {
                                        nick: _vm.data.reactionNickname,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.data.reactionNickname))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("被檢舉玩家暱稱")]
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "35%" } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "NmjPlayerData",
                                      query: { nick: _vm.data.sufferUId },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.data.sufferUId))]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("回報時間"),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.uiDate(_vm.data.reactionTime))),
                          ]),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("檢舉類別"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.data.reactionType))]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("聯絡信箱"),
                          ]),
                          _c("td", { attrs: { colspan: "1" } }, [
                            _c(
                              "a",
                              { attrs: { href: `mailto:${_vm.data.email}` } },
                              [_vm._v(" " + _vm._s(_vm.data.email) + " ")]
                            ),
                          ]),
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v("處理進度"),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.def.serviceState[_vm.data.status])
                            ),
                          ]),
                        ]),
                        _vm._m(3),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm._v(_vm._s(_vm.data.reactionNote)),
                          ]),
                        ]),
                        _vm.images.length > 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                      "justify-content": "flex-start",
                                    },
                                  },
                                  _vm._l(_vm.images, function (img) {
                                    return _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          margin: ".25em",
                                          cursor: "zoom-in",
                                          flex: "0 1 auto",
                                        },
                                        attrs: {
                                          href: img,
                                          target: "_blank",
                                          rel: "noopener nofollow",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            "max-height": "8em",
                                            "max-width": "100%",
                                            height: "auto",
                                          },
                                          attrs: { src: img, alt: "" },
                                        }),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _vm.data.status !== 3
                  ? _c("div", { staticClass: "table-responsive mt-30" }, [
                      _c(
                        "table",
                        { staticClass: "table table-bordered table-striped" },
                        [
                          _c("tbody", [
                            _vm._m(4),
                            _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _c(
                                  "div",
                                  { staticClass: "editor" },
                                  [
                                    _vm.data.status === 2
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.data.replyNote
                                            ),
                                          },
                                        })
                                      : _c("tinymce", {
                                          attrs: { propLang: _vm.editLang },
                                          model: {
                                            value: _vm.data.replyNote,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "replyNote",
                                                $$v
                                              )
                                            },
                                            expression: "data.replyNote",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c("div", { staticClass: "box-footer" }, [
            _vm.data.status < 2
              ? _c("div", { staticClass: "btns" }, [
                  _vm.type != "檢舉"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning btn-lg",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.save(1)
                            },
                          },
                        },
                        [_vm._v(" 儲存 ")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-lg",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.save(3)
                            },
                          },
                        },
                        [_vm._v(" 不需回覆 ")]
                      ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.save(2)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("玩家基本資料")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "bg-gray", attrs: { colspan: "4" } }, [
        _vm._v("玩家問題內容"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("玩家檢舉基本資料")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "bg-gray", attrs: { colspan: "4" } }, [
        _vm._v("玩家檢舉內容"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("客服回饋")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }