var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PlayerDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("玩家資料查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("玩家資料查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                { staticClass: "control-label ml-25" },
                [
                  _c("iCheckCheckBox", {
                    attrs: {
                      propValue: 1,
                      propChecked: _vm.timeTypes.includes(1),
                    },
                    on: { "update:checked": _vm.checkChange },
                  }),
                  _vm._v(" 創建帳號時間： "),
                ],
                1
              ),
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.local.createETime,
                        propStartDate: _vm.searchData.local.createSTime,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "createETime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "createETime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "createSTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "createSTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "clear form-group" }, [
              _c(
                "label",
                { staticClass: "control-label ml-25" },
                [
                  _c("iCheckCheckBox", {
                    attrs: {
                      propValue: 2,
                      propChecked: _vm.timeTypes.includes(2),
                    },
                    on: { "update:checked": _vm.checkChange },
                  }),
                  _vm._v(" 上次登入時間： "),
                ],
                1
              ),
              _c("div", { staticClass: "input-group date" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.local.loginETime,
                        propStartDate: _vm.searchData.local.loginSTime,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "loginETime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "loginETime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "loginSTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "loginSTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "clear input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.playUId,
                    expression: "searchData.playUId",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "玩家UID", type: "text" },
                domProps: { value: _vm.searchData.playUId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "playUId",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.playNickname,
                    expression: "searchData.playNickname",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { placeholder: "玩家暱稱", type: "text" },
                domProps: { value: _vm.searchData.playNickname },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "playNickname",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.exportOrders()
                    },
                  },
                },
                [_vm._v(" 匯出 ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-bordered text-center min-1000" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(2),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.result.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v(_vm._s(_vm.result.message)),
                          ]),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "11" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " + _vm._s(item.playUid || item.playUId) + " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(" " + _vm._s(item.playNickname) + " "),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currencyFormat")(item.level)) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currencyFormat")(item.exp)) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.define.vipStatus[item.vipLevel]) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currencyFormat")(item.vipExp)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currencyFormat")(item.gold)) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currencyFormat")(item.diamond)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(" " + _vm._s(item.createTime) + " "),
                            ]),
                            _c("td", { staticClass: "text-nowrap" }, [
                              _vm._v(" " + _vm._s(item.loginTime) + " "),
                            ]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.playerHistoryList(item)
                                    },
                                  },
                                },
                                [_vm._v(" 歷程 ")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v("玩家UID")]
      ),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v("玩家暱稱")]
      ),
      _c("th", [_vm._v("等級")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("等級經驗值")]),
      _c("th", [_vm._v("VIP等級")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("VIP經驗值")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("金幣點數")]),
      _c("th", [_vm._v("鑽石點數")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v(" 創建帳號時間 ")]
      ),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v(" 上次登入時間 ")]
      ),
      _c("th", [_vm._v("玩家歷程")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }