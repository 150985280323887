var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("萬元大獎抽獎名單")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("萬元大獎抽獎名單")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box topBox-announceList" }, [
            _c(
              "div",
              { staticClass: "date-group", staticStyle: { width: "auto" } },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("日期："),
                ]),
                _c("div", { staticClass: "input-group date datetime" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DateRangePicker", {
                        attrs: {
                          propTimePicker: true,
                          propEndDate: _vm.searchData.EndTime,
                          propStartDate: _vm.searchData.StartTime,
                        },
                        on: {
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "EndTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "EndTime", $event)
                          },
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "StartTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "StartTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("活動：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.searchData.Type,
                      expression: "searchData.Type",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.searchData,
                          "Type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.clickSearch()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "lottery" } }, [
                    _vm._v("翻牌活動"),
                  ]),
                  _c("option", { attrs: { value: "returnLottery" } }, [
                    _vm._v("老拉新活動(2023)"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group sW" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("輸入ID："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.UId,
                    expression: "UId",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: { placeholder: "搜尋ID", type: "text" },
                domProps: { value: _vm.UId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.UId = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group text" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("輸入門號："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: { placeholder: "搜尋門號", type: "text" },
                domProps: { value: _vm.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.phone = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clickSearch()
                    },
                  },
                },
                [_vm._v("查詢")]
              ),
            ]),
            _c("div", { staticClass: "text snmMT" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? [_vm._m(2)]
                      : _vm.total === 0
                      ? [_vm._m(3)]
                      : _vm._l(_vm.list, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.playerCode)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.phone)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.FormatTime(item.createTime))),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { width: "30%" } }, [_vm._v("遊戲ID")]),
      _c("th", { staticStyle: { width: "30%" } }, [_vm._v("門號")]),
      _c("th", { staticStyle: { width: "40%" } }, [_vm._v("時間")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }