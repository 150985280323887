var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("發票中獎專區")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("發票中獎專區")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "nav-tabs-custom box",
          staticStyle: { "border-top": "0px" },
        },
        [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c(
              "li",
              { class: { active: _vm.當前頁籤 == _vm.TabEnum.tab_prize } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.switchTab(_vm.TabEnum.tab_prize)
                      },
                    },
                  },
                  [_vm._v("發票中獎專區")]
                ),
              ]
            ),
            _c(
              "li",
              { class: { active: _vm.當前頁籤 == _vm.TabEnum.tab_print } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.switchTab(_vm.TabEnum.tab_print)
                      },
                    },
                  },
                  [_vm._v("發票列印歷程")]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane",
                class: { active: _vm.當前頁籤 == _vm.TabEnum.tab_prize },
              },
              [
                _c("InvoicePrizeList", {
                  on: { switcPrintTab: _vm.switcPrintTab },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                class: { active: _vm.當前頁籤 == _vm.TabEnum.tab_print },
              },
              [_c("InvoicePrintList", { ref: "invoicePrintList" })],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "overlay",
            },
            [_c("i", { staticClass: "fa fa-refresh fa-spin" })]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }