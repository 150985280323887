var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("相簿編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("相簿列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("相簿編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.classTypeId,
                            expression: "edit.classTypeId",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.OnTypeChnage()
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇分類")]
                        ),
                        _vm._l(_vm.classTypes.datas, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(_vm._s(item.className))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.detailTopInfo,
                          expression: "edit.detailTopInfo",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputAuthor",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.detailTopInfo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "detailTopInfo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.detailTopTitle,
                          expression: "edit.detailTopTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputContent",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.detailTopTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "detailTopTitle",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                this.edit.classId == "short_video"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.videoUrl,
                              expression: "edit.videoUrl",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputCosplayCharacter",
                            required: "required",
                            type: "text",
                          },
                          domProps: { value: _vm.edit.videoUrl },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "videoUrl",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                this.edit.classId != "short_video"
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-2 control-label" }, [
                        _vm._v(" 圖片： "),
                      ]),
                      _vm.addFileList.length > 0
                        ? _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              { staticClass: "row ann-imgChoose mt-10" },
                              [
                                _vm._l(_vm.addFileList, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "col-sm-12 chooseBox",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mt-10" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-sm-2 control-label",
                                            },
                                            [_vm._v(" 圖片名稱： ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-6" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.title,
                                                    expression: "item.title",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  name: "imgTitle",
                                                  type: "text",
                                                },
                                                domProps: { value: item.title },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      item,
                                                      "title",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm.addFileList.length > 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn ml-10",
                                                    class: [
                                                      index == 0
                                                        ? "btn-default"
                                                        : "btn-primary",
                                                    ],
                                                    attrs: {
                                                      type: "button",
                                                      disabled: index == 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.imageShiftUp(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-up",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.addFileList.length > 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn ml-10",
                                                    class: [
                                                      index ==
                                                      _vm.addFileList.length - 1
                                                        ? "btn-default"
                                                        : "btn-primary",
                                                    ],
                                                    attrs: {
                                                      type: "button",
                                                      disabled:
                                                        index ==
                                                        _vm.addFileList.length -
                                                          1,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.imageShiftDown(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-arrow-down",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.addFileList.length > 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-danger ml-10",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteImageApply(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "col-sm-2 control-label",
                                          },
                                          [_vm._v(" 圖片介紹： ")]
                                        ),
                                        _c("div", { staticClass: "col-sm-6" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.info,
                                                expression: "item.info",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "imgInfo",
                                              type: "text",
                                            },
                                            domProps: { value: item.info },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  item,
                                                  "info",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _vm.isCos()
                                        ? _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-sm-2 control-label",
                                                },
                                                [_vm._v(" 圖片類型： ")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "radio-date",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "radio text-left",
                                                        },
                                                        [
                                                          _c(
                                                            "iCheckRedioButton",
                                                            {
                                                              attrs: {
                                                                checkValue: "0",
                                                                message: "橫式",
                                                                name: `radioMode_${index}`,
                                                              },
                                                              model: {
                                                                value: item.alt,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "alt",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.alt",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "radio text-left",
                                                        },
                                                        [
                                                          _c(
                                                            "iCheckRedioButton",
                                                            {
                                                              attrs: {
                                                                checkValue: "1",
                                                                message: "直式",
                                                                name: `radioMode_${index}`,
                                                              },
                                                              model: {
                                                                value: item.alt,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "alt",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.alt",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "col-sm-2 control-label",
                                          },
                                          [_vm._v(" 作品上傳： ")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-10" },
                                          [
                                            _c("input", {
                                              attrs: {
                                                accept: "image/*",
                                                id: "Pic",
                                                type: "file",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onFileChange(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                            }),
                                            !item.imgFullFileName ||
                                            item.imgFullFileName == ""
                                              ? _c(
                                                  "div",
                                                  { staticClass: "help-block" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "text-red",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "建議尺寸 1024*768"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mt-10",
                                                staticStyle: { width: "250px" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: item.imgFullFileName,
                                                    name: "imgUrl",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-warning mt-10 mb-10",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOption()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-plus" }),
                                    _vm._v(" 其他作品 "),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "顯示",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "隱藏",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputAuthor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 作者名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputContent" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("作品名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "inputCosplayCharacter" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("作品連結： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }