var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ContactUsList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("聯絡網銀-友善連結")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("聯絡網銀-友善連結")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c("Table2", {
              attrs: {
                "table-layout": _vm.tableLayout,
                "editor-layout": _vm.editorLayout,
                "table-data": "",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }