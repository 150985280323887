var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ForumMuteSetting" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _c("span", [_vm._v(" " + _vm._s(_vm.專案))]),
        _vm._v(" - 關鍵字回覆設定"),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FBRobot/RobotList" } }, [
              _vm._v("FB機器人"),
            ]),
          ],
          1
        ),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.backList()
                },
              },
            },
            [_vm._v("貼文設定/關鍵字列表")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("關鍵字回覆設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content forum-mute" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("關鍵字回覆設定"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "80%" },
                },
                [
                  _c("div", { staticClass: "form-group sr-only" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 編號: " + _vm._s(_vm.edit.代號) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-lg-2 col-md-2 col-sm-3" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: 1,
                                message: "是",
                                name: "radioNeedKey",
                              },
                              model: {
                                value: _vm.needKey,
                                callback: function ($$v) {
                                  _vm.needKey = $$v
                                },
                                expression: "needKey",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-2 col-md-2 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: 0,
                              message: "否",
                              name: "radioNeedKey",
                            },
                            model: {
                              value: _vm.needKey,
                              callback: function ($$v) {
                                _vm.needKey = $$v
                              },
                              expression: "needKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.needKey
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c(
                            "div",
                            { staticClass: "keyWrapper" },
                            [
                              _vm._l(_vm.keys, function (item, index) {
                                return _c("div", { staticClass: "keyText" }, [
                                  _vm._v(" " + _vm._s(item) + " "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-keyClose fa fa-close",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delKey(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("close"),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.itemKey,
                                    expression: "itemKey",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "target_keywords",
                                  placeholder: "請輸入關鍵字",
                                  type: "text",
                                },
                                domProps: { value: _vm.itemKey },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.keyEnter()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.itemKey = $event.target.value.trim()
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ],
                            2
                          ),
                          _c("span", { staticClass: "text-red" }, [
                            _vm._v('關鍵字請以"Enter"送出'),
                          ]),
                          _c("br"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.needKey
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "col-sm-2 control-label" }, [
                          _vm._v("留言訊息： "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-lg-2 col-md-2 col-sm-3" },
                          [
                            _c("div", { staticClass: "radio-date" }, [
                              _c(
                                "div",
                                { staticClass: "radio" },
                                [
                                  _c("iCheckRedioButton", {
                                    attrs: {
                                      checkValue: 0,
                                      message: "部分符合",
                                      name: "radioNeeds",
                                    },
                                    model: {
                                      value: _vm.edit.符合度,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.edit, "符合度", $$v)
                                      },
                                      expression: "edit.符合度",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-lg-2 col-md-3 col-sm-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "radio" },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: 1,
                                    message: "完全符合",
                                    name: "radioNeeds",
                                  },
                                  model: {
                                    value: _vm.edit.符合度,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "符合度", $$v)
                                    },
                                    expression: "edit.符合度",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-lg-2 col-md-2 col-sm-3" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: 1,
                                message: "是",
                                name: "radioAutoLike",
                              },
                              model: {
                                value: _vm.edit.自動按讚,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "自動按讚", $$v)
                                },
                                expression: "edit.自動按讚",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-2 col-md-2 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: 0,
                              message: "否",
                              name: "radioAutoLike",
                            },
                            model: {
                              value: _vm.edit.自動按讚,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "自動按讚", $$v)
                              },
                              expression: "edit.自動按讚",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "commentMsgConten" },
                      },
                      [_vm._v("留言回覆內容： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.留言回覆訊息,
                            expression: "edit.留言回覆訊息",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "commentMsgConten",
                          placeholder:
                            "請輸入留言回覆文字，不填寫即不做留言回覆",
                          rows: "4",
                          cols: "100",
                        },
                        domProps: { value: _vm.edit.留言回覆訊息 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "留言回覆訊息",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "commentMsgImg" },
                      },
                      [_vm._v(" 留言回覆圖片： ")]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "commentMsgImg",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event, "comment")
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "text-red",
                        domProps: { innerHTML: _vm._s(_vm.picLimit) },
                      }),
                      _c("br"),
                      _vm.edit.留言圖片
                        ? _c("div", { staticClass: "pic has-imgClose mt-10" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-imgClose fa fa-close",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile("comment")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("close"),
                                ]),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.edit.留言圖片,
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { "max-width": "200px" },
                                  attrs: {
                                    draggable: "false",
                                    src: _vm.edit.留言圖片,
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "messengerMsgConten" },
                      },
                      [_vm._v("私訊回覆內容： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.私訊回覆訊息,
                            expression: "edit.私訊回覆訊息",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "messengerMsgConten",
                          placeholder:
                            "請輸入私訊回覆文字，不填寫即不做私訊回覆",
                          rows: "4",
                          cols: "100",
                        },
                        domProps: { value: _vm.edit.私訊回覆訊息 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "私訊回覆訊息",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "messengerMsgImg" },
                      },
                      [_vm._v(" 私訊回覆圖片： ")]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "messengerMsgImg",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event, "messenger")
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "text-red",
                        domProps: { innerHTML: _vm._s(_vm.picLimit) },
                      }),
                      _c("br"),
                      _vm.edit.私訊圖片
                        ? _c("div", { staticClass: "pic has-imgClose mt-10" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-imgClose fa fa-close",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile("messenger")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("close"),
                                ]),
                              ]
                            ),
                            _c("img", {
                              staticStyle: { "max-width": "200px" },
                              attrs: {
                                draggable: "false",
                                src: _vm.edit.私訊圖片,
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v(" 放棄設定 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 需要關鍵字： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "target_keywords" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("關鍵字： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 自動按讚： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }