var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "formDialog", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c(
        "form",
        {
          ref: "form",
          staticClass: "modal-content",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _vm._t("header", function () {
                return [
                  _vm._m(0),
                  _c("h4", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.title))),
                  ]),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "modal-body" }, [_vm._t("default")], 2),
          _c(
            "div",
            { staticClass: "modal-footer text-center" },
            [
              _vm._t("footer", function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { "min-width": "8em" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("確認送出")))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      staticStyle: { "min-width": "8em" },
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v(_vm._s(_vm.$t("取消")))]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }