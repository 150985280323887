var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "modal-header" }, [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
            on: {
              click: function ($event) {
                return _vm.$parent.getDataList()
              },
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
        _vm.loading
          ? _c("h4", { staticClass: "modal-title" })
          : _c("h4", { staticClass: "modal-title blue" }, [
              _vm._v(
                " [" +
                  _vm._s(_vm.getText(_vm.item)) +
                  "] - " +
                  _vm._s(_vm.item.reportTargetId) +
                  " "
              ),
            ]),
        _vm.data.articleTitle
          ? _c("h4", {
              staticClass: "modal-title link",
              domProps: { innerHTML: _vm._s(_vm.data.articleTitle) },
              on: {
                click: function ($event) {
                  return _vm.linkItem(_vm.data)
                },
              },
            })
          : _vm._e(),
      ]),
      _vm.loading
        ? _c("div", { staticClass: "modal-body" }, [_vm._v("Loading...")])
        : _vm.getText(_vm.item) == "話題"
        ? [
            !_vm.item.content
              ? _c("div", { staticClass: "modal-body" }, [_vm._v("查無資料")])
              : _c("div", {
                  staticClass: "modal-body",
                  domProps: { innerHTML: _vm._s(_vm.item.content) },
                }),
          ]
        : [
            !_vm.data.content
              ? _c("div", { staticClass: "modal-body" }, [_vm._v("查無資料")])
              : _c("div", {
                  staticClass: "modal-body",
                  domProps: { innerHTML: _vm._s(_vm.data.content) },
                }),
          ],
      _vm.data.tags
        ? _c(
            "div",
            { staticClass: "tags" },
            _vm._l(_vm.data.tags, function (item, index) {
              return _c("a", { key: index }, [
                _vm._v(" #" + _vm._s(item.Tag) + " "),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.getText(_vm.item) == "話題"
        ? void 0
        : [
            _vm.item.content ? _c("hr") : _vm._e(),
            _vm.item.content
              ? _c(
                  "div",
                  { staticClass: "modal-body bg-gray-light ml-20 mr-20 mb-20" },
                  [
                    _vm.item.TagReplyId &&
                    _vm.item.commentFloor &&
                    _vm.item.TagReplyFloor
                      ? _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function ($event) {
                                return _vm.showReplyTag(_vm.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " @" +
                                _vm._s(_vm.item.commentFloor) +
                                "-" +
                                _vm._s(_vm.item.TagReplyFloor) +
                                "樓 "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("span", {
                      staticClass: "ml-5",
                      domProps: { innerHTML: _vm._s(_vm.item.content) },
                    }),
                  ]
                )
              : _vm._e(),
          ],
      _c("div", { staticClass: "modal-footer" }, [
        _c("div", { staticStyle: { float: "left" } }, [
          _c("span", [
            _vm._v(
              "檢舉原因：" +
                _vm._s(
                  _vm.item.reportData == "其他"
                    ? `${_vm.item.reportData}(${_vm.item.note})`
                    : _vm.item.reportData
                )
            ),
          ]),
          _c("br"),
          _vm.item.isDelete
            ? _c("span", { staticClass: "text-red fl" }, [
                _vm._v(" (此" + _vm._s(_vm.item.text) + "已被刪除) "),
              ])
            : _vm._e(),
        ]),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.readItem(_vm.item)
              },
            },
          },
          [_vm._v(" 已讀 ")]
        ),
        !_vm.item.isDelete && _vm.getDataList
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showDelete(_vm.item)
                  },
                },
              },
              [_vm._v(" 刪除 ")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "btn btn-default pull-right",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$parent.getDataList()
              },
            },
          },
          [_vm._v(" 關閉 ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }