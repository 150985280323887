var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "with-border-top",
      staticStyle: { padding: "12px 5px", "background-color": "white" },
    },
    [
      _c("div", { staticClass: "input-group sendMsg_group" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.msg,
              expression: "msg",
            },
          ],
          ref: "inputArea",
          staticClass: "form-control input_textarea",
          attrs: {
            id: "inputArea",
            rows: "10",
            placeholder: _vm.提示訊息,
            disabled: !_vm.是否可發訊息,
          },
          domProps: { value: _vm.msg },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              if (
                $event.ctrlKey ||
                $event.shiftKey ||
                $event.altKey ||
                $event.metaKey
              )
                return null
              return _vm.keyEnter.apply(null, arguments)
            },
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.msg = $event.target.value
              },
              _vm.inputEvent,
            ],
          },
        }),
        _c("div", { staticClass: "input-group-btn" }, [
          _c(
            "button",
            {
              ref: "sendBtn",
              staticClass: "btn btn-warning btn-flat sendMsg",
              attrs: { type: "button", disabled: !_vm.是否可發訊息 },
              on: { click: _vm.SendChatMessage },
            },
            [_c("i", { staticClass: "fa fa-send" })]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }