var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "gameContentEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲內容")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲管理")]),
        _c("li", [_vm._v("活動列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲內容")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.$route.params.name) + "內容說明"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "none" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _vm.addData.名稱 == "Info" ||
                        _vm.addData.名稱 == "MG 盤面" ||
                        _vm.addData.名稱 == "Loading 頁"
                          ? [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0",
                                    "padding-top": "7px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.addData.名稱) + " ")]
                              ),
                              _vm.addData.名稱 == "Info"
                                ? _c("p", { staticClass: "text-red" }, [
                                    _vm._v(
                                      " Info 的區塊視覺，也用於遊戲館卡片，滑入放大時顯示的視覺。 "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.addData.名稱,
                                    expression: "addData.名稱",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "inputTitle",
                                  required: "required",
                                  type: "text",
                                  maxlength: "50",
                                },
                                domProps: { value: _vm.addData.名稱 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.addData,
                                      "名稱",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.addData.名稱.length) + " / 50"
                                ),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _vm._v(" " + _vm._s(_vm.addData.視覺型態) + " "),
                      _c(
                        "div",
                        {
                          staticClass: "mb-20",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "radio mr-10" },
                            [
                              _c("iCheckRedioButton", {
                                attrs: {
                                  checkValue: "1",
                                  message: "圖片",
                                  name: "radioMode",
                                },
                                model: {
                                  value: _vm.視覺型態,
                                  callback: function ($$v) {
                                    _vm.視覺型態 = $$v
                                  },
                                  expression: "視覺型態",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("input", {
                              attrs: {
                                accept: "image/*",
                                id: "AnnouncementPic",
                                type: "file",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onFileChange($event)
                                },
                              },
                            }),
                            _c("span", { staticClass: "text-red" }, [
                              _vm._v(
                                "上限為 5 MB 的 PNG、JPG 檔，直式或橫式圖片，最長邊不超過 320 px。"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "max-width": "320px" } },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.addData.預覽視覺集合[0].視覺路徑,
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mb-20",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "radio mr-10" },
                            [
                              _c("iCheckRedioButton", {
                                attrs: {
                                  checkValue: "2",
                                  message: "影片",
                                  name: "radioMode",
                                },
                                model: {
                                  value: _vm.視覺型態,
                                  callback: function ($$v) {
                                    _vm.視覺型態 = $$v
                                  },
                                  expression: "視覺型態",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "calc(100% - 61px)" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.addData.預覽視覺集合[1].視覺路徑,
                                    expression:
                                      "addData.預覽視覺集合[1].視覺路徑",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "inputTitle",
                                  required: "required",
                                  type: "text",
                                  disabled: _vm.視覺型態 != 2,
                                },
                                domProps: {
                                  value: _vm.addData.預覽視覺集合[1].視覺路徑,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.addData.預覽視覺集合[1],
                                      "視覺路徑",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v(
                                  "請貼上 YT 分享 > 複製網址的格式，例如：https://youtu.be/OOOOOOO"
                                ),
                              ]),
                              _c("div", [
                                _vm.addData.預覽視覺集合[1].視覺路徑
                                  ? _c("iframe", {
                                      staticClass: "mt-10",
                                      attrs: {
                                        src: `https://www.youtube.com/embed/${
                                          _vm.addData.預覽視覺集合[1].視覺路徑.split(
                                            "youtu.be/"
                                          )[1]
                                        }`,
                                        title: "YouTube video player",
                                        frameborder: "0",
                                        allow:
                                          "acc:elerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                                        allowfullscreen: "",
                                        width: "560",
                                        height: "315",
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [_vm._v(" 預覽說明： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.addData.預覽說明,
                            expression: "addData.預覽說明",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                          maxlength: "50",
                        },
                        domProps: { value: _vm.addData.預覽說明 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.addData,
                              "預覽說明",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.addData.預覽說明.length) + " / 50"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor mt-5" },
                        [
                          _c("tinymce", {
                            model: {
                              value: _vm.addData.詳細說明,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "詳細說明", $$v)
                              },
                              expression: "addData.詳細說明",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning pull-left mr-10",
                      on: {
                        click: function ($event) {
                          return _vm.preview()
                        },
                      },
                    },
                    [_vm._v(" 預覽詳細說明 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-left",
                      attrs: {
                        disabled:
                          _vm.addData.名稱 == "" ||
                          _vm.addData.詳細說明 == "" ||
                          (_vm.視覺型態 == "1" &&
                            _vm.addData.預覽視覺集合[0].視覺路徑 == "") ||
                          (_vm.視覺型態 == "2" &&
                            _vm.addData.預覽視覺集合[1].視覺路徑 == ""),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default pull-right",
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "preview",
          staticClass: "modal fade",
          attrs: { id: "detailAlert" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(3),
                _c("h4", { staticClass: "modal-title text-center" }, [
                  _vm._v(_vm._s(_vm.addData.名稱)),
                ]),
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.addData.詳細說明) },
                }),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 預覽視覺： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 詳細說明： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }