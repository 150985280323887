var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AlertBox" } }, [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "attention", "data-backdrop": "static" },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(0),
              _c("h4", { staticClass: "modal-title text-red" }, [
                _c("i", { staticClass: "fa fa-warning" }),
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", {
                attrs: { id: "attention-message" },
                domProps: { innerHTML: _vm._s(_vm.message) },
              }),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { id: "attention-delete", type: "button" },
                },
                [_vm._v(_vm._s(_vm.$t(`確定`)))]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: {
                    id: "attention-dismiss",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [_vm._v(_vm._s(_vm.$t(`關閉`)))]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _vm._m(8),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal fade", attrs: { id: "booking" } }, [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  "aria-label": "Close",
                  "data-dismiss": "modal",
                  type: "button",
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title" }, [_vm._v("預約會議室")]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered table-striped" },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("會議室名稱")]),
                      _c("td", [_vm._v("28F-B1")]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("設備")]),
                      _c("td", [_vm._v("主機(內網)、主機(外網)、投影機")]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("座位")]),
                      _c("td", [_vm._v("6人")]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("會議日期")]),
                      _c("td", [_vm._v("2019/07/01")]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("會議時段")]),
                      _c("td", { staticClass: "form-inline" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("select", { staticClass: "form-control" }, [
                            _c("option", [_vm._v("09:00")]),
                          ]),
                        ]),
                        _vm._v("至 "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("select", { staticClass: "form-control" }, [
                            _c("option", [_vm._v("10:00")]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("預約人")]),
                      _c("td", [_vm._v("星寶")]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("會議主題")]),
                      _c("td", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { required: "required", type: "text" },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("確定預約")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("取消")]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "edit-room" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("新增(修改)會議室"),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c("form", [
                  _c(
                    "table",
                    { staticClass: "table table-bordered table-striped" },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("會議室名稱")]),
                          _c("td", [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { required: "required", type: "text" },
                            }),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("設備")]),
                          _c("td", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 投影機 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 主機(內網) "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 主機(外網) "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 白板 "),
                                ]),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-orange" }, [
                              _c("i", { staticClass: "fa fa-warning" }),
                              _vm._v(" 如需新增設備請洽系統開發人員。 "),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("座位(人)")]),
                          _c("td", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  max: "100",
                                  min: "1",
                                  required: "required",
                                  type: "number",
                                },
                              }),
                              _c("span", { staticClass: "help-block" }, [
                                _vm._v("不得為空"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("可預約的會議時段")]),
                          _c("td", [
                            _c("div", { staticClass: "form-group" }, [
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 09:00-10:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 10:00-11:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 11:00-12:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 12:00-13:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 13:00-14:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 14:00-15:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 15:00-16:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 16:00-17:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 17:00-18:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 18:00-19:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 19:00-20:00 "),
                                ]),
                              ]),
                              _c("div", { staticClass: "checkbox" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "flat",
                                    attrs: { type: "checkbox" },
                                  }),
                                  _vm._v(" 20:00-21:00 "),
                                ]),
                              ]),
                            ]),
                            _c("p", { staticClass: "text-orange" }, [
                              _c("i", { staticClass: "fa fa-warning" }),
                              _vm._v(" 如需新增時段請洽系統開發人員。 "),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v("確定新增(修改)")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("取消")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "booking-area" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [_vm._v("預約交誼廳")]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-striped" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("活動日期")]),
                        _c("td", [_vm._v("2019/07/01")]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("會議時段")]),
                        _c("td", { staticClass: "form-inline" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("select", { staticClass: "form-control" }, [
                              _c("option", [_vm._v("18:00")]),
                            ]),
                          ]),
                          _vm._v("至 "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("select", { staticClass: "form-control" }, [
                              _c("option", [_vm._v("19:00")]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("預約人")]),
                        _c("td", [_vm._v("星寶")]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("活動主題")]),
                        _c("td", [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { required: "required", type: "text" },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("參與人數")]),
                        _c("td", [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              max: "100",
                              min: "1",
                              required: "required",
                              type: "number",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v("確定預約")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("取消")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "add-group" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("新增(修改)群組"),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputGroupName" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 群組名稱： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputGroupName",
                        required: "required",
                        type: "text",
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputGroupDescription" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 群組描述： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputGroupDescription",
                        required: "required",
                        type: "text",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "button" } },
                [_vm._v("新增(修改)")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("關閉")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "add-features" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("新增(修改)功能"),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputFeaturesID" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 功能代號： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputFeaturesID",
                        required: "required",
                        type: "text",
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputFeaturesName" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 功能名稱： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputFeaturesName",
                        required: "required",
                        type: "text",
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputFeaturesNum" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 排序： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputFeaturesNum",
                        required: "required",
                        type: "text",
                      },
                    }),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "text-muted" }, [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" 為網頁MENU "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputFeaturesName2" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 頁面路徑： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputFeaturesName2",
                        required: "required",
                        type: "text",
                      },
                    }),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v("※ 例如：/Announcements"),
                    ]),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "text-muted" }, [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { type: "checkbox" },
                        }),
                        _vm._v(" 開放設定 "),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "button" } },
                [_vm._v("新增(修改)")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("關閉")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "role-attention" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title text-red" }, [
                _c("i", { staticClass: "fa fa-warning" }),
                _vm._v(" 注意 "),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [_vm._v("您確定要切換成此帳號？")]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "button" } },
                [_vm._v("確定")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("關閉")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "role-setting" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("[aaa.aaa] 角色設定"),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", { staticClass: "form-horizontal" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "inputGroupName" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 選擇角色： "),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "radio" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { id: "role1", name: "role", type: "radio" },
                        }),
                        _vm._v(" 系統管理員 "),
                        _c("i", { staticClass: "fa fa-long-arrow-right" }),
                        _vm._v(" 僅權限管理不能使用，其他都能用 "),
                      ]),
                    ]),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", [
                        _c("input", {
                          staticClass: "flat",
                          attrs: { id: "role2", name: "role", type: "radio" },
                        }),
                        _vm._v(" SystemAdmin "),
                        _c("i", { staticClass: "fa fa-long-arrow-right" }),
                        _vm._v(" 系統開發人員管理角色 "),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning mt-10",
                        attrs: { type: "button" },
                      },
                      [
                        _c("i", { staticClass: "fa fa-arrow-down" }),
                        _vm._v(" 新增 "),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("角色名稱")]),
                      _c("th", [_vm._v("角色敘述")]),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "90px" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("SystemAdmin")]),
                      _c("td", [_vm._v("系統開發人員管理角色")]),
                      _c("td", { staticClass: "text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: {
                              "data-target": "#attention",
                              "data-toggle": "modal",
                            },
                          },
                          [_vm._v("刪除")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "button" } },
                [_vm._v("儲存")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default pull-right",
                  attrs: { "data-dismiss": "modal", type: "button" },
                },
                [_vm._v("關閉")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade newcomers-info",
        attrs: { id: "newcomers-info" },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    "aria-label": "Close",
                    "data-dismiss": "modal",
                    type: "button",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
              _c("h4", { staticClass: "modal-title" }, [_vm._v("廖OO")]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "pic jqimgFill" }, [
                _c("img", {
                  attrs: {
                    draggable: "false",
                    src: require("../assets/user-160x160.jpg"),
                  },
                }),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-striped" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("單位")]),
                        _c("th", [_vm._v("工號")]),
                        _c("th", [_vm._v("職等")]),
                        _c("th", [_vm._v("職稱")]),
                        _c("th", [_vm._v("報到日期")]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("企劃處-產品企劃部")]),
                        _c("td", [_vm._v("104744")]),
                        _c("td", [_vm._v("2")]),
                        _c("td", [_vm._v("企劃師")]),
                        _c("td", [_vm._v("2019/07/01")]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }