var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "SNDetail" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("序號明細")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("序號管理")]),
        _c("li", [_vm._v("獎項列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("序號明細")]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box topBox-activityManger" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog w100-md",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 獎項列表 ")]
              ),
              _vm.projectId == "omp_mftd" || _vm.projectId == "omp_heti"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-cog",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addSN()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增序號 "),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-success btn-cog w100-md",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.importSN()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-file" }),
                      _vm._v(" 匯入序號 "),
                    ]
                  ),
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("獎項："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.awardsId,
                        expression: "searchData.awardsId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "awardsId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  _vm._l(_vm.awards.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.awardsId } },
                      [_vm._v(_vm._s(item.awardsTitle))]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("序號批次："),
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.patchId,
                        expression: "searchData.patchId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "patchId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                    _vm._l(_vm.queryPatch.datas, function (item, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: item.patchId } },
                        [_vm._v(_vm._s(item.patchId))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group sW" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("輸入使用者："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.uId,
                      expression: "searchData.uId",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋使用者",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.uId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "uId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group sW" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("輸入序號："),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.sn,
                      expression: "searchData.sn",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋序號",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.sn },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "sn", $event.target.value)
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn verAlign-bottom" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "input-group search-group" }, [
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-danger btn-cog",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.DeletePatch()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-trash" }),
                      _vm._v(" 批次刪除 "),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text snmMT" }, [
                _c("p", { staticClass: "data-num" }, [
                  (_vm.searchShow = 1)
                    ? _c("span", [_vm._v("資料總筆數：")])
                    : _vm._e(),
                  _vm.searchShow != 1
                    ? _c("span", [_vm._v("搜尋結果筆數：")])
                    : _vm._e(),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.exchangeCode.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.exchangeCode.total > 0
                        ? _vm._l(_vm.exchangeCode.datas, function (item) {
                            return _c("tr", { key: item.id }, [
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.id)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.patchId)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.sn)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.vendorSN)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.uId)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(_vm.FormatTime(item.usedTime))),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.isUsed == "1"
                                        ? "已使用"
                                        : item.isUsed == "2"
                                        ? "待確認"
                                        : "未使用"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.note)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  _vm._s(
                                    item.createUName + " " + item.createUId
                                  )
                                ),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(_vm.FormatTime(item.createTime))),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                              ]),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.exchangeCode.total > 0,
                  expression: "exchangeCode.total > 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.exchangeCode.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("publicSerialNumber", { ref: "publicSerialNumber" }),
        _c("settingSerialNumber", { ref: "settingSerialNumber" }),
        _c("EditSN", { ref: "EditSN" }),
        _c("importData", { ref: "importData" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "6%" } }, [
        _vm._v("ID"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("序號批次"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("序號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "8%" } }, [
        _vm._v("原廠序號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "8%" } }, [
        _vm._v("使用者"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("使用日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "3%" } }, [
        _vm._v("是否使用"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("備註"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("建立者"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("建立時間"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "8%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }