var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ForumReportList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("論壇檢舉訊息")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("論壇檢舉訊息")]),
        ]),
      ]),
      _c("section", { staticClass: "content forum-report" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "form-group clearfix" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("檢舉時間："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _vm._m(0),
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.endTime,
                        propStartDate: _vm.searchData.startTime,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "endTime", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "endTime", $event)
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "startTime", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "startTime", $event)
                        },
                        restList: _vm.GetRestList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.reportId,
                      expression: "searchData.reportId",
                    },
                  ],
                  staticClass: "form-control mr-10",
                  attrs: { placeholder: "檢舉人門號", type: "text" },
                  domProps: { value: _vm.searchData.reportId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "reportId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.reportedId,
                      expression: "searchData.reportedId",
                    },
                  ],
                  staticClass: "form-control mr-10",
                  attrs: { placeholder: "被檢舉人門號", type: "text" },
                  domProps: { value: _vm.searchData.reportedId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "reportedId",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_vm._v("查詢")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submitReport()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-minus-circle" }),
                    _vm._v(" 禁言 "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.haveRead()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-check" }), _vm._v(" 已讀 ")]
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "text text-red",
                  staticStyle: { width: "100%" },
                },
                [_vm._v("* 禁言天數不輸入或大於3650天，為永久禁言。")]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-1200" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", [
                          _c("div", { staticClass: "checkbox-inline" }, [
                            _c(
                              "label",
                              [
                                _c("iCheckCheckBox", {
                                  attrs: { propChecked: _vm.checkAllStatus },
                                  on: {
                                    input: function ($event) {
                                      return _vm.checkAll()
                                    },
                                  },
                                  model: {
                                    value: _vm.checkAllStatus,
                                    callback: function ($$v) {
                                      _vm.checkAllStatus = $$v
                                    },
                                    expression: "checkAllStatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("th", [_vm._v("編號")]),
                        _c("th", { staticStyle: { "min-width": "180px" } }, [
                          _vm._v("檢舉時間"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "120px" } }, [
                          _vm._v("檢舉人門號"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "120px" } }, [
                          _vm._v("被檢舉人門號"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v("文章ID"),
                        ]),
                        _c("th", { staticStyle: { "min-width": "200px" } }, [
                          _vm._v("檢舉內容"),
                        ]),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "200px", width: "20%" },
                          },
                          [_vm._v("禁言備註")]
                        ),
                        _c("th", { staticStyle: { "min-width": "80px" } }, [
                          _vm._v("禁言天數"),
                        ]),
                      ]),
                      _vm.reportTotal === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "10" },
                              },
                              [_vm._v("沒有資料")]
                            ),
                          ])
                        : _vm._l(_vm.reportList, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", { staticClass: "text-center" }, [
                                _c("div", { staticClass: "checkbox-inline" }, [
                                  _c(
                                    "label",
                                    [
                                      _c("iCheckCheckBox", {
                                        attrs: { propChecked: item.checked },
                                        on: {
                                          input: function ($event) {
                                            return _vm.checkItem(item)
                                          },
                                        },
                                        model: {
                                          value: item.checked,
                                          callback: function ($$v) {
                                            _vm.$set(item, "checked", $$v)
                                          },
                                          expression: "item.checked",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.reportDate.split(".")[0])),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.reportId))]),
                              _c("td", [_vm._v(_vm._s(item.reportedId))]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showContent(
                                          item.reportTargetId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.reportTargetId))]
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.reportData))]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.note,
                                      expression: "item.note",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: item.note },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "note",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.days,
                                      expression: "item.days",
                                    },
                                  ],
                                  staticClass: "form-control text-center",
                                  staticStyle: { "min-width": "100px" },
                                  attrs: {
                                    min: "1",
                                    placeholder: "輸入天數",
                                    type: "number",
                                  },
                                  domProps: { value: item.days },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "days",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.itemDays(item)
                                      },
                                    ],
                                  },
                                }),
                              ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border clearfix" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.pageSize,
                      expression: "searchData.pageSize",
                    },
                  ],
                  staticClass: "form-control page-size",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "pageSize",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.pageDisplay()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "10" } }, [
                    _vm._v("單頁顯示10項"),
                  ]),
                  _c("option", { attrs: { value: "20" } }, [
                    _vm._v("單頁顯示20項"),
                  ]),
                  _c("option", { attrs: { value: "40" } }, [
                    _vm._v("單頁顯示40項"),
                  ]),
                  _c("option", { attrs: { value: "100" } }, [
                    _vm._v("單頁顯示100項"),
                  ]),
                ]
              ),
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.reportTotal > 0,
                    expression: "reportTotal > 0",
                  },
                ],
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.reportTotal,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("forumReportAlert", {
        ref: "forumReportAlert",
        attrs: {
          propsArticleId: _vm.getArticleId,
          propsIDList: _vm.getMuteId,
          propsList: _vm.getMuteList,
          propsSerNum: _vm.checkedList,
        },
        on: { restList: _vm.GetRestList },
      }),
      _c("forumContent", {
        ref: "forumContent",
        attrs: { propsCategoryNo: _vm.categoryNo, propsID: _vm.itemID },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }