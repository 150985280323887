import { render, staticRenderFns } from "./BigDataKOL.vue?vue&type=template&id=aadc24a6"
import script from "./BigDataKOL.vue?vue&type=script&lang=js"
export * from "./BigDataKOL.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\WorkSpace\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aadc24a6')) {
      api.createRecord('aadc24a6', component.options)
    } else {
      api.reload('aadc24a6', component.options)
    }
    module.hot.accept("./BigDataKOL.vue?vue&type=template&id=aadc24a6", function () {
      api.rerender('aadc24a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/spider/BigDataKOL.vue"
export default component.exports