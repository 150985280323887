var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("商品資料設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("商品資料設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-header" }, [
            _c(
              "div",
              { staticClass: "input-group-for-multiselect-wrap" },
              [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("產品："),
                ]),
                _c("multiselect", {
                  staticClass: "multiselect-width",
                  attrs: {
                    placeholder: "請選取產品",
                    options: _vm.廠商List,
                    label: "資料",
                    "track-by": "編號",
                    "show-labels": false,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.searchData.廠商,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchData, "廠商", $$v)
                    },
                    expression: "searchData.廠商",
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "input-group-addon",
                    on: {
                      click: function ($event) {
                        return _vm.openFirmPopForm()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-pencil" })]
                ),
              ],
              1
            ),
            _c("label", { staticClass: "input-group" }, [
              _c("span", { staticClass: "input-group-span" }, [
                _vm._v("商品編號："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.商品編號,
                    expression: "searchData.商品編號",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "請輸入 商品編號" },
                domProps: { value: _vm.searchData.商品編號 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "商品編號",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "input-group-for-multiselect-wrap" },
              [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("介接廠商："),
                ]),
                _c("multiselect", {
                  staticClass: "multiselect-width",
                  attrs: {
                    placeholder: "請選取介接廠商",
                    options: _vm.介接商List,
                    label: "資料",
                    "track-by": "編號",
                    "show-labels": false,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.searchData.介接商,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchData, "介接商", $$v)
                    },
                    expression: "searchData.介接商",
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "input-group-addon",
                    on: {
                      click: function ($event) {
                        return _vm.openFactoryPopForm()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-pencil" })]
                ),
              ],
              1
            ),
            _c("label", { staticClass: "input-group" }, [
              _c("span", { staticClass: "input-group-span" }, [
                _vm._v("ERP品號："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.ERP品號,
                    expression: "searchData.ERP品號",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "請輸入 ERP品號" },
                domProps: { value: _vm.searchData.ERP品號 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "ERP品號",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-search" }), _vm._v(" 查詢 ")]
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.productEdit.openAddForm()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _vm._m(0),
              _c(
                "tbody",
                [
                  _vm._l(_vm.list, function (item) {
                    return _c("tr", { key: item.編號 }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("filter廠商")(item.廠商編號, _vm.廠商List)
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.商品編號))]),
                      _c("td", [_vm._v(_vm._s(item.商品名稱))]),
                      _c("td", [_vm._v(_vm._s(item.顯示名稱))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("filter介接商")(
                              item.介接廠商編號,
                              _vm.介接商List
                            )
                          )
                        ),
                      ]),
                      _c(
                        "td",
                        _vm._l(item.來源Key_Array, function (來源, sn) {
                          return _c("div", { key: sn }, [_vm._v(_vm._s(來源))])
                        }),
                        0
                      ),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("TransEnum")(item.分類, _vm.分類Enum))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("TransYN")(item.彈性點YN))),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.erP品號))]),
                      _c("td", [_vm._v(_vm._s(item.erP名稱))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("TransEnum")(item.erP庫別, _vm.ERP庫別Enum)
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.帳務幣別))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("TransYN")(item.開立發票YN))),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "ckbx-style-8 ckbx toggleInput",
                            on: {
                              click: function ($event) {
                                return _vm.updEnable(item)
                              },
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.停用YN,
                                  expression: "item.停用YN",
                                },
                              ],
                              attrs: {
                                "false-value": true,
                                "true-value": false,
                                name: "ckbx-style-8",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(item.停用YN)
                                  ? _vm._i(item.停用YN, null) > -1
                                  : _vm._q(item.停用YN, false),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = item.停用YN,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? false : true
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          item,
                                          "停用YN",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          item,
                                          "停用YN",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(item, "停用YN", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", {
                              attrs: { for: "check" + item.編號 },
                            }),
                          ]
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.備註))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.productEdit.openUpdForm(item)
                              },
                            },
                          },
                          [_vm._v(" 編輯 ")]
                        ),
                      ]),
                    ])
                  }),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.list.length == 0,
                          expression: "list.length == 0",
                        },
                      ],
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { colspan: "15" },
                        },
                        [_vm._v("無資料")]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("ProductEdit", {
        ref: "productEdit",
        on: {
          openFirmPopForm: function ($event) {
            return _vm.openFirmPopForm()
          },
          openFactoryPopForm: function ($event) {
            return _vm.openFactoryPopForm()
          },
          reload: _vm.reload,
        },
      }),
      _c("OneClickPopForm", { ref: "oneClickPopForm" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", [_vm._v("產品")]),
        _c("th", [_vm._v("商品編號")]),
        _c("th", [_vm._v("商品名稱")]),
        _c("th", [_vm._v("顯示名稱")]),
        _c("th", [_vm._v("介接廠商")]),
        _c("th", [_vm._v("來源Key")]),
        _c("th", [_vm._v("分類")]),
        _c("th", [_vm._v("彈性點")]),
        _c("th", [_vm._v("ERP品號")]),
        _c("th", [_vm._v("ERP名稱")]),
        _c("th", [_vm._v("ERP庫別")]),
        _c("th", [_vm._v("帳務幣別")]),
        _c("th", [_vm._v("開立發票YN")]),
        _c("th", [_vm._v("停用/啟用")]),
        _c("th", [_vm._v("備註")]),
        _c("th", [_vm._v("功能")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }