var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "PopForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "800px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "box",
            class:
              _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.二聯
                ? "style2聯"
                : "style3聯",
          },
          [
            _c("div", { staticClass: "box-header with-border" }, [
              _c("h3", { staticClass: "box-title" }, [_vm._v("發票資料")]),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.Close()
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("開立時間")]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !(_vm.編輯模式 == _vm.編輯模式Enum.檢視),
                          expression: "!(編輯模式 == 編輯模式Enum.檢視)",
                        },
                      ],
                    },
                    [
                      _c("date-picker", {
                        attrs: {
                          config: {
                            format: "YYYY-MM-DD",
                            showTodayButton: true,
                            showClear: true,
                            useCurrent: false,
                            sideBySide: true,
                            locale: "zh-tw",
                          },
                        },
                        model: {
                          value: _vm.edit.發票時間,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "發票時間", $$v)
                          },
                          expression: "edit.發票時間",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                          expression: "編輯模式 == 編輯模式Enum.檢視",
                        },
                      ],
                    },
                    [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", disabled: "" },
                        domProps: { value: _vm.edit.發票時間 },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("發票種類")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.發票種類,
                          expression: "edit.發票種類",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "發票種類",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.發票種類Enum, function (item, key) {
                      return _c("option", { domProps: { value: item } }, [
                        _vm._v(_vm._s(key) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.edit.發票種類 == _vm.發票種類Enum.二聯捐贈
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("div", [
                        _c("label", { staticClass: "addItemBtn" }, [
                          _vm._v("愛心碼"),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn聯別 btn-xs addItemBtn",
                            attrs: {
                              type: "button",
                              disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.company.Open(
                                  true,
                                  _vm.手開發票常用資料類別Enum.愛心碼
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" 選擇愛心碼 "),
                          ]
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.愛心碼,
                            expression: "edit.愛心碼",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "愛心碼",
                          disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                        },
                        domProps: { value: _vm.edit.愛心碼 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "愛心碼",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.edit.發票種類 == _vm.發票種類Enum.二聯載具
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("div", [
                        _c("label", { staticClass: "addItemBtn" }, [
                          _vm._v("載具條碼"),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn聯別 btn-xs addItemBtn",
                            attrs: {
                              type: "button",
                              disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.company.Open(
                                  true,
                                  _vm.手開發票常用資料類別Enum.載具條碼
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" 選擇條碼 "),
                          ]
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.載具條碼,
                            expression: "edit.載具條碼",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "載具條碼",
                          disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                        },
                        domProps: { value: _vm.edit.載具條碼 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "載具條碼",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.edit.發票種類 == _vm.發票種類Enum.三聯紙本 ||
                _vm.edit.發票種類 == _vm.發票種類Enum.三聯交換
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("div", [
                        _c("label", { staticClass: "addItemBtn" }, [
                          _vm._v("公司統編"),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn聯別 btn-xs addItemBtn",
                            attrs: {
                              type: "button",
                              disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.company.Open(
                                  true,
                                  _vm.手開發票常用資料類別Enum.統一編號
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" 選擇公司 "),
                          ]
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.公司統編,
                            expression: "edit.公司統編",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "公司統編",
                          disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                        },
                        domProps: { value: _vm.edit.公司統編 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "公司統編",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.edit.發票種類 == _vm.發票種類Enum.三聯紙本 ||
                _vm.edit.發票種類 == _vm.發票種類Enum.三聯交換
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("公司名稱")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.公司名稱,
                            expression: "edit.公司名稱",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "公司名稱",
                          disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                        },
                        domProps: { value: _vm.edit.公司名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "公司名稱",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("備註")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.備註,
                        expression: "edit.備註",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "備註",
                      disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                    },
                    domProps: { value: _vm.edit.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "備註", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("div", [
                      _c("label", { staticClass: "addItemBtn" }, [
                        _vm._v("商品明細"),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn聯別 btn-xs",
                          attrs: {
                            type: "button",
                            disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                          },
                          on: {
                            click: function ($event) {
                              _vm.新增商品()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(" 新增商品 "),
                        ]
                      ),
                    ]),
                    _c("InvoiceElectronicProcessItems", {
                      ref: "itemsForm",
                      attrs: {
                        營業稅: _vm.itemData.營業稅,
                        營業稅別: _vm.itemData.營業稅別,
                        商品明細: _vm.itemData.商品明細,
                        發票種類: _vm.edit.發票種類,
                        isEdit: _vm.itemData.isEdit,
                        Const銷售總額: _vm.itemData.Const銷售總額,
                        Const發票金額: _vm.itemData.Const發票金額,
                      },
                      on: {
                        "update:營業稅": function ($event) {
                          return _vm.$set(_vm.itemData, "營業稅", $event)
                        },
                        "update:營業稅別": function ($event) {
                          return _vm.$set(_vm.itemData, "營業稅別", $event)
                        },
                        "update:商品明細": function ($event) {
                          return _vm.$set(_vm.itemData, "商品明細", $event)
                        },
                        "update:發票種類": function ($event) {
                          return _vm.$set(_vm.edit, "發票種類", $event)
                        },
                        "update:isEdit": function ($event) {
                          return _vm.$set(_vm.itemData, "isEdit", $event)
                        },
                        "update:is-edit": function ($event) {
                          return _vm.$set(_vm.itemData, "isEdit", $event)
                        },
                        "update:Const銷售總額": function ($event) {
                          return _vm.$set(_vm.itemData, "Const銷售總額", $event)
                        },
                        "update:const銷售總額": function ($event) {
                          return _vm.$set(_vm.itemData, "Const銷售總額", $event)
                        },
                        "update:Const發票金額": function ($event) {
                          return _vm.$set(_vm.itemData, "Const發票金額", $event)
                        },
                        "update:const發票金額": function ($event) {
                          return _vm.$set(_vm.itemData, "Const發票金額", $event)
                        },
                        刪除商品: function ($event) {
                          _vm.刪除商品($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-right": "15px" },
                    attrs: { type: "button" },
                    on: { click: _vm.Close },
                  },
                  [_vm._v("關閉")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn聯別",
                    attrs: {
                      type: "button",
                      disabled: _vm.編輯模式 == _vm.編輯模式Enum.檢視,
                    },
                    on: { click: _vm.save },
                  },
                  [_vm._v("儲存")]
                ),
              ]),
            ]),
            _c("div", { ref: "loading", staticClass: "overlay" }, [
              _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            ]),
            _c("InvoiceElectronicProcessOneClickSetting", {
              ref: "company",
              on: { companyClick: _vm.companyClick },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }