var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("圖片編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("圖片列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("圖片編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.displayTitle,
                          expression: "edit.displayTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.displayTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "displayTitle",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "newtalkLogo" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.getFileUrlByType(_vm.whiteBackgroundNews),
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "white_background_news",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange(
                              $event,
                              _vm.whiteBackgroundNews
                            )
                          },
                        },
                      }),
                      [_vm._m(2)],
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "newtalkLogo" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.getFileUrlByType(_vm.greenBackgroundNews),
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "green_background_news",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange(
                              $event,
                              _vm.greenBackgroundNews
                            )
                          },
                        },
                      }),
                      [_vm._m(4)],
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(5),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "newtalkLogo" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.getFileUrlByType(_vm.whiteBackgroundForum),
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "white_background_forum",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange(
                              $event,
                              _vm.whiteBackgroundForum
                            )
                          },
                        },
                      }),
                      [_vm._m(6)],
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(7),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "newtalkLogo" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.getFileUrlByType(_vm.greenBackgroundForum),
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "green_background_forum",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange(
                              $event,
                              _vm.greenBackgroundForum
                            )
                          },
                        },
                      }),
                      [_vm._m(8)],
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(9),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "newtalkLogo" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.getFileUrlByType(_vm.voteNews),
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "vote_news",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event, _vm.voteNews)
                          },
                        },
                      }),
                      [_vm._m(10)],
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(11),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "顯示",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "隱藏",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(12),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(13),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [_c("DatePicker", { ref: "formalStartDate" })],
                          1
                        ),
                      ]),
                      _vm._m(14),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(15),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(16),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [_c("DatePicker", { ref: "formalEndDate" })],
                          1
                        ),
                      ]),
                      _vm._m(17),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖片名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "white_background_news" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("新頭殼白底相片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 350 * 55"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "green_background_news" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("新頭殼綠底相片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note2" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 314 * 55"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "white_background_forum" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("討論區白底相片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note3" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 395 * 55"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "green_background_forum" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("討論區綠底相片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note4" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 350 * 55"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "vote_news" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("新頭殼投票相片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note5" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 350 * 55"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 正式開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "formalStartTime", type: "text" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 正式結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "formalEndTime", type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }