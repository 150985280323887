var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "chatForm",
        staticClass: "direct-chat-messages",
        class: { fixedHeight: _vm.historyType == "none" },
        attrs: { id: "defineHeight" },
        on: { scroll: _vm.onScroll },
      },
      _vm._l(_vm.messageData, function (item) {
        return _c(
          "div",
          { key: item.ChatId, attrs: { time: item.Time } },
          [
            item.firstEvent
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#EBF7F3",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(_vm._s(item.EventId))]
                )
              : _vm._e(),
            item.ChatMsgType == _vm.ChatMsgType.CsToPlayer ||
            item.ChatMsgType == _vm.ChatMsgType.OwnToCs ||
            item.ChatMsgType == _vm.ChatMsgType.OwnToGroup
              ? [
                  _c("div", { staticClass: "direct-chat-msg right" }, [
                    _c("div", { staticClass: "direct-chat-info clearfix" }, [
                      _c(
                        "span",
                        { staticClass: "direct-chat-name pull-right" },
                        [_vm._v(" " + _vm._s(item.SenderName) + " ")]
                      ),
                      _c(
                        "span",
                        { staticClass: "direct-chat-timestamp pull-left" },
                        [_vm._v(_vm._s(_vm._f("格式化時間")(item.Time)))]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "direct-chat-img",
                        staticStyle: {
                          "background-color": "#f39c12",
                          "border-color": "#f39c12",
                          color: "#fff",
                        },
                      },
                      [_c("div", [_vm._v(_vm._s(item.SenderName))])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "direct-chat-text",
                        class: [
                          _vm.historyType == "CSHistory"
                            ? "direct-chat-text-cs"
                            : "",
                        ],
                        staticStyle: {
                          "white-space": "pre-wrap",
                          "word-wrap": "break-word",
                        },
                        style: {
                          color: item.MsgColor ? item.MsgColor : "#ffffff",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.Msg) + " ")]
                    ),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "direct-chat-msg" }, [
                    _c("div", { staticClass: "direct-chat-info clearfix" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                item.ChatMsgType != _vm.ChatMsgType.PlayerToCs,
                              expression:
                                "item.ChatMsgType != ChatMsgType.PlayerToCs",
                            },
                          ],
                          staticClass: "direct-chat-name pull-left",
                        },
                        [_vm._v(_vm._s(item.SenderName))]
                      ),
                      _c(
                        "span",
                        { staticClass: "direct-chat-timestamp pull-right" },
                        [_vm._v(_vm._s(_vm._f("格式化時間")(item.Time)))]
                      ),
                    ]),
                    _c("div", { staticClass: "direct-chat-img" }, [
                      _c("div", [_vm._v(_vm._s(item.SenderName))]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "direct-chat-text",
                        staticStyle: {
                          "white-space": "pre-wrap",
                          "word-wrap": "break-word",
                        },
                        style: {
                          color: item.MsgColor ? item.MsgColor : "#000000",
                        },
                      },
                      [_vm._v(_vm._s(item.Msg) + " ")]
                    ),
                  ]),
                ],
          ],
          2
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
        },
      },
      [
        _c(
          "div",
          {
            ref: "showTopTime",
            staticClass: "box-title",
            staticStyle: { display: "none" },
          },
          [_vm._v(" 這裡用來暫放滾動訊息視窗的捲軸時，要出現的文字 ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }