var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table table-hover table-bordered min-800" },
    [
      _c(
        "tbody",
        [
          _vm._m(0),
          _vm.orders.length > 0
            ? _vm._l(_vm.orders, function (item) {
                return _c("tr", { key: item.id }, [
                  _c("td", [_vm._v(_vm._s(item.訂單編號))]),
                  _c("td", [_vm._v(_vm._s(item.廠商訂單編號))]),
                  _c("td", [_vm._v(_vm._s(item.儲值類型))]),
                  _c("td", [_vm._v(_vm._s(item.玩家暱稱))]),
                  _c("td", [_vm._v(_vm._s(item.玩家識別值))]),
                  _c("td", [_vm._v(_vm._s(item.訂單狀態))]),
                  _c("td", [_vm._v(_vm._s(item.幣別))]),
                  _c("td", [_vm._v(_vm._s(item.交易金額))]),
                  _c("td", [_vm._v(_vm._s(item.通知配點狀態))]),
                  _c("td", [_vm._v(_vm._s(item.配點金額))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$utils.formatTime(
                          item.建立日期,
                          "YYYY/MM/DD HH:mm:ss"
                        )
                      )
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isRequireCheck(item),
                            expression: "isRequireCheck(item)",
                          },
                        ],
                        staticClass: "btn btn-primary",
                        on: {
                          click: function ($event) {
                            return _vm.checkOrder(item)
                          },
                        },
                      },
                      [_vm._v("重新驗證")]
                    ),
                  ]),
                ])
              })
            : [_vm._m(1)],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: {} }, [_vm._v("訂單編號")]),
      _c("th", { staticStyle: {} }, [_vm._v("GASH交易序號")]),
      _c("th", { staticStyle: {} }, [_vm._v("代收方式")]),
      _c("th", { staticStyle: {} }, [_vm._v("暱稱")]),
      _c("th", { staticStyle: {} }, [_vm._v("玩家識別值")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("訂單狀態")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("幣別")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("金額")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("配點狀態")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("配點金額")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("建立日期")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "12" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }