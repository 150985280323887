var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.editTitle))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公告列表")]),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.editTitle))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.detailTitle)),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "70%" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newsType,
                              expression: "newsType",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: _vm.isEdit },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.newsType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "官方" } }, [
                            _vm._v("官方"),
                          ]),
                          _c(
                            "option",
                            { attrs: { value: "個人", hidden: !_vm.isEdit } },
                            [_vm._v("個人")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.newsType == "官方"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.categoryNo,
                                  expression: "edit.categoryNo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                required: "required",
                                disabled: _vm.isEdit,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.edit,
                                    "categoryNo",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "0", disabled: "" } },
                                [_vm._v("請選擇語系")]
                              ),
                              _vm._l(_vm.categoryquery, function (item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.categoryNo },
                                  },
                                  [_vm._v(_vm._s(item.language))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.articleTitle,
                            expression: "edit.articleTitle",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                          disabled: _vm.newsType == "個人",
                        },
                        domProps: { value: _vm.edit.articleTitle },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "articleTitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { propLang: _vm.editLang },
                            model: {
                              value: _vm.edit.articleContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "articleContent", $$v)
                              },
                              expression: "edit.articleContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm.newsType == "官方"
                ? _c("div", { staticClass: "box-footer" }, [
                    _c("div", { staticClass: "btns" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-lg",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save()
                            },
                          },
                        },
                        [_vm._v(" 確認送出 ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 公告分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 語系切換： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 主旨： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }