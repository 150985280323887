var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormDialog",
    {
      ref: "memberOfficialDialog",
      attrs: { title: _vm.$t("角色轉正") },
      on: { submit: _vm.submit },
    },
    [
      _c("div", { staticClass: "table-responsive mt-0" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("角色暱稱")))]),
              _c("td", [_vm._v(_vm._s(_vm.data.暱稱))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.分類,
                        expression: "data.分類",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "分類",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          value: "",
                          selected: "",
                          disabled: "",
                          hidden: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                    ),
                    _vm._l(_vm.types, function (name, id) {
                      return _c(
                        "option",
                        { key: id, domProps: { value: name } },
                        [_vm._v(_vm._s(name))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("內容")))]),
              _c("td", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.內容,
                      expression: "data.內容",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { min: "1", rows: "5", required: "" },
                  domProps: { value: _vm.data.內容 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "內容", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }