var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "forum_content",
      staticClass: "modal fade",
      attrs: { id: "forumContent" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", {
              staticClass: "modal-title",
              domProps: { innerHTML: _vm._s(_vm.edit.articleTitle) },
            }),
          ]),
          _c("div", {
            staticClass: "modal-body",
            domProps: { innerHTML: _vm._s(_vm.edit.articleContent) },
          }),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("關閉")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }