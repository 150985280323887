var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "top-box",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.clickSearch()
          },
        },
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-warning btn-add mb-10",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.add()
              },
            },
          },
          [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增內容 ")]
        ),
      ]
    ),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "table table-bordered text-center" }, [
        _c(
          "tbody",
          [
            _vm._m(0),
            _vm.message
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "10" } }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                ])
              : _vm.total <= 0
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "10" } }, [
                    _vm._v("查詢無任何資料"),
                  ]),
                ])
              : _vm._l(_vm.datas, function (item, index) {
                  return _c("tr", { key: item.gid }, [
                    _c("td", { staticClass: "text-nowrap" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "5px",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: item.排序,
                                expression: "item.排序",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass: "form-control p-5",
                            staticStyle: {
                              "padding-right": "0",
                              "text-align": "center",
                            },
                            attrs: {
                              type: "number",
                              min: "1",
                              max: "",
                              disabled: !item.是否發佈,
                            },
                            domProps: { value: item.排序 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  item,
                                  "排序",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning btn-add btn-upDate",
                              attrs: {
                                type: "button",
                                disabled: !item.是否發佈,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateOrder(item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw fa-arrows-v" }),
                              _vm._v("更新 "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "text-left" }, [
                      !item.isEdit
                        ? _c("div", [
                            _c("img", {
                              staticStyle: { "max-height": "100px" },
                              attrs: {
                                src: _vm.getImage(item, "desktop"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticStyle: { "max-height": "100px" },
                              attrs: {
                                src: _vm.getImage(item, "mobile"),
                                alt: "",
                              },
                            }),
                            _c("div", [
                              _c("h4", [_vm._v(_vm._s(_vm.getTitle(item)))]),
                              _c("p", [_vm._v(_vm._s(_vm.getSubtitle(item)))]),
                            ]),
                          ])
                        : _c(
                            "form",
                            { staticClass: "form-horizontal box-body" },
                            [
                              _c("fieldset", { staticClass: "form-group" }, [
                                _c("legend", [_vm._v("上傳 Banner：")]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "pull-left control-label required",
                                        attrs: { for: "bannerPc" },
                                      },
                                      [_vm._v("桌機版：")]
                                    ),
                                    _c(
                                      "ImageSelect",
                                      {
                                        attrs: {
                                          id: "bannerPc",
                                          gid: item.bannerId,
                                          module: "Banner",
                                          info: "desktop",
                                          accept:
                                            "image/jpeg, image/png, image/gif",
                                          maxsize: 500 * 1024,
                                        },
                                        model: {
                                          value: item.files,
                                          callback: function ($$v) {
                                            _vm.$set(item, "files", $$v)
                                          },
                                          expression: "item.files",
                                        },
                                      },
                                      [
                                        _c("p", { staticClass: "help-block" }, [
                                          _vm._v(
                                            "※僅限 JPG、PNG、GIF 格式，尺寸為 00px×00px，檔案上限為 500KB。"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "pull-left control-label required",
                                        attrs: { for: "bannerMobile" },
                                      },
                                      [_vm._v("手機版：")]
                                    ),
                                    _c(
                                      "ImageSelect",
                                      {
                                        attrs: {
                                          id: "bannerMobile",
                                          gid: item.bannerId,
                                          module: "Banner",
                                          info: "mobile",
                                          accept:
                                            "image/jpeg, image/png, image/gif",
                                          maxsize: 500 * 1024,
                                        },
                                        model: {
                                          value: item.files,
                                          callback: function ($$v) {
                                            _vm.$set(item, "files", $$v)
                                          },
                                          expression: "item.files",
                                        },
                                      },
                                      [
                                        _c("p", { staticClass: "help-block" }, [
                                          _vm._v(
                                            "※僅限 JPG、PNG、GIF 格式，尺寸為 00px×00px，檔案上限為 500KB。"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("fieldset", { staticClass: "form-group" }, [
                                _c("legend", { staticClass: "mt-20" }, [
                                  _vm._v("是否壓上標題或按鈕："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "grid-column": "1/-1" } },
                                  [
                                    _c("div", { staticClass: "mb-10" }, [
                                      _c(
                                        "span",
                                        { staticClass: "radio-inline mr-10" },
                                        [
                                          _c("iCheckRedioButton", {
                                            attrs: {
                                              checkValue: 0,
                                              message: "無",
                                              name: "radio選項",
                                            },
                                            model: {
                                              value: item.選項,
                                              callback: function ($$v) {
                                                _vm.$set(item, "選項", $$v)
                                              },
                                              expression: "item.選項",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "radio-inline mr-10" },
                                        [
                                          _c("iCheckRedioButton", {
                                            attrs: {
                                              checkValue: 1,
                                              message: "標題",
                                              name: "radio選項",
                                            },
                                            model: {
                                              value: item.選項,
                                              callback: function ($$v) {
                                                _vm.$set(item, "選項", $$v)
                                              },
                                              expression: "item.選項",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "radio-inline mr-10" },
                                        [
                                          _c("iCheckRedioButton", {
                                            attrs: {
                                              checkValue: 2,
                                              message: "按鈕",
                                              name: "radio選項",
                                            },
                                            model: {
                                              value: item.選項,
                                              callback: function ($$v) {
                                                _vm.$set(item, "選項", $$v)
                                              },
                                              expression: "item.選項",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    item.選項 == 1
                                      ? _c(
                                          "div",
                                          [
                                            _c("MultilangInput", {
                                              staticClass: "mb-10",
                                              attrs: {
                                                label: "大標題",
                                                langs: _vm.langs,
                                                prop: "bannerTitle",
                                              },
                                              model: {
                                                value: item.大標題,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "大標題", $$v)
                                                },
                                                expression: "item.大標題",
                                              },
                                            }),
                                            _c("MultilangInput", {
                                              staticClass: "mb-10",
                                              attrs: {
                                                label: "小標題 (選填)",
                                                langs: _vm.langs,
                                                prop: "bannerTitle",
                                                required: false,
                                              },
                                              model: {
                                                value: item.小標題,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "小標題", $$v)
                                                },
                                                expression: "item.小標題",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.選項 == 2
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              gap: ".5em",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "control-label required",
                                                attrs: { for: "btnLabel" },
                                              },
                                              [_vm._v("按鈕文字：")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.按鈕文字,
                                                  expression: "item.按鈕文字",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "btnLabel",
                                                maxlength: "10",
                                                placeholder:
                                                  "統一使用英文 (限10個字元以內)",
                                              },
                                              domProps: {
                                                value: item.按鈕文字,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "按鈕文字",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "control-label",
                                                attrs: { for: "btnLabel" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.按鈕文字.length) +
                                                    " / 10"
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _c("fieldset", { staticClass: "form-group" }, [
                                _c("legend", { staticClass: "mt-20" }, [
                                  _vm._v("指定連結："),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "radio-inline mr-10" },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: 0,
                                        message: "遊戲介紹頁：",
                                        name: "radio指定連結",
                                      },
                                      on: {
                                        input: function ($event) {
                                          item.連結參數 = ""
                                        },
                                      },
                                      model: {
                                        value: item.指定連結,
                                        callback: function ($$v) {
                                          _vm.$set(item, "指定連結", $$v)
                                        },
                                        expression: "item.指定連結",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.連結參數,
                                          expression: "item.連結參數",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        list: "gameList",
                                        placeholder: "請輸入遊戲名稱",
                                      },
                                      domProps: { value: item.連結參數 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "連結參數",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "datalist",
                                      { attrs: { id: "gameList" } },
                                      _vm._l(_vm.gameList, function (game) {
                                        return _c(
                                          "option",
                                          { domProps: { value: game.name } },
                                          [_vm._v(_vm._s(/* game.name */))]
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "radio-inline mr-10" },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: 1,
                                        message: "網址：",
                                        name: "radio指定連結",
                                      },
                                      model: {
                                        value: item.指定連結,
                                        callback: function ($$v) {
                                          _vm.$set(item, "指定連結", $$v)
                                        },
                                        expression: "item.指定連結",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.連結參數,
                                          expression: "item.連結參數",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "btnLabel",
                                        placeholder: "https://",
                                      },
                                      domProps: { value: item.連結參數 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "連結參數",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                    ]),
                    _c("td", [
                      _c(
                        "fieldset",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            gap: "5px",
                          },
                          attrs: { disabled: _vm.isEditing && !item.isEdit },
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.isEdit,
                                  expression: "!item.isEdit",
                                },
                              ],
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw fa-pencil" }),
                              _vm._v("修改 "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isEdit,
                                  expression: "item.isEdit",
                                },
                              ],
                              staticClass: "btn btn-success",
                              on: {
                                click: function ($event) {
                                  return _vm.save(item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw fa-save" }),
                              _vm._v("儲存 "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.isEdit,
                                  expression: "!item.isEdit",
                                },
                              ],
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteType(item, index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw fa-close" }),
                              _vm._v("刪除 "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isEdit,
                                  expression: "item.isEdit",
                                },
                              ],
                              staticClass: "btn btn-default",
                              on: {
                                click: function ($event) {
                                  return _vm.cancel(item, index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw fa-reply" }),
                              _vm._v("取消 "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                }),
          ],
          2
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total,
            expression: "total",
          },
        ],
        staticClass: "box-footer text-center no-border",
      },
      [
        _c("Pagination", {
          ref: "pagination",
          attrs: {
            total: _vm.total,
            pageLimitCount: _vm.searchData.pageSize,
            type2: true,
          },
          on: { pageMethod: _vm.onPageChange },
        }),
      ],
      1
    ),
    _c("pre", [_vm._v(_vm._s(_vm.datas))]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "240px", width: "90%" } }, [
        _vm._v("內容"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("動作"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }