var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "pictureview", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", { staticStyle: { height: "600px" } }, [
                  _c("td", { attrs: { align: "center" } }, [
                    _c("img", {
                      style: _vm.rotateStyle,
                      attrs: { id: "idCard", src: _vm.dataUrl },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "modal-footer",
            staticStyle: { "text-align": "center" },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: { click: _vm.undo },
              },
              [_c("i", { staticClass: "fa fa-undo" })]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.changePic()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.picCount == 1 ? "下一張" : "上一張"))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info",
                attrs: { type: "button" },
                on: { click: _vm.repeat },
              },
              [_c("i", { staticClass: "fa fa-repeat" })]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("圖片檢視")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }