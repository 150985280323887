var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ClassTypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("群組管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("群組管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  staticClass: "form-control",
                  attrs: { id: "selectType", required: "required" },
                },
                [
                  _c("option", { attrs: { value: "0", disabled: "" } }, [
                    _vm._v("請選擇訂閱類型"),
                  ]),
                  _vm._l(_vm.types, function (type, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [_vm._v(" " + _vm._s(type) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _c("span", [_vm._v("資料總筆數：")]),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(" " + _vm._s(_vm.currentData.length) + " "),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered AdListTable",
                attrs: { id: "groupSetTable" },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm._l(_vm.currentData, function (item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", [_vm._v(_vm._s(item.classTypeId))]),
                        _c("td", [_vm._v(_vm._s(item.description))]),
                        _c("td", { attrs: { align: "center" } }, [
                          _vm._v(_vm._s(item.module)),
                        ]),
                        _c("td", { staticClass: "td-sort text-nowrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.sortId,
                                expression: "item.sortId",
                              },
                            ],
                            attrs: { type: "number" },
                            domProps: { value: item.sortId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "sortId", $event.target.value)
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning btn-add btn-upDate",
                              attrs: { type: "button" },
                            },
                            [_vm._v(" 更新 ")]
                          ),
                        ]),
                        _c("td", { attrs: { align: "center" } }, [
                          _vm._v(_vm._s(item.isStop)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("button", { staticClass: "btn btn-primary" }, [
                            _vm._v("修改"),
                          ]),
                          _vm._v("   "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [_vm._v(" 刪除 ")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    total: _vm.currentData.total,
                    pageLimitCount: _vm.searchData.pageSize,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group" }, [
      _c("input", {
        staticClass: "form-control pull-right",
        attrs: {
          name: "table_search",
          placeholder: "群組名稱、群組說明",
          type: "text",
        },
      }),
      _c("div", { staticClass: "input-group-btn" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", attrs: { type: "button" } },
          [_c("i", { staticClass: "fa fa-search" })]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "20%" } },
        [_vm._v("群組名稱")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "25%" } },
        [_vm._v("群組說明")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "50px" }, attrs: { width: "10%" } },
        [_vm._v("群組類型")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "15%" },
        },
        [_vm._v(" 排序 ")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { "min-width": "100px", width: "10%" },
          attrs: { align: "center" },
        },
        [_vm._v(" 啟用狀態 ")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "200px" },
          attrs: { width: "25%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }