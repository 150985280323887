var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "popForm", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                "aria-label": "Close",
                "data-dismiss": "modal",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c(
            "table",
            { staticStyle: { margin: "0 auto", width: "580px" } },
            _vm._l(_vm.edit, function (item, key) {
              return _c("tr", { key: key }, [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { "padding-right": "10px" },
                  },
                  [_c("label", [_vm._v(_vm._s(key))])]
                ),
                _c("td", [
                  _c("label", { staticClass: "form-control" }, [
                    _vm._v(_vm._s(item)),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }