var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "forumReportAlert", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "ul",
            _vm._l(_vm.muteDatas, function (item, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(item.reportedId) + " "),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.updateMute()
                },
              },
            },
            [_vm._v(" 確定 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("是否禁言以下使用者ID？"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }