var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "content",
        staticClass: "modal fade",
        attrs: { "data-backdrop": "static", "data-keyboard": "false" },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content box" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.is頻道public
                      ? _vm.$t("新增公開頻道關鍵字")
                      : _vm.$t("新增密語頻道關鍵字")
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.關鍵字,
                    expression: "關鍵字",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                staticStyle: { height: "100px" },
                attrs: { placeholder: _vm.$t("請輸入關鍵字") },
                domProps: { value: _vm.關鍵字 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.關鍵字 = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("div", { staticClass: "text-muted" }, [
                _vm._v(" " + _vm._s(_vm.$t("大量新增關鍵字提示")) + " "),
              ]),
              _c("div", { staticClass: "text-muted text-red" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("自動忽略特殊符號", [
                        '(.^$*+?|()[]{}/~!@#%&_=:"' +
                          ";',.<>-" +
                          _vm.關鍵字特殊符號 +
                          ")",
                        "c*a*t=cat",
                      ])
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("送出")))]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-normal",
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("取消")))]
              ),
            ]),
            _c("div", { ref: "loading", staticClass: "overlay" }, [
              _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }