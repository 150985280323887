var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "normal", attrs: { id: "NewtalkVoteList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("投票管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("投票管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content forum-announcement" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.updateArticle()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(
                    " 新增" +
                      _vm._s(
                        _vm.searchData.articleType === 2 ? "民調" : "投票"
                      ) +
                      " "
                  ),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.articleType,
                        expression: "searchData.articleType",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "articleType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("投票")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("民調")]),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("留言")]),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("回覆")]),
                  ]
                ),
              ]),
              _vm.searchData.articleType === 1
                ? _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchData.pollsCategory,
                            expression: "searchData.pollsCategory",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "pollsCategory",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("全部分類"),
                        ]),
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(" " + _vm._s(item.className) + " ")]
                          )
                        }),
                        _c("option", { attrs: { value: "電競大賞" } }, [
                          _vm._v("電競大賞"),
                        ]),
                        _c("option", { attrs: { value: "2023電競大賞" } }, [
                          _vm._v("2023電競大賞"),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.searchData.articleType === 1
                ? _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchData.pollsType,
                            expression: "searchData.pollsType",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "pollsType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("全部投票類型"),
                        ]),
                        _c("option", { attrs: { value: "調查" } }, [
                          _vm._v("調查"),
                        ]),
                        _c("option", { attrs: { value: "辯論" } }, [
                          _vm._v("辯論"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.searchData.articleType === 1
                ? _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.searchData.pollsStatus,
                            expression: "searchData.pollsStatus",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.searchData,
                                "pollsStatus",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("全部投票"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("進行中"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("已截止"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.searchData.articleType === 1 ||
              _vm.searchData.articleType === 2
                ? _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.searchData.isDelete,
                            expression: "searchData.isDelete",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.searchData,
                                "isDelete",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "-1" } }, [
                          _vm._v("全部狀態"),
                        ]),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("上架"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("下架"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.searchData.articleType !== 1 &&
              _vm.searchData.articleType !== 2
                ? _c("div", { staticClass: "select-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.targetMobile,
                          expression: "searchData.targetMobile",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "發佈者搜尋" },
                      domProps: { value: _vm.searchData.targetMobile },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "targetMobile",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.searchData.articleType !== 1 &&
              _vm.searchData.articleType !== 2
                ? _c("div", { staticClass: "select-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.nickName,
                          expression: "searchData.nickName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "暱稱搜尋" },
                      domProps: { value: _vm.searchData.nickName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "nickName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.articleTitle,
                      expression: "searchData.articleTitle",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "搜尋關鍵字" },
                  domProps: { value: _vm.searchData.articleTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "articleTitle",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-cog",
                    staticStyle: { width: "auto" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 重設 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-danger btn-cog mr-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteAll()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" 批次刪除 "),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _vm.searchData.articleType === 1 ||
              _vm.searchData.articleType === 2
                ? _c(
                    "table",
                    {
                      staticClass:
                        "table table-hover table-bordered AdListTable",
                    },
                    [
                      _c(
                        "tbody",
                        [
                          _c(
                            "tr",
                            { staticClass: "bg-gray" },
                            [
                              _c(
                                "th",
                                { staticStyle: { "min-width": "50px" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "checkbox-inline" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _c("iCheckCheckBox", {
                                            attrs: {
                                              propValue: true,
                                              propChecked: _vm.isCheckAll,
                                            },
                                            on: {
                                              "update:checked": _vm.checkAll,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "50px" } },
                                [_vm._v("ID")]
                              ),
                              _vm.searchData.articleType === 1
                                ? _c(
                                    "th",
                                    { staticStyle: { "min-width": "140px" } },
                                    [_vm._v("熱門")]
                                  )
                                : _vm._e(),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "80px" } },
                                [_vm._v("分類")]
                              ),
                              _vm.searchData.articleType === 1
                                ? _c(
                                    "th",
                                    { staticStyle: { "min-width": "80px" } },
                                    [_vm._v("投票類型")]
                                  )
                                : _vm._e(),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "200px" } },
                                [_vm._v("標題")]
                              ),
                              _vm.searchData.articleType === 1
                                ? [
                                    _c(
                                      "th",
                                      { staticStyle: { "min-width": "80px" } },
                                      [_vm._v("剩餘天數")]
                                    ),
                                    _c(
                                      "th",
                                      { staticStyle: { "min-width": "100px" } },
                                      [_vm._v("投票人數")]
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "150px" } },
                                [_vm._v("建立時間")]
                              ),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "100px" } },
                                [_vm._v("建立者")]
                              ),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "50px" } },
                                [_vm._v("狀態")]
                              ),
                              _vm.searchData.articleType === 1
                                ? [
                                    _c(
                                      "th",
                                      { staticStyle: { "min-width": "50px" } },
                                      [_vm._v("喜歡")]
                                    ),
                                    _c(
                                      "th",
                                      { staticStyle: { "min-width": "50px" } },
                                      [_vm._v("留言")]
                                    ),
                                    _c(
                                      "th",
                                      { staticStyle: { "min-width": "80px" } },
                                      [_vm._v("投票結果")]
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "th",
                                { staticStyle: { "min-width": "150px" } },
                                [_vm._v("功能")]
                              ),
                            ],
                            2
                          ),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "15" },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "15" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c(
                                  "tr",
                                  { key: index },
                                  [
                                    _c("td", { staticClass: "text-center" }, [
                                      !item.isDelete
                                        ? _c(
                                            "div",
                                            { staticClass: "checkbox-inline" },
                                            [
                                              _c(
                                                "label",
                                                [
                                                  _c("iCheckCheckBox", {
                                                    attrs: {
                                                      propValue: item,
                                                      propChecked: item.checked,
                                                    },
                                                    on: {
                                                      "update:checked":
                                                        _vm.checkChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                        class: { link: item.isDelete === 0 },
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.articleId) + " "
                                        ),
                                      ]
                                    ),
                                    _vm.searchData.articleType === 1
                                      ? _c(
                                          "td",
                                          { staticClass: "text-nowrap" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-group" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value:
                                                        item.local
                                                          .officialHotTop,
                                                      expression:
                                                        "item.local.officialHotTop",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    min: "0",
                                                    max: "5",
                                                  },
                                                  domProps: {
                                                    value:
                                                      item.local.officialHotTop,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        item.local,
                                                        "officialHotTop",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-warning btn-add btn-upDate",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setHotTop(
                                                          item,
                                                          "officialHotTop",
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 更新 ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.define.classTypes[
                                                item.pollsCategory
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.searchData.articleType === 1
                                      ? _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-nowrap text-center",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.pollsType) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          class: { "text-red": item.isDelete },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.articleTitle) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm.searchData.articleType === 1
                                      ? [
                                          item.remainDayCount
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-nowrap text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "currencyFormat"
                                                        )(item.remainDayCount)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-nowrap text-center",
                                                },
                                                [_vm._v("已截止")]
                                              ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-nowrap text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currencyFormat")(
                                                      item.votePeopleCount || 0
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.publishTime,
                                                "YYYY/MM/DD HH:mm:ss"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.authorHide) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                        class: { "text-red": item.isDelete },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.define.deleteStatus[
                                                item.isDelete
                                              ]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.searchData.articleType === 1
                                      ? [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-nowrap text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.likecount) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-nowrap text-center",
                                            },
                                            [
                                              item.comment_count > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "link",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateComment(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.comment_count
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.comment_count
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              !item.hasResult
                                                ? _c("span", [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-success",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showResult(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" 結果 ")]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c("td", { staticClass: "text-center" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateArticle(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 修改 ")]
                                      ),
                                      _vm._v("   "),
                                      item.isDelete === 2
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-warning",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewItem(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 預覽 ")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  2
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.searchData.articleType === 3
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticStyle: { "min-width": "50px" } }, [
                              _c("div", { staticClass: "checkbox-inline" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: true,
                                        propChecked: _vm.isCheckAll,
                                      },
                                      on: { "update:checked": _vm.checkAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("ID")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("樓層"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("內容")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("發佈日期")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("發佈者")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("暱稱")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("狀態"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("不喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("回覆"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("功能")]
                            ),
                          ]),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "12" },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "12" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    !item.isDelete
                                      ? _c(
                                          "div",
                                          { staticClass: "checkbox-inline" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _c("iCheckCheckBox", {
                                                  attrs: {
                                                    propValue: item,
                                                    propChecked: item.checked,
                                                  },
                                                  on: {
                                                    "update:checked":
                                                      _vm.checkChange,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(_vm._s(item.commentId))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.commentFloor || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", [
                                    _c("span", {
                                      class: { "text-red": item.isDelete },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.showText(
                                            item,
                                            item.commentContent
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.commentTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.commentAuthor)),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.nickName || "-")),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-nowrap text-center",
                                      class: { "text-red": item.isDelete },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.deleteStatus[
                                              item.isDelete
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(" " + _vm._s(item.likecount) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.notlikecount) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      item.reply_count > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateReply(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.reply_count) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.reply_count) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(
                                              item,
                                              item.commentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 詳細 ")]
                                    ),
                                  ]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.searchData.articleType === 4
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticStyle: { "min-width": "50px" } }, [
                              _c("div", { staticClass: "checkbox-inline" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: true,
                                        propChecked: _vm.isCheckAll,
                                      },
                                      on: { "update:checked": _vm.checkAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("ID"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "80px" } }, [
                              _vm._v("樓層"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("內容")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("發佈日期")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("發佈者")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("暱稱")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("狀態"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("不喜歡"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("功能")]
                            ),
                          ]),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "11" },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "11" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    !item.isDelete
                                      ? _c(
                                          "div",
                                          { staticClass: "checkbox-inline" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _c("iCheckCheckBox", {
                                                  attrs: {
                                                    propValue: item,
                                                    propChecked: item.checked,
                                                  },
                                                  on: {
                                                    "update:checked":
                                                      _vm.checkChange,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(_vm._s(item.replyId))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.commentFloor) +
                                          "-" +
                                          _vm._s(item.ReplyFloor) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", [
                                    _c("span", {
                                      class: { "text-red": item.isDelete },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.showText(item, item.replyContent)
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.replyTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.nickName || "-")),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-nowrap text-center",
                                      class: { "text-red": item.isDelete },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.deleteStatus[
                                              item.isDelete
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(" " + _vm._s(item.likecount) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.notlikecount) + " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(
                                              item,
                                              item.replyId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 詳細 ")]
                                    ),
                                  ]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("forumContent", {
        ref: "forumContent",
        attrs: { getDataList: _vm.getDataList },
      }),
      _c("announcementAlert", {
        ref: "announcementAlert",
        attrs: { actionType: _vm.actionType, checkDatas: _vm.checkDatas },
        on: { updateList: _vm.getDataList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }