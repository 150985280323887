var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "announcementAlert", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _vm.checkDatas
            ? _c("h4", { staticClass: "modal-title" }, [
                _c("span", [_vm._v("是否刪除以下文章？")]),
                _c("span", [
                  _vm._v("(共計 " + _vm._s(_vm.checkDatas.length) + " 筆)"),
                ]),
              ])
            : _c("h4", { staticClass: "modal-title" }, [
                _c("span", [_vm._v("是否刪除此文章？")]),
              ]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _vm.checkDatas
            ? _c(
                "ul",
                _vm._l(_vm.checkDatas, function (item, index) {
                  return _c("li", {
                    key: index,
                    domProps: {
                      innerHTML: _vm._s(item.contentText || item.articleTitle),
                    },
                  })
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticStyle: { padding: "15px 0" } }, [
            _c("label", { staticClass: "col-sm-2", attrs: { for: "" } }, [
              _vm._v("刪除原因"),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deleteReason,
                    expression: "deleteReason",
                  },
                ],
                staticClass: "col-sm-7",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.deleteReason = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "廣告、商業宣傳" } }, [
                  _vm._v("廣告、商業宣傳"),
                ]),
                _c("option", { attrs: { value: "惡意洗版" } }, [
                  _vm._v("惡意洗版"),
                ]),
                _c("option", { attrs: { value: "垃圾內容或誤導性內容" } }, [
                  _vm._v("垃圾內容或誤導性內容"),
                ]),
                _c("option", { attrs: { value: "色情內容" } }, [
                  _vm._v("色情內容"),
                ]),
                _c("option", { attrs: { value: "暴力或令人反感的內容" } }, [
                  _vm._v("暴力或令人反感的內容"),
                ]),
                _c("option", { attrs: { value: "仇恨或惡意內容" } }, [
                  _vm._v("仇恨或惡意內容"),
                ]),
                _c("option", { attrs: { value: "侵犯智慧財產權" } }, [
                  _vm._v("侵犯智慧財產權"),
                ]),
                _c("option", { attrs: { value: "詐騙或詐欺" } }, [
                  _vm._v("詐騙或詐欺"),
                ]),
                _c("option", { attrs: { value: "其他" } }, [_vm._v("其他")]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v(" 確定 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }