var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      ref: "daterange",
      staticClass: "btn btn-default pull-left timepickbutton",
      staticStyle: { width: "200px", "margin-right": "10px" },
      attrs: { type: "button" },
    },
    [
      _c(
        "span",
        {
          ref: "daterangeSpan",
          staticClass: "daterangeSpan",
          staticStyle: { "padding-right": "10px" },
        },
        [
          _c("i", { staticClass: "fa fa-calendar" }),
          _vm._v(" " + _vm._s(_vm.$t("選擇時間區間")) + " "),
        ]
      ),
      _c("i", { staticClass: "fa fa-caret-down" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }