var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "channel box" },
      [
        _c("div", { staticClass: "layout-main", class: _vm.fontSize }, [
          _c("div", { staticClass: "layout-channel-div" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$t("頻道管理")))]),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.是否顯示異常按鈕,
                  expression: "是否顯示異常按鈕",
                },
              ],
              staticClass: "fa fa-warning",
              staticStyle: { color: "red", cursor: "pointer" },
              attrs: { title: _vm.$t("下載事件記錄") },
              on: {
                click: function ($event) {
                  return _vm.$store.dispatch("messageSystemServer/刷新畫面", "")
                },
              },
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDebug,
                  expression: "isDebug",
                },
              ],
              staticClass: "fa fa-check-square",
              staticStyle: { color: "red", cursor: "pointer" },
              attrs: { title: "" },
              on: {
                click: function ($event) {
                  return _vm.$store.dispatch("messageSystemServer/ReadLog", "")
                },
              },
            }),
            !(
              _vm.ServerIsOnline == "Connected" &&
              _vm.$user.hasPermission("ChannelManager")
            )
              ? _c(
                  "div",
                  { staticClass: "layout-main" },
                  [
                    !_vm.$user.hasPermission("ChannelManager")
                      ? _c("div", [_vm._v(_vm._s(_vm.$t("請申請權限")))])
                      : [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(`ServerStatus-${_vm.ServerIsOnline}`)
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ServerIsOnline == "Disconnected",
                                  expression:
                                    "ServerIsOnline == 'Disconnected'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(_vm.$t(`請重新整理以建立連線`)))]
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.ServerIsOnline == "Connected" &&
                      _vm.$user.hasPermission("ChannelManager"),
                    expression:
                      "ServerIsOnline == 'Connected' && $user.hasPermission('ChannelManager')",
                  },
                ],
                staticClass: "copyTipParent",
              },
              [
                _c("span", { ref: "copyTip", staticClass: "copyTip" }, [
                  _c("i", { staticClass: "fa fa-check" }),
                  _vm._v(" " + _vm._s(_vm.$t("複製成功"))),
                ]),
                _c("div", { staticClass: "section-nav all-radius" }, [
                  _c(
                    "span",
                    { staticClass: "span", attrs: { title: _vm.navStr } },
                    [_vm._v(" " + _vm._s(_vm.navStr) + " ")]
                  ),
                  _vm.玩家控管功能
                    ? _c(
                        "button",
                        {
                          staticClass: "btn control-btn",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { disabled: !_vm.nick },
                          on: {
                            click: function ($event) {
                              return _vm.showPlayer()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("玩家控管")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn control-btn",
                      attrs: { title: _vm.nick, disabled: !_vm.nick },
                      on: {
                        click: function ($event) {
                          _vm.一鍵複製(_vm.nick)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-copy" })]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.ServerIsOnline == "Connected" &&
                      _vm.$user.hasPermission("ChannelManager"),
                    expression:
                      "ServerIsOnline == 'Connected' && $user.hasPermission('ChannelManager')",
                  },
                ],
                staticClass: "section-channels",
              },
              [
                _c(
                  "div",
                  {
                    ref: "resizeDivPublic_1",
                    staticClass: "public-channel top-radius",
                  },
                  [
                    _c("div", { staticClass: "caption top-radius" }, [
                      _vm._v(_vm._s(_vm.$t("公開頻道"))),
                    ]),
                    _c(
                      "div",
                      { ref: "scrollFormPublic", staticClass: "scrollDiv" },
                      [
                        _c("ChannelManagerTable", {
                          attrs: {
                            list: _vm.監頻訊息_公開,
                            focusId: _vm.focusId,
                            th: [_vm.$t("頻道"), _vm.$t("發話人")],
                            td: ["Channel", "Sender"],
                            MessageSystemEnable: _vm.MessageSystemEnable,
                          },
                          on: {
                            onFocus: _vm.onFocus,
                            onSecretBtnClick: _vm.onSecretBtnClick,
                            onDownloadBtnClick: _vm.onDownloadBtnClick,
                            popUpImg: _vm.popUpImg,
                            mouseenter: (e, item) => {
                              _vm.mouseenter(e, item, true)
                            },
                            mouseout: _vm.mouseout,
                            playAudio: _vm.playAudio,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "resizeDivPrivate_1",
                    staticClass: "private-channel top-radius",
                  },
                  [
                    _c("div", { staticClass: "caption top-radius" }, [
                      _vm._v(_vm._s(_vm.$t("密語頻道"))),
                    ]),
                    _c(
                      "div",
                      { ref: "scrollFormPrivate", staticClass: "scrollDiv" },
                      [
                        _c("ChannelManagerTable", {
                          attrs: {
                            list: _vm.監頻訊息_密語,
                            focusId: _vm.focusId,
                            th: [_vm.$t("發話人"), _vm.$t("接收人")],
                            td: ["Sender", "Receiver"],
                            MessageSystemEnable: _vm.MessageSystemEnable,
                          },
                          on: {
                            onFocus: _vm.onFocus,
                            onSecretBtnClick: _vm.onSecretBtnClick,
                            onDownloadBtnClick: _vm.onDownloadBtnClick,
                            popUpImg: _vm.popUpImg,
                            mouseenter: (e, item) => {
                              _vm.mouseenter(e, item, false)
                            },
                            mouseout: _vm.mouseout,
                            playAudio: _vm.playAudio,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "resizeDivPublic_2",
                    staticClass: "public-filter-channel",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "caption cursor",
                        staticStyle: { "user-select": "none" },
                      },
                      [_vm._v(_vm._s(_vm.$t("過濾關鍵字")))]
                    ),
                    _c(
                      "div",
                      {
                        ref: "scrollFilterFormPublic",
                        staticClass: "scrollDiv",
                      },
                      [
                        _c("ChannelManagerTable", {
                          attrs: {
                            list: _vm.監頻訊息_過濾_公開,
                            focusId: _vm.focusId,
                            th: [_vm.$t("頻道"), _vm.$t("發話人")],
                            td: ["Channel", "Sender"],
                            MessageSystemEnable: _vm.MessageSystemEnable,
                          },
                          on: {
                            onFocus: _vm.onFocus,
                            onSecretBtnClick: _vm.onSecretBtnClick,
                            onDownloadBtnClick: _vm.onDownloadBtnClick,
                            popUpImg: _vm.popUpImg,
                            playAudio: _vm.playAudio,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "resizeDivPrivate_2",
                    staticClass: "private-filter-channel",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "caption cursor",
                        staticStyle: { "user-select": "none" },
                      },
                      [_vm._v(_vm._s(_vm.$t("過濾關鍵字")))]
                    ),
                    _c(
                      "div",
                      {
                        ref: "scrollFilterFormPrivate",
                        staticClass: "scrollDiv",
                      },
                      [
                        _c("ChannelManagerTable", {
                          attrs: {
                            list: _vm.監頻訊息_過濾_密語,
                            focusId: _vm.focusId,
                            th: [_vm.$t("發話人"), _vm.$t("接收人")],
                            td: ["Sender", "Receiver"],
                            MessageSystemEnable: _vm.MessageSystemEnable,
                          },
                          on: {
                            onFocus: _vm.onFocus,
                            onSecretBtnClick: _vm.onSecretBtnClick,
                            onDownloadBtnClick: _vm.onDownloadBtnClick,
                            popUpImg: _vm.popUpImg,
                            playAudio: _vm.playAudio,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ToolBar.頻道篩選 || _vm.ToolBar.字體大小,
                  expression: "ToolBar.頻道篩選 || ToolBar.字體大小",
                },
              ],
              staticClass: "layout-tool-div",
            },
            [
              _c("h1", { staticStyle: { visibility: "hidden" } }, [
                _vm._v("C"),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ToolBar.字體大小,
                      expression: "ToolBar.字體大小",
                    },
                  ],
                  staticClass: "fieldset all-radius",
                },
                [
                  _c("div", { staticClass: "legend" }, [
                    _vm._v(_vm._s(_vm.$t("字體"))),
                  ]),
                  _c("ul", { staticClass: "options" }, [
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fontSize,
                            expression: "fontSize",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "font-size",
                          id: "fs小",
                          value: "fonst-size-small",
                        },
                        domProps: {
                          checked: _vm._q(_vm.fontSize, "fonst-size-small"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.fontSize = "fonst-size-small"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "fs小" } }, [
                        _c("span", { staticClass: "checkBox" }),
                        _c("span", { staticClass: "checkSpace" }),
                        _c("span", { staticClass: "set-fonst-size-small" }, [
                          _vm._v(_vm._s(_vm.$t("小"))),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fontSize,
                            expression: "fontSize",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "font-size",
                          id: "fs中",
                          value: "fonst-size-middle",
                        },
                        domProps: {
                          checked: _vm._q(_vm.fontSize, "fonst-size-middle"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.fontSize = "fonst-size-middle"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "fs中" } }, [
                        _c("span", { staticClass: "checkBox" }),
                        _c("span", { staticClass: "checkSpace" }),
                        _c("span", { staticClass: "set-fonst-size-middle" }, [
                          _vm._v(_vm._s(_vm.$t("中"))),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fontSize,
                            expression: "fontSize",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "font-size",
                          id: "fs大",
                          value: "fonst-size-large",
                        },
                        domProps: {
                          checked: _vm._q(_vm.fontSize, "fonst-size-large"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.fontSize = "fonst-size-large"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "fs大" } }, [
                        _c("span", { staticClass: "checkBox" }),
                        _c("span", { staticClass: "checkSpace" }),
                        _c("span", { staticClass: "set-fonst-size-large" }, [
                          _vm._v(_vm._s(_vm.$t("大"))),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ToolBar.頻道篩選,
                      expression: "ToolBar.頻道篩選",
                    },
                  ],
                  staticClass: "fieldset all-radius",
                },
                [
                  _c("div", { staticClass: "legend" }, [
                    _vm._v(_vm._s(_vm.$t("公開頻道篩選")) + " "),
                    _c(
                      "span",
                      {
                        staticClass: "selAll",
                        on: {
                          click: function ($event) {
                            return _vm.selAllPublicChannels(_vm.頻道列表)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("全選")))]
                    ),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "options" },
                    _vm._l(_vm.頻道列表.publicTypes, function (item) {
                      return _c("li", { key: item }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkedPublicChannels,
                              expression: "checkedPublicChannels",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            name: "keyword-filter",
                            id: "ChannelType" + item,
                          },
                          domProps: {
                            value: item,
                            checked: Array.isArray(_vm.checkedPublicChannels)
                              ? _vm._i(_vm.checkedPublicChannels, item) > -1
                              : _vm.checkedPublicChannels,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.checkedPublicChannels,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedPublicChannels = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedPublicChannels = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedPublicChannels = $$c
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "ChannelType" + item } }, [
                          _c("span", { staticClass: "checkBox" }),
                          _c("span", { staticClass: "checkSpace" }),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("ChannelType" + item))),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
        ]),
        _c("PopUpImg", { ref: "popUpImg" }),
        _c("PlayerSettingContent", {
          ref: "playerSettingContent",
          staticClass: "popform",
          on: {
            updateList: function ($event) {
              _vm.$eventBus.$emit("showAlert", _vm.$t("完成"))
            },
          },
        }),
        _c("SecretMsgPannel", { ref: "secretMsgPannel" }),
        _c("DownloadPannel", { ref: "downloadPannel" }),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
        _c(
          "div",
          {
            ref: "imgToolTip",
            staticClass: "imgToolTip",
            style: _vm.imgToolTip.style,
          },
          [_c("img", { attrs: { src: _vm.imgToolTip.src, alt: "" } })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }