var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "companyList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("開發商列表")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("遊戲管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("開發商列表")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.開發商名稱,
                      expression: "searchData.開發商名稱",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋關鍵字",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.開發商名稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "開發商名稱",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.searchData.開發商名稱 != ""
                  ? _c("i", {
                      staticClass: "fa fa-close",
                      on: {
                        click: function ($event) {
                          _vm.searchData.開發商名稱 = ""
                        },
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered classTypeTable",
                  attrs: { id: "classTypes" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "150px" },
                            attrs: { width: "50%" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeSort()
                                  },
                                },
                              },
                              [
                                _vm._v(" 開發商名稱 "),
                                _c("i", { staticClass: "fa fa-sort" }),
                              ]
                            ),
                          ]
                        ),
                        _c("th", { staticStyle: { "min-width": "97px" } }, [
                          _vm._v("App數"),
                        ]),
                        _c(
                          "th",
                          {
                            staticStyle: { "min-width": "150px" },
                            attrs: { width: "50%" },
                          },
                          [_vm._v("建立時間")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap text-center",
                            staticStyle: { "min-width": "100px", width: "10%" },
                          },
                          [_vm._v("啟用")]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "text-nowrap text-center",
                            staticStyle: { "min-width": "150px", width: "20%" },
                          },
                          [_vm._v("功能")]
                        ),
                      ]),
                      _vm.total == 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "5" } },
                              [_c("Empty")],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.list, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.開發商名稱))]),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _vm._v(_vm._s(item.遊戲數)),
                          ]),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.建立時間,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { align: "center" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.是否啟用,
                                        expression: "item.是否啟用",
                                      },
                                    ],
                                    attrs: {
                                      "false-value": 0,
                                      id: "check" + index,
                                      "true-value": 1,
                                      name: "ckbx-style-8",
                                      type: "checkbox",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.是否啟用)
                                        ? _vm._i(item.是否啟用, null) > -1
                                        : _vm._q(item.是否啟用, 1),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.是否啟用,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 1 : 0
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "是否啟用",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "是否啟用",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "是否啟用", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.changeStatus(item)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + index },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.add(item)
                                  },
                                },
                              },
                              [_vm._v("編輯")]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _vm.total != 0
            ? _c(
                "div",
                { staticClass: "box-footer text-center no-border" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.pageSize,
                          expression: "searchData.pageSize",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control page-size mr-10",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "pageSize",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "20" } }, [
                        _vm._v("每頁筆數20"),
                      ]),
                      _c("option", { attrs: { value: "30" } }, [
                        _vm._v("每頁筆數30"),
                      ]),
                      _c("option", { attrs: { value: "50" } }, [
                        _vm._v("每頁筆數50"),
                      ]),
                    ]
                  ),
                  _c("Pagination", {
                    ref: "pagination",
                    attrs: {
                      total: _vm.total,
                      pageLimitCount: _vm.searchData.pageSize,
                      type2: true,
                    },
                    on: { pageMethod: _vm.onPageChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            ref: "create",
            staticClass: "modal fade",
            attrs: { id: "companyCreate" },
          },
          [
            _c("div", { staticClass: "modal-dialog modal-sm" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h4", { staticClass: "modal-title text-center" }, [
                    _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "名稱"),
                  ]),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "input-group-hasClose" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.addData.開發商名稱,
                          expression: "addData.開發商名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "開發商名稱" },
                      domProps: { value: _vm.addData.開發商名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addData,
                            "開發商名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm.addData.開發商名稱 != ""
                      ? _c("i", {
                          staticClass: "fa fa-close",
                          on: {
                            click: function ($event) {
                              _vm.addData.開發商名稱 = ""
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "modal-footer text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { "data-dismiss": "modal", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelInput()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: _vm.addData.開發商名稱 == "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }