var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio-date" },
    [
      _c("div", { staticClass: "input-group date" }, [
        _c("div", { staticClass: "input-group-addon fa fa-calendar" }),
        _c("input", {
          ref: "date",
          staticClass: "form-control datepicker",
          attrs: { type: "text" },
        }),
      ]),
      _c("div", { staticClass: "input-group time" }, [
        _c("div", { staticClass: "input-group-addon fa fa-clock-o" }),
        _c("input", {
          ref: "time",
          staticClass: "form-control timepicker",
          attrs: { type: "text" },
        }),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }