var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message_style" },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("客服群組")))]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.$t("客服群組"))),
          ]),
        ]),
      ]),
      _c("ServerStatusForm"),
      _c(
        "section",
        {
          staticClass: "content",
          style: { display: _vm.ServerIsOnline != "Connected" ? "none" : "" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-sm-9 col-xs-12",
                staticStyle: { background: "#F8F8FA" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickCsId != "",
                        expression: "clickCsId != ''",
                      },
                    ],
                    staticClass:
                      "box box-warning direct-chat direct-chat-warning",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "box-header defineHeader",
                        staticStyle: {
                          "background-color": "#fff",
                          "border-top": "1px solid #E8E8E8",
                          "border-bottom": "1px solid #E8E8E8",
                        },
                      },
                      [
                        _c("h3", { staticClass: "box-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.clickCsName == "群組"
                                ? _vm.$t("群組")
                                : _vm.clickCsName
                            ) + " "
                          ),
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticClass: "box-title",
                            staticStyle: {
                              "font-size": "12px",
                              color: "#979797",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("最後更新時間")) +
                                "：" +
                                _vm._s(_vm.聊天視窗_格式化最後更新時間) +
                                " "
                            ),
                          ]
                        ),
                        _vm._m(0),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("ChatMessageForm", {
                          attrs: { historyType: "CSHistory" },
                        }),
                        _c("SendMessageBtn", {
                          ref: "sendMessageBtn",
                          attrs: {
                            是否可發訊息: _vm.聊天視窗_是否可發訊息,
                            提示訊息: _vm.聊天視窗_提示訊息,
                          },
                          on: {
                            SendChatMessage: _vm.sendChatMessage,
                            UpdChatMessage: _vm.updChatMessage,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickCsId == "",
                        expression: "clickCsId == ''",
                      },
                    ],
                    staticStyle: { "border-top": "1px solid #E8E8E8" },
                  },
                  [
                    _c("div", { staticClass: "box-header fix-height" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "box-title",
                          staticStyle: {
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            "font-size": "20px",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("請選擇聊天室")))]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col-sm-3 col-xs-12",
                staticStyle: {
                  "border-top": "1px solid #E8E8E8",
                  "border-left": "1px solid #E8E8E8",
                },
              },
              [
                _c("div", { staticClass: "btn-group-message groupsystem" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: { active: _vm.sel事件 == 1 },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.sel事件 = 1
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("訊息")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: { active: _vm.sel事件 == 0 },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.sel事件 = 0
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("客服")) + " ")]
                  ),
                  _c("label", { attrs: { for: "html5colorpicker" } }, [
                    _c("input", {
                      staticStyle: {
                        width: "1px",
                        height: "1px",
                        position: "absolute",
                        visibility: "hidden",
                      },
                      attrs: { type: "color", id: "html5colorpicker" },
                      domProps: { value: _vm.csColor },
                      on: { change: _vm.changeColor },
                    }),
                    _c("span", {
                      staticClass:
                        "glyphicon glyphicon-text-color text-color-btn",
                      style: { color: _vm.csColor },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right-content cal-group-list-height" },
                  [
                    _vm.sel事件 == 0
                      ? _vm._l(_vm.右邊客服列表, function (item, key) {
                          return _c(
                            "div",
                            {
                              key: key,
                              class:
                                _vm.clickCsId == item.Account ? "active" : "",
                              on: {
                                click: function ($event) {
                                  _vm.開始客服聊天(item.Account)
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v(_vm._s(item.Name))]),
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  class: {
                                    hide: item.UnRead == 0,
                                    color_blue: false,
                                    color_red: true,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.UnRead))]
                              ),
                            ]
                          )
                        })
                      : _vm._e(),
                    _vm.sel事件 == 1
                      ? [
                          _c(
                            "div",
                            {
                              class:
                                _vm.clickCsId ==
                                _vm.GroupChatEvent.EventRelatedCs
                                  ? "active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  _vm.開始客服聊天("group")
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "first-row" }, [
                                _c("span", { staticClass: "player-name" }, [
                                  _vm._v(_vm._s(_vm.$t("群組"))),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge",
                                    class: {
                                      hide: _vm.groupUnRead == 0,
                                      color_blue: false,
                                      color_red: true,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.groupUnRead))]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-style-ellipsis last-message",
                                },
                                [_vm._v(_vm._s(_vm.GroupChatEvent.LastMsg.Msg))]
                              ),
                              _c("div", { staticClass: "second-row" }, [
                                _c("div", { staticClass: "end_time" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("格式化時間")(
                                        _vm.GroupChatEvent.LastMsg.Time
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._l(_vm.CsChatEventList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.EventId,
                                class:
                                  _vm.clickCsId == item.EventRelatedCs
                                    ? "active"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    _vm.開始客服聊天(item.EventRelatedCs)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "first-row" }, [
                                  _c("span", { staticClass: "player-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.EventRelatedCsName) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge",
                                      class: {
                                        hide: item.UnRead == 0,
                                        color_blue: false,
                                        color_red: true,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.UnRead))]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-style-ellipsis last-message",
                                  },
                                  [_vm._v(" " + _vm._s(item.LastMsg.Msg) + " ")]
                                ),
                                _c("div", { staticClass: "second-row" }, [
                                  _c("span", { staticClass: "end_time" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("格式化時間")(item.LastMsg.Time)
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-tools pull-right" }, [
      _c("span", { staticStyle: { display: "none" } }, [
        _vm._v("這裡放一些按鈕"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }