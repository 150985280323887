var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newtalk", attrs: { id: "NewtalkVoteResult" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("投票結果")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Newtalk/VoteList" } }, [
                _vm._v("投票管理"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("投票結果")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ]),
            _c("div", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.data.remainDayCount === 0
                    ? _c("h4", { staticClass: "text-red" }, [
                        _vm._v(" [已截止] "),
                      ])
                    : _vm._e(),
                  _c(
                    "h3",
                    {
                      staticClass: "link ml-10",
                      on: {
                        click: function ($event) {
                          return _vm.linkItem(_vm.data)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.data.articleTitle) + " ")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "font-size": "16px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 投票時間： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10 mt-5" }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.data.pollsStartTime,
                                "YYYY/MM/DD HH:mm"
                              )
                            ) +
                            " - " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.data.pollsEndTime,
                                "YYYY/MM/DD HH:mm"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "font-size": "16px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 投票人數： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10 mt-5" }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currencyFormat")(
                                _vm.data.votePeopleCount || 0
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "font-size": "16px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 投票內文： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10 mt-5" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.data.articleContent),
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  _vm._l(_vm.data.polls, function (poll, index) {
                    return _c(
                      "table",
                      { key: index, staticClass: "table mt-30 mb-30" },
                      [
                        _vm._m(0, true),
                        _c("tr", [
                          _c("td", { staticClass: "question" }, [
                            _c("h4", [_vm._v("Q" + _vm._s(index + 1))]),
                          ]),
                          _c("td", [_c("h4", [_vm._v(_vm._s(poll.title))])]),
                        ]),
                        _c("tr", [
                          _c("td"),
                          _c("td", [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-bordered table-striped tc",
                              },
                              [
                                _c(
                                  "tbody",
                                  [
                                    _vm._m(1, true),
                                    _vm._l(
                                      poll.options,
                                      function (option, oIndex) {
                                        return _c("tr", { key: oIndex }, [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "min-width": "80px",
                                                width: "80px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(oIndex + 1) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "min-width": "150px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(option.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "min-width": "100px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currencyFormat")(
                                                      option.voteCount
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "min-width": "100px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getVotePercentage(
                                                      option
                                                    )
                                                  ) +
                                                  "% "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("forumContent", { ref: "forumContent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { "min-width": "60px", width: "60px" } }),
      _c("th"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("序號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("選項")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("票數")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("比例")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }