var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("網紅編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("網紅列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("網紅編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("人物編輯"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("form", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              { staticClass: "box-body", staticStyle: { "max-width": "80%" } },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "AdPic" },
                    },
                    [_vm._v(" 圖片： ")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        staticStyle: { "max-width": "200px" },
                        attrs: { draggable: "false", src: _vm.edit.main_img },
                      }),
                    ]),
                    _c("input", {
                      attrs: { accept: "image/*", id: "AdPic", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _vm._m(0),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.target_name,
                          expression: "edit.target_name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "target_name",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.target_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "target_name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.gender,
                            expression: "edit.gender",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "gender",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇性別")]
                        ),
                        _vm._l(_vm.sexList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "col-sm-9 checkbox-wrapper" },
                    [
                      _c("iCheckMultiCheckBox", {
                        attrs: {
                          propOptions: _vm.selectTypeList,
                          propSelecteds: _vm.edit.categorys,
                        },
                        on: {
                          "update:propSelecteds": function ($event) {
                            return _vm.$set(_vm.edit, "categorys", $event)
                          },
                          "update:prop-selecteds": function ($event) {
                            return _vm.$set(_vm.edit, "categorys", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group bannercontent-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "description" },
                    },
                    [_vm._v("描述： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.description,
                          expression: "edit.description",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "description", placeholder: "建議100字內" },
                      domProps: { value: _vm.edit.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "description", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-lg-1 col-md-2 col-sm-4" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: true,
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.is_visibility,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "is_visibility", $$v)
                              },
                              expression: "edit.is_visibility",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-1 col-md-2 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: false,
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.is_visibility,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "is_visibility", $$v)
                            },
                            expression: "edit.is_visibility",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.Save()
                      },
                    },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
              ]),
            ]),
            _c("hr", { staticClass: "divider mb-10" }),
            _vm.isEdit
              ? _c("div", { staticClass: "top-box with-border" }, [
                  _c(
                    "h4",
                    { staticClass: "pull-left", attrs: { id: "title" } },
                    [_vm._v("社群平台編輯")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add fr",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addSocial()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                  ),
                ])
              : _vm._e(),
            _vm.isEdit
              ? _c(
                  "div",
                  {
                    staticClass: "table-responsive mt-10 m-auto2 mb-30",
                    staticStyle: { "max-width": "80%" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "table table-bordered classTypeTable",
                        attrs: { id: "classTypes" },
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _vm._m(5),
                            _vm.editChannels.length == 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { colspan: "10" },
                                    },
                                    [_vm._v("沒有資料")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._l(_vm.editChannels, function (channel, index) {
                              return _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: channel.isDelete != true,
                                      expression: "channel.isDelete != true",
                                    },
                                  ],
                                  key: index,
                                },
                                [
                                  _c("td", [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: channel.platform,
                                            expression: "channel.platform",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { disabled: !channel.editor },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              channel,
                                              "platform",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { disabled: "", value: "" },
                                          },
                                          [_vm._v("請選擇平台")]
                                        ),
                                        _vm._l(
                                          _vm.selectPlatformsList,
                                          function (platform, key) {
                                            return _c(
                                              "option",
                                              {
                                                key: key,
                                                domProps: {
                                                  value: platform.classId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(platform.className) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !channel.editor,
                                            expression: "!channel.editor",
                                          },
                                        ],
                                        staticClass: "m-0",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(channel.channel_name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: channel.channel_name,
                                          expression: "channel.channel_name",
                                          modifiers: { trim: true },
                                        },
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: channel.editor,
                                          expression: "channel.editor",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: channel.channel_name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            channel,
                                            "channel_name",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        align: "center",
                                        "data-label": "連結",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !channel.editor,
                                              expression: "!channel.editor",
                                            },
                                          ],
                                          staticClass: "m-0",
                                          attrs: {
                                            target: "_blank",
                                            href: channel.channel_url,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(channel.channel_url) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.trim",
                                            value: channel.channel_url,
                                            expression: "channel.channel_url",
                                            modifiers: { trim: true },
                                          },
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: channel.editor,
                                            expression: "channel.editor",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: channel.channel_url,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              channel,
                                              "channel_url",
                                              $event.target.value.trim()
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        align: "center",
                                        "data-label": "列表呈現",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ckbx ckbx-style-8 toggleInput",
                                        },
                                        [
                                          channel.channel_id
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      channel.is_visibility,
                                                    expression:
                                                      "channel.is_visibility",
                                                  },
                                                ],
                                                attrs: {
                                                  id: "check" + index,
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    channel.is_visibility
                                                  )
                                                    ? _vm._i(
                                                        channel.is_visibility,
                                                        null
                                                      ) > -1
                                                    : channel.is_visibility,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          channel.is_visibility,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              channel,
                                                              "is_visibility",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              channel,
                                                              "is_visibility",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          channel,
                                                          "is_visibility",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.updateChannel(
                                                        channel
                                                      )
                                                    },
                                                  ],
                                                },
                                              })
                                            : _vm._e(),
                                          _c("label", {
                                            attrs: { for: "check" + index },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        align: "center",
                                        "data-label": "是否為粉專",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ckbx ckbx-style-8 toggleInput",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: channel.fans_page,
                                                expression: "channel.fans_page",
                                              },
                                            ],
                                            attrs: {
                                              id: "check" + index,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                channel.fans_page
                                              )
                                                ? _vm._i(
                                                    channel.fans_page,
                                                    null
                                                  ) > -1
                                                : channel.fans_page,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = channel.fans_page,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          channel,
                                                          "fans_page",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          channel,
                                                          "fans_page",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      channel,
                                                      "fans_page",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.updateChannel(
                                                    channel
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _c("label", {
                                            attrs: { for: "check" + index },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      attrs: {
                                        align: "center",
                                        "data-label": "爬蟲狀態",
                                      },
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: channel.must_crawler,
                                              expression:
                                                "channel.must_crawler",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { disabled: !channel.editor },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                channel,
                                                "must_crawler",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.crawlerList,
                                          function (item, key) {
                                            return _c(
                                              "option",
                                              {
                                                key: key,
                                                domProps: { value: item.value },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !channel.editor,
                                            expression: "!channel.editor",
                                          },
                                        ],
                                        staticClass: "btn btn-maroon",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.LinkKPI(channel)
                                          },
                                        },
                                      },
                                      [_vm._v(" KPI ")]
                                    ),
                                    _vm._v("   "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !channel.editor,
                                            expression: "!channel.editor",
                                          },
                                        ],
                                        staticClass: "btn btn-primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeSocial(channel)
                                          },
                                        },
                                      },
                                      [_vm._v(" 修改 ")]
                                    ),
                                    _vm._v("   "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !channel.editor,
                                            expression: "!channel.editor",
                                          },
                                        ],
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSocial(
                                              channel,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 刪除 ")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: channel.editor,
                                            expression: "channel.editor",
                                          },
                                        ],
                                        staticClass: "btn btn-success btn-save",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveSocial(channel)
                                          },
                                        },
                                      },
                                      [_vm._v(" 儲存 ")]
                                    ),
                                    _vm._v("   "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: channel.editor,
                                            expression: "channel.editor",
                                          },
                                        ],
                                        staticClass: "btn btn-default",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancel(channel, index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 取消 ")]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._m(6),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("圖片建議尺寸為正方型。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "target_name" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 姓名： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "target_name" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 性別： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("類型： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "140px" }, attrs: { width: "10%" } },
        [_vm._v("平台")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v(" 粉絲團/頻道名稱 ")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("連結")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "70px" }, attrs: { width: "6%" } },
        [_vm._v("列表")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "70px" }, attrs: { width: "6%" } },
        [_vm._v("粉專")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "95px" }, attrs: { width: "8%" } },
        [_vm._v("爬蟲狀態")]
      ),
      _c(
        "th",
        {
          staticStyle: { "min-width": "180px" },
          attrs: { align: "center", width: "12%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "note mt-10" }, [
      _vm._v(" *"),
      _c("span", { staticClass: "color-red" }, [_vm._v("各")]),
      _vm._v("社群平台第一筆資料預設為主要社群 "),
      _c("br"),
      _vm._v(' *平台選擇youtube時連結欄位提示「使用"'),
      _c("b", [_vm._v("影片")]),
      _vm._v('"頁(連結中帶有'),
      _c(
        "a",
        {
          staticClass: "text-line",
          attrs: { href: "https://reurl.cc/1YLlQD", target: "_blank" },
        },
        [
          _c("b", [_vm._v(" videos")]),
          _vm._v(". ex:https://www.youtube.com/c/xxx/"),
          _c("b", [_vm._v("videos")]),
        ]
      ),
      _vm._v(")」"),
      _c("br"),
      _vm._v(
        ' *一開始新增社群資料不需勾選"列表"，點擊"儲存"後，系統會自動勾選。 '
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }