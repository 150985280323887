var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot max1040", attrs: { id: "activity" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動機制")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("活動機制")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.遊戲名稱,
                    expression: "searchData.遊戲名稱",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "遊戲名稱",
                  type: "text",
                },
                domProps: { value: _vm.searchData.遊戲名稱 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "遊戲名稱", $event.target.value)
                  },
                },
              }),
              _vm.searchData.遊戲名稱
                ? _c("i", {
                    staticClass: "fa fa-close",
                    on: {
                      click: function ($event) {
                        _vm.searchData.遊戲名稱 = ""
                      },
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _c("span", [_vm._v("資料總筆數：")]),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                attrs: { id: "classTypes" },
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "max-width": "90px" },
                        },
                        [_vm._v("圖示")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeSort()
                                },
                              },
                            },
                            [
                              _vm._v(" App名稱 "),
                              _c("i", { staticClass: "fa fa-sort" }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "40%" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm.total == 0
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "3" } },
                            [_c("Empty")],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.list, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticClass: "text-nowrap text-center" }, [
                          _c("div", { staticClass: "photo" }, [
                            _c("img", { attrs: { src: item.圖示, alt: "" } }),
                          ]),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.遊戲名稱))]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-10",
                              on: {
                                click: function ($event) {
                                  return _vm.add(item)
                                },
                              },
                            },
                            [_vm._v(" 機制項目 ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-10",
                              on: {
                                click: function ($event) {
                                  return _vm.showContent("normal", item)
                                },
                              },
                            },
                            [_vm._v(" 常態活動 ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.showContent("abnormal", item)
                                },
                              },
                            },
                            [_vm._v(" 非常態活動 ")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _vm.total != 0
          ? _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.pageSize,
                        expression: "searchData.pageSize",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control page-size mr-10",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "pageSize",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("每頁筆數20"),
                    ]),
                    _c("option", { attrs: { value: "30" } }, [
                      _vm._v("每頁筆數30"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("每頁筆數50"),
                    ]),
                  ]
                ),
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    type2: true,
                    total: _vm.total,
                    pageLimitCount: _vm.searchData.pageSize,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }