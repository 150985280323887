var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "gamehallList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("App：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.遊戲編號,
                      expression: "searchData.遊戲編號",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "遊戲編號",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: -1 } }, [_vm._v("全部")]),
                  _vm._l(_vm.gameList, function (game, index) {
                    return _c("option", { domProps: { value: game.編號 } }, [
                      _vm._v(" " + _vm._s(game.遊戲名稱) + " "),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("標籤：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.標籤編號,
                      expression: "searchData.標籤編號",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "標籤編號",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.tagList, function (tag, index) {
                    return _c(
                      "option",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: tag.被標記數 > 0,
                            expression: "tag.被標記數 > 0",
                          },
                        ],
                        key: index,
                        domProps: { value: tag.編號 },
                      },
                      [_vm._v(_vm._s(tag.標籤名稱))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "input-group mr-10" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("搜尋玩法："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.玩法名稱,
                    expression: "searchData.玩法名稱",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "請輸入玩法",
                  type: "text",
                },
                domProps: { value: _vm.searchData.玩法名稱 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "玩法名稱", $event.target.value)
                  },
                },
              }),
              _vm.searchData.玩法名稱 != ""
                ? _c("i", {
                    staticClass: "fa fa-close",
                    on: {
                      click: function ($event) {
                        _vm.searchData.玩法名稱 = ""
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("搜尋關鍵字："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.遊戲館名稱,
                    expression: "searchData.遊戲館名稱",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "請輸入關鍵字",
                  type: "text",
                },
                domProps: { value: _vm.searchData.遊戲館名稱 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "遊戲館名稱", $event.target.value)
                  },
                },
              }),
              _vm.searchData.遊戲館名稱 != ""
                ? _c("i", {
                    staticClass: "fa fa-close",
                    on: {
                      click: function ($event) {
                        _vm.searchData.遊戲館名稱 = ""
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "text", staticStyle: { "margin-top": "23px" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                attrs: { id: "classTypes" },
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c("th", { staticStyle: { "min-width": "97px" } }, [
                        _vm._v("圖示"),
                      ]),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeSort()
                                },
                              },
                            },
                            [
                              _vm._v(" 遊戲名稱 "),
                              _c("i", { staticClass: "fa fa-sort" }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [_vm._v("App名稱")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [_vm._v("玩法")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [_vm._v("標籤")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [_vm._v("建立時間")]
                      ),
                      _c(
                        "th",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: { width: "50%" },
                        },
                        [_vm._v("更新時間")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "100px", width: "10%" },
                        },
                        [_vm._v("啟用")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "250px", width: "20%" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm.total == 0
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "8" } },
                            [_c("Empty")],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.list, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticClass: "text-nowrap text-center" }, [
                          _c("div", { staticClass: "photo" }, [
                            _c("img", { attrs: { src: item.圖示, alt: "" } }),
                          ]),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.遊戲館名稱))]),
                        _c("td", [_vm._v(_vm._s(item.遊戲名稱))]),
                        _c(
                          "td",
                          { staticClass: "text-nowrap text-center" },
                          _vm._l(item.玩法集合, function (play) {
                            return _c("div", { staticClass: "mb-5" }, [
                              _vm._v(" " + _vm._s(play.玩法名稱) + " "),
                            ])
                          }),
                          0
                        ),
                        _c(
                          "td",
                          { staticClass: "text-nowrap text-center" },
                          _vm._l(item.標籤集合, function (tag) {
                            return _c(
                              "button",
                              {
                                staticClass: "tag btn btn-success mb-5",
                                staticStyle: { display: "block" },
                                attrs: { disabled: tag.是否啟用 == "0" },
                              },
                              [_vm._v(" " + _vm._s(tag.標籤名稱) + " ")]
                            )
                          }),
                          0
                        ),
                        _c("td", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                item.建立時間,
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                        _c("td", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                item.更新時間,
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ckbx-style-8 ckbx toggleInput" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.是否啟用,
                                      expression: "item.是否啟用",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 0,
                                    id: "check" + index,
                                    "true-value": 1,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                    disabled: "",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.是否啟用)
                                      ? _vm._i(item.是否啟用, null) > -1
                                      : _vm._q(item.是否啟用, 1),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.是否啟用,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "是否啟用", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "check" + index },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(item)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-10",
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item)
                                },
                              },
                            },
                            [_vm._v("基本資料")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.showContent(item)
                                },
                              },
                            },
                            [_vm._v("內容說明")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _vm.total != 0
          ? _c(
              "div",
              { staticClass: "box-footer text-center no-border" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.pageSize,
                        expression: "searchData.pageSize",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control page-size mr-10",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "pageSize",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "20" } }, [
                      _vm._v("每頁筆數20"),
                    ]),
                    _c("option", { attrs: { value: "30" } }, [
                      _vm._v("每頁筆數30"),
                    ]),
                    _c("option", { attrs: { value: "50" } }, [
                      _vm._v("每頁筆數50"),
                    ]),
                  ]
                ),
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    total: _vm.total,
                    pageLimitCount: _vm.searchData.pageSize,
                    type2: true,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }