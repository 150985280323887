var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "PopForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "600px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c("div", { staticClass: "box box-info" }, [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", { staticClass: "box-title" }, [
              _vm._v(
                _vm._s(_vm.isEdit ? _vm.$t("修改") : _vm.$t("新增")) +
                  " " +
                  _vm._s(
                    _vm.edit.isCustomize
                      ? _vm.$t("自定義罐頭訊息")
                      : _vm.$t("罐頭訊息")
                  ) +
                  " "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.Close()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("專案")) + "："),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.project,
                          expression: "edit.project",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: _vm.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "project",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      !_vm.isEdit
                        ? _c("option", { attrs: { value: "-1" } }, [
                            _vm._v(_vm._s(_vm.$t("請選擇專案"))),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.Service, function (item) {
                        return _c(
                          "option",
                          { key: item, domProps: { value: item } },
                          [_vm._v(_vm._s(_vm.$t(item)) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              !_vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: {
                        "margin-top": "-15px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("label", { staticClass: "col-sm-2 control-label" }),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("span", { staticClass: "red" }, [
                          _vm._v(_vm._s(_vm.$t("新增後，專案無法修改"))),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("類別")) + "："),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.categoryID,
                          expression: "edit.categoryID",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "categoryID",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      !_vm.isEdit
                        ? _c("option", { attrs: { value: "-1" } }, [
                            _vm._v(_vm._s(_vm.$t("請選擇問題類別"))),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.filterCategories, function (item) {
                        return _c(
                          "option",
                          {
                            key: item.categoryID,
                            domProps: { value: item.categoryID },
                          },
                          [
                            _vm._v(
                              _vm._s(item.name) +
                                _vm._s(
                                  item.enable == false
                                    ? _vm.$t("Scratch停用中")
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("問題")) + "："),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.question,
                        expression: "edit.question",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { height: "100px" },
                    attrs: { placeholder: "" },
                    domProps: { value: _vm.edit.question },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "question",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("回覆")) + "："),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.answer,
                        expression: "edit.answer",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { height: "100px" },
                    attrs: { placeholder: "" },
                    domProps: { value: _vm.edit.answer },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "answer", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("排序")) + "："),
                ]),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim.number",
                        value: _vm.edit.sort,
                        expression: "edit.sort",
                        modifiers: { trim: true, number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      disabled: _vm.edit.enable == false,
                    },
                    domProps: { value: _vm.edit.sort },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "sort",
                          _vm._n($event.target.value.trim())
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-2 control-label" }, [
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  _vm._v(_vm._s(_vm.$t("狀態")) + "："),
                ]),
                _c("div", { staticStyle: { "padding-top": "7px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "ckbx-style-8 ckbx toggleInput",
                      on: {
                        click: function ($event) {
                          _vm.edit.enable = !_vm.edit.enable
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.enable,
                            expression: "edit.enable",
                          },
                        ],
                        attrs: {
                          "false-value": false,
                          "true-value": true,
                          name: "ckbx-style-8",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.edit.enable)
                            ? _vm._i(_vm.edit.enable, null) > -1
                            : _vm.edit.enable,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.edit.enable,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.edit,
                                    "enable",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.edit,
                                    "enable",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.edit, "enable", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "check" } }),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.Close()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("取消")))]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.Save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("確認送出")))]
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }