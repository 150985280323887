var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ServiceList02" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("服務範疇-真誠關懷/悅讀助學")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v("服務範疇-真誠關懷/悅讀助學"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "banner-picker",
              {
                attrs: {
                  "img-help":
                    "圖片尺寸為 1400 * 400px，大小請勿超過 2Mb，選完要儲存！",
                },
              },
              [_vm._v(" 標頭圖 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "list-table",
              {
                attrs: {
                  "show-list": "index title file status",
                  "file-help": "可上傳所有文件檔案類型",
                },
              },
              [_vm._v(" 相關檔案 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }