var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "popUpImg", staticClass: "modal fade" }, [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c(
              "h4",
              { staticClass: "modal-title", staticStyle: { color: "#333333" } },
              [_vm._v(_vm._s(_vm.popUpImgSrc))]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "modal-body",
              staticStyle: { "text-align": "center" },
            },
            [_c("img", { attrs: { src: _vm.popUpImgSrc, alt: "" } })]
          ),
          _c("div", { staticClass: "modal-footer" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: { "data-dismiss": "modal", "aria-label": "Close" },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }