var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "TypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.type) + " " + _vm._s(_vm.$t("服務類別管理"))),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v(_vm._s(_vm.$t("首頁"))),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.type) + _vm._s(_vm.$t("服務類別管理"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("新增")) + " ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered text-center",
                attrs: { id: "classTypes" },
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        { staticStyle: { "min-width": "150px", width: "40%" } },
                        [_vm._v(_vm._s(_vm.$t("分類名稱")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "100px", width: "20%" },
                        },
                        [_vm._v(_vm._s(_vm.$t("啟用狀態")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "140px", width: "20%" },
                        },
                        [_vm._v(_vm._s(_vm.$t("功能")))]
                      ),
                    ]),
                    _vm._l(_vm.datas, function (item, index) {
                      return _c("tr", { key: item.編號 }, [
                        _c("td", { staticClass: "text-left" }, [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.isEdit,
                                  expression: "!item.isEdit",
                                },
                              ],
                              staticClass: "m-0",
                            },
                            [_vm._v(_vm._s(item.名稱))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isEdit,
                                expression: "item.isEdit",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.名稱,
                                expression: "item.名稱",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", required: "" },
                            domProps: { value: item.名稱 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "名稱", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "fieldset",
                            {
                              staticClass: "ckbx-style-8 ckbx toggleInput",
                              style: !_vm.can修改 ? "opacity:.5;" : false,
                              attrs: { disabled: !_vm.can修改 },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.啟用YN,
                                    expression: "item.啟用YN",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  "true-value": 0,
                                  "false-value": 1,
                                  id: "check" + index,
                                  name: "ckbx-style-8",
                                },
                                domProps: {
                                  checked: Array.isArray(item.啟用YN)
                                    ? _vm._i(item.啟用YN, null) > -1
                                    : _vm._q(item.啟用YN, 0),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = item.啟用YN,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 0 : 1
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              item,
                                              "啟用YN",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              item,
                                              "啟用YN",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(item, "啟用YN", $$c)
                                      }
                                    },
                                    function ($event) {
                                      return _vm.changeStatus(item)
                                    },
                                  ],
                                },
                              }),
                              _c("label", { attrs: { for: "check" + index } }),
                            ]
                          ),
                        ]),
                        _vm.can修改
                          ? _c("td", [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.isEdit,
                                      expression: "!item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(item, index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("修改")))]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-success",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save(item, index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("儲存")))]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-default ml-5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel(item, index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("取消")))]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }