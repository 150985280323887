var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲操作說明編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲操作說明列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲操作說明編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.version,
                          expression: "edit.version",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.version },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "version", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10 btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn mr-10",
                        class: { "btn-primary": _vm.type == "電腦版" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.type = "電腦版"
                          },
                        },
                      },
                      [_vm._v(" 電腦版 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: { "btn-primary": _vm.type == "手機版" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.type = "手機版"
                          },
                        },
                      },
                      [_vm._v(" 手機版 ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(_vm._s(_vm.type) + "圖片： "),
                  ]),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _vm.type == "電腦版"
                      ? _c("div", [
                          _vm.pcImg
                            ? _c(
                                "div",
                                { staticClass: "pic d-inline chooseBox" },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    attrs: { title: "刪除檔案" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(_vm.pcImg.id)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.pcImg.filesUrl,
                                    },
                                  }),
                                ]
                              )
                            : _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "pc",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event, "pc")
                                  },
                                },
                              }),
                        ])
                      : _c("div", [
                          _vm.mobileImg
                            ? _c(
                                "div",
                                { staticClass: "pic d-inline chooseBox" },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    attrs: { title: "刪除檔案" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(_vm.mobileImg.id)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    attrs: {
                                      draggable: "false",
                                      src: _vm.mobileImg.filesUrl,
                                    },
                                  }),
                                ]
                              )
                            : _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  id: "mobile",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileChange($event, "mobile")
                                  },
                                },
                              }),
                        ]),
                    _c("div", { staticClass: "help-block" }, [
                      _vm._v("建議尺寸935*525"),
                    ]),
                    _vm.isCreate
                      ? _c("div", { staticClass: "help-block" }, [
                          _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                        ])
                      : _c("div", { staticClass: "help-block" }, [
                          _vm._v("重選檔案，請先刪除圖片"),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.type) + "說明： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("tinymce", {
                          model: {
                            value: _vm.toggleContent,
                            callback: function ($$v) {
                              _vm.toggleContent = $$v
                            },
                            expression: "toggleContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.sort,
                          expression: "edit.sort",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputSort", type: "text" },
                      domProps: { value: _vm.edit.sort },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "sort", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("版本名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("版本類型： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputSort" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }