var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popForm",
      staticClass: "modal fade",
      attrs: { "data-backdrop": "static" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { "aria-label": "Close", type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title" }, [_vm._v("重置")]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(" 訂單編號：")]),
              _vm._v(_vm._s(_vm.訂單編號) + " "),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("備註：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.備註,
                      expression: "備註",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "請輸入備註" },
                  domProps: { value: _vm.備註 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.備註 = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "box-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_vm._v("取消")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary pull-right",
                staticStyle: { "margin-right": "15px" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.send()
                  },
                },
              },
              [_vm._v("確認重置")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }