var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box" },
            [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.查詢方式,
                        expression: "查詢方式",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.查詢方式 = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "一般查詢" } }, [
                      _vm._v("一般查詢"),
                    ]),
                    _c("option", { attrs: { value: "訂單查詢" } }, [
                      _vm._v("訂單查詢"),
                    ]),
                    _c("option", { attrs: { value: "識別值查詢" } }, [
                      _vm._v("識別值查詢"),
                    ]),
                    _c("option", { attrs: { value: "訂單購買門號查詢" } }, [
                      _vm._v("訂單購買門號查詢"),
                    ]),
                  ]
                ),
              ]),
              _vm.查詢方式 == "一般查詢"
                ? [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("廠商："),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.服務編號,
                              expression: "searchData.服務編號",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "150px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "服務編號",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._l(_vm.廠商options, function (item) {
                            return _c(
                              "option",
                              {
                                key: item.廠商編號,
                                domProps: { value: item.廠商編號 },
                              },
                              [_vm._v(_vm._s(item.顯示名稱) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("年份："),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.發票年份,
                              expression: "searchData.發票年份",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "80px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "發票年份",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.年份options, function (year) {
                          return _c(
                            "option",
                            { key: year, domProps: { value: year } },
                            [_vm._v(_vm._s(year))]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("獎項："),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.中獎類別,
                              expression: "searchData.中獎類別",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "250px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "中獎類別",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _c("option", { attrs: { value: "A" } }, [
                            _vm._v("特別獎-1000萬(A)"),
                          ]),
                          _c("option", { attrs: { value: "B" } }, [
                            _vm._v("雲端發票專屬兩千元獎-2,000元(B)"),
                          ]),
                          _c("option", { attrs: { value: "C" } }, [
                            _vm._v("雲端發票專屬百萬元獎-100萬元(C)"),
                          ]),
                          _c("option", { attrs: { value: "D" } }, [
                            _vm._v("雲端發票專屬五百元獎-500元(D)"),
                          ]),
                          _c("option", { attrs: { value: "E" } }, [
                            _vm._v("雲端發票專屬八百元獎-800元(E)"),
                          ]),
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("特獎-200萬元(0)"),
                          ]),
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("頭獎-20萬元(1)"),
                          ]),
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v("二獎-4萬元(2)"),
                          ]),
                          _c("option", { attrs: { value: "3" } }, [
                            _vm._v("三獎-1萬元(3)"),
                          ]),
                          _c("option", { attrs: { value: "4" } }, [
                            _vm._v("四獎-4,000元(4)"),
                          ]),
                          _c("option", { attrs: { value: "5" } }, [
                            _vm._v("五獎-1000元(5)"),
                          ]),
                          _c("option", { attrs: { value: "6" } }, [
                            _vm._v("六獎-200元(6)"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("期數："),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.發票期數,
                              expression: "searchData.發票期數",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "100px" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "發票期數",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _c("option", { attrs: { value: "1, 2月" } }, [
                            _vm._v("1.2月"),
                          ]),
                          _c("option", { attrs: { value: "3, 4月" } }, [
                            _vm._v("3.4月"),
                          ]),
                          _c("option", { attrs: { value: "5, 6月" } }, [
                            _vm._v("5.6月"),
                          ]),
                          _c("option", { attrs: { value: "7, 8月" } }, [
                            _vm._v("7.8月"),
                          ]),
                          _c("option", { attrs: { value: "9, 10月" } }, [
                            _vm._v("9.10月"),
                          ]),
                          _c("option", { attrs: { value: "11, 12月" } }, [
                            _vm._v("11.12月"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-right": "0px" },
                      },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("歸戶類型："),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.歸戶類型,
                                expression: "searchData.歸戶類型",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { width: "80px" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "歸戶類型",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("全部"),
                            ]),
                            _c("option", { attrs: { value: "A" } }, [
                              _vm._v("A檔"),
                            ]),
                            _c("option", { attrs: { value: "X" } }, [
                              _vm._v("X檔"),
                            ]),
                            _c("option", { attrs: { value: "Z" } }, [
                              _vm._v("Z檔"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        staticStyle: { "margin-right": "0px" },
                      },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("是否列印："),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.是否列印,
                                expression: "searchData.是否列印",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { width: "90px" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "是否列印",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("全部"),
                            ]),
                            _c("option", { domProps: { value: true } }, [
                              _vm._v("已列印"),
                            ]),
                            _c("option", { domProps: { value: false } }, [
                              _vm._v("未列印"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm.查詢方式 == "訂單查詢"
                ? [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchData.訂單編號,
                            expression: "searchData.訂單編號",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "220px" },
                        attrs: { type: "text", placeholder: "訂單編號" },
                        domProps: { value: _vm.searchData.訂單編號 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchData,
                              "訂單編號",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]
                : _vm.查詢方式 == "識別值查詢"
                ? [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchData.暱稱,
                            expression: "searchData.暱稱",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "220px" },
                        attrs: {
                          type: "text",
                          placeholder: "識別值，用,分隔，可查詢多個",
                        },
                        domProps: { value: _vm.searchData.暱稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchData,
                              "暱稱",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchData.門號,
                            expression: "searchData.門號",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "220px" },
                        attrs: { type: "text", placeholder: "訂單購買門號" },
                        domProps: { value: _vm.searchData.門號 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchData,
                              "門號",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ],
              _c("br"),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch(1, _vm.searchData)
                        },
                      },
                    },
                    [_vm._v(" 查詢 ")]
                  ),
                  _vm.lastQueryCommand && _vm.total > 0
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "button" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticStyle: { color: "white" },
                                attrs: { download: "", href: _vm.匯出結果網址 },
                              },
                              [_vm._v(" 匯出查詢結果")]
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success disabled",
                            attrs: { type: "button" },
                          },
                          [_vm._v(" 匯出查詢結果 ")]
                        ),
                      ],
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.開啟匯入頁面()
                        },
                      },
                    },
                    [_vm._v(" 匯入中獎發票 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.開啟寄送簡訊頁面()
                        },
                      },
                    },
                    [_vm._v(" A檔寄送簡訊 ")]
                  ),
                ],
                2
              ),
            ],
            2
          ),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm._l(_vm.list, function (item) {
                      return _c("tr", { key: item.訂單編號 }, [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.顯示廠商(item.服務編號))),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.發票年份)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.發票期數)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.訂單編號) + " "),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.門號)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.暱稱)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.購買日期)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.發票號碼)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.獎項)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.歸戶類型)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.是否寄送簡訊
                            ? _c("span", [_vm._v("已寄送")])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center text-nowrap" },
                          [
                            item.歸戶類型 == "A"
                              ? _c(
                                  "span",
                                  [
                                    item.可領獎狀態 == 0
                                      ? [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-info",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendSMS(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 寄送簡訊 ")]
                                          ),
                                          _c("br"),
                                        ]
                                      : _vm._e(),
                                    item.是否列印
                                      ? _c(
                                          "span",
                                          { staticClass: "text-green" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-warning btn-xs",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.確認重置(item)
                                                  },
                                                },
                                              },
                                              [_vm._v("重置")]
                                            ),
                                            _c("br"),
                                            _vm._v(" 已列印 "),
                                            _c("br"),
                                            _vm._v(
                                              " " + _vm._s(item.列印時間) + " "
                                            ),
                                          ]
                                        )
                                      : [
                                          item.可領獎狀態 == 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-light-blue",
                                                },
                                                [_vm._v("未列印")]
                                              )
                                            : _vm._e(),
                                          item.可領獎狀態 == 1
                                            ? _c(
                                                "span",
                                                { staticClass: "text-green" },
                                                [_vm._v("尚未至領獎日")]
                                              )
                                            : _vm._e(),
                                          item.可領獎狀態 == 2
                                            ? _c(
                                                "span",
                                                { staticClass: "text-red" },
                                                [_vm._v("已逾期")]
                                              )
                                            : _vm._e(),
                                        ],
                                  ],
                                  2
                                )
                              : [_vm._v(" - ")],
                          ],
                          2
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.備註)),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  _vm.開啟發票詳細頁面(item)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.歸戶類型 == "A",
                                  expression: "item.歸戶類型 == 'A'",
                                },
                              ],
                              staticClass: "btn btn-info",
                              on: {
                                click: function ($event) {
                                  return _vm.switcPrintTab({
                                    查詢方式: "訂單查詢",
                                    訂單編號: item.訂單編號,
                                    門號: item.門號,
                                  })
                                },
                              },
                            },
                            [_vm._v("列印歷程")]
                          ),
                        ]),
                      ])
                    }),
                    _vm.list.length == 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "14" },
                            },
                            [_vm._v("沒有資料")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pageSize,
                    expression: "pageSize",
                  },
                ],
                staticClass: "form-control page-size",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.pageSize = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: 10 } }, [
                  _vm._v("單頁顯示10項"),
                ]),
                _c("option", { domProps: { value: 20 } }, [
                  _vm._v("單頁顯示20項"),
                ]),
                _c("option", { domProps: { value: 40 } }, [
                  _vm._v("單頁顯示40項"),
                ]),
                _c("option", { domProps: { value: 100 } }, [
                  _vm._v("單頁顯示100項"),
                ]),
              ]
            ),
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
      _c("InvoicePopDetailForm", {
        ref: "invoicePopDetailForm",
        on: { 更新列表: _vm.onPageRefresh },
      }),
      _c("InvoicePopImportForm", { ref: "invoicePopImportForm" }),
      _c("InvoicePopSMSForm", {
        ref: "invoicePopSMSForm",
        attrs: { 廠商options: _vm.廠商options, 年份options: _vm.年份options },
      }),
      _c("InvoicePopResetForm", {
        ref: "invoicePopResetForm",
        on: { 更新列表: _vm.onPageRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "btn-group", staticStyle: { "margin-right": "15px" } },
      [
        _c(
          "button",
          {
            staticClass: "btn dropdown-toggle",
            attrs: {
              type: "button",
              "data-toggle": "dropdown",
              "aria-expanded": "true",
            },
          },
          [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
        ),
        _c(
          "div",
          { staticClass: "dropdown-menu", staticStyle: { padding: "15px" } },
          [
            _c("table", { staticClass: "text-nowrap" }, [
              _c("tr", [
                _c("td", [_c("dt", [_vm._v("Y檔,無歸戶")])]),
                _c("td", [_vm._v("索取紙本發票(我們公司無此項目)")]),
              ]),
              _c("tr", [
                _c("td", [_c("dt", [_vm._v("A檔,無歸戶")])]),
                _c("td", [_vm._v("通知中獎人,提供兌換碼請玩家至全家列印")]),
              ]),
              _c("tr", [
                _c("td", [_c("dt", [_vm._v("X檔,已歸戶")])]),
                _c("td", [_vm._v("未設定自動匯款")]),
              ]),
              _c("tr", [
                _c("td", [_c("dt", [_vm._v("Z檔,已歸戶")])]),
                _c("td", [_vm._v("已設定自動匯款")]),
              ]),
              _c("tr", [
                _c("td", [_c("dt", [_vm._v("Z檔")])]),
                _c("td", [_vm._v("設定捐贈碼捐贈")]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", [_vm._v("廠商")]),
      _c("th", [_vm._v("發票年份")]),
      _c("th", [_vm._v("發票期數")]),
      _c("th", [_vm._v("訂單編號")]),
      _c("th", [_vm._v("訂單購買門號")]),
      _c("th", [_vm._v("識別值")]),
      _c("th", [_vm._v("購買日期")]),
      _c("th", [_vm._v("發票號碼")]),
      _c("th", [_vm._v("獎項")]),
      _c("th", [_vm._v("歸戶/類型")]),
      _c("th", [_vm._v("簡訊")]),
      _c("th", [_vm._v("發票列印")]),
      _c("th", [_vm._v("備註")]),
      _c("th", [_vm._v("詳細資料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }