var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("輪播管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("輪播管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("輪播列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增內容 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.classTypeId,
                      expression: "searchData.classTypeId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "classTypeId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                _vm._l(_vm.classTypes.datas, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.classTypeId } },
                    [_vm._v(_vm._s(item.className))]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.listDatas.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.listDatas.total > 0
                      ? _vm._l(_vm.listDatas.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.contentId)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.classTypeName)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.detailTopTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.isStop == "1" ? "下架" : "上架")
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.FormatTime(item.publishTime))),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            _c("td", { staticClass: "td-sort text-nowrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.sortId,
                                    expression: "item.sortId",
                                  },
                                ],
                                attrs: { type: "number" },
                                domProps: { value: item.sortId },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "sortId",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-warning btn-add btn-upDate",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateGameOd(item)
                                    },
                                  },
                                },
                                [_vm._v("更新")]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listDatas.total > 0,
                expression: "listDatas.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.listDatas.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("ID"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("分類"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("發佈日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("建立日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("動態排序"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }