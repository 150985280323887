var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "serialNumber", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("序號長度(含活動代碼)")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.snCodeLen,
                          expression: "edit.snCodeLen",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "number" },
                      domProps: { value: _vm.edit.snCodeLen },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "snCodeLen",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("產生序號數量")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.snCount,
                          expression: "edit.snCount",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { min: "1", required: "required", type: "number" },
                      domProps: { value: _vm.edit.snCount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "snCount",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.UpdateSerialNumber()
                },
              },
            },
            [_vm._v(" 產生序號 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 取消 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("序號產生")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }