var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NewtalkAnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("討論區公告內容")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/Newtalk/AnnouncementList" } }, [
              _vm._v(" 討論區公告管理 "),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("討論區公告內容")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _vm._m(0),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.data.articleTitle,
                        expression: "data.articleTitle",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputTitle",
                      maxlength: "50",
                      placeholder: "請輸入標題，上限50字",
                      type: "text",
                      required: "",
                    },
                    domProps: { value: _vm.data.articleTitle },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.data,
                          "articleTitle",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "editor" },
                    [
                      _c("tinymce", {
                        attrs: { propLang: _vm.editLang },
                        model: {
                          value: _vm.data.articleContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "articleContent", $$v)
                          },
                          expression: "data.articleContent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          placeholder: "自行輸入標籤",
                          label: "Tag",
                          "track-by": "Tag",
                          required: "",
                          options: _vm.options,
                          "hide-selected": true,
                          "internal-search": false,
                          multiple: true,
                          taggable: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          selectLabel: "",
                          "max-elements": "已達標籤上限",
                        },
                        on: {
                          tag: _vm.addTag,
                          "search-change": _vm.updateTags,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _c("div", { staticClass: "option__desc" }, [
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(
                                      " " + _vm._s(props.option.Tag) + " "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "option__small",
                                      staticStyle: { float: "right" },
                                    },
                                    [
                                      _vm._v(
                                        " 使用文章數：" +
                                          _vm._s(props.option.UsedCount || 0) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.data.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "tags", $$v)
                          },
                          expression: "data.tags",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "notice",
                            attrs: { slot: "noOptions" },
                            slot: "noOptions",
                          },
                          [_vm._v("列表為空")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "notice",
                            attrs: { slot: "noResult" },
                            slot: "noResult",
                          },
                          [_vm._v("查無結果")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "notice maxElements",
                            attrs: { slot: "maxElements" },
                            slot: "maxElements",
                          },
                          [_vm._v(" 已達標籤上限 ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "上架",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.data.isDelete,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "isDelete", _vm._n($$v))
                          },
                          expression: "data.isDelete",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "2",
                          message: "下架",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.data.isDelete,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "isDelete", _vm._n($$v))
                          },
                          expression: "data.isDelete",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _vm.data.isDelete === 2
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-warning btn-lg",
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.submit(true)
                          },
                        },
                      },
                      [_vm._v(" 預覽 ")]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submit(false)
                      },
                    },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告分類： "),
      ]),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("select", { staticClass: "form-control", attrs: { disabled: "" } }, [
          _c("option", { attrs: { value: "官方" } }, [_vm._v("官方")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }