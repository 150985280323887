var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "voteContent", staticClass: "modal fade newtalk normal" },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("ul", [
              _c("li", [
                _c("div", { staticClass: "blue" }, [_vm._v("正方")]),
                _c("div", { staticClass: "gray" }, [
                  _c(
                    "div",
                    { staticClass: "fl" },
                    [
                      _vm._v(" 預設圖示： "),
                      _vm._l(_vm.positiveImgs, function (name, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "pointer ml-10" },
                          [
                            _c("img", {
                              staticClass: "blue",
                              class: {
                                "is-active": name === _vm.positiveSideName,
                              },
                              attrs: {
                                width: "50px",
                                name: name,
                                src: require(`@/assets/img/${name}`),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImage(name, "positive")
                                },
                              },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "fl ml-20" }, [
                    _vm._v(" 上傳圖示： "),
                    _c("input", {
                      attrs: {
                        accept: "image/*",
                        type: "file",
                        id: "positiveSide",
                        name: "positiveSide",
                        required: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeFile($event, "positive")
                        },
                      },
                    }),
                  ]),
                ]),
                _c("img", {
                  staticClass: "mt-25",
                  attrs: { width: "100px", src: _vm.positiveSideUrl },
                }),
              ]),
              _c("li", { staticClass: "mt-30" }, [
                _c("div", { staticClass: "red" }, [_vm._v("反方")]),
                _c("div", { staticClass: "gray" }, [
                  _c(
                    "div",
                    { staticClass: "fl" },
                    [
                      _vm._v(" 預設圖示： "),
                      _vm._l(_vm.negativeImgs, function (name, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "pointer ml-10" },
                          [
                            _c("img", {
                              staticClass: "red",
                              class: {
                                "is-active": name === _vm.negativeSideName,
                              },
                              attrs: {
                                width: "50px",
                                name: name,
                                src: require(`@/assets/img/${name}`),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImage(name, "negative")
                                },
                              },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "fl ml-20" }, [
                    _vm._v(" 上傳圖示： "),
                    _c("input", {
                      attrs: {
                        accept: "image/*",
                        type: "file",
                        id: "negativeSide",
                        name: "negativeSide",
                        required: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeFile($event, "negative")
                        },
                      },
                    }),
                  ]),
                ]),
                _c("img", {
                  staticClass: "mt-25",
                  attrs: { width: "100px", src: _vm.negativeSideUrl },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(" 確定 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v(" 關閉 ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("辯論圖示")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }