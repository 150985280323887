var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjGameDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("牌局紀錄查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("牌局紀錄查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.resetDataList()
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(" 查詢時間： "),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _c("div", {
                      staticClass: "input-group-addon fa fa-calendar",
                    }),
                    _c("DateRangePicker", {
                      attrs: {
                        propStartDate: _vm.searchData.StartTime,
                        propEndDate: _vm.searchData.EndTime,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "StartTime", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "StartTime", $event)
                        },
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "EndTime", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "EndTime", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.TableID,
                      expression: "searchData.TableID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "牌桌ID" },
                  domProps: { value: _vm.searchData.TableID },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "TableID",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.PlayerName,
                      expression: "searchData.PlayerName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "玩家暱稱" },
                  domProps: { value: _vm.searchData.PlayerName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "PlayerName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "btns mb-10" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.datas.length <= 0 },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: Object.keys(_vm.$route.query).length > 0,
                      expression: "Object.keys($route.query).length > 0",
                    },
                  ],
                  staticClass: "btn btn-default pull-right fa fa-arrow-left",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(" 返回")]
              ),
            ]
          ),
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c("table", { staticClass: "table table-bordered text-center" }, [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm.loading
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v("查詢中"),
                        ]),
                      ])
                    : _vm.result.message
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v(_vm._s(_vm.result.message)),
                        ]),
                      ])
                    : _vm.datas.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v("查詢無任何資料"),
                        ]),
                      ])
                    : _vm._l(_vm.pagedData, function (item, index) {
                        return [
                          _c("tr", [
                            _c("td", {
                              domProps: { textContent: _vm._s(item.牌桌ID) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.圈風) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.連莊次數) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.牌桌類型) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.籌碼類型) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.底) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.台) },
                            }),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  class: {
                                    mark:
                                      item.桌主暱稱 &&
                                      _vm.searchData.PlayerName ==
                                        item.桌主暱稱,
                                  },
                                },
                                [
                                  /^NPC-\d|^$/.test(item.桌主暱稱)
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.桌主暱稱 || "-")),
                                      ])
                                    : _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.贏家暱稱 },
                                          },
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.桌主暱稱),
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.牌局結果) },
                            }),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  class: {
                                    mark:
                                      item.贏家暱稱 &&
                                      _vm.searchData.PlayerName ==
                                        item.贏家暱稱,
                                  },
                                },
                                [
                                  /^NPC-\d|^$/.test(item.贏家暱稱)
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.贏家暱稱 || "-")),
                                      ])
                                    : _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.贏家暱稱 },
                                          },
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.贏家暱稱),
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  class: {
                                    mark:
                                      item.放槍暱稱 &&
                                      _vm.searchData.PlayerName ==
                                        item.放槍暱稱,
                                  },
                                },
                                [
                                  /^NPC-\d|^$/.test(item.放槍暱稱)
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.放槍暱稱 || "-")),
                                      ])
                                    : _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.放槍暱稱 },
                                          },
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.放槍暱稱),
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.總台數) },
                            }),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(item["籌碼變動(不含東錢)"]),
                              },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.東錢) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.剩餘張數) },
                            }),
                            _c("td", [
                              _c(
                                "span",
                                {
                                  class: {
                                    mark:
                                      item.莊家暱稱 &&
                                      _vm.searchData.PlayerName ==
                                        item.莊家暱稱,
                                  },
                                },
                                [
                                  /^NPC-\d|^$/.test(item.莊家暱稱)
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.莊家暱稱 || "-")),
                                      ])
                                    : _c("router-link", {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.莊家暱稱 },
                                          },
                                        },
                                        domProps: {
                                          textContent: _vm._s(item.莊家暱稱),
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c("td", { staticStyle: { padding: ".25em 0" } }, [
                              _c("table", { staticClass: "table-player" }, [
                                _c("tr", [
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("東風位"),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left",
                                      class: {
                                        mark:
                                          _vm.searchData.PlayerName ==
                                          item.東風位暱稱,
                                      },
                                    },
                                    [
                                      /^NPC-\d/.test(item.東風位暱稱)
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.東風位暱稱)),
                                          ])
                                        : _c("router-link", {
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerData",
                                                query: {
                                                  nick: item.東風位暱稱,
                                                },
                                              },
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.東風位暱稱
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.東風位被吃扣分),
                                    },
                                  }),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.東風位技術分),
                                    },
                                  }),
                                ]),
                                _c("tr", {}, [
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("南風位"),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left",
                                      class: {
                                        mark:
                                          _vm.searchData.PlayerName ==
                                          item.南風位暱稱,
                                      },
                                    },
                                    [
                                      /^NPC-\d/.test(item.南風位暱稱)
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.南風位暱稱)),
                                          ])
                                        : _c("router-link", {
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerData",
                                                query: {
                                                  nick: item.南風位暱稱,
                                                },
                                              },
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.南風位暱稱
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.南風位被吃扣分),
                                    },
                                  }),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.南風位技術分),
                                    },
                                  }),
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("西風位"),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left",
                                      class: {
                                        mark:
                                          _vm.searchData.PlayerName ==
                                          item.西風位暱稱,
                                      },
                                    },
                                    [
                                      /^NPC-\d/.test(item.西風位暱稱)
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.西風位暱稱)),
                                          ])
                                        : _c("router-link", {
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerData",
                                                query: {
                                                  nick: item.西風位暱稱,
                                                },
                                              },
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.西風位暱稱
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.西風位被吃扣分),
                                    },
                                  }),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.西風位技術分),
                                    },
                                  }),
                                ]),
                                _c("tr", [
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v("北風位"),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-left",
                                      class: {
                                        mark:
                                          _vm.searchData.PlayerName ==
                                          item.北風位暱稱,
                                      },
                                    },
                                    [
                                      /^NPC-\d/.test(item.北風位暱稱)
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.北風位暱稱)),
                                          ])
                                        : _c("router-link", {
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerData",
                                                query: {
                                                  nick: item.北風位暱稱,
                                                },
                                              },
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.北風位暱稱
                                              ),
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.北風位被吃扣分),
                                    },
                                  }),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(item.北風位技術分),
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(_vm.uiDate(item.結束時間)),
                              },
                            }),
                          ]),
                        ]
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "75px", width: "2%" } }, [
        _vm._v("牌桌ID"),
      ]),
      _c("th", { staticStyle: { "min-width": "60px", width: "2%" } }, [
        _vm._v("圈風"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("連莊"),
        _c("wbr"),
        _vm._v("數"),
      ]),
      _c("th", { staticStyle: { "min-width": "105px", width: "2%" } }, [
        _vm._v("牌局"),
        _c("wbr"),
        _vm._v("模式"),
      ]),
      _c("th", { staticStyle: { "min-width": "60px", width: "2%" } }, [
        _vm._v("貨幣"),
        _c("wbr"),
        _vm._v("類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("底分"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("台分"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("桌主"),
        _c("wbr"),
        _vm._v("暱稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("牌局"),
        _c("wbr"),
        _vm._v("結果"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("胡牌/自摸"),
        _c("wbr"),
        _vm._v("玩家暱稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("放槍玩家"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("總台"),
        _c("wbr"),
        _vm._v("數"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "2%" } }, [
        _vm._v("贏分"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("東錢"),
      ]),
      _c("th", { staticStyle: { "min-width": "50px", width: "2%" } }, [
        _vm._v("剩餘"),
        _c("wbr"),
        _vm._v("張數"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("莊家"),
        _c("wbr"),
        _vm._v("暱稱"),
      ]),
      _c(
        "th",
        {
          staticStyle: {
            "min-width": "280px",
            width: "5%",
            padding: ".25em 0",
          },
        },
        [
          _c("table", { staticClass: "table-player" }, [
            _c("tr", [
              _c("td", [_vm._v("風位")]),
              _c("td", [_vm._v("暱稱")]),
              _c("td", [_vm._v("被吃分")]),
              _c("td", [_vm._v("技術分")]),
            ]),
          ]),
        ]
      ),
      _c("th", { staticStyle: { "min-width": "105px", width: "5%" } }, [
        _vm._v("牌局結束時間"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }