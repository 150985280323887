var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "BigDataPost" }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("貼文資訊")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("網紅列表")]),
        _c("li", [_vm._v("KPI")]),
        _c("li", { staticClass: "active" }, [_vm._v("貼文資訊")]),
      ]),
    ]),
    _vm.postkpiData.post_data
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box with-border" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-default btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backKOL()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回 ")]
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-5" }, [
                  _vm.postkpiData.post_data.platform == "yt"
                    ? _c("div", { staticClass: "video-container" }, [
                        _c("iframe", {
                          attrs: {
                            width: "640",
                            height: "360",
                            src:
                              "https://www.youtube.com/embed/" +
                              _vm.postkpiData.post_data.video_id,
                            frameborder: "0",
                            allowfullscreen: "",
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "post-cardImg" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.postkpiData.post_data.post_display_url ==
                                "" || !_vm.postkpiData.post_data.post_display_ur
                                ? require("@/assets/no-image.png")
                                : _vm.postkpiData.post_data.post_display_url,
                          },
                        }),
                      ]),
                  _c("p", { staticClass: "title fs18 mt-10 mb-5" }, [
                    _c(
                      "a",
                      {
                        staticClass: "postLink",
                        attrs: {
                          href: _vm.GetLink(_vm.postkpiData),
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.GetPostTitle(
                                _vm.postkpiData.post_data.platform
                              )
                            ) +
                            " "
                        ),
                        _c("i", {
                          staticClass: "c-BigDataLinkIcon fa fa-link",
                        }),
                      ]
                    ),
                    _c("br"),
                    _c("small", { staticClass: "text-muted fs14" }, [
                      _vm._v(
                        "發佈日期: " +
                          _vm._s(
                            _vm.$utils.formatTime(
                              _vm.postkpiData.post_data.upload_date,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("p", { staticClass: "fs15" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.backKOL()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.postkpiData.channel_name) + " ")]
                    ),
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(
                        "粉絲數: " +
                          _vm._s(
                            _vm._f("currencyFormat")(
                              _vm.postkpiData.post_data.follow
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.postkpiData.post_data.description),
                    },
                  }),
                  _vm.postkpiData.post_data.tags != null &&
                  _vm.postkpiData.post_data.tags.length > 0
                    ? _c(
                        "div",
                        { staticClass: "label-wrappger" },
                        [
                          _c("p", { staticClass: "title mb-2" }, [
                            _vm._v("貼文標籤:"),
                          ]),
                          _vm._l(
                            _vm.postkpiData.post_data.tags,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "label label-success",
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("hr", { staticClass: "divider mb-10" }),
              _vm._m(0),
              _c("div", { staticClass: "top-box mt-15" }, [
                _c("div", { staticClass: "CrawlTime fr" }, [
                  _vm._v(
                    " 最新更新時間： " +
                      _vm._s(
                        _vm.$utils.formatTime(
                          _vm.postkpiData.post_data.last_update,
                          "YYYY-MM-DD HH:mm"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "dataCardWrapper clearfix" }, [
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("按讚數")]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencyFormat")(_vm.postkpiData.like_count)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("觀看數")]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencyFormat")(_vm.postkpiData.view_count)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("留言數")]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencyFormat")(_vm.postkpiData.comment_count)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("互動數")]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "按讚數+留言數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencyFormat")(
                          _vm.postkpiData.avg_post_interactions.toFixed(0)
                        )
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("互動率")]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "(按讚數+留言數)/影片數/粉絲數*100%",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseFloatNoRounding(
                            _vm.postkpiData.avg_post_probability
                          )
                        ) +
                        "% "
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "nav-tabs-custom boxShadow-none clearfix overflowX-auto",
                },
                [
                  _c("div", { staticClass: "tab-content notabStyle" }, [
                    _c(
                      "div",
                      { staticClass: "tab-pane active" },
                      [
                        _c("highcharts", {
                          attrs: { options: _vm.chartOptions },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box with-border" }, [
      _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
        _vm._v("數據分析"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }