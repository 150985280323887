var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("精彩賽事")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("精彩賽事")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_vm._v(" 新增網址 ")]
            ),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.highlights.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered",
                attrs: { id: "NmjWebHighlight" },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.datas.length <= 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("沒有資料")]
                          ),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "192px",
                                    height: "108px",
                                    margin: "auto",
                                    overflow: "hidden",
                                    background: "#ddd",
                                  },
                                },
                                [
                                  _c("iframe", {
                                    staticStyle: {
                                      transform: "scale(.5)",
                                      "transform-origin": "top left",
                                    },
                                    attrs: {
                                      src: _vm.checkEmbedLink(item.網址URL)
                                        ? item.網址URL
                                        : false,
                                      frameborder: "0",
                                      width: "384",
                                      height: "216",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", { staticClass: "question" }, [
                                    _vm._v(_vm._s(item.名稱)),
                                  ])
                                : _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "名稱" } }, [
                                      _vm._v("名稱："),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.lazy",
                                          value: item.名稱,
                                          expression: "item.名稱",
                                          modifiers: { lazy: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "名稱",
                                        type: "text",
                                        required: "",
                                      },
                                      domProps: { value: item.名稱 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            item,
                                            "名稱",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                              !item.isEdit
                                ? _c("p", { staticClass: "answer" }, [
                                    _vm._v(_vm._s(item.網址URL)),
                                  ])
                                : _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "youtube網址" } },
                                      [_vm._v("youtube網址：")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.網址URL,
                                          expression: "item.網址URL",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "youtube網址",
                                        type: "text",
                                        required: "",
                                        placeholder:
                                          "EX: https://www.youtube.com/embed/影片ID",
                                      },
                                      domProps: { value: item.網址URL },
                                      on: {
                                        change: function ($event) {
                                          item.網址URL =
                                            _vm.sanitizeYoutubeLink(
                                              $event.currentTarget.value
                                            )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "網址URL",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.網址URL &&
                                              !_vm.checkEmbedLink(item.網址URL),
                                            expression:
                                              "item.網址URL && !checkEmbedLink(item.網址URL)",
                                          },
                                        ],
                                        staticClass: "text-red",
                                      },
                                      [
                                        _vm._v(
                                          "連結不像YouTube影片，請確認是否符合規則："
                                        ),
                                        _c(
                                          "i",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "https://www.youtube.com/embed/影片ID"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              !_vm.isLoading || !item.isEdit
                                ? _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("toggle-button", {
                                        staticStyle: {
                                          "font-weight": "normal !important",
                                          transform: "scale(1.333)",
                                        },
                                        attrs: {
                                          value: !!item.是否啟用,
                                          labels: {
                                            checked: "啟用",
                                            unchecked: "關閉",
                                          },
                                        },
                                        on: {
                                          change: function ($event) {
                                            item.是否啟用 = +$event.value
                                            if (!_vm.isEditing)
                                              _vm.saveItem(item, index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "form-group" }, [
                                item.isEdit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveItem(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 儲存 ")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          disabled:
                                            _vm.isEditing && !item.isEdit,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 編輯 ")]
                                    ),
                                _vm._v("   "),
                                item.isEdit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-default",
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelItem(item, index)
                                          },
                                        },
                                      },
                                      [_vm._v(" 取消 ")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: {
                                          disabled:
                                            _vm.isEditing && !item.isEdit,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 刪除 ")]
                                    ),
                              ]),
                            ]),
                          ])
                        }),
                    _vm._m(1),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.highlights.total > 0,
                expression: "highlights.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.highlights.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "210px", width: "10%" } }, [
        _vm._v("預覽"),
      ]),
      _c("th", { staticStyle: { "min-width": "340px", width: "40%" } }, [
        _vm._v("youtube網址"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
        _vm._v("啟用狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "140px", width: "5%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _c("p", [
          _vm._v("YouTube連結參數可參考官方 "),
          _c(
            "a",
            {
              attrs: {
                href: "https://developers.google.com/youtube/player_parameters?hl=zh-tw#Parameters",
                target: "_blank",
                rel: "noopener noreferrer nofollow",
              },
            },
            [_vm._v("支援的參數文件")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }