var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("企業沿革編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("企業沿革列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("企業沿革編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group onlym" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              ref: "startTime",
                              staticClass: "start",
                              attrs: { propValue: _vm.startTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group onlym" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              ref: "endTime",
                              staticClass: "end",
                              attrs: { propValue: _vm.endTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.detailTopTitle,
                          expression: "edit.detailTopTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.detailTopTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "detailTopTitle",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.detailTopInfo,
                          expression: "edit.detailTopInfo",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputinfo",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.detailTopInfo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "detailTopInfo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "AdPic" },
                    },
                    [_vm._v(" 圖片： ")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pic",
                        staticStyle: {
                          "background-color": "#d0d0d0",
                          "max-width": "33%",
                          position: "relative",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.edit.contentUrl,
                          },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.edit.contentUrl.length > 0,
                                expression: "edit.contentUrl.length > 0 ",
                              },
                            ],
                            staticStyle: {
                              "padding-right": "0",
                              position: "absolute",
                              right: "-32px",
                              top: "0",
                              border: "1px solid black",
                              "border-radius": "50%",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center",
                              cursor: "pointer",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "img-close fa fa-fw fa-close",
                              staticStyle: { top: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFile(_vm.edit.contentUrl)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("input", {
                      attrs: { accept: "image/*", id: "AdPic", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _vm._m(6),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(8),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [_c("DatePicker", { staticClass: "publicdate" })],
                          1
                        ),
                      ]),
                      _vm._m(9),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 開始日期： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 結束日期： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 名稱："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputinfo" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容："),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("圖片建議300px * 160px"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }