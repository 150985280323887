var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "QppAnnouncementList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("公告列表")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("公告管理")]),
          _c("li", { staticClass: "active" }, [_vm._v("公告列表")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增公告 ")]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newsType,
                        expression: "newsType",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.newsType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeNewsType,
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "官方" } }, [
                      _vm._v("官方"),
                    ]),
                    _c("option", { attrs: { value: "個人" } }, [
                      _vm._v("個人"),
                    ]),
                    _c("option", { attrs: { value: "回覆" } }, [
                      _vm._v("回覆"),
                    ]),
                    _c("option", { attrs: { value: "留言" } }, [
                      _vm._v("留言"),
                    ]),
                  ]
                ),
              ]),
              _vm.newsType == "官方"
                ? _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.categoryNo,
                            expression: "searchData.categoryNo",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "required" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "categoryNo",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.clickSearch,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "0" } }, [
                          _vm._v("請選擇語系"),
                        ]),
                        _vm._l(_vm.categoryquery, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.categoryNo },
                            },
                            [_vm._v(_vm._s(item.language))]
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "select-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.targetMobile,
                      expression: "searchData.targetMobile",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "table_search",
                    placeholder: "門號搜尋",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.targetMobile },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "targetMobile",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.articleTitle,
                      expression: "searchData.articleTitle",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋關鍵字",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.articleTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "articleTitle",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.typeTotalCount)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _vm.newsType == "官方" || _vm.newsType == "個人"
                ? _c(
                    "table",
                    {
                      staticClass:
                        "table table-hover table-bordered AdListTable",
                    },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", [_vm._v("ID")]),
                            _vm.newsType == "官方"
                              ? _c("th", [_vm._v("語系")])
                              : _vm._e(),
                            _vm.newsType == "官方"
                              ? _c("th", [_vm._v("置頂")])
                              : _vm._e(),
                            _vm.newsType == "官方"
                              ? _c(
                                  "th",
                                  { staticStyle: { "min-width": "150px" } },
                                  [_vm._v("置頂排序")]
                                )
                              : _vm._e(),
                            _c("th", [_vm._v("熱門")]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("熱門排序")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("標題")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("發佈日期")]
                            ),
                            _c("th", [_vm._v("發佈者")]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("功能")]
                            ),
                          ]),
                          _vm.news.totalCount > 0
                            ? _vm._l(_vm.news.articleList, function (item) {
                                return _c("tr", { key: item.id }, [
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.articleId)),
                                  ]),
                                  _vm.newsType == "官方"
                                    ? _c("td", { staticClass: "text-nowrap" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCategory(item.categoryNo)
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.newsType == "官方"
                                    ? _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-nowrap text-center",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.officialHotTop > 0
                                                  ? "是"
                                                  : "否"
                                              ) +
                                              " "
                                          ),
                                          item.officialHotTop > 0
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-danger btn-add btn-upDate",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteOfficialTop(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.newsType == "官方"
                                    ? _c("td", { staticClass: "text-nowrap" }, [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.officialHotTop,
                                                  expression:
                                                    "item.officialHotTop",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                max: "5",
                                                min: "1",
                                                type: "number",
                                              },
                                              domProps: {
                                                value: item.officialHotTop,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "officialHotTop",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-warning btn-add btn-upDate",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setOfficialTop(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("更新")]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.hotTop > 0 ? "是" : "否"
                                          ) +
                                          " "
                                      ),
                                      item.hotTop > 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-danger btn-add btn-upDate",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteHopTop(item)
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _c("div", { staticClass: "input-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.hotTop,
                                            expression: "item.hotTop",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          max: "5",
                                          min: "1",
                                          type: "number",
                                        },
                                        domProps: { value: item.hotTop },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "hotTop",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-warning btn-add btn-upDate",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setHotTop(item)
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                    ]),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.articleTitle))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.FormatTime(item.publishTime))
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.authorHide)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm.newsType == "官方"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editorItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v("詳細")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.showContent(
                                                  item.articleId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("詳細")]
                                        ),
                                    _vm._v("   "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteNews(item)
                                          },
                                        },
                                      },
                                      [_vm._v("刪除")]
                                    ),
                                  ]),
                                ])
                              })
                            : [_vm._m(0)],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.newsType == "回覆"
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(1),
                          _vm.comment.totalCount > 0
                            ? _vm._l(
                                _vm.comment.commentDetailList,
                                function (item) {
                                  return _c("tr", { key: item.commentId }, [
                                    _c("td", { staticClass: "text-nowrap" }, [
                                      _vm._v(_vm._s(item.commentId)),
                                    ]),
                                    _c("td", [
                                      item.commentContent == ""
                                        ? _c(
                                            "span",
                                            { staticClass: "text-red" },
                                            [_vm._v("＊此篇文章內容已刪除")]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.onlyText(
                                                  item.commentContent
                                                )
                                              )
                                            ),
                                          ]),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.FormatTime(item.commentTime))
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-nowrap" }, [
                                      _vm._v(_vm._s(item.commentAuthor)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      item.commentContent != ""
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showContent(
                                                      item.commentId
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("詳細")]
                                            ),
                                            _vm._v("   "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteComment(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("刪除")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ])
                                }
                              )
                            : [_vm._m(2)],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.newsType == "留言"
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(3),
                          _vm.reply.totalCount > 0
                            ? _vm._l(
                                _vm.reply.replyDetailList,
                                function (item) {
                                  return _c("tr", { key: item.replyId }, [
                                    _c("td", { staticClass: "text-nowrap" }, [
                                      _vm._v(_vm._s(item.replyId)),
                                    ]),
                                    _c("td", [
                                      item.replyContent == ""
                                        ? _c(
                                            "span",
                                            { staticClass: "text-red" },
                                            [_vm._v("＊此篇文章內容已刪除")]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.onlyText(item.replyContent)
                                              )
                                            ),
                                          ]),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.FormatTime(item.replyTime))
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-nowrap" }, [
                                      _vm._v(_vm._s(item.author)),
                                    ]),
                                    _c("td", { staticClass: "text-center" }, [
                                      item.replyContent != ""
                                        ? _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showContent(
                                                      "R" + item.replyId
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("詳細")]
                                            ),
                                            _vm._v("   "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-danger",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteReply(item)
                                                  },
                                                },
                                              },
                                              [_vm._v("刪除")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ])
                                }
                              )
                            : [_vm._m(4)],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.news.totalCount > 0 &&
                    (_vm.newsType == "官方" || _vm.newsType == "個人"),
                  expression:
                    "news.totalCount > 0 && (newsType == '官方' || newsType == '個人')",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.news.totalCount,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.comment.totalCount > 0 && _vm.newsType == "回覆",
                  expression: "comment.totalCount > 0 && newsType == '回覆'",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.comment.totalCount,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.reply.totalCount > 0 && _vm.newsType == "留言",
                  expression: "reply.totalCount > 0 && newsType == '留言'",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.reply.totalCount,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("forumContent", {
        ref: "forumContent",
        attrs: { propsCategoryNo: _vm.contentCategoryNo, propsID: _vm.itemID },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("ID")]),
      _c("th", { staticStyle: { "min-width": "200px" } }, [_vm._v("內容")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("發佈日期")]),
      _c("th", [_vm._v("發佈者")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("ID")]),
      _c("th", { staticStyle: { "min-width": "200px" } }, [_vm._v("內容")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("發佈日期")]),
      _c("th", [_vm._v("發佈者")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }