var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ForumMuteSetting" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _c("span", [_vm._v(" " + _vm._s(_vm.edit.專案))]),
        _vm._v(" - 貼文設定/關鍵字列表"),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FBRobot/RobotList" } }, [
              _vm._v("FB機器人"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("貼文設定/關鍵字列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content forum-mute" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("貼文設定"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "form-horizontal",
              attrs: { name: "postSetForm", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "80%" },
                },
                [
                  _c("label", { staticClass: "sr-only" }, [
                    _vm._v(" 編號: " + _vm._s(_vm.edit.代號) + " "),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "target_post" },
                      },
                      [_vm._v(" 選擇貼文： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.貼文ID,
                              expression: "edit.貼文ID",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "target_post" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "貼文ID",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.postChosen()
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇要設定機器人的貼文")]
                          ),
                          _vm._l(_vm.fbList, function (item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.貼文ID } },
                              [
                                _vm._v(
                                  " (" +
                                    _vm._s(item.貼文ID) +
                                    ") " +
                                    _vm._s(item.貼文註解) +
                                    " - " +
                                    _vm._s(
                                      _vm.$utils.formatTime(
                                        _vm.edit.建立時間,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm.edit.貼文ID
                        ? _c(
                            "a",
                            {
                              staticClass: "fbPostCard",
                              attrs: {
                                href: _vm.edit.貼文連結,
                                target: "_blank",
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _c("p", {
                                  staticClass: "intro",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.edit.內容),
                                  },
                                }),
                                _c("p", { staticClass: "date" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$utils.formatTime(
                                          _vm.edit.建立時間,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "pic jqImgFill" }, [
                                _c("img", {
                                  attrs: { src: _vm.edit.圖片連結 },
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "postBriefly" },
                      },
                      [_vm._v("貼文註解： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.貼文註解,
                            expression: "edit.貼文註解",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "postBriefly",
                          required: "required",
                          type: "text",
                          maxlength: _vm.brieflyMax,
                          placeholder: `請輸入貼文註解，上限${_vm.brieflyMax}字`,
                        },
                        domProps: { value: _vm.edit.貼文註解 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "貼文註解", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "startTime" },
                      },
                      [_vm._v(" 回覆啟用時間： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(0),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.啟用時間 },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.啟用時間 = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.啟用時間 = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(1),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "endTime" },
                      },
                      [_vm._v(" 回覆結束時間： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(2),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.結束時間 },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.結束時間 = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.結束時間 = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(3),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.狀態,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "狀態", $$v)
                              },
                              expression: "edit.狀態",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.狀態,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "狀態", $$v)
                              },
                              expression: "edit.狀態",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _vm._m(6),
                      _vm.edit.代號 == 0
                        ? _c("div", { staticClass: "help-block" }, [
                            _c("span", { staticClass: "text-red mt-10" }, [
                              _vm._v(
                                ' *要設定關鍵字請先"確認送出"，再新增關鍵字'
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c("hr", { staticClass: "divider mb-10" }),
          _vm.isEdit
            ? _c("div", { staticClass: "top-box with-border" }, [
                _c("div", { staticClass: "w100 clearfix" }, [
                  _c(
                    "h4",
                    { staticClass: "pull-left", attrs: { id: "title" } },
                    [_vm._v("關鍵字列表")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add fr",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addKeyword()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" 新增關鍵字 "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "div",
                { staticClass: "table-responsive mt-10 m-auto2 mb-30" },
                [
                  _c(
                    "table",
                    { staticClass: "table table-bordered min1000 AdListTable" },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(7),
                          _vm.keywordData.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "9" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.keywordData, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(index + 1) + " "),
                                    _c("br"),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("代號:" + _vm._s(item.代號)),
                                    ]),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._f("isNull")(item.關鍵字)) +
                                        " "
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(_vm.getNeed(item.符合度)) + " "
                                    ),
                                  ]),
                                  _c("td", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.isAnyContent(
                                          item.留言回覆訊息
                                        )
                                      ),
                                    },
                                  }),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        width: "80px",
                                        "min-width": "80px",
                                        "max-width": "80",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.留言圖片
                                            ? item.留言圖片
                                            : require("@/assets/no-image2.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("td", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.isAnyContent(
                                          item.私訊回覆訊息
                                        )
                                      ),
                                    },
                                  }),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        width: "100px",
                                        "min-width": "80px",
                                        "max-width": "80",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.私訊圖片
                                            ? item.私訊圖片
                                            : require("@/assets/no-image2.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(item.留言數)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(item.私訊數)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.getAuto(item.自動按讚))),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "td-sort text-nowrap" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.排序,
                                            expression: "item.排序",
                                          },
                                        ],
                                        attrs: { type: "number" },
                                        domProps: { value: item.排序 },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "排序",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-warning btn-add btn-upDate",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateGameOd(item)
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editorKeyword(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 修改")]
                                    ),
                                    _c("br"),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        on: {
                                          click: function ($event) {
                                            return _vm.copy(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 複製")]
                                    ),
                                    _c("br"),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 刪除")]
                                    ),
                                  ]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("說明")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red mt-10" }, [
        _vm._v(' *"確認送出"必選擇貼文、填寫貼文註解、啟用時間、停用時間'),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-center" }, [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("關鍵字")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("符合度")]),
      _c(
        "th",
        {
          staticStyle: { "min-width": "200px", width: "20%" },
          attrs: { colspan: "2" },
        },
        [_vm._v("回覆留言")]
      ),
      _c(
        "th",
        {
          staticStyle: { "min-width": "200px", width: "20%" },
          attrs: { colspan: "2" },
        },
        [_vm._v("回覆私訊")]
      ),
      _c("th", { staticStyle: { "min-width": "80px" } }, [
        _vm._v("留言回覆數"),
      ]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [
        _vm._v("私訊回覆數"),
      ]),
      _c("th", { staticStyle: { width: "80px" } }, [_vm._v("自動按讚")]),
      _c("th", { staticStyle: { width: "80px" } }, [_vm._v("排序")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }