var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增-修改廠商品項")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "GoodsManagerList",
                    query: this.$route.query,
                  })
                },
              },
            },
            [_vm._v("品項管理")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增-修改廠商品項 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.廠商設定主檔編號,
                            expression: "edit.廠商設定主檔編號",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        attrs: { disabled: _vm.isEdit },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "廠商設定主檔編號",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              _vm.change廠商設定主檔編號([])
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.廠商設定List, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.名稱) +
                                "-" +
                                _vm._s(item.顯示名稱) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "form-group",
                    staticStyle: { "margin-top": "-15px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-4 control-label" }),
                    _vm._m(1),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-4 control-label" }, [
                    _vm._v(" 平台遊戲編號： "),
                  ]),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.平台遊戲編號,
                            expression: "edit.平台遊戲編號",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        attrs: { disabled: _vm.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "平台遊戲編號",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("不設定"),
                        ]),
                        _vm._l(_vm.廠商設定List, function (item) {
                          return _c(
                            "option",
                            {
                              key: item.廠商編號,
                              domProps: { value: item.廠商編號 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.顯示名稱) +
                                  " " +
                                  _vm._s(item.廠商編號) +
                                  " "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "form-group",
                    staticStyle: { "margin-top": "-15px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-4 control-label" }),
                    _vm._m(2),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.商品模式,
                            expression: "edit.商品模式",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        attrs: { disabled: _vm.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "商品模式",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.商品模式, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [_vm._v(" " + _vm._s(item.顯示名稱) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "form-group",
                    staticStyle: { "margin-top": "-15px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-4 control-label" }),
                    _vm._m(4),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.第三方物品類別,
                            expression: "edit.第三方物品類別",
                          },
                        ],
                        staticClass: "selPaymentType form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "第三方物品類別",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.第三方物品類別List, function (item) {
                        return _c(
                          "option",
                          {
                            key: item,
                            attrs: {
                              disabled:
                                _vm.orgEdit.第三方物品類別 != "" &&
                                item == "預設",
                            },
                            domProps: { value: item },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._m(6),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "col-sm-4 control-label dropright" },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(" 品項名稱 "),
                      _vm._m(7),
                      _vm._v(" ： "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu common" },
                        [
                          _vm._v(" 顯示在「付款資訊頁-付款品項」"),
                          _c("br"),
                          _c("hr"),
                          _vm._v(" 官網帶入點數=<point> "),
                          _c("br"),
                          _vm._v(" 官網帶入價格=<price> "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" 範例： "),
                          _c("br"),
                          _vm._v(" 付款品項顯示：yoe數位卡"),
                          _c("font", { staticClass: "text-blue" }, [
                            _vm._v("50"),
                          ]),
                          _vm._v("點(單價$"),
                          _c("font", { staticClass: "text-red" }, [
                            _vm._v("50"),
                          ]),
                          _vm._v(" *1) "),
                          _c("br"),
                          _vm._v(" 設定需設為：yoe數位卡"),
                          _c("font", { staticClass: "text-blue" }, [
                            _vm._v("<point>"),
                          ]),
                          _vm._v("點(單價$"),
                          _c("font", { staticClass: "text-red" }, [
                            _vm._v(" <price>"),
                          ]),
                          _vm._v("*1) "),
                          _c("br"),
                          _c("img", {
                            attrs: { src: "/download/IPS_illustrate01.png" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.品項名稱,
                          expression: "edit.品項名稱",
                          modifiers: { trim: true },
                        },
                        {
                          name: "AddArgInput",
                          rawName: "v-AddArgInput",
                          value: {
                            model: "edit.品項名稱",
                            format: _vm.AddArgFormat,
                          },
                          expression:
                            "{ model: 'edit.品項名稱', format: AddArgFormat }",
                        },
                      ],
                      ref: "品項名稱Input",
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.品項名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "品項名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("span", { staticClass: "input-group-btn widthAuto" }, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "AddArgBtn",
                              rawName: "v-AddArgBtn",
                              value: {
                                insertText: "<point>",
                                ref: "品項名稱Input",
                                model: "edit.品項名稱",
                              },
                              expression:
                                "{ insertText: '<point>', ref: '品項名稱Input', model: 'edit.品項名稱' }",
                            },
                          ],
                          staticClass: "btn btn-info btn-flat",
                          attrs: { type: "button" },
                        },
                        [_vm._v("添加<point>")]
                      ),
                    ]),
                    _c("span", { staticClass: "input-group-btn widthAuto" }, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "AddArgBtn",
                              rawName: "v-AddArgBtn",
                              value: {
                                insertText: "<price>",
                                ref: "品項名稱Input",
                                model: "edit.品項名稱",
                              },
                              expression:
                                "{ insertText: '<price>', ref: '品項名稱Input', model: 'edit.品項名稱' }",
                            },
                          ],
                          staticClass: "btn btn-info btn-flat",
                          attrs: { type: "button" },
                        },
                        [_vm._v("添加<price>")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.erP名稱,
                          expression: "edit.erP名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.erP名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "erP名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.商品代碼,
                          expression: "edit.商品代碼",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: _vm.isEdit },
                      domProps: { value: _vm.edit.商品代碼 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "商品代碼",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "form-group",
                    staticStyle: { "margin-top": "-15px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-4 control-label" }),
                    _vm._m(10),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-4 control-label" }, [
                    _vm._v(" 第三方物品編號： "),
                  ]),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.第三方物品編號,
                          expression: "edit.第三方物品編號",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.第三方物品編號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "第三方物品編號",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-4 control-label" }, [
                    _vm._v(" 商品備註： "),
                  ]),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.edit.商品備註,
                          expression: "edit.商品備註",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.edit.商品備註 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "商品備註",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(11),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim.number",
                          value: _vm.edit.金額,
                          expression: "edit.金額",
                          modifiers: { trim: true, number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", disabled: _vm.isEdit },
                      domProps: { value: _vm.edit.金額 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "金額",
                            _vm._n($event.target.value.trim())
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isEdit,
                        expression: "!isEdit",
                      },
                    ],
                    staticClass: "form-group",
                    staticStyle: { "margin-top": "-15px" },
                  },
                  [
                    _c("label", { staticClass: "col-sm-4 control-label" }),
                    _vm._m(12),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(13),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.edit.販賣數量,
                            expression: "edit.販賣數量",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.edit.販賣數量 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "販賣數量",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "input-group-addon text-red" },
                        [_vm._v("輸入-1=沒有設定限制")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(14),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c("DateTimePicker", {
                        attrs: { propDateTime: _vm.edit.販賣開始時間 },
                        on: {
                          "update:propDateTime": function ($event) {
                            return _vm.$set(_vm.edit, "販賣開始時間", $event)
                          },
                          "update:prop-date-time": function ($event) {
                            return _vm.$set(_vm.edit, "販賣開始時間", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._m(15),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(16),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c("DateTimePicker", {
                        attrs: { propDateTime: _vm.edit.販賣結束時間 },
                        on: {
                          "update:propDateTime": function ($event) {
                            return _vm.$set(_vm.edit, "販賣結束時間", $event)
                          },
                          "update:prop-date-time": function ($event) {
                            return _vm.$set(_vm.edit, "販賣結束時間", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._l(_vm.edit.支付品號列表, function (item) {
                  return _c(
                    "div",
                    { key: item.編號, staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "col-sm-4 control-label" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn dropdown-toggle btn-info",
                            class:
                              item.上層設定.啟用 &&
                              _vm.金流設定主檔Key[
                                item.上層設定.金流設定主檔編號
                              ].啟用 &&
                              _vm.金流帳號Key[item.上層設定.金流帳號編號]
                                .啟用 &&
                              _vm.金流支付設定Key[
                                item.上層設定.金流支付設定編號
                              ].啟用
                                ? "btn-info"
                                : "btn-danger",
                            attrs: {
                              type: "button",
                              "data-toggle": "dropdown",
                              "aria-expanded": "true",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.金流設定主檔Key[
                                      item.上層設定.金流設定主檔編號
                                    ].金流名稱
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.金流設定主檔Key[
                                      item.上層設定.金流設定主檔編號
                                    ].金流代碼
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c("span", [_vm._v("：")]),
                        _c(
                          "div",
                          { staticClass: "dropdown-menu" },
                          [
                            _c("PayInfo", {
                              ref: "upSetting",
                              refInFor: true,
                              attrs: {
                                上層設定: item.上層設定,
                                金流設定主檔Key: _vm.金流設定主檔Key,
                                金流帳號Key: _vm.金流帳號Key,
                                金流支付設定Key: _vm.金流支付設定Key,
                                地區列表Key: _vm.地區列表Key,
                                金流類型設定Key: _vm.金流類型設定Key,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          {
                            staticClass: "input-group margin",
                            staticStyle: { margin: "0px" },
                          },
                          [
                            _c("span", { staticClass: "input-group-addon" }, [
                              _vm._v(" 是否使用 "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.是否使用,
                                    expression: "item.是否使用",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(item.是否使用)
                                    ? _vm._i(item.是否使用, null) > -1
                                    : item.是否使用,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = item.是否使用,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "是否使用",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "是否使用",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "是否使用", $$c)
                                    }
                                  },
                                },
                              }),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.支付品號,
                                  expression: "item.支付品號",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.支付品號placeholder(
                                  item.上層設定.支付品號,
                                  _vm.金流支付設定Key[
                                    item.上層設定.金流支付設定編號
                                  ].支付品號
                                ),
                              },
                              domProps: { value: item.支付品號 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    item,
                                    "支付品號",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "input-group-btn" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-flat",
                                  staticStyle: {
                                    width: "200px",
                                    "text-align": "left",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      item.支付品號 =
                                        _vm.金流支付設定Key[
                                          item.上層設定.金流支付設定編號
                                        ].支付品號
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "品號(金流層)：" +
                                      _vm._s(
                                        _vm.金流支付設定Key[
                                          item.上層設定.金流支付設定編號
                                        ].支付品號
                                      )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(17),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "否",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.啟用,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "啟用", $$v)
                            },
                            expression: "edit.啟用",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "是",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.啟用,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "啟用", $$v)
                            },
                            expression: "edit.啟用",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("新增後無法修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("新增後無法修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 商品模式： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("新增後無法修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v("第三方物品類別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "form-group", staticStyle: { "margin-top": "-15px" } },
      [
        _c("label", { staticClass: "col-sm-4 control-label" }),
        _c("div", { staticClass: "col-sm-8" }, [
          _vm._v(" 一但設成預設以外的值後，無法改回"),
          _c("span", { staticClass: "text-red" }, [_vm._v("預設")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "true",
        },
      },
      [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" ERP名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 商品代碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("新增後無法修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金額： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("新增後無法修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 販賣數量： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 販售時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "col-sm-4 control-label" }, [
        _c("span", { staticClass: "text-red" }),
      ]),
      _c("div", { staticClass: "col-sm-8" }, [_vm._v(" ~ ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }