var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "PopForm",
      staticClass: "mymodal",
      on: {
        click: function ($event) {
          return _vm.clickBg()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modalWidth",
          staticStyle: {
            "margin-top": "30px",
            "margin-left": "auto",
            "margin-right": "auto",
          },
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "box-header with-border" }, [
              _c(
                "h3",
                { staticClass: "box-title" },
                [
                  _vm._t("slot-title", function () {
                    return [_vm._v(" TodoTitle ")]
                  }),
                ],
                2
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.hide()
                    },
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _c("div", { ref: "scrollArea", staticClass: "scroll-area" }, [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _vm._t("slot-body", function () {
                    return [_vm._v(" TodoBody ")]
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "box-footer" },
                [
                  _vm._t("slot-footer", function () {
                    return [_vm._v(" TodoFooter ")]
                  }),
                ],
                2
              ),
            ]),
            _c("div", { ref: "loading", staticClass: "overlay" }, [
              _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }