var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("videoAlert", {
          ref: "videoAlert",
          on: { submit: _vm.getvideoQueryAll },
        }),
        _c("channelAlert", { ref: "channelAlert" }),
        _c("div", { attrs: { id: "CustomerService" } }, [
          _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
            _c("ul", { staticClass: "nav nav-tabs" }, [
              _c(
                "li",
                { class: { active: _vm.searchDataVideo.module === "例行" } },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        "aria-expanded": "true",
                        "data-toggle": "tab",
                        href: "#usual",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("例行", "")
                        },
                      },
                    },
                    [_vm._v(" 例行檢索 ")]
                  ),
                ]
              ),
              _c(
                "li",
                { class: { active: _vm.searchDataVideo.module === "追蹤中" } },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        "aria-expanded": "false",
                        "data-toggle": "tab",
                        href: "#watch",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("追蹤中", "")
                        },
                      },
                    },
                    [_vm._v(" 追蹤中影片 ")]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  class: { active: _vm.searchDataVideo.module === "例行" },
                },
                [
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "box-body",
                        staticStyle: { padding: "0", border: "0" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "top-box",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("DaterangepickerForMessage", {
                              ref: "datePicker",
                              on: {
                                ApplyDaterangepicker: _vm.applyDaterangepicker,
                              },
                            }),
                            _c("div", { staticClass: "select-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchDataVideo.巡邏人,
                                      expression: "searchDataVideo.巡邏人",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "selectUnit" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.searchDataVideo,
                                          "巡邏人",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.clickSearch,
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "", select: "" } },
                                    [_vm._v("全部巡邏人")]
                                  ),
                                  _vm._l(
                                    _vm.channelMan.result,
                                    function (item, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: item.人員名稱 },
                                        },
                                        [_vm._v(" " + _vm._s(item.人員名稱))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _c("div", { staticClass: "select-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchDataVideo.狀態,
                                      expression: "searchDataVideo.狀態",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "selectUnit",
                                    required: "required",
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.searchDataVideo,
                                          "狀態",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.clickSearch,
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { key: "1", attrs: { value: "-1" } },
                                    [_vm._v("全部狀態")]
                                  ),
                                  _c(
                                    "option",
                                    { key: "2", attrs: { value: "0" } },
                                    [_vm._v("未處理")]
                                  ),
                                  _c(
                                    "option",
                                    { key: "3", attrs: { value: "1" } },
                                    [_vm._v("不處理")]
                                  ),
                                  _c(
                                    "option",
                                    { key: "4", attrs: { value: "2" } },
                                    [_vm._v("待追蹤")]
                                  ),
                                  _c(
                                    "option",
                                    { key: "5", attrs: { value: "3" } },
                                    [_vm._v("已結案")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchDataVideo.搜尋關鍵字,
                                    expression: "searchDataVideo.搜尋關鍵字",
                                  },
                                ],
                                staticClass: "form-control pull-right",
                                attrs: {
                                  name: "table_search",
                                  placeholder: "搜尋關鍵字",
                                  type: "text",
                                },
                                domProps: {
                                  value: _vm.searchDataVideo.搜尋關鍵字,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.searchDataVideo,
                                      "搜尋關鍵字",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("div", { staticClass: "input-group-btn" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSearch()
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-search" })]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _c("p", { staticClass: "data-num" }, [
                                (_vm.searchShow = 1)
                                  ? _c("span", [_vm._v("資料總筆數：")])
                                  : _vm._e(),
                                _vm.searchShow != 1
                                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                                  : _vm._e(),
                                _c("span", { attrs: { id: "total" } }, [
                                  _vm._v(_vm._s(_vm.edit.total)),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "final",
                                staticStyle: {
                                  position: "absolute",
                                  right: "0px",
                                  top: "6px",
                                  color: "#ff4f4f",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _vm._v(
                                  "最後檢索時間: " +
                                    _vm._s(
                                      _vm.FormatTime(_vm.edit.最後檢索時間)
                                    )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "table-responsive mt-10" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-hover table-bordered min-800",
                            },
                            [
                              _c(
                                "tbody",
                                [
                                  _vm._m(1),
                                  _vm.edit.total > 0
                                    ? _vm._l(
                                        _vm.edit.datas,
                                        function (item, index) {
                                          return _c(
                                            "tr",
                                            { key: "editDatas" + index },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  item.頻道管理編號 != null
                                                    ? _c("span", {
                                                        staticClass:
                                                          "linkStyle",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.頻道名稱
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openChannel(
                                                              item.頻道管理編號
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.頻道名稱
                                                          ),
                                                        },
                                                      }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        "http://img.youtube.com/vi/" +
                                                        item.videoId +
                                                        "/hqdefault.jpg",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass: "linkStyle",
                                                    attrs: {
                                                      href:
                                                        `https://www.youtube.com/watch?v=` +
                                                        item.videoId,
                                                      target: "_blank",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.影片標題
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "detailStyle",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.changeN(
                                                          item.影片簡介
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormatTime(
                                                        item.影片發布日期
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.巡邏人)),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  item.狀態 == 0
                                                    ? _c("span", [
                                                        _vm._v("未處理"),
                                                      ])
                                                    : _vm._e(),
                                                  item.狀態 == 1
                                                    ? _c("span", [
                                                        _vm._v("不處理"),
                                                      ])
                                                    : _vm._e(),
                                                  item.狀態 == 2
                                                    ? _c("span", [
                                                        _vm._v("待追蹤"),
                                                      ])
                                                    : _vm._e(),
                                                  item.狀態 == 3
                                                    ? _c("span", [
                                                        _vm._v("已結案"),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.備註)),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center text-nowrap",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openVideo(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("詳細")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : [_vm._m(2)],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.edit.total > 0,
                            expression: "edit.total > 0",
                          },
                        ],
                        staticClass: "box-footer text-center no-border",
                      },
                      [
                        _c("Pagination", {
                          ref: "pagination",
                          attrs: {
                            total: _vm.edit.total,
                            pageLimitCount: _vm.searchDataVideo.pageSize,
                          },
                          on: { pageMethod: _vm.onPageChange },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  class: { active: _vm.searchDataVideo.module === "追蹤中" },
                },
                [
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "box-body",
                        staticStyle: { padding: "0", border: "0" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "top-box",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchDataVideo.搜尋關鍵字,
                                    expression: "searchDataVideo.搜尋關鍵字",
                                  },
                                ],
                                staticClass: "form-control pull-right",
                                attrs: {
                                  name: "table_search",
                                  placeholder: "搜尋關鍵字",
                                  type: "text",
                                },
                                domProps: {
                                  value: _vm.searchDataVideo.搜尋關鍵字,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.searchDataVideo,
                                      "搜尋關鍵字",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("div", { staticClass: "input-group-btn" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickSearch()
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-search" })]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _c("p", { staticClass: "data-num" }, [
                                (_vm.searchShow = 1)
                                  ? _c("span", [_vm._v("資料總筆數：")])
                                  : _vm._e(),
                                _vm.searchShow != 1
                                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                                  : _vm._e(),
                                _c("span", { attrs: { id: "total" } }, [
                                  _vm._v(_vm._s(_vm.edit.total)),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "final",
                                staticStyle: {
                                  position: "absolute",
                                  right: "0px",
                                  top: "6px",
                                  color: "#ff4f4f",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _vm._v(
                                  "最後檢索時間: " +
                                    _vm._s(
                                      _vm.FormatTime(_vm.edit.最後檢索時間)
                                    )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "table-responsive mt-10" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-hover table-bordered min-800",
                            },
                            [
                              _c(
                                "tbody",
                                [
                                  _vm._m(3),
                                  _vm.edit.total > 0
                                    ? _vm._l(
                                        _vm.edit.datas,
                                        function (item, index) {
                                          return _c(
                                            "tr",
                                            { key: "editDatas" + index },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  item.頻道管理編號 != null
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "linkStyle",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openChannel(
                                                                item.頻道管理編號
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.頻道名稱
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.頻道名稱
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        "http://img.youtube.com/vi/" +
                                                        item.videoId +
                                                        "/hqdefault.jpg",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass: "linkStyle",
                                                    attrs: {
                                                      href:
                                                        `https://www.youtube.com/watch?v=` +
                                                        item.videoId,
                                                      target: "_blank",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.影片標題
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "detailStyle",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.changeN(
                                                          item.影片簡介
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.遊戲角色名稱)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormatTime(
                                                        item.影片發布日期
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.追蹤狀態)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.備註)),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center text-nowrap",
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openVideo(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("詳細")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : [_vm._m(4)],
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.edit.total > 0,
                            expression: "edit.total > 0",
                          },
                        ],
                        staticClass: "box-footer text-center no-border",
                      },
                      [
                        _c("Pagination", {
                          ref: "pagination",
                          attrs: {
                            total: _vm.edit.total,
                            pageLimitCount: _vm.searchDataVideo.pageSize,
                          },
                          on: { pageMethod: _vm.onPageChange },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticClass: "content-header",
        staticStyle: {
          display: "flex",
          "flex-wrap": "wrap",
          "align-items": "flex-end",
        },
      },
      [
        _c("h1", [_vm._v("影片追蹤")]),
        _c("span", { staticStyle: { "margin-left": "8px", color: "red" } }, [
          _vm._v("*包含:設定的頻道和關鍵字搜尋"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "min-width": "90px", width: "13%" },
        },
        [_vm._v("頻道名稱")]
      ),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("影片縮圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "25%" } }, [
        _vm._v("影片標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "20%" } }, [
        _vm._v("影片簡介"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("發布日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "6%" } }, [
        _vm._v("巡邏人"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "6%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("備註"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "min-width": "90px", width: "13%" },
        },
        [_vm._v("頻道名稱")]
      ),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("影片縮圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "25%" } }, [
        _vm._v("影片標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "22%" } }, [
        _vm._v("影片簡介"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "8%" } }, [
        _vm._v("遊戲ID"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("發布日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "8%" } }, [
        _vm._v("追蹤狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("備註"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }