var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "PlayerSettingList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("玩家控管設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("玩家控管設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.updateData()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.blockType,
                        expression: "searchData.blockType",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "blockType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("控管類型"),
                    ]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("停權")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("禁言")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.playUId,
                      expression: "searchData.playUId",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "玩家UID", type: "text" },
                  domProps: { value: _vm.searchData.playUId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "playUId",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered text-center min-1000" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.loading
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "9" } }, [
                              _vm._v("查詢中"),
                            ]),
                          ])
                        : _vm.result.message
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "9" } }, [
                              _vm._v(_vm._s(_vm.result.message)),
                            ]),
                          ])
                        : _vm.datas.length === 0
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "9" } }, [
                              _vm._v("查詢無任何資料"),
                            ]),
                          ])
                        : _vm._l(_vm.datas, function (item, index) {
                            return _c("tr", { key: index }, [
                              _c("td", { staticClass: "text-nowrap" }, [
                                _vm._v(_vm._s(item.playUId)),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.define.controlStatus[item.blockType]
                                  )
                                ),
                              ]),
                              item.timeType === 1
                                ? _c("td", [_vm._v("永久")])
                                : _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.blockEndTime,
                                            "YYYY/MM/DD HH:mm:ss"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateData(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 修改 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger ml-10",
                                    on: {
                                      click: function ($event) {
                                        return _vm.recoveryData(item, "3", 0)
                                      },
                                    },
                                  },
                                  [_vm._v(" 解除 ")]
                                ),
                              ]),
                            ])
                          }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("playerSettingContent", {
        ref: "playerSettingContent",
        on: {
          updateList: function ($event) {
            return _vm.getDataList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v("玩家UID")]
      ),
      _c("th", [_vm._v("控管類型")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v("控管期限")]
      ),
      _c("th", [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }