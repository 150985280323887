var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("白名單")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("白名單")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-header" }, [
            _c(
              "div",
              { staticClass: "input-group-for-multiselect-wrap" },
              [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("廠商："),
                ]),
                _c("multiselect", {
                  staticClass: "multiselect-width",
                  attrs: {
                    placeholder: "請選取廠商",
                    options: _vm.廠商Opts,
                    label: "名稱",
                    "track-by": "廠商代碼",
                    "show-labels": false,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.searchData.廠商,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchData, "廠商", $$v)
                    },
                    expression: "searchData.廠商",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "input-group" }, [
              _c("label", [
                _vm._v("類別： "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.型態,
                        expression: "searchData.型態",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { float: "none" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "型態",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.型態Enum, function (value, k) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [_vm._v(_vm._s(k))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _c("label", { staticClass: "input-group" }, [
              _c("span", { staticClass: "input-group-span" }, [
                _vm._v("識別值："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchData.識別值,
                    expression: "searchData.識別值",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "請輸入 識別值" },
                domProps: { value: _vm.searchData.識別值 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.searchData,
                      "識別值",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-search" }), _vm._v(" 查詢 ")]
              ),
            ]),
            _vm.editPermission
              ? _c("div", { staticClass: "input-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      on: {
                        click: function ($event) {
                          return _vm.openAddPopForm()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                  ),
                ])
              : _vm._e(),
            _vm.editPermission
              ? _c("div", { staticClass: "input-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: {
                        click: function ($event) {
                          return _vm.openBatchForm()
                        },
                      },
                    },
                    [_vm._v(" 批次匯入 ")]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _vm._m(0),
              _c(
                "tbody",
                [
                  _vm._l(_vm.list, function (item) {
                    return _c(
                      "tr",
                      {
                        key:
                          item.型態 + "-" + item.廠商編號 + "-" + item.識別值,
                      },
                      [
                        _c("td", [_vm._v(_vm._s(item.建立時間))]),
                        _c("td", [_vm._v(_vm._s(item.廠商))]),
                        _c("td", [_vm._v(_vm._s(item.型態))]),
                        _c("td", [_vm._v(_vm._s(item.識別值))]),
                        _c("td", [_vm._v(_vm._s(item.原因))]),
                        _c(
                          "td",
                          {
                            class: item.風控來源 == "MID" ? "text-yellow" : "",
                          },
                          [_vm._v(_vm._s(item.風控來源))]
                        ),
                        _c("td", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.驗證狀態 == "無使用",
                                  expression: "item.驗證狀態 == '無使用'",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(item.驗證狀態))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.驗證狀態 == "MID驗證",
                                  expression: "item.驗證狀態 == 'MID驗證'",
                                },
                              ],
                              staticClass: "text-light-blue",
                            },
                            [_vm._v(_vm._s(item.驗證狀態))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.驗證狀態 == "免驗證",
                                  expression: "item.驗證狀態 == '免驗證'",
                                },
                              ],
                              staticClass: "text-green",
                            },
                            [_vm._v(_vm._s(item.驗證狀態))]
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.備註))]),
                        _c(
                          "td",
                          [
                            item.風控來源 == "MID" && item.驗證狀態 == "MID驗證"
                              ? [
                                  _c("span", { staticClass: "text-red" }, [
                                    _vm._v("解除"),
                                  ]),
                                  _vm._v("請到OMP-MID實名認證，重置驗證"),
                                ]
                              : item.風控來源 == "MID" &&
                                item.驗證狀態 == "免驗證"
                              ? [
                                  _c("span", { staticClass: "text-red" }, [
                                    _vm._v("解除"),
                                  ]),
                                  _vm._v("請 廠商後台\\人員，移除免驗證"),
                                ]
                              : [
                                  _vm.delPermission
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.lift(item)
                                            },
                                          },
                                        },
                                        [_vm._v("解除 ")]
                                      )
                                    : _vm._e(),
                                ],
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.list.length == 0,
                          expression: "list.length == 0",
                        },
                      ],
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { colspan: "7" },
                        },
                        [_vm._v("無資料")]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("PopForm", {
        ref: "addForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 新增白名單 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("廠商："),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input-group-for-multiselect-wrap",
                    staticStyle: { "padding-right": "0" },
                  },
                  [
                    _c("multiselect", {
                      attrs: {
                        placeholder: "請選取廠商",
                        options: _vm.廠商List,
                        label: "名稱",
                        "track-by": "廠商代碼",
                        "show-labels": false,
                        "allow-empty": false,
                        maxHeight: 200,
                      },
                      model: {
                        value: _vm.edit.廠商,
                        callback: function ($$v) {
                          _vm.$set(_vm.edit, "廠商", $$v)
                        },
                        expression: "edit.廠商",
                      },
                    }),
                  ],
                  1
                ),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("類別： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.型態,
                          expression: "edit.型態",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "型態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.型態Enum, function (value, k) {
                      return _c(
                        "option",
                        { key: value, domProps: { value: value } },
                        [_vm._v(_vm._s(k))]
                      )
                    }),
                    0
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("識別值： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.識別值,
                        expression: "edit.識別值",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "請輸入 識別值" },
                    domProps: { value: _vm.edit.識別值 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "識別值", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("原因： "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.原因,
                        expression: "edit.原因",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "請輸入 原因" },
                    domProps: { value: _vm.edit.原因 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "原因", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("備註： "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.備註,
                        expression: "edit.備註",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "請輸入 備註" },
                    domProps: { value: _vm.edit.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "備註", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.addForm.hide()
                      },
                    },
                  },
                  [_vm._v("關閉")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.addEditData()
                      },
                    },
                  },
                  [_vm._v("新增")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("PopForm", {
        ref: "batchForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 批次匯入白名單 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("廠商："),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input-group-for-multiselect-wrap",
                    staticStyle: { "padding-right": "0" },
                  },
                  [
                    _c("multiselect", {
                      attrs: {
                        placeholder: "請選取廠商",
                        options: _vm.廠商List,
                        label: "名稱",
                        "track-by": "廠商代碼",
                        "show-labels": false,
                        "allow-empty": false,
                        maxHeight: 200,
                      },
                      model: {
                        value: _vm.batch.廠商,
                        callback: function ($$v) {
                          _vm.$set(_vm.batch, "廠商", $$v)
                        },
                        expression: "batch.廠商",
                      },
                    }),
                  ],
                  1
                ),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("類別： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.batch.型態,
                          expression: "batch.型態",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.batch,
                            "型態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.型態Enum, function (value, k) {
                      return _c("option", { key: k, domProps: { value: k } }, [
                        _vm._v(_vm._s(k)),
                      ])
                    }),
                    0
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("原因： "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.batch.原因,
                        expression: "batch.原因",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "請輸入 原因" },
                    domProps: { value: _vm.batch.原因 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.batch, "原因", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _vm._v("備註： "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.batch.備註,
                        expression: "batch.備註",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "請輸入 備註" },
                    domProps: { value: _vm.batch.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.batch, "備註", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("匯入檔案： "),
                  _c("input", {
                    ref: "batchData",
                    attrs: { type: "file", accept: ".txt" },
                  }),
                ]),
                _c("span", [
                  _vm._v("限用"),
                  _c("span", { staticClass: "text-red" }, [_vm._v("txt")]),
                  _vm._v("文字檔，請使用"),
                  _c("span", { staticClass: "text-red" }, [_vm._v("換行")]),
                  _vm._v("區別不同識別值"),
                ]),
                _vm.batch.廠商.廠商代碼 == "Yoe"
                  ? _c(
                      "div",
                      {
                        staticClass: "callout callout-danger",
                        staticStyle: { padding: "8px 22px 8px 9px" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "margin-bottom": "0px",
                            },
                          },
                          [_vm._v("注意！ Yoe 匯入格式變動！")]
                        ),
                        _c("p", [
                          _vm._v(
                            " 匯入格式請改使用 平台識別值(Y000000000) 格式匯入"
                          ),
                          _c("br"),
                          _vm._v(" 範例: Y000195471 "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.batchForm.hide()
                      },
                    },
                  },
                  [_vm._v("關閉")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.batchData()
                      },
                    },
                  },
                  [_vm._v("匯入")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", [_vm._v("建立日期")]),
        _c("th", [_vm._v("廠商")]),
        _c("th", [_vm._v("類別")]),
        _c("th", [_vm._v("識別值")]),
        _c("th", [_vm._v("原因")]),
        _c("th", [_vm._v("風控來源")]),
        _c("th", [_vm._v("驗證狀態")]),
        _c("th", [_vm._v("備註")]),
        _c("th", [_vm._v("功能")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }