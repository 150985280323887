var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "popForm", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                "aria-label": "Close",
                "data-dismiss": "modal",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _c("h4", { staticClass: "modal-title" }, [_vm._v("新增金額")]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "form-horizontal" }, [
            _c("table", [
              _c("tr", [
                _vm._m(0),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.money,
                        expression: "money",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.money },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.money = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.addOption },
            },
            [_vm._v("加入")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_vm._v("關閉")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("label", { staticClass: "control-label" }, [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 輸入金額： "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }