var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("首頁輪播")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("輪播列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("輪播編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle4",
                        required: "required",
                        type: "text",
                        placeholder: "僅在後台顯示",
                      },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputcolor1" },
                    },
                    [_vm._v(" 標題顏色(英)：")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subject1Color,
                          expression: "subject1Color",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { padding: "0", width: "35px" },
                      attrs: { id: "inputcolor1", type: "color" },
                      domProps: { value: _vm.subject1Color },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.subject1Color = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitle1" },
                    },
                    [_vm._v(" 標題第一行(英)：")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.subject1,
                          expression: "edit.subject1",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputTitle1", type: "text" },
                      domProps: { value: _vm.edit.subject1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "subject1", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitle2" },
                    },
                    [_vm._v(" 標題第二行(英)：")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.subject2,
                          expression: "edit.subject2",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputTitle2", type: "text" },
                      domProps: { value: _vm.edit.subject2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "subject2", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputcolor2" },
                    },
                    [_vm._v(" 中文標題顏色：")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subject2Color,
                          expression: "subject2Color",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { padding: "0", width: "35px" },
                      attrs: { id: "inputcolor2", type: "color" },
                      domProps: { value: _vm.subject2Color },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.subject2Color = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitle3" },
                    },
                    [_vm._v(" 中文標題： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.subject3,
                          expression: "edit.subject3",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "inputTitle3", type: "text" },
                      domProps: { value: _vm.edit.subject3 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "subject3", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "Pic" },
                    },
                    [_vm._v(" 說明： ")]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info,
                          expression: "info",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { height: "150px" },
                      attrs: { required: "required", placeholder: "內容...." },
                      domProps: { value: _vm.info },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.info = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group thetype" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "圖片",
                            name: "fileMode",
                          },
                          model: {
                            value: _vm.edit.contentUrlType,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "contentUrlType", $$v)
                            },
                            expression: "edit.contentUrlType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "2",
                            message: "影片",
                            name: "fileMode",
                          },
                          model: {
                            value: _vm.edit.contentUrlType,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "contentUrlType", $$v)
                            },
                            expression: "edit.contentUrlType",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm.edit.contentUrlType == 1
                  ? _c("div", { staticClass: "form-group image-part" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("div", { staticClass: "pic" }, [
                          _c("img", {
                            staticStyle: { "max-width": "30%" },
                            attrs: {
                              draggable: "false",
                              src: _vm.edit.contentUrl,
                            },
                          }),
                        ]),
                        _c("input", {
                          attrs: {
                            accept: "image/*",
                            id: "AdPic",
                            type: "file",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event, "main")
                            },
                          },
                        }),
                        _c("div", { staticClass: "help-block" }, [
                          _c("span", { staticClass: "text-red" }, [
                            _vm._v(
                              "大圖建議尺寸 " + _vm._s(_vm.imageSize) + "px。"
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.edit.contentUrlType == 2
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.contentUrl,
                              expression: "edit.contentUrl",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "videoUrl", type: "text" },
                          domProps: { value: _vm.edit.contentUrl },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "contentUrl",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group image-part" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "AdPic2" },
                    },
                    [_vm._v(" 小圖： ")]
                  ),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        staticStyle: { "max-width": "30%" },
                        attrs: {
                          draggable: "false",
                          src: _vm.edit.smallImgFullFileName,
                        },
                      }),
                    ]),
                    _c("input", {
                      attrs: { accept: "image/*", id: "AdPic2", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "small")
                        },
                      },
                    }),
                    _c("div", { staticClass: "help-block" }, [
                      _c("span", { staticClass: "text-red" }, [
                        _vm._v(
                          "小圖建議尺寸 " + _vm._s(_vm.awardsImgSize) + "px。"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle4" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 後台標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 背景類型： "),
      _c("div", { staticClass: "noedit" }, [_vm._v("不可更改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "AdPic" } },
      [_c("span", { staticClass: "text-red" }, [_vm._v("*")]), _vm._v(" 大圖 ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "videoUrl" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 影片連結： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }