var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "analyze" } }, [
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.data.文章標題)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "none" },
                },
                [
                  _c("div", {
                    attrs: { id: "article" },
                    domProps: { innerHTML: _vm._s(_vm.data.內文) },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "main" }, [
      _c("div", { staticClass: "app mb-10" }, [
        _c("div", { staticClass: "photo" }, [
          _c("img", { attrs: { src: _vm.query.圖示, alt: "" } }),
        ]),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.query.遊戲名稱)),
          ]),
          _c("p", { staticClass: "time" }, [
            _vm._v(
              _vm._s(_vm._f("dateTimeFormat")(_vm.data.發佈時間)) + "發佈"
            ),
          ]),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "btn btn-default btn-play mb-10",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.backList(_vm.query)
            },
          },
        },
        [_vm._v(" 此 App 所有分析 ")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default btn-play",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.backList()
            },
          },
        },
        [_vm._v(" 返回集成分析 ")]
      ),
      _c(
        "div",
        { staticClass: "sub-title-list" },
        _vm._l(_vm.h2List, function (sub, index) {
          return _c(
            "p",
            {
              attrs: { id: `link_h2_${index}` },
              on: {
                click: function ($event) {
                  return _vm.goText(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(sub) + " ")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }