var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("頻道管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("頻道管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "div",
              {
                staticClass: "btn-add",
                staticStyle: { display: "flex", width: "270px" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-primary",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.watchEnd()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 完成巡邏 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-success",
                    class: { waiting: _vm.loadingExcel },
                    staticStyle: { "margin-right": "5px" },
                    attrs: {
                      type: "btn btn-block btn-success",
                      disabled: _vm.searchData.length == 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.Exprot(1, _vm.searchData)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("匯出")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                ),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.巡邏人,
                      expression: "searchData.巡邏人",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "巡邏人",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "", select: "" } }, [
                    _vm._v("全部巡邏人"),
                  ]),
                  _c("option", { attrs: { value: "尚未派發" } }, [
                    _vm._v("尚未派發"),
                  ]),
                  _vm._l(_vm.channelMan.result, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.人員名稱 } },
                      [_vm._v(" " + _vm._s(item.人員名稱))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.留言板狀態,
                      expression: "searchData.留言板狀態",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit2", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "留言板狀態",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { key: "0", attrs: { value: "-1", select: "" } },
                    [_vm._v("全部留言板狀態")]
                  ),
                  _c("option", { key: "1", attrs: { value: "0" } }, [
                    _vm._v("關閉"),
                  ]),
                  _c("option", { key: "2", attrs: { value: "1" } }, [
                    _vm._v("開放"),
                  ]),
                  _c("option", { key: "3", attrs: { value: "2" } }, [
                    _vm._v("部分關閉"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.檢索狀態,
                      expression: "searchData.檢索狀態",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit3", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "檢索狀態",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { key: "1", attrs: { value: "1" } }, [
                    _vm._v("開啟檢索"),
                  ]),
                  _c("option", { key: "2", attrs: { value: "0" } }, [
                    _vm._v("關閉檢索"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.搜尋關鍵字,
                    expression: "searchData.搜尋關鍵字",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋頻道名稱、遊戲ID",
                  type: "text",
                },
                domProps: { value: _vm.searchData.搜尋關鍵字 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "搜尋關鍵字", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.channelQueryAll.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showcheck,
                              expression: "showcheck",
                            },
                          ],
                          staticStyle: { "min-width": "40px", width: "4%" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                filter: "grayscale(0)",
                                opacity: ".5",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectAll,
                                    expression: "selectAll",
                                  },
                                ],
                                staticClass: "inputBox",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.selectAll)
                                    ? _vm._i(_vm.selectAll, null) > -1
                                    : _vm.selectAll,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.selectAll,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectAll = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectAll = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectAll = $$c
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "10%" } },
                        [_vm._v("頻道名稱")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "8%" } },
                        [_vm._v("遊戲ID")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "5%" },
                        },
                        [_vm._v("電話")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "4%" },
                        },
                        [_vm._v("版權使用規範")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "4%" },
                        },
                        [_vm._v("巡邏人")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "2%" },
                        },
                        [_vm._v("影片下架數")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "2%" },
                        },
                        [_vm._v("留言板狀態")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v("備註")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "6%" },
                        },
                        [_vm._v("最後巡邏時間")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "10%" },
                        },
                        [_vm._v("功能")]
                      ),
                    ]),
                    _vm.channelQueryAll.total > 0
                      ? _vm._l(
                          _vm.channelQueryAll.datas,
                          function (item, index) {
                            return _c("tr", { key: item.id }, [
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showcheck,
                                      expression: "showcheck",
                                    },
                                  ],
                                  staticStyle: {
                                    "text-align": "center",
                                    padding: "0",
                                  },
                                },
                                [
                                  _c("label", { staticClass: "label" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.timeList,
                                          expression: "timeList",
                                        },
                                      ],
                                      staticClass: "inputBox",
                                      attrs: {
                                        type: "checkbox",
                                        name: "checktime",
                                        pname: item.頻道名稱,
                                      },
                                      domProps: {
                                        value: item.頻道管理編號,
                                        checked: Array.isArray(_vm.timeList)
                                          ? _vm._i(
                                              _vm.timeList,
                                              item.頻道管理編號
                                            ) > -1
                                          : _vm.timeList,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.timeList,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = item.頻道管理編號,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.timeList = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.timeList = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.timeList = $$c
                                          }
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "linkStyle",
                                      attrs: {
                                        href: item.頻道連結,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.頻道名稱))]
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "moretd",
                                  staticStyle: {
                                    "text-align": "center",
                                    padding: "0",
                                  },
                                },
                                _vm._l(item.玩家資訊, function (people, index) {
                                  return _c("div", [
                                    _vm._v(
                                      " " + _vm._s(people.玩家角色名稱) + " "
                                    ),
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "moretd",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { align: "center" },
                                },
                                _vm._l(item.玩家資訊, function (people, index) {
                                  return _c("div", [
                                    _vm._v(
                                      " " + _vm._s(people.玩家角色電話) + " "
                                    ),
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "moretd",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { align: "center" },
                                },
                                _vm._l(item.玩家資訊, function (people, index) {
                                  return _c("div", [
                                    people.版權使用規範簡訊狀態 == 0
                                      ? _c("span", [_vm._v("未寄送")])
                                      : _vm._e(),
                                    people.版權使用規範簡訊狀態 == 1
                                      ? _c("span", [_vm._v("已寄送")])
                                      : _vm._e(),
                                    people.版權使用規範簡訊狀態 == 2
                                      ? _c("span", [_vm._v("發送失敗")])
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v(_vm._s(item.巡邏人))]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v(_vm._s(item.影片下架數))]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  item.留言板狀態 == 0
                                    ? _c("span", [_vm._v("關閉")])
                                    : _vm._e(),
                                  item.留言板狀態 == 1
                                    ? _c("span", [_vm._v("開放")])
                                    : _vm._e(),
                                  item.留言板狀態 == 2
                                    ? _c("span", [_vm._v("部分關閉")])
                                    : _vm._e(),
                                ]
                              ),
                              _c("td", {
                                staticStyle: { "text-align": "center" },
                                attrs: { align: "center" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.changeN(item.備註)),
                                },
                              }),
                              _c("td", {
                                staticStyle: { "text-align": "center" },
                                attrs: { align: "center" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.FormatTime(item.巡邏時間)
                                  ),
                                },
                              }),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center text-nowrap",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editorVideo(item)
                                        },
                                      },
                                    },
                                    [_vm._v("所有影片")]
                                  ),
                                  _vm._v("     "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editorItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _vm._v("     "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]
                              ),
                            ])
                          }
                        )
                      : [
                          _c("tr", [
                            _vm.showcheck
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "11" },
                                  },
                                  [_vm._v("沒有資料")]
                                )
                              : _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_vm._v("沒有資料")]
                                ),
                          ]),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.channelQueryAll.total > 0,
                expression: "channelQueryAll.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.channelQueryAll.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }