var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ServiceList01" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("服務範疇-與樂同行/愛讚公益")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v("服務範疇-與樂同行/愛讚公益"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [_c("banner-picker")], 1),
      ]),
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "list-table",
              { attrs: { "show-list": "index title link status" } },
              [_vm._v("相關連結")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }