var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 品項管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", { staticClass: "active" }, [_vm._v("品項管理 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("廠商：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.廠商設定主檔編號,
                      expression: "searchData.廠商設定主檔編號",
                    },
                  ],
                  staticClass: "selPaymentType form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "廠商設定主檔編號",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v(" 全部 ")]),
                  _vm._l(_vm.廠商設定List, function (item) {
                    return _c(
                      "option",
                      { key: item.編號, domProps: { value: item.編號 } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.名稱) +
                            "-" +
                            _vm._s(item.顯示名稱) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onSearch(1, _vm.searchData)
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                on: {
                  click: function ($event) {
                    return _vm.addItem()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增品項 ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.list.length > 0
                      ? _vm._l(_vm.list, function (item) {
                          return _c("tr", { key: item.編號 }, [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.品項名稱)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.商品代碼)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.金額)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.販賣數量)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updEnable(item)
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.啟用,
                                        expression: "item.啟用",
                                      },
                                    ],
                                    attrs: {
                                      "false-value": 0,
                                      "true-value": 1,
                                      name: "ckbx-style-8",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.啟用)
                                        ? _vm._i(item.啟用, null) > -1
                                        : _vm._q(item.啟用, 1),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = item.啟用,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "啟用",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "啟用",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "啟用", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + item },
                                  }),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                  },
                                },
                                [_vm._v("編輯")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("品項名稱")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("商品代碼")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("金額")]),
      _c("th", { staticClass: "text-nowrap" }, [
        _vm._v("販賣數量"),
        _c("br"),
        _vm._v("(-1表示無限制)"),
      ]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("停用/啟用")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }