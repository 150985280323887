var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "MftdContactUsList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("聯繫客服列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("聯繫客服列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "input-group mr-20" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("連絡電話："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.phoneNumber,
                    expression: "searchData.phoneNumber",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋連絡電話",
                  type: "text",
                },
                domProps: { value: _vm.searchData.phoneNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "phoneNumber", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group mr-20" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("角色名稱："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.name,
                    expression: "searchData.name",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋角色名稱",
                  type: "text",
                },
                domProps: { value: _vm.searchData.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group mr-20" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("填單開始日期："),
              ]),
              _c(
                "div",
                { staticClass: "input-group date" },
                [
                  _vm._m(0),
                  _c("DatePicker", {
                    attrs: { propValue: _vm.searchData.local.startTime },
                    on: {
                      "update:propValue": function ($event) {
                        return _vm.$set(
                          _vm.searchData.local,
                          "startTime",
                          $event
                        )
                      },
                      "update:prop-value": function ($event) {
                        return _vm.$set(
                          _vm.searchData.local,
                          "startTime",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("填單結束日期："),
              ]),
              _c(
                "div",
                { staticClass: "input-group date" },
                [
                  _vm._m(1),
                  _c("DatePicker", {
                    attrs: { propValue: _vm.searchData.local.endTime },
                    on: {
                      "update:propValue": function ($event) {
                        return _vm.$set(_vm.searchData.local, "endTime", $event)
                      },
                      "update:prop-value": function ($event) {
                        return _vm.$set(_vm.searchData.local, "endTime", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "text mt-25" }, [
              _c("p", { staticClass: "data-num" }, [
                _c("span", [_vm._v("資料總筆數：" + _vm._s(_vm.total))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-group fl mr-20 mt-25 w100-md mt-15-md" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning w100-md",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_vm._v(" 查詢 ")]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "form-group fl mr-20 mt-25 w100-md mt-15-md" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger w100-md",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetSearch()
                      },
                    },
                  },
                  [_vm._v(" 清除 ")]
                ),
              ]
            ),
            _c("div", { staticClass: "form-group fl mt-25 w100-md mt-15-md" }, [
              _vm.total > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success w100-md",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.exportOrders()
                        },
                      },
                    },
                    [_vm._v(" 匯出 ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(2),
                    _vm.loading
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { align: "center", colspan: "10" } },
                            [_vm._v("查詢中")]
                          ),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { align: "center", colspan: "10" } },
                            [_vm._v("查詢無任何資料")]
                          ),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.companyName)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.email)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.phoneNumber)),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.subject))]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.define.handleStatus[item.status]) +
                                  " "
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.updateUName)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateData(item)
                                    },
                                  },
                                },
                                [_vm._v(" 編輯 ")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "1%" } }, [
        _vm._v("編號"),
      ]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v("填單時間")]
      ),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("角色名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("玩家ID"),
      ]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "150px" } },
        [_vm._v("Email")]
      ),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "120px" } },
        [_vm._v("連絡電話")]
      ),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "15%" } }, [
        _vm._v("主旨"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "7%" } }, [
        _vm._v("處理人員"),
      ]),
      _c("th", { staticClass: "text-nowrap text-center" }, [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }