var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "importData", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.$t("匯入x資料", [_vm.$t(_vm.title)]))),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "modal-body",
            staticStyle: { display: "flex", gap: ".7em" },
          },
          [
            _c(
              "label",
              {
                staticClass: "btn btn-primary btn-cog",
                staticStyle: { flex: "1 1 auto", cursor: "pointer" },
                attrs: { type: "button" },
              },
              [
                _c("input", {
                  ref: "inputFile",
                  staticStyle: {
                    margin: "auto",
                    width: "100%",
                    cursor: "pointer",
                  },
                  attrs: {
                    type: "file",
                    id: "inputFile",
                    accept: ".xls,.xlsx",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.readExcel()
                    },
                  },
                }),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-success btn-cog",
                attrs: {
                  type: "button",
                  href: `/uploads/XSW/${_vm.title}範例.xlsx`,
                  download: "",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("下載範例")) + " ")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "aria-label": "Close",
          "data-dismiss": "modal",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }