var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot", attrs: { id: "clientGameHallList" } },
    [
      _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "box box-primary",
            staticStyle: { background: "none" },
          },
          [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c("div", { staticClass: "g_back" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-chevron-left" })]
                  ),
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.query.遊戲名稱) + " 遊戲介紹")]),
                    _c("p", [_vm._v(_vm._s(_vm.query.開發商名稱))]),
                  ]),
                ]),
                _c("div", { staticClass: "top-box" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "input-group mr-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.遊戲館名稱,
                            expression: "searchData.遊戲館名稱",
                          },
                        ],
                        staticClass: "form-control pull-right",
                        attrs: {
                          name: "table_search",
                          placeholder: "遊戲館名稱",
                          type: "text",
                        },
                        domProps: { value: _vm.searchData.遊戲館名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchData,
                              "遊戲館名稱",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.searchData.遊戲館名稱
                        ? _c("i", {
                            staticClass: "fa fa-close",
                            on: {
                              click: function ($event) {
                                _vm.searchData.遊戲館名稱 = ""
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-search" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.玩法名稱,
                            expression: "searchData.玩法名稱",
                          },
                        ],
                        staticClass: "form-control pull-right",
                        attrs: {
                          name: "table_search",
                          placeholder: "玩法名稱",
                          type: "text",
                        },
                        domProps: { value: _vm.searchData.玩法名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchData,
                              "玩法名稱",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.searchData.玩法名稱
                        ? _c("i", {
                            staticClass: "fa fa-close",
                            on: {
                              click: function ($event) {
                                _vm.searchData.玩法名稱 = ""
                              },
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-search" })]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("p", { staticClass: "data-num" }, [
                        _c("span", [_vm._v("資料筆數：")]),
                        _c("span", { attrs: { id: "total" } }, [
                          _vm._v(_vm._s(_vm.total)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          staticStyle: { display: "inline" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sort dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dropdown =
                                    _vm.dropdown == "none" ? "block" : "none"
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "icon-sort" }),
                              _vm._v(" 排序依據 "),
                            ]
                          ),
                          _c(
                            "ul",
                            {
                              staticClass: "dropdown-menu",
                              style: { display: `${_vm.dropdown}` },
                            },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    class: [
                                      "dropdown-item",
                                      _vm.searchData.排序順序 == 2
                                        ? "active"
                                        : "",
                                    ],
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetData(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" A to Z ")]
                                ),
                              ]),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    class: [
                                      "dropdown-item",
                                      _vm.searchData.排序順序 == 1
                                        ? "active"
                                        : "",
                                    ],
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetData(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" Z to A ")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-play",
                      staticStyle: { float: "right" },
                      on: {
                        click: function ($event) {
                          return _vm.showPlay()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-book" }),
                      _vm._v(" 所有玩法定義 "),
                    ]
                  ),
                ]),
                _vm.total == 0
                  ? _c("Empty", {
                      attrs: {
                        title: "找不到結果",
                        desc: "請改用其他關鍵字或移除排序條件",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "g-list hall table-responsive mt-20" },
                  [
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        {
                          staticClass: "g-card",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            mouseout: function ($event) {
                              return _vm.pauseVideo()
                            },
                            mouseover: function ($event) {
                              return _vm.playVideo()
                            },
                          },
                        },
                        [_c("GameHallCard", { attrs: { item: item } })],
                        1
                      )
                    }),
                    _vm.searchData.PageNum < _vm.totolPage
                      ? _c("div", { staticClass: "loading-box" }, [
                          _c("div", { staticClass: "loading" }),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("slotPlayContent", { ref: "slotPlayContent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }