var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "videoAlert", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-borderless" }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("頻道名稱：")]
                ),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.videoitem.頻道名稱))]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("影片標題：")]
                ),
                _c("td", [
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "linkStyle",
                        attrs: {
                          href:
                            `https://www.youtube.com/watch?v=` +
                            _vm.videoitem.videoId,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.videoitem.影片標題))]
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      width: "120px",
                      "vertical-align": "baseline",
                      "padding-top": "8px",
                    },
                  },
                  [_vm._v("留言作者：")]
                ),
                _c("td", [
                  _c("span", {
                    staticStyle: {
                      display: "-webkit-box",
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "5",
                      overflow: "hidden",
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.changeN(_vm.videoitem.留言作者)),
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      width: "120px",
                      "vertical-align": "baseline",
                      "padding-top": "8px",
                    },
                  },
                  [_vm._v("留言內容：")]
                ),
                _c("td", [
                  _c("span", {
                    staticStyle: {
                      display: "-webkit-box",
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "5",
                      overflow: "hidden",
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.changeN(_vm.videoitem.留言內容)),
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("發布日期：")]
                ),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.videoitem.留言發布日期))]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("巡邏人：")]
                ),
                _c("td", [
                  this.CheckMan
                    ? _c("span", [_vm._v(_vm._s(_vm.videoitem.巡邏人))])
                    : _c("div", { staticClass: "select-group" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.videoitem.巡邏人,
                                expression: "videoitem.巡邏人",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "selectUnit" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.videoitem,
                                  "巡邏人",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "",
                                  disabled: "",
                                  selected: "",
                                },
                              },
                              [_vm._v("請選擇巡邏人")]
                            ),
                            _vm._l(
                              _vm.channelMan.result,
                              function (item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.人員名稱 },
                                  },
                                  [_vm._v(_vm._s(item.人員名稱))]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      width: "120px",
                      "vertical-align": "baseline",
                      "padding-top": "17px",
                    },
                  },
                  [_vm._v("狀態：")]
                ),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.videoitem.狀態,
                          expression: "videoitem.狀態",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.videoitem,
                            "狀態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { key: "1", attrs: { value: "0" } }, [
                        _vm._v("未處理"),
                      ]),
                      _c("option", { key: "2", attrs: { value: "1" } }, [
                        _vm._v("不處理"),
                      ]),
                      _c("option", { key: "3", attrs: { value: "2" } }, [
                        _vm._v("待追蹤"),
                      ]),
                      _c("option", { key: "4", attrs: { value: "3" } }, [
                        _vm._v("已結案"),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "text-red" }, [
                    _vm._v("*待追蹤會列入追蹤中留言區"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("遊戲ID：")]
                ),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.videoitem.遊戲角色名稱,
                        expression: "videoitem.遊戲角色名稱",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.videoitem.遊戲角色名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.videoitem,
                          "遊戲角色名稱",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      width: "120px",
                      "vertical-align": "baseline",
                      "padding-top": "15px",
                    },
                  },
                  [_vm._v("追蹤狀態：")]
                ),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.videoitem.追蹤狀態,
                        expression: "videoitem.追蹤狀態",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.videoitem.追蹤狀態 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.videoitem, "追蹤狀態", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "5px" } },
                    _vm._l(_vm.commonTexts, function (text, index) {
                      return _c(
                        "button",
                        {
                          key: index,
                          staticClass: "btn btn-warning",
                          staticStyle: { margin: "4px" },
                          on: {
                            click: function ($event) {
                              return _vm.insertText(text)
                            },
                          },
                        },
                        [_vm._v(_vm._s(text))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-right",
                    staticStyle: { width: "120px" },
                  },
                  [_vm._v("備註：")]
                ),
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.videoitem.備註,
                        expression: "videoitem.備註",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "", id: "", cols: "30", rows: "3" },
                    domProps: { value: _vm.videoitem.備註 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.videoitem, "備註", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v(" 儲存 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [
        _c("span", [_vm._v("留言詳細")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }