var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("企業沿革")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("企業沿革")]),
        _c("li", { staticClass: "active" }, [_vm._v("企業沿革")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增企業沿革 ")]
            ),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋關鍵字",
                  type: "text",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.games.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.games.total > 0
                      ? _vm._l(_vm.games.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.detailTopTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.years + "年 " + item.months + "月")
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.yeare + "年 " + item.monthe + "月")
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.isStop == "1" ? "下架" : "上架")
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center text-nowrap" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editorItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                                _vm._v("   "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("刪除")]
                                ),
                              ]
                            ),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.games.total > 0,
                expression: "games.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.games.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("標題")]),
      _c("th", { staticStyle: { "min-width": "65px" } }, [_vm._v("起始時間")]),
      _c("th", { staticStyle: { "min-width": "65px" } }, [_vm._v("結束時間")]),
      _c("th", { staticStyle: { "min-width": "180px" } }, [_vm._v("建立日期")]),
      _c("th", { staticStyle: { "min-width": "65px" } }, [_vm._v("狀態")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }