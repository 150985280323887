var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("最新消息列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("最新消息列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add fa fa-plus",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_vm._v(" 新增訊息 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.classTypeId,
                      expression: "searchData.classTypeId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "classTypeId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.newsTypes.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.classTypeId } },
                      [_vm._v(" " + _vm._s(item.className) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  type: "text",
                  name: "table_search",
                  placeholder: "搜尋關鍵字",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c("button", {
                  staticClass: "btn btn-default fa fa-search",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clickSearch()
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.news.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm.news.total <= 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "10" } }, [
                          _vm._v("沒有資料"),
                        ]),
                      ])
                    : _vm._l(_vm.news.datas, function (item, index) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [
                            _vm._v(_vm._s(_vm.FormatTime(item.publishTime))),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text-right",
                              staticStyle: { "border-right": "0" },
                            },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isTop === 1,
                                    expression: "item.isTop === 1",
                                  },
                                ],
                                staticClass: "fa fa-thumb-tack fa-lg",
                                staticStyle: { transform: "rotate(45deg)" },
                              }),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-left",
                              staticStyle: { "border-left": "0" },
                            },
                            [_vm._v(_vm._s(item.detailTopTitle))]
                          ),
                          _c("td", [_vm._v(_vm._s(item.classTypeName))]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                on: {
                                  click: function ($event) {
                                    return _vm.previewItem(item)
                                  },
                                },
                              },
                              [_vm._v("預覽")]
                            ),
                            _vm._v("   "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.editorItem(item)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                            _vm._v("   "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_vm._v("刪除")]
                            ),
                          ]),
                        ])
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.news.total > 0,
                expression: "news.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.news.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "20%" } }, [
        _vm._v("發布時間"),
      ]),
      _c(
        "th",
        {
          staticStyle: { "min-width": "150px", width: "40%" },
          attrs: { colspan: "2" },
        },
        [_vm._v("標題")]
      ),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "200px", width: "10%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }