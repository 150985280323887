var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "VersionSettings" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("版本管理設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("版本管理設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm.maintain.maintainETime
            ? _c("div", { staticClass: "top-box" }, [
                _c(
                  "div",
                  { staticClass: "form-group", staticStyle: { width: "auto" } },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("系統維護："),
                    ]),
                    _c("div", { staticClass: "input-group date" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _c("DateRangePicker", {
                            attrs: {
                              propTimePicker: true,
                              propEndDate: _vm.maintain.maintainETime,
                              propStartDate: _vm.maintain.maintainSTime,
                            },
                            on: {
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.maintain,
                                  "maintainETime",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.maintain,
                                  "maintainETime",
                                  $event
                                )
                              },
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.maintain,
                                  "maintainSTime",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.maintain,
                                  "maintainSTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.maintainUpdate },
                    },
                    [_vm._v(" 更新 ")]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addType()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-bordered text-center min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "7" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "7" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.datas.length - index)),
                            ]),
                            _c("td", [
                              !item.isCreate
                                ? _c("p", [
                                    _vm._v(" " + _vm._s(item.platform) + " "),
                                  ])
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.platform,
                                          expression: "item.platform",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            item,
                                            "platform",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "Android" } },
                                        [_vm._v("Android")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "iOS" } },
                                        [_vm._v("iOS")]
                                      ),
                                    ]
                                  ),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", [
                                    _vm._v(" " + _vm._s(item.versionNo) + " "),
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.versionNo,
                                        expression: "item.versionNo",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: item.versionNo },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "versionNo",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", [
                                    _vm._v(_vm._s(item.isHard ? "是" : "否")),
                                  ])
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: item.isHard,
                                          expression: "item.isHard",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return _vm._n(val)
                                              })
                                          _vm.$set(
                                            item,
                                            "isHard",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "1" } }, [
                                        _vm._v("是"),
                                      ]),
                                      _c("option", { attrs: { value: "0" } }, [
                                        _vm._v("否"),
                                      ]),
                                    ]
                                  ),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { align: "center" },
                              },
                              [
                                item.isEdit
                                  ? _c("p", [_vm._v("–")])
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.isStop,
                                              expression: "item.isStop",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            name: "ckbx-style-8",
                                            id: `check${index}`,
                                            "false-value": 1,
                                            "true-value": 0,
                                          },
                                          domProps: {
                                            checked: Array.isArray(item.isStop)
                                              ? _vm._i(item.isStop, null) > -1
                                              : _vm._q(item.isStop, 0),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = item.isStop,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? 0 : 1
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        item,
                                                        "isStop",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        item,
                                                        "isStop",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(item, "isStop", $$c)
                                                }
                                              },
                                              function ($event) {
                                                return _vm.toggle(item)
                                              },
                                            ],
                                          },
                                        }),
                                        _c("label", {
                                          attrs: { for: `check${index}` },
                                        }),
                                      ]
                                    ),
                              ]
                            ),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.updateTime,
                                      "YYYY/MM/DD HH:mm:ss"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.updateUName))]),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.isEdit,
                                      expression: "!item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(item)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-success btn-save",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save(item)
                                    },
                                  },
                                },
                                [_vm._v(" 儲存 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-default",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel(item)
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "50px" } }, [_vm._v("編號")]),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "120px" } },
        [_vm._v("平台")]
      ),
      _c(
        "th",
        { staticClass: "text-nowrap", staticStyle: { "min-width": "100px" } },
        [_vm._v("版本號")]
      ),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("是否強更")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [
        _vm._v("停用/啟用"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [
        _vm._v("建立/更新時間"),
      ]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("更新者")]),
      _c("th", { staticStyle: { "min-width": "140px" } }, [_vm._v("功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }