var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ServeStatus" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("伺服器狀態")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("伺服器狀態")]),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-hover table-bordered ServerListTable",
              },
              [
                _c("tbody", [
                  _c("tr", { staticClass: "bg-gray" }, [
                    _c(
                      "th",
                      { staticStyle: { "min-width": "150px", width: "25%" } },
                      [_vm._v("名字")]
                    ),
                    _c(
                      "th",
                      { staticStyle: { "min-width": "90px", width: "20%" } },
                      [_vm._v("狀態")]
                    ),
                    _c(
                      "th",
                      { staticStyle: { "min-width": "90px", width: "20%" } },
                      [_vm._v("總人數")]
                    ),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Gods 眾神國度")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("正常")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("3,000")]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("ASGARD 阿斯嘉")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("正常")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("3,000")]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("眾神國度伺服器")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("正常")]),
                    _c("td", { staticClass: "t-tc" }, [_vm._v("3,000")]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }