var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot-empty" }, [
    _vm.title == "找不到結果"
      ? _c("img", { attrs: { src: require("@/assets/img/slot-empty.png") } })
      : _vm._e(),
    _c("h2", [_vm._v(_vm._s(_vm.title))]),
    _c("p", [_vm._v(_vm._s(_vm.desc))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }