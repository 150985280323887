var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "TicketDetail", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("table", { staticClass: "table table-bordered table-habits" }, [
            _c(
              "tbody",
              [
                _vm._m(1),
                _vm._l(_vm.info, function (item) {
                  return _c("tr", { key: item.流水號 }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(item.商品單價)),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(item.商品名稱) + " "),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(item.序號) + " "),
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.orderResult == 1
                              ? _vm.GetStatus(item.信託狀態)
                              : "未配發"
                          ) +
                          " "
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._m(2),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("票券明細")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("單價")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("優惠券名稱")]),
      _c("th", [_vm._v("票券序號")]),
      _c("th", [_vm._v("使用狀態")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v(" 關閉 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }