var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("活動管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("藝術大賞")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增內容 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  staticStyle: { width: "70px" },
                },
                [_vm._v("狀態：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isStop,
                      expression: "searchData.isStop",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "isStop",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { domProps: { value: -1 } }, [_vm._v("全部")]),
                  _c("option", { domProps: { value: 0 } }, [_vm._v("上架")]),
                  _c("option", { domProps: { value: 1 } }, [_vm._v("下架")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  staticStyle: { width: "70px" },
                },
                [_vm._v("類別：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.classTypeId,
                      expression: "searchData.classTypeId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "classTypeId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.classTypes.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.classTypeId } },
                      [_vm._v(_vm._s(item.className))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                {
                  staticClass: "control-label",
                  staticStyle: { width: "70px" },
                },
                [_vm._v("排序：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isDescOrder,
                      expression: "searchData.isDescOrder",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "isDescOrder",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { domProps: { value: -1 } }, [_vm._v("舊→新")]),
                  _c("option", { domProps: { value: 1 } }, [_vm._v("新→舊")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "作品名稱",
                  type: "text",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.listDatas.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.listDatas.total > 0
                      ? _vm._l(_vm.listDatas.datas, function (item, dataIndex) {
                          return _c("tr", { key: item.id }, [
                            _vm.searchData.isDescOrder == -1
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(dataIndex + 1)),
                                ])
                              : _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    _vm._s(_vm.listDatas.total - dataIndex)
                                  ),
                                ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.contentId)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.classTypeName)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.detailTopTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.detailTopInfo)),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "pic jqimgFill" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.mainImgFullFileName,
                                    draggable: "false",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", { staticClass: "td-sort text-nowrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.sortId,
                                    expression: "item.sortId",
                                  },
                                ],
                                attrs: { type: "number", min: "1" },
                                domProps: { value: item.sortId },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "sortId",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-warning btn-add btn-upDate",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateGameOd(item)
                                    },
                                  },
                                },
                                [_vm._v("更新")]
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.isStop,
                                        expression: "item.isStop",
                                      },
                                    ],
                                    attrs: {
                                      "false-value": 1,
                                      id: "check" + dataIndex,
                                      "true-value": 0,
                                      name: "ckbx-style-8",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.isStop)
                                        ? _vm._i(item.isStop, null) > -1
                                        : _vm._q(item.isStop, 0),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.isStop,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 0 : 1
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "isStop",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "isStop",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "isStop", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.updateGameStatus(item)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + dataIndex },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center text-nowrap" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editorItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                                _vm._v("  "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("刪除")]
                                ),
                              ]
                            ),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", [_vm._v("#")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("id")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("類別")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("作品名稱")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("作者名稱")]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("主圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("排序")]),
      _c("th", { staticStyle: { "min-width": "60px" } }, [_vm._v("狀態")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }