var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AlbumEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("相簿編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("相簿列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("相簿編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.名稱,
                          expression: "edit.名稱",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "名稱", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.功能模組編號,
                          expression: "edit.功能模組編號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.功能模組編號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "功能模組編號",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v(" 圖片： "),
                  ]),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        attrs: { draggable: "false", src: _vm.edit.filesUrl },
                      }),
                    ]),
                    _c("input", {
                      attrs: {
                        accept: "image/*",
                        id: "AnnouncementPic",
                        type: "file",
                        multiple: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _c("div", { staticClass: "help-block" }, [
                      _c("span", { staticClass: "text-red" }, [
                        _vm._v(_vm._s(_vm.picLimit)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row ann-imgChoose mt-10" },
                      [
                        _c(
                          "dnd-zone",
                          { attrs: { "vertical-search": "" } },
                          [
                            _c(
                              "dnd-container",
                              {
                                staticClass: "row",
                                attrs: {
                                  "dnd-model": _vm.edit.相片列表,
                                  "dnd-id": "list-example",
                                },
                              },
                              [
                                _vm._l(_vm.edit.相片列表, function (card) {
                                  return [
                                    _c(
                                      "dnd-item",
                                      {
                                        key: card.applyId,
                                        attrs: {
                                          "dnd-model": card,
                                          "dnd-id": card.applyId,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-sm-3 col-xs-6 chooseBox",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "img-close fa fa-fw fa-close",
                                              staticStyle: {
                                                height: "25px",
                                                width: "25px",
                                                "line-height": "20px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DeletePic(card)
                                                },
                                              },
                                            }),
                                            _c("img", {
                                              attrs: { src: card.filesUrl },
                                              on: {
                                                mouseup: function ($event) {
                                                  return _vm.getMainPic()
                                                },
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "radio" },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "radio",
                                                    id:
                                                      "mainImg" + card.applyId,
                                                    name: "mainImg",
                                                  },
                                                  domProps: {
                                                    checked: card.isMain == 1,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.SetPicIsMain(
                                                        card
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v("主圖 "),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否顯示,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否顯示", $$v)
                              },
                              expression: "edit.是否顯示",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否顯示,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否顯示", $$v)
                              },
                              expression: "edit.是否顯示",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _vm.edit.isStop == 1 &&
                  (_vm.edit.projectId == "omp_dk" ||
                    _vm.edit.projectId == "omp_jx2")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning btn-lg",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save(true)
                            },
                          },
                        },
                        [_vm._v(" 預覽 ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 相簿名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 對應ID： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }