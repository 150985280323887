var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ForumMuteSetting" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("討論區禁言管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("討論區禁言管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content forum-mute" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addMute()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增禁言 ")]
              ),
              _c("div", { staticClass: "form-group clearfix" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("開始日期："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _vm._m(0),
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.expiryDate,
                        propStartDate: _vm.searchData.startDate,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "expiryDate", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "expiryDate", $event)
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "startDate", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "startDate", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.muteId,
                      expression: "searchData.muteId",
                    },
                  ],
                  staticClass: "form-control mr-10",
                  attrs: { placeholder: "輸入使用者ID", type: "text" },
                  domProps: { value: _vm.searchData.muteId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "muteId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
              _c("p", { staticClass: "text text-red" }, [
                _vm._v(" * 禁言天數不輸入或大於3650天，為永久禁言。 "),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered min1000" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "9" },
                            },
                            [_vm._v("查詢中")]
                          ),
                        ])
                      : _vm.datas.length === 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "9" },
                            },
                            [_vm._v("查詢無任何資料")]
                          ),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class:
                                item.dateNumber === 0 ? "bg-gray-light" : "",
                            },
                            [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.muteId))]),
                              _c("td", [_vm._v(_vm._s(item.nickName || "-"))]),
                              _c("td", { staticClass: "text-center" }, [
                                !item.isEdit
                                  ? _c("span", [
                                      item.dateNumber === "" ||
                                      item.dateNumber > 3650
                                        ? _c("p")
                                        : item.dateNumber === 0
                                        ? _c("p", [_vm._v("已解禁")])
                                        : _c("p", [
                                            _vm._v(
                                              _vm._s(item.dateNumber) + " 天"
                                            ),
                                          ]),
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: item.dateNumber,
                                          expression: "item.dateNumber",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control text-center",
                                      staticStyle: { "min-width": "100px" },
                                      attrs: {
                                        min: "1",
                                        placeholder: "輸入天數",
                                        type: "number",
                                      },
                                      domProps: { value: item.dateNumber },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "dateNumber",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.startDate.split(/\s/)[0]) +
                                    " "
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                item.dateNumber === "" || item.dateNumber > 3650
                                  ? _c("p", { staticClass: "text-red" }, [
                                      _vm._v(" 永久禁言 "),
                                    ])
                                  : item.isEdit
                                  ? _c("p", [_vm._v("–")])
                                  : _c("p", [
                                      _vm._v(
                                        _vm._s(item.expiryDate.split(/\s/)[0])
                                      ),
                                    ]),
                              ]),
                              _c("td", [
                                !item.isEdit
                                  ? _c("p", [
                                      _vm._v(" " + _vm._s(item.note) + " "),
                                    ])
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.note,
                                          expression: "item.note",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: item.note },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "note",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(item.opreateId)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !item.isEdit,
                                        expression: "!item.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.change(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 修改 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.isEdit,
                                        expression: "item.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-success btn-save",
                                    on: {
                                      click: function ($event) {
                                        return _vm.save(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 儲存 ")]
                                ),
                                _vm._v("   "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !item.isEdit,
                                        expression: "!item.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 刪除 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.isEdit,
                                        expression: "item.isEdit",
                                      },
                                    ],
                                    staticClass: "btn btn-default",
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancel(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 取消 ")]
                                ),
                              ]),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border clearfix" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.searchData.pageSize,
                      expression: "searchData.pageSize",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "form-control page-size",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return _vm._n(val)
                          })
                        _vm.$set(
                          _vm.searchData,
                          "pageSize",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "10" } }, [
                    _vm._v("單頁顯示10項"),
                  ]),
                  _c("option", { attrs: { value: "20" } }, [
                    _vm._v("單頁顯示20項"),
                  ]),
                  _c("option", { attrs: { value: "40" } }, [
                    _vm._v("單頁顯示40項"),
                  ]),
                  _c("option", { attrs: { value: "100" } }, [
                    _vm._v("單頁顯示100項"),
                  ]),
                ]
              ),
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("forumMuteAdd", {
        ref: "forumMuteAdd",
        on: { updateList: _vm.resetDataList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-center" }, [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("使用者ID")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("暱稱")]),
      _c("th", { staticStyle: { "min-width": "80px" } }, [_vm._v("禁言天數")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("更新日期")]),
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("結束日期")]),
      _c("th", { staticStyle: { "min-width": "200px" } }, [_vm._v("備註")]),
      _c("th", [_vm._v("操作人")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }