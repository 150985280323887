var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("分類管理")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("分類管理"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button", disabled: _vm.selService == "" },
                on: {
                  click: function ($event) {
                    _vm.開啟新增列()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" " + _vm._s(_vm.$t("新增")) + " "),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selService,
                      expression: "selService",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { disabled: _vm.是否有資料修改中 },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selService = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.queryAll,
                    ],
                  },
                },
                _vm._l(_vm.projects, function (item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-bordered classTypeTable" },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c("th", { staticStyle: { width: "35%" } }, [
                        _vm._v(_vm._s(_vm.$t("分類ID"))),
                      ]),
                      _c("th", { staticStyle: { width: "35%" } }, [
                        _vm._v(_vm._s(_vm.$t("分類名稱"))),
                      ]),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(_vm._s(_vm.$t("排序")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(_vm._s(_vm.$t("啟用狀態")))]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v(_vm._s(_vm.$t("功能")))]
                      ),
                    ]),
                    _vm._l(_vm.list, function (item) {
                      return _c(
                        "tr",
                        { key: item.categoryID },
                        [
                          item.editor == 0
                            ? [
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.keyName) + " "),
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(" " + _vm._s(item.sort) + " "),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                        on: {
                                          click: function ($event) {
                                            _vm.啟停用(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: item.enable,
                                              expression: "item.enable",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          attrs: {
                                            "false-value": false,
                                            "true-value": true,
                                            name: "ckbx-style-8",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(item.enable)
                                              ? _vm._i(item.enable, null) > -1
                                              : item.enable,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = item.enable,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "enable",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "enable",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "enable", $$c)
                                              }
                                            },
                                          },
                                        }),
                                        _c("label", {
                                          attrs: { for: "check" + item },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "text-center" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          _vm.修改(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("修改")))]
                                  ),
                                ]),
                              ]
                            : [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.editor.keyName,
                                        expression: "editor.keyName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.editor.keyName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.editor,
                                          "keyName",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.editor.name,
                                        expression: "editor.name",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.editor.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.editor,
                                          "name",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", { attrs: { align: "center" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.editor.sort,
                                        expression: "editor.sort",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control text-center",
                                    attrs: { type: "number" },
                                    domProps: { value: _vm.editor.sort },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.editor,
                                          "sort",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                        on: {
                                          click: function ($event) {
                                            _vm.editor.enable =
                                              !_vm.editor.enable
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.editor.enable,
                                              expression: "editor.enable",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          attrs: {
                                            "false-value": false,
                                            "true-value": true,
                                            name: "ckbx-style-8",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.editor.enable
                                            )
                                              ? _vm._i(
                                                  _vm.editor.enable,
                                                  null
                                                ) > -1
                                              : _vm.editor.enable,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.editor.enable,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.editor,
                                                      "enable",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.editor,
                                                      "enable",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.editor,
                                                  "enable",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("label", {
                                          attrs: { for: "check" + item },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("td", { staticClass: "text-center" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success btn-save",
                                      on: {
                                        click: function ($event) {
                                          _vm.儲存(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("儲存")))]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-default",
                                      on: {
                                        click: function ($event) {
                                          _vm.取消(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("取消")))]
                                  ),
                                ]),
                              ],
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }