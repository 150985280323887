var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "RewardMessage" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("信件發獎")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("信件發獎")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _vm._m(0),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/NMJ/RewardMessageHistory" } },
                  [_vm._v("玩家信件查詢")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c(
              "form",
              {
                staticClass: "top-box",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    _vm.resetDataList()
                  },
                },
              },
              [
                _vm.$user.hasPermission("給獎訊息編輯")
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-block btn-warning btn-add fa fa-plus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addMessage()
                          },
                        },
                      },
                      [_vm._v(" 新增 ")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form-group", staticStyle: { width: "auto" } },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("發送時間："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-group date" },
                      [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c("DateRangePicker", {
                          attrs: {
                            propTimePicker: true,
                            propEndDate: _vm.local.endTime,
                            propStartDate: _vm.local.startTime,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(_vm.local, "endTime", $event)
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(_vm.local, "endTime", $event)
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(_vm.local, "startTime", $event)
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(_vm.local, "startTime", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.狀態,
                          expression: "searchData.狀態",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "狀態",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.def.mailState, function (option, i) {
                      return _c("option", { key: i, domProps: { value: i } }, [
                        _vm._v(_vm._s(option)),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._m(1),
              ]
            ),
            _c("div", { staticClass: "table-responsive mt-0" }, [
              _c("table", { staticClass: "table table-bordered text-center" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(2),
                    _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "8" } }, [
                            _vm._v(_vm._s(_vm.message)),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class: { "bg-gray-light": item.狀態 === 3 },
                            },
                            [
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.發送時間))),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.到期時間))),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.建立者))]),
                              _c("td", { staticClass: "text-left" }, [
                                _vm._v(_vm._s(item.信件標題)),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkContent(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看 ")]
                                ),
                                _vm.$user.hasPermission("給獎訊息編輯") &&
                                (item.狀態 === 0 || item.狀態 === 1)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        staticStyle: { "margin-left": ".35em" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editMessage(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 修改 ")]
                                    )
                                  : item.狀態 === 2
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        staticStyle: { "margin-left": ".35em" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkContent(item, true)
                                          },
                                        },
                                      },
                                      [_vm._v(" 明細 ")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        staticStyle: {
                                          "margin-left": ".35em",
                                          visibility: "hidden",
                                        },
                                      },
                                      [_vm._v("查看")]
                                    ),
                              ]),
                              _c(
                                "td",
                                { class: { "text-red": item.狀態 === 0 } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.def.mailState[item.狀態]) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("rewardMessageContent", { ref: "rewardMessageContent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "active" }, [
      _c("a", { staticStyle: { cursor: "pointer" } }, [_vm._v("信件發獎")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btns mb-10" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v(" 查詢 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("發送時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("有效時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("處理人員"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "15%" } }, [
        _vm._v("標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "5%" } }, [
        _vm._v("發送內容"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }