var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-10" },
    [
      _c("date-picker", {
        attrs: { config: _vm.options },
        on: { "dp-hide": _vm.showDatePickResult },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }