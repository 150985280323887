var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ClassTypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("統計表")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.$t("統計表")))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "box box-default" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "box-header with-border" },
                    [
                      _c("DaterangepickerForMessage", {
                        ref: "datePicker",
                        on: { ApplyDaterangepicker: _vm.applyDaterangepicker },
                      }),
                      _c("div", {
                        staticClass: "state icheckbox_flat-green",
                        class: this.排除自動關閉事件 ? "checked" : "",
                        on: {
                          click: function ($event) {
                            _vm.切換排除自動關閉事件()
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "line-height": "34px",
                            "vertical-align": "middle",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("排除自動關閉的事件")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticStyle: { height: "10px" } }),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-6 col-xs-6" }, [
                        _c(
                          "div",
                          {
                            staticClass: "small-box bg-aqua cusor_hand",
                            on: {
                              click: function ($event) {
                                _vm.重置圖表(_vm.ChartTypeEnum.事件)
                              },
                            },
                          },
                          [
                            _vm.進行事件數 == "none"
                              ? [
                                  _c("div", { staticClass: "inner" }, [
                                    _c("h3", [_vm._v(_vm._s(_vm.完成事件數))]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.$t("完成事件數"))),
                                    ]),
                                    _vm._m(0),
                                  ]),
                                ]
                              : [
                                  _c("div", { staticClass: "inner" }, [
                                    _c("h3", [
                                      _vm._v(
                                        _vm._s(_vm.進行事件數) +
                                          " / " +
                                          _vm._s(_vm.完成事件數)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.$t("進行事件數")) +
                                          " / " +
                                          _vm._s(_vm.$t("完成事件數"))
                                      ),
                                    ]),
                                    _vm._m(1),
                                  ]),
                                ],
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "col-lg-6 col-xs-6" }, [
                        _c(
                          "div",
                          {
                            staticClass: "small-box bg-aqua cusor_hand",
                            on: {
                              click: function ($event) {
                                _vm.重置圖表(_vm.ChartTypeEnum.時間)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "inner" }, [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.filterAvgFinishTime(_vm.平均完成時間)
                                  )
                                ),
                              ]),
                              _c("p", [_vm._v(_vm._s(_vm.$t("平均完成時間")))]),
                            ]),
                            _vm._m(2),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-header with-border" }, [
                  _c("i", { staticClass: "fa fa-bar-chart" }),
                  _c("h3", { staticClass: "box-title" }, [
                    _vm._v(_vm._s(_vm.$t("客服成員統計"))),
                  ]),
                ]),
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _vm.是否即時資料
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "34px",
                              "white-space": "nowrap",
                              "padding-right": "5px",
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "state icheckbox_flat-green",
                              class: this.是否執勤中 ? "checked" : "",
                              on: {
                                click: function ($event) {
                                  _vm.切換執勤中()
                                },
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("執勤中")) + " "),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticStyle: { width: "100%" } },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.客服列表,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "preserve-search": true,
                            placeholder: _vm.$t("請輸入姓名"),
                            label: "show",
                            "track-by": "Cs",
                            "preselect-first": false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function ({ values, search, isOpen }) {
                                return [
                                  values.length == _vm.客服列表.length &&
                                  _vm.客服列表.length != 0
                                    ? _c(
                                        "span",
                                        { staticClass: "multiselect__single" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("已選取所有客服"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.Cs,
                            callback: function ($$v) {
                              _vm.Cs = $$v
                            },
                            expression: "Cs",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "300px",
                        "overflow-y": "scroll",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            { staticStyle: { "background-color": "white" } },
                            [
                              !_vm.是否即時資料
                                ? _c("th", [_vm._v(_vm._s(_vm.$t("比較")))])
                                : _vm._e(),
                              _c("th", {}, [_vm._v(_vm._s(_vm.$t("姓名")))]),
                              _vm.是否即時資料
                                ? _c(
                                    "th",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.改變排序欄位("ProcessingCount")
                                        },
                                      },
                                    },
                                    [
                                      _vm.當前排序 == "ProcessingCount遞增"
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-fw fa-long-arrow-up",
                                          })
                                        : _vm._e(),
                                      _vm.當前排序 == "ProcessingCount遞減"
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-fw fa-long-arrow-down",
                                          })
                                        : _vm._e(),
                                      _vm.排序欄位 != "ProcessingCount"
                                        ? _c("i", {
                                            staticClass: "fa fa-fw fa-arrows-v",
                                            staticStyle: { color: "gray" },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("進行中")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "th",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.改變排序欄位("FinishCount")
                                    },
                                  },
                                },
                                [
                                  _vm.當前排序 == "FinishCount遞增"
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-fw fa-long-arrow-up",
                                      })
                                    : _vm._e(),
                                  _vm.當前排序 == "FinishCount遞減"
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-fw fa-long-arrow-down",
                                      })
                                    : _vm._e(),
                                  _vm.排序欄位 != "FinishCount"
                                    ? _c("i", {
                                        staticClass: "fa fa-fw fa-arrows-v",
                                        staticStyle: { color: "gray" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(_vm.$t("已完成")) + " "),
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.改變排序欄位("AvgFinishTime")
                                    },
                                  },
                                },
                                [
                                  _vm.當前排序 == "AvgFinishTime遞增"
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-fw fa-long-arrow-up",
                                      })
                                    : _vm._e(),
                                  _vm.當前排序 == "AvgFinishTime遞減"
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-fw fa-long-arrow-down",
                                      })
                                    : _vm._e(),
                                  _vm.排序欄位 != "AvgFinishTime"
                                    ? _c("i", {
                                        staticClass: "fa fa-fw fa-arrows-v",
                                        staticStyle: { color: "gray" },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("平均完成時間")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.filter客服成員統計表, function (item) {
                              return _c(
                                "tr",
                                {
                                  key: item.Cs,
                                  class: !_vm.是否即時資料 ? "cusor_hand" : "",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.加入比較(item)
                                    },
                                  },
                                },
                                [
                                  !_vm.是否即時資料
                                    ? _c("td", [
                                        _c("div", {
                                          staticClass:
                                            "state icheckbox_flat-green",
                                          class: item.compare ? "checked" : "",
                                        }),
                                      ])
                                    : _vm._e(),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.filter客服(item.Cs))),
                                  ]),
                                  _vm.是否即時資料
                                    ? _c("td", [
                                        _vm._v(_vm._s(item.ProcessingCount)),
                                      ])
                                    : _vm._e(),
                                  _c("td", [_vm._v(_vm._s(item.FinishCount))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.filterAvgFinishTime(
                                          item.AvgFinishTime
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            _vm.filter客服成員統計表.length == 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "5" } }, [
                                    _vm._v(_vm._s(_vm.$t("無資料"))),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", [
                  _c("div", { staticClass: "box-header with-border" }, [
                    _c("i", { staticClass: "fa fa-bar-chart" }),
                    _c("h3", { staticClass: "box-title" }, [
                      _vm._v(_vm._s(_vm.$t("圖表"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "box-body" },
                    [_c("highcharts", { attrs: { options: _vm.圖表物件 } })],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { ref: "loading", staticClass: "overlay" }, [
              _c("i", { staticClass: "fa fa-refresh fa-spin" }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon", staticStyle: { right: "100px" } }, [
      _c("i", { staticClass: "fa fa-fw fa-weixin" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon", staticStyle: { right: "100px" } }, [
      _c("i", { staticClass: "fa fa-fw fa-weixin" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon", staticStyle: { right: "100px" } }, [
      _c("i", { staticClass: "fa fa-fw fa-check-square" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }