var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "videoModal",
      staticClass: "modal fade",
      on: {
        click: function ($event) {
          return _vm.hideAlert()
        },
      },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  "aria-label": "Close",
                  "data-dismiss": "modal",
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    return _vm.hideAlert()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title text-center" }, [
              _vm._v(" " + _vm._s(_vm.user.暱稱) + " 影片播放 "),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "videoWrapper" }, [
              _vm.user.影片連結
                ? _c(
                    "video",
                    {
                      attrs: {
                        controls: "",
                        loop: "",
                        muted: "",
                        autoplay: "",
                        id: "Video",
                      },
                      domProps: { muted: true },
                    },
                    [
                      _c("source", {
                        attrs: { src: _vm.user.影片連結, type: "video/mp4" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("p", { staticClass: "text-center" }, [
              _vm._v(
                " 倍數:" +
                  _vm._s(_vm.user.倍數) +
                  "、分數:" +
                  _vm._s(_vm.user.分數) +
                  "、等級:" +
                  _vm._s(_vm.user.等級) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { "data-dismiss": "modal", type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hideAlert()
                  },
                },
              },
              [_vm._v(" 關閉影片 ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }