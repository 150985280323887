var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增-修改廠商")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "FirmSettingList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("廠商設定")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增-修改廠商 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.名稱,
                        expression: "edit.名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "名稱", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.顯示名稱,
                        expression: "edit.顯示名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.顯示名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "顯示名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.廠商編號,
                        expression: "edit.廠商編號",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.廠商編號 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "廠商編號",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.幣別,
                          expression: "edit.幣別",
                        },
                      ],
                      staticClass: "selPaymentType form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "幣別",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.地區列表, function (item) {
                      return _c(
                        "option",
                        { key: item.編號, domProps: { value: item.編號 } },
                        [_vm._v(" " + _vm._s(item.幣別) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.廠商代碼,
                        expression: "edit.廠商代碼",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.廠商代碼 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "廠商代碼",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(5),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.廠商金鑰,
                        expression: "edit.廠商金鑰",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.廠商金鑰 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "廠商金鑰",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(6),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "notice",
                        },
                        model: {
                          value: _vm.edit.是否通知,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "是否通知", $$v)
                          },
                          expression: "edit.是否通知",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "notice",
                        },
                        model: {
                          value: _vm.edit.是否通知,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "是否通知", $$v)
                          },
                          expression: "edit.是否通知",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-4 control-label" }, [
                  _vm.edit.是否通知 == 1
                    ? _c("span", { staticClass: "text-red" }, [_vm._v("*")])
                    : _vm._e(),
                  _vm._v(" 訂單狀態通知URL "),
                  _vm._m(7),
                  _vm._v(" ： "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      staticStyle: { "text-align": "center" },
                    },
                    [_vm._v(" 訂單完成後前端跳轉畫面使用 ")]
                  ),
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.訂單狀態通知URL,
                        expression: "edit.訂單狀態通知URL",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.訂單狀態通知URL },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "訂單狀態通知URL",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(8),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.訂單狀態後台通知URL,
                        expression: "edit.訂單狀態後台通知URL",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.訂單狀態後台通知URL },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "訂單狀態後台通知URL",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(9),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 顯示名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商編號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 幣別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商代碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商金鑰： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 是否通知： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "true",
        },
      },
      [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _vm._v(" 訂單狀態後台通知URL "),
      _c(
        "button",
        {
          staticClass: "btn dropdown-toggle",
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-expanded": "true",
          },
        },
        [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
      ),
      _vm._v(" ： "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          staticStyle: { "text-align": "center" },
        },
        [_vm._v(" 訂單完成後後端通知訊息用 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }