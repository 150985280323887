var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "editSN", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("使用狀態")]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "未使用",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isUsed,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isUsed", $$v)
                            },
                            expression: "edit.isUsed",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "已使用",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isUsed,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isUsed", $$v)
                            },
                            expression: "edit.isUsed",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "2",
                            message: "待確認",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isUsed,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isUsed", $$v)
                            },
                            expression: "edit.isUsed",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("備註")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.note,
                          expression: "edit.note",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.edit.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "note", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.isUsed == 1,
                        expression: "edit.isUsed == 1",
                      },
                    ],
                  },
                  [
                    _c("td", [_vm._v("使用時間")]),
                    _c("td", [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(1),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [_c("DatePicker")],
                            1
                          ),
                        ]),
                        _vm._m(2),
                      ]),
                    ]),
                  ]
                ),
                _c("tr", [
                  _c("td", [_vm._v("使用者")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.uId,
                          expression: "edit.uId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.edit.uId },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "uId", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.UpdateSN()
                },
              },
            },
            [_vm._v("確定")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("取消")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("序號修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }