var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box-body" }, [
        _c(
          "div",
          { staticClass: "top-box" },
          [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.查詢方式,
                      expression: "查詢方式",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.查詢方式 = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "一般查詢" } }, [
                    _vm._v("一般查詢"),
                  ]),
                  _c("option", { attrs: { value: "訂單查詢" } }, [
                    _vm._v("訂單查詢"),
                  ]),
                  _c("option", { attrs: { value: "訂單購買門號查詢" } }, [
                    _vm._v("訂單購買門號查詢"),
                  ]),
                ]
              ),
            ]),
            _vm.查詢方式 == "一般查詢"
              ? [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("超商："),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.列印廠商,
                            expression: "searchData.列印廠商",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "150px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "列印廠商",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "全家" } }, [
                          _vm._v("全家"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("年份："),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.發票年份,
                            expression: "searchData.發票年份",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "80px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "發票年份",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.年份options, function (year) {
                        return _c(
                          "option",
                          { key: year, domProps: { value: year } },
                          [_vm._v(_vm._s(year))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("期數："),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.發票期數,
                            expression: "searchData.發票期數",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "100px" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "發票期數",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _c("option", { attrs: { value: "1, 2月" } }, [
                          _vm._v("1.2月"),
                        ]),
                        _c("option", { attrs: { value: "3, 4月" } }, [
                          _vm._v("3.4月"),
                        ]),
                        _c("option", { attrs: { value: "5, 6月" } }, [
                          _vm._v("5.6月"),
                        ]),
                        _c("option", { attrs: { value: "7, 8月" } }, [
                          _vm._v("7.8月"),
                        ]),
                        _c("option", { attrs: { value: "9, 10月" } }, [
                          _vm._v("9.10月"),
                        ]),
                        _c("option", { attrs: { value: "11, 12月" } }, [
                          _vm._v("11.12月"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("列印時間："),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "control-label",
                        staticStyle: { "padding-top": "0px", width: "160px" },
                      },
                      [
                        _c("date-picker", {
                          attrs: {
                            config: {
                              format: "YYYY-MM-DD",
                              showTodayButton: true,
                              showClear: true,
                              useCurrent: false,
                              sideBySide: true,
                              locale: "zh-tw",
                            },
                          },
                          model: {
                            value: _vm.searchData.起始列印時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchData, "起始列印時間", $$v)
                            },
                            expression: "searchData.起始列印時間",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "control-label",
                        staticStyle: { margin: "0px 5px" },
                      },
                      [_vm._v(" ~ ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "control-label",
                        staticStyle: { "padding-top": "0px", width: "160px" },
                      },
                      [
                        _c("date-picker", {
                          attrs: {
                            config: {
                              format: "YYYY-MM-DD",
                              showTodayButton: true,
                              showClear: true,
                              useCurrent: false,
                              sideBySide: true,
                              locale: "zh-tw",
                            },
                          },
                          model: {
                            value: _vm.searchData.結束列印時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchData, "結束列印時間", $$v)
                            },
                            expression: "searchData.結束列印時間",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm.查詢方式 == "訂單查詢"
              ? [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.訂單編號,
                          expression: "searchData.訂單編號",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "220px" },
                      attrs: { type: "text", placeholder: "訂單編號" },
                      domProps: { value: _vm.searchData.訂單編號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "訂單編號",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.購買門號,
                          expression: "searchData.購買門號",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { width: "220px" },
                      attrs: { type: "text", placeholder: "訂單購買門號" },
                      domProps: { value: _vm.searchData.購買門號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "購買門號",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ],
            _c("br"),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.onSearch(1, _vm.searchData)
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: {
                    disabled: !(_vm.lastQueryCommand && _vm.total > 0),
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.匯出結果(_vm.lastQueryCommand)
                    },
                  },
                },
                [_vm._v(" 匯出查詢結果 ")]
              ),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "table-responsive mt-10" }, [
          _c(
            "table",
            { staticClass: "table table-hover table-bordered min-800" },
            [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm._l(_vm.list, function (item, sn) {
                    return _c("tr", { key: item.編號 }, [
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.列印廠商)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.列印門市)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.發票年份)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.發票期數)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.訂單編號)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.購買門號)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.發票號碼)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.列印時間)),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        item.列印狀態 == 1
                          ? _c("span", [_vm._v(_vm._s(item.列印狀態名稱))])
                          : item.列印狀態 == 2
                          ? _c("span", { staticClass: "text-green" }, [
                              _vm._v(_vm._s(item.列印狀態名稱)),
                            ])
                          : item.列印狀態 == 3 || item.列印狀態 == 4
                          ? _c("span", { staticClass: "text-aqua" }, [
                              _vm._v(_vm._s(item.列印狀態名稱)),
                            ])
                          : _c("span", { staticClass: "text-red" }, [
                              _vm._v(_vm._s(item.列印狀態名稱)),
                            ]),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(item.備註)),
                      ]),
                    ])
                  }),
                  _vm.list.length == 0
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "10" },
                          },
                          [_vm._v("沒有資料")]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "box-footer text-center no-border" },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageSize,
                  expression: "pageSize",
                },
              ],
              staticClass: "form-control page-size",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.pageSize = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: 10 } }, [
                _vm._v("單頁顯示10項"),
              ]),
              _c("option", { domProps: { value: 20 } }, [
                _vm._v("單頁顯示20項"),
              ]),
              _c("option", { domProps: { value: 40 } }, [
                _vm._v("單頁顯示40項"),
              ]),
              _c("option", { domProps: { value: 100 } }, [
                _vm._v("單頁顯示100項"),
              ]),
            ]
          ),
          _c("Pagination", {
            ref: "pagination",
            attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
            on: { pageMethod: _vm.onPageChange },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", [_vm._v("超商")]),
      _c("th", [_vm._v("門市")]),
      _c("th", [_vm._v("發票年份")]),
      _c("th", [_vm._v("發票期數")]),
      _c("th", [_vm._v("訂單編號")]),
      _c("th", [_vm._v("訂單購買門號")]),
      _c("th", [_vm._v("發票號碼")]),
      _c("th", [_vm._v("列印時間")]),
      _c("th", [_vm._v("列印狀態")]),
      _c("th", [_vm._v("備註")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }