var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ServerIsOnline != "Connected"
    ? _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "login-box-body",
            staticStyle: {
              width: "400px",
              "margin-left": "auto",
              "margin-right": "auto",
            },
          },
          [
            _c("p", { staticClass: "login-box-msg" }, [
              _vm._v(_vm._s(_vm.$t(`ServerStatus-${_vm.ServerIsOnline}`))),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ServerIsOnline == "Disconnected",
                    expression: "ServerIsOnline == 'Disconnected'",
                  },
                ],
                staticClass: "login-box-msg",
              },
              [_vm._v(_vm._s(_vm.$t(`請重新整理以建立連線`)))]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }