var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CollectTypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("收藏管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("收藏管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addType()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                attrs: { id: "classTypes" },
              },
              [
                _c("tbody", [
                  _vm._m(0),
                  _c("tr", [
                    _c("td", [_vm._v("sport1")]),
                    _c("td", [_vm._v("奧運好手")]),
                    _vm._m(1),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        attrs: { align: "center", "data-fun": "啟用狀態" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ckbx-style-8 ckbx toggleInput" },
                          [
                            _c("input", {
                              attrs: { name: "ckbx-style-8", type: "checkbox" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeStatus(_vm.item)
                                },
                              },
                            }),
                            _c("label"),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        attrs: { "data-fun": "功能" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                return _vm.change(_vm.item)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _vm._v("   "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function ($event) {
                                return _vm.deleteType(_vm.item, _vm.index)
                              },
                            },
                          },
                          [_vm._v("刪除")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "10%" } },
        [_vm._v("分類ID")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "50%" } },
        [_vm._v("分類名稱")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "10%" } },
        [_vm._v("公用/私人")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("啟用狀態")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "20%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("select", { staticClass: "w100", attrs: { disabled: "" } }, [
        _c("option", { attrs: { value: "public" } }, [_vm._v("公開")]),
        _c("option", { attrs: { value: "private" } }, [_vm._v("私人")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }