var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("最新消息列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _vm.id
          ? _c(
              "li",
              { staticClass: "active" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { params: { id: null, searchData: _vm.searchData } },
                    },
                  },
                  [_vm._v("最新消息列表")]
                ),
              ],
              1
            )
          : _c("li", { staticClass: "active" }, [_vm._v("最新消息列表")]),
        _vm.id
          ? _c("li", { staticClass: "active" }, [
              _vm._v(_vm._s(_vm.id != "New" ? "編輯" : "新增") + "內容"),
            ])
          : _vm._e(),
      ]),
    ]),
    !_vm.id
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "top-box" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-block btn-warning btn-add fa fa-plus",
                      attrs: {
                        to: {
                          params: { id: "New", searchData: _vm.searchData },
                        },
                      },
                    },
                    [_vm._v(" 新增訊息 ")]
                  ),
                  _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.classTypeId,
                            expression: "searchData.classTypeId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "selectUnit", required: "" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.clickSearch,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("全部"),
                        ]),
                        _vm._l(_vm.newsTypes.datas, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(" " + _vm._s(item.className) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.keyword,
                          expression: "searchData.keyword",
                        },
                      ],
                      staticClass: "form-control pull-right",
                      attrs: {
                        type: "text",
                        name: "table_search",
                        placeholder: "搜尋關鍵字",
                      },
                      domProps: { value: _vm.searchData.keyword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "keyword",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "input-group-btn" }, [
                      _c("button", {
                        staticClass: "btn btn-default fa fa-search",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.clickSearch()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _c("p", { staticClass: "data-num" }, [
                      (_vm.searchShow = 1)
                        ? _c("span", [_vm._v("資料總筆數：")])
                        : _vm._e(),
                      _vm.searchShow != 1
                        ? _c("span", [_vm._v("搜尋結果筆數：")])
                        : _vm._e(),
                      _c("span", { attrs: { id: "total" } }, [
                        _vm._v(_vm._s(_vm.news.total)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
                _c(
                  "table",
                  { staticClass: "table table-hover table-bordered" },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm.news.total <= 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("沒有資料"),
                              ]),
                            ])
                          : _vm._l(_vm.news.datas, function (item, index) {
                              return _c("tr", { key: item.id }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.FormatTime(item.publishTime))
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { "border-right": "0" },
                                  },
                                  [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.isTop === 1,
                                          expression: "item.isTop === 1",
                                        },
                                      ],
                                      staticClass: "fa fa-thumb-tack fa-lg",
                                      staticStyle: {
                                        transform: "rotate(45deg)",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-left",
                                    staticStyle: { "border-left": "0" },
                                  },
                                  [_vm._v(_vm._s(item.detailTopTitle))]
                                ),
                                _c("td", [_vm._v(_vm._s(item.classTypeName))]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success",
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("預覽")]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editorItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _vm._v("   "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.news.total > 0,
                    expression: "news.total > 0",
                  },
                ],
                staticClass: "box-footer text-center no-border",
              },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    total: _vm.news.total,
                    pageLimitCount: _vm.searchData.pageSize,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]),
        ])
      : _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "top-box with-border" },
                [
                  _c(
                    "h4",
                    { staticClass: "pull-left", attrs: { id: "title" } },
                    [_vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-block btn-default btn-cog fa fa-reply",
                      attrs: {
                        to: {
                          params: {
                            id: null,
                            tab: _vm.tab,
                            searchData: _vm.searchData,
                          },
                        },
                      },
                    },
                    [_vm._v(" 返回列表 ")]
                  ),
                ],
                1
              ),
              _c(
                "form",
                {
                  staticClass: "form-horizontal",
                  attrs: { name: "announcementForm" },
                },
                [
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.classTypeId,
                                expression: "edit.classTypeId",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "classTypeId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "disabled", value: "" } },
                              [_vm._v("請選擇分類")]
                            ),
                            _vm._l(_vm.newsTypes.datas, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: item.classTypeId },
                                },
                                [_vm._v(" " + _vm._s(item.className) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.detailTopTitle,
                              expression: "edit.detailTopTitle",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputTitle",
                            required: "",
                          },
                          domProps: { value: _vm.edit.detailTopTitle },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "detailTopTitle",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("p", { staticClass: "help-block" }, [
                          _vm._v(
                            "標題上限 " +
                              _vm._s(_vm.edit.detailTopTitle.length) +
                              "/50 字"
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "是",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isTop", _vm._n($$v))
                                },
                                expression: "edit.isTop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "否",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isTop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isTop", _vm._n($$v))
                                },
                                expression: "edit.isTop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          _c("DateAndTimePicker", {
                            model: {
                              value: _vm.edit.publishTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "publishTime", $$v)
                              },
                              expression: "edit.publishTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group image-part" }, [
                      _vm._m(5),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          attrs: {
                            type: "file",
                            id: "AdPic",
                            accept: "image/webp, image/jpeg, image/png",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event)
                            },
                          },
                        }),
                        _c("p", { staticClass: "help-block text-red" }, [
                          _vm._v("※檔案尺寸900x500，小於300Kb，格式 webp、jpg"),
                        ]),
                        _c("img", {
                          staticClass: "help-block",
                          staticStyle: { "max-height": "200px" },
                          attrs: {
                            src: _vm.previewImg,
                            draggable: "false",
                            alt: "",
                            hidden: !_vm.previewImg,
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(6),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "editor" },
                          [
                            _c("tinymce", {
                              attrs: { propLang: _vm.editLang, height: "400" },
                              model: {
                                value: _vm.edit.detailTopInfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "detailTopInfo", $$v)
                                },
                                expression: "edit.detailTopInfo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "box-footer" }, [
                    _c("div", { staticClass: "btns" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-lg fa fa-eye",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save(true)
                            },
                          },
                        },
                        [_vm._v(" 預覽 ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-lg fa fa-save",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save(false)
                            },
                          },
                        },
                        [_vm._v(" 確認送出 ")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "20%" } }, [
        _vm._v("發布時間"),
      ]),
      _c(
        "th",
        {
          staticStyle: { "min-width": "150px", width: "40%" },
          attrs: { colspan: "2" },
        },
        [_vm._v("標題")]
      ),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "200px", width: "10%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 類別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 公告標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 置頂： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發布時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "AdPic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內文： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }