var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增/修改廠商直儲金流")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(" 廠商管理 ")]),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "FirmPayFlowList",
                    query: this.$route.query,
                  })
                },
              },
            },
            [_vm._v("廠商直儲金流")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增/修改廠商直儲金流 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.廠商設定主檔編號,
                          expression: "edit.廠商設定主檔編號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: _vm.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "廠商設定主檔編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.廠商設定List, function (item) {
                      return _c(
                        "option",
                        { key: item.編號, domProps: { value: item.編號 } },
                        [_vm._v(" " + _vm._s(item.顯示名稱) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.平台遊戲編號,
                          expression: "edit.平台遊戲編號",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "平台遊戲編號",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("不設定"),
                      ]),
                      _vm._l(_vm.廠商設定List, function (item) {
                        return _c(
                          "option",
                          {
                            key: item.編號,
                            domProps: { value: item.廠商編號 },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.顯示名稱) +
                                " " +
                                _vm._s(item.廠商編號) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.直儲名稱,
                        expression: "edit.直儲名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.直儲名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "直儲名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.商品代碼,
                        expression: "edit.商品代碼",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.商品代碼 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "商品代碼",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-4 control-label" }, [
                  _vm._v(" 第三方物品編號： "),
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.第三方物品編號,
                        expression: "edit.第三方物品編號",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.第三方物品編號 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "第三方物品編號",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-4 control-label" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(" 廠商產品名稱 "),
                  _vm._m(4),
                  _vm._v(" ： "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu common" },
                    [
                      _vm._v(" 顯示在「付款資訊頁-付款品項」"),
                      _c("br"),
                      _vm._v(" 後端帶入儲值金額=<儲值金額> "),
                      _c("br"),
                      _vm._v(" EX： "),
                      _c("br"),
                      _vm._v(" 後端儲值金額設100， "),
                      _c("br"),
                      _vm._v(" 廠商產品名稱輸入"),
                      _c("font", { staticClass: "text-red" }, [
                        _vm._v("<儲值金額>點"),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" 前端會顯示"),
                      _c("font", { staticClass: "text-red" }, [_vm._v("100")]),
                      _vm._v("點 "),
                      _c("br"),
                      _c("hr"),
                      _vm._v(" 官網帶入點數=<point> "),
                      _c("br"),
                      _vm._v(" 官網帶入價格=<price> "),
                      _c("br"),
                      _c("br"),
                      _vm._v(" 範例： "),
                      _c("br"),
                      _vm._v(" 付款品項顯示：yoe數位卡"),
                      _c("font", { staticClass: "text-blue" }, [_vm._v("50")]),
                      _vm._v("點(單價$"),
                      _c("font", { staticClass: "text-red" }, [_vm._v("50")]),
                      _vm._v(" *1) "),
                      _c("br"),
                      _vm._v(" 設定需設為：yoe數位卡"),
                      _c("font", { staticClass: "text-blue" }, [
                        _vm._v("<point>"),
                      ]),
                      _vm._v("點(單價$"),
                      _c("font", { staticClass: "text-red" }, [
                        _vm._v(" <price>"),
                      ]),
                      _vm._v("*1) "),
                      _c("br"),
                      _c("img", {
                        attrs: { src: "/download/IPS_illustrate01.png" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.廠商產品名稱,
                        expression: "edit.廠商產品名稱",
                        modifiers: { trim: true },
                      },
                      {
                        name: "AddArgInput",
                        rawName: "v-AddArgInput",
                        value: {
                          model: "edit.廠商產品名稱",
                          format: _vm.AddArgFormat,
                        },
                        expression:
                          "{ model: 'edit.廠商產品名稱', format: AddArgFormat }",
                      },
                    ],
                    ref: "廠商Input",
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "" },
                    domProps: { value: _vm.edit.廠商產品名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "廠商產品名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c("span", { staticClass: "input-group-btn widthAuto" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "AddArgBtn",
                            rawName: "v-AddArgBtn",
                            value: {
                              insertText: "<儲值金額>",
                              ref: "廠商Input",
                              model: "edit.廠商產品名稱",
                            },
                            expression:
                              "{ insertText: '<儲值金額>', ref: '廠商Input', model: 'edit.廠商產品名稱' }",
                          },
                        ],
                        staticClass: "btn btn-info btn-flat",
                        attrs: { type: "button" },
                      },
                      [_vm._v("添加<儲值金額>")]
                    ),
                  ]),
                  _c("span", { staticClass: "input-group-btn widthAuto" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "AddArgBtn",
                            rawName: "v-AddArgBtn",
                            value: {
                              insertText: "<point>",
                              ref: "廠商Input",
                              model: "edit.廠商產品名稱",
                            },
                            expression:
                              "{ insertText: '<point>', ref: '廠商Input', model: 'edit.廠商產品名稱' }",
                          },
                        ],
                        staticClass: "btn btn-info btn-flat",
                        attrs: { type: "button" },
                      },
                      [_vm._v("添加<point>")]
                    ),
                  ]),
                  _c("span", { staticClass: "input-group-btn widthAuto" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "AddArgBtn",
                            rawName: "v-AddArgBtn",
                            value: {
                              insertText: "<price>",
                              ref: "廠商Input",
                              model: "edit.廠商產品名稱",
                            },
                            expression:
                              "{ insertText: '<price>', ref: '廠商Input', model: 'edit.廠商產品名稱' }",
                          },
                        ],
                        staticClass: "btn btn-info btn-flat",
                        attrs: { type: "button" },
                      },
                      [_vm._v("添加<price>")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(5),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.erP名稱,
                        expression: "edit.erP名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.erP名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "erP名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(6),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.直儲模式,
                          expression: "edit.直儲模式",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit,
                            "直儲模式",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.直儲模式, function (item) {
                      return _c(
                        "option",
                        { key: item.編號, domProps: { value: item.編號 } },
                        [_vm._v(" " + _vm._s(item.名稱) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(7),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.廠商支付編號,
                            expression: "edit.廠商支付編號",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "廠商支付編號",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.filter廠商支付設定List, function (item) {
                        return _c(
                          "option",
                          { key: item.編號, domProps: { value: item.編號 } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.金流帳號Key[item.金流帳號編號].名稱
                                ) +
                                "-" +
                                _vm._s(
                                  _vm.金流支付設定Key[item.金流支付設定編號]
                                    .支付名稱
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("PayInfo", {
                      ref: "upSetting",
                      attrs: {
                        上層設定: _vm.上層設定,
                        金流設定主檔Key: _vm.金流設定主檔Key,
                        金流帳號Key: _vm.金流帳號Key,
                        金流支付設定Key: _vm.金流支付設定Key,
                        地區列表Key: _vm.地區列表Key,
                        金流類型設定Key: _vm.金流類型設定Key,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.上層設定
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-4 control-label" }, [
                      _vm._v("支付品號： "),
                    ]),
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group margin",
                          staticStyle: { margin: "0px" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.edit.支付品號,
                                expression: "edit.支付品號",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.支付品號placeholder(
                                _vm.上層設定.支付品號,
                                _vm.金流支付設定Key[
                                  _vm.上層設定.金流支付設定編號
                                ].支付品號
                              ),
                            },
                            domProps: { value: _vm.edit.支付品號 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "支付品號",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("span", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-flat",
                                staticStyle: { width: "200px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.edit.支付品號 =
                                      _vm.金流支付設定Key[
                                        _vm.上層設定.金流支付設定編號
                                      ].支付品號
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "品號(金流層)：" +
                                    _vm._s(
                                      _vm.金流支付設定Key[
                                        _vm.上層設定.金流支付設定編號
                                      ].支付品號
                                    )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.上層設定
                ? _c("div", { staticClass: "form-group" }, [
                    _vm._m(8),
                    _vm.是否檢查儲值金額
                      ? _c(
                          "div",
                          { staticClass: "col-sm-8" },
                          [
                            _c("AddItemForm", {
                              attrs: { 儲值金額List: _vm.edit.儲值金額 },
                              on: {
                                addOption: _vm.addOption,
                                delOption: _vm.delOption,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "col-sm-8" }, [_vm._m(9)]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(10),
                _vm.是否檢查儲值金額
                  ? _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "否",
                              name: "自訂金額",
                            },
                            model: {
                              value: _vm.edit.是否自訂金額,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否自訂金額", $$v)
                              },
                              expression: "edit.是否自訂金額",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "是",
                              name: "自訂金額",
                            },
                            model: {
                              value: _vm.edit.是否自訂金額,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否自訂金額", $$v)
                              },
                              expression: "edit.是否自訂金額",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "col-sm-8" }, [_vm._m(11)]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.限制開關,
                      expression: "限制開關",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _vm._m(12),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c("LimitControler", {
                        ref: "comp",
                        attrs: { 額外限制List: _vm.edit.額外限制 },
                        on: {
                          "update:額外限制List": function ($event) {
                            return _vm.$set(_vm.edit, "額外限制", $event)
                          },
                          "update:額外限制list": function ($event) {
                            return _vm.$set(_vm.edit, "額外限制", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(13),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 廠商： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 平台遊戲編號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 直儲名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 商品代碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "true",
        },
      },
      [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" ERP名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 直儲模式： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流帳號-金流支付： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 儲值金額 "),
      _c(
        "button",
        {
          staticClass: "btn dropdown-toggle",
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-expanded": "true",
          },
        },
        [_c("span", [_c("i", { staticClass: "fa fa-fw fa-question" })])]
      ),
      _vm._v(" ： "),
      _c("div", { staticClass: "dropdown-menu" }, [
        _vm._v(" 如果儲值金額不設，此值會由遊戲帶入 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "input-group margin", staticStyle: { margin: "0px" } },
      [
        _c("span", { staticClass: "form-control noline" }, [
          _vm._v("此金流類型不需設定"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 自訂金額： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "input-group margin", staticStyle: { margin: "0px" } },
      [
        _c("span", { staticClass: "form-control noline" }, [
          _vm._v("此金流類型不需設定"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 額外限制： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }