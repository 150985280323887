var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "App" } },
    [
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      _c("AlertBox", { ref: "alertBox" }),
      _c("AlertAsyncBox"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }