var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲操作說明列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲操作說明管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲操作說明列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增說明 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add mr-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.clearCatch()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 清除Catch ")]
            ),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _vm._v(" 資料總筆數: "),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    !_vm.total
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "9" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.datas, function (item) {
                      return _c("tr", { key: item.id }, [
                        _c("td", { attrs: { align: "center" } }, [
                          _vm._v(" " + _vm._s(item.version) + " "),
                        ]),
                        _c("td", { attrs: { align: "center" } }, [
                          _c("img", {
                            attrs: { src: _vm.getListImgUrl(item) },
                          }),
                        ]),
                        _c("td", { attrs: { align: "left" } }, [
                          _c("span", {
                            staticClass: "textEllipsis",
                            domProps: { innerHTML: _vm._s(item.content) },
                          }),
                        ]),
                        _c("td", { staticClass: "td-sort text-nowrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.sort,
                                expression: "item.sort",
                              },
                            ],
                            staticClass: "t-c",
                            attrs: {
                              type: "number",
                              min: "1",
                              disabled: item.isStop == 1,
                            },
                            domProps: { value: item.sort },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "sort", $event.target.value)
                              },
                            },
                          }),
                          _c("br"),
                          item.isStop != 1
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-warning btn-add btn-upDate mt-10",
                                  staticStyle: { width: "70px" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateOrder(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-up" }),
                                  _vm._v(" 更新 "),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.editorItem(item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-pencil" }),
                              _vm._v(" 修改 "),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning mt-5",
                              on: {
                                click: function ($event) {
                                  return _vm.copyItem(item.gid)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-copy" }),
                              _vm._v(" 複製 "),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mt-5",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-close" }),
                              _vm._v(" 刪除 "),
                            ]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total,
                expression: "total",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
                type2: true,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "50px", width: "3%" } }, [
        _vm._v("版本"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("圖片"),
      ]),
      _c("th", { staticStyle: { "min-width": "150px", width: "15%" } }, [
        _vm._v("內容"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { "min-width": "100px", width: "3%" },
        },
        [_vm._v("排序")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "3%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }