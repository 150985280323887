var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lableFloat", attrs: { id: "RobotList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("FB機器人 - 社群管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FBRobot/RobotList" } }, [
              _vm._v("FB機器人"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("社群管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { attrs: { id: "fb-root" } }),
            _c("div", {
              staticClass: "fb-login-button",
              attrs: {
                "data-width": "",
                "data-size": "medium",
                "data-button-type": "login_with",
                "data-layout": "rounded",
                "data-auto-logout-link": "false",
                "data-use-continue-as": "false",
              },
            }),
            _vm.isConnected
              ? _c("div", [_vm._v("名字: " + _vm._s(_vm.fbName))])
              : _vm._e(),
            !_vm.isConnected
              ? _c(
                  "div",
                  [
                    _c("facebook-login", {
                      staticClass: "button",
                      attrs: { appId: "372739654154496" },
                      on: {
                        login: _vm.onLogin,
                        logout: _vm.onLogout,
                        "sdk-loaded": _vm.sdkLoaded,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._m(0),
          ]),
          _vm.isConnected
            ? _c("div", { staticClass: "table-responsive mt-10" }, [
                _c("table", { staticClass: "table table-bordered min1000" }, [
                  _c(
                    "tbody",
                    [
                      _vm._m(1),
                      _vm._l(_vm.fanList, function (item, index) {
                        return _c("tr", { key: item.id }, [
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    `https://www.facebook.com/` + item.粉專ID,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(item.粉專名稱) + " "),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(_vm._s(item.代號)),
                                ]),
                              ]
                            ),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            item.綁定YN == 1
                              ? _c("span", [_vm._v("已綁定")])
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mb-5",
                                    on: {
                                      click: function ($event) {
                                        return _vm.reAuthorize(item.代號)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-chain" }),
                                    _vm._v(" 取得臉書授權綁定 "),
                                  ]
                                ),
                            _vm._v("   "),
                          ]),
                        ])
                      }),
                      _vm.fanList == 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "9" },
                              },
                              [_vm._v("目前無連接任何粉絲專業")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          "text-align": "left",
          "margin-top": "5px",
          "margin-bottom": "5px",
        },
      },
      [
        _vm._v(
          " *要增加管理粉絲團，請先登入有FB粉絲團的FB帳號，以取得該FB粉絲團的權限 "
        ),
        _c("br"),
        _vm._v(" *登入後，即可看到連結的粉絲專頁資訊 "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "200px", width: "50%" } }, [
        _vm._v("社群"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }