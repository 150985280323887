var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "popForm",
      staticClass: "modal fade",
      attrs: { "data-backdrop": "static" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { "aria-label": "Close", type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("填寫資料：" + _vm._s(_vm.edit.訂單編號)),
            ]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("是否捐贈")]),
              _c("div", [
                _c("div", {
                  staticClass: "state icheckbox_flat-green",
                  class: _vm.edit.是否捐贈 == 1 ? "checked" : "",
                  on: {
                    click: function ($event) {
                      _vm.edit.是否捐贈 = _vm.edit.是否捐贈 == 1 ? 0 : 1
                    },
                  },
                }),
              ]),
            ]),
            _vm.edit.是否捐贈 == 1
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("愛心條碼")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.愛心條碼,
                        expression: "edit.愛心條碼",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "愛心條碼" },
                    domProps: { value: _vm.edit.愛心條碼 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "愛心條碼",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.edit.是否捐贈 == 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("收件人")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.收件人,
                        expression: "edit.收件人",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "收件人" },
                    domProps: { value: _vm.edit.收件人 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "收件人", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.edit.是否捐贈 == 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("收件地址")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.收件地址,
                        expression: "edit.收件地址",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "收件地址" },
                    domProps: { value: _vm.edit.收件地址 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "收件地址",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.edit.是否捐贈 == 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("連絡電話")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.連絡電話,
                        expression: "edit.連絡電話",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "連絡電話" },
                    domProps: { value: _vm.edit.連絡電話 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "連絡電話",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.edit.是否捐贈 == 0
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("信箱")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.信箱,
                        expression: "edit.信箱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "信箱" },
                    domProps: { value: _vm.edit.信箱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.edit, "信箱", $event.target.value.trim())
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("備註")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.edit.備註,
                    expression: "edit.備註",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "備註" },
                domProps: { value: _vm.edit.備註 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.edit, "備註", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.loading, type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [_vm._v("確認修改")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_vm._v("關閉")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }