var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MemberData" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("會員資料")))]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v(_vm._s(_vm.$t("首頁"))),
              ]),
            ],
            1
          ),
          _vm.name
            ? _c(
                "li",
                { staticClass: "active" },
                [
                  _c("router-link", { attrs: { to: _vm.goBack } }, [
                    _vm._v(_vm._s(_vm.$t("會員資料"))),
                  ]),
                ],
                1
              )
            : _c("li", { staticClass: "active" }, [
                _vm._v(_vm._s(_vm.$t("會員資料"))),
              ]),
          _vm.name
            ? _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.name))])
            : _vm._e(),
        ]),
      ]),
      !_vm.name
        ? _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary" }, [
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "top-box clearfix",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          _vm.searchData.pageNum = 1
                          _vm.getDataList()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "select-group mb-10 mr-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchData.type,
                                expression: "searchData.type",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.searchData,
                                    "type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  _vm.searchData.value = ""
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.selectType, function (value, key) {
                            return _c("option", { domProps: { value: key } }, [
                              _vm._v(_vm._s(_vm.$t(value))),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "input-group mb-10 mr-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.value,
                              expression: "searchData.value",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              _vm.selectType[_vm.searchData.type]
                            ),
                          },
                          domProps: { value: _vm.searchData.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "value",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-10",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("查詢")) + " ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              disabled: _vm.datas.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportPlayers()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                        ),
                      ]),
                      _vm.$route.params.back
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-default pull-right mb-10 ml-10",
                              attrs: { to: _vm.$route.params.back },
                            },
                            [
                              _c("i", { staticClass: "fa fa-arrow-left" }),
                              _vm._v(" " + _vm._s(_vm.$t("返回")) + " "),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "table-responsive mt-0" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered text-center" },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("最後登入日期")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "105px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("暱稱")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "120px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("綁定門號")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "90px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("登入方式")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("等級")))]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "230px",
                                  width: "5%",
                                },
                                style: {
                                  minWidth: /^(en)/.test(
                                    _vm.$root.$options.i18n.locale
                                  )
                                    ? "320px"
                                    : "230px",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("玩家歷程")) + " ")]
                            ),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "min-width": "80px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("停權狀態")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.loading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查詢中"))),
                                  ]),
                                ])
                              : _vm.message
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t(_vm.message))),
                                  ]),
                                ])
                              : _vm.datas.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.$t("查無資料"))),
                                  ]),
                                ])
                              : _vm._l(_vm.datas, function (player, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.uiDate(player.最後登入))
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(player.暱稱))]),
                                    _c("td", [
                                      player.綁定門號
                                        ? _c("span", [
                                            _vm._v(_vm._s(player.綁定門號)),
                                          ])
                                        : _c(
                                            "button",
                                            {
                                              staticClass: "btn-link",
                                              on: {
                                                click: function ($event) {
                                                  _vm
                                                    .getDetails(player)
                                                    .then(
                                                      (res) =>
                                                        (player.綁定門號 =
                                                          res.綁定門號 || "-")
                                                    )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("查看")))]
                                          ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.$t(player.登入方式))),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(player.level))]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "grid",
                                            "grid-template-columns": "1fr 1fr",
                                            gap: "5px",
                                          },
                                          attrs: {
                                            "data-style": {
                                              gridTemplateColumns: /^(en)/.test(
                                                _vm.$root.$options.i18n.locale
                                              )
                                                ? "1fr"
                                                : "1fr 1fr",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-purple",
                                              attrs: {
                                                to: {
                                                  params: { name: player.暱稱 },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-user-circle-o fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("會員資料")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-primary",
                                              attrs: {
                                                to: {
                                                  name: "XswGameHistory",
                                                  query: { nick: player.暱稱 },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-history fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("玩家歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-success",
                                              attrs: {
                                                to: {
                                                  name: "TransactionInfoList",
                                                  query: {
                                                    userId: player.暱稱,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-dollar fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("儲值歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "btn btn-warning",
                                              attrs: {
                                                to: {
                                                  name: "XswGameMessage",
                                                  query: { nick: player.暱稱 },
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-envelope fa-fw",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("信件歷程")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "max-content",
                                            margin: "0 auto",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          player.停權狀態 != 0
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.banStatus(
                                                      0,
                                                      player.停權狀態,
                                                      player.停權截止
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          player.禁言狀態 != 0
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.banStatus(
                                                      1,
                                                      player.禁言狀態,
                                                      player.禁言截止
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          player.停權狀態 == 0 &&
                                          player.禁言狀態 == 0
                                            ? _c("p", [
                                                _vm._v(_vm._s(_vm.$t("正常"))),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.can控管
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "mt-10",
                                              staticStyle: {
                                                width: "max-content",
                                                margin: "0 auto",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-info",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.memberModerate(
                                                        player
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.$t("控管")))]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        player.停權狀態 != 0 ||
                                                        player.禁言狀態 != 0,
                                                      expression:
                                                        "player.停權狀態 != 0 || player.禁言狀態 != 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-danger ml-5",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.memberModerate(
                                                        player,
                                                        true,
                                                        player.停權狀態 == 0
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.$t("解鎖")))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "box-footer" },
                    [
                      _c("Pagination", {
                        attrs: {
                          pageLimitCount: _vm.searchData.pageSize,
                          total: _vm.total,
                        },
                        on: { pageMethod: _vm.onPageChange },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary mb-10" }, [
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "div",
                    { staticClass: "top-box mb-10" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: { to: _vm.goBack },
                        },
                        [
                          _c("i", { staticClass: "fa fa-reply" }),
                          _vm._v(" " + _vm._s(_vm.$t("返回")) + " "),
                        ]
                      ),
                      _c(
                        "h2",
                        {
                          staticClass: "pull-left mb-5 mt-5",
                          staticStyle: { "font-size": "1.5em" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("玩家暱稱")) + "：" + _vm._s(_vm.name)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.user.暱稱
                    ? _c("div", { staticClass: "mb-10" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-grid table-data table-bordered table-condensed",
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入方式")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t(_vm.user.登入方式))),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("綁定門號")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.綁定門號
                                        ? _vm.user.綁定門號
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("申請日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.apply))),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("綁定日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.apply))),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("暱稱")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.暱稱))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("帳號")))]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.user.vip == 0
                                            ? "一般會員"
                                            : "正式會員"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.user.vip == 0 && _vm.can轉正
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning btn-sm",
                                          staticStyle: {
                                            "margin-left": "auto",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.memberOfficial(
                                                _vm.name
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("轉正")) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("VIP階級")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.vip))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入日期")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(_vm.user.最後登入))),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("遊戲等級")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.level))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("角色狀態")))]),
                                _c("td", [
                                  _c("div", [
                                    _vm.user.停權狀態 != 0
                                      ? _c("p", { staticClass: "text-red" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.banStatus(
                                                0,
                                                _vm.user.停權狀態,
                                                _vm.user.停權截止
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.user.禁言狀態 != 0
                                      ? _c("p", { staticClass: "text-red" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.banStatus(
                                                1,
                                                _vm.user.禁言狀態,
                                                _vm.user.禁言截止
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.user.停權狀態 == 0 &&
                                    _vm.user.禁言狀態 == 0
                                      ? _c("p", { staticClass: "text-green" }, [
                                          _vm._v(_vm._s(_vm.$t("正常"))),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("登入狀態")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.sid == "1"
                                        ? _vm.$t("在線")
                                        : _vm.$t("離線")
                                    )
                                  ),
                                ]),
                                _c("th"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("活躍值")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.活躍值))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("榮譽值")))]),
                                _c("td", [_vm._v(_vm._s(_vm.user.榮譽值))]),
                              ]),
                              _vm._m(0),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("公會")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.公會 || "-")),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("公會會長")))]),
                                _c(
                                  "td",
                                  [
                                    _vm.user.公會會長
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                params: {
                                                  name: _vm.user.公會會長,
                                                },
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.user.公會會長))]
                                        )
                                      : _c("span", [_vm._v("-")]),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("停權次數")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.banList.filter(
                                        (i) => i.異動動作 == "角色停權"
                                      ).length || "-"
                                    )
                                  ),
                                ]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("停權截止")))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.停權狀態 != 0
                                        ? _vm.uiDate(_vm.user.停權截止)
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", [_vm._v(_vm._s(_vm.$t("剩餘星幣")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.xinvalues) + " "),
                                  _vm.can星幣
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger btn-sm",
                                          staticStyle: {
                                            "margin-left": "auto",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.memberCoin(
                                                _vm.user,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("扣除")) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("th"),
                                _c("td"),
                              ]),
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("保險箱餘額"))),
                                ]),
                                _c("td", [_vm._v(_vm._s(_vm.user.保險))]),
                                _c("th", [_vm._v(_vm._s(_vm.$t("剩餘點數")))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.user.storepoints)),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.tab != 0
                ? _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
                    _c("ul", { staticClass: "nav nav-tabs" }, [
                      _vm.can服務
                        ? _c("li", { class: { active: _vm.tab == 1 } }, [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.tab = 1
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("服務紀錄")))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.can控管記錄
                        ? _c("li", { class: { active: _vm.tab == 2 } }, [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.tab = 2
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("控管紀錄")))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.can配點查詢
                        ? _c("li", { class: { active: _vm.tab == 4 } }, [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.tab = 4
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("點數查詢")))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.can榮譽
                        ? _c("li", { class: { active: _vm.tab == 3 } }, [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.tab = 3
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("榮譽值紀錄")))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.can序號查詢
                        ? _c("li", { class: { active: _vm.tab == 5 } }, [
                            _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.tab = 5
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("序號兌換紀錄")))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.tab == 1 && _vm.can服務
                      ? _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "form",
                            {
                              staticClass: "top-box clearfix",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  _vm.serviceSearchData.pageNum = 1
                                  _vm.getServiceInfo()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group m-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label pull-left mt-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group date pull-left mb-10 mr-10",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "input-group-addon fa fa-calendar",
                                    }),
                                    _c("DateRangePicker", {
                                      key: "serviceSearch",
                                      attrs: {
                                        propStartDate:
                                          _vm.serviceSearchData.開始時間,
                                        propEndDate:
                                          _vm.serviceSearchData.結束時間,
                                        propTimePicker: false,
                                      },
                                      on: {
                                        "update:propStartDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serviceSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:prop-start-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serviceSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:propEndDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serviceSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                        "update:prop-end-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serviceSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-left mb-10",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢")))]
                                ),
                              ]),
                              _vm.can服務新增
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning pull-right mb-10 ml-10",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.memberService(_vm.name)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-plus" }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("新增")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive mb-10" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "bg-gray" }, [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "65px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("編號")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("角色暱稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("異動項目")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("類別名稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "150px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("內容")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作時間")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "80px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作人員")))]
                                    ),
                                    _vm.can服務刪除
                                      ? _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "min-width": "75px",
                                              width: "5%",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("功能")))]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.serviceLoading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查詢中")))]
                                          ),
                                        ])
                                      : _vm.serviceMessage
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(_vm.serviceMessage)
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.serviceList.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查無紀錄")))]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.serviceList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.玩家異動紀錄ID },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家異動紀錄ID)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家識別值)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(item.異動項目)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.異動動作)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(_vm.get內容(item))
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(item.異動時間)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.異動者)),
                                                ]),
                                                _vm.can服務刪除
                                                  ? _c("td", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-danger",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deletePlayerService(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("刪除")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm.serviceTotal > _vm.serviceSearchData.pageSize
                            ? _c(
                                "div",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      pageLimitCount:
                                        _vm.serviceSearchData.pageSize,
                                      total: _vm.serviceTotal,
                                    },
                                    on: { pageMethod: _vm.onPageChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tab == 2 && _vm.can控管
                      ? _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "form",
                            {
                              staticClass: "top-box clearfix",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  _vm.honorSearchData.pageNum = 1
                                  _vm.getBanInfo()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group m-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label pull-left mt-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group date pull-left mb-10 mr-10",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "input-group-addon fa fa-calendar",
                                    }),
                                    _c("DateRangePicker", {
                                      key: "banSearch",
                                      attrs: {
                                        propStartDate:
                                          _vm.banSearchData.開始時間,
                                        propEndDate: _vm.banSearchData.結束時間,
                                        propTimePicker: false,
                                      },
                                      on: {
                                        "update:propStartDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.banSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:prop-start-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.banSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:propEndDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.banSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                        "update:prop-end-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.banSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-left mb-10",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢")))]
                                ),
                              ]),
                              _vm.can控管
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pull-right",
                                      staticStyle: {
                                        width: "max-content",
                                        margin: "0 auto",
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-info",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.memberModerate(
                                                _vm.user
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-gavel",
                                          }),
                                          _vm._v(" " + _vm._s(_vm.$t("控管"))),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.user.停權狀態 != 0 ||
                                                _vm.user.禁言狀態 != 0,
                                              expression:
                                                "user.停權狀態 != 0 || user.禁言狀態 != 0",
                                            },
                                          ],
                                          staticClass: "btn btn-danger ml-5",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.memberModerate(
                                                _vm.user,
                                                true,
                                                _vm.user.停權狀態 == 0
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-unlock",
                                          }),
                                          _vm._v(" " + _vm._s(_vm.$t("解鎖"))),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive mb-10" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "bg-gray" }, [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "65px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("編號")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("角色暱稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("類型")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("期限")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "150px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("原因")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作時間")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "80px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作人員")))]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.banLoading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查詢中")))]
                                          ),
                                        ])
                                      : _vm.banMessage
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(_vm.banMessage))
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.banList.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查無紀錄")))]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.pagedBanData,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.玩家異動紀錄ID },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家異動紀錄ID)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家識別值)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(item.異動動作)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(
                                                        item.新內容.控管期限
                                                      ) ||
                                                        _vm.uiDate(
                                                          item.新內容.期限
                                                        )
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.新內容.服務原因 ||
                                                        item.新內容.原因
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(item.異動時間)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.異動者)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm.banTotal > _vm.banPageSize
                            ? _c(
                                "div",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      pageLimitCount: _vm.banPageSize,
                                      total: _vm.banTotal,
                                    },
                                    on: { pageMethod: _vm.onPageChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tab == 3 && _vm.can榮譽
                      ? _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "form",
                            {
                              staticClass: "top-box clearfix",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  _vm.honorSearchData.pageNum = 1
                                  _vm.getHonorInfo()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group m-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label pull-left mt-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group date pull-left mb-10 mr-10",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "input-group-addon fa fa-calendar",
                                    }),
                                    _c("DateRangePicker", {
                                      key: "honorSearch",
                                      attrs: {
                                        propStartDate:
                                          _vm.honorSearchData.開始時間,
                                        propEndDate:
                                          _vm.honorSearchData.結束時間,
                                        propTimePicker: false,
                                      },
                                      on: {
                                        "update:propStartDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.honorSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:prop-start-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.honorSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:propEndDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.honorSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                        "update:prop-end-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.honorSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-left mb-10",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢")))]
                                ),
                              ]),
                              _vm.can榮譽異動
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-danger pull-right mb-10 ml-5",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.memberHonor(
                                              _vm.user,
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-minus" }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("回收")) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-success pull-right mb-10 ml-5",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.memberHonor(
                                              _vm.user,
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-plus" }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("添加")) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive mb-10" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "bg-gray" }, [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "65px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("編號")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("角色暱稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("類型")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("榮譽值")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("榮譽值餘額")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "150px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("內容")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作時間")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "80px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("操作人員")))]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.honorLoading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查詢中")))]
                                          ),
                                        ])
                                      : _vm.honorMessage
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(_vm.honorMessage))
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.honorList.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查無紀錄")))]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.honorList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.玩家異動紀錄ID },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家異動紀錄ID)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.玩家識別值)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(item.異動動作)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.新內容.配發數量)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.新內容.餘額)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.新內容.內容)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(item.異動時間)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.異動者)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm.honorTotal > _vm.honorSearchData.pageSize
                            ? _c(
                                "div",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      pageLimitCount:
                                        _vm.honorSearchData.pageSize,
                                      total: _vm.honorTotal,
                                    },
                                    on: { pageMethod: _vm.onPageChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tab == 4 && _vm.can配點查詢
                      ? _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "form",
                            {
                              staticClass: "top-box clearfix",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  _vm.pointSearchData.pageNum = 1
                                  _vm.getPointInfo()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group m-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label pull-left mt-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group date pull-left mb-10 mr-10",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "input-group-addon fa fa-calendar",
                                    }),
                                    _c("DateRangePicker", {
                                      key: "pointSearch",
                                      attrs: {
                                        propStartDate:
                                          _vm.pointSearchData.開始時間,
                                        propEndDate:
                                          _vm.pointSearchData.結束時間,
                                        propTimePicker: false,
                                      },
                                      on: {
                                        "update:propStartDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.pointSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:prop-start-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.pointSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:propEndDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.pointSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                        "update:prop-end-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.pointSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-left mb-10",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢")))]
                                ),
                              ]),
                              _c("div", [
                                _vm.can配點
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning pull-right mb-10 ml-5",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.memberPoint(_vm.name)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-plus" }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("新增")) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-default pull-right mb-10 ml-5",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.pointList.length === 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportPoints()
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive mb-10" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "bg-gray" }, [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("配點時間")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("角色暱稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("類型")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("點數")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("卡別")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "120px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("備註")))]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.pointLoading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查詢中")))]
                                          ),
                                        ])
                                      : _vm.pointMessage
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(_vm.pointMessage))
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.pointList.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查無紀錄")))]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.pointList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.時間 },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(item.時間)
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.暱稱)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t(item.類型))
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.點數)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.類型 == "配點"
                                                        ? "-"
                                                        : item.卡別
                                                    )
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.備註)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm.pointTotal > _vm.pointSearchData.pageSize
                            ? _c(
                                "div",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      pageLimitCount:
                                        _vm.pointSearchData.pageSize,
                                      total: _vm.pointTotal,
                                    },
                                    on: { pageMethod: _vm.onPageChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tab == 5 && _vm.can序號查詢
                      ? _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "form",
                            {
                              staticClass: "top-box clearfix",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  _vm.serialSearchData.pageNum = 1
                                  _vm.getSerialInfo()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group m-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label pull-left mt-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢區間")) + "：")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input-group date pull-left mb-10 mr-10",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "input-group-addon fa fa-calendar",
                                    }),
                                    _c("DateRangePicker", {
                                      key: "serialSearch",
                                      attrs: {
                                        propStartDate:
                                          _vm.serialSearchData.開始時間,
                                        propEndDate:
                                          _vm.serialSearchData.結束時間,
                                        propTimePicker: false,
                                      },
                                      on: {
                                        "update:propStartDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serialSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:prop-start-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serialSearchData,
                                            "開始時間",
                                            $event
                                          )
                                        },
                                        "update:propEndDate": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serialSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                        "update:prop-end-date": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.serialSearchData,
                                            "結束時間",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-left mb-10",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("查詢")))]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "table-responsive mb-10" }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _c("thead", [
                                  _c("tr", { staticClass: "bg-gray" }, [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "50px",
                                          width: "5%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("編號")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("序號")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("兌換項目")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("暱稱")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "80px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("兌換數量")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "80px",
                                          width: "10%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("道具")))]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "min-width": "100px",
                                          width: "15%",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("兌換日期")))]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm.serialLoading
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查詢中")))]
                                          ),
                                        ])
                                      : _vm.serialMessage
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(_vm.serialMessage)
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.serialList.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "11" } },
                                            [_vm._v(_vm._s(_vm.$t("查無紀錄")))]
                                          ),
                                        ])
                                      : _vm._l(
                                          _vm.serialList,
                                          function (item, index) {
                                            return _c(
                                              "tr",
                                              { key: item.序號 },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(index + 1)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.序號)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.兌換項目)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.暱稱)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.兌換數量)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.道具)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.uiDate(item.兌換時間)
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm.serialTotal > _vm.serialSearchData.pageSize
                            ? _c(
                                "div",
                                { staticClass: "text-center mt-10" },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      pageLimitCount:
                                        _vm.serialSearchData.pageSize,
                                      total: _vm.serialTotal,
                                    },
                                    on: { pageMethod: _vm.onPageChange },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
      _c("pre", [_vm._v(_vm._s(_vm.serviceList))]),
      _c("MemberOfficialDialog", {
        ref: "MemberOfficialDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onOfficial()
          },
        },
      }),
      _c("MemberModerateDialog", {
        ref: "MemberModerateDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onModerate()
          },
        },
      }),
      _c("MemberServiceDialog", {
        ref: "MemberServiceDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onService()
          },
        },
      }),
      _c("MemberRebateDialog", {
        ref: "MemberRebateDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onRebate()
          },
        },
      }),
      _c("MemberPointDialog", {
        ref: "MemberPointDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onPoint()
          },
        },
      }),
      _c("MemberHonorDialog", {
        ref: "MemberHonorDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onHonor()
          },
        },
      }),
      _c("MemberCoinDialog", {
        ref: "MemberCoinDialog",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.onCoin()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th"), _c("td"), _c("th"), _c("td")])
  },
]
render._withStripped = true

export { render, staticRenderFns }