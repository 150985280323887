var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot", attrs: { id: "clientGameHallList" } },
    [
      _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "box box-primary",
            staticStyle: { background: "none" },
          },
          [
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _c("div", { staticClass: "top-box" }, [
                  _c("div", { staticClass: "left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: { "margin-left": "0" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "dropdown",
                            staticStyle: { display: "inline" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-default",
                                staticStyle: { "border-color": "#333" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.tag) +
                                    " (" +
                                    _vm._s(_vm.total) +
                                    ") "
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sort dropdown-toggle",
                                attrs: {
                                  type: "button",
                                  "data-bs-toggle": "dropdown",
                                  "aria-expanded": "false",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dropdown =
                                      _vm.dropdown == "none" ? "block" : "none"
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "icon-sort" }),
                                _vm._v(" 排序依據 "),
                              ]
                            ),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-menu",
                                style: { display: `${_vm.dropdown}` },
                              },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "dropdown-item",
                                        _vm.searchData.排序順序 == 2
                                          ? "active"
                                          : "",
                                      ],
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetData(2)
                                        },
                                      },
                                    },
                                    [_vm._v(" A to Z ")]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "dropdown-item",
                                        _vm.searchData.排序順序 == 1
                                          ? "active"
                                          : "",
                                      ],
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetData(1)
                                        },
                                      },
                                    },
                                    [_vm._v(" Z to A ")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm.total == 0
                  ? _c("Empty", {
                      attrs: {
                        title: "找不到結果",
                        desc: "請改用其他關鍵字或移除排序條件",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "g-list hall table-responsive mt-20" },
                  [
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        {
                          staticClass: "g-card",
                          staticStyle: { cursor: "pointer" },
                        },
                        [_c("GameHallCard", { attrs: { item: item } })],
                        1
                      )
                    }),
                    _vm.searchData.PageNum < _vm.totolPage
                      ? _c("div", { staticClass: "loading-box" }, [
                          _c("div", { staticClass: "loading" }),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("slotPlayContent", { ref: "slotPlayContent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }