var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "AnnouncementEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("項目設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("遊戲管理")]),
          _c("li", [_vm._v("活動機制")]),
          _c("li", [_vm._v("機制項目")]),
          _c("li", { staticClass: "active" }, [_vm._v("項目設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("h4", { attrs: { id: "title" } }, [
              _vm._v(" " + _vm._s(_vm.$route.params.name) + " "),
            ]),
            _c("div", { staticClass: "top-box with-border" }),
            _c(
              "div",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.項目,
                              expression: "data.項目",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data,
                                "項目",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("請選擇項目"),
                          ]),
                          _c("option", { domProps: { value: 1 } }, [
                            _vm._v("主項目"),
                          ]),
                          _c("option", { domProps: { value: 2 } }, [
                            _vm._v("次項目"),
                          ]),
                          _c("option", { domProps: { value: 3 } }, [
                            _vm._v("延伸項目"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.項目名稱,
                            expression: "data.項目名稱",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                          maxlength: "50",
                        },
                        domProps: { value: _vm.data.項目名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data, "項目名稱", $event.target.value)
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.data.項目名稱.length) + " / 50"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [_vm._v(" 詳細說明： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            model: {
                              value: _vm.data.詳細說明,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "詳細說明", $$v)
                              },
                              expression: "data.詳細說明",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning pull-left mr-5",
                        attrs: {
                          disabled:
                            _vm.data.項目 == "" || _vm.data.項目名稱 == "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.preview()
                          },
                        },
                      },
                      [_vm._v(" 預覽 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-left",
                        attrs: {
                          disabled:
                            _vm.data.項目 == "" || _vm.data.項目名稱 == "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(" 儲存 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default pull-right",
                        on: {
                          click: function ($event) {
                            return _vm.backList()
                          },
                        },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { ref: "preview", staticClass: "modal fade" }, [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { name: "announcementForm" },
                  },
                  [
                    _c("div", { staticClass: "box-body" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label text-nowrap",
                            staticStyle: { "padding-top": "0" },
                          },
                          [_vm._v(" 項目選擇： ")]
                        ),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.項目 == 1
                                  ? "主項目"
                                  : _vm.data.項目 == 2
                                  ? "次項目"
                                  : "延伸項目"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label text-nowrap",
                            staticStyle: { "padding-top": "0" },
                          },
                          [_vm._v(" 項目名稱： ")]
                        ),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _vm._v(" " + _vm._s(_vm.data.項目名稱) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label text-nowrap",
                            staticStyle: { "padding-top": "0" },
                          },
                          [_vm._v(" 詳細說明： ")]
                        ),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.data.詳細說明) },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 項目選擇： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 項目名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-center" }, [_vm._v("預覽")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }