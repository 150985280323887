var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("信件發獎")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/RewardMessage" } }, [
              _vm._v("信件發獎"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog fa fa-reply",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_vm._v(" 返回列表 ")]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "rewardMessageForm", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-12" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                checkValue: "0",
                                message: "全伺服器發送",
                              },
                              model: {
                                value: _vm.data.發送名單選項,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "發送名單選項",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "data.發送名單選項",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-3" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                checkValue: "1",
                                message: "單一玩家",
                              },
                              model: {
                                value: _vm.data.發送名單選項,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "發送名單選項",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "data.發送名單選項",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "col-md-9",
                            style:
                              _vm.data.發送名單選項 != 1
                                ? "pointer-events:none"
                                : "",
                            attrs: { disabled: _vm.data.發送名單選項 != 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex", gap: ".5em" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.player,
                                      expression: "player",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "輸入玩家暱稱",
                                  },
                                  domProps: { value: _vm.player },
                                  on: {
                                    keydown: function ($event) {
                                      ;(function (e) {
                                        if (e.key == "Enter") {
                                          e.preventDefault()
                                          _vm.addPlayer()
                                        }
                                      })($event)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.player = $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.player == "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPlayer()
                                      },
                                    },
                                  },
                                  [_vm._v("新增")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.playerList.length > 0,
                                    expression: "playerList.length > 0",
                                  },
                                ],
                                staticClass: "mt-10",
                                staticStyle: {
                                  display: "flex",
                                  gap: ".5em",
                                  "align-items": "flex-end",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "player-list form-control" },
                                  _vm._l(_vm.playerList, function (player, i) {
                                    return _c("li", [
                                      _c("span", [_vm._v(_vm._s(player))]),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-default text-red pull-right",
                                          staticStyle: {
                                            padding: "0 .5em",
                                            "border-color": "currentColor",
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.playerList.splice(i, 1)
                                            },
                                          },
                                        },
                                        [_vm._v("移除")]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearPlayers()
                                      },
                                    },
                                  },
                                  [_vm._v("清空")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-3 col" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                message: "匯入名單",
                                checkValue: "3",
                              },
                              model: {
                                value: _vm.data.發送名單選項,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "發送名單選項",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "data.發送名單選項",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "col-md-9 col",
                            style:
                              _vm.data.發送名單選項 != 3
                                ? "pointer-events:none"
                                : "",
                            attrs: { disabled: _vm.data.發送名單選項 != 3 },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "btn btn-primary fa fa-file-alt",
                                on: {
                                  click: function ($event) {
                                    return _vm.importSN()
                                  },
                                },
                              },
                              [_vm._v(" 選擇檔案 ")]
                            ),
                            _vm.importList.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success fa fa-list",
                                    staticStyle: { "margin-left": ".35em" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkContent(_vm.data, true)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看名單 ")]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              { staticStyle: { "padding-left": "8px" } },
                              [_vm._v(" " + _vm._s(_vm.importFileName) + " ")]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-3" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                name: "sendRadio",
                                message: "指定條件",
                                checkValue: "2",
                              },
                              model: {
                                value: _vm.data.發送名單選項,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "發送名單選項",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "data.發送名單選項",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "fieldset",
                          {
                            staticClass: "col-md-9 col",
                            style:
                              _vm.data.發送名單選項 != 2
                                ? "pointer-events:none"
                                : "",
                            attrs: { disabled: _vm.data.發送名單選項 != 2 },
                          },
                          [
                            _c("div", { staticClass: "box-group" }, [
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "label",
                                  { staticClass: "checkbox checkbox-inline" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.conditions[0].checked,
                                          expression: "conditions[0].checked",
                                        },
                                      ],
                                      staticStyle: {
                                        position: "relative",
                                        width: "1.5em",
                                        height: "1.5em",
                                        "vertical-align": "sub",
                                        "margin-top": "-.2em",
                                        "accent-color": "#3498db",
                                        cursor: "pointer",
                                      },
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.conditions[0].checked
                                        )
                                          ? _vm._i(
                                              _vm.conditions[0].checked,
                                              null
                                            ) > -1
                                          : _vm.conditions[0].checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.conditions[0].checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.conditions[0],
                                                  "checked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.conditions[0],
                                                  "checked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.conditions[0],
                                              "checked",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" 段位等級 "),
                                  ]
                                ),
                              ]),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "vip col-md-9",
                                  attrs: {
                                    required: _vm.conditions[0].checked,
                                    disabled: !_vm.conditions[0].checked,
                                  },
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.conditions[0].min,
                                          expression: "conditions[0].min",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.conditions[0],
                                            "min",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.gameTypes.Level,
                                      function (option, i) {
                                        return _c(
                                          "option",
                                          { key: i, domProps: { value: i } },
                                          [
                                            _vm._v(
                                              _vm._s(option) +
                                                " (" +
                                                _vm._s(i) +
                                                ")"
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("p", [_vm._v("~")]),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.conditions[0].max,
                                          expression: "conditions[0].max",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.conditions[0],
                                            "max",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.gameTypes.Level,
                                      function (option, i) {
                                        return _c(
                                          "option",
                                          { key: i, domProps: { value: i } },
                                          [
                                            _vm._v(
                                              _vm._s(option) +
                                                " (" +
                                                _vm._s(i) +
                                                ")"
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "box-group" }, [
                              _c("div", { staticClass: "col-md-3" }, [
                                _c(
                                  "label",
                                  { staticClass: "checkbox checkbox-inline" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.conditions[1].checked,
                                          expression: "conditions[1].checked",
                                        },
                                      ],
                                      staticStyle: {
                                        position: "relative",
                                        width: "1.5em",
                                        height: "1.5em",
                                        "vertical-align": "sub",
                                        "margin-top": "-.2em",
                                        "accent-color": "#3498db",
                                        cursor: "pointer",
                                      },
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.conditions[1].checked
                                        )
                                          ? _vm._i(
                                              _vm.conditions[1].checked,
                                              null
                                            ) > -1
                                          : _vm.conditions[1].checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.conditions[1].checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.conditions[1],
                                                  "checked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.conditions[1],
                                                  "checked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.conditions[1],
                                              "checked",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" 雀幣 "),
                                  ]
                                ),
                              ]),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "level col-md-9",
                                  attrs: {
                                    required: _vm.conditions[1].checked,
                                    disabled: !_vm.conditions[1].checked,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.conditions[1].min,
                                        expression: "conditions[1].min",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number", min: "0" },
                                    domProps: { value: _vm.conditions[1].min },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.conditions[1],
                                          "min",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _c("p", [_vm._v("~")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.conditions[1].max,
                                        expression: "conditions[1].max",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "number" },
                                    domProps: { value: _vm.conditions[1].max },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.conditions[1],
                                          "max",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.寄件者,
                              expression: "data.寄件者",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data,
                                "寄件者",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.def.mailSender, function (option, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: i } },
                            [_vm._v(_vm._s(option))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.data.信件標題,
                            expression: "data.信件標題",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputTitle",
                          maxlength: _vm.titleMax,
                          placeholder: `請輸入主旨，上限${_vm.titleMax}字`,
                          required: "",
                        },
                        domProps: { value: _vm.data.信件標題 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data,
                              "信件標題",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("p", { staticClass: "word-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.data.信件標題.length) +
                            " / " +
                            _vm._s(_vm.titleMax) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "editor" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.信件內容,
                              expression: "data.信件內容",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            cols: "100",
                            rows: "10",
                            maxlength: _vm.contentMax,
                            placeholder: `請輸入內容，上限${_vm.contentMax}字。`,
                            required: "",
                          },
                          domProps: { value: _vm.data.信件內容 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "信件內容",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "word-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.data.信件內容.length) +
                            " / " +
                            _vm._s(_vm.contentMax) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _vm._l(_vm.data.發送獎勵列表, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "box-group sendAwards" },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.type,
                                      expression: "item.type",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { required: "" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          item,
                                          "type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        ;(item.id =
                                          item.type == "1" ? "0" : ""),
                                          (item.count = 1)
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "",
                                        selected: "",
                                        hidden: "",
                                        disabled: "",
                                      },
                                    },
                                    [_vm._v("請選擇類型")]
                                  ),
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v("貨幣"),
                                  ]),
                                  _c("option", { attrs: { value: "2" } }, [
                                    _vm._v("物品"),
                                  ]),
                                ]
                              ),
                              item.type != "2"
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.id,
                                          expression: "item.id",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      staticStyle: { "max-width": "10em" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            item,
                                            "id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            hidden: "",
                                            disabled: "",
                                          },
                                        },
                                        [_vm._v("請選擇類別")]
                                      ),
                                      _vm._l(
                                        _vm.def.gameCoins,
                                        function (name, id) {
                                          return item.type == "1"
                                            ? _c(
                                                "option",
                                                {
                                                  key: name,
                                                  domProps: { value: id },
                                                },
                                                [_vm._v(_vm._s(name))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : item.type === "2"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.id,
                                        expression: "item.id",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { "max-width": "10em" },
                                    attrs: {
                                      type: "text",
                                      list: "prizeList",
                                      placeholder: "請輸入物品ID",
                                    },
                                    domProps: { value: item.id },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "id",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "datalist",
                                { attrs: { id: "prizeList" } },
                                _vm._l(_vm.gameTypes.Item, function (name, id) {
                                  return _c(
                                    "option",
                                    { domProps: { value: id } },
                                    [
                                      _vm._v(
                                        _vm._s(name) + " (" + _vm._s(id) + ")"
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: item.count,
                                    expression: "item.count",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control inputNumber",
                                attrs: {
                                  type: "number",
                                  placeholder: "請輸入數量",
                                  required: "",
                                  min: "1",
                                },
                                domProps: { value: item.count },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "count",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeReward(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]
                          )
                        }),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.發送獎勵列表.length < 4,
                                expression: "data.發送獎勵列表.length < 4",
                              },
                            ],
                            staticClass: "btn btn-warning fa fa-plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addReward()
                              },
                            },
                          },
                          [_vm._v(" 新增獎勵 ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default pull-right",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.getTypes(true)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-refresh" }),
                            _vm._v(" 重抓物品ID資訊 "),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c("fieldset", { staticClass: "form-group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "radio col-sm-10" }, [
                      _c(
                        "p",
                        [
                          _c("iCheckRedioButton", {
                            staticStyle: { "margin-right": "1em" },
                            attrs: {
                              name: "timeRadio",
                              message: "立即發送",
                              checkValue: "0",
                            },
                            model: {
                              value: _vm.scheduled,
                              callback: function ($$v) {
                                _vm.scheduled = _vm._n($$v)
                              },
                              expression: "scheduled",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            attrs: {
                              name: "timeRadio",
                              message: "排程時間",
                              checkValue: "1",
                            },
                            model: {
                              value: _vm.scheduled,
                              callback: function ($$v) {
                                _vm.scheduled = _vm._n($$v)
                              },
                              expression: "scheduled",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "fieldset",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.data.isScheduled,
                              expression: "data.isScheduled",
                            },
                          ],
                          staticClass: "radio-date",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-group date" },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DatePicker", {
                                staticClass: "pull-right",
                                attrs: { propValue: _vm.startDate },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.startDate = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.startDate = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "input-group time" }, [
                            _c("div", {
                              staticClass: "input-group-addon fa fa-clock-o",
                            }),
                            _c("input", {
                              staticClass: "form-control timepicker",
                              attrs: { type: "text", id: "startTimeOnly" },
                              on: {
                                input: function ($event) {
                                  return _vm.updateTime()
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("fieldset", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c(
                          "div",
                          { staticClass: "input-group date" },
                          [
                            _c("div", {
                              staticClass: "input-group-addon fa fa-calendar",
                            }),
                            _c("DatePicker", {
                              staticClass: "pull-right",
                              attrs: { propValue: _vm.endDate },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endDate = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endDate = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "input-group time" }, [
                          _c("div", {
                            staticClass: "input-group-addon fa fa-clock-o",
                          }),
                          _c("input", {
                            staticClass: "form-control timepicker",
                            attrs: { type: "text", id: "endTimeOnly" },
                            on: {
                              input: function ($event) {
                                return _vm.updateTime()
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.data.寄件者 == 2 &&
                  (_vm.data.發送名單選項 == 1 || _vm.data.發送名單選項 == 3) &&
                  _vm.data.isScheduled == false
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("hr"),
                        _c("label", { staticClass: "col-sm-2 control-label" }, [
                          _vm._v(" 服務紀錄： "),
                        ]),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("p", [
                            _c(
                              "label",
                              { staticClass: "checkbox checkbox-inline" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isService,
                                      expression: "isService",
                                    },
                                  ],
                                  staticStyle: {
                                    position: "relative",
                                    width: "1.5em",
                                    height: "1.5em",
                                    "vertical-align": "sub",
                                    "margin-top": "-.2em",
                                    "accent-color": "#3498db",
                                    cursor: "pointer",
                                  },
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.isService)
                                      ? _vm._i(_vm.isService, null) > -1
                                      : _vm.isService,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.isService,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isService = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isService = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isService = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" 新增服務紀錄 "),
                              ]
                            ),
                          ]),
                          _vm.isService
                            ? _c(
                                "table",
                                { staticClass: "table table-bordered" },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [_vm._v("服務類型")]),
                                      _c("td", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: _vm.data.分類,
                                                expression: "data.分類",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "服務類型",
                                              required: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return _vm._n(val)
                                                    })
                                                _vm.$set(
                                                  _vm.data,
                                                  "分類",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "0",
                                                  selected: "",
                                                  disabled: "",
                                                  hidden: "",
                                                },
                                              },
                                              [_vm._v("請選擇類別")]
                                            ),
                                            _vm._l(
                                              _vm.serviceTypes,
                                              function (name, id) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: id,
                                                    domProps: { value: id },
                                                  },
                                                  [_vm._v(_vm._s(name))]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]),
                                    _c("tr", [
                                      _c("th", [_vm._v("內容")]),
                                      _c("td", [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.內容,
                                              expression: "data.內容",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "內容",
                                            min: "1",
                                            rows: "5",
                                            required: "",
                                          },
                                          domProps: { value: _vm.data.內容 },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.data,
                                                "內容",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _vm.id
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger btn-lg fa fa-trash",
                            staticStyle: { "margin-right": ".5em" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteMessage()
                              },
                            },
                          },
                          [_vm._v(" 刪除草稿 ")]
                        )
                      : _vm._e(),
                    _vm.data.狀態 === 0
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-lg fa fa-save",
                            staticStyle: { "margin-right": ".5em" },
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                _vm.data.isDraft = true
                              },
                            },
                          },
                          [_vm._v(" 儲存草稿 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg fa fa-send-o",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            _vm.data.isDraft = false
                          },
                        },
                      },
                      [_vm._v(" 確認送出 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("rewardMessageContent", { ref: "rewardMessageContent" }),
        _c("ImportData", {
          ref: "ImportData",
          attrs: { title: "玩家", checks: _vm.checks },
          on: { importData: _vm.importData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發送名單： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 寄件者： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息主旨： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送獎勵： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 到期時間： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }