var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot", attrs: { id: "ClassTypeManager" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲分類")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲分類")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                attrs: { id: "classTypes" },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm._l(_vm.testList, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _c("td", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(_vm._s(item.count)),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ckbx-style-8 ckbx toggleInput" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.isStop,
                                      expression: "item.isStop",
                                    },
                                  ],
                                  attrs: {
                                    "false-value": 1,
                                    id: "check" + index,
                                    "true-value": 0,
                                    name: "ckbx-style-8",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(item.isStop)
                                      ? _vm._i(item.isStop, null) > -1
                                      : _vm._q(item.isStop, 0),
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = item.isStop,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 0 : 1
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "isStop",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "isStop",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "isStop", $$c)
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changeStatus(item)
                                      },
                                    ],
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "check" + index },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item)
                                },
                              },
                            },
                            [_vm._v("編輯")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: { total: 1, pageLimitCount: 1 },
            }),
          ],
          1
        ),
      ]),
      _c("div", { ref: "create", staticClass: "modal fade" }, [
        _c("div", { staticClass: "modal-dialog modal-sm" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(2),
            _c("div", { staticClass: "modal-body" }, [
              !_vm.isEdit
                ? _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "開發商名稱" },
                  })
                : _vm.editData.name
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editData.name,
                        expression: "editData.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "開發商名稱" },
                    domProps: { value: _vm.editData.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.editData, "name", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "modal-footer text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { "data-dismiss": "modal", type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("儲存")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("p", { staticClass: "data-num" }, [
        _c("span", [_vm._v("資料筆數：")]),
        _c("span", { attrs: { id: "total" } }, [_vm._v("1")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "50%" } },
        [_vm._v("分類名稱")]
      ),
      _c("th", { staticStyle: { "min-width": "97px" } }, [_vm._v("遊戲數")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v("啟用")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "20%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title text-center" }, [
        _vm._v("新增名稱"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }