var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "VersionSettings" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("儲值品項管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FC/TopupRecordList" } }, [
              _vm._v("儲值查詢"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("儲值品項管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addType()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-bordered text-center min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.datas.length === 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v("查詢無任何資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.datas.length - index)),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", [_vm._v(_vm._s(item.產品項目))])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.產品項目,
                                        expression: "item.產品項目",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: item.產品項目 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "產品項目",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", [_vm._v(_vm._s(item.產品類別))])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.產品類別,
                                        expression: "item.產品類別",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: item.產品類別 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "產品類別",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                            ]),
                            _c("td", [
                              !item.isEdit
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currencyFormat")(item.點數)
                                      )
                                    ),
                                  ])
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.點數,
                                        expression: "item.點數",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: item.點數 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "點數",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { align: "center" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ckbx-style-8 ckbx toggleInput",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: item.status,
                                          expression: "item.status",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "ckbx-style-8",
                                        id: `check${index}`,
                                        "false-value": 1,
                                        "true-value": 0,
                                      },
                                      domProps: {
                                        checked: Array.isArray(item.status)
                                          ? _vm._i(item.status, null) > -1
                                          : _vm._q(item.status, 0),
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = item.status,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? 0 : 1
                                          if (Array.isArray($$a)) {
                                            var $$v = _vm._n(null),
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "status",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "status",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "status", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      attrs: { for: `check${index}` },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.isEdit,
                                      expression: "!item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(item)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-success btn-save",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save(item)
                                    },
                                  },
                                },
                                [_vm._v(" 儲存 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isEdit,
                                      expression: "item.isEdit",
                                    },
                                  ],
                                  staticClass: "btn btn-default",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel(item)
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchDataEdit.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [
        _vm._v("品項產品ID"),
      ]),
      _c("th", [_vm._v("品項名稱")]),
      _c("th", [_vm._v("品項價格")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "10%" },
        },
        [_vm._v(" 啟用狀態 ")]
      ),
      _c("th", { staticStyle: { "min-width": "150px", width: "20%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }