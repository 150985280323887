var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "ForumMuteSetting" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("討論區數據統計")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("討論區數據統計")]),
      ]),
    ]),
    _c("section", { staticClass: "content forum-mute" }, [
      _c("div", { staticClass: "box box-primary max1200" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "form-group clearfix" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("開始日期："),
              ]),
              _c(
                "div",
                { staticClass: "input-group date" },
                [
                  _vm._m(0),
                  _c("DateRangePicker", {
                    attrs: {
                      propEndDate: _vm.searchData.endTime,
                      propStartDate: _vm.searchData.startTime,
                    },
                    on: {
                      "update:propEndDate": function ($event) {
                        return _vm.$set(_vm.searchData, "endTime", $event)
                      },
                      "update:prop-end-date": function ($event) {
                        return _vm.$set(_vm.searchData, "endTime", $event)
                      },
                      "update:propStartDate": function ($event) {
                        return _vm.$set(_vm.searchData, "startTime", $event)
                      },
                      "update:prop-start-date": function ($event) {
                        return _vm.$set(_vm.searchData, "startTime", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.resetDataList()
                  },
                },
              },
              [_vm._v(" 查詢 ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered min1000" }, [
              _c(
                "tbody",
                [
                  _vm._m(1),
                  _vm.loading
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "12" },
                          },
                          [_vm._v("查詢中")]
                        ),
                      ])
                    : _vm.datas.length === 0
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "12" },
                          },
                          [_vm._v("查詢無任何資料")]
                        ),
                      ])
                    : _vm._l(_vm.datas, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.CountDay)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.visitorCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.fbCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.memberCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.articleCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.NewsArticle)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.sendArticle)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.planArticle)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.commentCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.commentMember)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.collectNewsCount)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.TrackArticle)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.TrackTag)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.TrackReporter)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.TrackAuthor)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.voteCount)),
                          ]),
                        ])
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "box-footer text-center no-border clearfix" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px" } }, [_vm._v("日期")]),
      _c("th", [_vm._v("新訪客ID數")]),
      _c("th", [_vm._v("新FB會員數")]),
      _c("th", [_vm._v("新QPP會員門號數")]),
      _c("th", [_vm._v("新話題數")]),
      _c("th", [_vm._v("新評論數")]),
      _c("th", [_vm._v("新投書數")]),
      _c("th", [_vm._v("新特企數")]),
      _c("th", [_vm._v("新留言數")]),
      _c("th", [_vm._v("新留言不重複人數")]),
      _c("th", [_vm._v("新收藏新聞數")]),
      _c("th", [_vm._v("新追蹤文章數")]),
      _c("th", [_vm._v("新追蹤標籤數")]),
      _c("th", [_vm._v("新追蹤記者數")]),
      _c("th", [_vm._v("新追蹤作者數")]),
      _c("th", [_vm._v("新投票數")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }