var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ClassTypeManager" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("客服歷程")))]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(_vm._s(_vm.$t("客服歷程"))),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              { staticClass: "top-box" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("開始日期")) + "：")]),
                _c(
                  "div",
                  { staticClass: "searchBox" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.searchData.ETime,
                        propStartDate: _vm.searchData.STime,
                        propsIn: "format2",
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "ETime", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "ETime", $event)
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "STime", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "STime", $event)
                        },
                        "update:date": _vm.updatedate,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "state iradio_flat-blue",
                  attrs: { checked: _vm.搜尋類型 == "搜尋事件" },
                  on: {
                    click: function ($event) {
                      _vm.搜尋類型 = "搜尋事件"
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t("搜尋事件")) + " "),
                _c("div", {
                  staticClass: "state iradio_flat-blue",
                  attrs: { checked: _vm.搜尋類型 == "搜尋內容" },
                  on: {
                    click: function ($event) {
                      _vm.搜尋類型 = "搜尋內容"
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t("搜尋內容")) + " "),
                _c("br"),
                _vm.搜尋類型 == "搜尋事件"
                  ? [
                      _c("div", { staticClass: "searchBox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.Player,
                              expression: "searchData.Player",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("請輸入暱稱"),
                          },
                          domProps: { value: _vm.searchData.Player },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "Player",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "searchBox" },
                        [
                          _c("multiselect", {
                            attrs: {
                              placeholder: _vm.$t("請輸入客服人員"),
                              options: _vm.客服列表,
                              label: "show",
                              "track-by": "Cs",
                            },
                            model: {
                              value: _vm.searchData.Cs,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "Cs", $$v)
                              },
                              expression: "searchData.Cs",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c("div", { staticClass: "searchBox" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.搜尋暱稱或客服,
                                expression: "搜尋暱稱或客服",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.搜尋暱稱或客服 = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "暱稱" } }, [
                              _vm._v(_vm._s(_vm.$t("暱稱"))),
                            ]),
                            _c("option", { attrs: { value: "客服" } }, [
                              _vm._v(_vm._s(_vm.$t("客服人員"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "searchBox" },
                        [
                          _vm.搜尋暱稱或客服 == "暱稱"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.searchData.Player,
                                    expression: "searchData.Player",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                staticStyle: { width: "150px" },
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("請輸入暱稱"),
                                },
                                domProps: { value: _vm.searchData.Player },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.searchData,
                                      "Player",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.搜尋暱稱或客服 == "客服"
                            ? _c("multiselect", {
                                attrs: {
                                  placeholder: "請輸入客服人員",
                                  options: _vm.客服列表,
                                  label: "show",
                                  "track-by": "Cs",
                                },
                                model: {
                                  value: _vm.searchData.Cs,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchData, "Cs", $$v)
                                  },
                                  expression: "searchData.Cs",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                _vm.搜尋類型 == "搜尋事件"
                  ? _c("div", { staticClass: "searchBox" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchEventData.FinishReason,
                              expression: "searchEventData.FinishReason",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchEventData,
                                "FinishReason",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("全部事件類別"))),
                          ]),
                          _vm._l(_vm.自動完成事件類型, function (item) {
                            return _c(
                              "option",
                              { domProps: { value: item.id } },
                              [_vm._v(_vm._s(_vm.$t(item.name)))]
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.搜尋類型 == "搜尋事件"
                  ? _c("div", { staticClass: "searchBox" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchEventData.EventType,
                              expression: "searchEventData.EventType",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchEventData,
                                "EventType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("全部事件原因"))),
                          ]),
                          _vm._l(_vm.固定事件類型, function (item) {
                            return _c(
                              "option",
                              { domProps: { value: item.id } },
                              [_vm._v(_vm._s(_vm.$t(item.name)))]
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.搜尋類型 == "搜尋事件"
                  ? _c("div", { staticClass: "searchBox" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchEventData.ProcessStatus,
                              expression: "searchEventData.ProcessStatus",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchEventData,
                                "ProcessStatus",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("全部狀態"))),
                          ]),
                          _c("option", { domProps: { value: 0 } }, [
                            _vm._v(_vm._s(_vm.$t("未分派"))),
                          ]),
                          _c("option", { domProps: { value: 1 } }, [
                            _vm._v(_vm._s(_vm.$t("已分派"))),
                          ]),
                          _c("option", { domProps: { value: 2 } }, [
                            _vm._v(_vm._s(_vm.$t("處理中"))),
                          ]),
                          _c("option", { domProps: { value: 3 } }, [
                            _vm._v(_vm._s(_vm.$t("已完成"))),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.搜尋類型 == "搜尋內容"
                  ? _c("div", { staticClass: "searchBox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.searchMessageData.Keyword,
                            expression: "searchMessageData.Keyword",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("內容搜尋"),
                        },
                        domProps: { value: _vm.searchMessageData.Keyword },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.searchMessageData,
                              "Keyword",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "searchBox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch(1, _vm.search條件, _vm.搜尋類型)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-search" }),
                      _vm._v(" " + _vm._s(_vm.$t("查詢"))),
                    ]
                  ),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("資料總筆數")) +
                    "：" +
                    _vm._s(_vm.total) +
                    " "
                ),
              ],
              2
            ),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _vm.eventList.length > 0 ||
              (_vm.messageList.length == 0 && _vm.搜尋類型 == "搜尋事件")
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("ID"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("事件類別"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("事件原因"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("狀態"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("玩家暱稱"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("客服人員"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("建立時間"))),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-nowrap text-center",
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("功能")) + " ")]
                            ),
                          ]),
                          _vm._l(_vm.eventList, function (item) {
                            return _c("tr", { key: item.EventId }, [
                              _c("td", [_vm._v(_vm._s(item.EventId))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$t(item.FinishReasonKey))),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.$t(item.EventTypeKey))),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.$t(`事件狀態${item.事件狀態Key}`))
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.Player))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.filter客服(item.Cs))),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.格式化時間(item.CreateTime))),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDetailForm(
                                          item.EventId,
                                          item.ServiceNo
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("詳細")))]
                                ),
                              ]),
                            ])
                          }),
                          _vm.eventList.length == 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "8" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.messageList.length > 0 ||
              (_vm.eventList.length == 0 && _vm.搜尋類型 == "搜尋內容")
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("ID"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("發話方"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("內容"))),
                            ]),
                            _c("th", { staticClass: "text-nowrap" }, [
                              _vm._v(_vm._s(_vm.$t("建立時間"))),
                            ]),
                            _c(
                              "th",
                              {
                                staticClass: "text-nowrap text-center",
                                staticStyle: {
                                  "min-width": "150px",
                                  width: "10%",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("功能")) + " ")]
                            ),
                          ]),
                          _vm._l(_vm.messageList, function (item) {
                            return _c("tr", { key: item.ChatId }, [
                              _c("td", [_vm._v(_vm._s(item.EventId))]),
                              _c("td", [_vm._v(_vm._s(item.Sender))]),
                              _c("td", [_vm._v(_vm._s(item.Msg))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.格式化時間(item.CreateTime))),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-block btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDetailForm(
                                          item.EventId,
                                          item.ServiceNo
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("詳細")))]
                                ),
                              ]),
                            ])
                          }),
                          _vm.messageList.length == 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "7" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("PopLogForm", {
        ref: "popLogForm",
        attrs: { 客服顯示對應表: _vm.客服顯示對應表 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }