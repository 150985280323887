var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "playerSettingContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(" " + _vm._s(this.item.blockId ? "編輯" : "新增") + "內容 "),
          ]),
        ]),
        _c(
          "form",
          {
            staticClass: "form-horizontal",
            attrs: { name: "playerSettingForm", method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { width: "90px" } }, [
                        _vm._v("控管對象"),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.item.playUId,
                              expression: "item.playUId",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "66%" },
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.item.playUId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.item,
                                "playUId",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("控管原因")]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.item.note,
                              expression: "item.note",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "66%" },
                          attrs: { type: "text", placeholder: "選填" },
                          domProps: { value: _vm.item.note },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.item,
                                "note",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("控管類型")]),
                      _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              checkValue: "1",
                              message: "停權",
                              name: "blockType",
                            },
                            model: {
                              value: _vm.item.blockType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "blockType", $$v)
                              },
                              expression: "item.blockType",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              checkValue: "2",
                              message: "禁言",
                              name: "blockType",
                            },
                            model: {
                              value: _vm.item.blockType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "blockType", $$v)
                              },
                              expression: "item.blockType",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "hide",
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              checkValue: "3",
                              message: "解除",
                              name: "blockType",
                            },
                            model: {
                              value: _vm.item.blockType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "blockType", $$v)
                              },
                              expression: "item.blockType",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("控管期限")]),
                      _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              checkValue: "1",
                              message: "永久",
                              name: "timeType",
                            },
                            model: {
                              value: _vm.item.timeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "timeType", _vm._n($$v))
                              },
                              expression: "item.timeType",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              checkValue: "2",
                              message: "限時",
                              name: "timeType",
                            },
                            model: {
                              value: _vm.item.timeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "timeType", _vm._n($$v))
                              },
                              expression: "item.timeType",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "hide",
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              checkValue: "0",
                              message: "無",
                              name: "timeType",
                            },
                            model: {
                              value: _vm.item.timeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "timeType", _vm._n($$v))
                              },
                              expression: "item.timeType",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "radio-date",
                              staticStyle: { float: "right" },
                            },
                            [
                              _c("div", { staticClass: "input-group date" }, [
                                _vm._m(1),
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c("DatePicker", {
                                      attrs: { propValue: _vm.endTime },
                                      on: {
                                        "update:propValue": function ($event) {
                                          _vm.endTime = $event
                                        },
                                        "update:prop-value": function ($event) {
                                          _vm.endTime = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._m(2),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(3),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("確認送出")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v(" 關閉 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }