var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "normal", attrs: { id: "NewtalkElectionHistorical" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            _vm._s(_vm.type == "President" ? "總統" : "縣市長") + "大選歷史數據"
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(
              _vm._s(_vm.type == "President" ? "總統" : "縣市長") +
                "大選歷史數據"
            ),
          ]),
        ]),
        _vm.type == "President"
          ? _c("div", { staticClass: "mt-10" }, [
              _c(
                "button",
                {
                  class: [
                    "btn btn-cog mr-5",
                    _vm.category == "總統" ? "btn-primary" : "btn-default",
                  ],
                  staticStyle: { width: "auto" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.category = "總統"
                    },
                  },
                },
                [_vm._v(" 總統 ")]
              ),
              _c(
                "button",
                {
                  class: [
                    "btn btn-cog mr-5",
                    _vm.category == "立委" ? "btn-primary" : "btn-default",
                  ],
                  staticStyle: { width: "auto" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.category = "立委"
                    },
                  },
                },
                [_vm._v(" 立委 ")]
              ),
              _c(
                "button",
                {
                  class: [
                    "btn btn-cog",
                    _vm.category == "政黨" ? "btn-primary" : "btn-default",
                  ],
                  staticStyle: { width: "auto" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.category = "政黨"
                    },
                  },
                },
                [_vm._v(" 政黨 ")]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "section",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.year,
                          expression: "searchData.year",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.yearList, function (year) {
                      return _c("option", { domProps: { value: year } }, [
                        _vm._v(" " + _vm._s(year) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.category == "總統" ||
                _vm.category == "縣市長" ||
                _vm.category == "立委"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.area,
                              expression: "searchData.area",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "area",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.setCounty()
                              },
                            ],
                          },
                        },
                        [
                          _vm._l(_vm.menu, function (data, key) {
                            return [
                              !(
                                (_vm.category == "縣市長" ||
                                  _vm.category == "總統") &&
                                key == "原住民"
                              )
                                ? _c("option", { domProps: { value: key } }, [
                                    _vm._v(" " + _vm._s(key) + " "),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.category == "總統" ||
                _vm.category == "縣市長" ||
                _vm.category == "立委"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.county,
                              expression: "searchData.county",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "county",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.setConstituency()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.countyList, function (county, key) {
                          return _c(
                            "option",
                            {
                              key: county.代碼,
                              domProps: { value: county.代碼 },
                            },
                            [_vm._v(" " + _vm._s(county.縣市) + " ")]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.category == "立委" && _vm.searchData.area != "原住民"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.constituency,
                              expression: "searchData.constituency",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "constituency",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.resetDataList()
                              },
                            ],
                          },
                        },
                        _vm._l(
                          _vm.constituency,
                          function (constituency, index) {
                            return _c(
                              "option",
                              {
                                key: index + 1,
                                domProps: { value: index + 1 },
                              },
                              [_vm._v(" " + _vm._s(constituency) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.category == "政黨"
                  ? _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedGroup,
                              expression: "selectedGroup",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "required" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedGroup = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(
                          _vm.mayorDatas.candidateList,
                          function (gorop, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: index } },
                              [_vm._v(" " + _vm._s(gorop.partyName) + " ")]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-cog",
                      staticStyle: { width: "auto" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetSearch()
                        },
                      },
                    },
                    [_vm._v(" 清除篩選 ")]
                  ),
                  _vm.searchData.year === _vm.yearList[0]
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-cog",
                          staticStyle: { width: "auto" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.resetDataList()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-refresh" }),
                          _vm._v(" 資料刷新 (" + _vm._s(_vm.cecTime) + ") "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm.category == "政黨" && _vm.mayorDatas.candidateList
                ? _c("div", { staticClass: "mt-10" }, [
                    _vm._v(
                      " 得票數：" +
                        _vm._s(
                          _vm.mayorDatas.candidateList[_vm.selectedGroup]
                            .tickets
                        ) +
                        "票 得票率：" +
                        _vm._s(
                          _vm.mayorDatas.candidateList[_vm.selectedGroup].rate
                        ) +
                        "% "
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-hover table-bordered table-list",
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _c("tr", { staticClass: "bg-gray" }, [
                          _c("th", { staticStyle: { width: "5%" } }, [
                            _vm._v("號次"),
                          ]),
                          _vm.category != "政黨"
                            ? _c("th", { staticStyle: { width: "15%" } }, [
                                _vm._v("頭像"),
                              ])
                            : _vm._e(),
                          _c("th", { staticStyle: { width: "20%" } }, [
                            _vm._v("候選人"),
                          ]),
                          _vm.category != "政黨"
                            ? _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("黨徽"),
                              ])
                            : _vm._e(),
                          _vm.category != "政黨"
                            ? _c("th", { staticStyle: { width: "25%" } }, [
                                _vm._v("所屬政黨"),
                              ])
                            : _vm._e(),
                          _vm.category != "政黨"
                            ? _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("得票數"),
                              ])
                            : _vm._e(),
                          _vm.category != "政黨"
                            ? _c("th", { staticStyle: { width: "10%" } }, [
                                _vm._v("得票率"),
                              ])
                            : _vm._e(),
                          _c("th", { staticStyle: { width: "10%" } }, [
                            _vm._v("最終結果"),
                          ]),
                          _vm.category == "政黨"
                            ? _c("th", { staticStyle: { width: "20%" } }, [
                                _vm._v("備註"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.loading
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    colspan: _vm.category == "政黨" ? 4 : 6,
                                  },
                                },
                                [_vm._v("查詢中")]
                              ),
                            ])
                          : _vm.dataList && _vm.dataList.length === 0
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    colspan: _vm.category == "政黨" ? 4 : 6,
                                  },
                                },
                                [_vm._v("查詢無任何資料")]
                              ),
                            ])
                          : _vm.dataList
                          ? _vm._l(_vm.dataList, function (mayor, index) {
                              return _c("tr", { key: "mayor" + index }, [
                                _c("td", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    " " + _vm._s(mayor.candidateNum) + " "
                                  ),
                                ]),
                                _vm.category != "政黨"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _vm.category == "總統" &&
                                        mayor.candidate
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pic imgFill",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: mayor.candidate[0]
                                                        .image,
                                                      alt: mayor.candidate[0]
                                                        .name,
                                                    },
                                                    on: {
                                                      error: _vm.imgUrlAlt,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "div",
                                                { staticClass: "pic imgFill" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: mayor.candidateImage,
                                                      alt: mayor.candidateName,
                                                    },
                                                    on: {
                                                      error: _vm.imgUrlAlt,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  { staticClass: "text-nowrap" },
                                  [
                                    _vm.category == "總統"
                                      ? _vm._l(
                                          mayor.candidate,
                                          function (President) {
                                            return _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(President.name) +
                                                  " "
                                              ),
                                            ])
                                          }
                                        )
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(mayor.candidateName) +
                                              " "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm.category != "政黨"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "50px" },
                                          attrs: {
                                            src: mayor.candidatePartyImage,
                                            alt: mayor.candidatePartyName,
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.category != "政黨"
                                  ? _c("td", { staticClass: "text-nowrap" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(mayor.candidatePartyName) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.category != "政黨"
                                  ? _c(
                                      "td",
                                      { staticClass: "text-nowrap text-right" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.numberWithCommas(
                                                mayor.tickets
                                              )
                                            ) +
                                            "票 "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.category != "政黨"
                                  ? _c(
                                      "td",
                                      { staticClass: "text-nowrap text-right" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              +(
                                                Math.round(mayor.rate + "e+2") +
                                                "e-2"
                                              )
                                            ) +
                                            "% "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "td",
                                  { staticClass: "text-nowrap text-center" },
                                  [
                                    mayor.electStatus
                                      ? _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/icon-mark.png"),
                                            alt: "當選",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.category == "政黨"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "text-nowrap text-center",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.electStatus[mayor.electStatus]
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm.category == "縣市長"
            ? [
                _c("h2", { staticClass: "page-header" }, [
                  _vm._v("各縣市政黨圖表"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "nav-tabs-custom",
                    attrs: { id: "cityChart" },
                  },
                  [
                    _c("ul", { staticClass: "nav nav-tabs" }, [
                      _c(
                        "li",
                        { class: { active: _vm.tabName == "partisan_1" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#partisan_1",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showTab("partisan_1")
                                },
                              },
                            },
                            [_vm._v("候選人得票數 (長條圖)")]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        { class: { active: _vm.tabName == "partisan_2" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#partisan_2",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showTab("partisan_2")
                                },
                              },
                            },
                            [_vm._v("候選人得票數 (頭像版)")]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        { class: { active: _vm.tabName == "partisan_3" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#partisan_3",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showTab("partisan_3")
                                },
                              },
                            },
                            [_vm._v("政黨得票率")]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        { class: { active: _vm.tabName == "partisan_5" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "#partisan_5",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showTab("partisan_5")
                                },
                              },
                            },
                            [
                              _vm._v("政黨近年比較圖 "),
                              _c("span", { staticClass: "text-red" }, [
                                _vm._v("(今年與往年比較)"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "tab-content" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "tab-pane",
                            _vm.tabName == "partisan_1" ? "active" : "",
                          ],
                          attrs: { id: "partisan_1" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "chart-area",
                              attrs: { id: "partisan_1_chart" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "partisan_assign",
                                  attrs: { id: "badge_votes" },
                                },
                                [
                                  _c("div", { staticClass: "box-header" }, [
                                    _vm.countyNameList[_vm.searchData.county]
                                      ? _c("h3", { staticClass: "box-title" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.countyNameList[
                                                  _vm.searchData.county
                                                ]["縣市"] || ""
                                              ) +
                                              "候選人得票數 "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("ElectionBarChart", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.tabName === "partisan_1",
                                        expression: "tabName === 'partisan_1'",
                                      },
                                    ],
                                    ref: "electionBarChart",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default btn-download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadPhoto(
                                    "partisan_1_chart",
                                    _vm.countyNameList[_vm.searchData.county]
                                      .縣市 + "候選人得票數-長條圖"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 匯出圖片 ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "tab-pane",
                            _vm.tabName == "partisan_2" ? "active" : "",
                          ],
                          attrs: { id: "partisan_2" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "chart-area",
                              attrs: { id: "partisan_2_chart" },
                            },
                            [
                              _c("div", { attrs: { id: "got_elected" } }, [
                                _c("div", { staticClass: "box-header" }, [
                                  _vm.countyNameList[_vm.searchData.county]
                                    ? _c("h3", { staticClass: "box-title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.countyNameList[
                                                _vm.searchData.county
                                              ].縣市
                                            ) +
                                            "候選人得票數 "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "partisan_assign" },
                                  _vm._l(
                                    _vm.mayorDatas.candidateList,
                                    function (mayor) {
                                      return _c(
                                        "div",
                                        {
                                          key: "no" + mayor.candidateNum,
                                          class: [
                                            "item",
                                            mayor.electStatus === "normal" ||
                                            mayor.electStatus === "woman"
                                              ? "is-winner"
                                              : "",
                                          ],
                                        },
                                        [
                                          _c("div", { staticClass: "photo" }, [
                                            _c("div", {
                                              staticClass: "pic",
                                              style:
                                                "background-image: url(" +
                                                mayor.candidateImage +
                                                "?time=" +
                                                new Date().valueOf() +
                                                ")",
                                            }),
                                          ]),
                                          _c("p", { staticClass: "name" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(mayor.candidateName) +
                                                " "
                                            ),
                                            _c("i", { staticClass: "badge" }, [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    mayor.candidatePartyImage +
                                                    "?time=" +
                                                    new Date().valueOf(),
                                                  alt: mayor.candidatePartyName,
                                                },
                                              }),
                                            ]),
                                          ]),
                                          _c("p", { staticClass: "tickets" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.numberWithCommas(
                                                    mayor.tickets
                                                  )
                                                ) +
                                                "票 "
                                            ),
                                            mayor.electStatus === "normal" ||
                                            mayor.electStatus === "woman"
                                              ? _c(
                                                  "i",
                                                  { staticClass: "icon-mark" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/icon-mark.png"),
                                                        alt: "當選",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default btn-download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadPhoto(
                                    "partisan_2_chart",
                                    _vm.countyNameList[_vm.searchData.county]
                                      .縣市 + "候選人得票數-頭像版"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 匯出圖片 ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "tab-pane",
                            _vm.tabName == "partisan_3" ? "active" : "",
                          ],
                          attrs: { id: "partisan_3" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "chart-area",
                              attrs: { id: "partisan_3_chart" },
                            },
                            [
                              _vm.countyNameList[_vm.searchData.county]
                                ? _c(
                                    "h3",
                                    { staticClass: "box-title sr-only" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.countyNameList[
                                              _vm.searchData.county
                                            ].縣市
                                          ) +
                                          "政黨得票率 "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "partisan_assign",
                                  attrs: { id: "vote_rate" },
                                },
                                [
                                  _c("ElectionPieChart", {
                                    ref: "electionPieChart",
                                    attrs: {
                                      chartTitle: "政黨得票率",
                                      year: _vm.searchData.year,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default btn-download",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadPhoto(
                                    "partisan_3",
                                    _vm.countyNameList[_vm.searchData.county]
                                      .縣市 + "政黨得票率"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 匯出圖片 ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "tab-pane",
                            _vm.tabName == "partisan_5" ? "active" : "",
                          ],
                          attrs: { id: "partisan_5" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "partisan_assign has-photo",
                              attrs: { id: "comparison" },
                            },
                            [
                              _c("div", { staticClass: "flex-block" }, [
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "partisan_5_chart_b" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.countyNameList[
                                            _vm.searchData.county
                                          ]
                                            ? _c(
                                                "h3",
                                                { staticClass: "box-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.countyNameList[
                                                          _vm.searchData.county
                                                        ].縣市
                                                      ) +
                                                      "政黨近年比較圖 "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("ElectionPieChart", {
                                            ref: "electionPieChart_partyPrev",
                                            attrs: {
                                              chartTitle: _vm.prevYear,
                                              multiple: true,
                                              hasPhoto: true,
                                            },
                                          }),
                                          _vm.cityPartyVotesList[0]
                                            ? _c(
                                                "p",
                                                { staticClass: "tickets" },
                                                [
                                                  _vm._v(
                                                    " 總有效票數：" +
                                                      _vm._s(
                                                        _vm
                                                          .cityPartyVotesList[0]
                                                          .totalTickets
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._m(0),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "partisan_5_chart_b",
                                            _vm.prevYear +
                                              _vm.countyNameList[
                                                _vm.searchData.county
                                              ].縣市 +
                                              "政黨近年比較圖"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "icon-right",
                                  style:
                                    "background-image: url(" +
                                    _vm.iconRight +
                                    ")",
                                }),
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "partisan_5_chart_a" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.countyNameList[
                                            _vm.searchData.county
                                          ]
                                            ? _c(
                                                "h3",
                                                { staticClass: "box-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.countyNameList[
                                                          _vm.searchData.county
                                                        ].縣市
                                                      ) +
                                                      "政黨近年比較圖 "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("ElectionPieChart", {
                                            ref: "electionPieChart_partyThis",
                                            attrs: {
                                              chartTitle: _vm.thisYear,
                                              multiple: true,
                                              hasPhoto: true,
                                            },
                                          }),
                                          _vm.cityPartyVotesList[1]
                                            ? _c(
                                                "p",
                                                { staticClass: "tickets" },
                                                [
                                                  _vm._v(
                                                    " 總有效票數：" +
                                                      _vm._s(
                                                        _vm
                                                          .cityPartyVotesList[1]
                                                          .totalTickets
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._m(1),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "partisan_5_chart_a",
                                            _vm.thisYear +
                                              _vm.countyNameList[
                                                _vm.searchData.county
                                              ].縣市 +
                                              "政黨近年比較圖"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c("h2", { staticClass: "page-header" }, [_vm._v("全國圖表")]),
                _c(
                  "div",
                  {
                    staticClass: "nav-tabs-custom",
                    attrs: { id: "gobalChart" },
                  },
                  [
                    _vm._m(2),
                    _c("div", { staticClass: "tab-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane active",
                          attrs: { id: "national_1" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "partisan_assign",
                              attrs: { id: "global_rate" },
                            },
                            [
                              _c("div", { staticClass: "flex-block" }, [
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "national_1_chart_b" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "box-title" },
                                            [_vm._v("政黨得票率")]
                                          ),
                                          _c("Election3dChart", {
                                            ref: "election3dChart_prev",
                                            attrs: { chartTitle: _vm.prevYear },
                                          }),
                                          _vm._m(3),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "national_1_chart_b",
                                            `${_vm.prevYear}全國圖表-政黨得票率`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "icon-right",
                                  style:
                                    "background-image: url(" +
                                    _vm.iconRight +
                                    ")",
                                }),
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "national_1_chart_a" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "box-title" },
                                            [_vm._v("政黨得票率")]
                                          ),
                                          _c("Election3dChart", {
                                            ref: "election3dChart_this",
                                            attrs: { chartTitle: _vm.thisYear },
                                          }),
                                          _vm._m(4),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "national_1_chart_a",
                                            `${_vm.thisYear}全國圖表-政黨得票率`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane",
                          attrs: { id: "national_2" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "partisan_assign",
                              attrs: { id: "global_territory" },
                            },
                            _vm._l(_vm.partySeats, function (seat, index) {
                              return _c(
                                "div",
                                { key: seat.year, staticClass: "year-group" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: {
                                        id: `national_2_chart_${
                                          seat.year == _vm.prevYear ? "b" : "a"
                                        }`,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c("h3", { staticClass: "box-title" }, [
                                          _vm._v("縣市長選舉政黨版圖"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "year-item" },
                                          [
                                            _c("div", { staticClass: "year" }, [
                                              _vm._v(_vm._s(seat.year)),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "badges" },
                                              _vm._l(
                                                seat.data,
                                                function (subSeat, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key:
                                                        seat.year +
                                                        subSeat.name +
                                                        index,
                                                      class: [
                                                        "item",
                                                        _vm.switchBadgeId(
                                                          subSeat.name
                                                        ),
                                                      ],
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        { staticClass: "icon" },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                "https://dev77.newtalk.tw/images/election/parties/" +
                                                                (subSeat.name ===
                                                                "其他黨派"
                                                                  ? "無黨籍及未經政黨推薦"
                                                                  : subSeat.name) +
                                                                ".png?time=" +
                                                                new Date().valueOf(),
                                                              alt: subSeat.name,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "table",
                                                        {
                                                          staticClass: "table",
                                                        },
                                                        [
                                                          _c("thead", [
                                                            _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "subtitle",
                                                              },
                                                              [
                                                                _c(
                                                                  "th",
                                                                  [
                                                                    subSeat.name ==
                                                                    "無黨籍及未經政黨推薦"
                                                                      ? [
                                                                          _vm._v(
                                                                            " 無黨籍"
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            "& 其他 "
                                                                          ),
                                                                        ]
                                                                      : subSeat.name ==
                                                                        "中國國民黨"
                                                                      ? [
                                                                          _vm._v(
                                                                            " 國民黨 "
                                                                          ),
                                                                        ]
                                                                      : subSeat.name ==
                                                                        "民主進步黨"
                                                                      ? [
                                                                          _vm._v(
                                                                            " 民進黨 "
                                                                          ),
                                                                        ]
                                                                      : subSeat.name ==
                                                                        "台灣民眾黨"
                                                                      ? [
                                                                          _vm._v(
                                                                            " 民眾黨 "
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                subSeat.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "seat",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        subSeat.total
                                                                      ) + " 席"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v("六都"),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "seat",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        subSeat.municipality ||
                                                                          0
                                                                      ) +
                                                                      " 席 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("tr", [
                                                              _c("td", [
                                                                _vm._v(
                                                                  "地方縣市"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "seat",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subSeat.others ||
                                                                        0
                                                                    ) + " 席"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "map" },
                                              [
                                                _c("ElectionMapChart", {
                                                  ref: "electionMapChart",
                                                  refInFor: true,
                                                  attrs: { datas: seat.map },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._m(5, true),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            `national_2_chart_${
                                              seat.year == _vm.prevYear
                                                ? "b"
                                                : "a"
                                            }`,
                                            `${seat.year}全國圖表-縣市長選舉政黨版圖`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _vm.category == "總統"
            ? [
                _c("h2", { staticClass: "page-header" }, [_vm._v("全國圖表")]),
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c(
                      "li",
                      { class: { active: _vm.tabName == "president_1" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#president_1",
                              "data-toggle": "tab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTab("president_1")
                              },
                            },
                          },
                          [_vm._v("候選人得票數")]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { class: { active: _vm.tabName == "president_2" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#president_2",
                              "data-toggle": "tab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTab("president_2")
                              },
                            },
                          },
                          [
                            _vm._v("政黨得票率"),
                            _c("span", { staticClass: "text-red" }, [
                              _vm._v("(今年與往年比較)"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "tab-pane",
                          _vm.tabName == "president_1" ? "active" : "",
                        ],
                        attrs: { id: "president_1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "chart-area",
                            attrs: { id: "president_1_chart" },
                          },
                          [
                            _c("div", { attrs: { id: "got_elected" } }, [
                              _c(
                                "div",
                                { staticClass: "partisan_assign" },
                                _vm._l(
                                  _vm.mayorDatas.candidateList,
                                  function (mayor) {
                                    return _c(
                                      "div",
                                      {
                                        key: "no" + mayor.candidateNum,
                                        class: [
                                          "item",
                                          mayor.electStatus === "normal" ||
                                          mayor.electStatus === "woman"
                                            ? "is-winner"
                                            : "",
                                        ],
                                      },
                                      [
                                        _c("div", { staticClass: "photo" }, [
                                          mayor.candidate
                                            ? _c("div", {
                                                staticClass: "pic",
                                                style:
                                                  "background-image: url(" +
                                                  mayor.candidate[0].image +
                                                  "?time=" +
                                                  new Date().valueOf() +
                                                  ")",
                                              })
                                            : _vm._e(),
                                        ]),
                                        _c(
                                          "p",
                                          { staticClass: "name" },
                                          [
                                            _vm._l(
                                              mayor.candidate,
                                              function (item) {
                                                return _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ])
                                              }
                                            ),
                                            _c("i", { staticClass: "badge" }, [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    mayor.candidatePartyImage +
                                                    "?time=" +
                                                    new Date().valueOf(),
                                                  alt: mayor.candidatePartyName,
                                                },
                                              }),
                                            ]),
                                          ],
                                          2
                                        ),
                                        _vm.mayorDatas.electionProfileAllCity[
                                          mayor.candidatePartyName
                                        ]
                                          ? _c(
                                              "p",
                                              { staticClass: "tickets" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.numberWithCommas(
                                                        _vm.mayorDatas
                                                          .electionProfileAllCity[
                                                          mayor
                                                            .candidatePartyName
                                                        ].allTickets
                                                      )
                                                    ) +
                                                    "票 "
                                                ),
                                                mayor.electStatus ===
                                                  "normal" ||
                                                mayor.electStatus === "woman"
                                                  ? _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "icon-mark",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/icon-mark.png"),
                                                            alt: "當選",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default btn-download",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPhoto(
                                  "president_1_chart",
                                  `總統候選人得票數-頭像版`
                                )
                              },
                            },
                          },
                          [_vm._v(" 匯出圖片 ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "tab-pane",
                          _vm.tabName == "president_2" ? "active" : "",
                        ],
                        attrs: { id: "president_2" },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "partisan_assign has-photo",
                              attrs: { id: "comparison" },
                            },
                            [
                              _c("div", { staticClass: "flex-block" }, [
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "president_2_chart_b" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "box-title" },
                                            [_vm._v("中華民國總統選舉")]
                                          ),
                                          _c("ElectionPieChart", {
                                            ref: "electionPieChart_president2_Prev",
                                            attrs: {
                                              chartTitle: _vm.prevYear,
                                              multiple: true,
                                              hasPhoto: true,
                                            },
                                          }),
                                          _vm.chartData.president[0]
                                            ? _c(
                                                "p",
                                                { staticClass: "tickets" },
                                                [
                                                  _vm._v(
                                                    " 總有效票數：" +
                                                      _vm._s(
                                                        _vm.chartData
                                                          .president[0]
                                                          .totalTickets
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._m(6),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "president_2_chart_b",
                                            `${_vm.prevYear}政黨得票率`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                                _c("i", {
                                  staticClass: "icon-right",
                                  style:
                                    "background-image: url(" +
                                    _vm.iconRight +
                                    ")",
                                }),
                                _c("div", { staticClass: "flex-item" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chart-area",
                                      attrs: { id: "president_2_chart_a" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "box-title" },
                                            [_vm._v("中華民國總統選舉")]
                                          ),
                                          _c("ElectionPieChart", {
                                            ref: "electionPieChart_president2_This",
                                            attrs: {
                                              chartTitle: _vm.thisYear,
                                              multiple: true,
                                              hasPhoto: true,
                                            },
                                          }),
                                          _vm.chartData.president[1]
                                            ? _c(
                                                "p",
                                                { staticClass: "tickets" },
                                                [
                                                  _vm._v(
                                                    " 總有效票數：" +
                                                      _vm._s(
                                                        _vm.chartData
                                                          .president[1]
                                                          .totalTickets
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._m(7),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default btn-download",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadPhoto(
                                            "president_2_chart_a",
                                            `${_vm.thisYear}政黨得票率`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 匯出圖片 ")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm.category == "立委"
            ? [
                _c("h2", { staticClass: "page-header" }, [
                  _vm._v(
                    _vm._s(
                      _vm.searchData.area == "原住民" ? "原住民" : "各縣市"
                    ) + "區域立委圖表"
                  ),
                ]),
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c(
                      "li",
                      { class: { active: _vm.tabName == "legislator_1" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#legislator_1",
                              "data-toggle": "tab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTab("legislator_1")
                              },
                            },
                          },
                          [
                            _vm.searchData.area != "原住民"
                              ? _c("span", [_vm._v("區域立委得票數(長條圖)")])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.searchData.county == "00000-01"
                                          ? "山地"
                                          : "平地"
                                      ) +
                                      "立委得票數(長條圖) "
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { class: { active: _vm.tabName == "legislator_2" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#legislator_2",
                              "data-toggle": "tab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTab("legislator_2")
                              },
                            },
                          },
                          [
                            _vm.searchData.area != "原住民"
                              ? _c("span", [_vm._v("區域立委得票數(有頭像版)")])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.searchData.county == "00000-01"
                                          ? "山地"
                                          : "平地"
                                      ) +
                                      "立委得票數(有頭像版) "
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      { class: { active: _vm.tabName == "legislator_3" } },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "#legislator_3",
                              "data-toggle": "tab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTab("legislator_3")
                              },
                            },
                          },
                          [
                            _vm.searchData.area != "原住民"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.countyNameList[
                                          _vm.searchData.county
                                        ]["縣市"]
                                      ) +
                                      "立委席次分配"
                                  ),
                                  _c("span", { staticClass: "text-red" }, [
                                    _vm._v("(今年與往年比較)"),
                                  ]),
                                ])
                              : _c("span", [
                                  _vm._v(" 原住民立委席次分配"),
                                  _c("span", { staticClass: "text-red" }, [
                                    _vm._v("(今年與往年比較)"),
                                  ]),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "tab-pane",
                          _vm.tabName == "legislator_1" ? "active" : "",
                        ],
                        attrs: { id: "legislator_1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "chart-area",
                            attrs: { id: "legislator_1_chart" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "partisan_assign",
                                attrs: { id: "badge_votes" },
                              },
                              [
                                _c("div", { staticClass: "box-header" }, [
                                  _vm.searchData.area != "原住民"
                                    ? _c("h3", { staticClass: "box-title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.searchData.year) +
                                            _vm._s(
                                              _vm.constituency[
                                                _vm.searchData.constituency - 1
                                              ]
                                            ) +
                                            "立委得票數 "
                                        ),
                                      ])
                                    : _c("h3", { staticClass: "box-title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.searchData.year) +
                                            _vm._s(
                                              _vm.searchData.county ==
                                                "00000-01"
                                                ? "山地"
                                                : "平地"
                                            ) +
                                            "原住民立委得票數 "
                                        ),
                                      ]),
                                ]),
                                _c("ElectionBarChart", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tabName === "legislator_1",
                                      expression: "tabName === 'legislator_1'",
                                    },
                                  ],
                                  ref: "electionBarChart",
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default btn-download",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPhoto(
                                  "legislator_1_chart",
                                  _vm.searchData.area != "原住民"
                                    ? `${
                                        _vm.constituency[
                                          _vm.searchData.constituency - 1
                                        ]
                                      }立委得票數-長條圖`
                                    : _vm.searchData.county == "00000-01"
                                    ? `山地原住民立委得票數-長條圖`
                                    : `平地原住民立委得票數-長條圖`
                                )
                              },
                            },
                          },
                          [_vm._v(" 匯出圖片 ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "tab-pane",
                          _vm.tabName == "legislator_2" ? "active" : "",
                        ],
                        attrs: { id: "legislator_2" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "chart-area",
                            attrs: { id: "legislator_2_chart" },
                          },
                          [
                            _c("div", { attrs: { id: "got_elected" } }, [
                              _c("div", { staticClass: "box-header" }, [
                                _vm.searchData.area != "原住民"
                                  ? _c("h3", { staticClass: "box-title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.searchData.year) +
                                          _vm._s(
                                            _vm.constituency[
                                              _vm.searchData.constituency - 1
                                            ]
                                          ) +
                                          "立委得票數 "
                                      ),
                                    ])
                                  : _c("h3", { staticClass: "box-title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.searchData.year) +
                                          _vm._s(
                                            _vm.searchData.county == "00000-01"
                                              ? "山地"
                                              : "平地"
                                          ) +
                                          "原住民立委得票數 "
                                      ),
                                    ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "partisan_assign",
                                  class: [
                                    _vm.setNameHeight(),
                                    _vm.searchData.area == "原住民"
                                      ? "icon_in_photo"
                                      : "",
                                  ],
                                  style: _vm.legislatorSize(
                                    _vm.mayorDatas.candidateList
                                  ),
                                },
                                _vm._l(
                                  _vm.mayorDatas.candidateList,
                                  function (mayor) {
                                    return _c(
                                      "div",
                                      {
                                        key: "no" + mayor.candidateNum,
                                        class: [
                                          "item",
                                          mayor.electStatus === "normal" ||
                                          mayor.electStatus === "woman"
                                            ? "is-winner"
                                            : "",
                                        ],
                                      },
                                      [
                                        _c("div", { staticClass: "photo" }, [
                                          _c("div", {
                                            staticClass: "pic",
                                            style:
                                              "background-image: url(" +
                                              mayor.candidateImage +
                                              "?time=" +
                                              new Date().valueOf() +
                                              ")",
                                          }),
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "name",
                                            class: _vm.setNameSize(
                                              mayor.candidateName
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(mayor.candidateName) +
                                                " "
                                            ),
                                            _c("i", { staticClass: "badge" }, [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    mayor.candidatePartyImage +
                                                    "?time=" +
                                                    new Date().valueOf(),
                                                  alt: mayor.candidatePartyName,
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                        _c("p", { staticClass: "tickets" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.numberWithCommas(
                                                  mayor.tickets
                                                )
                                              ) +
                                              "票 "
                                          ),
                                          mayor.electStatus === "normal" ||
                                          mayor.electStatus === "woman"
                                            ? _c(
                                                "i",
                                                { staticClass: "icon-mark" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/icon-mark.png"),
                                                      alt: "當選",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default btn-download",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPhoto(
                                  "legislator_2_chart",
                                  _vm.searchData.area != "原住民"
                                    ? `${
                                        _vm.constituency[
                                          _vm.searchData.constituency - 1
                                        ]
                                      }立委得票數-頭像版`
                                    : _vm.searchData.county == "00000-01"
                                    ? `山地原住民立委得票數-頭像版`
                                    : `平地原住民立委得票數-頭像版`
                                )
                              },
                            },
                          },
                          [_vm._v(" 匯出圖片 ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "tab-pane",
                          _vm.tabName == "legislator_3" ? "active" : "",
                        ],
                        attrs: { id: "legislator_3" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "partisan_assign has-photo",
                            attrs: { id: "comparison" },
                          },
                          [
                            _c("div", { staticClass: "flex-block" }, [
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "legislator_3_chart_b" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.searchData.area != "原住民"
                                          ? _c(
                                              "h3",
                                              { staticClass: "box-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.countyNameList[
                                                        _vm.searchData.county
                                                      ]["縣市"]
                                                    ) +
                                                    "立委席次分配(席) "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "h3",
                                              { staticClass: "box-title" },
                                              [
                                                _vm._v(
                                                  " 原住民立委席次分配(席) "
                                                ),
                                              ]
                                            ),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_legislator3_Prev",
                                          attrs: {
                                            chartTitle: _vm.prevYear,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                        _vm.chartData.legislatorCounty[0]
                                          ? _c(
                                              "p",
                                              { staticClass: "tickets" },
                                              [
                                                _vm._v(
                                                  " 總有效票數：" +
                                                    _vm._s(
                                                      _vm.chartData
                                                        .legislatorCounty[0]
                                                        .totalTickets
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._m(8),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadPhoto(
                                          "legislator_3_chart_b",
                                          _vm.searchData.area != "原住民"
                                            ? `${_vm.prevYear}${
                                                _vm.countyNameList[
                                                  _vm.searchData.county
                                                ]["縣市"]
                                              }立委席次分配`
                                            : `原住民立委席次分配`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                              _c("i", {
                                staticClass: "icon-right",
                                style:
                                  "background-image: url(" +
                                  _vm.iconRight +
                                  ")",
                              }),
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "legislator_3_chart_a" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.searchData.area != "原住民"
                                          ? _c(
                                              "h3",
                                              { staticClass: "box-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.countyNameList[
                                                        _vm.searchData.county
                                                      ]["縣市"]
                                                    ) +
                                                    "立委席次分配(席) "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "h3",
                                              { staticClass: "box-title" },
                                              [
                                                _vm._v(
                                                  " 原住民立委席次分配(席) "
                                                ),
                                              ]
                                            ),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_legislator3_This",
                                          attrs: {
                                            chartTitle: 2024,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                        _vm.chartData.legislatorCounty[1]
                                          ? _c(
                                              "p",
                                              { staticClass: "tickets" },
                                              [
                                                _vm._v(
                                                  " 總有效票數：" +
                                                    _vm._s(
                                                      _vm.chartData
                                                        .legislatorCounty[1]
                                                        .totalTickets
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._m(9),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadPhoto(
                                          "legislator_3_chart_a",
                                          _vm.searchData.area != "原住民"
                                            ? `${_vm.thisYear}${
                                                _vm.countyNameList[
                                                  _vm.searchData.county
                                                ]["縣市"]
                                              }立委席次分配`
                                            : `原住民立委席次分配`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("h2", { staticClass: "page-header" }, [_vm._v("全國圖表")]),
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane active",
                        attrs: { id: "legislator_4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "partisan_assign has-photo" },
                          [
                            _c("div", { staticClass: "flex-block" }, [
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "legislator_4_chart_b" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("h3", { staticClass: "box-title" }, [
                                          _vm._v("全國立委席次分配(席)"),
                                        ]),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_legislator4_Prev",
                                          attrs: {
                                            chartTitle: _vm.prevYear,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._m(11),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.downloadPhoto(
                                          "legislator_4_chart_b",
                                          `${_vm.prevYear}全國立委席次分配(席)`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                              _c("i", {
                                staticClass: "icon-right",
                                style:
                                  "background-image: url(" +
                                  _vm.iconRight +
                                  ")",
                              }),
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "legislator_4_chart_a" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("h3", { staticClass: "box-title" }, [
                                          _vm._v("全國立委席次分配(席)"),
                                        ]),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_legislator4_This",
                                          attrs: {
                                            chartTitle: _vm.thisYear,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._m(12),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.downloadPhoto(
                                          "legislator_4_chart_a",
                                          `${_vm.thisYear}全國立委席次分配(席)`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm.category == "政黨"
            ? [
                _c("h2", { staticClass: "page-header" }, [
                  _vm._v("不分區政黨圖表"),
                ]),
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c("li", { staticClass: "active" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#group_1", "data-toggle": "tab" },
                          on: {
                            click: function ($event) {
                              return _vm.showTab("group_1")
                            },
                          },
                        },
                        [
                          _vm._v("不分區政黨席次分配"),
                          _c("span", { staticClass: "text-red" }, [
                            _vm._v("(今年與往年比較)"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane active",
                        attrs: { id: "group_1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "partisan_assign has-photo",
                            attrs: { id: "comparison" },
                          },
                          [
                            _c("h3", { staticClass: "box-title" }, [
                              _vm._v(" 不分區立委席次分配(席) "),
                            ]),
                            _c("div", { staticClass: "flex-block" }, [
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "group_1_chart_b" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("h3", { staticClass: "box-title" }, [
                                          _vm._v("不分區立委席次分配(席)"),
                                        ]),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_party_Prev",
                                          attrs: {
                                            chartTitle: _vm.prevYear,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._m(13),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.downloadPhoto(
                                          "group_1_chart_b",
                                          `${_vm.prevYear}不分區立委席次分配(席)`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                              _c("i", {
                                staticClass: "icon-right",
                                style:
                                  "background-image: url(" +
                                  _vm.iconRight +
                                  ")",
                              }),
                              _c("div", { staticClass: "flex-item" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chart-area",
                                    attrs: { id: "group_1_chart_a" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("h3", { staticClass: "box-title" }, [
                                          _vm._v("不分區立委席次分配(席)"),
                                        ]),
                                        _c("ElectionPieChart", {
                                          ref: "electionPieChart_party_This",
                                          attrs: {
                                            chartTitle: _vm.thisYear,
                                            multiple: true,
                                            isSeat: true,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._m(14),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default btn-download",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.downloadPhoto(
                                          "group_1_chart_a",
                                          `${_vm.thisYear}不分區立委席次分配(席)`
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 匯出圖片 ")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "active" }, [
        _c("a", { attrs: { href: "#national_1", "data-toggle": "tab" } }, [
          _vm._v("政黨得票率"),
        ]),
      ]),
      _c("li", [
        _c("a", { attrs: { href: "#national_2", "data-toggle": "tab" } }, [
          _vm._v("縣市長選舉政黨版圖"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
      _c("p", { staticClass: "text-red" }, [
        _vm._v("嘉義市長選舉延後至12月18日"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav nav-tabs" }, [
      _c("li", { staticClass: "active" }, [
        _c("a", [
          _vm._v("全國立委席次分配(席)"),
          _c("span", { staticClass: "text-red" }, [_vm._v("(今年與往年比較)")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend" }, [
      _c("p", { staticClass: "note" }, [
        _vm._v(
          " 本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }