var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("公告編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公告列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("公告編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.相關公告編號,
                              expression: "edit.相關公告編號",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectUnit", required: "required" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "相關公告編號",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.changeType,
                            ],
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇主分類")]
                          ),
                          _vm._l(
                            _vm.projectData.module,
                            function (item, index) {
                              return _c(
                                "option",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.id == "action" ||
                                        item.id == "news",
                                      expression:
                                        " item.id == 'action' ||  item.id == 'news'",
                                    },
                                  ],
                                  key: index,
                                  domProps: { value: item.id },
                                },
                                [_vm._v(" " + _vm._s(item.name))]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.classTypeId,
                              expression: "edit.classTypeId",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇子分類")]
                          ),
                          _vm._l(_vm.newsTypes.datas, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(_vm._s(item.className))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm.edit.projectId === "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(2),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.detailTopTitle,
                                expression: "edit.detailTopTitle",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "inputTitle",
                              required: "required",
                              type: "text",
                            },
                            domProps: { value: _vm.edit.detailTopTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "detailTopTitle",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId !== "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(3),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.detailTopTitle,
                                expression: "edit.detailTopTitle",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "inputTitle",
                              required: "required",
                              type: "text",
                            },
                            domProps: { value: _vm.edit.detailTopTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "detailTopTitle",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { propLang: _vm.editLang },
                            model: {
                              value: _vm.edit.detailTopInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "detailTopInfo", $$v)
                              },
                              expression: "edit.detailTopInfo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.isSpecialPage
                    ? [
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(5),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              {
                                staticClass: "radio radio-inline",
                                staticStyle: { "padding-left": "0" },
                              },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: "1",
                                    message: "即日起",
                                    name: "radioisNowOn",
                                  },
                                  model: {
                                    value: _vm.edit.isNowOn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "isNowOn", $$v)
                                    },
                                    expression: "edit.isNowOn",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "radio radio-inline _time" },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: "0",
                                    message: "",
                                    name: "radioisNowOn",
                                  },
                                  model: {
                                    value: _vm.edit.isNowOn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "isNowOn", $$v)
                                    },
                                    expression: "edit.isNowOn",
                                  },
                                }),
                                _c("div", { staticClass: "radio-date" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group date" },
                                    [
                                      _vm._m(6),
                                      _c(
                                        "div",
                                        { staticClass: "pull-right" },
                                        [
                                          _c("DatePicker", {
                                            attrs: {
                                              propValue: _vm.local.startTime,
                                            },
                                            on: {
                                              "update:propValue": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.local,
                                                  "startTime",
                                                  $event
                                                )
                                              },
                                              "update:prop-value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.local,
                                                  "startTime",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(7),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("div", { staticClass: "radio-date" }, [
                              _c("div", { staticClass: "input-group date" }, [
                                _vm._m(8),
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c("DatePicker", {
                                      attrs: { propValue: _vm.local.endTime },
                                      on: {
                                        "update:propValue": function ($event) {
                                          return _vm.$set(
                                            _vm.local,
                                            "endTime",
                                            $event
                                          )
                                        },
                                        "update:prop-value": function ($event) {
                                          return _vm.$set(
                                            _vm.local,
                                            "endTime",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 圖片： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "pic" }, [
                        _c("img", {
                          attrs: { draggable: "false", src: _vm.edit.filesUrl },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: "image/*",
                          id: "AnnouncementPic2",
                          type: "file",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event)
                          },
                        },
                      }),
                      _c("div", { staticClass: "help-block" }, [
                        _c("span", { staticClass: "text-red" }, [
                          _vm._v(_vm._s(_vm.picLimit)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row ann-imgChoose mt-10" },
                        _vm._l(_vm.imgList, function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isMain == 1,
                                  expression: "item.isMain == 1",
                                },
                              ],
                              key: index,
                              staticClass: "col-sm-3 col-xs-6 chooseBox",
                            },
                            [
                              _c("div", {
                                staticClass: "img-close fa fa-fw fa-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.DeletePic(item)
                                  },
                                },
                              }),
                              _c("img", {
                                attrs: { src: item.imgFullFileName },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-2 control-label" }, [
                      _vm._v(" 檔案上傳： "),
                    ]),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "pic" }, [
                        _c("img", {
                          attrs: {
                            draggable: "false",
                            src: _vm.edit.filesUrl2,
                          },
                        }),
                      ]),
                      _c("input", {
                        attrs: {
                          accept: ".pdf",
                          id: "AnnouncementPic",
                          type: "file",
                          multiple: true,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange2($event)
                          },
                        },
                      }),
                      _c("span", { staticClass: "text-red" }, [
                        _vm._v(" PDF格式，單一檔案上限10MB "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row ann-imgChoose mt-10",
                          staticStyle: { margin: "0" },
                        },
                        _vm._l(_vm.imgList, function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.isMain == 0,
                                  expression: "item.isMain == 0",
                                },
                              ],
                              key: index,
                              staticClass: "chooseBox",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "padding-right": "34px",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    staticStyle: { top: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(item)
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "filename" }, [
                                    _vm._v(_vm._s(item.imgName)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(9),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c(
                          "multiselect",
                          {
                            attrs: {
                              placeholder: "自行輸入標籤",
                              label: "Tag",
                              "track-by": "Tag",
                              required: "",
                              options: _vm.tagList,
                              "hide-selected": true,
                              "internal-search": false,
                              multiple: true,
                              taggable: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              selectLabel: "",
                              "max-elements": "已達標籤上限",
                            },
                            on: { tag: _vm.addTag },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "option__desc" }, [
                                      _c(
                                        "span",
                                        { staticClass: "option__title" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(props.option.Tag) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.tag,
                              callback: function ($$v) {
                                _vm.tag = $$v
                              },
                              expression: "tag",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "notice",
                                attrs: { slot: "noOptions" },
                                slot: "noOptions",
                              },
                              [_vm._v("列表為空")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "notice",
                                attrs: { slot: "noResult" },
                                slot: "noResult",
                              },
                              [_vm._v("查無結果")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "notice maxElements",
                                attrs: { slot: "maxElements" },
                                slot: "maxElements",
                              },
                              [_vm._v(" 已達標籤上限 ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(10),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "上架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(11),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.local.publishTime },
                                on: {
                                  "update:propValue": function ($event) {
                                    return _vm.$set(
                                      _vm.local,
                                      "publishTime",
                                      $event
                                    )
                                  },
                                  "update:prop-value": function ($event) {
                                    return _vm.$set(
                                      _vm.local,
                                      "publishTime",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(12),
                      ]),
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.isStop,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "isStop", $$v)
                              },
                              expression: "edit.isStop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.edit.projectId == "omp_wanin"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label",
                            attrs: { for: "inputTitle" },
                          },
                          [_vm._v(" 網銀官網Link： ")]
                        ),
                        _c("div", [
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.splitNewsIdList.link1,
                                  expression: "splitNewsIdList.link1",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "inputNewsId1", type: "text" },
                              domProps: { value: _vm.splitNewsIdList.link1 },
                              on: {
                                change: function ($event) {
                                  return _vm.ChangeNewsId($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.splitNewsIdList,
                                    "link1",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.splitNewsIdList.link2,
                                  expression: "splitNewsIdList.link2",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "inputNewsId2", type: "text" },
                              domProps: { value: _vm.splitNewsIdList.link2 },
                              on: {
                                change: function ($event) {
                                  return _vm.ChangeNewsId($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.splitNewsIdList,
                                    "link2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _vm.edit.isStop == 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning btn-lg",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save(true)
                            },
                          },
                        },
                        [_vm._v("預覽")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 主分類："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 子分類："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 起始日期："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 活動結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }