var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "main-header" }, [
    _vm._m(0),
    _c("nav", { staticClass: "navbar navbar-static-top" }, [
      _vm._m(1),
      _vm.projectId != "omp_slot"
        ? _c("h2", { staticClass: "name-websit" }, [_vm._v("營運整合工具")])
        : _vm._e(),
      _vm.projectId == "omp_slot"
        ? _c("div", { staticClass: "header-search" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.search()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.keyword,
                      expression: "keyword",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: "全站搜尋(APP、遊戲、集成分析",
                  },
                  domProps: { value: _vm.keyword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.keyword = $event.target.value
                    },
                  },
                }),
                _c("i", {
                  staticClass: "fa fa-search",
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                }),
                _vm.keyword != ""
                  ? _c("i", {
                      staticClass: "fa fa-close",
                      on: {
                        click: function ($event) {
                          _vm.keyword = ""
                        },
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "navbar-custom-menu" },
        [
          _c("ul", { staticClass: "nav navbar-nav" }, [
            _c(
              "li",
              { staticClass: "dropdown" },
              [
                (_vm.$user.hasPermission("ChannelManager") ||
                  _vm.$user.hasPermission("MessageSystem")) &&
                _vm.$store.state.jwtToken != ""
                  ? _c("MessageSystemSideTool", {
                      attrs: { MSProject: _vm.projectId },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.projectId === "omp_nmj" &&
            !/^(stage|production)$/.test(_vm.environment)
              ? _c("li", { staticClass: "dropdown project-select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.environment,
                          expression: "environment",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.environment = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.changeEnvironment,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "development" } }, [
                        _vm._v("測試版API"),
                      ]),
                      _c("option", { attrs: { value: "test" } }, [
                        _vm._v("審核版API"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.langs.length > 0
              ? _c("li", { staticClass: "dropdown project-select" }, [
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      domProps: { value: _vm.lang },
                      on: { change: _vm.changeLang },
                    },
                    _vm._l(_vm.langs, function (lang) {
                      return _c(
                        "option",
                        { key: lang.id, domProps: { value: lang.id } },
                        [_vm._v(_vm._s(lang.name) + " ")]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _c("li", { staticClass: "dropdown project-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.projectId,
                      expression: "projectId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.projectId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.changeProject,
                    ],
                  },
                },
                _vm._l(_vm.projects, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.projectId } },
                    [_vm._v(_vm._s(_vm.$t(item.projectName)) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("li", { staticClass: "dropdown user user-menu" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  attrs: { "data-toggle": "dropdown", href: "#" },
                },
                [
                  _c("img", {
                    staticClass: "user-image",
                    attrs: {
                      alt: "User Image",
                      src: require("../assets/user-160x160.jpg"),
                    },
                  }),
                  _c("span", { staticClass: "hidden-xs" }, [
                    _vm._v(_vm._s(_vm.loginUser.EmployeeName)),
                  ]),
                ]
              ),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c("li", { staticClass: "user-header" }, [
                  _c("img", {
                    staticClass: "img-circle",
                    attrs: {
                      alt: "User Image",
                      src: require("../assets/user-160x160.jpg"),
                    },
                  }),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.loginUser.DeptName) +
                        " - " +
                        _vm._s(_vm.loginUser.EmployeeName) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.loginUser.EmpTitleName) + " "),
                  ]),
                ]),
                _c("li", { staticClass: "user-footer" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default btn-flat mr-10",
                        on: { click: _vm.logout },
                      },
                      [_vm._v("登出")]
                    ),
                    _vm.loginUser.IsOutsider == 1
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-danger btn-flat",
                            on: { click: _vm.midify },
                          },
                          [_vm._v(" 編輯 ")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("midify", { ref: "midify", attrs: { proplogin: true } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "logo", attrs: { href: "/#/Home" } }, [
      _c("h1", { staticClass: "sr-only" }, [
        _vm._v("網銀國際 OMP 營運管理平台"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-toggle",
        attrs: { "data-toggle": "push-menu", href: "#", role: "button" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }