var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "gameContent" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("遊戲內容")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("遊戲管理")]),
          _c("li", [_vm._v("遊戲列表")]),
          _c("li", { staticClass: "active" }, [_vm._v("遊戲內容")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("h4", { attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.$route.params.name) + " 內容說明"),
            ]),
            _c("div", { staticClass: "top-box with-border" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add mr-10",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v(" 新增 ")]
              ),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered classTypeTable",
                  attrs: { id: "classTypes" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm._l(_vm.list, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _vm._v(_vm._s(item.名稱)),
                          ]),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.建立時間,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.更新時間,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "td-sort text-nowrap text-center" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.排序數字,
                                    expression: "item.排序數字",
                                  },
                                ],
                                staticClass: "form-control text-center mr-10",
                                staticStyle: {
                                  width: "60px",
                                  display: "inline-block",
                                  "vertical-align": "middle",
                                },
                                attrs: {
                                  required: "required",
                                  type: "number",
                                  disabled:
                                    item.名稱 == "Info" ||
                                    item.名稱 == "MG 盤面" ||
                                    item.名稱 == "Loading 頁",
                                },
                                domProps: { value: item.排序數字 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "排序數字",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              item.名稱 != "Info" &&
                              item.名稱 != "MG 盤面" &&
                              item.名稱 != "Loading 頁"
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateOrder(item)
                                        },
                                      },
                                    },
                                    [_vm._v("更新")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { align: "center" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.是否啟用,
                                        expression: "item.是否啟用",
                                      },
                                    ],
                                    attrs: {
                                      "false-value": 0,
                                      id: "check" + index,
                                      "true-value": 1,
                                      name: "ckbx-style-8",
                                      type: "checkbox",
                                      disabled: "",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.是否啟用)
                                        ? _vm._i(item.是否啟用, null) > -1
                                        : _vm._q(item.是否啟用, 1),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = item.是否啟用,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "是否啟用",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "是否啟用", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + index },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeStatus(item, true)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(item)
                                  },
                                },
                              },
                              [_vm._v("編輯")]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _vm.total != 0
            ? _c(
                "div",
                { staticClass: "box-footer text-center no-border" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.pageSize,
                          expression: "searchData.pageSize",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control page-size mr-10",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "pageSize",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "20" } }, [
                        _vm._v("每頁筆數20"),
                      ]),
                      _c("option", { attrs: { value: "30" } }, [
                        _vm._v("每頁筆數30"),
                      ]),
                      _c("option", { attrs: { value: "50" } }, [
                        _vm._v("每頁筆數50"),
                      ]),
                    ]
                  ),
                  _c("Pagination", {
                    ref: "pagination",
                    attrs: {
                      total: _vm.total,
                      pageLimitCount: _vm.searchData.pageSize,
                      type2: true,
                    },
                    on: { pageMethod: _vm.onPageChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "25%" },
        },
        [_vm._v("名稱")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { width: "10%" },
        },
        [_vm._v("建立時間")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { width: "10%" },
        },
        [_vm._v("更新時間")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "5%" },
        },
        [_vm._v("排序")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "100px", width: "5%" },
        },
        [_vm._v("啟用")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }