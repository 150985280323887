var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.上層設定
    ? _c("div", { staticClass: "payStyle" }, [
        _c("ul", [
          _c("li", [_vm._v("廠商支付")]),
          _c("li", [
            _vm._v("啟停用： "),
            _vm.上層設定.啟用 == 1
              ? _c("span", [_vm._v("啟用")])
              : _c("span", { staticClass: "text-red" }, [_vm._v("停用")]),
          ]),
        ]),
        _c("ul", [
          _c("li", [_vm._v("金流設定")]),
          _c("li", [
            _vm._v(
              "金流名稱：" +
                _vm._s(
                  _vm.金流設定主檔Key[_vm.上層設定.金流設定主檔編號].金流名稱
                )
            ),
          ]),
          _c("li", [
            _vm._v(
              "金流代碼：" +
                _vm._s(
                  _vm.金流設定主檔Key[_vm.上層設定.金流設定主檔編號].金流代碼
                )
            ),
          ]),
          _c("li", [
            _vm._v("啟停用： "),
            _vm.金流設定主檔Key[_vm.上層設定.金流設定主檔編號].啟用 == 1
              ? _c("span", [_vm._v("啟用")])
              : _c("span", { staticClass: "text-red" }, [_vm._v("停用")]),
          ]),
        ]),
        _c("ul", [
          _c("li", [_vm._v("金流帳號")]),
          _c("li", [
            _vm._v(
              "名稱：" +
                _vm._s(_vm.金流帳號Key[_vm.上層設定.金流帳號編號].名稱) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v(
              "金流識別值：" +
                _vm._s(_vm.金流帳號Key[_vm.上層設定.金流帳號編號].金流識別值) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v("啟停用： "),
            _vm.金流帳號Key[_vm.上層設定.金流帳號編號].啟用 == 1
              ? _c("span", [_vm._v("啟用")])
              : _c("span", { staticClass: "text-red" }, [_vm._v("停用")]),
          ]),
        ]),
        _c("ul", [
          _c("li", [_vm._v("金流支付")]),
          _c("li", [
            _vm._v(
              "支付名稱：" +
                _vm._s(
                  _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號].支付名稱
                ) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v(
              "支付方式代碼：" +
                _vm._s(
                  _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號]
                    .支付方式代碼
                ) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v(
              "地區：" +
                _vm._s(
                  _vm.地區列表Key[
                    _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號].地區
                  ].地區
                )
            ),
          ]),
          _c("li", [
            _vm._v(
              "限制幣別：" +
                _vm._s(
                  _vm.地區列表Key[
                    _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號].限制幣別
                  ].幣別
                ) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v(
              "金流類型：" +
                _vm._s(
                  _vm.金流類型設定Key[
                    _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號].金流類型
                  ].類型
                ) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v(
              "是否開立發票：" +
                _vm._s(
                  _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號]
                    .是否開立發票 == 1
                    ? "是"
                    : "否"
                ) +
                " "
            ),
          ]),
          _c("li", [
            _vm._v("啟停用： "),
            _vm.金流支付設定Key[_vm.上層設定.金流支付設定編號].啟用 == 1
              ? _c("span", [_vm._v("啟用")])
              : _c("span", { staticClass: "text-red" }, [_vm._v("停用")]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }