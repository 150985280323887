var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjEventAnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲Banner設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲Banner設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content customer-service" }, [
      _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
        _c(
          "ul",
          { staticClass: "nav nav-tabs" },
          _vm._l(_vm.def.bannerType, function (item, key) {
            return _c(
              "li",
              { key: key, class: { active: _vm.searchData.分類 == key } },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `#${item}`,
                      "aria-expanded": "true",
                      "data-toggle": "tab",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList(+key, -1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                ),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "tab-content" }, [
          _c("div", { staticClass: "tab-pane active" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addNew()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增")]
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("狀態："),
                ]),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.是否開啟,
                          expression: "searchData.是否開啟",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "是否開啟",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("全部"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [_vm._v("關閉")]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("開啟")]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-bordered table-center min-800 AdListTable",
                },
                [
                  _vm._m(0),
                  _vm.total > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.公告編號 }, [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.公告編號)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("toggle-button", {
                                  attrs: { labels: true },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateStatus(item)
                                    },
                                  },
                                  model: {
                                    value: item.status,
                                    callback: function ($$v) {
                                      _vm.$set(item, "status", $$v)
                                    },
                                    expression: "item.status",
                                  },
                                }),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "text-red":
                                        _vm.GetStatus(item) == "已過期",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.GetStatus(item)))]
                                ),
                              ],
                              1
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.GetPlatfrom(item.顯示平台))),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.def.bannerType[item.分類])),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.公告名稱)),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.fileUrl,
                                      expression: "item.fileUrl",
                                    },
                                  ],
                                  staticClass: "pic jqimgFill",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.fileUrl,
                                      alt: "",
                                      draggable: "false",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "td-sort text-nowrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.排序,
                                    expression: "item.排序",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  max: _vm.total,
                                },
                                domProps: { value: item.排序 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "排序", $event.target.value)
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-warning btn-add btn-upDate",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateSort(item)
                                    },
                                  },
                                },
                                [_vm._v("更新")]
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.uiDate(item.開始時間))),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  item.是否常駐顯示 === 1
                                    ? "常駐"
                                    : _vm.uiDate(item.結束時間)
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v("  "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v("刪除")]
                              ),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _c("tbody", [_vm._m(1)]),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box text-center no-border no-shadow mt-20" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "60px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("編號")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("狀態")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "80px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("平台")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "105px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("分類")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "105px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("名稱")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "105px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("縮圖")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "88px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("排序")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("上線"), _c("wbr"), _vm._v("時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "92px",
              width: "10%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("下線"), _c("wbr"), _vm._v("時間")]
        ),
        _c(
          "th",
          {
            staticStyle: {
              "min-width": "140px",
              width: "5%",
              "word-break": "keep-all",
            },
          },
          [_vm._v("功能")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("查詢沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }