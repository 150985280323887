var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.isSpecialPage ? "優惠專案" : "公告列表"))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v(_vm._s(_vm.isSpecialPage ? "優惠專案" : "公告管理"))]),
        _vm.isSpecialPage
          ? _c("li", { staticClass: "active" }, [_vm._v("公告列表")])
          : _vm._e(),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.isSpecialPage ? "新增優惠" : "新增訊息") +
                    " "
                ),
              ]
            ),
            _vm.projectId == "omp_xinstars" || _vm.projectId == "omp_kf3"
              ? _c("div", { staticClass: "date-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("發布日期："),
                  ]),
                  _c("div", { staticClass: "input-group date" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _c("DateRangePicker", {
                          attrs: {
                            propEndDate: _vm.searchData.EndTime,
                            propStartDate: _vm.searchData.StartTime,
                            propsIn: "format2",
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(_vm.searchData, "EndTime", $event)
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(_vm.searchData, "EndTime", $event)
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "StartTime",
                                $event
                              )
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(
                                _vm.searchData,
                                "StartTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.projectId !== "omp_heti"
              ? _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.classTypeId,
                          expression: "searchData.classTypeId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "classTypeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.clickSearch,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                      _vm._l(_vm.newsTypes.datas, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.classTypeId } },
                          [_vm._v(_vm._s(item.className))]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.keyword,
                    expression: "searchData.keyword",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋關鍵字",
                  type: "text",
                },
                domProps: { value: _vm.searchData.keyword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "keyword", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.news.total)),
                ]),
              ]),
            ]),
            _vm.projectId == "omp_kf3"
              ? _c("div", { staticClass: "dropdown sort-dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sort dropdown-toggle",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dropdown =
                            _vm.dropdown == "none" ? "block" : "none"
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon-sort" }),
                      _vm._v(" 排序依據 "),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu",
                      style: { display: `${_vm.dropdown}` },
                    },
                    [
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: [
                              "dropdown-item",
                              _vm.searchData.排序條件 == "發布日期"
                                ? "active"
                                : "",
                            ],
                            attrs: { href: "javaScript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData("發布日期")
                              },
                            },
                          },
                          [_vm._v(" 發布日期 ")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: [
                              "dropdown-item",
                              _vm.searchData.排序條件 == "建立日期"
                                ? "active"
                                : "",
                            ],
                            attrs: { href: "javaScript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData("建立日期")
                              },
                            },
                          },
                          [_vm._v(" 建立日期 ")]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm.projectId === "omp_heti"
                      ? _c("tr", { staticClass: "bg-gray" }, [
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("ID")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { "min-width": "90px", width: "3%" },
                            },
                            [_vm._v("置頂")]
                          ),
                          _vm.$route.name == "HetiSpecialOffer"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "5%" },
                                },
                                [_vm._v("優惠新訊")]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "20%" },
                            },
                            [_vm._v("標題")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("發佈日期")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [_vm._v("狀態")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: {
                                "min-width": "150px",
                                width: "10%",
                              },
                            },
                            [_vm._v("功能")]
                          ),
                        ])
                      : _c("tr", { staticClass: "bg-gray" }, [
                          _vm.projectId !== "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "10%" },
                                },
                                [_vm._v("id")]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticStyle: { "min-width": "90px", width: "5%" },
                            },
                            [_vm._v("分類")]
                          ),
                          _vm.projectId !== "omp_dem"
                            ? _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "90px",
                                    width: "5%",
                                  },
                                },
                                [_vm._v("主圖")]
                              )
                            : _vm._e(),
                          _vm.projectId !== "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticStyle: {
                                    "min-width": "90px",
                                    width: "3%",
                                  },
                                },
                                [_vm._v("置頂")]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("主旨")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("發佈日期")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [_vm._v("狀態")]
                          ),
                          _vm.projectId == "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "5%" },
                                },
                                [_vm._v("瀏覽人數")]
                              )
                            : _vm._e(),
                          _vm.projectId !== "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "3%" },
                                },
                                [_vm._v("建立者工號")]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "3%" },
                            },
                            [_vm._v("建立者")]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("建立日期")]
                          ),
                          _vm.projectId == "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "3%" },
                                },
                                [_vm._v("更新者")]
                              )
                            : _vm._e(),
                          _vm.projectId == "omp_xinstars"
                            ? _c(
                                "th",
                                {
                                  staticClass: "text-nowrap",
                                  staticStyle: { width: "10%" },
                                },
                                [_vm._v("更新日期")]
                              )
                            : _vm._e(),
                          _c(
                            "th",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: {
                                "min-width": "150px",
                                width: "10%",
                              },
                            },
                            [_vm._v("功能")]
                          ),
                        ]),
                    _vm.news.total > 0
                      ? _vm._l(_vm.news.datas, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _vm.projectId !== "omp_xinstars"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm.projectId == "omp_heti" &&
                                  item.isStop == 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "text-primary",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.newsId) + " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [_vm._v(_vm._s(item.newsId))]),
                                ])
                              : _vm._e(),
                            _vm.projectId !== "omp_heti"
                              ? _c("td", [_vm._v(_vm._s(item.classTypeName))])
                              : _vm._e(),
                            _vm.projectId !== "omp_heti" &&
                            _vm.projectId !== "omp_dem"
                              ? _c("td", [
                                  _c("div", { staticClass: "pic jqimgFill" }, [
                                    _c("img", {
                                      attrs: {
                                        draggable: "false",
                                        src: item.mainImgFullFileName,
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.projectId !== "omp_xinstars"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.isTop === 1 ? "是" : "否") +
                                      " "
                                  ),
                                  _vm.projectId === "omp_heti" &&
                                  item.isTop === 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger",
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelTop(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 取消 ")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.projectId === "omp_heti" &&
                            _vm.$route.name == "HetiSpecialOffer"
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c("div", { staticClass: "w" }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.isHint,
                                              expression: "item.isHint",
                                            },
                                          ],
                                          attrs: {
                                            "false-value": 0,
                                            id: "check" + index,
                                            "true-value": 1,
                                            name: "ckbx-style-8",
                                            type: "checkbox",
                                            disabled:
                                              _vm.hasIsHint != item.newsId &&
                                              _vm.hasIsHint != "",
                                          },
                                          domProps: {
                                            checked: Array.isArray(item.isHint)
                                              ? _vm._i(item.isHint, null) > -1
                                              : _vm._q(item.isHint, 1),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = item.isHint,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? 1 : 0
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        item,
                                                        "isHint",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        item,
                                                        "isHint",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(item, "isHint", $$c)
                                                }
                                              },
                                              function ($event) {
                                                return _vm.changeHint(item)
                                              },
                                            ],
                                          },
                                        }),
                                        _c("label", {
                                          attrs: { for: "check" + index },
                                          on: {
                                            click: function ($event) {
                                              return _vm.hasHint(item)
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("td", [_vm._v(_vm._s(item.detailTopTitle))]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.FormatTime(item.publishTime))),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(item.isStop == "1" ? "下架" : "上架")
                              ),
                            ]),
                            _vm.projectId == "omp_xinstars"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.點擊次數)),
                                ])
                              : _vm._e(),
                            _vm.projectId !== "omp_xinstars" &&
                            _vm.projectId !== "omp_heti"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(" " + _vm._s(item.createUId)),
                                ])
                              : _vm._e(),
                            _vm.projectId !== "omp_heti"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.createUName)),
                                ])
                              : _vm._e(),
                            _vm.projectId !== "omp_heti"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.createTime,
                                        "YYYY/MM/DD hh:mm:ss"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.projectId == "omp_xinstars"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.updateUName)),
                                ])
                              : _vm._e(),
                            _vm.projectId == "omp_xinstars"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.updateTime)),
                                ])
                              : _vm._e(),
                            _c(
                              "td",
                              { staticClass: "text-center text-nowrap" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editorItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                                _vm._v("   "),
                                item.isStop == "1" &&
                                (_vm.projectId == "omp_mftd" ||
                                  _vm.projectId == "omp_dk" ||
                                  _vm.projectId == "omp_heti" ||
                                  _vm.projectId == "omp_dem")
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v("預覽")]
                                    )
                                  : _vm._e(),
                                _vm._v("   "),
                                _vm.projectId !== "omp_heti"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v("刪除")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.news.total > 0,
                expression: "news.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.news.total,
                pageLimitCount: _vm.searchData.pageSize,
                type2: true,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }