var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "popForm", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog complete_style" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.$t("分類和完成事件"))),
          ]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("提示訊息1")))]),
            _c("br"),
            _c("label", [_vm._v(_vm._s(_vm.$t("事件原因")) + "：")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eventType,
                    expression: "eventType",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.eventType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "-1" } }, [
                  _vm._v(_vm._s(_vm.$t("請選擇事件原因"))),
                ]),
                _vm._l(_vm.固定事件類型, function (item) {
                  return _c(
                    "option",
                    { key: item.id, domProps: { value: item.id } },
                    [_vm._v(_vm._s(_vm.$t(item.name)))]
                  )
                }),
              ],
              2
            ),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.remark,
                  expression: "remark",
                },
              ],
              staticClass: "form-control",
              attrs: { rows: "3", placeholder: _vm.$t("新增此次對話備註") },
              domProps: { value: _vm.remark },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.remark = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "footer" }, [
          _c(
            "button",
            { staticClass: "close", attrs: { "data-dismiss": "modal" } },
            [_vm._v(_vm._s(_vm.$t("取消")))]
          ),
          _c("div", [
            _vm.是否出現儲存草稿
              ? _c(
                  "button",
                  {
                    staticClass: "btn savebtn",
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("儲存草稿")))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn sendbtn",
                on: {
                  click: function ($event) {
                    return _vm.send()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("完成")))]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }