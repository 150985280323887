var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomerServiceEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.type))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FC/CustomerService" } }, [
              _vm._v("客服處理"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.type))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "customerServiceForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "table-responsive mt-10" }, [
                  _c(
                    "table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === "聯絡我們",
                          expression: "type === '聯絡我們'",
                        },
                      ],
                      staticClass: "table table-bordered table-striped min-600",
                    },
                    [
                      _c("tbody", [
                        _vm._m(0),
                        _c("tr", [
                          _c("td", [_vm._v("聯絡時間")]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.data.reactionTime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [_vm._v("平台")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.define.contactPlatforms[_vm.data.platform]
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("會員編號")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.reactionUId))]),
                          _c("td", [_vm._v("網路環境")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.network))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("暱稱")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.reactionNickname))]),
                          _c("td", [_vm._v("手機型號")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.phoneModel))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("系統版本")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.systemVersion))]),
                          _c("td", [_vm._v("APP版本")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.appVersion))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("IP")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.ip))]),
                          _c("td", [_vm._v("問題類別")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.define.contactTypes[_vm.data.reactionType]
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("玩家Eamil")]),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c(
                              "a",
                              { attrs: { href: `mailto:${_vm.data.email}` } },
                              [_vm._v(" " + _vm._s(_vm.data.email) + " ")]
                            ),
                          ]),
                        ]),
                        _vm._m(1),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm._v(_vm._s(_vm.data.reactionNote)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type === "檢舉",
                          expression: "type === '檢舉'",
                        },
                      ],
                      staticClass: "table table-bordered table-striped",
                    },
                    [
                      _c("tbody", [
                        _vm._m(2),
                        _c("tr", [
                          _c("td", [_vm._v("檢舉玩家UID")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.reactionUId))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("被檢舉玩家UID")]),
                          _c("td", [_vm._v(_vm._s(_vm.data.sufferUId))]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("檢舉類別")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.define.reportTypes[_vm.data.reactionType]
                              )
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("檢舉玩家Email")]),
                          _c("td", [
                            _c(
                              "a",
                              { attrs: { href: `mailto:${_vm.data.email}` } },
                              [_vm._v(" " + _vm._s(_vm.data.email) + " ")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-bordered table-striped min-600 mt-30",
                    },
                    [
                      _c("tbody", [
                        _vm._m(3),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "editor" },
                              [
                                _vm.data.status === 2
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.data.replyNote),
                                      },
                                    })
                                  : _c("tinymce", {
                                      attrs: { propLang: _vm.editLang },
                                      model: {
                                        value: _vm.data.replyNote,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "replyNote", $$v)
                                        },
                                        expression: "data.replyNote",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "box-footer" }, [
            _vm.data.status !== 2
              ? _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.save(1)
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.save(2)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("玩家基本資料")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "bg-gray", attrs: { colspan: "4" } }, [
        _vm._v("玩家回饋內容"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "2" } }, [_vm._v("玩家基本資料")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("客服回饋")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }