var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoicePage", class: !_vm.item.買方 ? `page` : `` },
    [
      _c("div", { staticClass: "font-company" }, [
        _vm._v("網銀國際股份有限公司"),
      ]),
      _vm.item.列印狀態 == _vm.手開發票列印狀態Enum.已列印
        ? _c("div", { staticClass: "font-title1" }, [
            _vm._v("電子發票證明聯補印"),
          ])
        : _c("div", { staticClass: "font-title2" }, [_vm._v("電子發票證明聯")]),
      _c("div", { staticClass: "font-title2" }, [
        _vm._v(_vm._s(_vm.item.民國) + "年" + _vm._s(_vm.item.期數) + "月"),
      ]),
      _c("div", { staticClass: "font-title2" }, [
        _vm._v(
          _vm._s(_vm.item.發票號碼.substring(0, 2)) +
            "-" +
            _vm._s(_vm.item.發票號碼.substring(2))
        ),
      ]),
      _c(
        "div",
        { staticClass: "font-info", staticStyle: { "margin-top": "16px" } },
        [_vm._v(_vm._s(_vm.item.時間))]
      ),
      _c("div", { staticClass: "font-info" }, [
        _c("span", { staticClass: "font-left" }, [
          _vm._v("隨機碼:" + _vm._s(_vm.item.隨機碼)),
        ]),
        _c("span", [_vm._v("總計:" + _vm._s(_vm.item.發票金額))]),
      ]),
      _c("div", { staticClass: "font-info" }, [
        _c("span", { staticClass: "font-left" }, [
          _vm._v("賣方:" + _vm._s(_vm.網銀統編)),
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.item.買方,
                expression: "item.買方",
              },
            ],
          },
          [_vm._v("買方:" + _vm._s(_vm.item.買方))]
        ),
      ]),
      _c("div", {
        staticClass: "div-code",
        attrs: { id: "barcode_" + _vm.item.發票號碼 },
      }),
      _c("div", { staticClass: "div-code" }, [
        _c("span", { attrs: { id: "qr1code_" + _vm.item.發票號碼 } }),
        _c("span", { staticStyle: { display: "inline-block", width: "36px" } }),
        _c("span", { attrs: { id: "qr2code_" + _vm.item.發票號碼 } }),
      ]),
      _c("div", { staticClass: "font-info" }, [
        _vm._v("請妥善保管您的發票及銷貨明細，辦"),
      ]),
      _c("div", { staticClass: "font-info" }, [
        _vm._v("理相關作業，請持發票及銷貨明細"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }