var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomizeList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("帳號服務群組設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("帳號服務群組設定")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box topBox-announceList" }, [
            _c(
              "div",
              {
                staticClass: "form-group fr",
                staticStyle: { "margin-bottom": "0" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning w100-md",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showModal()
                      },
                    },
                  },
                  [_vm._v("新增群組")]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm.loading
                    ? [_vm._m(1)]
                    : _vm.total == 0
                    ? [_vm._m(2)]
                    : _vm._l(_vm.list, function (item, index) {
                        return [
                          _c("tr", [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  (_vm.searchData.pageNum - 1) *
                                    _vm.searchData.pageSize +
                                    index +
                                    1
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.群組名稱)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.群組說明)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              _vm._l(item.對應列表, function (service) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: service.細項名稱,
                                        expression: "service.細項名稱",
                                      },
                                    ],
                                  },
                                  [_vm._v(" " + _vm._s(service.細項名稱) + " ")]
                                )
                              }),
                              0
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.建立者姓名)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.建立時間,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 編輯 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]),
                          ]),
                        ]
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          ref: "create",
          staticClass: "modal fade",
          attrs: { id: "serviceList", "data-backdrop": "static" },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-sm" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "mb-20" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { "aria-label": "Close", type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×"),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "form",
                  { staticClass: "form-horizontal", attrs: { name: "Form" } },
                  [
                    _c("div", { staticClass: "box-body" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "col-sm-11" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.群組ID,
                                  expression: "data.群組ID",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "selectUnit" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "群組ID",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.groupList, function (group, index) {
                              return _c(
                                "option",
                                { domProps: { value: group.gId } },
                                [_vm._v(" " + _vm._s(group.groupName) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(3),
                          _vm.accountList.length > 0
                            ? _vm._l(_vm.accountList, function (account) {
                                return _c("tr", [
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _c("div", { staticClass: "checkbox" }, [
                                      _c(
                                        "label",
                                        [
                                          _c("iCheckCheckBox", {
                                            attrs: {
                                              propValue: account,
                                              propChecked: account.checked,
                                            },
                                            on: {
                                              "update:checked": _vm.checkChange,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(account.accName)),
                                  ]),
                                ])
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "modal-footer text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v(" 確定送出 ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("編號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("群祖名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "20%" } }, [
        _vm._v("群組說明"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "15%" } }, [
        _vm._v("指定服務"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "8%" } }, [
        _vm._v("建立人員"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "15%" } }, [
        _vm._v("建立時間"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { width: "15%", "min-width": "120px" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "50px" } }, [
        _vm._v("勾選"),
      ]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("服務")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }