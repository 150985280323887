var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "rewardMessageContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content nav-tabs-custom tab-warning" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c(
            "h4",
            { staticClass: "modal-title" },
            [
              _vm.tabNum == 1 ? [_vm._v("發送內容")] : _vm._e(),
              _vm.tabNum == 2 ? [_vm._v("發送明細")] : _vm._e(),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _vm.tabNum == 1
              ? _c("table", { staticClass: "table table-bordered" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticStyle: { width: "100px" } }, [
                        _vm._v("發送時間"),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.uiDate(_vm.message.發送時間))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("到期時間")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.uiDate(_vm.message.到期時間))),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("信件標題")]),
                      _c("td", [_vm._v(_vm._s(_vm.message.信件標題))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("信件內容")]),
                      _c("td", [
                        /^</.test(_vm.message.信件內容)
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.message.信件內容),
                              },
                            })
                          : _c("div", {
                              staticStyle: { "white-space": "pre-wrap" },
                              domProps: {
                                textContent: _vm._s(_vm.message.信件內容),
                              },
                            }),
                      ]),
                    ]),
                    _vm.message.發送獎勵列表.length > 0
                      ? _c("tr", [
                          _c("td", [_vm._v("附件")]),
                          _c("td", [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered text-center",
                              },
                              [
                                _vm._m(1),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.message.發送獎勵列表,
                                    function (item) {
                                      return _c("tr", [
                                        _c(
                                          "td",
                                          { staticStyle: { padding: ".1em" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.gameTypes.Item[item.id]
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#bbb" },
                                              },
                                              [
                                                _vm._v(
                                                  "(" + _vm._s(item.id) + ")"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { padding: ".1em" } },
                                          [_vm._v(_vm._s(item.count))]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm.tabNum == 2
              ? _c("div", [
                  _vm.message.發送名單選項 == 0
                    ? _c("div", [_c("p", [_vm._v(" 發送數量: 全伺服器發送 ")])])
                    : _c("div", [
                        _vm.message.發送名單選項 == 2
                          ? _c(
                              "p",
                              [
                                _vm._v(" 發送條件:   "),
                                _vm._l(
                                  _vm.message.指定條件列表,
                                  function (item, i) {
                                    return _c("span", [
                                      item.field == 0
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.gameTypes.Level[
                                                    item.minValue
                                                  ]
                                                )
                                            ),
                                            _c(
                                              "small",
                                              {
                                                staticStyle: { color: "#aaa" },
                                              },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(item.minValue) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " ~ " +
                                                _vm._s(
                                                  _vm.gameTypes.Level[
                                                    item.maxValue
                                                  ]
                                                )
                                            ),
                                            _c(
                                              "small",
                                              {
                                                staticStyle: { color: "#aaa" },
                                              },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(item.maxValue) +
                                                    ")"
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.field == 1
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.minValue) +
                                                " ~ " +
                                                _vm._s(item.maxValue) +
                                                "雀幣 "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            i <
                                              _vm.message.指定條件列表.length -
                                                1
                                              ? "，"
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "發送數量: " +
                                  _vm._s(_vm.message.玩家列表.length) +
                                  " "
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterString,
                                  expression: "filterString",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { width: "50%" },
                              attrs: { type: "text", placeholder: "暱稱搜尋" },
                              domProps: { value: _vm.filterString },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.filterString = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                        _c("table", { staticClass: "table table-bordered" }, [
                          _vm._m(2),
                          _c(
                            "tbody",
                            _vm._l(_vm.pagedTable, function (row) {
                              return _c(
                                "tr",
                                _vm._l(row, function (col) {
                                  return _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        padding: ".1em .5em",
                                        width: "25%",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            "data-dismiss": "modal",
                                            to: {
                                              name: "NmjRewardMessageHistory",
                                              query: { nick: col },
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(col))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "box-footer text-center no-border" },
                          [
                            _c("Pagination", {
                              ref: "pagination",
                              attrs: {
                                pageLimitCount: _vm.pageSize,
                                total: _vm.pageTotal,
                              },
                              on: { pageMethod: _vm.onPageChange },
                            }),
                          ],
                          1
                        ),
                      ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._m(3),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "text-bold bg-gray" }, [
        _c("th", { staticStyle: { padding: ".1em" } }, [
          _c("b", [_vm._v("物品 (ID)")]),
        ]),
        _c("th", { staticStyle: { padding: ".1em" } }, [
          _c("b", [_vm._v("數量")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray-light" }, [
        _c("th", { attrs: { colspan: "10" } }, [_vm._v("玩家暱稱")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default pull-right",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_vm._v(" 關閉 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }