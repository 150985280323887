var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(
          _vm._s(
            this.$route.name == "HetiSpecialOfferEdit" ? "新增優惠" : "公告編輯"
          )
        ),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [
          _vm._v(
            _vm._s(
              this.$route.name == "HetiSpecialOfferEdit"
                ? "優惠專案"
                : "公告列表"
            )
          ),
        ]),
        _c("li", { staticClass: "active" }, [
          _vm._v(
            _vm._s(
              this.$route.name == "HetiSpecialOfferEdit"
                ? "新增優惠"
                : "公告編輯"
            )
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _vm.edit.projectId !== "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            _vm._l(_vm.langTypes, function (item, index) {
                              return _c(
                                "button",
                                {
                                  key: index,
                                  class: [
                                    item.id == _vm.editLang
                                      ? "btn btn-default active"
                                      : "btn btn-default",
                                  ],
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.ChangeLang(item.id)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId !== "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.classTypeId,
                                  expression: "edit.classTypeId",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.edit,
                                      "classTypeId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  _vm.changeType,
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "disabled", value: "" } },
                                [_vm._v("請選擇分類")]
                              ),
                              _vm._l(
                                _vm.newsTypes.datas,
                                function (item, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: item.classTypeId },
                                    },
                                    [_vm._v(_vm._s(item.className))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId === "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(2),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.detailTopTitle,
                                expression: "edit.detailTopTitle",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "inputTitle",
                              required: "required",
                              type: "text",
                            },
                            domProps: { value: _vm.edit.detailTopTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "detailTopTitle",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId !== "omp_xinstars"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { staticClass: "col-sm-2 control-label text-nowrap" },
                          [
                            _vm.edit.projectId !== "omp_heti"
                              ? _c("span", { staticClass: "text-red" }, [
                                  _vm._v("*"),
                                ])
                              : _vm._e(),
                            _vm._v(" 是否置頂： "),
                          ]
                        ),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("div", { staticClass: "checkbox" }, [
                            _c(
                              "label",
                              [
                                _c("iCheckCheckBox", {
                                  attrs: { propChecked: _vm.edit.isTop },
                                  on: {
                                    "update:checked": function ($event) {
                                      _vm.edit.isTop = $event["checked"]
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.edit.projectId !== "omp_heti"
                              ? _c("span", [_vm._v("置頂")])
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId !== "omp_heti"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(3),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.detailTopTitle,
                                expression: "edit.detailTopTitle",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "inputTitle",
                              required: "required",
                              type: "text",
                            },
                            domProps: { value: _vm.edit.detailTopTitle },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "detailTopTitle",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { propLang: _vm.editLang },
                            model: {
                              value: _vm.edit.detailTopInfo,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "detailTopInfo", $$v)
                              },
                              expression: "edit.detailTopInfo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.isSpecialPage
                    ? [
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(5),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              {
                                staticClass: "radio radio-inline",
                                staticStyle: { "padding-left": "0" },
                              },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: "1",
                                    message: "即日起",
                                    name: "radioisNowOn",
                                  },
                                  model: {
                                    value: _vm.edit.isNowOn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "isNowOn", $$v)
                                    },
                                    expression: "edit.isNowOn",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "radio radio-inline _time" },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: "0",
                                    message: "",
                                    name: "radioisNowOn",
                                  },
                                  model: {
                                    value: _vm.edit.isNowOn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "isNowOn", $$v)
                                    },
                                    expression: "edit.isNowOn",
                                  },
                                }),
                                _c("div", { staticClass: "radio-date" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group date" },
                                    [
                                      _vm._m(6),
                                      _c(
                                        "div",
                                        { staticClass: "pull-right" },
                                        [
                                          _c("DatePicker", {
                                            attrs: {
                                              propValue: _vm.local.startTime,
                                            },
                                            on: {
                                              "update:propValue": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.local,
                                                  "startTime",
                                                  $event
                                                )
                                              },
                                              "update:prop-value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.local,
                                                  "startTime",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(7),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("div", { staticClass: "radio-date" }, [
                              _c("div", { staticClass: "input-group date" }, [
                                _vm._m(8),
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c("DatePicker", {
                                      attrs: { propValue: _vm.local.endTime },
                                      on: {
                                        "update:propValue": function ($event) {
                                          return _vm.$set(
                                            _vm.local,
                                            "endTime",
                                            $event
                                          )
                                        },
                                        "update:prop-value": function ($event) {
                                          return _vm.$set(
                                            _vm.local,
                                            "endTime",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  !(
                    _vm.edit.projectId === "omp_heti" ||
                    _vm.edit.projectId === "omp_dem"
                  ) ||
                  (_vm.edit.projectId === "omp_heti" && _vm.isSpecialPage)
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "col-sm-2 control-label" }, [
                          _vm.isSpecialPage ||
                          _vm.edit.projectId == "omp_xinstars"
                            ? _c("span", { staticClass: "text-red" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          _vm._v(" 圖片： "),
                        ]),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("div", { staticClass: "pic" }, [
                            _c("img", {
                              attrs: {
                                draggable: "false",
                                src: _vm.edit.filesUrl,
                              },
                            }),
                          ]),
                          _c("input", {
                            attrs: {
                              accept: "image/*",
                              id: "AnnouncementPic",
                              type: "file",
                              multiple:
                                _vm.edit.projectId == "omp_wanin" ||
                                _vm.edit.projectId == "omp_heti"
                                  ? false
                                  : true,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onFileChange($event)
                              },
                            },
                          }),
                          _vm.edit.projectId == "omp_heti" && _vm.isSpecialPage
                            ? _c("span", { staticClass: "text-red" }, [
                                _vm._v(" 建議尺寸 1000*1000 "),
                              ])
                            : _vm._e(),
                          !_vm.isSpecialPage
                            ? _c("div", { staticClass: "help-block" }, [
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v(_vm._s(_vm.picLimit)),
                                ]),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "row ann-imgChoose mt-10" },
                            _vm._l(_vm.imgList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "col-sm-3 col-xs-6 chooseBox",
                                },
                                [
                                  _c("div", {
                                    staticClass: "img-close fa fa-fw fa-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeletePic(item)
                                      },
                                    },
                                  }),
                                  _c("img", {
                                    attrs: { src: item.imgFullFileName },
                                  }),
                                  _c("div", { staticClass: "radio" }, [
                                    _c("input", {
                                      attrs: {
                                        type: "radio",
                                        id: "mainImg" + index,
                                        name: "mainImg",
                                      },
                                      domProps: { checked: item.isMain == 1 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.SetPicIsMain(item)
                                        },
                                      },
                                    }),
                                    _vm._v("主圖 "),
                                  ]),
                                  _vm.edit.projectId == "omp_xinstars"
                                    ? _c("span", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.title,
                                              expression: "item.title",
                                            },
                                          ],
                                          staticClass: "form-control mt-7",
                                          attrs: {
                                            id: "imgTitle",
                                            type: "text",
                                            placeholder: "圖片title",
                                          },
                                          domProps: { value: item.title },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                item,
                                                "title",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.alt,
                                              expression: "item.alt",
                                            },
                                          ],
                                          staticClass: "form-control mt-7",
                                          attrs: {
                                            id: "imgTitle",
                                            type: "text",
                                            placeholder: "圖片alt",
                                          },
                                          domProps: { value: item.alt },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                item,
                                                "alt",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId == "omp_xinstars"
                    ? _c("div", [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 control-label",
                              attrs: { for: "introduction" },
                            },
                            [_vm._v(" 引言： ")]
                          ),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.introduction,
                                  expression: "edit.introduction",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "introduction",
                                placeholder: "內容建議二行內",
                              },
                              domProps: { value: _vm.edit.introduction },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "introduction",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(9),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.keyword,
                                  expression: "edit.keyword",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "Keywords",
                                type: "text",
                                placeholder: "網頁的關鍵字",
                              },
                              domProps: { value: _vm.edit.keyword },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "keyword",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(10),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit.description,
                                  expression: "edit.description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "Description",
                                type: "text",
                                placeholder: "網頁的描述",
                              },
                              domProps: { value: _vm.edit.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.edit,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId == "omp_kf3"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(11),
                        _c(
                          "div",
                          { staticClass: "col-sm-10" },
                          [
                            _c(
                              "multiselect",
                              {
                                attrs: {
                                  placeholder: "自行輸入標籤",
                                  label: "Tag",
                                  "track-by": "Tag",
                                  required: "",
                                  options: _vm.tagList,
                                  "hide-selected": true,
                                  "internal-search": false,
                                  multiple: true,
                                  taggable: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  selectLabel: "",
                                  "max-elements": "已達標籤上限",
                                },
                                on: { tag: _vm.addTag },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "option__desc" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "option__title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(props.option.Tag) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2983738152
                                ),
                                model: {
                                  value: _vm.tag,
                                  callback: function ($$v) {
                                    _vm.tag = $$v
                                  },
                                  expression: "tag",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "notice",
                                    attrs: { slot: "noOptions" },
                                    slot: "noOptions",
                                  },
                                  [_vm._v("列表為空")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "notice",
                                    attrs: { slot: "noResult" },
                                    slot: "noResult",
                                  },
                                  [_vm._v("查無結果")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "notice maxElements",
                                    attrs: { slot: "maxElements" },
                                    slot: "maxElements",
                                  },
                                  [_vm._v(" 已達標籤上限 ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(12),
                    _c(
                      "div",
                      {
                        staticClass: "col-sm-10",
                        style: [
                          _vm.edit.projectId === "omp_heti"
                            ? { display: "flex" }
                            : "",
                        ],
                      },
                      [
                        _c("div", { staticClass: "radio-date" }, [
                          _c(
                            "div",
                            { staticClass: "radio" },
                            [
                              _c("iCheckRedioButton", {
                                attrs: {
                                  checkValue: "0",
                                  message: "上架",
                                  name: "radioMode",
                                },
                                model: {
                                  value: _vm.edit.isStop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edit, "isStop", $$v)
                                  },
                                  expression: "edit.isStop",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.edit.projectId !== "omp_heti",
                                  expression: "edit.projectId !== 'omp_heti'",
                                },
                              ],
                              staticClass: "input-group date",
                            },
                            [
                              _vm._m(13),
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("DatePicker", {
                                    attrs: { propValue: _vm.local.publishTime },
                                    on: {
                                      "update:propValue": function ($event) {
                                        return _vm.$set(
                                          _vm.local,
                                          "publishTime",
                                          $event
                                        )
                                      },
                                      "update:prop-value": function ($event) {
                                        return _vm.$set(
                                          _vm.local,
                                          "publishTime",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.edit.projectId !== "omp_heti",
                                  expression: "edit.projectId !== 'omp_heti'",
                                },
                              ],
                              staticClass: "input-group time",
                            },
                            [
                              _vm._m(14),
                              _c("input", {
                                staticClass: "form-control timepicker",
                                attrs: { type: "text" },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "下架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm.edit.projectId == "omp_xinstars"
                    ? _c("div", { staticClass: "form-group" }, [
                        _vm._m(15),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("div", { staticClass: "radio-date" }, [
                            _c(
                              "div",
                              { staticClass: "radio" },
                              [
                                _c("iCheckRedioButton", {
                                  attrs: {
                                    checkValue: "1",
                                    message: "是",
                                    name: "radioisEnd",
                                  },
                                  model: {
                                    value: _vm.edit.isEnd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.edit, "isEnd", $$v)
                                    },
                                    expression: "edit.isEnd",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "input-group date" }, [
                              _vm._m(16),
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("DatePicker", {
                                    attrs: { propValue: _vm.local.endTime },
                                    on: {
                                      "update:propValue": function ($event) {
                                        return _vm.$set(
                                          _vm.local,
                                          "endTime",
                                          $event
                                        )
                                      },
                                      "update:prop-value": function ($event) {
                                        return _vm.$set(
                                          _vm.local,
                                          "endTime",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._m(17),
                          ]),
                          _c(
                            "div",
                            { staticClass: "radio" },
                            [
                              _c("iCheckRedioButton", {
                                attrs: {
                                  checkValue: "0",
                                  message: "否",
                                  name: "radioisEnd",
                                },
                                model: {
                                  value: _vm.edit.isEnd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.edit, "isEnd", $$v)
                                  },
                                  expression: "edit.isEnd",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.edit.projectId == "omp_wanin"
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "col-sm-2 control-label",
                            attrs: { for: "inputTitle" },
                          },
                          [_vm._v(" 網銀官網Link： ")]
                        ),
                        _c("div", [
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.splitNewsIdList.link1,
                                  expression: "splitNewsIdList.link1",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "inputNewsId1", type: "text" },
                              domProps: { value: _vm.splitNewsIdList.link1 },
                              on: {
                                change: function ($event) {
                                  return _vm.ChangeNewsId($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.splitNewsIdList,
                                    "link1",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.splitNewsIdList.link2,
                                  expression: "splitNewsIdList.link2",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "inputNewsId2", type: "text" },
                              domProps: { value: _vm.splitNewsIdList.link2 },
                              on: {
                                change: function ($event) {
                                  return _vm.ChangeNewsId($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.splitNewsIdList,
                                    "link2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _vm.edit.isStop == 1 &&
                  (_vm.edit.projectId == "omp_mftd" ||
                    _vm.edit.projectId == "omp_dk" ||
                    _vm.edit.projectId == "omp_jx2" ||
                    _vm.edit.projectId == "omp_xinstars" ||
                    _vm.edit.projectId == "omp_heti" ||
                    _vm.edit.projectId == "omp_dem" ||
                    _vm.edit.projectId == "omp_kf3")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning btn-lg",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.Save(true)
                            },
                          },
                        },
                        [_vm._v("預覽")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 語系切換： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 公告分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標題： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 主旨： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 起始日期： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 活動結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "Keywords" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("Keywords： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "Description" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("Description： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-clock-o" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 活動結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timePickerEnd",
        attrs: { type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }