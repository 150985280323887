var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot max1040", attrs: { id: "playEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("玩法說明")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("玩法管理 ")]),
        _c("li", [_vm._v("玩法列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("玩法說明")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.data.玩法名稱,
                          expression: "data.玩法名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                        maxlength: "50",
                      },
                      domProps: { value: _vm.data.玩法名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data,
                            "玩法名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c("span", [
                      _vm._v(_vm._s(50 - _vm.data.玩法名稱.length) + " / 50"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("tinymce", {
                          attrs: { toolbar1: _vm.toolbar1 },
                          model: {
                            value: _vm.data.詳細說明,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "詳細說明", $$v)
                            },
                            expression: "data.詳細說明",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning pull-left mr-10",
                      attrs: {
                        disabled:
                          _vm.data.玩法名稱 == "" || _vm.data.詳細說明 == "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.preview()
                        },
                      },
                    },
                    [_vm._v(" 預覽 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-left",
                      attrs: {
                        disabled:
                          _vm.data.玩法名稱 == "" || _vm.data.詳細說明 == "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default pull-right",
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "preview",
          staticClass: "modal fade",
          attrs: { id: "detailAlert" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(2),
                _c("h4", { staticClass: "modal-title text-center" }, [
                  _vm._v(_vm._s(_vm.data.玩法名稱)),
                ]),
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", {
                  staticClass: "text-center",
                  domProps: { innerHTML: _vm._s(_vm.data.詳細說明) },
                }),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 玩法名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 詳細說明： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }