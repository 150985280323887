var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("廣告列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("廣告管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("廣告列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增圖片 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.classTypeId,
                      expression: "searchData.classTypeId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "classTypeId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _vm.projectId != "omp_heti"
                    ? _c("option", { attrs: { value: "" } }, [_vm._v("全部")])
                    : _vm._e(),
                  _vm._l(_vm.bannerTypes.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.classTypeId } },
                      [_vm._v(" " + _vm._s(item.className) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.banners.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _vm.projectId !== "omp_heti"
                        ? _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "90px",
                                width: "13%",
                              },
                            },
                            [_vm._v("分類")]
                          )
                        : _vm._e(),
                      _vm.projectId === "omp_heti"
                        ? _c(
                            "th",
                            {
                              staticStyle: {
                                "min-width": "90px",
                                width: "13%",
                              },
                            },
                            [_vm._v("ID")]
                          )
                        : _vm._e(),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "5%" } },
                        [_vm._v("主圖")]
                      ),
                      _c(
                        "th",
                        { staticStyle: { "min-width": "90px", width: "13%" } },
                        [_vm._v("名稱")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "10%" },
                        },
                        [_vm._v("建立日期")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap",
                          staticStyle: { width: "5%" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.projectId == "omp_fantacity" ? "正式" : ""
                              ) +
                              "狀態 "
                          ),
                        ]
                      ),
                      _vm.projectId == "omp_fantacity"
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [_vm._v(" 測試狀態 ")]
                          )
                        : _vm._e(),
                      !_vm.coverMode
                        ? _c(
                            "th",
                            {
                              staticClass: "text-nowrap",
                              staticStyle: { width: "5%" },
                            },
                            [_vm._v("排序")]
                          )
                        : _vm._e(),
                      _c(
                        "th",
                        {
                          staticClass: "text-nowrap text-center",
                          staticStyle: { "min-width": "150px", width: "5%" },
                        },
                        [_vm._v(" 功能 ")]
                      ),
                    ]),
                    _vm.banners.total > 0
                      ? _vm._l(_vm.banners.datas, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _vm.projectId !== "omp_heti"
                              ? _c("td", [_vm._v(_vm._s(item.className))])
                              : _vm._e(),
                            _vm.projectId === "omp_heti"
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(item.bannerId)),
                                ])
                              : _vm._e(),
                            _c("td", [
                              _c("div", { staticClass: "pic jqimgFill" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.filesUrl,
                                    draggable: "false",
                                  },
                                }),
                              ]),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.bannerTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                            !_vm.coverMode
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(_vm._s(_vm.GetStatus(item, false))),
                                ])
                              : _vm._e(),
                            _vm.coverMode
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c("div", { staticClass: "w" }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.isStop,
                                              expression: "item.isStop",
                                            },
                                          ],
                                          attrs: {
                                            "false-value": 1,
                                            id: "check" + index,
                                            "true-value": 0,
                                            name: "ckbx-style-8",
                                            type: "checkbox",
                                            disabled:
                                              _vm.hasIsOn != item.bannerId &&
                                              _vm.hasIsOn != "",
                                          },
                                          domProps: {
                                            checked: Array.isArray(item.isStop)
                                              ? _vm._i(item.isStop, null) > -1
                                              : _vm._q(item.isStop, 0),
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = item.isStop,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked ? 0 : 1
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        item,
                                                        "isStop",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        item,
                                                        "isStop",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(item, "isStop", $$c)
                                                }
                                              },
                                              function ($event) {
                                                return _vm.changeOn(item)
                                              },
                                            ],
                                          },
                                        }),
                                        _c("label", {
                                          attrs: { for: "check" + index },
                                          on: {
                                            click: function ($event) {
                                              return _vm.hasOn(item.bannerId)
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.projectId == "omp_fantacity" && !_vm.coverMode
                              ? _c("td", { attrs: { align: "center" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.GetStatus(item, true)) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.coverMode
                              ? _c(
                                  "td",
                                  { staticClass: "td-sort text-nowrap" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.orderNum,
                                          expression: "item.orderNum",
                                        },
                                      ],
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        disabled: item.isStop == 1,
                                      },
                                      domProps: { value: item.orderNum },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "orderNum",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    item.isStop != 1
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-warning btn-add btn-upDate",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateBannerOd(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 更新 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(0)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.banners.total > 0,
                expression: "banners.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.banners.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }