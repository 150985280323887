var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("社群管理")))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.$t("社群管理"))),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass: "table table-bordered classTypeTable",
                staticStyle: { width: "60%" },
              },
              [
                _c(
                  "tbody",
                  [
                    _c("tr", { staticClass: "bg-gray" }, [
                      _c("th", [_vm._v(_vm._s(_vm.$t("社群")))]),
                      _c("th", { staticClass: "text-nowrap text-center" }, [
                        _vm._v(_vm._s(_vm.$t("功能"))),
                      ]),
                    ]),
                    _vm._l(_vm.PlatformType, function (item, key) {
                      return _c("tr", { key: key }, [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t(`PlatformType-${item.Type}`))),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  _vm.詳細(key)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("詳細")))]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }