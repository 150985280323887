var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjEventAnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.typeString) + "設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/AppBannerList" } }, [
              _vm._v("遊戲Banner設定"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.typeString) + "設定"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "fcAnnouncementForm", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _vm.id
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("span", [_vm._v(_vm._s(_vm.data.公告編號))]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.id
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.data.建立時間,
                                "YYYY/MM/DD HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _vm.loading == false
                    ? _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("toggle-button", {
                            attrs: { labels: true },
                            model: {
                              value: _vm.data.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "status", $$v)
                              },
                              expression: "data.status",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.分類,
                            expression: "data.分類",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { "max-width": "320px" },
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "分類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.def.bannerType, function (t, k, i) {
                        return _c("option", { domProps: { value: k } }, [
                          _vm._v(_vm._s(t)),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c(
                    "div",
                    { staticClass: "form-group col-sm-9" },
                    _vm._l(_vm.def.gamePlatform, function (platform, i) {
                      return _c(
                        "div",
                        { staticClass: "checkbox checkbox-inline" },
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.platforms[i],
                                  expression: "data.platforms[i]",
                                },
                              ],
                              staticStyle: {
                                position: "relative",
                                width: "1.5em",
                                height: "1.5em",
                                "vertical-align": "sub",
                                "margin-top": "-.2em",
                                "accent-color": "#3498db",
                                cursor: "pointer",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.data.platforms[i])
                                  ? _vm._i(_vm.data.platforms[i], null) > -1
                                  : _vm.data.platforms[i],
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.data.platforms[i],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.data.platforms,
                                            i,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.data.platforms,
                                            i,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.data.platforms, i, $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.updatePlatforms()
                                  },
                                ],
                              },
                            }),
                            _vm._v("  " + _vm._s(platform) + " "),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.data.公告名稱,
                          expression: "data.公告名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "請輸入公告名稱",
                        required: "",
                        maxlength: "50",
                      },
                      domProps: { value: _vm.data.公告名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data,
                            "公告名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _c(
                      "span",
                      { class: { "text-red": _vm.data.公告名稱.length > 50 } },
                      [
                        _vm._v(
                          " 名稱上限 " +
                            _vm._s(_vm.data.公告名稱.length) +
                            " / 50 字 "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      attrs: {
                        type: "file",
                        id: "Img",
                        accept: "image/*",
                        required: !_vm.data.fileUrl,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, _vm.type)
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "text-red",
                      domProps: { innerHTML: _vm._s(_vm.picLimit) },
                    }),
                    _c("br"),
                    _vm.data.fileUrl
                      ? _c("div", { staticClass: "pic has-imgClose mt-10" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.data.fileUrl,
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { "max-width": "200px" },
                                attrs: {
                                  src: _vm.data.fileUrl,
                                  alt: "",
                                  draggable: "false",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-imgClose fa fa-close",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("close"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "fieldset",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.分類 == 2,
                        expression: "data.分類 == 2",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.標籤,
                                expression: "data.標籤",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { "max-width": "320px" },
                            attrs: { required: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "標籤",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.def.bannerTag, function (t, k, i) {
                            return _c("option", { domProps: { value: k } }, [
                              _vm._v(_vm._s(t)),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.圖示,
                                expression: "data.圖示",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { "max-width": "320px" },
                            attrs: { required: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data,
                                  "圖示",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.def.bannerBadge, function (t, k, i) {
                            return _c("option", { domProps: { value: k } }, [
                              _vm._v(_vm._s(t)),
                            ])
                          }),
                          0
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.分類 != 2,
                                expression: "data.分類 != 2",
                              },
                            ],
                            staticClass: "text-red",
                          },
                          [
                            _vm._v(
                              " 分類選擇為【活動公告】才會顯示「標籤」與「圖示」 "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.排序,
                          expression: "data.排序",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { "max-width": "320px" },
                      attrs: {
                        type: "number",
                        min: "1",
                        max: "999",
                        required: "",
                      },
                      domProps: { value: _vm.data.排序 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "排序", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              name: "actionRadio",
                              message: "無功能",
                            },
                            model: {
                              value: _vm.data.連結機制,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "連結機制", _vm._n($$v))
                              },
                              expression: "data.連結機制",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              name: "actionRadio",
                              message: "輸入網址",
                            },
                            model: {
                              value: _vm.data.連結機制,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "連結機制", _vm._n($$v))
                              },
                              expression: "data.連結機制",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "2",
                              name: "actionRadio",
                              message: "連結至遊戲功能",
                            },
                            model: {
                              value: _vm.data.連結機制,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "連結機制", _vm._n($$v))
                              },
                              expression: "data.連結機制",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.data.連結機制 == 1
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(11),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.data.網址Url,
                                  expression: "data.網址Url",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "請輸入網址連結",
                                required: _vm.data.連結機制 == 1,
                              },
                              domProps: { value: _vm.data.網址Url },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.data,
                                    "網址Url",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.data.連結機制 == 2
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(12),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.string",
                                    value: _vm.data.連結機制編號,
                                    expression: "data.連結機制編號",
                                    modifiers: { string: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { required: "" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.data,
                                      "連結機制編號",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.def.gameAction, function (option, i) {
                                return _c("option", {
                                  domProps: {
                                    value: i,
                                    textContent: _vm._s(option),
                                  },
                                })
                              }),
                              0
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(13),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.startTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(14),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", { staticClass: "checkbox" }, [
                          _c(
                            "label",
                            [
                              _c("iCheckCheckBox", {
                                attrs: { propChecked: _vm.data.是否常駐顯示 },
                                on: {
                                  "update:checked": function ($event) {
                                    _vm.data.是否常駐顯示 = $event["checked"]
                                  },
                                },
                              }),
                              _vm._v(" 常駐顯示 "),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.data.是否常駐顯示,
                        expression: "!data.是否常駐顯示",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _vm._m(15),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _c("div", {
                            staticClass: "input-group-addon fa fa-calendar",
                          }),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.endTime },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.endTime = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.endTime = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(16),
                      ]),
                    ]),
                  ]
                ),
                _vm._m(17),
                _c("div", { staticClass: "box-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-3 control-label",
                      staticStyle: { "padding-top": "0" },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "checkbox checkbox-inline" },
                        [
                          _c("iCheckCheckBox", {
                            attrs: { propChecked: _vm.data.是否顯示等級 },
                            on: {
                              "update:checked": function ($event) {
                                _vm.data.是否顯示等級 = $event["checked"]
                              },
                            },
                            model: {
                              value: _vm.data.是否顯示等級,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "是否顯示等級", $$v)
                              },
                              expression: "data.是否顯示等級",
                            },
                          }),
                          _vm._v("   段位等級 "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "fieldset",
                    {
                      staticClass: "vip col-md-9",
                      attrs: { disabled: !_vm.data.是否顯示等級 },
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.levels[0],
                              expression: "data.levels[0]",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: _vm.data.是否顯示等級 },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data.levels,
                                0,
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.gameTypes.Level, function (option, i) {
                          return _c("option", {
                            key: i,
                            domProps: {
                              value: i,
                              textContent: _vm._s(`${option} (${i})`),
                            },
                          })
                        }),
                        0
                      ),
                      _c("p", [_vm._v("~")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.levels[1],
                              expression: "data.levels[1]",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: _vm.data.是否顯示等級 },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data.levels,
                                1,
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.gameTypes.Level, function (option, i) {
                          return _c("option", {
                            key: i,
                            domProps: {
                              value: i,
                              textContent: _vm._s(`${option} (${i})`),
                            },
                          })
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "box-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-3 control-label",
                      staticStyle: { "padding-top": "0" },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "checkbox checkbox-inline" },
                        [
                          _c("iCheckCheckBox", {
                            attrs: { propChecked: _vm.data.是否顯示雀能 },
                            on: {
                              "update:checked": function ($event) {
                                _vm.data.是否顯示雀能 = $event["checked"]
                              },
                            },
                            model: {
                              value: _vm.data.是否顯示雀能,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "是否顯示雀能", $$v)
                              },
                              expression: "data.是否顯示雀能",
                            },
                          }),
                          _vm._v("   雀幣"),
                          _c("span", {
                            staticStyle: {
                              display: "inline-block",
                              width: "2em",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "fieldset",
                    {
                      staticClass: "level col-md-9",
                      attrs: { disabled: !_vm.data.是否顯示雀能 },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.coins[0],
                            expression: "data.coins[0]",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          min: "1",
                          required: _vm.data.是否顯示雀能,
                        },
                        domProps: { value: _vm.data.coins[0] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data.coins, 0, $event.target.value)
                          },
                        },
                      }),
                      _c("p", [_vm._v("~")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.coins[1],
                            expression: "data.coins[1]",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          min: "1",
                          required: _vm.data.是否顯示雀能,
                        },
                        domProps: { value: _vm.data.coins[1] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data.coins, 1, $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 編號： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 建立時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputStatus" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 是否開啟： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 分類： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-3 control-label",
        attrs: { for: "inputPlatform" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 顯示平台(可複選)： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputSort" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputImg" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖示： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputSort" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "actionRadio" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 動作： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        staticStyle: { "padding-inline": "0" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 網址： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        staticStyle: { "padding-inline": "0" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲功能： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon fa fa-clock-o" }),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 截止時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon fa fa-clock-o" }),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "col-sm-3 control-label" }, [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 顯示條件(可複選)： "),
      ]),
      _c("div", { staticClass: "col-sm-9 control-label text-red text-left" }, [
        _vm._v(" 若皆無選擇則全玩家皆可看到 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }