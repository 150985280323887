var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "g-info" }, [
      _c("div", { staticClass: "g-photo" }, [
        _c("img", { attrs: { src: _vm.item.圖示, alt: "" } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _c("div", { staticClass: "g-name" }, [
          _vm._v(_vm._s(_vm.item.遊戲館名稱)),
        ]),
        _c("div", [
          _c(
            "ul",
            { staticClass: "g-play" },
            _vm._l(_vm.item.玩法集合.slice(0, 3), function (play, index) {
              return _c("li", [_vm._v(" " + _vm._s(play.玩法名稱) + " ")])
            }),
            0
          ),
          _vm.item.玩法集合.length > 3
            ? _c("span", [_vm._v("更多+")])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "bg-gray-light g-link" }, [
      _c(
        "div",
        { staticClass: "left" },
        _vm._l(_vm.item.標籤集合.slice(0, 3), function (tag, index) {
          return _c(
            "router-link",
            {
              staticClass: "tag btn btn-success mr-5",
              attrs: { to: `/slot/searchTag/${tag.編號}` },
            },
            [_vm._v(" " + _vm._s(tag.標籤名稱) + " ")]
          )
        }),
        1
      ),
      _c("div", { staticClass: "right" }, [
        _vm.item.標籤集合.length > 3
          ? _c(
              "a",
              {
                staticClass: "mr-10",
                staticStyle: { "font-size": "14px" },
                attrs: { href: "" },
              },
              [_vm._v(" + ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "g-card-hover" }, [
      _c(
        "div",
        {
          staticClass: "g-photo",
          staticStyle: { overflow: "hidden" },
          on: {
            click: function ($event) {
              return _vm.goContent(_vm.item)
            },
          },
        },
        [
          _vm._l(_vm.item.預覽視覺集合, function (media, m_index) {
            return [
              media.視覺型態 == "1" && media.是否啟用 == 1
                ? _c("img", {
                    attrs: { src: media.視覺路徑, id: `img_${m_index + 1}` },
                  })
                : _vm._e(),
              media.視覺型態 == "2" && media.是否啟用 == 1
                ? _c("iframe", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "YTVideo",
                      src: `https://www.youtube.com/embed/${
                        media.視覺路徑.split("youtu.be/")[1]
                      }?enablejsapi=1&autoplay=1&mute=1`,
                      title: "YouTube video player",
                      frameborder: "0",
                      allow: "autoplay; encrypted-media;",
                      allowfullscreen: "",
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "text" }, [
        _c(
          "div",
          {
            staticClass: "g-name",
            on: {
              click: function ($event) {
                return _vm.goContent(_vm.item)
              },
            },
          },
          [_vm._v(_vm._s(_vm.item.遊戲館名稱))]
        ),
        _c("div", [
          _c("p", [_vm._v("玩法")]),
          _c(
            "ul",
            { staticClass: "g-play" },
            _vm._l(_vm.item.玩法集合.slice(0, 3), function (play, index) {
              return _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showPlay(play.編號)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(play.玩法名稱) + " ")]
              )
            }),
            0
          ),
          _vm.item.玩法集合.length > 3
            ? _c("div", { staticClass: "more" }, [_vm._v("更多+")])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "g-link" }, [
          _c("p", [_vm._v("標籤")]),
          _c(
            "div",
            _vm._l(_vm.item.標籤集合.slice(0, 3), function (tag, index) {
              return _c(
                "router-link",
                {
                  staticClass: "tag btn btn-success mr-5 mb-5",
                  attrs: { to: `/slot/searchTag/${tag.編號}` },
                },
                [_vm._v(" " + _vm._s(tag.標籤名稱) + " ")]
              )
            }),
            1
          ),
          _vm.item.標籤集合.length > 3
            ? _c("div", { staticClass: "more" }, [_vm._v("更多+")])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }