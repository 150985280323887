var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("精華影片")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("精華影片")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "box box-primary" },
        [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.order,
                        expression: "searchData.order",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "order",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("默認排序"),
                    ]),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("倍數")]),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("分數")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.上架,
                        expression: "searchData.上架",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "上架",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "-1" } }, [
                      _vm._v("上/下架"),
                    ]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("上架")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("下架")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "date-group w-a" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("開獎日期："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-right" },
                    [
                      _c("DatePicker", {
                        attrs: { propValue: _vm.date },
                        on: {
                          "update:propValue": function ($event) {
                            _vm.date = $event
                          },
                          "update:prop-value": function ($event) {
                            _vm.date = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" }), _vm._v(" 查詢 ")]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass:
                    "table table-hover table-bordered min-800 AdListTable",
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(1),
                      _vm.total > 0
                        ? _vm._l(_vm.datas, function (item, index) {
                            return _c("tr", { key: item.id }, [
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.建立時間,
                                      "YYYY-MM-DD "
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [
                                _c("img", {
                                  attrs: {
                                    src: item.封面連結,
                                    alt: item.封面連結,
                                  },
                                }),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openVideo(item)
                                      },
                                    },
                                  },
                                  [_vm._v("播放")]
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.暱稱 || " - ")),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.倍數)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.分數)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.等級)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.中獎時間,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    )
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.遊戲館編號)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ckbx-style-8 ckbx toggleInput",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.上架,
                                          expression: "item.上架",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "ckbx-style-8",
                                        id: `check${index}`,
                                        "false-value": false,
                                        "true-value": true,
                                      },
                                      domProps: {
                                        checked: Array.isArray(item.上架)
                                          ? _vm._i(item.上架, null) > -1
                                          : item.上架,
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$a = item.上架,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    item,
                                                    "上架",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    item,
                                                    "上架",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(item, "上架", $$c)
                                            }
                                          },
                                          function ($event) {
                                            return _vm.toggle(item)
                                          },
                                        ],
                                      },
                                    }),
                                    _c("label", {
                                      attrs: { for: `check${index}` },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(item.異動人員 || " - ")),
                              ]),
                              item.異動時間
                                ? _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.異動時間,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      )
                                    ),
                                  ])
                                : _c("td", { staticClass: "text-center" }, [
                                    _vm._v(" - "),
                                  ]),
                            ])
                          })
                        : [_vm._m(2)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > _vm.searchData.pageSize,
                  expression: "total > searchData.pageSize",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.total,
                  pageLimitCount: _vm.searchData.pageSize,
                  type2: true,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("VideoModal", { ref: "VideoModal" }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("上傳日期"),
      ]),
      _c("th", { staticStyle: { "min-width": "200px", width: "20%" } }, [
        _vm._v("影片縮圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("觀看影片"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("暱稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("倍數"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("得分"),
      ]),
      _c("th", { staticStyle: { "min-width": "60px", width: "5%" } }, [
        _vm._v("等級"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("開獎日期"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("遊戲館編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("上架狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("異動人員"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("異動時間"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("查詢無任何資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }