var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PopForm", {
        ref: "popform",
        staticClass: "popform",
        on: { onBGClick: _vm.onBGClick },
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("下載歷程")) +
                    "-" +
                    _vm._s(_vm.Sender) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _vm.下載遊戲歷程功能
                  ? _c(
                      "button",
                      {
                        staticClass: "btn control-btn",
                        on: {
                          click: function ($event) {
                            return _vm.onDownloadGameBtnClick($event)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-fw fa-gamepad",
                          staticStyle: { "padding-right": "10px" },
                        }),
                        _vm._v(_vm._s(_vm.$t("遊戲歷程"))),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn control-btn",
                    on: {
                      click: function ($event) {
                        return _vm.onDownloadMsgBtnClick($event)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-fw fa-commenting",
                      staticStyle: { "padding-right": "10px" },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("發話歷程")) + " "),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }