var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PopForm", {
        ref: "popform",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("發送密語")) +
                    "-" +
                    _vm._s(_vm.聊天會員對象) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c("span", { attrs: { width: "20%" } }, [
                      _vm._v(_vm._s(_vm.$t("新增罐頭訊息")) + "："),
                    ]),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.options,
                        "custom-label": _vm.nameWithLang,
                        placeholder: _vm.$t("選擇罐頭訊息"),
                        label: "answer",
                        "track-by": "track",
                      },
                      on: { select: _vm.onSelect },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    }),
                    _c("br"),
                    _c("span", [_vm._v(_vm._s(_vm.$t("內容")) + "：")]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.msg,
                          expression: "msg",
                          modifiers: { trim: true },
                        },
                      ],
                      ref: "inputArea",
                      staticClass: "form-control",
                      attrs: { placeholder: _vm.$t("請填入密語內容") },
                      domProps: { value: _vm.msg },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          return _vm.keyEnter.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.msg = $event.target.value.trim()
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: _vm.btnDisable },
                    on: { click: _vm.SendChatMessage },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("確認送出")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default pull-right",
                    staticStyle: { "margin-left": "5px" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.popform.hide()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("取消")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }