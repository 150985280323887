var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomerService" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("客服處理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("客服處理")]),
      ]),
    ]),
    _c("section", { staticClass: "content customer-service" }, [
      _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _c(
            "li",
            { class: { active: _vm.searchData.module === "玩家回報" } },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: "#problem",
                    "aria-expanded": "true",
                    "data-toggle": "tab",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList("玩家回報", "問題類別")
                    },
                  },
                },
                [_vm._v(" 玩家回報 ")]
              ),
            ]
          ),
          _c("li", { class: { active: _vm.searchData.module === "檢舉" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: "#report",
                  "aria-expanded": "false",
                  "data-toggle": "tab",
                },
                on: {
                  click: function ($event) {
                    return _vm.resetDataList("檢舉", "檢舉類別")
                  },
                },
              },
              [_vm._v(" 檢舉 ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.searchData.module === "玩家回報" },
            },
            [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "select-group mb-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.reactionType,
                          expression: "searchData.reactionType",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "reactionType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("玩家回報")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("問題類別"),
                      ]),
                      _c("option", { attrs: { value: "大廳" } }, [
                        _vm._v("大廳"),
                      ]),
                      _c("option", { attrs: { value: "段位賽" } }, [
                        _vm._v("段位賽"),
                      ]),
                      _c("option", { attrs: { value: "好友對決" } }, [
                        _vm._v("好友對決"),
                      ]),
                      _c("option", { attrs: { value: "儲值相關" } }, [
                        _vm._v("儲值相關"),
                      ]),
                      _c("option", { attrs: { value: "帳號相關" } }, [
                        _vm._v("帳號相關"),
                      ]),
                      _c("option", { attrs: { value: "活動" } }, [
                        _vm._v("活動"),
                      ]),
                      _c("option", { attrs: { value: "建議" } }, [
                        _vm._v("建議"),
                      ]),
                      _c("option", { attrs: { value: "其他" } }, [
                        _vm._v("其他"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group mb-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.platform,
                          expression: "searchData.platform",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("玩家回報")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("所有平台"),
                      ]),
                      _c("option", { attrs: { value: "Android" } }, [
                        _vm._v("Android"),
                      ]),
                      _c("option", { attrs: { value: "iOS" } }, [
                        _vm._v("iOS"),
                      ]),
                      _c("option", { attrs: { value: "Other" } }, [
                        _vm._v("Other"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group mb-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.status,
                          expression: "searchData.status",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("玩家回報")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("處理進度"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("未回覆"),
                      ]),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("已回覆"),
                      ]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("草稿")]),
                    ]
                  ),
                ]),
                _c("div", { staticStyle: { clear: "both" } }),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.ReactionNickname,
                        expression: "searchData.ReactionNickname",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "玩家暱稱" },
                    domProps: { value: _vm.searchData.ReactionNickname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "ReactionNickname",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: {
                      float: "left",
                      "margin-right": ".5em",
                      width: "auto",
                    },
                  },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("回報時間："),
                    ]),
                    _c("div", { staticClass: "input-group date" }, [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _c("DateRangePicker", {
                            attrs: {
                              propTimePicker: true,
                              propEndDate: _vm.searchData.local.contactEndTime,
                              propStartDate:
                                _vm.searchData.local.contactStartTime,
                            },
                            on: {
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "contactEndTime",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "contactEndTime",
                                  $event
                                )
                              },
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "contactStartTime",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "contactStartTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("玩家回報")
                        },
                      },
                    },
                    [_vm._v("查詢")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.exportOrders()
                        },
                      },
                    },
                    [_vm._v("匯出")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center" },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      [
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "14" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "13" } }, [
                                _vm._v("查詢無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(item.reactionId))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(item.reactionTime))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: {
                                              nick: item.reactionNickname,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.reactionNickname))]
                                    ),
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v(_vm._s(item.platform))]),
                                _c("td", [_vm._v(_vm._s(item.phoneModel))]),
                                _c("td", [_vm._v(_vm._s(item.systemVersion))]),
                                _c("td", [_vm._v(_vm._s(item.appVersion))]),
                                _c("td", [_vm._v(_vm._s(item.reactionType))]),
                                _c("td", [_vm._v(_vm._s(item.replyUName))]),
                                _c(
                                  "td",
                                  { class: { "text-red": item.status === 1 } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.def.serviceState[item.status]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(item.replyTime))),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkContent(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.searchData.module === "檢舉" },
            },
            [
              _c("div", { staticClass: "top-box" }, [
                _c("div", { staticClass: "select-group mb-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.reactionType,
                          expression: "searchData.reactionType",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "reactionType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("檢舉")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("檢舉類別"),
                      ]),
                      _c("option", { attrs: { value: "辱罵聊天" } }, [
                        _vm._v("辱罵聊天"),
                      ]),
                      _c("option", { attrs: { value: "不當暱稱" } }, [
                        _vm._v("不當暱稱"),
                      ]),
                      _c("option", { attrs: { value: "作弊" } }, [
                        _vm._v("作弊"),
                      ]),
                      _c("option", { attrs: { value: "濫用廣告" } }, [
                        _vm._v("濫用廣告"),
                      ]),
                      _c("option", { attrs: { value: "其他" } }, [
                        _vm._v("其他"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "select-group mb-10" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.searchData.status,
                          expression: "searchData.status",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.searchData,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.resetDataList("檢舉")
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v("處理進度"),
                      ]),
                      _c("option", { attrs: { value: "0" } }, [
                        _vm._v("未回覆"),
                      ]),
                      _c("option", { attrs: { value: "2" } }, [
                        _vm._v("已回覆"),
                      ]),
                      _c("option", { attrs: { value: "3" } }, [
                        _vm._v("不需回復"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticStyle: { clear: "both" } }),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.ReactionNickname,
                        expression: "searchData.ReactionNickname",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "玩家暱稱" },
                    domProps: { value: _vm.searchData.ReactionNickname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "ReactionNickname",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: {
                      float: "left",
                      "margin-right": ".5em",
                      width: "auto",
                    },
                  },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("檢舉時間："),
                    ]),
                    _c("div", { staticClass: "input-group date" }, [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _c("DateRangePicker", {
                            attrs: {
                              propTimePicker: true,
                              propEndDate: _vm.searchData.local.reportEndTime,
                              propStartDate:
                                _vm.searchData.local.reportStartTime,
                            },
                            on: {
                              "update:propEndDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "reportEndTime",
                                  $event
                                )
                              },
                              "update:prop-end-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "reportEndTime",
                                  $event
                                )
                              },
                              "update:propStartDate": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "reportStartTime",
                                  $event
                                )
                              },
                              "update:prop-start-date": function ($event) {
                                return _vm.$set(
                                  _vm.searchData.local,
                                  "reportStartTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList("檢舉")
                        },
                      },
                    },
                    [_vm._v("查詢")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.exportOrders()
                        },
                      },
                    },
                    [_vm._v("匯出")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-responsive mt-10" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center" },
                  [
                    _vm._m(1),
                    _c(
                      "tbody",
                      [
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "10" } }, [
                                _vm._v("查詢無資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(item.reactionId))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(item.reactionTime))),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: {
                                              nick: item.reactionNickname,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.reactionNickname))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerData",
                                            query: { nick: item.sufferUId },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.sufferUId))]
                                    ),
                                  ],
                                  1
                                ),
                                _c("td", [_vm._v(_vm._s(item.reactionType))]),
                                _c("td", [_vm._v(_vm._s(item.replyUName))]),
                                _c(
                                  "td",
                                  { class: { "text-red": item.status === 1 } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.def.serviceState[item.status]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.uiDate(item.replyTime))),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkContent(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "box text-center no-border no-shadow mt-20" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("編號"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("回報"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("回報"),
          _c("wbr"),
          _vm._v("來源/平台"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
          _vm._v("遊玩"),
          _c("wbr"),
          _vm._v("裝置"),
          _c("wbr"),
          _vm._v("型號"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "15%" } }, [
          _vm._v("裝置"),
          _c("wbr"),
          _vm._v("版本"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("遊戲"),
          _c("wbr"),
          _vm._v("版本"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("問題"),
          _c("wbr"),
          _vm._v("類別"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("處理"),
          _c("wbr"),
          _vm._v("人員"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("處理"),
          _c("wbr"),
          _vm._v("進度"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("回覆"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("內容"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
          _vm._v("編號"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v("回報"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "125px", width: "10%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "125px", width: "10%" } }, [
          _vm._v("被檢舉"),
          _c("wbr"),
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("檢舉"),
          _c("wbr"),
          _vm._v("類別"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("客服"),
          _c("wbr"),
          _vm._v("人員"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("處理"),
          _c("wbr"),
          _vm._v("狀況"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v("回覆"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("內容"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }