var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("頻道管理-" + _vm._s(_vm.頻道名稱))]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表")]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchDataVideo.狀態,
                        expression: "searchDataVideo.狀態",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectUnit", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchDataVideo,
                            "狀態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.clickSearch,
                      ],
                    },
                  },
                  [
                    _c("option", { key: "1", attrs: { value: "-1" } }, [
                      _vm._v("全部狀態"),
                    ]),
                    _c("option", { key: "2", attrs: { value: "0" } }, [
                      _vm._v("未處理"),
                    ]),
                    _c("option", { key: "3", attrs: { value: "1" } }, [
                      _vm._v("不處理"),
                    ]),
                    _c("option", { key: "4", attrs: { value: "2" } }, [
                      _vm._v("待追蹤"),
                    ]),
                    _c("option", { key: "5", attrs: { value: "3" } }, [
                      _vm._v("已結案"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchDataVideo.搜尋關鍵字,
                      expression: "searchDataVideo.搜尋關鍵字",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "搜尋影片標題",
                    type: "text",
                  },
                  domProps: { value: _vm.searchDataVideo.搜尋關鍵字 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchDataVideo,
                        "搜尋關鍵字",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  (_vm.searchShow = 1)
                    ? _c("span", [_vm._v("資料總筆數：")])
                    : _vm._e(),
                  _vm.searchShow != 1
                    ? _c("span", [_vm._v("搜尋結果筆數：")])
                    : _vm._e(),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.edit.total)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.edit.total > 0
                        ? _vm._l(_vm.edit.datas, function (item, index) {
                            return _c("tr", { key: "editDatas" + index }, [
                              _c(
                                "td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c("a", {
                                    staticClass: "linkStyle",
                                    attrs: {
                                      href:
                                        `https://www.youtube.com/watch?v=` +
                                        item.videoId,
                                      target: "_blank",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(item.影片標題),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c("span", {
                                    staticClass: "detailStyle",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.changeN(item.影片簡介)
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.FormatTime(item.影片發布日期))
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  item.狀態 == 0
                                    ? _c("span", [_vm._v("未處理")])
                                    : _vm._e(),
                                  item.狀態 == 1
                                    ? _c("span", [_vm._v("不處理")])
                                    : _vm._e(),
                                  item.狀態 == 2
                                    ? _c("span", [_vm._v("待追蹤")])
                                    : _vm._e(),
                                  item.狀態 == 3
                                    ? _c("span", [_vm._v("已結案")])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [_c("span", [_vm._v(_vm._s(item.備註))])]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center text-nowrap",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openVideo(item)
                                        },
                                      },
                                    },
                                    [_vm._v("詳細")]
                                  ),
                                ]
                              ),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.edit.total > 0,
                  expression: "edit.total > 0",
                },
              ],
              staticClass: "box-footer text-center no-border",
            },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  total: _vm.edit.total,
                  pageLimitCount: _vm.searchDataVideo.pageSize,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("videoAlert", {
          ref: "videoAlert",
          on: { submit: _vm.getvideoQueryAll },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "20%" } }, [
        _vm._v("影片標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "20%" } }, [
        _vm._v("影片簡介"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("發布日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "20%" } }, [
        _vm._v("備註"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }