var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { margin: "9px 0", display: "flex", padding: "0 15px" } },
      [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.當前類別Id,
                expression: "當前類別Id",
              },
            ],
            staticClass: "form-control",
            staticStyle: { "margin-right": "10px" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.當前類別Id = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c("option", { domProps: { value: -1 } }, [
              _vm._v(_vm._s(_vm.$t("全部類別"))),
            ]),
            _vm._l(_vm.罐頭類別List, function (item) {
              return _c(
                "option",
                { key: item.id, domProps: { value: item.id } },
                [_vm._v(" " + _vm._s(item.類別) + " ")]
              )
            }),
          ],
          2
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.search字串,
              expression: "search字串",
              modifiers: { trim: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: _vm.$t("請輸入關鍵字") },
          domProps: { value: _vm.search字串 },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search字串 = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "cal-list-height",
        staticStyle: { "border-top": "1px solid #E8E8E8" },
      },
      [
        _c(
          "div",
          {
            staticClass: "can-msg-title",
            on: {
              click: function ($event) {
                _vm.isShow自定義 = !_vm.isShow自定義
              },
            },
          },
          [
            _vm._v(_vm._s(_vm.$t("自定義訊息")) + " "),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow自定義,
                  expression: "isShow自定義",
                },
              ],
              staticClass: "fa fa-fw fa-arrow-down",
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isShow自定義,
                  expression: "!isShow自定義",
                },
              ],
              staticClass: "fa fa-fw fa-arrow-up",
            }),
          ]
        ),
        _vm._l(_vm.罐頭訊息List.自定義list, function (item) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow自定義,
                  expression: "isShow自定義",
                },
              ],
              key: item.id,
              staticClass: "can-msg-list",
              attrs: { title: item.Q + "\n\n" + item.A },
              on: {
                click: function ($event) {
                  return _vm.addCanMessag(item.A)
                },
              },
            },
            [
              _c("div", { staticClass: "font-style-q font-style-ellipsis" }, [
                _vm._v(_vm._s(item.Q)),
              ]),
              _c("div", { staticClass: "font-style-a font-style-ellipsis" }, [
                _vm._v(_vm._s(item.A)),
              ]),
            ]
          )
        }),
        _vm.罐頭訊息List.自定義list.length == 0 && _vm.isShow自定義
          ? [
              _c("div", { staticClass: "can-msg-noList" }, [
                _vm._v(" " + _vm._s(_vm.$t("無資料")) + " "),
              ]),
            ]
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "can-msg-title",
            on: {
              click: function ($event) {
                _vm.isShow固定 = !_vm.isShow固定
              },
            },
          },
          [
            _vm._v(_vm._s(_vm.$t("通用訊息")) + " "),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow固定,
                  expression: "isShow固定",
                },
              ],
              staticClass: "fa fa-fw fa-arrow-down",
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isShow固定,
                  expression: "!isShow固定",
                },
              ],
              staticClass: "fa fa-fw fa-arrow-up",
            }),
          ]
        ),
        _vm._l(_vm.罐頭訊息List.固定list, function (item) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow固定,
                  expression: "isShow固定",
                },
              ],
              key: item.id,
              staticClass: "can-msg-list",
              attrs: { title: item.Q + "\n\n" + item.A },
              on: {
                click: function ($event) {
                  return _vm.addCanMessag(item.A)
                },
              },
            },
            [
              _c("div", { staticClass: "font-style-q font-style-ellipsis" }, [
                _vm._v(_vm._s(item.Q)),
              ]),
              _c("div", { staticClass: "font-style-a font-style-ellipsis" }, [
                _vm._v(_vm._s(item.A)),
              ]),
            ]
          )
        }),
        _vm.罐頭訊息List.固定list.length == 0 && _vm.isShow固定
          ? [
              _c("div", { staticClass: "can-msg-noList" }, [
                _vm._v(" " + _vm._s(_vm.$t("無資料")) + " "),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }