var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "announcementReplyAlert", staticClass: "modal fade" },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h3", { staticClass: "modal-title text-primary" }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.linkItem(_vm.item)
                    },
                  },
                },
                [
                  _vm.type == 1
                    ? _c("span", [_vm._v(" " + _vm._s(_vm.item.articleTitle))])
                    : _vm.type == 2
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.item.commentContent)),
                      ])
                    : _c("span", [_vm._v(" " + _vm._s(_vm.item.contentText))]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("h4", { staticStyle: { "margin-top": "0" } }, [
              _vm._v("新增回覆"),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              staticStyle: {
                width: "100%",
                "border-color": "#ccc",
                padding: "10px",
              },
              attrs: { rows: "5", placeholder: "請輸入回覆內容" },
              domProps: { value: _vm.content },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(" 回覆 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
                on: {
                  click: function ($event) {
                    _vm.content = ""
                  },
                },
              },
              [_vm._v(" 關閉 ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }