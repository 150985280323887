var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "fcImportData", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm._m(0),
          _c("h4", { staticClass: "modal-title" }, [
            _vm._v("匯入" + _vm._s(_vm.title) + "資料"),
          ]),
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _c("div", { staticClass: "form-group name-group" }, [
                      _c("div", { staticClass: "right" }, [
                        _c("input", {
                          ref: "batchCancelOrder",
                          attrs: { id: "batchCancelOrder", type: "file" },
                        }),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-danger btn-cog",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.readExcel()
                          },
                        },
                      },
                      [_vm._v(" 確定匯入 ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-block btn-success btn-cog",
                        attrs: {
                          href: `/uploads/${_vm.title}範例.xlsx`,
                          type: "button",
                          download: "",
                        },
                      },
                      [_vm._v(" 匯入" + _vm._s(_vm.title) + "範例 ")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }