var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(" 新增-修改金流")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "/" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({
                    name: "IPSSettingList",
                    params: _vm.$route.params,
                  })
                },
              },
            },
            [_vm._v("金流設定")]
          ),
        ]),
        _c("li", { staticClass: "active" }, [_vm._v("新增-修改金流 ")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [_vm._v("內容")]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.金流名稱,
                        expression: "edit.金流名稱",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.金流名稱 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "金流名稱",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.金流代碼,
                        expression: "edit.金流代碼",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.edit.金流代碼 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit,
                          "金流代碼",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "是否有退貨功能",
                        },
                        model: {
                          value: _vm.edit.退貨功能,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "退貨功能", $$v)
                          },
                          expression: "edit.退貨功能",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "是否有退貨功能",
                        },
                        model: {
                          value: _vm.edit.退貨功能,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "退貨功能", $$v)
                          },
                          expression: "edit.退貨功能",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-10" }, [
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "否",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "1",
                          message: "是",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.edit.啟用,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "啟用", $$v)
                          },
                          expression: "edit.啟用",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-footer" }, [
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-lg",
                    attrs: { type: "button" },
                    on: { click: _vm.Save },
                  },
                  [_vm._v(" 確認送出 ")]
                ),
                _vm._v("  "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-lg",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [_vm._v(" 取消返回 ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { ref: "loading", staticClass: "overlay" }, [
          _c("i", { staticClass: "fa fa-refresh fa-spin" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 金流代碼： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 退貨功能： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 停用/啟用： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }