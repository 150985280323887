var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "announcementContent", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { width: "100px" } }, [_vm._v("發送時間")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.item.sendStartTime,
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("截止時間")]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.item.sendEndTime,
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("發送平台")]),
                  _c("td", [_vm._v(_vm._s(_vm.item.platform))]),
                ]),
                _vm.searchData.typeName === "系統"
                  ? _c("tr", [
                      _c("td", [_vm._v("訊息主旨")]),
                      _c("td", [_vm._v(_vm._s(_vm.item.title))]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("td", [_vm._v("訊息內容")]),
                  _vm.item.info.startsWith("<")
                    ? _c("td", [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.item.info) },
                        }),
                      ])
                    : _c("td", { staticStyle: { "white-space": "pre-line" } }, [
                        _vm._v(" " + _vm._s(_vm.item.info) + " "),
                      ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _vm.isEdit
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.updateContent()
                    },
                  },
                },
                [_vm._v(" 編輯 ")]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v(" 關閉 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("發送內容")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }