var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: { display: _vm.visible } }, [
    _c("div", { staticStyle: { padding: "10px" } }, [
      _c("table", { staticClass: "table table-bordered" }, [
        _c("tbody", [
          _c("th", { staticStyle: { "white-space": "nowrap" } }, [
            _vm._v("匯入進度"),
          ]),
          _c("th", [_vm._v("當前筆數/總筆數")]),
          _c("tr", [
            _c("td", { staticStyle: { width: "80%" } }, [
              _c("div", { staticClass: "progress progress-xs" }, [
                _c("div", {
                  staticClass: "progress-bar progress-bar-danger",
                  style: { width: _vm.百分比 },
                }),
              ]),
            ]),
            _c("td", { staticStyle: { "text-align": "center" } }, [
              _c("span", { staticClass: "badge bg-red" }, [
                _vm._v(_vm._s(_vm.當前筆數) + "/" + _vm._s(_vm.總筆數)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticStyle: {
          padding: "10px",
          "overflow-y": "scroll",
          height: "100px",
        },
      },
      _vm._l(_vm.logs, function (log, i) {
        return _c("div", { key: i }, [_vm._v(_vm._s(log))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }