var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "RewardMessageHistory" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("玩家信件查詢")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("玩家信件查詢")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/NMJ/RewardMessage" } }, [
                  _vm._v("信件發獎"),
                ]),
              ],
              1
            ),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c(
              "form",
              {
                staticClass: "top-box",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    _vm.resetDataList()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group", staticStyle: { width: "auto" } },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("發送時間："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-group date" },
                      [
                        _c("div", {
                          staticClass: "input-group-addon fa fa-calendar",
                        }),
                        _c("DateRangePicker", {
                          attrs: {
                            propTimePicker: true,
                            propEndDate: _vm.local.endTime,
                            propStartDate: _vm.local.startTime,
                          },
                          on: {
                            "update:propEndDate": function ($event) {
                              return _vm.$set(_vm.local, "endTime", $event)
                            },
                            "update:prop-end-date": function ($event) {
                              return _vm.$set(_vm.local, "endTime", $event)
                            },
                            "update:propStartDate": function ($event) {
                              return _vm.$set(_vm.local, "startTime", $event)
                            },
                            "update:prop-start-date": function ($event) {
                              return _vm.$set(_vm.local, "startTime", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchData.Name,
                        expression: "searchData.Name",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "玩家暱稱" },
                    domProps: { value: _vm.searchData.Name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.searchData,
                          "Name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._m(1),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: Object.keys(_vm.$route.query).length > 0,
                        expression: "Object.keys($route.query).length > 0",
                      },
                    ],
                    staticClass: "btn btn-default pull-right fa fa-arrow-left",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v(" 返回")]
                ),
              ]
            ),
            _c("div", { staticClass: "table-responsive mt-0" }, [
              _c("table", { staticClass: "table table-bordered text-center" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(2),
                    _vm.message
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v(_vm._s(_vm.message)),
                          ]),
                        ])
                      : _vm._l(_vm.pagedData, function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class: { "bg-gray-light": item.狀態 === 3 },
                            },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "NmjPlayerData",
                                          query: { nick: item.暱稱 },
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.暱稱))]
                                  ),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(item.信件編號))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.發送時間))),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate(item.到期時間))),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.uiDate(item.刪除時間) || "-")
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.寄件人))]),
                              _c("td", [_vm._v(_vm._s(item.信件標題))]),
                              _c("td", [_vm._v(_vm._s(item.領取狀態))]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkContent(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看 ")]
                                ),
                              ]),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("rewardMessageContent", { ref: "rewardMessageContent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "active" }, [
      _c("a", [_vm._v("玩家信件查詢")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btns mb-10" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v(" 查詢 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "130px", width: "10%" } }, [
        _vm._v("玩家暱稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("信件編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
        _vm._v("發送時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
        _vm._v("到期時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
        _vm._v("刪除時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "130px", width: "10%" } }, [
        _vm._v("寄信人"),
      ]),
      _c("th", { staticStyle: { "min-width": "130px", width: "15%" } }, [
        _vm._v("標題"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("內容"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }