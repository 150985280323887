var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tinymce-editor", attrs: { id: "tinymce4" } },
    [
      _c("Editor", {
        attrs: {
          disabled: _vm.disabled,
          init: _vm.init,
          propLang: _vm.propLang,
          propPlaceholder: _vm.propPlaceholder,
          propPlugins: _vm.plugins,
          id: "uuid",
        },
        on: { onClick: _vm.onClick, onPaste: _vm.onPaste },
        model: {
          value: _vm.myValue,
          callback: function ($$v) {
            _vm.myValue = $$v
          },
          expression: "myValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }