var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageHistory" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("給獎訊息設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FC/RewardMessage" } }, [
              _vm._v("給獎訊息設定"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("歷程明細")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
            _c("div", { staticClass: "input-group date" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "pull-right" },
                [
                  _c("DateRangePicker", {
                    attrs: {
                      propEndDate: _vm.searchData.local.endTime,
                      propStartDate: _vm.searchData.local.startTime,
                    },
                    on: {
                      "update:propEndDate": function ($event) {
                        return _vm.$set(_vm.searchData.local, "endTime", $event)
                      },
                      "update:prop-end-date": function ($event) {
                        return _vm.$set(_vm.searchData.local, "endTime", $event)
                      },
                      "update:propStartDate": function ($event) {
                        return _vm.$set(
                          _vm.searchData.local,
                          "startTime",
                          $event
                        )
                      },
                      "update:prop-start-date": function ($event) {
                        return _vm.$set(
                          _vm.searchData.local,
                          "startTime",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                  },
                },
                [_vm._v(" 查詢 ")]
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", [_vm._v("總發送筆數：" + _vm._s(_vm.total))]),
              _c("p", [_vm._v("成功收件筆數：" + _vm._s(_vm.sendCount))]),
              _c("p", [_vm._v("成功領取筆數：" + _vm._s(_vm.getCount))]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered text-center" }, [
              _c(
                "tbody",
                [
                  _vm._m(1),
                  _vm.loading
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v("查詢中"),
                        ]),
                      ])
                    : _vm.datas.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v("查詢無任何資料"),
                        ]),
                      ])
                    : _vm._l(_vm.datas, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.sendDay,
                                    "YYYY/MM/DD"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("currencyFormat")(item.sendCount))
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("currencyFormat")(item.getCount))
                            ),
                          ]),
                        ])
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("日期")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("收件筆數")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("領取筆數")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }