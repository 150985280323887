var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._l(_vm.propOptions, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "checkbox-inline",
          attrs: { id: "checkbox" + index },
        },
        [
          _c(
            "label",
            [
              _c("iCheckCheckBox2", {
                attrs: { propValue: item, propChecked: _vm.selecteds[item] },
                on: { "update:checked": _vm.onChange },
              }),
              _vm._v(" " + _vm._s(item) + " "),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }