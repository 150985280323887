var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PlayerDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("玩家儲值查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("玩家儲值查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.resetDataList()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "form-group mb-10",
                  staticStyle: { width: "max-content", "max-width": "100%" },
                },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("訂單時間："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-group date" },
                    [
                      _c("div", {
                        staticClass: "input-group-addon fa fa-calendar",
                      }),
                      _c("DateRangePicker", {
                        attrs: {
                          propTimePicker: true,
                          propEndDate: _vm.local.endTime,
                          propStartDate: _vm.local.startTime,
                        },
                        on: {
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.local, "endTime", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.local, "endTime", $event)
                          },
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.local, "startTime", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.local, "startTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.searchData.平台,
                        expression: "searchData.平台",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return _vm._n(val)
                            })
                          _vm.$set(
                            _vm.searchData,
                            "平台",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.resetDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "ALL" } }, [
                      _vm._v("所有平台"),
                    ]),
                    _c("option", { attrs: { value: "GOOGLE" } }, [
                      _vm._v("GooglePlay"),
                    ]),
                    _c("option", { attrs: { value: "IOS" } }, [
                      _vm._v("AppleStore"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "clear input-group mb-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.暱稱,
                      expression: "searchData.暱稱",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "玩家暱稱" },
                  domProps: { value: _vm.searchData.暱稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "暱稱",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input-group mb-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.交易ID,
                      expression: "searchData.交易ID",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "訂單編號" },
                  domProps: { value: _vm.searchData.交易ID },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "交易ID",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "btns mb-10" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: { click: function ($event) {} },
                  },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.datas.length === 0 },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: Object.keys(_vm.$route.query).length > 0,
                      expression: "Object.keys($route.query).length > 0",
                    },
                  ],
                  staticClass: "btn btn-default pull-right fa fa-arrow-left",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(" 返回")]
              ),
            ]
          ),
          _c("div", { staticClass: "total-data" }, [
            _c("b", [_vm._v("總金額(TWD)：" + _vm._s(_vm.totalMoney))]),
            _c("b", [_vm._v("總筆數：" + _vm._s(_vm.total))]),
            _c("b", [_vm._v("不重複人數：" + _vm._s(_vm.totalUnique))]),
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              { staticClass: "table table-bordered table-hover text-center" },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v("查詢中"),
                          ]),
                        ])
                      : _vm.datas.length <= 0
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "10" } }, [
                            _vm._v("查詢無資料"),
                          ]),
                        ])
                      : _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [
                              _vm._v(_vm._s(_vm.uiDate(item.訂單最後處理時間))),
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "NmjPlayerData",
                                        query: { nick: item.暱稱 },
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.暱稱))]
                                ),
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  /^apple/.test(item.平台商品ID)
                                    ? "AppleStore"
                                    : "GooglePlay"
                                )
                              ),
                            ]),
                            _c("td", [_vm._v(_vm._s(item.訂單編號))]),
                            _c("td", [_vm._v(_vm._s(item.平台商品ID))]),
                            _c("td", [_vm._v(_vm._s(item.商品名稱))]),
                            _c("td", [_vm._v(_vm._s(item["販售金額(TWD)"]))]),
                            _c("td", [_vm._v(_vm._s(item.獲得雀幣))]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "box text-center no-border no-shadow mt-20" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.searchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "box-footer text-center no-border" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("訂單最後"),
          _c("wbr"),
          _vm._v("處理時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "10%" } }, [
          _vm._v("玩家暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "5%" } }, [
          _vm._v("儲值平台"),
        ]),
        _c("th", { staticStyle: { "min-width": "120px", width: "15%" } }, [
          _vm._v("訂單編號"),
        ]),
        _c("th", { staticStyle: { "min-width": "120px", width: "15%" } }, [
          _vm._v("平台"),
          _c("wbr"),
          _vm._v("商品ID"),
        ]),
        _c("th", { staticStyle: { "min-width": "92px", width: "5%" } }, [
          _vm._v("商品名稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("販售金額"),
          _c("wbr"),
          _vm._v("(TWD)"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("獲得雀幣"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }