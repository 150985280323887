var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newtalk", attrs: { id: "NewtalkVoteEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [
          _vm._v(
            _vm._s(_vm.data.pollsCategory === "民調" ? "民調" : "投票") + "內容"
          ),
        ]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Newtalk/VoteList" } }, [
                _vm._v("投票管理"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [
            _vm._v(
              " " +
                _vm._s(_vm.data.pollsCategory === "民調" ? "民調" : "投票") +
                "內容 "
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "voteForm", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.data.pollsCategory,
                                expression: "data.pollsCategory",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.data,
                                    "pollsCategory",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                _vm.changeVoteType,
                              ],
                            },
                          },
                          [
                            _vm._l(_vm.list, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: item.classTypeId },
                                },
                                [_vm._v(" " + _vm._s(item.className) + " ")]
                              )
                            }),
                            _c("option", { attrs: { value: "電競大賞" } }, [
                              _vm._v("電競大賞"),
                            ]),
                            _c("option", { attrs: { value: "2023電競大賞" } }, [
                              _vm._v("2023電競大賞"),
                            ]),
                            _c("option", { attrs: { value: "民調" } }, [
                              _vm._v("民調"),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm.data.pollsCategory !== "民調"
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(1),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.voteType,
                                    expression: "voteType",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { required: "" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.voteType = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.changeVoteType()
                                    },
                                  ],
                                },
                              },
                              [
                                _vm.data.pollsCategory.indexOf("電競大賞") > -1
                                  ? [
                                      _c(
                                        "option",
                                        { attrs: { value: "圖片模式" } },
                                        [_vm._v("圖片模式")]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "option",
                                        { attrs: { value: "調查" } },
                                        [_vm._v("調查")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "辯論" } },
                                        [_vm._v("辯論")]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.data.pollsCategory.indexOf("電競大賞") > -1
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(2),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.data.pollsSubcategory,
                                    expression: "data.pollsSubcategory",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { required: "" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.data,
                                        "pollsSubcategory",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.setTitle()
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { value: "射擊類選手獎" } },
                                  [_vm._v("射擊類選手獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "射擊類新人獎" } },
                                  [_vm._v("射擊類新人獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "射擊類校園獎" } },
                                  [_vm._v("射擊類校園獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "綜合類選手獎" } },
                                  [_vm._v("綜合類選手獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "綜合類新人獎" } },
                                  [_vm._v("綜合類新人獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "綜合類校園獎" } },
                                  [_vm._v("綜合類校園獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "MOBA類選手獎" } },
                                  [_vm._v("MOBA類選手獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "MOBA類新人獎" } },
                                  [_vm._v("MOBA類新人獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "MOBA類校園獎" } },
                                  [_vm._v("MOBA類校園獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "賽事評論獎" } },
                                  [_vm._v("賽事評論獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "評論報導獎" } },
                                  [_vm._v("評論報導獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "教育貢獻獎" } },
                                  [_vm._v("教育貢獻獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "電競團隊獎" } },
                                  [_vm._v("電競團隊獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "娛樂效果獎" } },
                                  [_vm._v("娛樂效果獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "專業技術獎" } },
                                  [_vm._v("專業技術獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "影音特效獎" } },
                                  [_vm._v("影音特效獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "造型服裝獎" } },
                                  [_vm._v("造型服裝獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "最佳意見領袖獎" } },
                                  [_vm._v("最佳意見領袖獎")]
                                ),
                                _c(
                                  "option",
                                  { attrs: { value: "最佳人氣選手獎" } },
                                  [_vm._v("最佳人氣選手獎")]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "inputTitle" },
                        },
                        [
                          _c("span", { staticClass: "text-red" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.pollsCategory.indexOf("電競大賞") > -1
                                  ? "主旨"
                                  : "標題"
                              ) +
                              "： "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.data.articleTitle,
                              expression: "data.articleTitle",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputTitle",
                            maxlength: "50",
                            placeholder: "請輸入標題，上限50字",
                            type: "text",
                            required: "",
                          },
                          domProps: { value: _vm.data.articleTitle },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "articleTitle",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "editor" },
                          [
                            _c("tinymce", {
                              attrs: { propLang: _vm.lang },
                              model: {
                                value: _vm.data.articleContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "articleContent", $$v)
                                },
                                expression: "data.articleContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm.data.pollsCategory !== "民調"
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(4),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c(
                              "div",
                              { staticClass: "table-responsive mt-10" },
                              _vm._l(_vm.data.polls, function (poll, index) {
                                return _c(
                                  "table",
                                  {
                                    key: index,
                                    staticClass: "table table-bordered tc",
                                  },
                                  [
                                    _c("tr", [
                                      _c("th", {
                                        staticStyle: {
                                          "min-width": "60px",
                                          width: "60px",
                                        },
                                      }),
                                      _c("th", {
                                        staticStyle: { "min-width": "150px" },
                                      }),
                                      _c("th", {
                                        staticStyle: { "min-width": "160px" },
                                        style: {
                                          width:
                                            poll.options.length > 2
                                              ? "160px"
                                              : "110px",
                                        },
                                      }),
                                    ]),
                                    _c("tr", [
                                      _c("td", { staticClass: "question" }, [
                                        _vm._v("Q" + _vm._s(index + 1)),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.data.polls[0].title,
                                              expression: "data.polls[0].title",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          staticClass: "form-control mt-10",
                                          attrs: {
                                            type: "text",
                                            placeholder: "題目",
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.data.polls[0].title,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.data.polls[0],
                                                "title",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td"),
                                    ]),
                                    _vm._l(
                                      poll.options,
                                      function (option, oIndex) {
                                        return _c("tr", { key: oIndex }, [
                                          _c("td", [
                                            _vm.data.pollsType === "辯論"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-10" },
                                                  [
                                                    oIndex === 0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass: "blue",
                                                          },
                                                          [_vm._v("正方")]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass: "red",
                                                          },
                                                          [_vm._v("反方")]
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: option.title,
                                                  expression: "option.title",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control mt-10",
                                              attrs: {
                                                type: "text",
                                                placeholder: `選項${
                                                  oIndex + 1
                                                }`,
                                                required: "",
                                              },
                                              domProps: { value: option.title },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    option,
                                                    "title",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-left",
                                              staticStyle: {
                                                "min-width": "200px",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.data.pollsCategory.indexOf(
                                                          "電競大賞"
                                                        ) > -1,
                                                      expression:
                                                        "data.pollsCategory.indexOf('電競大賞') > -1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-primary mt-10 ml-10",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showImgContent(
                                                        poll,
                                                        oIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-paperclip",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        poll.options.length > 1,
                                                      expression:
                                                        "poll.options.length > 1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-warning mt-10 ml-10",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sortOption(
                                                        poll,
                                                        oIndex,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-arrow-up",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        poll.options.length > 1,
                                                      expression:
                                                        "poll.options.length > 1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-warning mt-10 ml-10",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sortOption(
                                                        poll,
                                                        oIndex,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-arrow-down",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        poll.options.length > 2,
                                                      expression:
                                                        "poll.options.length > 2",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-danger mt-10 ml-10",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeOption(
                                                        poll,
                                                        option
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-trash",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    _vm.data.pollsType === "辯論"
                                      ? _c("tr", [
                                          _c("td"),
                                          _c("td", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mt-10 fl",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showContent()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-upload",
                                                }),
                                                _vm._v(" 變更圖示 "),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "min-width": "500px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fl ml-25" },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "mt-10 ml-10",
                                                      attrs: {
                                                        width: "100px",
                                                        src: _vm.data
                                                          .positiveSideUrl,
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "blue" },
                                                      [_vm._v("正方")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "fl",
                                                    staticStyle: {
                                                      margin: "50px",
                                                    },
                                                  },
                                                  [_vm._v("VS")]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "fl" },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "mt-10 ml-10",
                                                      attrs: {
                                                        width: "100px",
                                                        src: _vm.data
                                                          .negativeSideUrl,
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "red" },
                                                      [_vm._v("反方")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("td"),
                                        ])
                                      : _vm._e(),
                                    _c("tr", [
                                      _c("td"),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-left",
                                          attrs: { colspan: "2" },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.data.pollsType ===
                                                      "調查" ||
                                                    _vm.data.pollsCategory.indexOf(
                                                      "電競大賞"
                                                    ) > -1,
                                                  expression:
                                                    "\n                            data.pollsType === '調查' ||\n                            data.pollsCategory.indexOf('電競大賞') > -1\n                          ",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-warning mt-10 mb-10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addOption(poll)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus",
                                              }),
                                              _vm._v(" 新增選項 "),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.data.pollsType ===
                                                    "調查",
                                                  expression:
                                                    "data.pollsType === '調查'",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-primary mt-10 mb-10 ml-10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyQuestion(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-plus",
                                              }),
                                              _vm._v(" 複製題組 "),
                                            ]
                                          ),
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.data.pollsType !== "調查",
                                                expression:
                                                  "data.pollsType !== '調查'",
                                              },
                                            ],
                                            staticClass: "mb-10",
                                          }),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.data.polls.length > 1,
                                                  expression:
                                                    "data.polls.length > 1",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-primary mt-10 mb-10 ml-10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sortQuestion(
                                                    index,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-arrow-up",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.data.polls.length > 1,
                                                  expression:
                                                    "data.polls.length > 1",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-primary mt-10 mb-10 ml-10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sortQuestion(
                                                    index,
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-arrow-down",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.data.polls.length > 1,
                                                  expression:
                                                    "data.polls.length > 1",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-danger mt-10 mb-10 ml-10",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeQuestion(
                                                    poll
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.data.pollsType === "調查",
                                      expression: "data.pollsType === '調查'",
                                    },
                                  ],
                                  staticClass: "btn btn-primary mt-10",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addQuestion()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-plus" }),
                                  _vm._v(" 新增題目 "),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.data.pollsCategory.indexOf("電競大賞") == -1
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(5),
                          _c(
                            "div",
                            { staticClass: "col-sm-10" },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    placeholder: "自行輸入標籤",
                                    label: "Tag",
                                    "track-by": "Tag",
                                    required: "",
                                    options: _vm.options,
                                    "hide-selected": true,
                                    "internal-search": false,
                                    multiple: true,
                                    taggable: true,
                                    "close-on-select": false,
                                    "clear-on-select": false,
                                    selectLabel: "",
                                    "max-elements": "已達標籤上限",
                                  },
                                  on: {
                                    tag: _vm.addTag,
                                    "search-change": _vm.updateTags,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "option",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "option__desc" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "option__title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          props.option.Tag
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "option__small",
                                                    staticStyle: {
                                                      float: "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " 使用文章數：" +
                                                        _vm._s(
                                                          props.option
                                                            .UsedCount || 0
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1579797342
                                  ),
                                  model: {
                                    value: _vm.data.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "tags", $$v)
                                    },
                                    expression: "data.tags",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice",
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions",
                                    },
                                    [_vm._v("列表為空")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice",
                                      attrs: { slot: "noResult" },
                                      slot: "noResult",
                                    },
                                    [_vm._v("查無結果")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice maxElements",
                                      attrs: { slot: "maxElements" },
                                      slot: "maxElements",
                                    },
                                    [_vm._v(" 已達標籤上限 ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.data.pollsCategory !== "民調"
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(6),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("div", { staticClass: "radio-date" }, [
                              _c("div", { staticClass: "input-group date" }, [
                                _vm._m(7),
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c("DatePicker", {
                                      attrs: { propValue: _vm.startTime },
                                      on: {
                                        "update:propValue": function ($event) {
                                          _vm.startTime = $event
                                        },
                                        "update:prop-value": function ($event) {
                                          _vm.startTime = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._m(8),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.data.pollsCategory !== "民調"
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(9),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("div", { staticClass: "radio-date" }, [
                              _c("div", { staticClass: "input-group date" }, [
                                _vm._m(10),
                                _c(
                                  "div",
                                  { staticClass: "pull-right" },
                                  [
                                    _c("DatePicker", {
                                      attrs: { propValue: _vm.endTime },
                                      on: {
                                        "update:propValue": function ($event) {
                                          _vm.endTime = $event
                                        },
                                        "update:prop-value": function ($event) {
                                          _vm.endTime = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._m(11),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.data.pollsCategory === "民調"
                      ? [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { staticClass: "col-sm-2 control-label" },
                              [
                                !_vm.pollJsonUrl
                                  ? _c("span", { staticClass: "text-red" }, [
                                      _vm._v("*"),
                                    ])
                                  : _c("span", [_vm._v("更換")]),
                                _vm._v("JSON檔： "),
                              ]
                            ),
                            _c("div", { staticClass: "col-sm-10" }, [
                              _c("input", {
                                attrs: {
                                  accept: "application/JSON",
                                  type: "file",
                                  id: "pollJson",
                                  name: "pollJson",
                                  required: !_vm.pollJsonUrl ? true : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeFile($event, "json")
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.pollJsonUrl,
                                  expression: "pollJsonUrl",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c("label", {
                                staticClass: "col-sm-2 control-label",
                              }),
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.voteImgName,
                                        expression: "voteImgName",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.pollJsonUrl,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.voteImgName) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm.data.pollsCategory.indexOf("電競大賞") == -1
                      ? [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(12),
                            _c("div", { staticClass: "col-sm-10" }, [
                              _c("input", {
                                attrs: {
                                  accept: "image/*",
                                  type: "file",
                                  id: "voteImg",
                                  name: "voteImg",
                                  required:
                                    !_vm.voteImgId &&
                                    _vm.data.pollsCategory.indexOf(
                                      "電競大賞"
                                    ) == -1,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeFile($event)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.voteImgUrl,
                                  expression: "voteImgUrl",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c("label", {
                                staticClass: "col-sm-2 control-label",
                              }),
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("img", {
                                  attrs: {
                                    width: "100px",
                                    src: _vm.voteImgUrl,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.voteImgName,
                                        expression: "voteImgName",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: _vm.voteImgUrl,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.voteImgName) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.data.pollsCategory !== "民調"
                      ? _c("div", { staticClass: "form-group" }, [
                          _vm._m(13),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _vm.voteType === "調查"
                              ? _c(
                                  "div",
                                  { staticClass: "radio" },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: "2",
                                        message: "截止後顯示",
                                        name: "resultMode",
                                      },
                                      model: {
                                        value: _vm.data.pollsResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "pollsResult",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.pollsResult",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.data.pollsCategory.indexOf("電競大賞") == -1
                              ? _c(
                                  "div",
                                  {
                                    class: [
                                      "radio",
                                      _vm.voteType === "調查" ? "mt-10" : "",
                                    ],
                                  },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: "1",
                                        message: "投票完即顯示",
                                        name: "resultMode",
                                      },
                                      model: {
                                        value: _vm.data.pollsResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "pollsResult",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.pollsResult",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.data.pollsCategory.indexOf("電競大賞") > -1
                              ? _c(
                                  "div",
                                  { staticClass: "radio" },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: "3",
                                        message:
                                          "最高票前三名顯示入圍；其餘人顯示票數",
                                        name: "resultMode",
                                      },
                                      model: {
                                        value: _vm.data.pollsResult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "pollsResult",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.pollsResult",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(14),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "上架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.data.isDelete,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "isDelete", _vm._n($$v))
                                },
                                expression: "data.isDelete",
                              },
                            }),
                            _vm.data.pollsCategory !== "民調"
                              ? _c("span", { staticClass: "text-red ml-10" }, [
                                  _vm._v(" (以投票開始時間自動上架) "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio mt-10" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "2",
                                message: "下架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.data.isDelete,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "isDelete", _vm._n($$v))
                                },
                                expression: "data.isDelete",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _vm.data.isDelete === 2
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-lg",
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                return _vm.save(true)
                              },
                            },
                          },
                          [_vm._v(" 預覽 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.save(false)
                          },
                        },
                      },
                      [_vm._v(" 確認送出 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("voteContent", {
        ref: "voteContent",
        on: { updateContent: _vm.updateContent },
      }),
      _c("voteImgContent", {
        ref: "voteImgContent",
        on: { updateContent: _vm.updateImgContent },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票類型： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 子類別： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票選項： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 主圖： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 投票結果： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }