var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["slotPlayContent", _vm.showContent ? "is-active" : ""] },
    [
      _c("div", { staticClass: "mask" }),
      _c(
        "div",
        {
          staticClass: "close",
          on: {
            click: function ($event) {
              return _vm.goBack()
            },
          },
        },
        [_c("i", { staticClass: "fa fa-close" })]
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { class: ["main", !_vm.showInfo ? "is-active" : ""] },
          [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "input-group mr-10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.玩法名稱,
                      expression: "searchData.玩法名稱",
                    },
                  ],
                  staticClass: "form-control pull-right",
                  attrs: {
                    name: "table_search",
                    placeholder: "遊戲館名稱",
                    type: "text",
                  },
                  domProps: { value: _vm.searchData.玩法名稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.searchData, "玩法名稱", $event.target.value)
                    },
                  },
                }),
                _vm.searchData.玩法名稱
                  ? _c("i", {
                      staticClass: "fa fa-close",
                      on: {
                        click: function ($event) {
                          _vm.searchData.玩法名稱 = ""
                        },
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetData()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "data-num mr-10" }, [
                _c("span", [_vm._v("資料總筆數：")]),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "dropdown", staticStyle: { display: "inline" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sort dropdown-toggle",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dropdown =
                            _vm.dropdown == "none" ? "block" : "none"
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon-sort" }),
                      _vm._v(" 排序依據 "),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu",
                      style: { display: `${_vm.dropdown}` },
                    },
                    [
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: [
                              "dropdown-item",
                              _vm.searchData.排序順序 == 2 ? "active" : "",
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData(2)
                              },
                            },
                          },
                          [_vm._v(" A to Z ")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: [
                              "dropdown-item",
                              _vm.searchData.排序順序 == 1 ? "active" : "",
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.resetData(1)
                              },
                            },
                          },
                          [_vm._v(" Z to A ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm.total == 0
              ? _c("Empty", {
                  attrs: {
                    title: "找不到結果",
                    desc: "請改用其他關鍵字或移除排序條件",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.getData(item.編號)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(item.玩法名稱))])]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c("div", { class: ["sub", _vm.showInfo ? "is-active" : ""] }, [
          _c("div", { staticClass: "top" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    _vm.showInfo = false
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-chevron-left" })]
            ),
            _vm._v(" " + _vm._s(_vm.data.玩法名稱) + " "),
          ]),
          _c("div", { staticClass: "editor text-center" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.data.詳細說明) } }),
            _c("p", [_vm._v("已經到底了")]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }