var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "PopForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "800px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c("div", { staticClass: "box box-info" }, [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", { staticClass: "box-title" }, [_vm._v("常用代碼設定")]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.Close()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    float: "left",
                    "margin-top": "0px",
                    "margin-right": "0px",
                    "margin-bottom": "15px",
                    "margin-left": "0px",
                  },
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selType,
                          expression: "selType",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        disabled:
                          _vm.是否有資料修改中 || _vm.是否有一鍵複製功能,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.手開發票常用資料類別Enum, function (item, key) {
                      return _c("option", { domProps: { value: item } }, [
                        _vm._v(_vm._s(key)),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  staticStyle: {
                    float: "left",
                    "margin-top": "0px",
                    "margin-right": "0px",
                    "margin-bottom": "15px",
                    "margin-left": "0px",
                  },
                },
                [
                  _c("div", { staticClass: "box-tools" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group",
                        staticStyle: { width: "200px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search,
                              expression: "search",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: "搜尋",
                            disabled: _vm.是否有資料修改中,
                          },
                          domProps: { value: _vm.search },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.search = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "input-group-addon",
                            class: { disableBackground: _vm.是否有資料修改中 },
                          },
                          [_c("i", { staticClass: "fa fa-search" })]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm.是否有資料修改中
                ? _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: {
                        float: "left",
                        "margin-top": "0px",
                        "margin-right": "0px",
                        "margin-bottom": "15px",
                        "margin-left": "10px",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "line-height": "34px" } }, [
                        _vm._v("欲啟用搜尋功能需先儲存正在編輯的項目!"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-warning btn-add",
                  staticStyle: { float: "right", "margin-bottom": "10px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.開啟新增列()
                    },
                  },
                },
                [_vm._v("新增 ")]
              ),
              _c("table", { staticClass: "table table-striped" }, [
                _c(
                  "tbody",
                  [
                    _c("tr", [
                      _c("th", { staticStyle: { width: "38%" } }, [
                        _vm._v(
                          _vm._s(_vm._f("一鍵複製key欄位顯示")(_vm.selType))
                        ),
                      ]),
                      _c("th", { staticStyle: { width: "38%" } }, [
                        _vm._v(
                          _vm._s(_vm._f("一鍵複製value欄位顯示")(_vm.selType))
                        ),
                      ]),
                      _c("th", { staticClass: "text-nowrap text-center" }, [
                        _vm._v("功能"),
                      ]),
                    ]),
                    _vm._l(_vm.filterList, function (item) {
                      return _c(
                        "tr",
                        { key: item.編號 },
                        [
                          item.editor == 0
                            ? [
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.名稱) + " "),
                                ]),
                                _c("td", [_vm._v(" " + _vm._s(item.值) + " ")]),
                                _c(
                                  "td",
                                  { staticClass: "text-right text-nowrap" },
                                  [
                                    _vm.是否有一鍵複製功能
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "companyClick",
                                                  item.類別,
                                                  item.值,
                                                  item.名稱
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("一鍵複製")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn bg-purple",
                                        on: {
                                          click: function ($event) {
                                            _vm.修改(item)
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        on: {
                                          click: function ($event) {
                                            _vm.刪除(item)
                                          },
                                        },
                                      },
                                      [_vm._v("刪除")]
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.edit.名稱,
                                        expression: "edit.名稱",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.edit.名稱 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.edit,
                                          "名稱",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.edit.值,
                                        expression: "edit.值",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.edit.值 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.edit,
                                          "值",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", { staticClass: "text-right" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success btn-save",
                                      on: {
                                        click: function ($event) {
                                          _vm.儲存(item)
                                        },
                                      },
                                    },
                                    [_vm._v("儲存")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-default",
                                      on: {
                                        click: function ($event) {
                                          _vm.取消(item)
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ]),
                              ],
                        ],
                        2
                      )
                    }),
                    _vm.filterList.length == 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "3" },
                            },
                            [_vm._v(" 無資料 ")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }