var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲介紹管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("遊戲介紹列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增遊戲 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add mr-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.clearCatch()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 清除Catch ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _vm.newTypeClass
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.遊戲分類,
                          expression: "searchData.遊戲分類",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectUnit", required: "required" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "遊戲分類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.clickSearch,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("全部遊戲分類"),
                      ]),
                      _vm._l(_vm.newTypeClass, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.classTypeId } },
                          [_vm._v(" " + _vm._s(item.className) + " ")]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.是否發佈,
                      expression: "searchData.是否發佈",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "是否發佈",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v("上/下架")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("上架")]),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("下架")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.遊戲名稱,
                    expression: "searchData.遊戲名稱",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋遊戲名稱",
                  type: "text",
                },
                domProps: { value: _vm.searchData.遊戲名稱 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "遊戲名稱", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "select-group checkbox-group ml-10" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.是否出現首頁,
                    expression: "searchData.是否出現首頁",
                  },
                ],
                attrs: { id: "checkLogoLong", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.searchData.是否出現首頁)
                    ? _vm._i(_vm.searchData.是否出現首頁, null) > -1
                    : _vm.searchData.是否出現首頁,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.searchData.是否出現首頁,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.searchData,
                              "是否出現首頁",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.searchData,
                              "是否出現首頁",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.searchData, "是否出現首頁", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.clickSearch()
                    },
                  ],
                },
              }),
              _c("label", { attrs: { for: "checkLogoLong" } }, [
                _vm._v("首頁顯示中的遊戲長圖"),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _vm._v(" 資料總筆數: "),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.total > 0
                      ? _vm._l(_vm.datas, function (item, index) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.getClassTypeName(item.遊戲分類)) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "pic jqimgFill" }, [
                                _c("img", {
                                  attrs: { src: _vm.getLogoUrl(item) },
                                }),
                              ]),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(" " + _vm._s(item.遊戲名稱) + " "),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.是否發佈,
                                        expression: "item.是否發佈",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "ckbx-style-8",
                                      id: `check${index}`,
                                      "false-value": false,
                                      "true-value": true,
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.是否發佈)
                                        ? _vm._i(item.是否發佈, null) > -1
                                        : item.是否發佈,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a = item.是否發佈,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "是否發佈",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "是否發佈",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "是否發佈", $$c)
                                          }
                                        },
                                        function ($event) {
                                          return _vm.toggle(item)
                                        },
                                      ],
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: `check${index}` },
                                  }),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "td-sort text-nowrap" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.排序,
                                    expression: "item.排序",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  disabled: !item.是否發佈,
                                },
                                domProps: { value: item.排序 },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(item, "排序", $event.target.value)
                                  },
                                },
                              }),
                              item.是否發佈
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-warning btn-add btn-upDate",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateOrder(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrow-up",
                                      }),
                                      _vm._v(" 更新 "),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-pencil" }),
                                  _vm._v(" 修改 "),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning mt-5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyItem(item.gid)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-copy" }),
                                  _vm._v(" 複製 "),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mt-5",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-close" }),
                                  _vm._v(" 刪除 "),
                                ]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total,
                expression: "total",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
                type2: true,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "50px", width: "5%" } }, [
        _vm._v("No"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("分類"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "15%" } }, [
        _vm._v("遊戲Logo"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "15%" } }, [
        _vm._v("遊戲名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "5%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("查詢無任何資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }