var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "NmjGameDataList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("雀幣爭霸戰牌局紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("雀幣爭霸戰牌局紀錄")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "form",
            {
              staticClass: "top-box",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.resetDataList()
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(" 查詢時間： "),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _c("div", {
                      staticClass: "input-group-addon fa fa-calendar",
                    }),
                    _c("DateRangePicker", {
                      attrs: {
                        propStartDate: _vm.searchData.開始時間,
                        propEndDate: _vm.searchData.結束時間,
                        propTimePicker: true,
                      },
                      on: {
                        "update:propStartDate": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(_vm.searchData, "開始時間", $event)
                        },
                        "update:propEndDate": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(_vm.searchData, "結束時間", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.暱稱,
                      expression: "searchData.暱稱",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "玩家暱稱" },
                  domProps: { value: _vm.searchData.暱稱 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "暱稱",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "btns mb-10" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" 查詢 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.datas.length <= 0 },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_vm._v(" 匯出 ")]
                ),
              ]),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: Object.keys(_vm.$route.query).length > 0,
                      expression: "Object.keys($route.query).length > 0",
                    },
                  ],
                  staticClass: "btn btn-default pull-right fa fa-arrow-left",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(" 返回")]
              ),
            ]
          ),
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c("table", { staticClass: "table table-bordered text-center" }, [
              _c(
                "tbody",
                [
                  _vm._m(0),
                  _vm.loading
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v("查詢中"),
                        ]),
                      ])
                    : _vm.result.message
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v(_vm._s(_vm.result.message)),
                        ]),
                      ])
                    : _vm.datas.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "20" } }, [
                          _vm._v("查詢無任何資料"),
                        ]),
                      ])
                    : _vm._l(_vm.pagedData, function (item, index) {
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              [
                                _c("router-link", {
                                  attrs: {
                                    to: {
                                      name: "NmjPlayerData",
                                      query: { nick: item.暱稱.trim() },
                                    },
                                  },
                                  domProps: {
                                    textContent: _vm._s(item.暱稱.trim()),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.賽事類別) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.roundID) },
                            }),
                            _c(
                              "td",
                              [
                                _c("router-link", {
                                  attrs: {
                                    to: {
                                      name: "NmjGameRecord",
                                      query: { id: item.牌桌ID },
                                    },
                                  },
                                  domProps: {
                                    textContent: _vm._s(item.牌桌ID),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.名次結果) },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.賽事籌碼) },
                            }),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.uiDate(item.比賽開始時間)
                                ),
                              },
                            }),
                            _c("td", {
                              domProps: { textContent: _vm._s(item.報名順序) },
                            }),
                          ]),
                        ]
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                pageLimitCount: _vm.searchData.pageSize,
                total: _vm.total,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray text-nowrap" }, [
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("玩家暱稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("賽事類別"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("Round ID"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("牌桌ID"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("名次結果"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("賽事籌碼"),
      ]),
      _c("th", { staticStyle: { "min-width": "105px", width: "10%" } }, [
        _vm._v("比賽開始時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("報名順序"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }