var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "PaymentOwedSettingTime", staticClass: "modal fade" },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.timeTypeName) + "設定"),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered table-striped" },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("訂單編號")]),
                      _c("td", [_vm._v(_vm._s(_vm.edit.訂單編號))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("服務編號")]),
                      _c("td", [_vm._v(_vm._s(_vm.data.服務編號))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("交易方式")]),
                      _c("td", [_vm._v(_vm._s(_vm.data.交易方式))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("儲值角色")]),
                      _c("td", [_vm._v(_vm._s(_vm.data.儲值角色))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.timeTypeName))]),
                      _c(
                        "td",
                        { staticClass: "form-inline" },
                        [
                          _c("DateTimePicker", {
                            attrs: { propDateTime: _vm.datetime },
                            on: {
                              "update:propDateTime": function ($event) {
                                _vm.datetime = $event
                              },
                              "update:prop-date-time": function ($event) {
                                _vm.datetime = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.save()
                  },
                },
              },
              [_vm._v(" 確定 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v(" 取消 ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }