var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slot max1040", attrs: { id: "gameInfo" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "遊戲")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("遊戲管理")]),
        _c("li", [_vm._v("遊戲列表")]),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "遊戲"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "form-horizontal", attrs: { name: "gameForm" } },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addData.圖示 == "",
                          expression: "addData.圖示 == ''",
                        },
                      ],
                      attrs: { accept: "image/*", id: "Pic", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addData.圖示 != "",
                          expression: "addData.圖示 != ''",
                        },
                      ],
                      staticStyle: { height: "120px", cursor: "pointer" },
                      attrs: { src: _vm.addData.圖示 },
                      on: {
                        click: function ($event) {
                          return _vm.changePic()
                        },
                      },
                    }),
                    _c("p", { staticStyle: { color: "#b3b3b3" } }, [
                      _vm._v("上限為 5 MB 的 PNG、JPG 檔，尺寸 120 x 120。"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [_vm._v(" 連結： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.addData.影片連結,
                            expression: "addData.影片連結",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                          placeholder: "Youtube網址",
                        },
                        domProps: { value: _vm.addData.影片連結 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.addData,
                              "影片連結",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _c("br"),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.addData.遊戲編號,
                            expression: "addData.遊戲編號",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.addData,
                              "遊戲編號",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v("請選擇遊戲"),
                        ]),
                        _vm._l(_vm.gameList, function (game, index) {
                          return _c(
                            "option",
                            { domProps: { value: game.編號 } },
                            [_vm._v(" " + _vm._s(game.遊戲名稱) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.addData.遊戲館名稱,
                          expression: "addData.遊戲館名稱",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.addData.遊戲館名稱 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addData,
                            "遊戲館名稱",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            placeholder: "搜尋標籤",
                            label: "標籤名稱",
                            "track-by": "標籤名稱",
                            required: "",
                            options: _vm.tagList,
                            "hide-selected": true,
                            multiple: true,
                            taggable: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "max-elements": "已達標籤上限",
                            max: 6,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (props) {
                                return [
                                  _c("div", { staticClass: "option__desc" }, [
                                    _c(
                                      "span",
                                      { staticClass: "option__title" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.option.標籤名稱) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.addData.標籤集合,
                            callback: function ($$v) {
                              _vm.$set(_vm.addData, "標籤集合", $$v)
                            },
                            expression: "addData.標籤集合",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "notice",
                              attrs: { slot: "noOptions" },
                              slot: "noOptions",
                            },
                            [_vm._v("列表為空")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "notice",
                              attrs: { slot: "noResult" },
                              slot: "noResult",
                            },
                            [_vm._v("查無結果")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "notice maxElements",
                              attrs: { slot: "maxElements" },
                              slot: "maxElements",
                            },
                            [_vm._v(" 已達標籤上限 ")]
                          ),
                        ]
                      ),
                      _c("p", { staticStyle: { color: "#b3b3b3" } }, [
                        _vm._v("最少選用 1 個標籤，最多 6 個。"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(5),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            placeholder: "搜尋玩法",
                            label: "玩法名稱",
                            "track-by": "玩法名稱",
                            required: "",
                            options: _vm.playList,
                            "hide-selected": true,
                            multiple: true,
                            taggable: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "max-elements": "已達玩法上限",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (props) {
                                return [
                                  _c("div", { staticClass: "option__desc" }, [
                                    _c(
                                      "span",
                                      { staticClass: "option__title" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.option.玩法名稱) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.addData.玩法集合,
                            callback: function ($$v) {
                              _vm.$set(_vm.addData, "玩法集合", $$v)
                            },
                            expression: "addData.玩法集合",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "notice",
                              attrs: { slot: "noOptions" },
                              slot: "noOptions",
                            },
                            [_vm._v("列表為空")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "notice",
                              attrs: { slot: "noResult" },
                              slot: "noResult",
                            },
                            [_vm._v("查無結果")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "notice maxElements",
                              attrs: { slot: "maxElements" },
                              slot: "maxElements",
                            },
                            [_vm._v(" 已達標籤上限 ")]
                          ),
                        ]
                      ),
                      _c("p", { staticStyle: { color: "#b3b3b3" } }, [
                        _vm._v("最少選用 1 個玩法。"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary pull-left",
                      attrs: {
                        disabled:
                          _vm.addData.圖示 == "" ||
                          _vm.addData.遊戲編號 == 0 ||
                          _vm.addData.遊戲館名稱 == "" ||
                          _vm.addData.玩法集合.length == 0 ||
                          _vm.addData.標籤集合.length == 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default pull-right",
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box with-border" }, [
      _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
        _vm._v("基本資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 圖示： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" App： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 標籤： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-4 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 玩法： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }