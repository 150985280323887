var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormDialog",
    {
      ref: "memberServiceDialog",
      attrs: { title: _vm.$t((_vm.isEdit ? "修改" : "新增") + "服務紀錄") },
      on: { submit: _vm.submit },
    },
    [
      _c("div", { staticClass: "table-responsive mt-0" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
              _c("td", [
                !_vm.isEdit
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.分類,
                            expression: "data.分類",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "", disabled: _vm.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "分類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: {
                              value: "",
                              selected: "",
                              disabled: "",
                              hidden: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                        ),
                        _vm._l(_vm.types, function (name, id) {
                          return _c(
                            "option",
                            { key: id, domProps: { value: name } },
                            [_vm._v(_vm._s(name))]
                          )
                        }),
                      ],
                      2
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.data.異動動作))]),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("內容")))]),
              _c("td", [
                !_vm.isEdit
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.內容,
                          expression: "data.內容",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { min: "1", rows: "5", required: "" },
                      domProps: { value: _vm.data.內容 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "內容", $event.target.value)
                        },
                      },
                    })
                  : _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.新內容,
                          expression: "data.新內容",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { min: "1", rows: "5", required: "" },
                      domProps: { value: _vm.data.新內容 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "新內容", $event.target.value)
                        },
                      },
                    }),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("處理狀態")))]),
              _c(
                "td",
                [
                  _c("iCheckRedioButton", {
                    staticClass: "radio-inline",
                    attrs: {
                      checkValue: "0",
                      message: _vm.$t("處理中"),
                      name: "blockType",
                    },
                    model: {
                      value: _vm.data.處理狀態,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "處理狀態", _vm._n($$v))
                      },
                      expression: "data.處理狀態",
                    },
                  }),
                  _c("iCheckRedioButton", {
                    staticClass: "radio-inline ml-25",
                    attrs: {
                      checkValue: "1",
                      message: _vm.$t("結案"),
                      name: "blockType",
                    },
                    model: {
                      value: _vm.data.處理狀態,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "處理狀態", _vm._n($$v))
                      },
                      expression: "data.處理狀態",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }