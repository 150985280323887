var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "FcAnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.type) + "公告")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FC/AnnouncementList" } }, [
              _vm._v("官方公告發送"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.type) + "公告"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "fcAnnouncementForm", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.startTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(2),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.endTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(5),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.platform,
                            expression: "data.platform",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "40%" },
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "platform",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "所有平台" } }, [
                          _vm._v("所有平台"),
                        ]),
                        _c("option", { attrs: { value: "Android" } }, [
                          _vm._v("Android"),
                        ]),
                        _c("option", { attrs: { value: "iOS" } }, [
                          _vm._v("iOS"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.type === "系統"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.data.title,
                              expression: "data.title",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputTitle",
                            maxlength: "30",
                            placeholder: "請輸入主旨，上限30字",
                            type: "text",
                            required: "",
                          },
                          domProps: { value: _vm.data.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "title",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _c("p", { staticClass: "word-count" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.data.title.length) +
                              " / " +
                              _vm._s(_vm.titleMax) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "editor" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.info,
                            expression: "data.info",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          cols: "100",
                          rows: "10",
                          maxlength: _vm.contentMax,
                          placeholder: `請輸入內容，上限${_vm.contentMax}字。`,
                          required: "",
                        },
                        domProps: { value: _vm.data.info },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data, "info", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "word-count" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.data.info.length) +
                          " / " +
                          _vm._s(_vm.contentMax) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.save(0)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.backList()
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發送時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 截止時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送平台： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息主旨： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息內容： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }