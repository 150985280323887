var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("Banner管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _vm.id
          ? _c(
              "li",
              { staticClass: "active" },
              [
                _c("router-link", { attrs: { to: { params: { id: null } } } }, [
                  _vm._v("Banner管理"),
                ]),
              ],
              1
            )
          : _c("li", { staticClass: "active" }, [_vm._v("Banner管理")]),
        _vm.id
          ? _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))])
          : _vm._e(),
      ]),
    ]),
    !_vm.id
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "top-box" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-block btn-warning btn-add fa fa-plus",
                      attrs: { to: { params: { id: "New" } } },
                    },
                    [_vm._v("新增")]
                  ),
                  _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterStatus,
                            expression: "filterStatus",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.filterStatus = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.clickSearch,
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("所有狀態"),
                        ]),
                        _c("option", { attrs: { value: "進行中" } }, [
                          _vm._v("進行中"),
                        ]),
                        _c("option", { attrs: { value: "等待上線" } }, [
                          _vm._v("等待上線"),
                        ]),
                        _c("option", { attrs: { value: "已過期" } }, [
                          _vm._v("已過期"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _c("p", [
                      _vm._v(
                        "資料總筆數：" + _vm._s(_vm.filteredBanners.length)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "table-responsive mt-10 text-center" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-hover table-bordered AdListTable",
                  },
                  [
                    _c(
                      "tbody",
                      [
                        _vm._m(0),
                        _vm.filteredBanners.length <= 0
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: { colspan: "10" },
                                },
                                [_vm._v("無資料")]
                              ),
                            ])
                          : _vm._l(_vm.pagedBanners, function (item, index) {
                              return _c("tr", { key: item.id }, [
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item.orderNum,
                                        expression: "item.orderNum",
                                      },
                                    ],
                                    staticClass: "form-control sort",
                                    attrs: {
                                      type: "number",
                                      min: "1",
                                      max: _vm.banners.total,
                                      disabled: item.isStop == 1,
                                    },
                                    domProps: { value: item.orderNum },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          item,
                                          "orderNum",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  item.isStop != 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning ml-5",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateBannerOd(item)
                                            },
                                          },
                                        },
                                        [_vm._v("更新")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("td", [
                                  _c("div", { staticClass: "pic" }, [
                                    _c("img", {
                                      attrs: {
                                        src: item.filesUrl,
                                        draggable: "false",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.note))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.GetStatus(item, false))),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.uiDate(item.formalStartTime))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.uiDate(item.formalEndTime))
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editorItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger ml-5",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("刪除")]
                                  ),
                                ]),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "box-footer text-center no-border" },
                [
                  _c("Pagination", {
                    ref: "pagination",
                    attrs: {
                      total: _vm.filteredBanners.length,
                      pageLimitCount: _vm.searchData.pageSize,
                    },
                    on: { pageMethod: _vm.onPageChange },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "top-box with-border" },
                [
                  _c(
                    "h4",
                    { staticClass: "pull-left", attrs: { id: "title" } },
                    [_vm._v(_vm._s(_vm.id == "New" ? "新增" : "編輯") + "內容")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-block btn-default btn-cog",
                      attrs: { to: { params: { id: null } } },
                    },
                    [
                      _c("i", { staticClass: "fa fa-reply" }),
                      _vm._v(" 返回列表 "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "form",
                {
                  staticClass: "form-horizontal",
                  attrs: { name: "announcementForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      _vm.Save()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label required",
                          attrs: { for: "inputTitle" },
                        },
                        [_vm._v("名稱：")]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.note,
                              expression: "edit.note",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "inputTitle",
                            required: "",
                            placeholder: "辨識用，前端用於圖片alt",
                          },
                          domProps: { value: _vm.edit.note },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.edit, "note", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label",
                          attrs: { for: "inputContent" },
                        },
                        [_vm._v("內容：")]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.note,
                              expression: "edit.note",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputContent",
                            rows: "3",
                            placeholder: "",
                          },
                          domProps: { value: _vm.edit.note },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.edit, "note", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group image-part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label required",
                          attrs: { for: "AdPic" },
                        },
                        [_vm._v("圖片：")]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("input", {
                          attrs: {
                            type: "file",
                            id: "AdPic",
                            accept: "image/webp, image/png, image/gif",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event)
                            },
                          },
                        }),
                        _c("p", { staticClass: "help-block text-red" }, [
                          _vm._v(
                            "※檔案尺寸 960×540px，小於" +
                              _vm._s(this.imgMaxSize) +
                              "Kb，格式 webp、png、gif"
                          ),
                        ]),
                        _c("img", {
                          staticStyle: { "max-height": "200px" },
                          attrs: {
                            src: _vm.previewImg,
                            draggable: "false",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group bannerlink-part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label required",
                          attrs: { for: "inputLink" },
                        },
                        [_vm._v("連結：")]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.bannerLink,
                              expression: "edit.bannerLink",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "url", id: "inputLink", required: "" },
                          domProps: { value: _vm.edit.bannerLink },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "bannerLink",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("label", { staticClass: "mt-7" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: { checked: !!_vm.edit.isLinkOpen },
                            on: {
                              change: function ($event) {
                                _vm.edit.isLinkOpen =
                                  +$event.currentTarget.checked
                              },
                            },
                          }),
                          _vm._v(" 另開視窗 "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 control-label required",
                          attrs: { for: "inputOrderNum" },
                        },
                        [_vm._v("排序：")]
                      ),
                      _c("div", { staticClass: "col-sm-9" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.edit.orderNum,
                              expression: "edit.orderNum",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            id: "inputOrderNum",
                            min: "0",
                          },
                          domProps: { value: _vm.edit.orderNum },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "orderNum",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-3 control-label required" },
                        [_vm._v("上架時間：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("DateAndTimePicker", {
                            attrs: { format: "YYYY/MM/DD HH:mm:ss" },
                            model: {
                              value: _vm.edit.testStartTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "testStartTime", $$v)
                              },
                              expression: "edit.testStartTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-3 control-label required" },
                        [_vm._v("下架時間：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("DateAndTimePicker", {
                            attrs: { format: "YYYY/MM/DD HH:mm:ss" },
                            model: {
                              value: _vm.edit.testEndTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "testEndTime", $$v)
                              },
                              expression: "edit.testEndTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._m(1),
                ]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "135px", width: "5%" } }, [
        _vm._v("排序"),
      ]),
      _c("th", { staticStyle: { "min-width": "120px", width: "5%" } }, [
        _vm._v("主圖"),
      ]),
      _c("th", { staticStyle: { "min-width": "120px", width: "10%" } }, [
        _vm._v("名稱"),
      ]),
      _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
        _vm._v("狀態"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("開始時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
        _vm._v("結束時間"),
      ]),
      _c("th", { staticStyle: { "min-width": "135px", width: "5%" } }, [
        _vm._v("功能"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }