var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$user.hasPermission("MessageSystem")
    ? _c(
        "div",
        { staticClass: "message_style" },
        [
          _c("section", { staticClass: "content-header" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.$t("訊息系統")) + " "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.是否顯示異常按鈕,
                    expression: "是否顯示異常按鈕",
                  },
                ],
                staticClass: "fa fa-warning",
                staticStyle: { color: "red", cursor: "pointer" },
                attrs: { title: _vm.$t("下載事件記錄") },
                on: {
                  click: function ($event) {
                    _vm.刷新畫面()
                  },
                },
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDebug,
                    expression: "isDebug",
                  },
                ],
                staticClass: "fa fa-check-square",
                staticStyle: { color: "red", cursor: "pointer" },
                attrs: { title: "" },
                on: {
                  click: function ($event) {
                    return _vm.$store.dispatch(
                      "messageSystemServer/ReadLog",
                      ""
                    )
                  },
                },
              }),
            ]),
            _c("span", { ref: "copyTip", staticClass: "copyTip" }, [
              _c("i", { staticClass: "fa fa-check" }),
              _vm._v(" " + _vm._s(_vm.$t("複製成功"))),
            ]),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
                1
              ),
              _c("li", { staticClass: "active" }, [
                _vm._v(_vm._s(_vm.$t("訊息系統"))),
              ]),
            ]),
          ]),
          _c("ServerStatusForm"),
          _c(
            "section",
            {
              staticClass: "content",
              style: {
                display: _vm.ServerIsOnline != "Connected" ? "none" : "",
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3 col-xs-12",
                    staticStyle: {
                      "border-top": "1px solid #E8E8E8",
                      "border-right": "1px solid #E8E8E8",
                    },
                  },
                  [
                    _c("div", { staticClass: "left-nav" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.sel左邊Tool,
                              expression: "sel左邊Tool",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.sel左邊Tool = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("客服狀態"))),
                          ]),
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("罐頭訊息"))),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sel左邊Tool == 1,
                            expression: "sel左邊Tool == 1",
                          },
                        ],
                        staticClass: "left-content",
                      },
                      [
                        _c("div", { staticClass: "left-content-cs-list" }, [
                          _c("table", [
                            _c(
                              "tbody",
                              [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("客服名稱"))),
                                  ]),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("允許派發"))),
                                  ]),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("服務數量"))),
                                  ]),
                                ]),
                                _vm.Sort線上客服列表.length > 0
                                  ? _vm._l(
                                      _vm.Sort線上客服列表,
                                      function (item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.Account,
                                            staticClass: "tr_hover",
                                            on: {
                                              click: function ($event) {
                                                _vm.sel客服 = item.Account
                                              },
                                            },
                                          },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(item.AccountName) + " "
                                              ),
                                            ]),
                                            _c("td", [
                                              item.AutoDispatchState
                                                ? _c("span", {
                                                    staticClass: "fa fa-bell-o",
                                                  })
                                                : _vm._e(),
                                              item.IsServiceVIP
                                                ? _c("span", {
                                                    staticClass: "fa fa-star-o",
                                                  })
                                                : _vm._e(),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.ServiceCount)),
                                            ]),
                                          ]
                                        )
                                      }
                                    )
                                  : [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-center",
                                            attrs: { colspan: "3" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                                        ),
                                      ]),
                                    ],
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "left-content-cs-list" },
                          [
                            _vm.左邊線上玩家列表.length > 0
                              ? [
                                  _c("table", [
                                    _c(
                                      "tbody",
                                      [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.sel客服Name) +
                                                  " " +
                                                  _vm._s(_vm.$t("服務會員列表"))
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._l(
                                          _vm.左邊線上玩家列表,
                                          function (玩家) {
                                            return _c(
                                              "tr",
                                              {
                                                key: 玩家.EventId,
                                                staticClass: "tr_hover",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.開始會員聊天(玩家.Nick)
                                                  },
                                                },
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(玩家.Nick) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "badge",
                                                      class: {
                                                        hide:
                                                          玩家.NoReturnCount ==
                                                          0,
                                                        color_blue:
                                                          玩家.是否出現接手事件按鈕,
                                                        color_red:
                                                          !玩家.是否出現接手事件按鈕,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            玩家.NoReturnCount
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    玩家.倒數時間或最後時間
                                                      .顯示倒數
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              玩家
                                                                .倒數時間或最後時間
                                                                .顯示字串
                                                            )
                                                          ),
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            _vm._s(
                                                              玩家
                                                                .倒數時間或最後時間
                                                                .顯示字串
                                                            )
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sel左邊Tool == 2,
                            expression: "sel左邊Tool == 2",
                          },
                        ],
                        staticClass: "left-content",
                      },
                      [
                        _c("CanMessageForm", {
                          ref: "canMessageForm",
                          on: { addCanMessag: _vm.addCanMessag },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.messageForm == _vm.MessageForm.Chat,
                        expression: "messageForm == MessageForm.Chat",
                      },
                    ],
                    staticClass: "col-sm-6 col-xs-12",
                    staticStyle: { background: "#F8F8FA" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.聊天視窗_事件物件.EventId != "",
                            expression: "聊天視窗_事件物件.EventId != ''",
                          },
                        ],
                        staticClass:
                          "box box-warning direct-chat direct-chat-warning",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "box-header defineHeader with-border-top with-border-bottom",
                            staticStyle: { "background-color": "#fff" },
                          },
                          [
                            _c("h3", { staticClass: "box-title" }, [
                              _vm._v(_vm._s(_vm.聊天會員對象) + " "),
                              _c("i", {
                                staticClass: "fa fa-copy",
                                staticStyle: {
                                  color: "gray",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.一鍵複製(_vm.聊天會員對象)
                                  },
                                },
                              }),
                            ]),
                            _c("br"),
                            _c(
                              "div",
                              {
                                staticClass: "box-title",
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#979797",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("最後更新時間")) +
                                    "：" +
                                    _vm._s(_vm.聊天視窗_格式化最後更新時間) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "eventTools" }, [
                              _vm.MessageSystemStartService &&
                              _vm.聊天視窗_事件物件.強制接手權限
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tool",
                                      on: {
                                        click: function ($event) {
                                          _vm.強制接手事件()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("接手事件")))]
                                  )
                                : _vm._e(),
                              _vm.MessageSystemFinishService &&
                              _vm.聊天視窗_事件物件.聊天視窗_強制分類和完成事件
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tool",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPopForceCompleteForm(
                                            _vm.聊天視窗_事件物件
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("強制結束")))]
                                  )
                                : _vm._e(),
                              _vm.聊天視窗_事件物件.事件狀態是否為已結束
                                ? _c("span", { staticClass: "tool" }, [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("已完成")) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.聊天視窗_事件物件.聊天視窗_接手事件按鈕
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tool",
                                      on: {
                                        click: function ($event) {
                                          return _vm.startService()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("接手事件")))]
                                  )
                                : _vm._e(),
                              _vm.聊天視窗_事件物件.聊天視窗_分類和完成事件
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "tool done",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPopCompleteForm(
                                            _vm.聊天視窗_事件物件
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("分類和完成事件")))]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "box-body" },
                          [
                            _c("ChatMessageForm", {
                              attrs: { historyType: "ChatHistory" },
                            }),
                            _c("SendMessageBtn", {
                              ref: "sendChatMessageBtn",
                              attrs: {
                                是否可發訊息: _vm.聊天視窗_是否可發訊息,
                                提示訊息: _vm.聊天視窗_提示訊息,
                              },
                              on: {
                                SendChatMessage: _vm.sendChatMessage,
                                UpdChatMessage: _vm.updChatMessage,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.聊天視窗_事件物件.EventId == "",
                            expression: "聊天視窗_事件物件.EventId == ''",
                          },
                        ],
                        staticClass:
                          "body-align-center-middle2 with-border-top",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("請選擇聊天室")) + " ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.messageForm == _vm.MessageForm.Secret,
                        expression: "messageForm == MessageForm.Secret",
                      },
                    ],
                    staticClass: "col-sm-6 col-xs-12",
                    staticStyle: { background: "#F8F8FA" },
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "secret-title with-border-top with-border-bottom",
                      },
                      [_vm._v(_vm._s(_vm.$t("新增密語")))]
                    ),
                    _c("div", { staticClass: "body-align-center-middle" }, [
                      _c("div", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v(_vm._s(_vm.$t("密語對象"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.secretPlayer,
                            expression: "secretPlayer",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control secret-player-input",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("請輸入玩家暱稱"),
                        },
                        domProps: { value: _vm.secretPlayer },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.secretPlayer = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "0px",
                          right: "0",
                          left: "0px",
                        },
                      },
                      [
                        _c("SendMessageBtn", {
                          ref: "sendSecretMessageBtn",
                          attrs: {
                            是否可發訊息: true,
                            提示訊息: _vm.$t("請輸入內容"),
                          },
                          on: { SendChatMessage: _vm.sendSecretChatMessage },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-sm-3 col-xs-12",
                    staticStyle: {
                      "border-top": "1px solid #E8E8E8",
                      "border-left": "1px solid #E8E8E8",
                    },
                  },
                  [
                    _c("div", { staticClass: "btn-group-message" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: { active: _vm.selProcessStatus == -1 },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.selProcessStatus = -1
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("全部")))]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: { active: _vm.selProcessStatus == -2 },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.selProcessStatus = -2
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("處理中")))]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: { active: _vm.selProcessStatus == -3 },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.selProcessStatus = -3
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("已完成")))]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btn-group-message",
                        staticStyle: { "margin-top": "-9px" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: { active: _vm.sel事件 == 0 },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.sel事件 = 0
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.$t("所有事件")))]),
                            _vm._v(" "),
                            _vm.總未回數 > 0
                              ? _c(
                                  "span",
                                  { staticClass: "badge color_blue" },
                                  [_vm._v(" " + _vm._s(_vm.總未回數))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: { active: _vm.sel事件 == 1 },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.sel事件 = 1
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.$t("我的事件")))]),
                            _vm._v(" "),
                            _vm.我的未回數 > 0
                              ? _c("span", { staticClass: "badge color_red" }, [
                                  _vm._v(" " + _vm._s(_vm.我的未回數) + " "),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "right-content cal-list-height" },
                      [
                        _vm.右邊線上玩家列表.length > 0
                          ? _vm._l(_vm.右邊線上玩家列表, function (玩家) {
                              return _c(
                                "div",
                                {
                                  key: 玩家.EventId,
                                  staticClass: "rigth-event-div",
                                  class:
                                    _vm.clickEventId == 玩家.EventId
                                      ? "active"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      _vm.開始會員聊天(玩家.Nick, 玩家.EventId)
                                      _vm.consoleEvent(玩家)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "first-row" }, [
                                    _c("span", { staticClass: "player-name" }, [
                                      _vm._v(" " + _vm._s(玩家.Nick) + " "),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: 玩家.Rank,
                                            expression: "玩家.Rank",
                                          },
                                        ],
                                        staticClass: "vip",
                                      },
                                      [_vm._v(_vm._s(玩家.Rank))]
                                    ),
                                    _vm._v("    "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge",
                                        class: {
                                          hide: 玩家.NoReturnCount == 0,
                                          color_blue: 玩家.是否出現接手事件按鈕,
                                          color_red: !玩家.是否出現接手事件按鈕,
                                        },
                                        staticStyle: {
                                          "vertical-align": "top",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(玩家.NoReturnCount))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: 玩家.聊天視窗_分類和完成事件,
                                            expression:
                                              "玩家.聊天視窗_分類和完成事件",
                                          },
                                        ],
                                        staticClass: "editBtn",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-edit",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openPopCompleteForm(
                                                玩家
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.MessageSystemFinishService &&
                                              玩家.聊天視窗_強制分類和完成事件,
                                            expression:
                                              "MessageSystemFinishService && 玩家.聊天視窗_強制分類和完成事件",
                                          },
                                        ],
                                        staticClass: "editBtn",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-edit",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openPopForceCompleteForm(
                                                玩家
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-style-ellipsis last-message",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(玩家.LastMsg.Msg) + " "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "second-row" }, [
                                    玩家.倒數時間或最後時間.顯示倒數
                                      ? _c(
                                          "span",
                                          { staticClass: "wait_time" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                玩家.倒數時間或最後時間.秒數
                                              ) + _vm._s(_vm.$t("秒"))
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "end_time" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                玩家.倒數時間或最後時間.顯示字串
                                              )
                                            ),
                                          ]
                                        ),
                                    _vm.selProcessStatus == -1
                                      ? _c("span", [
                                          _vm._v(
                                            " ·" +
                                              _vm._s(
                                                _vm.事件狀態Tag(
                                                  玩家.ProcessStatus,
                                                  玩家.EventType
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: 玩家.EventType,
                                            expression: "玩家.EventType",
                                          },
                                        ],
                                        staticStyle: {
                                          color: "#f39c12",
                                          "padding-left": "3px",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-check-circle",
                                        }),
                                      ]
                                    ),
                                    _vm.sel事件 == 0 && 玩家.顯示客服名稱
                                      ? _c(
                                          "span",
                                          { staticClass: "serviceName" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(玩家.CSName) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c("PopCompleteForm", {
            ref: "popCompleteForm",
            on: {
              FinishService: _vm.finishService,
              ResetEventType: _vm.resetEventType,
            },
          }),
          _c("PopForceCompleteForm", {
            ref: "popForceCompleteForm",
            on: {
              EnforceFinishService: _vm.enforceFinishService,
              ResetEventType: _vm.resetEventType,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }