var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "FcAnnouncementList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("官方公告發送")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("官方公告發送")]),
        ]),
      ]),
      _c("section", { staticClass: "content customer-service" }, [
        _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
          _c("ul", { staticClass: "nav nav-tabs" }, [
            _c(
              "li",
              { class: { active: _vm.searchData.typeName === "系統" } },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      "aria-expanded": "true",
                      "data-toggle": "tab",
                      href: "#problem",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList("系統", -9)
                      },
                    },
                  },
                  [_vm._v(" 系統公告 ")]
                ),
              ]
            ),
            _c(
              "li",
              { class: { active: _vm.searchData.typeName === "緊急" } },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      "aria-expanded": "false",
                      "data-toggle": "tab",
                      href: "#report",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList("緊急", -9)
                      },
                    },
                  },
                  [_vm._v(" 緊急公告 ")]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane",
                class: { active: _vm.searchData.typeName === "系統" },
              },
              [
                _c("div", { staticClass: "top-box" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addContent()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                  ),
                  _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.searchData.status,
                            expression: "searchData.status",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.searchData,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList("系統")
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "-9" } }, [
                          _vm._v("所有狀態"),
                        ]),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("等待中"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("已發送"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("已過期"),
                        ]),
                        _c("option", { attrs: { value: "-1" } }, [
                          _vm._v("已刪除"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "table-responsive mt-10" }, [
                  _c(
                    "table",
                    {
                      staticClass: "table table-bordered text-center min-1000",
                    },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(0),
                          _vm.loading
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "9" } }, [
                                  _vm._v("查詢中"),
                                ]),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "9" } }, [
                                  _vm._v("查詢無任何資料"),
                                ]),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: {
                                      "bg-gray-light": item.status === -1,
                                    },
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.datas.length - index)),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.sendStartTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.sendEndTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(item.platform))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("ellipsis")(item.title, 20)
                                        )
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkContent(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看 ")]
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.announcementStatus[
                                              item.status
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.updateUName)),
                                    ]),
                                    _c("td", [
                                      item.status !== -1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-danger",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteContent(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 刪除 ")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                class: { active: _vm.searchData.typeName === "緊急" },
              },
              [
                _c("div", { staticClass: "top-box" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-warning btn-add",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.addContent()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                  ),
                  _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.searchData.status,
                            expression: "searchData.status",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.searchData,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.resetDataList("緊急")
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "-9" } }, [
                          _vm._v("所有狀態"),
                        ]),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("等待中"),
                        ]),
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("已發送"),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("已過期"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "table-responsive mt-10" }, [
                  _c(
                    "table",
                    {
                      staticClass: "table table-bordered text-center min-1000",
                    },
                    [
                      _c(
                        "tbody",
                        [
                          _vm._m(1),
                          _vm.loading
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "7" } }, [
                                  _vm._v("查詢中"),
                                ]),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "7" } }, [
                                  _vm._v("查詢無任何資料"),
                                ]),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: {
                                      "bg-gray-light": item.status === -1,
                                    },
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.datas.length - index)),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.sendStartTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.sendEndTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(item.platform))]),
                                    _c("td", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkContent(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看 ")]
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.announcementStatus[
                                              item.status
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.updateUName)),
                                    ]),
                                  ]
                                )
                              }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "box text-center no-border no-shadow mt-20" },
              [
                _c("Pagination", {
                  ref: "pagination",
                  attrs: {
                    pageLimitCount: _vm.searchData.pageSize,
                    total: _vm.total,
                  },
                  on: { pageMethod: _vm.onPageChange },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("announcementContent", { ref: "announcementContent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("發送時間")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("截止時間")]),
      _c("th", [_vm._v("發送平台")]),
      _c("th", { staticStyle: { "min-width": "200px" } }, [_vm._v("訊息主旨")]),
      _c("th", [_vm._v("訊息內容")]),
      _c("th", [_vm._v("狀態")]),
      _c("th", [_vm._v("操作者")]),
      _c("th", [_vm._v("功能")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", [_vm._v("編號")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("發送時間")]),
      _c("th", { staticStyle: { "min-width": "150px" } }, [_vm._v("截止時間")]),
      _c("th", [_vm._v("發送平台")]),
      _c("th", [_vm._v("訊息內容")]),
      _c("th", [_vm._v("狀態")]),
      _c("th", [_vm._v("操作者")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }