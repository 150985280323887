var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "PopForm", staticClass: "mymodal" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "600px",
          "margin-top": "30px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [
        _c("div", { staticClass: "box box-info" }, [
          _c("div", { staticClass: "box-header with-border" }, [
            _c("h3", { staticClass: "box-title" }, [
              _vm._v(" " + _vm._s(_vm.title)),
            ]),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.Close()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _c("div", { staticClass: "card" }, [
            _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.VIPMsgEnable,
                      expression: "VIPMsgEnable",
                    },
                  ],
                  staticClass: "card-title span-vertical-middle",
                },
                [_vm._v(_vm._s(_vm.$t("通用訊息")))]
              ),
              _c("span", {
                staticClass: "state iradio_flat-blue",
                attrs: { checked: true },
              }),
              _c("span", { staticClass: "span-vertical-middle" }, [
                _vm._v(_vm._s(_vm.$t("自訂內容"))),
              ]),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.edit.Msg,
                  expression: "edit.Msg",
                  modifiers: { trim: true },
                },
                {
                  name: "AddArgInput",
                  rawName: "v-AddArgInput",
                  value: { model: "edit.Msg", format: _vm.AddArgMixinsFormat },
                  expression:
                    "{ model: 'edit.Msg', format: AddArgMixinsFormat }",
                },
              ],
              ref: "editTextarea",
              staticClass: "form-control card-textarea",
              attrs: { placeholder: _vm.$t("請輸入內容") },
              domProps: { value: _vm.edit.Msg },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.edit, "Msg", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _c("div", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "AddArgBtn",
                      rawName: "v-AddArgBtn",
                      value: {
                        insertText: "{{cs_number}}",
                        ref: "editTextarea",
                        model: "edit.Msg",
                      },
                      expression:
                        "{ insertText: '{{cs_number}}', ref: 'editTextarea', model: 'edit.Msg' }",
                    },
                  ],
                  staticClass: "btn btn-warning",
                  attrs: { type: "button" },
                },
                [_vm._v(_vm._s(_vm.$t("添加客服編號")))]
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.VIPMsgEnable,
                  expression: "VIPMsgEnable",
                },
              ],
              staticClass: "card",
            },
            [
              _c("div", [
                _c("span", { staticClass: "card-title span-vertical-middle" }, [
                  _vm._v(_vm._s(_vm.$t("VIP專屬"))),
                ]),
                _c("span", {
                  staticClass: "state iradio_flat-blue",
                  attrs: { checked: true },
                }),
                _c("span", { staticClass: "span-vertical-middle" }, [
                  _vm._v(_vm._s(_vm.$t("自訂內容"))),
                ]),
                _c(
                  "div",
                  { staticStyle: { float: "right", "font-size": "18px" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#437d43", "font-size": "14px" },
                      },
                      [_vm._v(_vm._s(_vm.$t("VIPMsgTip-" + _vm.projectId)))]
                    ),
                  ]
                ),
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.edit.MsgVIP,
                    expression: "edit.MsgVIP",
                    modifiers: { trim: true },
                  },
                  {
                    name: "AddArgInput",
                    rawName: "v-AddArgInput",
                    value: {
                      model: "edit.MsgVIP",
                      format: _vm.AddArgMixinsFormat,
                    },
                    expression:
                      "{ model: 'edit.MsgVIP', format: AddArgMixinsFormat }",
                  },
                ],
                ref: "editTextarea2",
                staticClass: "form-control",
                staticStyle: { height: "100px" },
                attrs: { placeholder: _vm.$t("請輸入內容") },
                domProps: { value: _vm.edit.MsgVIP },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.edit, "MsgVIP", $event.target.value.trim())
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("div", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "AddArgBtn",
                        rawName: "v-AddArgBtn",
                        value: {
                          insertText: "{{cs_number}}",
                          ref: "editTextarea2",
                          model: "edit.MsgVIP",
                        },
                        expression:
                          "{ insertText: '{{cs_number}}', ref: 'editTextarea2', model: 'edit.MsgVIP' }",
                      },
                    ],
                    staticClass: "btn btn-warning",
                    attrs: { type: "button" },
                  },
                  [_vm._v(_vm._s(_vm.$t("添加客服編號")))]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "box-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                staticStyle: { "margin-right": "15px" },
                attrs: { type: "button" },
                on: { click: _vm.Close },
              },
              [_vm._v(_vm._s(_vm.$t("關閉")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: { type: "button" },
                on: { click: _vm.save },
              },
              [_vm._v(_vm._s(_vm.$t("儲存")))]
            ),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }