var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass:
        "table table-bordered classTypeTable InvoiceElectronicItemStyle",
      class:
        _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.二聯
          ? "style2聯"
          : "style3聯",
    },
    [
      _c(
        "tbody",
        [
          _c("tr", { staticClass: "bg-gray" }, [
            _vm.isEdit
              ? _c("th", { staticClass: "text-nowrap" }, [_vm._v("功能")])
              : _vm._e(),
            _c("th", { staticClass: "text-nowrap" }, [_vm._v("品名")]),
            _c("th", { staticClass: "text-nowrap" }, [_vm._v("數量")]),
            _c("th", { staticClass: "text-nowrap" }, [
              _vm._v("單價"),
              _c("span", { staticClass: "color聯別" }, [
                _vm._v(
                  _vm._s(
                    _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.二聯
                      ? "(含稅)"
                      : "(未稅)"
                  )
                ),
              ]),
            ]),
            _c("th", { staticClass: "text-nowrap" }, [_vm._v("金額")]),
          ]),
          _vm._l(_vm.商品明細, function (item) {
            return _c("tr", { key: item.id }, [
              _vm.isEdit
                ? _c("td", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-close",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("刪除商品", item)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: item.品名,
                      expression: "item.品名",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: !_vm.isEdit },
                  domProps: { value: item.品名 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(item, "品名", $event.target.value.trim())
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim.number",
                      value: item.數量,
                      expression: "item.數量",
                      modifiers: { trim: true, number: true },
                    },
                  ],
                  staticClass: "form-control text-right",
                  attrs: { type: "number", disabled: !_vm.isEdit },
                  domProps: { value: item.數量 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(item, "數量", _vm._n($event.target.value.trim()))
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim.number",
                      value: item.單價,
                      expression: "item.單價",
                      modifiers: { trim: true, number: true },
                    },
                  ],
                  staticClass: "form-control text-right",
                  attrs: { type: "number", disabled: !_vm.isEdit },
                  domProps: { value: item.單價 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(item, "單價", _vm._n($event.target.value.trim()))
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c(
                "td",
                {
                  staticClass: "text-nowrap text-right",
                  staticStyle: { "padding-right": "12px" },
                },
                [_vm._v(" " + _vm._s(_vm.四捨五入(item.數量, item.單價)) + " ")]
              ),
            ])
          }),
          _vm.商品明細.length == 0
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "text-center",
                    attrs: { colspan: _vm.isEdit ? 5 : 4 },
                  },
                  [_vm._v(" 無商品 ")]
                ),
              ])
            : _vm._e(),
          _c("tr", { staticClass: "noBorder1" }, [
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                attrs: { colspan: _vm.isEdit ? 4 : 3 },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.三聯
                        ? "銷售額總計"
                        : "總計(發票金額)"
                    )
                ),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                staticStyle: { "padding-right": "12px" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.三聯
                        ? _vm.銷售額總計
                        : _vm.總計
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("tr", { staticClass: "noBorder2" }, [
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                attrs: { colspan: _vm.isEdit ? 4 : 3 },
              },
              [
                _vm._v(" 營業稅別 "),
                _c(
                  "select",
                  {
                    staticClass: "form-control",
                    staticStyle: {
                      display: "inline-block",
                      "padding-right": "10px",
                      width: "90px",
                    },
                    attrs: { disabled: !_vm.isEdit },
                    domProps: { value: _vm.營業稅別 },
                    on: {
                      input: (e) =>
                        _vm.$emit("update:營業稅別", e.target.value),
                    },
                  },
                  _vm._l(_vm.稅別Enum, function (item, key) {
                    return _c("option", { domProps: { value: item } }, [
                      _vm._v(_vm._s(key)),
                    ])
                  }),
                  0
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn聯別",
                    staticStyle: {
                      "margin-left": "15px",
                      "margin-top": "-7px",
                    },
                    attrs: { type: "button", disabled: !_vm.isEdit },
                    on: {
                      click: function ($event) {
                        _vm.帶入營業稅(_vm.營業稅自動計算.值)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.營業稅自動計算.字串) +
                        _vm._s(_vm.營業稅自動計算.值) +
                        " "
                    ),
                  ]
                ),
                _vm._v(" 營業稅 "),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                staticStyle: { padding: "0px" },
              },
              [
                _c("input", {
                  ref: "營業稅Ref",
                  staticClass: "form-control text-right",
                  class:
                    _vm.營業稅自動計算.值 == _vm.營業稅 ? "" : "style-conflict",
                  attrs: { type: "number", disabled: !_vm.isEdit },
                  domProps: { value: _vm.營業稅 },
                  on: {
                    input: function ($event) {
                      _vm.更新營業稅($event)
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("tr", { staticClass: "noBorder2" }, [
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                attrs: { colspan: _vm.isEdit ? 4 : 3 },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.二聯
                        ? "銷售額總計"
                        : "總計(發票金額)"
                    )
                ),
              ]
            ),
            _c(
              "td",
              {
                staticClass: "text-nowrap text-right",
                staticStyle: { "padding-right": "12px" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.發票聯別與類型.聯別 == _vm.發票聯別Enum.二聯
                        ? _vm.銷售額總計
                        : _vm.總計
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }