var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹" + _vm._s(_vm.isEdit ? "編輯" : "新增"))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: _vm.goBack } }, [
              _vm._v("遊戲介紹" + _vm._s(_vm.isEdit ? "編輯" : "新增")),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.isEdit ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog pull-right",
                  attrs: { to: _vm.goBack },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-body",
                  staticStyle: { "max-width": "1200px" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [_vm._v(" Gid： ")]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v(_vm._s(_vm.edit.gid)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.遊戲館,
                            expression: "edit.遊戲館",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "inputTitle" },
                        domProps: { value: _vm.edit.遊戲館 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "遊戲館", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.遊戲分類,
                              expression: "edit.遊戲分類",
                            },
                          ],
                          staticClass: "form-control select-part",
                          attrs: { id: "inputType" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  "遊戲分類",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.updateTypeClassID,
                            ],
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v("請選擇分類")]
                          ),
                          _vm._l(_vm.newTypeClass, function (item, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                attrs: { class_id: item.classId },
                                domProps: { value: item.classTypeId },
                              },
                              [_vm._v(" " + _vm._s(item.className) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.遊戲簡介,
                            expression: "edit.遊戲簡介",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "summary",
                          placeholder: "內容建議100字內",
                        },
                        domProps: { value: _vm.edit.遊戲簡介 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "遊戲簡介", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "form-group image-part" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "col-sm-10" },
                        [
                          _c(
                            "ImageSelect",
                            {
                              ref: "imageSelect",
                              attrs: {
                                id: "imgLogo",
                                gid: _vm.edit.gid,
                                info: "logo",
                                module: "GameGlobal",
                                maxsize: 200 * 1024,
                                accept: "image/png",
                              },
                              model: {
                                value: _vm.edit.files,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "files", $$v)
                                },
                                expression: "edit.files",
                              },
                            },
                            [
                              _c("p", { staticClass: "help-block" }, [
                                _vm._v(" 小於200kb,背景透明png "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [
                        _vm.type.id == _vm.langs[0].id
                          ? _c("span", { staticClass: "text-red" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(" 版本類型： "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-sm-10 btns",
                        staticStyle: {
                          display: "flex",
                          "flex-wrap": "wrap",
                          gap: "5px",
                        },
                      },
                      _vm._l(_vm.langs, function (lang) {
                        return _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: { "btn-primary": _vm.type.id == lang.id },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.type = lang
                              },
                            },
                          },
                          [_vm._v(_vm._s(lang.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "inputTitle" },
                      },
                      [
                        _vm.type.id == _vm.langs[0].id
                          ? _c("span", { staticClass: "text-red" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.type.name) + "名稱： "),
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.toggleName,
                            expression: "toggleName",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "inputTitle" },
                        domProps: { value: _vm.toggleName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.toggleName = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-2 control-label",
                        attrs: { for: "Limits" },
                      },
                      [
                        _vm.type.id == _vm.langs[0].id
                          ? _c("span", { staticClass: "text-red" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.type.name) + "說明： "),
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "Limits editor" },
                        [
                          _c("tinymce", {
                            model: {
                              value: _vm.toggleContent,
                              callback: function ($$v) {
                                _vm.toggleContent = $$v
                              },
                              expression: "toggleContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.遊戲操作說明ID,
                              expression: "edit.遊戲操作說明ID",
                            },
                          ],
                          staticClass: "form-control select-part",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "遊戲操作說明ID",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "disabled", value: "" } },
                            [_vm._v(" 請選擇操作說明版本 ")]
                          ),
                          _vm._l(_vm.operationTypes, function (item) {
                            return _c(
                              "option",
                              { key: item.gid, domProps: { value: item.gid } },
                              [_vm._v(" " + _vm._s(item.version) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "help-block" },
                        [
                          _vm._v(" *進入 "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "AWH5GameOperationList" },
                                target: "_blank",
                              },
                            },
                            [_vm._v("遊戲操作說明列表")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(5),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.排序,
                            expression: "edit.排序",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "inputOrderNum", type: "number" },
                        domProps: { value: _vm.edit.排序 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "排序",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: true,
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否發佈,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否發佈", $$v)
                              },
                              expression: "edit.是否發佈",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: false,
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否發佈,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否發佈", $$v)
                              },
                              expression: "edit.是否發佈",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.Save(false)
                        },
                      },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲編號： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲分類： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "summary" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲簡介： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "imgLogo" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲館圖： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 操作說明： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "inputOrderNum" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }