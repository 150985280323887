var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AlbumPhotoManage" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("相簿管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("相簿列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("相簿管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c("form", { attrs: { name: "announcementForm" } }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                { staticClass: "form-group albumUploadWrapper clearfix" },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "buttonWrapper" }, [
                    _c("input", {
                      attrs: { accept: "image/*", type: "file", multiple: "" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _c("div", { staticClass: "help-block text-red" }, [
                      _vm._v("(每個檔案上限5MB，最多可以選5個檔案)"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "photo-album",
                  attrs: { id: "albumPhtoWrapper" },
                },
                _vm._l(_vm.fileList, function (file, index) {
                  return _c("li", { key: index, staticClass: "photoBox" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pic jqimgFill",
                        attrs: { "data-id": file.id },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: file.imgFullFileName,
                            draggable: "false",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteFile(file)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-trash" })]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "control-label", attrs: { for: "Pic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("請選擇上傳檔案： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }