var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("遊戲介紹" + _vm._s(_vm.isEdit ? "編輯" : "新增"))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "XsgWebGame",
                    params: { searchData: _vm.$route.params.searchData },
                  },
                },
              },
              [_vm._v("遊戲介紹列表")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.id))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c(
            "div",
            { staticClass: "top-box with-border" },
            [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: {
                    to: {
                      name: "XsgWebGame",
                      params: { searchData: _vm.$route.params.searchData },
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  _vm.Save()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "box-body" },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-3 control-label" }, [
                      _vm._v(" GID： "),
                    ]),
                    _c("div", { staticClass: "col-sm-9 mt-7" }, [
                      _vm._v(" " + _vm._s(this.edit.gid) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.遊戲分類,
                              expression: "edit.遊戲分類",
                            },
                          ],
                          staticClass: "form-control select-part",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "遊戲分類",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", selected: "", disabled: "" },
                            },
                            [_vm._v("請選擇分類")]
                          ),
                          _vm._l(_vm.dfn.gameCategory, function (name, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: key } },
                              [_vm._v(" " + _vm._s(key + " " + name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.中文名稱,
                            expression: "edit.中文名稱",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputTitle",
                          required: "",
                          placeholder: "",
                        },
                        domProps: { value: _vm.edit.中文名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "中文名稱", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.英文名稱,
                            expression: "edit.英文名稱",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputName",
                          required: "",
                          placeholder: "",
                        },
                        domProps: { value: _vm.edit.英文名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "英文名稱", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c(
                          "ImageSelect",
                          {
                            ref: "imageSelect",
                            attrs: {
                              id: "AdPic",
                              gid: _vm.edit.gid,
                              info: "logo",
                              module: "WebGame",
                              maxsize: 200 * 1024,
                              accept: "image/png",
                            },
                            model: {
                              value: _vm.imgEdit,
                              callback: function ($$v) {
                                _vm.imgEdit = $$v
                              },
                              expression: "imgEdit",
                            },
                          },
                          [
                            _c("p", { staticClass: "help-block" }, [
                              _vm._v("尺寸188×208px，背景透明png"),
                              _c("br"),
                              _vm._v("(圖片綁定貼文，新增圖片即新增貼文)"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.遊戲影片ID,
                            expression: "edit.遊戲影片ID",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputName",
                          required: "",
                          placeholder: "填入代碼即可。ex: 8zf6Wi2BtsE",
                        },
                        domProps: { value: _vm.edit.遊戲影片ID },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "遊戲影片ID",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._m(5),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "div",
                        { staticClass: "editor" },
                        [
                          _c("tinymce", {
                            attrs: { height: "400" },
                            model: {
                              value: _vm.edit.內容,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "內容", $$v)
                              },
                              expression: "edit.內容",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputDescription" },
                      },
                      [_vm._v(" Description： ")]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.description,
                            expression: "edit.description",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputDescription",
                          rows: "2",
                          placeholder: "網頁的描述",
                        },
                        domProps: { value: _vm.edit.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputKeywords" },
                      },
                      [_vm._v(" Keywords： ")]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.keyword,
                            expression: "edit.keyword",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputKeywords",
                          placeholder: "網頁的關鍵字",
                        },
                        domProps: { value: _vm.edit.keyword },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "keyword", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(7),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.排序,
                            expression: "edit.排序",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "inputOrderNum",
                          min: "0",
                        },
                        domProps: { value: _vm.edit.排序 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "排序",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(8),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "上架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否發佈,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否發佈", $$v)
                              },
                              expression: "edit.是否發佈",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio radio-inline" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "0",
                              message: "下架",
                              name: "radioMode",
                            },
                            model: {
                              value: _vm.edit.是否發佈,
                              callback: function ($$v) {
                                _vm.$set(_vm.edit, "是否發佈", $$v)
                              },
                              expression: "edit.是否發佈",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(9),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c("DateAndTimePicker", {
                          model: {
                            value: _vm.edit.開始時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "開始時間", $$v)
                            },
                            expression: "edit.開始時間",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(10),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c("DateAndTimePicker", {
                          model: {
                            value: _vm.edit.結束時間,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "結束時間", $$v)
                            },
                            expression: "edit.結束時間",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("hr"),
                  _vm._l(_vm.tagOptions, function (group, name) {
                    return _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-3 control-label" }, [
                        _vm._v(" " + _vm._s(name) + "： "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-sm-9" },
                        [
                          _c("TagSelect", {
                            attrs: { options: _vm.tagOptions[name] },
                            model: {
                              value: _vm.tagEdit[name],
                              callback: function ($$v) {
                                _vm.$set(_vm.tagEdit, name, $$v)
                              },
                              expression: "tagEdit[name]",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  }),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-sm-3 control-label" }),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "p",
                        { staticClass: "help-block" },
                        [
                          _vm._v("編輯類別與標籤請至 "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "XsgWebGameTag" },
                                target: "_blank",
                              },
                            },
                            [_vm._v("類別與標籤管理")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._m(11),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 遊戲分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 中文名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputName" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 英文名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "AdPic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲LOGO： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputName" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 遊戲影片ID： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _vm._v(" ※ID位置: "),
      _c("span", [_vm._v("https://www.youtube.com/watch?v=")]),
      _c("span", { staticClass: "text-red" }, [_vm._v("8zf6Wi2BtsE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputContent" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box-footer" }, [
      _c("div", { staticClass: "btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-lg fa fa-save",
            attrs: { type: "submit" },
          },
          [_vm._v(" 確認送出 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }