var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "MIDAnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("公告列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("公告列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增公告 ")]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.noteType,
                      expression: "searchData.noteType",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "noteType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "系統公告" } }, [
                    _vm._v("系統公告"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                _c("span", [_vm._v("資料總筆數：")]),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.loading
                      ? [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("查詢中")]
                          ),
                        ]
                      : _vm.total == 0
                      ? [_vm._m(1)]
                      : _vm._l(_vm.data, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.searchData.pageSize *
                                    (_vm.searchData.pageNum - 1) +
                                    index +
                                    1
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.noteType)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.note)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.publishSTime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.publishETime,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(item.isStop == "1" ? "下架" : "上架")
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mr-10",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _vm._v("   "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger mr-10",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0 && !_vm.loading,
                expression: "total > 0 && !loading",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "5%" } }, [
        _vm._v("編號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("分類"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "20%" } }, [
        _vm._v("內容"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "15%" } }, [
        _vm._v("開始日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "15%" } }, [
        _vm._v("結束日期"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("狀態"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { width: "15%", "min-width": "200px" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("查詢無任何資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }