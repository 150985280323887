var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MemberData" } },
    [
      _c("section", { staticClass: "content-header" }, [
        !_vm.id
          ? _c("h1", [_vm._v("申請刪除帳號管理")])
          : _c("h1", [_vm._v("申請刪除帳號 " + _vm._s(_vm.id) + "號")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _vm.id
            ? _c(
                "li",
                { staticClass: "active" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "XswMemberDelete" } } },
                    [_vm._v("申請刪除帳號管理")]
                  ),
                ],
                1
              )
            : _c("li", { staticClass: "active" }, [_vm._v("申請刪除帳號管理")]),
          _vm.id
            ? _c("li", { staticClass: "active" }, [
                _vm._v("申請刪除" + _vm._s(_vm.id) + "號"),
              ])
            : _vm._e(),
        ]),
      ]),
      !_vm.id
        ? _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary" }, [
                _c("div", { staticClass: "box-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "top-box clearfix",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          _vm.getDataList()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "input-group mb-10 mr-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.nickName,
                              expression: "searchData.nickName",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "申請人" },
                          domProps: { value: _vm.searchData.nickName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "nickName",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "input-group mb-10 mr-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.searchData.phoneNumber,
                              expression: "searchData.phoneNumber",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "綁定門號" },
                          domProps: { value: _vm.searchData.phoneNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.searchData,
                                "phoneNumber",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "clear form-group pull-left m-0" },
                        [
                          _c(
                            "label",
                            { staticClass: "control-label pull-left mt-7" },
                            [_vm._v("發送時間：")]
                          ),
                          _c(
                            "div",
                            { staticClass: "input-group datetime mb-10 mr-10" },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DateRangePicker", {
                                attrs: {
                                  propTimePicker: true,
                                  propEndDate: _vm.searchData.結束時間,
                                  propStartDate: _vm.searchData.開始時間,
                                },
                                on: {
                                  "update:propEndDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                  "update:prop-end-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchData,
                                      "結束時間",
                                      $event
                                    )
                                  },
                                  "update:propStartDate": function ($event) {
                                    return _vm.$set(
                                      _vm.searchData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                  "update:prop-start-date": function ($event) {
                                    return _vm.$set(
                                      _vm.searchData,
                                      "開始時間",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "form-group pull-left m-0" }, [
                        _c(
                          "label",
                          { staticClass: "control-label pull-left mt-7" },
                          [_vm._v("審核區間：")]
                        ),
                        _c(
                          "div",
                          { staticClass: "input-group datetime mb-10 mr-10" },
                          [
                            _c("div", {
                              staticClass: "input-group-addon fa fa-calendar",
                            }),
                            _c("DateRangePicker", {
                              attrs: {
                                propTimePicker: true,
                                propEndDate: _vm.searchData.結束時間,
                                propStartDate: _vm.searchData.開始時間,
                              },
                              on: {
                                "update:propEndDate": function ($event) {
                                  return _vm.$set(
                                    _vm.searchData,
                                    "結束時間",
                                    $event
                                  )
                                },
                                "update:prop-end-date": function ($event) {
                                  return _vm.$set(
                                    _vm.searchData,
                                    "結束時間",
                                    $event
                                  )
                                },
                                "update:propStartDate": function ($event) {
                                  return _vm.$set(
                                    _vm.searchData,
                                    "開始時間",
                                    $event
                                  )
                                },
                                "update:prop-start-date": function ($event) {
                                  return _vm.$set(
                                    _vm.searchData,
                                    "開始時間",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "pull-left mb-10 mr-10" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-10",
                            attrs: { type: "submit" },
                          },
                          [_vm._v(" 查詢 ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              disabled: _vm.datas.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportOrders()
                              },
                            },
                          },
                          [_vm._v(" 匯出 ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "table-responsive mt-0" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered text-center" },
                      [
                        _vm._m(1),
                        _c(
                          "tbody",
                          [
                            _vm.loading
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v("查詢中"),
                                  ]),
                                ])
                              : _vm.result.message
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v(_vm._s(_vm.result.message)),
                                  ]),
                                ])
                              : _vm.datas.length === 0
                              ? _c("tr", [
                                  _c("td", { attrs: { colspan: "11" } }, [
                                    _vm._v("查詢無任何資料"),
                                  ]),
                                ])
                              : _vm._l(_vm.datas, function (player, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(player.nickName))]),
                                    _c("td", [_vm._v(_vm._s(player.playerID))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          player.門號綁定
                                            ? player.門號綁定
                                            : "-"
                                        )
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(player.會員狀態))]),
                                    _c("td", [
                                      _vm._v(_vm._s(player.段位等級) + " "),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#aaa" } },
                                        [
                                          _vm._v(
                                            "(" + _vm._s(player.level) + ")"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(player.levelExp))]),
                                    _c("td", [_vm._v(_vm._s(player.skill))]),
                                    _c("td", [
                                      _vm._v(_vm._s(player.mahjongCoin)),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.uiDate(player.createTime * 1000)
                                        )
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.uiDate(
                                            player.lastPlayerLoginTime * 1000
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerHistory",
                                                query: {
                                                  nick: player.nickName,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-history fa-fw",
                                            }),
                                            _vm._v(" 玩家歷程 "),
                                          ]
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "btn btn-success mt-5",
                                            attrs: {
                                              to: {
                                                name: "NmjPlayerDeposit",
                                                query: {
                                                  nick: player.nickName,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-dollar fa-fw",
                                            }),
                                            _vm._v(" 儲值歷程 "),
                                          ]
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "btn btn-warning mt-5",
                                            attrs: {
                                              to: {
                                                name: "NmjRewardMessageHistory",
                                                query: {
                                                  nick: player.nickName,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-envelope fa-fw",
                                            }),
                                            _vm._v(" 信件歷程 "),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "max-content",
                                            margin: "0 auto",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          player.suspensionTime * 1000 >=
                                          Date.now()
                                            ? _c("p", [
                                                _vm._v(
                                                  " 停權至" +
                                                    _vm._s(player.停權時間)
                                                ),
                                              ])
                                            : _vm._e(),
                                          player.banSpeechTime * 1000 >=
                                          Date.now()
                                            ? _c("p", [
                                                _vm._v(
                                                  " 禁言至" +
                                                    _vm._s(player.禁言時間) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                          player.banSpeechTime * 1000 <
                                            Date.now() &&
                                          player.suspensionTime * 1000 <
                                            Date.now()
                                            ? _c("p", [_vm._v(" 正常無控管 ")])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("p", { staticClass: "mt-15" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-info",
                                            on: {
                                              click: function ($event) {
                                                return _vm.playerSetting(player)
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  player.banSpeechTime * 1000 >=
                                                    Date.now() ||
                                                  player.suspensionTime *
                                                    1000 >=
                                                    Date.now(),
                                                expression:
                                                  "player.banSpeechTime * 1000 >= (Date.now()) || player.suspensionTime * 1000 >= (Date.now())",
                                              },
                                            ],
                                            staticClass: "btn btn-danger ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.playerSetting(
                                                  player,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("解鎖")]
                                        ),
                                      ]),
                                    ]),
                                  ])
                                }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _c(
            "section",
            { staticClass: "content", staticStyle: { "min-height": "auto" } },
            [
              _c("div", { staticClass: "box box-primary" }, [
                _c("div", { staticClass: "box-header" }, [
                  _c(
                    "div",
                    { staticClass: "top-box with-border m-0" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-default pull-right",
                          attrs: { to: { name: "XswMemberDelete" } },
                        },
                        [
                          _c("i", { staticClass: "fa fa-reply" }),
                          _vm._v(" 返回"),
                        ]
                      ),
                      _c(
                        "h2",
                        {
                          staticClass: "pull-left mb-5 mt-5",
                          staticStyle: { "font-size": "1.5em" },
                        },
                        [_vm._v("完整資訊")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "form-horizontal" }, [
                  _c("div", { staticClass: "box-body" }, [
                    _c("div", { staticClass: "table-responsive mt-0" }, [
                      _c(
                        "table",
                        { staticClass: "table table-bordered min-600" },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("申請ID")]),
                              _c("td", [_vm._v(_vm._s(_vm.id))]),
                              _c("th", [_vm._v("遊戲暱稱")]),
                              _c("td", [_vm._v("給我大紅大紫")]),
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("綁定門號")]),
                              _c("td", [_vm._v("886926026250")]),
                              _c("th", [_vm._v("申請時間")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.uiDate("2024-03-18"))),
                              ]),
                            ]),
                            _vm._m(2),
                            _vm._m(3),
                            _vm._m(4),
                            _vm._m(5),
                            _vm._m(6),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg fa fa-floppy-o",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          _vm.data.isDraft = false
                        },
                      },
                    },
                    [_vm._v(" 儲存 ")]
                  ),
                ]),
              ]),
            ]
          ),
      _c("PlayerSettingContent", {
        ref: "PlayerSettingContent",
        on: {
          updateList: function ($event) {
            _vm.getDataList()
            _vm.getBanInfo()
          },
        },
      }),
      _c("PlayerServiceContent", {
        ref: "PlayerServiceContent",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.getServiceInfo()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group pull-left mb-10 mr-10" }, [
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", placeholder: "狀態" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "120px", width: "5%" } }, [
          _vm._v("申請時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("門號"),
        ]),
        _c("th", { staticStyle: { "min-width": "120px", width: "5%" } }, [
          _vm._v("申請人"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("登入方式"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("審核狀態"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("備註"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("審核通過時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("附檔"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("功能"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("登入方式")]),
      _c("td", [_vm._v("立即玩_綁門號")]),
      _c("th"),
      _c("td"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("狀態")]),
      _c("td", [
        _c("select", { staticClass: "form-control" }, [
          _c("option", [_vm._v("狀態")]),
        ]),
      ]),
      _c("th", [_vm._v("真實姓名")]),
      _c("td", [
        _c("input", { staticClass: "form-control", attrs: { type: "text" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("身分證")]),
      _c("td", [
        _c("input", { staticClass: "form-control", attrs: { type: "text" } }),
      ]),
      _c("th", [_vm._v("聯絡電話")]),
      _c("td", [
        _c("input", { staticClass: "form-control", attrs: { type: "text" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("備註")]),
      _c("td", { attrs: { colspan: "3" } }, [
        _c("textarea", { staticClass: "form-control", attrs: { rows: "5" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("附件")]),
      _c("td", { attrs: { colspan: "3" } }, [
        _c("img"),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-wrap": "wrap",
              gap: "0.25em",
              "align-items": "center",
              "justify-content": "center",
            },
          },
          [
            _c(
              "button",
              { staticClass: "btn btn-warning", attrs: { type: "button" } },
              [_vm._v("身分證正面")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-default", attrs: { type: "button" } },
              [_vm._v("身分證反面")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-default", attrs: { type: "button" } },
              [_vm._v("手機上期電信帳單")]
            ),
            _c(
              "button",
              { staticClass: "btn btn-default", attrs: { type: "button" } },
              [_vm._v("轉向")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }