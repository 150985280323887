var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("付款限制管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("付款限制管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-header" }, [
            _c("div", { staticClass: "input-group" }, [
              _c("label", [
                _vm._v("交易方式： "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.交易方式,
                        expression: "searchData.交易方式",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { float: "none" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "交易方式",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: -1 } }, [_vm._v("全部")]),
                    _vm._l(_vm.金流設定List, function (item) {
                      return _c(
                        "option",
                        { key: item.金流代碼, domProps: { value: item.編號 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.金流名稱) +
                              "(" +
                              _vm._s(item.金流代碼) +
                              ") "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("label", [
                _vm._v("儲值類型： "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.儲值類型,
                        expression: "searchData.儲值類型",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { float: "none" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "儲值類型",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: -1 } }, [_vm._v("全部")]),
                    _vm._l(_vm.儲值類型SearchOpts, function (val, k) {
                      return _c(
                        "option",
                        { key: val, domProps: { value: val } },
                        [_vm._v(" " + _vm._s(val) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "input-group-for-multiselect-wrap" },
              [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("廠商："),
                ]),
                _c("multiselect", {
                  staticClass: "multiselect-width",
                  attrs: {
                    placeholder: "請選取廠商",
                    options: _vm.廠商Opts,
                    label: "名稱",
                    "track-by": "廠商代碼",
                    "show-labels": false,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.searchData.廠商,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchData, "廠商", $$v)
                    },
                    expression: "searchData.廠商",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "input-group" }, [
              _c("label", [
                _vm._v("直儲模式： "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.直儲模式,
                        expression: "searchData.直儲模式",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { float: "none" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "直儲模式",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: -1 } }, [_vm._v("全部")]),
                    _c("option", { attrs: { value: "" } }, [_vm._v("不設定")]),
                    _vm._l(_vm.直儲模式, function (item) {
                      return _c(
                        "option",
                        { key: item.名稱, domProps: { value: item.名稱 } },
                        [_vm._v(" " + _vm._s(item.名稱) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _vm.editPermission
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      on: {
                        click: function ($event) {
                          return _vm.openPopFormAdd()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "bg-gray" }, [
                  _c("th", [_vm._v("交易方式")]),
                  _c("th", [_vm._v("儲值類型")]),
                  _c("th", [_vm._v("廠商")]),
                  _c("th", [_vm._v("規則描述")]),
                  _c("th", [_vm._v("直儲模式")]),
                  _vm.table欄位.規則型態
                    ? _c("th", [_vm._v("規則型態")])
                    : _vm._e(),
                  _vm.table欄位.識別值
                    ? _c("th", [_vm._v("識別值")])
                    : _vm._e(),
                  _vm.table欄位.條件分鐘
                    ? _c("th", [_vm._v("條件分鐘")])
                    : _vm._e(),
                  _vm.table欄位.條件金額
                    ? _c("th", [_vm._v("條件金額")])
                    : _vm._e(),
                  _vm.table欄位.累算筆數
                    ? _c("th", [_vm._v("累算筆數")])
                    : _vm._e(),
                  _vm.table欄位.累算金額
                    ? _c("th", [_vm._v("累算金額")])
                    : _vm._e(),
                  _vm.table欄位.消失金額
                    ? _c("th", [_vm._v("消失金額")])
                    : _vm._e(),
                  _c("th", [
                    _vm._v(" 功能 "),
                    _vm._m(0),
                    _c("div", { staticStyle: { position: "relative" } }, [
                      _c(
                        "ul",
                        { staticClass: "dropdown-menu func-menu" },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "item",
                              on: {
                                click: function ($event) {
                                  _vm.toggle所有欄位($event)
                                },
                              },
                            },
                            [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked:
                                    _vm.table顯示欄位數 == _vm.table欄位數,
                                },
                              }),
                              _c("span", [_vm._v("顯示/隱藏 所有欄位")]),
                            ]
                          ),
                          _c("li", { staticClass: "func-divider" }),
                          _vm._l(_vm.table欄位, function (isShow, 欄位名稱) {
                            return _c(
                              "li",
                              {
                                key: 欄位名稱,
                                staticClass: "item",
                                on: {
                                  click: function ($event) {
                                    _vm.toggle單一欄位($event, 欄位名稱)
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.table欄位[欄位名稱],
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(欄位名稱))]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(_vm.filterList, function (item) {
                    return _c("tr", { key: item.vueKey }, [
                      _c("td", [_vm._v(_vm._s(item.交易方式))]),
                      _c("td", [_vm._v(_vm._s(item.儲值類型))]),
                      _c("td", [_vm._v(_vm._s(item.廠商))]),
                      _c(
                        "td",
                        { staticClass: "td-description" },
                        _vm._l(item.規則描述, function (description, index) {
                          return _c(
                            "span",
                            { key: index, class: description[1] },
                            [_vm._v(" " + _vm._s(description[0]) + " ")]
                          )
                        }),
                        0
                      ),
                      _c("td", [_vm._v(_vm._s(item.mode))]),
                      _vm.table欄位.規則型態
                        ? _c("td", [
                            _vm._v(_vm._s(_vm.trans規則型態(item.規則型態))),
                          ])
                        : _vm._e(),
                      _vm.table欄位.識別值
                        ? _c("td", [_vm._v(_vm._s(item.識別值))])
                        : _vm._e(),
                      _vm.table欄位.條件分鐘
                        ? _c("td", [_vm._v(_vm._s(item.條件分鐘))])
                        : _vm._e(),
                      _vm.table欄位.條件金額
                        ? _c("td", [_vm._v(_vm._s(item.條件金額))])
                        : _vm._e(),
                      _vm.table欄位.累算筆數
                        ? _c("td", [_vm._v(_vm._s(item.累算筆數))])
                        : _vm._e(),
                      _vm.table欄位.累算金額
                        ? _c("td", [_vm._v(_vm._s(item.累算金額))])
                        : _vm._e(),
                      _vm.table欄位.消失金額
                        ? _c("td", [_vm._v(_vm._s(item.消失金額))])
                        : _vm._e(),
                      _c("td", [
                        _vm.editPermission
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.openPopFormEdit(item)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        _vm.delPermission
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function ($event) {
                                    return _vm.del(item)
                                  },
                                },
                              },
                              [_vm._v("刪除")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.list.length == 0,
                          expression: "list.length == 0",
                        },
                      ],
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { colspan: _vm.table顯示欄位數 + 6 },
                        },
                        [_vm._v("無資料")]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
      ]),
      _c("PopForm", {
        ref: "addForm",
        staticClass: "popform",
        scopedSlots: _vm._u([
          {
            key: "slot-title",
            fn: function () {
              return [_vm._v(" 新增 / 修改 付款限制 ")]
            },
            proxy: true,
          },
          {
            key: "slot-body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "edit-description" },
                  _vm._l(_vm.edit.規則描述, function (description, index) {
                    return _c("span", { key: index, class: description[1] }, [
                      _vm._v(" " + _vm._s(description[0]) + " "),
                    ])
                  }),
                  0
                ),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("規則型態： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.data.規則型態,
                          expression: "edit.data.規則型態",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: _vm.edit.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit.data,
                            "規則型態",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.規則型態Enum, function (item, k) {
                      return _c("option", { key: k, domProps: { value: k } }, [
                        _vm._v(_vm._s(_vm.trans規則型態(item.val))),
                      ])
                    }),
                    0
                  ),
                ]),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("識別值： "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.edit.data.識別值,
                        expression: "edit.data.識別值",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "請輸入 識別值",
                      disabled: _vm.edit.識別值Disabled || _vm.edit.isEdit,
                    },
                    domProps: { value: _vm.edit.data.識別值 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.edit.data,
                          "識別值",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("廠商："),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input-group-for-multiselect-wrap",
                    staticStyle: { "padding-right": "0" },
                  },
                  [
                    _c("multiselect", {
                      attrs: {
                        placeholder: "請選取廠商",
                        options: _vm.廠商List,
                        label: "名稱",
                        "track-by": "廠商代碼",
                        "show-labels": false,
                        "allow-empty": false,
                        disabled: _vm.edit.isEdit,
                      },
                      model: {
                        value: _vm.edit.data.廠商,
                        callback: function ($$v) {
                          _vm.$set(_vm.edit.data, "廠商", $$v)
                        },
                        expression: "edit.data.廠商",
                      },
                    }),
                  ],
                  1
                ),
                _c("label", { staticClass: "form-group" }, [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v("交易方式： "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.data.交易方式,
                          expression: "edit.data.交易方式",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: _vm.edit.isEdit },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.edit.data,
                            "交易方式",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.金流設定List, function (item) {
                      return _c(
                        "option",
                        { key: item.金流代碼, domProps: { value: item.編號 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.金流名稱) +
                              "(" +
                              _vm._s(item.金流代碼) +
                              ") "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "label",
                  { staticClass: "form-group", attrs: { label: "" } },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("儲值類型： "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.data.儲值類型,
                            expression: "edit.data.儲值類型",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.edit.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit.data,
                              "儲值類型",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.儲值類型EditOpts, function (val, k) {
                        return _c(
                          "option",
                          { key: val, domProps: { value: val } },
                          [_vm._v(" " + _vm._s(val) + " ")]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "label",
                  { staticClass: "form-group", attrs: { label: "" } },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("直儲模式： "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.data.mode,
                            expression: "edit.data.mode",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: _vm.edit.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit.data,
                              "mode",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("不設定"),
                        ]),
                        _vm._l(_vm.直儲模式, function (item) {
                          return _c(
                            "option",
                            { key: item.名稱, domProps: { value: item.名稱 } },
                            [_vm._v(_vm._s(item.名稱))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.顯示欄位.條件分鐘,
                        expression: "edit.顯示欄位.條件分鐘",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("條件分鐘："),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.editWarning條件分鐘)),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.data.條件分鐘,
                          expression: "edit.data.條件分鐘",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", placeholder: "請輸入 條件分鐘" },
                      domProps: { value: _vm.edit.data.條件分鐘 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit.data,
                            "條件分鐘",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.顯示欄位.條件金額,
                        expression: "edit.顯示欄位.條件金額",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("條件金額："),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.editWarning條件金額)),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.data.條件金額,
                          expression: "edit.data.條件金額",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", placeholder: "請輸入 條件金額" },
                      domProps: { value: _vm.edit.data.條件金額 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit.data,
                            "條件金額",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.顯示欄位.累算筆數,
                        expression: "edit.顯示欄位.累算筆數",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("累算筆數："),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.editWarning累算筆數)),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.data.累算筆數,
                          expression: "edit.data.累算筆數",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", placeholder: "請輸入 累算筆數" },
                      domProps: { value: _vm.edit.data.累算筆數 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit.data,
                            "累算筆數",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.顯示欄位.累算金額,
                        expression: "edit.顯示欄位.累算金額",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("累算金額："),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.editWarning累算金額)),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.data.累算金額,
                          expression: "edit.data.累算金額",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", placeholder: "請輸入 累算金額" },
                      domProps: { value: _vm.edit.data.累算金額 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit.data,
                            "累算金額",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.顯示欄位.消失金額,
                        expression: "edit.顯示欄位.消失金額",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                    _vm._v("消失金額："),
                    _c("span", { staticClass: "text-red" }, [
                      _vm._v(_vm._s(_vm.editWarning消失金額)),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.edit.data.消失金額,
                          expression: "edit.data.消失金額",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", placeholder: "請輸入 消失金額" },
                      domProps: { value: _vm.edit.data.消失金額 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit.data,
                            "消失金額",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "slot-footer",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.addForm.hide()
                      },
                    },
                  },
                  [_vm._v("關閉")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.sendEditData()
                      },
                    },
                  },
                  [_vm._v("送出")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-default btn-sm",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "fa fa-bars" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }