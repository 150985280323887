var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "跑馬燈內容"),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/NMJ/Marquee" } }, [
              _vm._v("跑馬燈設定"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "內容"),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.id != undefined ? "編輯" : "新增") + "內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog fa fa-reply",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "rewardMessageForm", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "form-group",
                    attrs: { disabled: _vm.id != undefined },
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: "inputPlatform" },
                      },
                      [
                        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                        _vm._v(
                          " 顯示平台(" +
                            _vm._s(
                              _vm.id != undefined ? "不可更改" : "可複選"
                            ) +
                            ")： "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group col-sm-9" },
                      _vm._l(_vm.def.gamePlatform, function (platform, i) {
                        return _c(
                          "div",
                          { staticClass: "checkbox checkbox-inline" },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.platforms[i],
                                    expression: "platforms[i]",
                                  },
                                ],
                                staticStyle: {
                                  position: "relative",
                                  width: "1.5em",
                                  height: "1.5em",
                                  "vertical-align": "sub",
                                  "margin-top": "-.2em",
                                  "accent-color": "#3498db",
                                  cursor: "pointer",
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.platforms[i])
                                    ? _vm._i(_vm.platforms[i], null) > -1
                                    : _vm.platforms[i],
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.platforms[i],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.platforms,
                                              i,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.platforms,
                                              i,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.platforms, i, $$c)
                                      }
                                    },
                                    function ($event) {
                                      return _vm.updatePlatforms()
                                    },
                                  ],
                                },
                              }),
                              _vm._v("  " + _vm._s(platform) + " "),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.data.類型,
                            expression: "data.類型",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.data,
                              "類型",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.def.marqueeType, function (option, i) {
                        return _c(
                          "option",
                          { key: i, domProps: { value: i } },
                          [_vm._v(_vm._s(option))]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "editor" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.內容,
                            expression: "data.內容",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          cols: "100",
                          rows: "5",
                          maxlength: _vm.contentMax,
                          placeholder: `請輸入內容，上限${_vm.contentMax}字。`,
                          required: "",
                        },
                        domProps: { value: _vm.data.內容 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data, "內容", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "word-count" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.data.內容.length) +
                          " / " +
                          _vm._s(_vm.contentMax) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("fieldset", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "radio col-sm-10" }, [
                    _c(
                      "p",
                      [
                        _c("iCheckRedioButton", {
                          staticStyle: { "margin-right": "1em" },
                          attrs: {
                            name: "timeRadio",
                            message: "立即發送",
                            checkValue: "0",
                          },
                          model: {
                            value: _vm.data.發送狀態,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "發送狀態", _vm._n($$v))
                            },
                            expression: "data.發送狀態",
                          },
                        }),
                        _c("iCheckRedioButton", {
                          attrs: {
                            name: "timeRadio",
                            message: "排程時間",
                            checkValue: "1",
                          },
                          model: {
                            value: _vm.data.發送狀態,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "發送狀態", _vm._n($$v))
                            },
                            expression: "data.發送狀態",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.data.發送狀態 != 0,
                            expression: "data.發送狀態 != 0",
                          },
                        ],
                        staticClass: "radio-date",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "input-group date" },
                          [
                            _c("div", {
                              staticClass: "input-group-addon fa fa-calendar",
                            }),
                            _c("DatePicker", {
                              staticClass: "pull-right",
                              attrs: { propValue: _vm.startDate },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startDate = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startDate = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "input-group time" }, [
                          _c("div", {
                            staticClass: "input-group-addon fa fa-clock-o",
                          }),
                          _c("input", {
                            staticClass: "form-control timepicker",
                            attrs: { type: "text", id: "startTimeOnly" },
                            on: {
                              input: function ($event) {
                                return _vm.updateTime()
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.發送狀態 != 0,
                        expression: "data.發送狀態 != 0",
                      },
                    ],
                  },
                  [
                    _c("fieldset", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "radio-date" }, [
                          _c(
                            "div",
                            { staticClass: "input-group date" },
                            [
                              _c("div", {
                                staticClass: "input-group-addon fa fa-calendar",
                              }),
                              _c("DatePicker", {
                                staticClass: "pull-right",
                                attrs: { propValue: _vm.endDate },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.endDate = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.endDate = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "input-group time" }, [
                            _c("div", {
                              staticClass: "input-group-addon fa fa-clock-o",
                            }),
                            _c("input", {
                              staticClass: "form-control timepicker",
                              attrs: { type: "text", id: "endTimeOnly" },
                              on: {
                                input: function ($event) {
                                  return _vm.updateTime()
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        {
                          staticClass: "col-sm-4",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            gap: ".5em",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.循環時間,
                                expression: "data.循環時間",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: { value: _vm.data.循環時間 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.data,
                                  "循環時間",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" 分 "),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _vm.id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-lg fa fa-trash",
                          staticStyle: { "margin-right": ".5em" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteMessage()
                            },
                          },
                        },
                        [_vm._v(" 刪除 ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg fa fa-save",
                      staticStyle: { "margin-right": ".5em" },
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          _vm.data.發送狀態 = 2
                        },
                      },
                    },
                    [_vm._v(" 儲存草稿 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg fa fa-send-o",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 類型： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 跑馬燈內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 開始時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 結束時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 循環時間： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }