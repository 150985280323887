var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "BigData" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("網紅大數據")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("網紅大數據")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c("div", { staticClass: "clearfix" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-block btn-warning btn-add ml-10 ml-0-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addNew()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-primary btn-add",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportOrders()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-upload" }), _vm._v(" 匯出 ")]
                ),
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.PlatformType,
                          expression: "searchData.PlatformType",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectPlatforms" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.searchData,
                              "PlatformType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.selectChange($event)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("全部社群"),
                      ]),
                      _vm._l(_vm.selectPlatformsList, function (item, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: item.classId } },
                          [_vm._v(" " + _vm._s(item.className) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "select-group" },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.selectTypeList,
                        multiple: true,
                        placeholder: "請選擇類型",
                        showLabels: false,
                        max: 2,
                        "hide-selected": true,
                        searchable: true,
                      },
                      model: {
                        value: _vm.searchData.categorys,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "categorys", $$v)
                        },
                        expression: "searchData.categorys",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "input-group atLeft" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.KolName,
                        expression: "searchData.KolName",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "搜尋網紅名稱",
                      type: "text",
                    },
                    domProps: { value: _vm.searchData.KolName },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.searchData, "KolName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-tools mb-10-md ml-0 btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.search },
                  },
                  [_c("i", { staticClass: "fa fa-search" }), _vm._v(" 搜尋 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-tools mb-10-md btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.isAdvancedSearch = !_vm.isAdvancedSearch
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-search-plus",
                      class: { "fa-search-minus": _vm.isAdvancedSearch },
                    }),
                    _vm._v(" 進階 "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-tools",
                    attrs: { "data-btn": "重設", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.searchReset()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 重設 ")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAdvancedSearch,
                        expression: "isAdvancedSearch",
                      },
                    ],
                    staticClass: "advancedSearch clearfix clear pt-10",
                    class: { "is-advanced": _vm.isAdvancedSearch == true },
                  },
                  [
                    _c("div", { staticClass: "select-group sex-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.gender,
                              expression: "searchData.gender",
                            },
                          ],
                          ref: "select",
                          staticClass: "form-control",
                          attrs: { id: "selectSex" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData,
                                  "gender",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.selectChange($event)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.selectSexList, function (item, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "select-group" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchData.sort.range.follow,
                              expression: "searchData.sort.range.follow",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectFans" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.searchData.sort.range,
                                  "follow",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.selectChange($event)
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { value: "0", disabled: "" } },
                            [_vm._v("請選擇粉絲數")]
                          ),
                          _vm._l(_vm.selectFansList, function (item, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: item.value } },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "select-group checkbox-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isShowAll,
                            expression: "isShowAll",
                          },
                        ],
                        attrs: { id: "checkKpiData", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.isShowAll)
                            ? _vm._i(_vm.isShowAll, null) > -1
                            : _vm.isShowAll,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.isShowAll,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.isShowAll = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isShowAll = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isShowAll = $$c
                              }
                            },
                            function ($event) {
                              return _vm.changeKpiData()
                            },
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "checkKpiData" } }, [
                        _vm._v("搜尋全部資料(不含KPI)"),
                      ]),
                    ]),
                    _c(
                      "span",
                      { staticClass: "fr mt-10 mt-0-sm w100-sm mb-10-sm" },
                      [
                        _vm._v(" 找不到? "),
                        _c(
                          "b",
                          {
                            staticClass: "link text-line",
                            on: {
                              click: function ($event) {
                                return _vm.addNew()
                              },
                            },
                          },
                          [_vm._v("手動新增")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group sort-group mt-10" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.sort_by,
                        expression: "searchData.sort_by",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectSort" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "sort_by",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.search,
                      ],
                    },
                  },
                  _vm._l(_vm.kpiSortOption, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.value } },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.pageSize,
                      expression: "searchData.pageSize",
                    },
                  ],
                  staticClass: "form-control select-group mt-10 page-size",
                  attrs: { placeholder: "請選擇單頁顯示數目" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "pageSize",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.pageDisplay()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "10" } }, [
                    _vm._v("單頁顯示10項"),
                  ]),
                  _c("option", { attrs: { value: "20" } }, [
                    _vm._v("單頁顯示20項"),
                  ]),
                  _c("option", { attrs: { value: "40" } }, [
                    _vm._v("單頁顯示40項"),
                  ]),
                  _c("option", { attrs: { value: "100" } }, [
                    _vm._v("單頁顯示100項"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "input-group mt-10 page-size",
                  staticStyle: { width: "125px" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customPageSize,
                        expression: "customPageSize",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "自訂顯示筆數",
                      type: "number",
                    },
                    domProps: { value: _vm.customPageSize },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.customPageSize = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "text mt-10" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(" " + _vm._s(_vm.kolTotal) + " "),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered AdListTable",
                  attrs: { id: "groupSetTable" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm._l(_vm.kolList, function (item) {
                        return _c("tr", { key: item.target_id }, [
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "main-img jqImgFill imgLink",
                                on: {
                                  click: function ($event) {
                                    return _vm.showImg(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "imgLink",
                                  attrs: {
                                    src: item.main_img,
                                    alt: item.target_id,
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("td", [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(" " + _vm._s(item.target_id) + " "),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "link",
                                on: {
                                  click: function ($event) {
                                    return _vm.showContent(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.target_name))]
                            ),
                          ]),
                          _c("td", { attrs: { align: "center" } }, [
                            _vm._v(_vm._s(_vm.GetGender(item.gender))),
                          ]),
                          _c("td", { attrs: { align: "center" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.array2string(item.categorys)) +
                                " "
                            ),
                          ]),
                          _c("td", { attrs: { "data-label": "mainData" } }, [
                            _c("div", { staticClass: "data-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "data-box",
                                  class: { isShow: item.isShow },
                                },
                                [
                                  item.channel_data.length == 0
                                    ? _c("span", { staticClass: "tc w100" }, [
                                        _vm._v("暫無資料"),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    item.channel_data,
                                    function (channel, index) {
                                      return _c(
                                        "ul",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: channel.is_visibility,
                                              expression:
                                                "channel.is_visibility",
                                            },
                                          ],
                                          key: index,
                                          staticClass: "data-list",
                                          class: channel.platform,
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: channel.channel_url,
                                                  target: "_blank",
                                                  title: channel.channel_name,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "media-icon fa",
                                                  class: channel.platform,
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "data-value" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat2"
                                                          )(
                                                            _vm.pareToFloor(
                                                              channel.follow
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "data-name" },
                                              [_vm._v("互動率")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "data-value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseFloatNoRounding(
                                                      channel.avg_post_probability
                                                    )
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "data-name" },
                                              [_vm._v("漲粉率")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "data-value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseFloatNoRounding(
                                                      channel.fans_up_probability
                                                    )
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "data-name" },
                                              [_vm._v("觀看率")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "data-value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.parseFloatNoRounding(
                                                      channel.avg_view_content
                                                    )
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              !item.channel_data.length == 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "data-toogle",
                                      on: {
                                        click: function ($event) {
                                          return _vm.channelToggle(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-angle-down",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("td", { attrs: { align: "center" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.is_visibility == 0 ? "下架" : "上架"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { attrs: { align: "center" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$utils.formatTime(
                                  item.crawler_time,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              ) + " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-center funTdVer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn bg-maroon",
                                on: {
                                  click: function ($event) {
                                    return _vm.LinkKPI(item)
                                  },
                                },
                              },
                              [_vm._v(" KPI ")]
                            ),
                            _c("br"),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.editorItem(item)
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                            _c("br"),
                            _vm._v("   "),
                          ]),
                        ])
                      }),
                      !_vm.kolTotal
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "8" },
                              },
                              [_vm._v("沒有資料")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "box-footer text-center no-border" },
                [
                  _c("Pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.kolTotal > 0,
                        expression: "kolTotal > 0",
                      },
                    ],
                    ref: "pagination",
                    attrs: {
                      pageLimitCount: _vm.searchData.pageSize,
                      total: _vm.kolTotal,
                    },
                    on: { pageMethod: _vm.onPageChange },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("BigDataAlert", {
        ref: "BigDataAlert",
        attrs: { "props-in": "kolList" },
      }),
      _c("CollectAddAlert", { ref: "CollectAddAlert" }),
      _c("BigDataImg", { ref: "BigDataImg" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticStyle: { "max-width": "100px", "min-width": "100px" },
          attrs: { width: "5%" },
        },
        [_vm._v("主圖")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "120px" }, attrs: { width: "10%" } },
        [_vm._v("姓名")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "50px" }, attrs: { width: "4%" } },
        [_vm._v("性別")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "60px" }, attrs: { width: "8%" } },
        [_vm._v("類型")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "450px" }, attrs: { width: "32%" } },
        [_vm._v("(30天)社群資訊")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "50px" }, attrs: { width: "4%" } },
        [_vm._v("狀態")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "90px" }, attrs: { width: "4%" } },
        [_vm._v("更新時間")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "80px" },
          attrs: { width: "8%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }