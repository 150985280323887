var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("廣告編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("廣告列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("廣告編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.classTypeId,
                            expression: "edit.classTypeId",
                          },
                        ],
                        staticClass: "form-control select-part",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.edit,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.onSelectChange($event)
                            },
                          ],
                        },
                      },
                      [
                        this.projectId != "omp_heti"
                          ? _c(
                              "option",
                              { attrs: { disabled: "disabled", value: "" } },
                              [_vm._v("請選擇分類")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.bannerTypes.datas, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              attrs: { class_id: item.classId },
                              domProps: { value: item.classTypeId },
                            },
                            [_vm._v(" " + _vm._s(item.className) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "inputTitle" },
                    },
                    [
                      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                      _vm._v(
                        " " + _vm._s(_vm.coverMode ? "標題" : "名稱") + "： "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.bannerTitle,
                          expression: "edit.bannerTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.bannerTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "bannerTitle", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "pic" }, [
                        _c("img", {
                          attrs: { draggable: "false", src: _vm.edit.filesUrl },
                        }),
                      ]),
                      _c("input", {
                        attrs: { accept: "image/*", id: "AdPic", type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event)
                          },
                        },
                      }),
                      _vm.projectId == "omp_kf3"
                        ? [_vm._m(2)]
                        : _vm.projectId !== "omp_heti"
                        ? [_vm._m(3), _vm._m(4), _vm._m(5)]
                        : _vm.projectId == "omp_heti" && !_vm.coverMode
                        ? [_vm._m(6)]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm.projectId != "omp_foundation"
                  ? _c("div", { staticClass: "form-group bannerlink-part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 control-label",
                          attrs: { for: "inputLink" },
                        },
                        [
                          _vm.linkIsRequired
                            ? _c("span", { staticClass: "text-red" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          _vm._v(" 網址： "),
                        ]
                      ),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.bannerLink,
                              expression: "edit.bannerLink",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "inputLink",
                            required: "required",
                            type: "text",
                          },
                          domProps: { value: _vm.edit.bannerLink },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "bannerLink",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("label", { staticClass: "col-sm-2 control-label" }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.projectId !== "omp_heti",
                              expression: "projectId !== 'omp_heti'",
                            },
                          ],
                          staticClass: "col-sm-10",
                        },
                        [
                          _c("div", { staticClass: "checkbox" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.edit.isLinkOpen,
                                    expression: "edit.isLinkOpen",
                                  },
                                ],
                                staticStyle: {
                                  margin: "0",
                                  height: "18px",
                                  width: "18px",
                                  position: "absolute",
                                  left: "0",
                                  top: "12px",
                                  "margin-top": "0",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  type: "checkbox",
                                  id: "isLinkOpen",
                                  name: "isLinkOpen",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.edit.isLinkOpen)
                                    ? _vm._i(_vm.edit.isLinkOpen, null) > -1
                                    : _vm.edit.isLinkOpen,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.edit.isLinkOpen,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.edit,
                                            "isLinkOpen",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.edit,
                                            "isLinkOpen",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.edit, "isLinkOpen", $$c)
                                    }
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" 另開視窗 "),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.projectId !== "omp_heti",
                        expression: "projectId !== 'omp_heti'",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _vm._m(7),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.orderNum,
                            expression: "edit.orderNum",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "inputOrderNum", type: "text" },
                        domProps: { value: _vm.edit.orderNum },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.edit, "orderNum", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _vm.projectId != "omp_fantacity" && !_vm.coverMode
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "上架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "下架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.projectId !== "omp_kf3" &&
                _vm.projectId !== "omp_heti" &&
                _vm.projectId !== "omp_dem"
                  ? _c(
                      "div",
                      { staticClass: "form-group bannercontent-part" },
                      [
                        _vm._m(9),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit.bannerContent,
                                expression: "edit.bannerContent",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "bannercontent",
                              required: "required",
                              placeholder: "內容建議100字內",
                            },
                            domProps: { value: _vm.edit.bannerContent },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  "bannerContent",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.projectId == "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(10),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "radio-date" }, [
                          _c("div", { staticClass: "input-group date" }, [
                            _vm._m(11),
                            _c(
                              "div",
                              { staticClass: "pull-right" },
                              [_c("DatePicker", { ref: "formalStartDate" })],
                              1
                            ),
                          ]),
                          _vm._m(12),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.projectId == "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(13),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "radio-date" }, [
                          _c("div", { staticClass: "input-group date" }, [
                            _vm._m(14),
                            _c(
                              "div",
                              { staticClass: "pull-right" },
                              [_c("DatePicker", { ref: "formalEndDate" })],
                              1
                            ),
                          ]),
                          _vm._m(15),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.projectId == "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(16),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "radio-date" }, [
                          _c("div", { staticClass: "input-group date" }, [
                            _vm._m(17),
                            _c(
                              "div",
                              { staticClass: "pull-right" },
                              [_c("DatePicker", { ref: "testStartDate" })],
                              1
                            ),
                          ]),
                          _vm._m(18),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.projectId == "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(19),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("div", { staticClass: "radio-date" }, [
                          _c("div", { staticClass: "input-group date" }, [
                            _vm._m(20),
                            _c(
                              "div",
                              { staticClass: "pull-right" },
                              [_c("DatePicker", { ref: "testEndDate" })],
                              1
                            ),
                          ]),
                          _vm._m(21),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 分類： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "AdPic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("圖片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("首頁輪播圖片建議尺寸 900 * 450，請勿小於該尺寸。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("首頁輪播圖片建議尺寸 498 * 248，請勿小於該尺寸。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note2" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note3" } }, [
        _vm._v("推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help-block" }, [
      _c("span", { staticClass: "text-red", attrs: { id: "note1" } }, [
        _vm._v("建議尺寸 2000*1333"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputLink" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 排序： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "bannercontent" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 正式開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "formalStartTime", type: "text" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 正式結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "formalEndTime", type: "text" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 測試開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "testStartTime", type: "text" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 測試結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { id: "testEndTime", type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }