var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("活動列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("活動編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.activityCode,
                          expression: "edit.activityCode",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "activityCode",
                        required: "required",
                        type: "text",
                        maxlength: "3",
                      },
                      domProps: { value: _vm.edit.activityCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "activityCode",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 control-label",
                      attrs: { for: "vendorActivityCode" },
                    },
                    [_vm._v("原廠活動代號：")]
                  ),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.vendorActivityCode,
                          expression: "edit.vendorActivityCode",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "vendorActivityCode",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.vendorActivityCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "vendorActivityCode",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.activityTitle,
                          expression: "edit.activityTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "activityTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.activityTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.edit,
                            "activityTitle",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.ruleType,
                            expression: "edit.ruleType",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "ruleType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [_vm._v("無")]),
                        _c("option", { attrs: { value: "1帳多次" } }, [
                          _vm._v("一個帳號領多個序號 (全活動)"),
                        ]),
                        _c("option", { attrs: { value: "綁定帳號" } }, [
                          _vm._v("綁定帳號"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.edit.ruleType.includes("1帳"),
                        expression: "edit.ruleType.includes('1帳')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.edit.ruleCountLimit,
                            expression: "edit.ruleCountLimit",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: "1" },
                        domProps: { value: _vm.edit.ruleCountLimit },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.edit,
                              "ruleCountLimit",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.startTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.startTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.startTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(6),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("div", { staticClass: "radio-date" }, [
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(8),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.endTime },
                              on: {
                                "update:propValue": function ($event) {
                                  _vm.endTime = $event
                                },
                                "update:prop-value": function ($event) {
                                  _vm.endTime = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._m(9),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "啟用",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "停用",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", $$v)
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v("確認送出")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "activityCode" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動代號： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "activityTitle" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 活動名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 限制條件： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 限制個數： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 活動開始時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 活動結束時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }