var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AlbumList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("美術相簿")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("美術相簿")]),
        _c("li", { staticClass: "active" }, [_vm._v("美術相簿")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.PermissionEdit,
                    expression: "this.PermissionEdit",
                  },
                ],
                staticClass: "btn btn-block btn-warning btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addNew()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增 ")]
            ),
            _c("div", { staticClass: "text" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.album.total)),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.album.total > 0
                      ? _vm._l(_vm.album.photoAlbumList, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.名稱)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  staticStyle: { "margin-right": "8px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item, "image")
                                    },
                                  },
                                },
                                [_vm._v("相簿列表")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editorItem(item, "video")
                                    },
                                  },
                                },
                                [_vm._v("影片列表")]
                              ),
                            ]),
                          ])
                        })
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("相簿名稱"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { width: "10%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "5" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }