import { render, staticRenderFns } from "./KeyWordManager.vue?vue&type=template&id=3d36083e&scoped=true"
import script from "./KeyWordManager.vue?vue&type=script&lang=js"
export * from "./KeyWordManager.vue?vue&type=script&lang=js"
import style0 from "./KeyWordManager.vue?vue&type=style&index=0&id=3d36083e&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d36083e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\WorkSpace\\ompweb\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d36083e')) {
      api.createRecord('3d36083e', component.options)
    } else {
      api.reload('3d36083e', component.options)
    }
    module.hot.accept("./KeyWordManager.vue?vue&type=template&id=3d36083e&scoped=true", function () {
      api.rerender('3d36083e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projects/ChannelMonitor/KeyWordManager.vue"
export default component.exports