var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "CollectAddAlert",
      staticClass: "modal fade",
      attrs: { id: "addAlert" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("收藏 "),
              _c("span", { staticClass: "fr mr-10 mr-0-sm mb-10-sm fs14" }, [
                _vm._v(" 找不到? "),
                _c(
                  "b",
                  {
                    staticClass: "link text-line",
                    attrs: { "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.AddCollect()
                      },
                    },
                  },
                  [_vm._v("新增群組")]
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.collectionType,
                  multiple: true,
                  placeholder: "請選擇要加入收藏群組",
                  showLabels: false,
                  "hide-selected": true,
                  searchable: true,
                },
                model: {
                  value: _vm.collections,
                  callback: function ($$v) {
                    _vm.collections = $$v
                  },
                  expression: "collections",
                },
              }),
            ],
            1
          ),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer tc" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v(" 儲存 ")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v(" 關閉 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }