var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot", attrs: { id: "clientGameActivity" } },
    [
      _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "box box-primary",
            staticStyle: { background: "none" },
          },
          [
            _c(
              "div",
              { staticClass: "box-body", staticStyle: { padding: "0" } },
              [
                _c("div", { staticClass: "nav-tabs-custom" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c(
                      "li",
                      {
                        class: { active: _vm.tab == "app" },
                        on: {
                          click: function ($event) {
                            _vm.tab = "app"
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _vm._v("App (" + _vm._s(_vm.total.app) + ")"),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class: { active: _vm.tab == "game" },
                        on: {
                          click: function ($event) {
                            _vm.tab = "game"
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _vm._v("遊戲 (" + _vm._s(_vm.total.game) + ")"),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class: { active: _vm.tab == "analyze" },
                        on: {
                          click: function ($event) {
                            _vm.tab = "analyze"
                          },
                        },
                      },
                      [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _vm._v("集成分析(" + _vm._s(_vm.total.analyze) + ")"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "top-box" }, [
                  _c("div", { staticClass: "left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: { "margin-left": "0" },
                      },
                      [
                        _c("div", { staticClass: "dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sort dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "dropdown",
                                "aria-expanded": "false",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dropdown =
                                    _vm.dropdown == "none" ? "block" : "none"
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "icon-sort" }),
                              _vm._v(" 排序依據 "),
                            ]
                          ),
                          _c(
                            "ul",
                            {
                              staticClass: "dropdown-menu",
                              style: { display: `${_vm.dropdown}` },
                            },
                            [
                              _c("li", [
                                _vm.tab == "app"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 1 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(1)
                                          },
                                        },
                                      },
                                      [_vm._v(" 更新時間 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("li", [
                                _vm.tab == "app"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 2 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(2)
                                          },
                                        },
                                      },
                                      [_vm._v(" 建立時間 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("li", [
                                _vm.tab == "game"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 2 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(2)
                                          },
                                        },
                                      },
                                      [_vm._v(" A to Z ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("li", [
                                _vm.tab == "game"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 1 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(1)
                                          },
                                        },
                                      },
                                      [_vm._v(" Z to A ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("li", [
                                _vm.tab == "analyze"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 1 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(1)
                                          },
                                        },
                                      },
                                      [_vm._v(" 最近發布 ")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("li", [
                                _vm.tab == "analyze"
                                  ? _c(
                                      "a",
                                      {
                                        class: [
                                          "dropdown-item",
                                          _vm.order == 1 ? "active" : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetData(1)
                                          },
                                        },
                                      },
                                      [_vm._v(" 最早發布 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.list.length == 0
              ? _c("Empty", {
                  attrs: {
                    title: "找不到結果",
                    desc: "請改用其他關鍵字或移除排序條件",
                  },
                })
              : _c(
                  "div",
                  {
                    class: [
                      "g-list table-responsive mt-20",
                      _vm.tab == "game"
                        ? "hall"
                        : _vm.tab == "analyze"
                        ? "analyze"
                        : "",
                    ],
                  },
                  [
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        {
                          class: [
                            "g-card",
                            _vm.tab == "analyze" ? "no-bg" : "",
                          ],
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _vm.tab == "app"
                            ? _c("GameCard", { attrs: { item: item } })
                            : _vm._e(),
                          _vm.tab == "game"
                            ? _c("GameHallCard", { attrs: { item: item } })
                            : _vm._e(),
                          _vm.tab == "analyze"
                            ? _c("AnalyzeCard", { attrs: { item: item } })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm.searchData.PageNum < _vm.totolPage
                      ? _c("div", { staticClass: "loading-box" }, [
                          _c("div", { staticClass: "loading" }),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
          ],
          1
        ),
      ]),
      _c("slotPlayContent", { ref: "slotPlayContent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }