var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "PlayerDataList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("玩家資料查詢")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("玩家資料查詢")]),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "content", staticStyle: { "min-height": "auto" } },
        [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "form",
                {
                  staticClass: "top-box",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      _vm.getDataList()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.nickName,
                          expression: "searchData.nickName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "玩家暱稱" },
                      domProps: { value: _vm.searchData.nickName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "nickName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.searchData.phoneNumber,
                          expression: "searchData.phoneNumber",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "手機門號" },
                      domProps: { value: _vm.searchData.phoneNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "phoneNumber",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "btns mb-10" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("查詢")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: {
                          type: "button",
                          disabled: _vm.datas.length === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.exportOrders()
                          },
                        },
                      },
                      [_vm._v(" 匯出 ")]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: Object.keys(_vm.$route.query).length > 0,
                          expression: "Object.keys($route.query).length > 0",
                        },
                      ],
                      staticClass: "btn btn-default pull-right mb-10 ml-10",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v(" 返回 "),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-warning pull-right mb-10",
                      attrs: { to: { name: "NmjPlayerSetting" } },
                    },
                    [_vm._v("大量停權")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "table-responsive mt-0" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered text-center" },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      [
                        _vm.loading
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "15" } }, [
                                _vm._v("查詢中"),
                              ]),
                            ])
                          : _vm.result.message
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "15" } }, [
                                _vm._v(_vm._s(_vm.result.message)),
                              ]),
                            ])
                          : _vm.datas.length === 0
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "15" } }, [
                                _vm._v("查詢無任何資料"),
                              ]),
                            ])
                          : _vm._l(_vm.datas, function (player, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(player.nickName))]),
                                _c("td", [_vm._v(_vm._s(player.playerID))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      player.門號綁定 ? player.門號綁定 : "-"
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(player.會員狀態))]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(player.段位等級) + " "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#aaa" } },
                                    [_vm._v("(" + _vm._s(player.level) + ")")]
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(player.levelExp))]),
                                _c("td", [_vm._v(_vm._s(player.skill))]),
                                _c("td", [_vm._v(_vm._s(player.mahjongCoin))]),
                                _c("td", [
                                  _vm._v(_vm._s(player.mahjongSilver)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.uiDate(player.createTime * 1000))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.uiDate(
                                        player.lastPlayerLoginTime * 1000
                                      )
                                    )
                                  ),
                                ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerHistory",
                                            query: { nick: player.nickName },
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-history fa-fw",
                                        }),
                                        _vm._v(" 玩家歷程 "),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-success mt-5",
                                        attrs: {
                                          to: {
                                            name: "NmjPlayerDeposit",
                                            query: { nick: player.nickName },
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-dollar fa-fw",
                                        }),
                                        _vm._v(" 儲值歷程 "),
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn btn-warning mt-5",
                                        attrs: {
                                          to: {
                                            name: "NmjRewardMessageHistory",
                                            query: { nick: player.nickName },
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-envelope fa-fw",
                                        }),
                                        _vm._v(" 信件歷程 "),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      _vm.controlLabels,
                                      function (label, key) {
                                        return [
                                          player[key] * 1000 >= Date.now()
                                            ? _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(label) +
                                                    "至" +
                                                    _vm._s(
                                                      _vm.formatTimestamp(
                                                        player[key]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                    _vm.isControl(player)
                                      ? _c("p", [_vm._v("正常無控管")])
                                      : _vm._e(),
                                    _c("p", { staticClass: "mt-15" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-info",
                                          on: {
                                            click: function ($event) {
                                              return _vm.playerSetting(player)
                                            },
                                          },
                                        },
                                        [_vm._v(" 修改 ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isControl(player),
                                              expression: "!isControl(player)",
                                            },
                                          ],
                                          staticClass: "btn btn-danger ml-5",
                                          on: {
                                            click: function ($event) {
                                              return _vm.playerSetting(
                                                player,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 解鎖 ")]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ])
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm.datas.length > 0
            ? _c("div", [
                _c(
                  "h2",
                  { staticClass: "text-big", staticStyle: { color: "#333" } },
                  [
                    _vm._v(" 玩家紀錄： "),
                    _vm.datas.length == 1
                      ? _c("span", [_vm._v(_vm._s(_vm.datas[0].nickName))])
                      : _c("span", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchData.暱稱,
                                  expression: "searchData.暱稱",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: {
                                display: "inline-block",
                                width: "auto",
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.searchData,
                                    "暱稱",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.datas, function (player, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: { value: player.nickName },
                                },
                                [_vm._v(" " + _vm._s(player.nickName) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                  ]
                ),
                _c("div", { staticClass: "nav-tabs-custom tab-warning" }, [
                  _c("ul", { staticClass: "nav nav-tabs" }, [
                    _c("li", { class: { active: _vm.tab == 1 } }, [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            "aria-expanded": "true",
                            "data-toggle": "tab",
                          },
                          on: {
                            click: function ($event) {
                              _vm.tab = 1
                            },
                          },
                        },
                        [_vm._v("服務紀錄")]
                      ),
                    ]),
                    _c("li", { class: { active: _vm.tab == 2 } }, [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            "aria-expanded": "true",
                            "data-toggle": "tab",
                          },
                          on: {
                            click: function ($event) {
                              _vm.tab = 2
                            },
                          },
                        },
                        [_vm._v("控管紀錄")]
                      ),
                    ]),
                  ]),
                  _vm.tab == 1
                    ? _c("div", { staticClass: "tab-content" }, [
                        _c("div", { staticClass: "top-box" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-warning pull-right mb-10 ml-10",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.playerService(_vm.searchData.暱稱)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" 新增 "),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered text-center" },
                            [
                              _vm._m(1),
                              _c(
                                "tbody",
                                [
                                  _vm.serviceLoading
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v("查詢中"),
                                        ]),
                                      ])
                                    : _vm.serviceMessage
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v(_vm._s(_vm.serviceMessage)),
                                        ]),
                                      ])
                                    : _vm.serviceList.length === 0
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v("查無控管紀錄"),
                                        ]),
                                      ])
                                    : _vm._l(
                                        _vm.serviceList,
                                        function (item, index) {
                                          return _c("tr", { key: item.日期 }, [
                                            _c("td", [
                                              _vm._v(_vm._s(item.暱稱)),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(_vm.uiDate(item.日期))
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.發佈者)),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.serviceTypes[item.分類]
                                                )
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.內容)),
                                            ]),
                                            _c("td", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-danger",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deletePlayerService(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 刪除 ")]
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.tab == 2
                    ? _c("div", { staticClass: "tab-content" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered text-center" },
                            [
                              _vm._m(2),
                              _c(
                                "tbody",
                                [
                                  _vm.banLoading
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v("查詢中"),
                                        ]),
                                      ])
                                    : _vm.banMessage
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v(_vm._s(_vm.banMessage)),
                                        ]),
                                      ])
                                    : _vm.banList.length === 0
                                    ? _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v("查無控管紀錄"),
                                        ]),
                                      ])
                                    : _vm._l(
                                        _vm.banList,
                                        function (item, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(_vm._s(item.暱稱)),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(_vm.uiDate(item.起始日))
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.操作者)),
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                attrs: {
                                                  "data-title": "類型-期限",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.typeStatus(
                                                        item.動作,
                                                        item.類型,
                                                        item.起始日,
                                                        item.終止日
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.controlTerm(
                                                    item.類型,
                                                    item.終止日
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.原因)),
                                            ]),
                                          ])
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm.banTotal > _vm.banSearchData.pageSize
                          ? _c(
                              "div",
                              { staticClass: "text-center mt-10" },
                              [
                                _c("Pagination", {
                                  ref: "pagination",
                                  attrs: {
                                    pageLimitCount: _vm.banSearchData.pageSize,
                                    total: _vm.banTotal,
                                  },
                                  on: { pageMethod: _vm.onPageChange },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("PlayerSettingContent", {
        ref: "PlayerSettingContent",
        on: {
          updateList: function ($event) {
            _vm.getDataList()
            _vm.getBanInfo()
          },
        },
      }),
      _c("PlayerServiceContent", {
        ref: "PlayerServiceContent",
        attrs: { types: _vm.serviceTypes },
        on: {
          updateList: function ($event) {
            return _vm.getServiceInfo()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "120px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("ID"),
        ]),
        _c("th", { staticStyle: { "min-width": "120px", width: "5%" } }, [
          _vm._v("綁定"),
          _c("wbr"),
          _vm._v("門號"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("會員"),
          _c("wbr"),
          _vm._v("狀態"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("段位"),
          _c("wbr"),
          _vm._v("等級"),
        ]),
        _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
          _vm._v("段位"),
          _c("wbr"),
          _vm._v("經驗值"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("牌技"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("雀幣"),
        ]),
        _c("th", { staticStyle: { "min-width": "80px", width: "5%" } }, [
          _vm._v("雀銀"),
        ]),
        _c("th", { staticStyle: { "min-width": "105px", width: "5%" } }, [
          _vm._v("創建"),
          _c("wbr"),
          _vm._v("帳號"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "105px", width: "5%" } }, [
          _vm._v("上次"),
          _c("wbr"),
          _vm._v("登入"),
          _c("wbr"),
          _vm._v("時間"),
        ]),
        _c("th", { staticStyle: { "min-width": "140px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("歷程"),
        ]),
        _c("th", { staticStyle: { "min-width": "140px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("控管"),
          _c("wbr"),
          _vm._v("狀態"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v(" 操作"),
          _c("wbr"),
          _vm._v("時間 "),
        ]),
        _c("th", { staticStyle: { "min-width": "110px", width: "5%" } }, [
          _vm._v("操作"),
          _c("wbr"),
          _vm._v("人員"),
        ]),
        _c("th", { staticStyle: { "min-width": "110px", width: "5%" } }, [
          _vm._v("類型"),
        ]),
        _c("th", { staticStyle: { "min-width": "150px", width: "15%" } }, [
          _vm._v("內容"),
        ]),
        _c("th", { staticStyle: { "min-width": "75px", width: "5%" } }, [
          _vm._v("功能"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "bg-gray" }, [
        _c("th", { staticStyle: { "min-width": "100px", width: "5%" } }, [
          _vm._v("玩家"),
          _c("wbr"),
          _vm._v("暱稱"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v(" 操作"),
          _c("wbr"),
          _vm._v("時間 "),
        ]),
        _c("th", { staticStyle: { "min-width": "110px", width: "5%" } }, [
          _vm._v("操作"),
          _c("wbr"),
          _vm._v("人員"),
        ]),
        _c("th", { staticStyle: { "min-width": "110px", width: "5%" } }, [
          _vm._v("類型"),
        ]),
        _c("th", { staticStyle: { "min-width": "100px", width: "10%" } }, [
          _vm._v("期限"),
        ]),
        _c("th", { staticStyle: { "min-width": "150px", width: "15%" } }, [
          _vm._v("原因"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }