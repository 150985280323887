var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [
        _vm._v(
          " " +
            _vm._s(
              _vm.isCustomize ? _vm.$t("自定義罐頭訊息") : _vm.$t("罐頭訊息")
            ) +
            " "
        ),
      ]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(
            _vm._s(
              _vm.isCustomize ? _vm.$t("自定義罐頭訊息") : _vm.$t("罐頭訊息")
            )
          ),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { disabled: !_vm.searchData.Project, type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.OpenAddForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" " + _vm._s(_vm.$t("新增")) + " "),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.Project,
                        expression: "searchData.Project",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "Project",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.projects, function (item) {
                    return _c(
                      "option",
                      { key: item, domProps: { value: item } },
                      [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.CategoryID,
                        expression: "searchData.CategoryID",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "CategoryID",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v(_vm._s(_vm.$t("全部"))),
                    ]),
                    _vm._l(_vm.filterCategories, function (item) {
                      return _c(
                        "option",
                        {
                          key: item.categoryID,
                          domProps: { value: item.categoryID },
                        },
                        [
                          _vm._v(
                            _vm._s(item.name) +
                              _vm._s(
                                item.enable == false
                                  ? _vm.$t("Scratch停用中")
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.IsEnable,
                        expression: "searchData.IsEnable",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "IsEnable",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "true" } }, [
                      _vm._v(_vm._s(_vm.$t("啟用"))),
                    ]),
                    _c("option", { attrs: { value: "false" } }, [
                      _vm._v(_vm._s(_vm.$t("停用"))),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.searchData.KeyWord,
                      expression: "searchData.KeyWord",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("搜尋問題和回覆"),
                  },
                  domProps: { value: _vm.searchData.KeyWord },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.searchData,
                        "KeyWord",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "select-group", staticStyle: { width: "auto" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: !_vm.searchData.Project,
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchData.Page = 1
                          _vm.Query()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-search" }),
                      _vm._v(" " + _vm._s(_vm.$t("查詢")) + " "),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.Exprot()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("匯出")) + " ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-bordered classTypeTable" },
                [
                  _c(
                    "tbody",
                    [
                      _c("tr", { staticClass: "bg-gray" }, [
                        _c("th", { attrs: { width: "10%" } }, [
                          _vm._v(_vm._s(_vm.$t("類別"))),
                        ]),
                        _c("th", { attrs: { width: "20%" } }, [
                          _vm._v(_vm._s(_vm.$t("問題"))),
                        ]),
                        _c("th", { attrs: { width: "40%" } }, [
                          _vm._v(_vm._s(_vm.$t("回覆"))),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("排序")))]),
                        _c("th", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(_vm._s(_vm.$t("啟用狀態"))),
                        ]),
                        _c("th", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(_vm._s(_vm.$t("修改日期"))),
                        ]),
                        _c("th", { staticClass: "text-nowrap text-center" }, [
                          _vm._v(_vm._s(_vm.$t("功能"))),
                        ]),
                      ]),
                      _vm._l(_vm.list, function (item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.categoryName /*item.categoryID*/)
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticStyle: { "white-space": "pre-line" } },
                              [_vm._v(" " + _vm._s(item.question) + " ")]
                            ),
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticStyle: { "white-space": "pre-line" } },
                              [_vm._v(" " + _vm._s(item.answer) + " ")]
                            ),
                          ]),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.sort,
                                  expression: "item.sort",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: {
                                display: "inline-block",
                                width: "100px",
                                "vertical-align": "middle",
                                "margin-right": "10px",
                              },
                              attrs: {
                                type: "number",
                                disabled: item.enable == false,
                              },
                              domProps: { value: item.sort },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "sort", $event.target.value)
                                },
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { disabled: item.原始sort == item.sort },
                                on: {
                                  click: function ($event) {
                                    _vm.更新排序(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("更新")))]
                            ),
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { align: "center" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ckbx-style-8 ckbx toggleInput",
                                  on: {
                                    click: function ($event) {
                                      _vm.啟停用(item)
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.enable,
                                        expression: "item.enable",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    attrs: {
                                      "false-value": false,
                                      "true-value": true,
                                      name: "ckbx-style-8",
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(item.enable)
                                        ? _vm._i(item.enable, null) > -1
                                        : item.enable,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = item.enable,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                item,
                                                "enable",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                item,
                                                "enable",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(item, "enable", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _c("label", {
                                    attrs: { for: "check" + item },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "text-nowrap text-center",
                              staticStyle: { "word-break": "break-word" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(item.updateTime)) +
                                  " "
                              ),
                            ]
                          ),
                          _c("td", { staticClass: "text-nowrap text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.OpenEditForm(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("修改")))]
                            ),
                            _vm._v("   "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                on: {
                                  click: function ($event) {
                                    return _vm.DeleteItem(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("刪除")))]
                            ),
                          ]),
                        ])
                      }),
                      _vm.list.length == 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "7" },
                              },
                              [_vm._v(_vm._s(_vm.$t("沒有資料")))]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: { total: _vm.total, pageLimitCount: _vm.pageSize },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
          _c("div", { ref: "loading", staticClass: "overlay" }, [
            _c("i", { staticClass: "fa fa-refresh fa-spin" }),
          ]),
        ]),
        _c("MessageCanMsgEdit", {
          ref: "MessageCanMsgEdit",
          attrs: { Categories: _vm.Categories, Service: _vm.projects },
          on: {
            refresh: function ($event) {
              return _vm.Query()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }