var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "RewardMessageEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("給獎訊息設定")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/FC/RewardMessage" } }, [
              _vm._v("給獎訊息設定"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
        ]),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box with-border" }, [
              _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                _vm._v(_vm._s(_vm.id ? "編輯" : "新增") + "內容"),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "rewardMessageForm", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-2" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "單一玩家",
                                name: "sendRadio",
                              },
                              model: {
                                value: _vm.message.sendType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.message, "sendType", _vm._n($$v))
                                },
                                expression: "message.sendType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-10" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.playerIds,
                                expression: "playerIds",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "輸入玩家編號，中間用逗號分隔",
                              type: "text",
                              required: _vm.message.sendType === 1,
                            },
                            domProps: { value: _vm.playerIds },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.playerIds = $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-2 col" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "2",
                                message: "指定名單",
                                name: "sendRadio",
                              },
                              model: {
                                value: _vm.message.sendType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.message, "sendType", _vm._n($$v))
                                },
                                expression: "message.sendType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-10 col" }, [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.importSN()
                                },
                              },
                            },
                            [_vm._v(" 選擇檔案 ")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "8px" } },
                            [_vm._v(" " + _vm._s(_vm.importList.name) + " ")]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "radio col-md-2" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "3",
                                message: "複合條件",
                                name: "sendRadio",
                              },
                              model: {
                                value: _vm.message.sendType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.message, "sendType", _vm._n($$v))
                                },
                                expression: "message.sendType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-10" }, [
                          _c("div", { staticClass: "box-group" }, [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "label",
                                { staticClass: "checkbox checkbox-inline" },
                                [
                                  _c("iCheckCheckBox", {
                                    attrs: {
                                      propValue: 1,
                                      propChecked: _vm.conditions[0].checked,
                                    },
                                    on: {
                                      "update:checked": _vm.conditionChange,
                                    },
                                  }),
                                  _vm._v(" 等級 "),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "level col-md-9" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.conditions[0].ruleValue1,
                                    expression: "conditions[0].ruleValue1",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  required: +_vm.conditions[0].checked === 1,
                                },
                                domProps: {
                                  value: _vm.conditions[0].ruleValue1,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.conditions[0],
                                      "ruleValue1",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("p", [_vm._v("~")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.conditions[0].ruleValue2,
                                    expression: "conditions[0].ruleValue2",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  required: +_vm.conditions[0].checked === 1,
                                },
                                domProps: {
                                  value: _vm.conditions[0].ruleValue2,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.conditions[0],
                                      "ruleValue2",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "box-group" }, [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "label",
                                { staticClass: "checkbox checkbox-inline" },
                                [
                                  _c("iCheckCheckBox", {
                                    attrs: {
                                      propValue: 2,
                                      propChecked: _vm.conditions[1].checked,
                                    },
                                    on: {
                                      "update:checked": _vm.conditionChange,
                                    },
                                  }),
                                  _vm._v(" VIP "),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vip col-md-9" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.conditions[1].ruleValue1,
                                      expression: "conditions[1].ruleValue1",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    required: +_vm.conditions[1].checked === 1,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.conditions[1],
                                        "ruleValue1",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("請選擇"),
                                  ]),
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v("BRONZE"),
                                  ]),
                                  _c("option", { attrs: { value: "2" } }, [
                                    _vm._v("SILVER"),
                                  ]),
                                  _c("option", { attrs: { value: "3" } }, [
                                    _vm._v("GOLD"),
                                  ]),
                                  _c("option", { attrs: { value: "4" } }, [
                                    _vm._v("PLATINUM"),
                                  ]),
                                  _c("option", { attrs: { value: "5" } }, [
                                    _vm._v("DIAMOND"),
                                  ]),
                                  _c("option", { attrs: { value: "6" } }, [
                                    _vm._v("ROYAL DIAMOND"),
                                  ]),
                                  _c("option", { attrs: { value: "7" } }, [
                                    _vm._v("BLACK DIAMOND"),
                                  ]),
                                ]
                              ),
                              _c("p", [_vm._v("~")]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.conditions[1].ruleValue2,
                                      expression: "conditions[1].ruleValue2",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    required: +_vm.conditions[1].checked === 1,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.conditions[1],
                                        "ruleValue2",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("請選擇"),
                                  ]),
                                  _c("option", { attrs: { value: "1" } }, [
                                    _vm._v("BRONZE"),
                                  ]),
                                  _c("option", { attrs: { value: "2" } }, [
                                    _vm._v("SILVER"),
                                  ]),
                                  _c("option", { attrs: { value: "3" } }, [
                                    _vm._v("GOLD"),
                                  ]),
                                  _c("option", { attrs: { value: "4" } }, [
                                    _vm._v("PLATINUM"),
                                  ]),
                                  _c("option", { attrs: { value: "5" } }, [
                                    _vm._v("DIAMOND"),
                                  ]),
                                  _c("option", { attrs: { value: "6" } }, [
                                    _vm._v("ROYAL DIAMOND"),
                                  ]),
                                  _c("option", { attrs: { value: "7" } }, [
                                    _vm._v("BLACK DIAMOND"),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "box-group" }, [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "label",
                                { staticClass: "checkbox checkbox-inline" },
                                [
                                  _c("iCheckCheckBox", {
                                    attrs: {
                                      propValue: 3,
                                      propChecked: _vm.conditions[2].checked,
                                    },
                                    on: {
                                      "update:checked": _vm.conditionChange,
                                    },
                                  }),
                                  _vm._v(" 俱樂部名稱 "),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "club-id col-md-9" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.groupIds,
                                    expression: "groupIds",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  placeholder: "輸入俱樂部名稱，中間用逗號分隔",
                                  type: "text",
                                  required: +_vm.conditions[2].checked === 1,
                                },
                                domProps: { value: _vm.groupIds },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.groupIds = $event.target.value.trim()
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.message.messageTitle,
                            expression: "message.messageTitle",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputTitle",
                          maxlength: _vm.titleMax,
                          placeholder: `請輸入主旨，上限${_vm.titleMax}字`,
                          required: "",
                        },
                        domProps: { value: _vm.message.messageTitle },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.message,
                              "messageTitle",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _c("p", { staticClass: "word-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.message.messageTitle.length) +
                            " / " +
                            _vm._s(_vm.titleMax) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "editor" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message.messageNote,
                              expression: "message.messageNote",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            cols: "100",
                            rows: "10",
                            maxlength: _vm.contentMax,
                            placeholder: `請輸入內容，上限${_vm.contentMax}字。`,
                            required: "",
                          },
                          domProps: { value: _vm.message.messageNote },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.message,
                                "messageNote",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("p", { staticClass: "word-count" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.message.messageNote.length) +
                            " / " +
                            _vm._s(_vm.contentMax) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(4),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.startTime },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.startTime = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.startTime = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(5),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("div", { staticClass: "radio-date" }, [
                        _c("div", { staticClass: "input-group date" }, [
                          _vm._m(7),
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c("DatePicker", {
                                attrs: { propValue: _vm.endTime },
                                on: {
                                  "update:propValue": function ($event) {
                                    _vm.endTime = $event
                                  },
                                  "update:prop-value": function ($event) {
                                    _vm.endTime = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._m(8),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(9),
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.message.getDayLimit,
                            expression: "message.getDayLimit",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "保留天數",
                          type: "number",
                          min: "0",
                          required: "",
                        },
                        domProps: { value: _vm.message.getDayLimit },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.message,
                              "getDayLimit",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(10),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _vm._l(_vm.message.items, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "box-group sendAwards" },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.prizeTypeNo,
                                      expression: "item.prizeTypeNo",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { required: "" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          item,
                                          "prizeTypeNo",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.mainTypeChange(item)
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("請選擇類型"),
                                  ]),
                                  _vm._l(
                                    _vm.mainTypes,
                                    function (mainType, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: {
                                            value: mainType.prizeTypeNo,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(mainType.prizeTypeName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              item.subTypes && item.subTypes.length
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.prizeSubType,
                                          expression: "item.prizeSubType",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        required:
                                          item.subTypes && item.subTypes.length,
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              item,
                                              "prizeSubType",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.subTypeChange(item)
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("請選擇副類別"),
                                      ]),
                                      _vm._l(
                                        item.subTypes,
                                        function (subType, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: index,
                                              domProps: { value: subType },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(subType) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.prizeItemNo,
                                      expression: "item.prizeItemNo",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { required: "" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        item,
                                        "prizeItemNo",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("請選擇道具"),
                                  ]),
                                  _vm._l(
                                    item.detailTypes,
                                    function (detailType, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: {
                                            value: detailType.prizeItemNo,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(detailType.prizeItemName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: item.amount,
                                    expression: "item.amount",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control inputNumber",
                                attrs: {
                                  placeholder: "請輸入數量",
                                  type: "number",
                                  required: "",
                                },
                                domProps: { value: item.amount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      "amount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index !== 0,
                                      expression: "index !== 0",
                                    },
                                  ],
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAwards(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" 刪除 ")]
                              ),
                            ]
                          )
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addAwards()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" 新增獎勵 "),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    !_vm.message.messageStatus
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-lg",
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                return _vm.save(0)
                              },
                            },
                          },
                          [_vm._v(" 儲存 ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            return _vm.save(1)
                          },
                        },
                      },
                      [_vm._v(" 確認送出 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _c("fcImportData", {
          ref: "fcImportData",
          attrs: { title: "玩家", checks: _vm.checks },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發送名單： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息主旨： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "editor" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息發送時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "startTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訊息有效時間： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text", id: "endTimeOnly" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "effectiveTime" },
      },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 保留天數： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "sendAwards" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 發送獎勵： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }