var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AboutUsList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("關於網銀")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("關於網銀")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "banner-picker",
              {
                attrs: {
                  "img-help":
                    "圖片尺寸為 1400 * 400px，大小請勿超過 2Mb，選完要儲存！",
                },
              },
              [_vm._v(" 標頭圖 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "box box-primary" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _c(
              "list-table",
              {
                attrs: {
                  "show-list": "index image online date",
                  "img-help": "圖片尺寸為 1400 * 520px，大小請勿超過 2Mb",
                },
              },
              [_vm._v(" 獎項相簿 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }