var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.channalKPIData.target_name) + "的KPI")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("網紅列表")]),
        _c("li", { staticClass: "active" }, [_vm._v("KPI")]),
      ]),
    ]),
    _vm.kolkpiData
      ? _c("section", { staticClass: "content" }, [
          _c("div", { staticClass: "box box-primary" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "top-box with-border" }, [
                _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
                  _vm._v("目前數據"),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-default btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.backList()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-reply" }),
                    _vm._v(" 返回列表 "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-warning btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.editorItem()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-pencil" }), _vm._v(" 編輯 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-primary btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.exportData()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-upload" }), _vm._v(" 匯出 ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-google btn-cog",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.crawlerKOLChannel()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-bug" }), _vm._v(" 重抓 ")]
                ),
              ]),
              _c("div", { staticClass: "top-box mt-15" }, [
                _c("div", { staticClass: "select-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.platform,
                          expression: "platform",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectSocial" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.platform = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.changePlatform()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "" } }, [
                        _vm._v("請選擇平台"),
                      ]),
                      _vm._l(_vm.platformList, function (item, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: item } },
                          [_vm._v(" " + _vm._s(item.className) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "select-group long" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.channel,
                          expression: "channel",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "selectSocial" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.channel = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "" } }, [
                        _vm._v("請選擇頻道"),
                      ]),
                      _vm._l(_vm.channelList, function (item, key) {
                        return _c(
                          "option",
                          { key: key, domProps: { value: item } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.channel_name == ""
                                    ? "尚未設置頻道名"
                                    : item.channel_name
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.channalKPIData.channel_url,
                      target: "_blank",
                    },
                  },
                  [_c("i", { staticClass: "c-BigDataLinkIcon t-5 fa fa-link" })]
                ),
              ]),
              _c("div", { staticClass: "dataCardWrapper clearfix" }, [
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("粉絲數")]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat2")(
                            _vm.pareToFloor(_vm.channel.follow)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm.channalKPIData.platform != "yt"
                  ? _c("div", { staticClass: "dataCard" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("總貼文數"),
                      ]),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currencyFormat")(_vm.channel.total)
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "dataCard" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v("總影片數"),
                      ]),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currencyFormat")(_vm.channel.total)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
              ]),
              _c("hr", { staticClass: "divider mb-10" }),
              _vm._m(0),
              _c("div", { staticClass: "top-box mt-15" }, [
                _c(
                  "div",
                  { staticClass: "btn-group w100-sm ml-10 ml-0-sm" },
                  _vm._l(_vm.dayAnalysis, function (day, key) {
                    return _c(
                      "button",
                      {
                        key: key,
                        staticClass: "btn btn-default w25-sm",
                        class: {
                          active: _vm.dayAnalysisChoose == "day" + day,
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.changeDays(day)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(day + "天") + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "dataCardWrapper clearfix" }, [
                _c("label", { staticClass: "card-label" }, [
                  _vm._v(
                    " 貼文數：" + _vm._s(_vm.channalKPIData.interval_post_count)
                  ),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("平均留言數"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "總留言數/貼文數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat")(
                            _vm.channalKPIData.avg_comment.toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("平均按讚數"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "總按讚數/貼文數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat")(
                            _vm.channalKPIData.avg_like.toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("平均互動數"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "(按讚數+分享數+留言數)/貼文數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat")(
                            _vm.channalKPIData.avg_post_interactions.toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("互動率")]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "貼文平均互動數 / 平台追蹤數 * 100%",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseFloatNoRounding(
                            _vm.channalKPIData.avg_post_probability
                          )
                        ) +
                        "% "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("影片平均觀看數"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "總觀看數/影片數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat")(
                            _vm.channalKPIData.avg_view_content.toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("影片平均觀看率"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "影片平均觀看數／平台追蹤數 * 100%",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseFloatNoRounding(
                            _vm.channalKPIData.avg_view_content /
                              _vm.channalKPIData.max_follow
                          )
                        ) +
                        "% "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("影片平均互動數"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "指定時間內（按讚數＋留言數）／影片數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currencyFormat")(
                            _vm.channalKPIData.avg_post_interactions.toFixed(2)
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("影片平均互動率"),
                  ]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "平均互動數 / 平台追蹤數 * 100%",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseFloatNoRounding(
                            _vm.channalKPIData.avg_post_probability
                          )
                        ) +
                        "% "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("漲粉數")]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "總平台追蹤數 - 指定時間平台追蹤數",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currencyFormat")(_vm.channalKPIData.fans_up)
                      ) + " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "dataCard" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("漲粉率")]),
                  _c(
                    "i",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title:
                          "(總平台追蹤數 - 指定時間前平台追蹤數) / 指定時間前平台追蹤數 * 100%",
                      },
                    },
                    [_vm._v("?")]
                  ),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.parseFloatNoRounding(
                            _vm.channalKPIData.fans_up_probability
                          )
                        ) +
                        "% "
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "nav-tabs-custom boxShadow-none clearfix overflowX-auto",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "nav nav-tabs nav-yellow min-600" },
                    _vm._l(_vm.tabAnalysisName, function (tab, key) {
                      return _c(
                        "li",
                        {
                          key: key,
                          class: { active: _vm.tabAnalysis == "tab" + key },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.SetGraphValue(key)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: "#tab" + key } }, [
                            _vm._v(" " + _vm._s(tab)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "tab-content" },
                    _vm._l(_vm.tabAnalysisName, function (tab, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "tab-pane pt-30",
                          class: { active: _vm.tabAnalysis == "tab" + key },
                          attrs: { id: "#tab" + key },
                        },
                        [
                          _c("highcharts", {
                            attrs: { options: _vm.chartOptions },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c("hr", { staticClass: "divider mb-10" }),
              _c("div", { staticClass: "top-box mt-20" }, [
                _c("div", { staticClass: "input-group atLeft" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.historySearchData.keyword,
                        expression: "historySearchData.keyword",
                      },
                    ],
                    staticClass: "form-control pull-right",
                    attrs: {
                      name: "table_search",
                      placeholder: "搜尋貼文關鍵字",
                      type: "text",
                    },
                    domProps: { value: _vm.historySearchData.keyword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.historySearchData,
                          "keyword",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.searchHistoryKeyWord },
                      },
                      [_c("i", { staticClass: "fa fa-search" })]
                    ),
                  ]),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.searchReset()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-refresh" }), _vm._v(" 重設 ")]
                ),
                _c("div", { staticClass: "btn-group fr" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { active: _vm.tabLayout == "table" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.tabLayout = "table"
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-list-ul" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { active: _vm.tabLayout == "list" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.tabLayout = "list"
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-th" })]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "nav-tabs-custom boxShadow-none clearfix mt-35",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "nav nav-tabs nav-yellow" },
                    _vm._l(_vm.tabPostName, function (tab, key) {
                      return _c(
                        "li",
                        {
                          key: key,
                          class: { active: _vm.tabPost == "tab" + key },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeSortTab(key)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: "#tab" + key } }, [
                            _vm._v(" " + _vm._s(tab)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "tab-content" },
                    [
                      _vm._l(_vm.tabPostName, function (tab, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            staticClass:
                              "tab-pane overflowX-auto table-responsive",
                            class: { active: _vm.tabPost == "tab" + key },
                            attrs: { id: "#tab" + key },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "css-table post-table",
                                class: { cssList: _vm.tabLayout == "list" },
                              },
                              [
                                _c("div", { staticClass: "thead" }, [
                                  _c("div", { staticClass: "tr" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "50%" },
                                      },
                                      [_vm._v("貼文")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "10%" },
                                      },
                                      [_vm._v("按讚數")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "10%" },
                                      },
                                      [_vm._v("留言數")]
                                    ),
                                    _vm.platform.classId == "yt"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "th",
                                            staticStyle: { width: "10%" },
                                          },
                                          [_vm._v("觀看數")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "10%" },
                                      },
                                      [_vm._v("爬蟲時間")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "tbody" },
                                  _vm._l(_vm.historyList, function (post, key) {
                                    return post.is_valid !== false
                                      ? _c(
                                          "a",
                                          {
                                            key: key,
                                            staticClass: "postCard tr",
                                            attrs: { href: "javascript:;" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goPostData(post)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card-body td" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card-img jqImgFill",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: post.post_display_url
                                                          ? post.post_display_url
                                                          : require("@/assets/no-image.png"),
                                                      },
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "typeIcon fa",
                                                      class: !_vm.isVideo(post)
                                                        ? "fa-sticky-note"
                                                        : "fa-play",
                                                    }),
                                                  ]
                                                ),
                                                _c("p", {
                                                  staticClass: "card-text",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      post.description
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "card-date" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$utils.formatTime(
                                                          post.upload_date,
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "td td-like" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon fa fa-thumbs-up",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currencyFormat")(
                                                        post.like_count
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "td td-comment" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon fa fa-comment",
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currencyFormat")(
                                                      post.comment_counts
                                                    )
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                            _vm.platform.classId == "yt"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "td td-browse",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon fa fa-eye",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "currencyFormat"
                                                          )(post.view_count)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "td td-crawl time",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$utils.formatTime(
                                                        post.last_update,
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "box-footer text-center no-border mt-20",
                        },
                        [
                          _c("Pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.historyTotal > 0,
                                expression: "historyTotal > 0",
                              },
                            ],
                            ref: "pagination",
                            attrs: {
                              pageLimitCount: _vm.historySearchData.page_size,
                              total: _vm.historyTotal,
                            },
                            on: { pageMethod: _vm.onPageChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-box with-border" }, [
      _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
        _vm._v("數據分析"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }