var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "DeliveryRate" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("簡訊送達率查詢")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("簡訊送達率查詢")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "div",
              { staticClass: "date-group", staticStyle: { width: "auto" } },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("時間："),
                ]),
                _c("div", { staticClass: "input-group date" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "pull-left" },
                    [
                      _c("DateRangePicker", {
                        attrs: {
                          propTimePicker: false,
                          propEndDate: _vm.searchData.結束時間,
                          propStartDate: _vm.searchData.開始時間,
                        },
                        on: {
                          "update:propEndDate": function ($event) {
                            return _vm.$set(_vm.searchData, "結束時間", $event)
                          },
                          "update:prop-end-date": function ($event) {
                            return _vm.$set(_vm.searchData, "結束時間", $event)
                          },
                          "update:propStartDate": function ($event) {
                            return _vm.$set(_vm.searchData, "開始時間", $event)
                          },
                          "update:prop-start-date": function ($event) {
                            return _vm.$set(_vm.searchData, "開始時間", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._m(1),
          ]),
          _c("div", { staticClass: "top-box topBox-announceList" }, [
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "" } },
                [_vm._v("區碼：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.區碼,
                      expression: "searchData.區碼",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "區碼",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.define.areaCodeList, function (areaCode, index) {
                    return _c(
                      "option",
                      { domProps: { value: areaCode.code } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(areaCode.code) +
                            "(" +
                            _vm._s(areaCode.name) +
                            ") "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "" } },
                [_vm._v("簡訊商：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.簡訊商代碼,
                      expression: "searchData.簡訊商代碼",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "簡訊商代碼",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.comList, function (com, index) {
                    return [
                      _c(
                        "option",
                        { domProps: { value: com.簡訊商門號代碼 } },
                        [_vm._v(" " + _vm._s(com.簡訊商門號說明) + " ")]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "label",
                { staticClass: "control-label", attrs: { for: "" } },
                [_vm._v("服務列表：")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.帳號,
                      expression: "searchData.帳號",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "帳號",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.accountList, function (account, index) {
                    return _c(
                      "option",
                      { domProps: { value: account.accId } },
                      [_vm._v(" " + _vm._s(account.accName) + " ")]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "btns mr-10" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.resetDataList()
                    },
                  },
                },
                [_vm._v("查詢")]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "input-group display-ib ml-10",
                staticStyle: { width: "auto" },
              },
              [
                _c("label", {
                  staticClass: "control-label",
                  attrs: { for: "" },
                }),
                _c("div", { staticClass: "checkbox" }, [
                  _c(
                    "label",
                    [
                      _c("iCheckCheckBox", {
                        attrs: { propChecked: _vm.searchData.不分區碼YN },
                        on: {
                          "update:checked": function ($event) {
                            _vm.searchData.不分區碼YN = $event["checked"]
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("不分區碼(隱藏區碼欄位)")]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-hover table-bordered" }, [
              _c(
                "tbody",
                [
                  _c("tr", { staticClass: "bg-gray" }, [
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("編號")]
                    ),
                    _vm.searchData.不分區碼YN == 0
                      ? _c(
                          "th",
                          {
                            staticClass: "text-nowrap",
                            staticStyle: { width: "15%" },
                          },
                          [_vm._v("區碼")]
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "20%" },
                      },
                      [_vm._v("簡訊商")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "20%" },
                      },
                      [_vm._v("服務")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("發送筆數")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("到達筆數")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "10%" },
                      },
                      [_vm._v("送達率")]
                    ),
                  ]),
                  _vm.loading
                    ? [_vm._m(2)]
                    : _vm.total == 0
                    ? [_vm._m(3)]
                    : _vm._l(_vm.list, function (item, index) {
                        return [
                          _c("tr", [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _vm.searchData.不分區碼YN == 0
                              ? _c("td", { staticClass: "text-center" }, [
                                  _vm._v(_vm._s(_vm.area(item.區碼))),
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.comListName[item.簡訊商代碼])),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.名稱)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.筆數)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.送達筆數)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.送達率)),
                            ]),
                          ]),
                        ]
                      }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "text-red display-ib mr-10",
        staticStyle: { "padding-top": "7px" },
      },
      [_c("p", [_vm._v("* 查詢日期區間以30天為限")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }