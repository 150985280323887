var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("影片")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("在地饗宴")]),
        _c("li", { staticClass: "active" }, [_vm._v("影片")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "box box-primary" },
        [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addVideo()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" 新增影片 ")]
              ),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.list.length)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                { staticClass: "table table-hover table-bordered min-800" },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm.list.length > 0
                        ? _vm._l(_vm.list, function (item, index) {
                            return _c("tr", { key: item.id }, [
                              _c("td", [
                                item.editor && item.editor != 0
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.title,
                                          expression: "item.title",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: item.title },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "title",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _c("p", { attrs: { align: "center" } }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                              ]),
                              _c("td", [
                                item.editor && item.editor != 0
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.url,
                                          expression: "item.url",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: item.url },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            item,
                                            "url",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _c("p", { attrs: { align: "center" } }, [
                                      _vm._v(_vm._s(item.url)),
                                    ]),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(_vm.dateFormat(item.createTime))),
                              ]),
                              _c(
                                "td",
                                {
                                  staticClass: "text-center",
                                  attrs: { align: "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ckbx-style-8 ckbx toggleInput",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.isStop,
                                            expression: "item.isStop",
                                          },
                                        ],
                                        attrs: {
                                          "false-value": 1,
                                          id: "check" + index,
                                          "true-value": 0,
                                          name: "ckbx-style-8",
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(item.isStop)
                                            ? _vm._i(item.isStop, null) > -1
                                            : _vm._q(item.isStop, 0),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = item.isStop,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? 0 : 1
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "isStop",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "isStop",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "isStop", $$c)
                                              }
                                            },
                                            function ($event) {
                                              return _vm.updateData(item, true)
                                            },
                                          ],
                                        },
                                      }),
                                      _c("label", {
                                        attrs: { for: "check" + index },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  item.editor && item.editor != 0
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success mr-10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateData(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 儲存 ")]
                                        ),
                                        _vm._v("   "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-default",
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancel(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 取消 ")]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.change(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 修改 ")]
                                        ),
                                        _vm._v("   "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteData(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 刪除 ")]
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ])
                          })
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
          _c("VideoAlert", {
            ref: "videoAlert",
            on: { updateList: _vm.getVideoQueryAll },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { "min-width": "150px" },
          attrs: { width: "25%" },
        },
        [_vm._v("標題")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { "min-width": "150px" },
          attrs: { width: "25%" },
        },
        [_vm._v("影片連結")]
      ),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("發布時間")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("狀態")]),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px", width: "10%" },
        },
        [_vm._v(" 功能 ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }