var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "100px" } },
          [_vm._v(_vm._s(_vm.$t("時間")))]
        ),
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "20%" } },
          [_vm._v(_vm._s(_vm.th[0]))]
        ),
        _c(
          "th",
          { staticClass: "text-center", staticStyle: { width: "20%" } },
          [_vm._v(_vm._s(_vm.th[1]))]
        ),
        _c("th", { staticStyle: { width: "50%" } }, [
          _vm._v(_vm._s(_vm.$t("內容"))),
        ]),
      ]),
    ]),
    _c(
      "tbody",
      [
        _vm._l(_vm.list, function (item, sn) {
          return _c(
            "tr",
            {
              key: item.SendId,
              staticClass: "cursor",
              class: _vm.focusId == item.SendId ? "focus" : "",
              on: {
                click: function ($event) {
                  return _vm.$emit("onFocus", item)
                },
              },
            },
            [
              _c("td", { staticClass: "text-center" }, [
                _c("span", { staticClass: "timer" }, [
                  _vm._v(_vm._s(item.時間)),
                ]),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.MessageSystemEnable,
                      expression: "MessageSystemEnable",
                    },
                  ],
                  staticClass: "fa fa-fw fa-envelope tool",
                  attrs: { title: _vm.$t("密語") },
                  on: {
                    click: function ($event) {
                      return _vm.onSecretBtnClick($event, item)
                    },
                  },
                }),
                _c("i", {
                  staticClass: "fa fa-fw fa-cloud-download tool",
                  attrs: { title: _vm.$t("下載歷程") },
                  on: {
                    click: function ($event) {
                      return _vm.onDownloadBtnClick($event, item)
                    },
                  },
                }),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(item[_vm.td[0]])),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(item[_vm.td[1]])),
              ]),
              _c("td", { attrs: { title: item.Content } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.MsgType == _vm.MsgTypeEnum.Photo,
                      expression: "item.MsgType == MsgTypeEnum.Photo",
                    },
                  ],
                  staticStyle: { "max-width": "50px" },
                  attrs: {
                    src:
                      item.MsgType == _vm.MsgTypeEnum.Photo ? item.Content : "",
                    alt: "",
                  },
                  on: {
                    mouseenter: function ($event) {
                      return _vm.mouseenter($event, item)
                    },
                    mouseout: function ($event) {
                      return _vm.mouseout($event, item)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("popUpImg", item)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.MsgType == _vm.MsgTypeEnum.Voice,
                        expression: "item.MsgType == MsgTypeEnum.Voice",
                      },
                    ],
                    staticClass: "btn control-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("playAudio", item.Content)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("播放音訊")))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.MsgType == _vm.MsgTypeEnum.Sticker,
                        expression: "item.MsgType == MsgTypeEnum.Sticker",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      " <" +
                        _vm._s(_vm.$t("貼圖")) +
                        ">" +
                        _vm._s(item.Content) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.MsgType == _vm.MsgTypeEnum.Text,
                        expression: "item.MsgType == MsgTypeEnum.Text",
                      },
                    ],
                  },
                  [_vm._v(" " + _vm._s(item.Content) + " ")]
                ),
              ]),
            ]
          )
        }),
        _vm.list.length == 0
          ? _c("tr", [
              _c(
                "td",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { colspan: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("無資料")))]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }