var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-BannerPicker" }, [
    this.$slots.default
      ? _c("h3", { staticClass: "text-center" }, [_vm._t("default")], 2)
      : _vm._e(),
    _c("img", {
      staticClass: "banner-img",
      attrs: { src: _vm.edit.mainImgFullFileName, alt: "", draggable: "false" },
    }),
    _c("div", { staticClass: "flex-menu mt-10" }, [
      _c("div", { staticClass: "flex-1" }, [
        _c("input", {
          attrs: { accept: "image/*", type: "file" },
          on: {
            change: function ($event) {
              return _vm.onFileChange($event)
            },
          },
        }),
        _c("div", { staticClass: "help-block" }, [
          _c(
            "span",
            {
              staticClass: "text-red",
              domProps: { textContent: _vm._s(_vm.imgHelp) },
            },
            [_vm._v("圖片尺寸為 1400 * 400px，大小請勿超過 2Mb，選完要儲存！")]
          ),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", { staticClass: "btn btn-primary btn-save" }, [
      _c("i", { staticClass: "fa fa-thumbs-up" }),
      _vm._v(" 儲存更新"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }