var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("序號列表")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("序號管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("序號列表")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box topBox-announceList" }, [
            _c("div", { staticClass: "date-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("使用日期："),
              ]),
              _c("div", { staticClass: "input-group date datetime" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c("DateRangePicker", {
                      attrs: {
                        propTimePicker: true,
                        propEndDate: _vm.searchData.local.usedEndTime,
                        propStartDate: _vm.searchData.local.usedStartTime,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "usedEndTime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "usedEndTime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "usedStartTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.searchData.local,
                            "usedStartTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("活動：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.activityId,
                      expression: "searchData.activityId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "activityId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.onChangeActivity,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.activitys.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.activityId } },
                      [_vm._v(_vm._s(item.activityTitle))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("獎項：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.awardsId,
                      expression: "searchData.awardsId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "awardsId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _vm._l(_vm.awards.datas, function (item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item.awardsId } },
                      [_vm._v(_vm._s(item.awardsTitle))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("使用狀態："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.isUsed,
                      expression: "searchData.isUsed",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "isUsed",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "-1" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("已使用")]),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("未使用")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("待確認")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group sW" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("輸入序號："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.sn,
                    expression: "searchData.sn",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: { placeholder: "搜尋序號", type: "text" },
                domProps: { value: _vm.searchData.sn },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "sn", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "input-group text" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("輸入使用對象："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.uId,
                    expression: "searchData.uId",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: { placeholder: "搜尋使用對象", type: "text" },
                domProps: { value: _vm.searchData.uId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "uId", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.clickSearch()
                    },
                  },
                },
                [_vm._v("查詢")]
              ),
            ]),
            _c("div", { staticClass: "text snmMT" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.total)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-group fr snmMT w100-md mt-15-md" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success w100-md",
                  attrs: { type: "button" },
                  on: { click: _vm.exportOrders },
                },
                [_vm._v("序號匯出")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(1),
                    _vm.loading
                      ? [_vm._m(2)]
                      : _vm.total === 0
                      ? [_vm._m(3)]
                      : _vm._l(_vm.exchangeCodes.datas, function (item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.activityTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.awardsTitle)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.id)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.sn)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.vendorSN)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.FormatTime(
                                    item.awardsStartTime || item.startTime
                                  )
                                )
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.FormatTime(
                                    item.awardsEndTime || item.endTime
                                  )
                                )
                              ),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.FormatTime(item.createTime))),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.uId)),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(_vm.FormatTime(item.usedTime))),
                            ]),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.isUsed == "1"
                                      ? "已使用"
                                      : item.isUsed == "2"
                                      ? "待確認"
                                      : "未使用"
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "box-footer text-center no-border" },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "11%" } }, [
        _vm._v("活動"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("獎項"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "6%" } }, [
        _vm._v("ID"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("序號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("對應序號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("兌獎開始時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("兌獎結束時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("建立日期"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "8%" } }, [
        _vm._v("使用對象"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("使用日期"),
      ]),
      _c(
        "th",
        {
          staticClass: "text-nowrap",
          staticStyle: { width: "5%", "min-width": "60px" },
        },
        [_vm._v("狀態")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }