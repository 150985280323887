var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "exchangeItems", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c("table", { staticClass: "table table-bordered table-striped" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("道具類別名稱")]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.itemTypeId,
                            expression: "edit.itemTypeId",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "itemTypeId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("不分類"),
                        ]),
                        _vm._l(_vm.datas, function (item, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: item.itemTypeId },
                            },
                            [_vm._v(" " + _vm._s(item.itemTypeTitle) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("道具代碼")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.itemCode,
                          expression: "edit.itemCode",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.edit.itemCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "itemCode", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("道具名稱")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.itemTitle,
                          expression: "edit.itemTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { required: "required", type: "text" },
                      domProps: { value: _vm.edit.itemTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "itemTitle", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("道具說明")]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.itemInfo,
                          expression: "edit.itemInfo",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        rows: "4",
                        maxlength: "500",
                        placeholder: "上限500字",
                      },
                      domProps: { value: _vm.edit.itemInfo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "itemInfo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("說明")]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.itemNote,
                          expression: "edit.itemNote",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        rows: "4",
                        maxlength: "500",
                        placeholder: "上限500字",
                      },
                      domProps: { value: _vm.edit.itemNote },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "itemNote", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("狀態")]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "0",
                            message: "上架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", _vm._n($$v))
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "radio radio-inline" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "1",
                            message: "下架",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.edit.isStop,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "isStop", _vm._n($$v))
                            },
                            expression: "edit.isStop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.UpdateExchangeItems()
                },
              },
            },
            [_vm._v("確認")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { "data-dismiss": "modal", type: "button" },
            },
            [_vm._v("取消")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("道具編輯")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }