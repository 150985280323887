var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot", attrs: { id: "ActivityItemControl" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("項目設定")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("遊戲管理")]),
          _c("li", [_vm._v("活動機制")]),
          _c("li", [_vm._v("機制項目")]),
          _c("li", { staticClass: "active" }, [_vm._v("項目設定")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("h4", { attrs: { id: "title" } }, [
              _vm._v(" " + _vm._s(_vm.$route.params.name) + " "),
            ]),
            _c("div", { staticClass: "top-box with-border" }),
            _c(
              "div",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-7" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.流程名稱,
                            expression: "data.流程名稱",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputTitle",
                          required: "required",
                          type: "text",
                          maxlength: "50",
                        },
                        domProps: { value: _vm.data.流程名稱 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.data, "流程名稱", $event.target.value)
                          },
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.data.流程名稱.length) + " / 50"),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-5",
                          attrs: { disabled: !_vm.data.流程名稱 },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("儲存")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning mr-5",
                          attrs: { disabled: !_vm.data.流程名稱 },
                          on: {
                            click: function ($event) {
                              return _vm.review()
                            },
                          },
                        },
                        [_vm._v("預覽")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          on: {
                            click: function ($event) {
                              return _vm.backList()
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mainItem,
                              expression: "mainItem",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { disabled: !_vm.isEdit },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.mainItem = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { disabled: "", value: "" } }, [
                            _vm._v("請選擇項目"),
                          ]),
                          _vm._l(_vm.mainList, function (item, index) {
                            return _c("option", { domProps: { value: item } }, [
                              _vm._v(" " + _vm._s(item.項目名稱) + " "),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm.mainItem != "" && _vm.mainCanChoose()
                        ? _c("i", {
                            staticClass: "fa fa-plus",
                            on: {
                              click: function ($event) {
                                return _vm.check("main")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subItem,
                              expression: "subItem",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: _vm.mainItem == "" || _vm.mainCanChoose(),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.subItem = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("請選擇項目"),
                          ]),
                          _vm._l(_vm.subList, function (item, index) {
                            return _c("option", { domProps: { value: item } }, [
                              _vm._v(" " + _vm._s(item.項目名稱) + " "),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm.subItem != "" && _vm.subCanChoose()
                        ? _c("i", {
                            staticClass: "fa fa-plus",
                            on: {
                              click: function ($event) {
                                return _vm.check("sub")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-sm-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.extendItem,
                              expression: "extendItem",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled:
                              _vm.mainItem == "" ||
                              _vm.mainCanChoose() ||
                              (_vm.subItem != "" && _vm.subCanChoose()),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.extendItem = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("請選擇項目"),
                          ]),
                          _vm._l(_vm.extendList, function (item, index) {
                            return _c("option", { domProps: { value: item } }, [
                              _vm._v(" " + _vm._s(item.項目名稱) + " "),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm.extendItem != "" && _vm.extendCanChoose()
                        ? _c("i", {
                            staticClass: "fa fa-plus",
                            on: {
                              click: function ($event) {
                                return _vm.check("extend")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "col-sm-2" }),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _vm._m(2),
                  _vm.data.項目對應 && !_vm.loading
                    ? _c(
                        "draggable",
                        {
                          attrs: { group: "main" },
                          on: {
                            start: function ($event) {
                              _vm.drag = true
                            },
                            end: function ($event) {
                              _vm.drag = false
                            },
                            sort: function ($event) {
                              return _vm.save()
                            },
                          },
                          model: {
                            value: _vm.data.項目對應,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "項目對應", $$v)
                            },
                            expression: "data.項目對應",
                          },
                        },
                        _vm._l(_vm.data.項目對應, function (main, m_index) {
                          return _c(
                            "div",
                            { staticClass: "flow-list" },
                            [
                              _c("div", { staticClass: "main-flow" }, [
                                _vm.data.項目對應.length > 1
                                  ? _c("i", { staticClass: "icon-tow" })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(main.title) + " "),
                                _c("i", {
                                  staticClass: "fa fa-minus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(
                                        _vm.data.項目對應,
                                        m_index,
                                        "main"
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "child-flow",
                                  attrs: { group: `sub${m_index}` },
                                  on: {
                                    start: function ($event) {
                                      _vm.drag = true
                                    },
                                    end: function ($event) {
                                      _vm.drag = false
                                    },
                                    sort: function ($event) {
                                      return _vm.save()
                                    },
                                  },
                                  model: {
                                    value: main.sub,
                                    callback: function ($$v) {
                                      _vm.$set(main, "sub", $$v)
                                    },
                                    expression: "main.sub",
                                  },
                                },
                                _vm._l(main.sub, function (sub, s_index) {
                                  return _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "sub-flow" }, [
                                        main.sub.length > 1 && sub.title != ""
                                          ? _c("i", { staticClass: "icon-tow" })
                                          : _vm._e(),
                                        _vm._v(" " + _vm._s(sub.title) + " "),
                                        sub.title != ""
                                          ? _c("i", {
                                              staticClass: "fa fa-minus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(
                                                    main.sub,
                                                    s_index,
                                                    "sub"
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                      sub.title == ""
                                        ? _c(
                                            "div",
                                            { staticClass: "child-flow" },
                                            _vm._l(
                                              sub.extend,
                                              function (extend, e_index) {
                                                return _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "extend-flow",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass: "icon-tow",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(extend.title) +
                                                          " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-minus",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              main.sub,
                                                              s_index,
                                                              "extend"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "draggable",
                                            {
                                              staticClass: "child-flow mb-10",
                                              attrs: {
                                                group: `extend${s_index}`,
                                              },
                                              on: {
                                                start: function ($event) {
                                                  _vm.drag = true
                                                },
                                                end: function ($event) {
                                                  _vm.drag = false
                                                },
                                                sort: function ($event) {
                                                  return _vm.save()
                                                },
                                              },
                                              model: {
                                                value: sub.extend,
                                                callback: function ($$v) {
                                                  _vm.$set(sub, "extend", $$v)
                                                },
                                                expression: "sub.extend",
                                              },
                                            },
                                            _vm._l(
                                              sub.extend,
                                              function (extend, e_index) {
                                                return _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "extend-flow",
                                                    },
                                                    [
                                                      sub.extend.length > 1
                                                        ? _c("i", {
                                                            staticClass:
                                                              "icon-tow",
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(extend.title) +
                                                          " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-minus",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              sub.extend,
                                                              e_index,
                                                              "extend"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "review",
          staticClass: "modal fade",
          attrs: { id: "activityContent" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "flow-box" }, [
                  _c(
                    "div",
                    { staticClass: "flow-item" },
                    [
                      _c("ActivityTable", {
                        ref: "activityTable",
                        attrs: {
                          data: _vm.reviewData,
                          isEdit: true,
                          id: _vm.data.遊戲編號,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 流程(系統)名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 項目對應： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flow-header" }, [
      _c("div", { staticClass: "text-nowrap text-center" }, [_vm._v("主項目")]),
      _c("div", { staticClass: "text-nowrap text-center" }, [_vm._v("次項目")]),
      _c("div", { staticClass: "text-nowrap text-center" }, [
        _vm._v("延伸機制或活動"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title text-center" }, [_vm._v("預覽")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }