var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "normal", attrs: { id: "NewtalkAnnouncementDetailList" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("留言與回覆列表")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: "/Newtalk/AnnouncementList" } },
                [_vm._v(" 討論區公告管理 ")]
              ),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("留言與回覆列表")]),
        ]),
      ]),
      _c("section", { staticClass: "content forum-announcement" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 話題列表 ")]
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("開始日期："),
                ]),
                _c(
                  "div",
                  { staticClass: "input-group date" },
                  [
                    _vm._m(0),
                    _c("DateRangePicker", {
                      attrs: {
                        propEndDate: _vm.detailSearchData.endTime,
                        propStartDate: _vm.detailSearchData.startTime,
                      },
                      on: {
                        "update:propEndDate": function ($event) {
                          return _vm.$set(
                            _vm.detailSearchData,
                            "endTime",
                            $event
                          )
                        },
                        "update:prop-end-date": function ($event) {
                          return _vm.$set(
                            _vm.detailSearchData,
                            "endTime",
                            $event
                          )
                        },
                        "update:propStartDate": function ($event) {
                          return _vm.$set(
                            _vm.detailSearchData,
                            "startTime",
                            $event
                          )
                        },
                        "update:prop-start-date": function ($event) {
                          return _vm.$set(
                            _vm.detailSearchData,
                            "startTime",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.detailSearchData.authorUid,
                      expression: "detailSearchData.authorUid",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "發佈者搜尋" },
                  domProps: { value: _vm.detailSearchData.authorUid },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.detailSearchData,
                        "authorUid",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm.detailSearchData.articleType !== 1
                ? _c("div", { staticClass: "select-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.detailSearchData.nickName,
                          expression: "detailSearchData.nickName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "暱稱搜尋" },
                      domProps: { value: _vm.detailSearchData.nickName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.detailSearchData,
                            "nickName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.detailSearchData.contentKey,
                      expression: "detailSearchData.contentKey",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "搜尋關鍵字" },
                  domProps: { value: _vm.detailSearchData.contentKey },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.detailSearchData,
                        "contentKey",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("div", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetDataList()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-search" })]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _c("span", [_vm._v("資料總筆數：")]),
                  _c("span", { attrs: { id: "total" } }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-block btn-danger btn-cog mr-10",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteAll()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" 批次刪除 "),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _vm.detailSearchData.articleType === 3
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticStyle: { "min-width": "50px" } }, [
                              _c("div", { staticClass: "checkbox-inline" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: true,
                                        propChecked: _vm.isCheckAll,
                                      },
                                      on: { "update:checked": _vm.checkAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("ID")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("樓層"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("內容")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("發佈日期")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("發佈者")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "100px" } },
                              [_vm._v("暱稱")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("狀態"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("不喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("回覆"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("功能")]
                            ),
                          ]),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "11" },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "11" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    !item.isDelete
                                      ? _c(
                                          "div",
                                          { staticClass: "checkbox-inline" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _c("iCheckCheckBox", {
                                                  attrs: {
                                                    propValue: item,
                                                    propChecked: item.checked,
                                                  },
                                                  on: {
                                                    "update:checked":
                                                      _vm.checkChange,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(_vm._s(item.commentId))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.commentFloor || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", [
                                    _c("span", {
                                      class: { "text-red": item.isDelete },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.showText(
                                            item,
                                            item.commentContent
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.commentTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.commentAuthor)),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.nickName || "-")),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-nowrap text-center",
                                      class: { "text-red": item.isDelete },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.deleteStatus[
                                              item.isDelete
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(" " + _vm._s(item.likecount) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.notlikecount) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      item.reply_count > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateReply(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.reply_count) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.reply_count) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(
                                              item,
                                              item.commentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 詳細 ")]
                                    ),
                                    !item.isDelete
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.officialRply(item, 3)
                                              },
                                            },
                                          },
                                          [_vm._v(" 回覆 ")]
                                        )
                                      : _vm._e(),
                                    !item.isDelete
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 刪除 ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.detailSearchData.articleType === 4
                ? _c(
                    "table",
                    { staticClass: "table table-hover table-bordered" },
                    [
                      _c(
                        "tbody",
                        [
                          _c("tr", { staticClass: "bg-gray" }, [
                            _c("th", { staticStyle: { "min-width": "50px" } }, [
                              _c("div", { staticClass: "checkbox-inline" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: true,
                                        propChecked: _vm.isCheckAll,
                                      },
                                      on: { "update:checked": _vm.checkAll },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("ID"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "80px" } }, [
                              _vm._v("樓層"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("內容")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("發佈日期")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("發佈者")]
                            ),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "150px" } },
                              [_vm._v("暱稱")]
                            ),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("狀態"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("喜歡"),
                            ]),
                            _c("th", { staticStyle: { "min-width": "60px" } }, [
                              _vm._v("不喜歡"),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { "min-width": "200px" } },
                              [_vm._v("功能")]
                            ),
                          ]),
                          _vm.loading
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_vm._v("查詢中")]
                                ),
                              ])
                            : _vm.datas.length === 0
                            ? _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_vm._v("查詢無任何資料")]
                                ),
                              ])
                            : _vm._l(_vm.datas, function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    !item.isDelete
                                      ? _c(
                                          "div",
                                          { staticClass: "checkbox-inline" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _c("iCheckCheckBox", {
                                                  attrs: {
                                                    propValue: item,
                                                    propChecked: item.checked,
                                                  },
                                                  on: {
                                                    "update:checked":
                                                      _vm.checkChange,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(_vm._s(item.replyId))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.commentFloor) +
                                          "-" +
                                          _vm._s(item.ReplyFloor) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", [
                                    _c("span", {
                                      class: { "text-red": item.isDelete },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.showText(item, item.replyContent)
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              item.replyTime,
                                              "YYYY/MM/DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.author)),
                                  ]),
                                  _c("td", { staticClass: "text-nowrap" }, [
                                    _vm._v(_vm._s(item.nickName || "-")),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-nowrap text-center",
                                      class: { "text-red": item.isDelete },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.define.deleteStatus[
                                              item.isDelete
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [_vm._v(" " + _vm._s(item.likecount) + " ")]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-nowrap text-center" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.notlikecount) + " "
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-center" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showContent(
                                              item,
                                              item.replyId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 詳細 ")]
                                    ),
                                    !item.isDelete
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.officialRply(item, 3)
                                              },
                                            },
                                          },
                                          [_vm._v(" 回覆 ")]
                                        )
                                      : _vm._e(),
                                    !item.isDelete
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger ml-5",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 刪除 ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ])
                              }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "box-footer text-center no-border" },
            [
              _c("Pagination", {
                ref: "pagination",
                attrs: {
                  pageLimitCount: _vm.detailSearchData.pageSize,
                  total: _vm.total,
                },
                on: { pageMethod: _vm.onPageChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("forumContent", {
        ref: "forumContent",
        attrs: { getDataList: _vm.getDataList, page: "announcement" },
      }),
      _c("announcementAlert", {
        ref: "announcementAlert",
        attrs: { actionType: _vm.actionType, checkDatas: _vm.checkDatas },
        on: { updateList: _vm.getDataList },
      }),
      _c("announcementReplyAlert", {
        ref: "announcementReplyAlert",
        attrs: { actionType: _vm.actionType },
        on: { updateList: _vm.getDataList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }