var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormDialog",
        {
          ref: "moderateDialog",
          attrs: {
            title: _vm.player
              ? _vm.$t(_vm.unban ? "玩家解鎖" : "玩家控管")
              : _vm.$t("執行大量停權"),
          },
          on: { submit: _vm.submit },
        },
        [
          _c("div", { staticClass: "table-responsive mt-0" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tbody", [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t((_vm.unban ? "解鎖" : "控管") + "對象"))
                    ),
                  ]),
                  _vm.player
                    ? _c("td", [_vm._v(_vm._s(_vm.data.暱稱))])
                    : _vm._e(),
                ]),
                _vm.player
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("綁定門號")))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.player.綁定門號 ? _vm.player.綁定門號 : "-"
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t((_vm.unban ? "解鎖" : "控管") + "類型"))
                    ),
                  ]),
                  _vm.unban
                    ? _c(
                        "td",
                        [
                          !_vm.unban || (_vm.player && _vm.player.停權狀態 != 0)
                            ? _c("iCheckRedioButton", {
                                staticClass: "radio-inline",
                                attrs: {
                                  checkValue: "0",
                                  message: _vm.$t("停權"),
                                  name: "blockType",
                                },
                                model: {
                                  value: _vm.data.動作,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "動作", _vm._n($$v))
                                  },
                                  expression: "data.動作",
                                },
                              })
                            : _vm._e(),
                          !_vm.unban || (_vm.player && _vm.player.禁言狀態 != 0)
                            ? _c("iCheckRedioButton", {
                                staticClass: "radio-inline",
                                class: { "ml-25": _vm.player.停權狀態 != 0 },
                                attrs: {
                                  checkValue: "1",
                                  message: _vm.$t("禁言"),
                                  name: "blockType",
                                },
                                model: {
                                  value: _vm.data.動作,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "動作", _vm._n($$v))
                                  },
                                  expression: "data.動作",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline",
                            attrs: {
                              checkValue: "0",
                              message: _vm.$t("停權"),
                              name: "blockType",
                            },
                            model: {
                              value: _vm.data.動作,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "動作", _vm._n($$v))
                              },
                              expression: "data.動作",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline ml-25",
                            attrs: {
                              checkValue: "1",
                              message: _vm.$t("禁言"),
                              name: "blockType",
                            },
                            model: {
                              value: _vm.data.動作,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "動作", _vm._n($$v))
                              },
                              expression: "data.動作",
                            },
                          }),
                        ],
                        1
                      ),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t(_vm.unban ? "解鎖" : "期限"))),
                  ]),
                  _vm.unban
                    ? _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline",
                            attrs: {
                              checkValue: true,
                              message: _vm.$t("立即解鎖"),
                              name: "unban",
                            },
                            model: {
                              value: _vm.data.解鎖,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "解鎖", $$v)
                              },
                              expression: "data.解鎖",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("td", [
                        _vm.data.動作 == 0
                          ? _c(
                              "div",
                              { key: 0 },
                              [
                                _c(
                                  "div",
                                  { staticClass: "radio-group" },
                                  [
                                    _c("iCheckRedioButton", {
                                      staticClass: "radio radioCenter",
                                      attrs: {
                                        checkValue: "1",
                                        message: "",
                                        name: "timeType",
                                      },
                                      model: {
                                        value: _vm.data.類型,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "類型",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.類型",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.data.期限,
                                          expression: "data.期限",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "1" },
                                      domProps: { value: _vm.data.期限 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.data,
                                            "期限",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(_vm.$t("天")))]),
                                  ],
                                  1
                                ),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "3",
                                    message: _vm.$t("暫時凍結"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "2",
                                    message: _vm.$t("終止契約"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { key: 1 },
                              [
                                _c(
                                  "div",
                                  { staticClass: "radio-group" },
                                  [
                                    _c("iCheckRedioButton", {
                                      staticClass: "radio radioCenter",
                                      attrs: {
                                        checkValue: "1",
                                        message: "",
                                        name: "timeType",
                                      },
                                      model: {
                                        value: _vm.data.類型,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "類型",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "data.類型",
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.data.期限,
                                          expression: "data.期限",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "1" },
                                      domProps: { value: _vm.data.期限 },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.data,
                                            "期限",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("分鐘"))),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("iCheckRedioButton", {
                                  staticClass: "radio radioCenter",
                                  attrs: {
                                    checkValue: "2",
                                    message: _vm.$t("永久禁言"),
                                    name: "timeType",
                                  },
                                  model: {
                                    value: _vm.data.類型,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "類型", _vm._n($$v))
                                    },
                                    expression: "data.類型",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.分類,
                            expression: "data.分類",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { required: "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data,
                              "分類",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: {
                              value: "",
                              selected: "",
                              disabled: "",
                              hidden: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                        ),
                        _vm._l(_vm.types, function (name, id) {
                          return _c(
                            "option",
                            { key: id, domProps: { value: name } },
                            [_vm._v(_vm._s(name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                !_vm.unban
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("發送簡訊")))]),
                      _c(
                        "td",
                        [
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline",
                            attrs: {
                              checkValue: true,
                              message: _vm.$t("是"),
                              name: "sms",
                            },
                            model: {
                              value: _vm.data.發簡訊,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "發簡訊", $$v)
                              },
                              expression: "data.發簡訊",
                            },
                          }),
                          _c("iCheckRedioButton", {
                            staticClass: "radio-inline ml-25",
                            attrs: {
                              checkValue: false,
                              message: _vm.$t("否"),
                              name: "sms",
                            },
                            model: {
                              value: _vm.data.發簡訊,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "發簡訊", $$v)
                              },
                              expression: "data.發簡訊",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("原因")))]),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.data.原因,
                          expression: "data.原因",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("必填"),
                        rows: "3",
                        required: "",
                      },
                      domProps: { value: _vm.data.原因 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.data, "原因", $event.target.value.trim())
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("ImportData", {
        ref: "ImportData",
        attrs: { title: "玩家", checks: _vm.checks },
        on: { importData: _vm.importData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }