var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot max1040", attrs: { id: "clientGameActivity" } },
    [
      _c("section", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "box box-primary",
            staticStyle: { background: "none" },
          },
          [
            _c("div", { staticClass: "box-body" }, [
              _c(
                "div",
                {
                  staticClass: "g_back",
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [
                  _vm._m(0),
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.query.遊戲名稱) + "遊戲介紹")]),
                    _c("p", [_vm._v(_vm._s(_vm.query.開發商名稱))]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "nav-tabs-custom" }, [
                _c("ul", { staticClass: "nav nav-tabs" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.tab == "event" },
                      on: {
                        click: function ($event) {
                          _vm.tab = "event"
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "javascript:;" } }, [
                        _vm._v("機制項目"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.tab == "normal" },
                      on: {
                        click: function ($event) {
                          _vm.tab = "normal"
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "javascript:;" } }, [
                        _vm._v("常態活動"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.tab == "abnormal" },
                      on: {
                        click: function ($event) {
                          _vm.tab = "abnormal"
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "javascript:;" } }, [
                        _vm._v("非常態活動"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flow-box" },
                [
                  _vm.tab == "event"
                    ? [
                        _vm.flowList.length == 0
                          ? _c("Empty", {
                              attrs: {
                                title: "找不到結果",
                                desc: "請改用其他關鍵字或移除排序條件",
                              },
                            })
                          : _vm._l(_vm.flowList, function (item, m_index) {
                              return _c(
                                "div",
                                { staticClass: "flow-item" },
                                [
                                  _c("div", { staticClass: "flow-title" }, [
                                    _vm._v(_vm._s(item.流程名稱)),
                                  ]),
                                  _c("ActivityTable", {
                                    attrs: {
                                      data: item.項目對應,
                                      id: Number(_vm.$route.params.id),
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                      ]
                    : _vm._e(),
                  _vm.tab == "normal"
                    ? [
                        _c(
                          "div",
                          { staticClass: "table-responsive mt-10" },
                          [
                            _vm.normalList.length == 0
                              ? _c("Empty", {
                                  attrs: {
                                    title: "找不到結果",
                                    desc: "請改用其他關鍵字或移除排序條件",
                                  },
                                })
                              : _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-striped table-bordered table-normal",
                                    attrs: { id: "classTypes" },
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              "background-color": "#FEE94E",
                                            },
                                          },
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-nowrap text-center",
                                                staticStyle: {
                                                  "min-width": "100px",
                                                  width: "30%",
                                                },
                                              },
                                              [_vm._v("時間")]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-nowrap text-center",
                                                staticStyle: {
                                                  "min-width": "150px",
                                                  width: "70%",
                                                },
                                              },
                                              [_vm._v("週期活動")]
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.normalList,
                                          function (item, index) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(_vm._s(item.活動時間)),
                                              ]),
                                              _c("td", {
                                                staticClass: "text-left",
                                                domProps: {
                                                  innerHTML: _vm._s(item.內容),
                                                },
                                              }),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.tab == "abnormal"
                    ? [
                        _c("div", { staticClass: "top-box" }, [
                          _c("div", { staticClass: "input-group mr-10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchData2.活動名稱,
                                  expression: "searchData2.活動名稱",
                                },
                              ],
                              staticClass: "form-control pull-right",
                              attrs: {
                                name: "table_search",
                                placeholder: "活動名稱",
                                type: "text",
                              },
                              domProps: { value: _vm.searchData2.活動名稱 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.searchData2,
                                    "活動名稱",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.searchData2.活動名稱
                              ? _c("i", {
                                  staticClass: "fa fa-close",
                                  on: {
                                    click: function ($event) {
                                      _vm.searchData2.活動名稱 = ""
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "input-group-btn" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetData()
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-search" })]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _c("p", { staticClass: "data-num" }, [
                              _c("span", [_vm._v("資料筆數：")]),
                              _c("span", { attrs: { id: "total" } }, [
                                _vm._v(_vm._s(_vm.abNormalList.length)),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown",
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sort dropdown-toggle",
                                    attrs: {
                                      type: "button",
                                      "data-bs-toggle": "dropdown",
                                      "aria-expanded": "false",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.dropdown =
                                          _vm.dropdown == "none"
                                            ? "block"
                                            : "none"
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "icon-sort" }),
                                    _vm._v(" 排序依據 "),
                                  ]
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "dropdown-menu",
                                    style: { display: `${_vm.dropdown}` },
                                  },
                                  [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          class: [
                                            "dropdown-item",
                                            _vm.searchData2.排序條件 == 2
                                              ? "active"
                                              : "",
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetData(2, 2)
                                            },
                                          },
                                        },
                                        [_vm._v(" 更新時間 ")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          class: [
                                            "dropdown-item",
                                            _vm.searchData2.排序條件 == 3 &&
                                            _vm.searchData2.排序順序 == 1
                                              ? "active"
                                              : "",
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetData(3, 1)
                                            },
                                          },
                                        },
                                        [_vm._v(" A to Z ")]
                                      ),
                                    ]),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          class: [
                                            "dropdown-item",
                                            _vm.searchData2.排序條件 == 3 &&
                                            _vm.searchData2.排序順序 == 2
                                              ? "active"
                                              : "",
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetData(3, 2)
                                            },
                                          },
                                        },
                                        [_vm._v(" Z to A ")]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "table-responsive mt-10" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-striped table-bordered table-normal",
                              attrs: { id: "classTypes" },
                            },
                            [
                              _vm.abNormalList.length == 0
                                ? _c("Empty", {
                                    attrs: {
                                      title: "找不到結果",
                                      desc: "請改用其他關鍵字或移除排序條件",
                                    },
                                  })
                                : _c(
                                    "tbody",
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: {
                                            "background-color": "#FEE94E",
                                          },
                                        },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "text-nowrap text-center",
                                              staticStyle: {
                                                "min-width": "100px",
                                                width: "30%",
                                              },
                                            },
                                            [_vm._v("活動名稱")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "text-nowrap text-center",
                                              staticStyle: {
                                                "min-width": "150px",
                                                width: "70%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.tab == "normal"
                                                      ? "活動說明"
                                                      : "活動簡述"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.abNormalList,
                                        function (item, index) {
                                          return _c("tr", { key: index }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "td-hover",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goContent(
                                                      item.編號
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.活動名稱))]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-left" },
                                              [_vm._v(_vm._s(item.活動說明))]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            ref: "content",
            staticClass: "modal fade",
            attrs: { id: "activityContent" },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _vm._m(1),
                  _vm.tab == "event"
                    ? _c("h4", { staticClass: "modal-title text-center" }, [
                        _vm._v(_vm._s(_vm.detail.項目名稱)),
                      ])
                    : _vm._e(),
                  _vm.tab == "abnormal"
                    ? _c("h4", { staticClass: "modal-title text-center" }, [
                        _vm._v(_vm._s(_vm.data.活動名稱)),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _vm.tab == "event"
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.detail.詳細說明) },
                      })
                    : _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.data.內容) },
                      }),
                  _c("p", { staticClass: "is-bottom" }, [_vm._v("已經到底了")]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c("slotPlayContent", { ref: "slotPlayContent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fa fa-chevron-left" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }