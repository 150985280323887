var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CustomizeEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("簡訊自訂發送新增/編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("簡訊自訂發送")]),
        _c("li", { staticClass: "active" }, [_vm._v("簡訊自訂發送新增/編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("新增/編輯"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_vm._v(" 返回 ")]
            ),
          ]),
          _c("div", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group mt-20 mb-20" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.簡訊商代碼,
                          expression: "data.簡訊商代碼",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.data,
                            "簡訊商代碼",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.sendComList, function (sendCom) {
                      return _c(
                        "option",
                        { domProps: { value: sendCom.簡訊商代碼 } },
                        [_vm._v(" " + _vm._s(sendCom.簡訊商名稱) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              !_vm.isEdit
                ? _c("div", { staticClass: "form-group mt-20 mb-20" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        {
                          staticClass: "radio",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: 0,
                              message: "手動，門號",
                              name: "radioPhone",
                            },
                            model: {
                              value: _vm.isMutilPhone,
                              callback: function ($$v) {
                                _vm.isMutilPhone = $$v
                              },
                              expression: "isMutilPhone",
                            },
                          }),
                          _c("span", { staticClass: "text-red ml-10" }, [
                            _vm._v(
                              " *門號規則如國內：0952123456或886912333444，國外：60703755644 "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mt-10" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phoneNumberInput,
                              expression: "phoneNumberInput",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            rows: "3",
                            placeholder: "請輸入門號，多筆請用逗號(,)或換行",
                          },
                          domProps: { value: _vm.phoneNumberInput },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.phoneNumberInput = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "radio",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: 1,
                              message: "匯入，上傳",
                              name: "radioPhone",
                            },
                            model: {
                              value: _vm.isMutilPhone,
                              callback: function ($$v) {
                                _vm.isMutilPhone = $$v
                              },
                              expression: "isMutilPhone",
                            },
                          }),
                          _c("div", { staticClass: "input-group ml-10" }, [
                            _c("input", {
                              attrs: {
                                accept: ".xls,.xlsx",
                                id: "file",
                                type: "file",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.readExcel($event)
                                },
                              },
                            }),
                          ]),
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                href: "/uploads/多筆發送範例.xlsx",
                                type: "button",
                                download: "",
                              },
                            },
                            [_vm._v(" 多筆發送範例 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-group mt-20 mb-20" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.發送內容,
                        expression: "data.發送內容",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "textArea", rows: "3" },
                    domProps: { value: _vm.data.發送內容 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "發送內容", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-10 mb-10" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        on: {
                          click: function ($event) {
                            return _vm.messageModal()
                          },
                        },
                      },
                      [_vm._v(" 插入罐頭訊息 ")]
                    ),
                  ]),
                  _c("span", { staticClass: "text-red" }, [
                    _vm._v(
                      " * 多筆發送如欲套用品名/序號，請將#NAME#/#CODE#(大寫)放在您欲取代品名/序號的位置。 例如：恭喜您獲得#NAME# 兌換碼：#CODE#。"
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group mt-20 mb-20" }, [
                _vm._m(3),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 0,
                          message: "立即發送",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.data.是否預約,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "是否預約", $$v)
                          },
                          expression: "data.是否預約",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "radio-date" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: 1,
                            message: "預約發送",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.data.是否預約,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "是否預約", $$v)
                            },
                            expression: "data.是否預約",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "input-group date" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _c("DatePicker", {
                            attrs: { propValue: _vm.time },
                            on: {
                              "update:propValue": function ($event) {
                                _vm.time = $event
                              },
                              "update:prop-value": function ($event) {
                                _vm.time = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._m(5),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "btns text-center mt-20",
                  staticStyle: { "margin-top": "60px !important" },
                },
                [
                  _c("label", { staticClass: "col-sm-2 control-label" }),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(" 完成送出 ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "message",
          staticClass: "modal fade",
          attrs: { id: "CustomizeMessage", "data-backdrop": "static" },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(6),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "top-box" }, [
                  _c("div", { staticClass: "select-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchData.classTypeId,
                            expression: "searchData.classTypeId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "selectUnit" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.searchData,
                                "classTypeId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.getMessageList()
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("全部類型"),
                        ]),
                        _vm._l(_vm.categoryList, function (category) {
                          return _c(
                            "option",
                            { domProps: { value: category.classTypeId } },
                            [_vm._v(" " + _vm._s(category.className) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchData.keyword,
                          expression: "searchData.keyword",
                        },
                      ],
                      staticClass: "form-control pull-right",
                      attrs: {
                        name: "table_search",
                        placeholder: "請輸入關鍵字",
                        type: "text",
                      },
                      domProps: { value: _vm.searchData.keyword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchData,
                            "keyword",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm.searchData.keyword.length > 0
                      ? _c("i", {
                          staticClass: "fas fa-times",
                          on: {
                            click: function ($event) {
                              return _vm.cancelKeyword()
                            },
                          },
                        })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "fa fa-search",
                      on: {
                        click: function ($event) {
                          return _vm.getMessageList()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "message-list mt-20" }, [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.messageList.length == 0,
                              expression: "messageList.length == 0",
                            },
                          ],
                          staticClass: "text-center",
                        },
                        [_vm._v("沒有資料")]
                      ),
                      _vm._l(_vm.messageList, function (item) {
                        return [
                          item.isStop == 0
                            ? _c(
                                "li",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addMessage(item.detailTopTitle)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.detailTopTitle)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c("div", { staticClass: "modal-footer text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { "data-dismiss": "modal", type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.cancelMessage()
                      },
                    },
                  },
                  [_vm._v("關閉")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發送簡訊商： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 輸入門號： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 簡訊內容： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發送： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group time" }, [
      _c("div", { staticClass: "input-group-addon" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
      ]),
      _c("input", {
        staticClass: "form-control timepicker",
        attrs: { type: "text" },
      }),
      _c("div", { staticClass: "timepicker-tips" }, [
        _c("div", { staticClass: "text-red" }, [
          _vm._v("*00:00/24:00 請選擇AM12:00"),
        ]),
        _c("div", { staticClass: "text-red" }, [
          _vm._v("*12:00 請選擇PM12:00"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title text-center" }, [
        _vm._v("罐頭訊息"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }