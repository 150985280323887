var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "add_mute", staticClass: "modal fade", attrs: { id: "addMute" } },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("form", { staticClass: "form-horizontal" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.muteId,
                        expression: "muteId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputMuteId",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.muteId },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.muteId = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.muteDay,
                        expression: "muteDay",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputMuteDay",
                      min: "1",
                      placeholder: "輸入天數",
                      required: "required",
                      type: "number",
                    },
                    domProps: { value: _vm.muteDay },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.muteDay = $event.target.value
                      },
                    },
                  }),
                  _c("p", { staticClass: "text-red mt-10" }, [
                    _vm._v("* 不輸入或天數大於3650天，為永久禁言。"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "inputMuteNote" },
                  },
                  [_vm._v("備註：")]
                ),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.muteNote,
                        expression: "muteNote",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "inputMuteNote", type: "text" },
                    domProps: { value: _vm.muteNote },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.muteNote = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submitMute()
                  },
                },
              },
              [_vm._v("新增")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default pull-right",
                attrs: { "data-dismiss": "modal", type: "button" },
              },
              [_vm._v("關閉")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            "aria-label": "Close",
            "data-dismiss": "modal",
            type: "button",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("新增禁言")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputMuteId" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 門號： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "inputMuteDay" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 禁言時間： "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }