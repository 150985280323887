var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav navbar-nav" },
    [
      _vm.MessageSystemEnable
        ? [
            _c(
              "li",
              {
                staticClass: "dropdown messages-menu",
                style: {
                  display: _vm.ServerIsOnline != "Connected" ? "none" : "",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "50px",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _vm.IsServiceVIP
                      ? _c(
                          "div",
                          {
                            staticClass: "dropdown-toggle dispach dispach-open",
                            staticStyle: { display: "flex" },
                            attrs: { "data-toggle": "dropdown" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "8px",
                                  "padding-right": "5px",
                                },
                              },
                              [_vm._v("●")]
                            ),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("VIP自動派發中"))),
                            ]),
                          ]
                        )
                      : _vm.autoDispatch
                      ? _c(
                          "div",
                          {
                            staticClass: "dropdown-toggle dispach dispach-open",
                            staticStyle: { display: "flex" },
                            attrs: { "data-toggle": "dropdown" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "8px",
                                  "padding-right": "5px",
                                },
                              },
                              [_vm._v("●")]
                            ),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("自動派發中"))),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "dropdown-toggle dispach dispach-close",
                            staticStyle: { display: "flex" },
                            attrs: { "data-toggle": "dropdown" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "8px",
                                  "padding-right": "5px",
                                },
                              },
                              [_vm._v("●")]
                            ),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("關閉派發"))),
                            ]),
                          ]
                        ),
                    _c(
                      "ul",
                      {
                        staticClass: "dropdown-menu",
                        staticStyle: {
                          "background-color": "transparent",
                          top: "70%",
                          right: "-8px",
                          left: "auto",
                          border: "none",
                        },
                      },
                      [
                        _c("div", { staticClass: "mwt_border" }, [
                          _c("span", { staticClass: "arrow_t_int" }),
                          _c("span", { staticClass: "arrow_t_out" }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "white-space": "nowrap",
                                color: "white",
                              },
                            },
                            [
                              _c("table", { staticClass: "tool-layout" }, [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("允許派發")) + "："),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ckbx-style-8 ckbx toggleInput",
                                        on: {
                                          click: function ($event) {
                                            return _vm.updAutoDispatch($event)
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.autoDispatch,
                                              expression: "autoDispatch",
                                            },
                                          ],
                                          attrs: {
                                            "false-value": false,
                                            "true-value": true,
                                            name: "ckbx-style-8",
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.autoDispatch
                                            )
                                              ? _vm._i(_vm.autoDispatch, null) >
                                                -1
                                              : _vm.autoDispatch,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.autoDispatch,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.autoDispatch =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.autoDispatch = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.autoDispatch = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("label", {
                                          class: _vm.IsServiceVIP
                                            ? "disabled"
                                            : "",
                                          attrs: { for: "check" },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.MessageSystemVIPService,
                                        expression: "MessageSystemVIPService",
                                      },
                                    ],
                                    staticStyle: { "padding-top": "10px" },
                                  },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.$t("VIP客服")) + "："),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ckbx-style-8 ckbx toggleInput",
                                          on: {
                                            click: function ($event) {
                                              return _vm.updServiceVIP($event)
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.IsServiceVIP,
                                                expression: "IsServiceVIP",
                                              },
                                            ],
                                            attrs: {
                                              "false-value": false,
                                              "true-value": true,
                                              name: "ckbx-style-8",
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.IsServiceVIP
                                              )
                                                ? _vm._i(
                                                    _vm.IsServiceVIP,
                                                    null
                                                  ) > -1
                                                : _vm.IsServiceVIP,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.IsServiceVIP,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.IsServiceVIP =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.IsServiceVIP = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.IsServiceVIP = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c("label", {
                                            class: _vm.autoDispatch
                                              ? "disabled"
                                              : "",
                                            attrs: { for: "check" },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm.ChannelManagerEnable
        ? [
            _c(
              "li",
              { staticClass: "dropdown messages-menu" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-toggle",
                    staticStyle: { height: "50px" },
                    attrs: {
                      to: "/ChannelMonitor/ChannelManager",
                      title: _vm.$t("頻道管理"),
                    },
                  },
                  [_c("i", { staticClass: "fa fa-television" })]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.MessageSystemEnable
        ? [
            _c("li", { staticClass: "dropdown messages-menu" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  staticStyle: { height: "50px" },
                  attrs: { href: "#", title: _vm.$t("密語") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.updChatForm(_vm.MessageForm.Secret)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-envelope" })]
              ),
            ]),
            _c("li", { staticClass: "dropdown messages-menu" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-toggle",
                  staticStyle: { height: "50px" },
                  attrs: { href: "#", title: _vm.$t("訊息系統") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.updChatForm(_vm.MessageForm.Chat)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-comments" }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.我的未回數 > 0,
                          expression: "我的未回數 > 0",
                        },
                      ],
                      staticClass: "unRead-div",
                    },
                    [
                      _c("span", { staticClass: "unRead color_red" }, [
                        _vm._v(" " + _vm._s(_vm.我的未回數)),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "li",
              { staticClass: "dropdown messages-menu" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-toggle",
                    staticStyle: { height: "50px" },
                    attrs: {
                      to: "/MessageSystem/GroupSystem",
                      title: _vm.$t("客服群組"),
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-group" }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.客服未回數 > 0,
                            expression: "客服未回數 > 0",
                          },
                        ],
                        staticClass: "unRead-div",
                      },
                      [
                        _c("span", { staticClass: "unRead color_blue" }, [
                          _vm._v(" " + _vm._s(_vm.客服未回數)),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }