var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormDialog",
    {
      ref: "memberHonorDialog",
      attrs: {
        title:
          _vm.$t(_vm.isAddHonor ? "添加" : "回收") + " " + _vm.$t("榮譽值"),
      },
      on: { submit: _vm.submit },
    },
    [
      _c("div", { staticClass: "table-responsive mt-0" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("tbody", [
            _vm.isAddHonor !== null
              ? _c("tr", [
                  _c("th", [
                    _c(
                      "span",
                      { class: _vm.isAddHonor ? "text-green" : "text-red" },
                      [_vm._v(_vm._s(_vm.$t(_vm.isAddHonor ? "增加" : "扣除")))]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("榮譽值"))),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.data.榮譽值,
                          expression: "data.榮譽值",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        min: "0",
                        max:
                          _vm.isAddHonor == false ? _vm.player.榮譽值 : false,
                      },
                      domProps: { value: _vm.data.榮譽值 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data,
                            "榮譽值",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("服務類型")))]),
              _c("td", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.分類,
                        expression: "data.分類",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { required: "" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "分類",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: {
                          value: "",
                          selected: "",
                          disabled: "",
                          hidden: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("請選擇類別")))]
                    ),
                    _vm._l(_vm.types, function (name, id) {
                      return _c(
                        "option",
                        { key: id, domProps: { value: name } },
                        [_vm._v(_vm._s(name))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("內容")))]),
              _c("td", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.內容,
                      expression: "data.內容",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { min: "1", rows: "5", required: "" },
                  domProps: { value: _vm.data.內容 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "內容", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }