var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("景點編輯")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("在地饗宴")]),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/heti/Attractions" } }, [
              _vm._v("周邊景點"),
            ]),
          ],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("景點編輯")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("編輯內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.bannerTitle,
                          expression: "edit.bannerTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                      },
                      domProps: { value: _vm.edit.bannerTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "bannerTitle", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group image-part" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        attrs: { draggable: "false", src: _vm.edit.filesUrl },
                      }),
                    ]),
                    _c("input", {
                      attrs: { accept: "image/*", id: "AdPic", type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event)
                        },
                      },
                    }),
                    _c("div", { staticClass: "text-red" }, [
                      _vm._v("建議尺寸1024*768"),
                    ]),
                  ]),
                ]),
                _vm.projectId != "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "上架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "下架",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.isStop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "isStop", $$v)
                                },
                                expression: "edit.isStop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 圖片名稱： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "AdPic" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v("圖片： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 發佈： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }