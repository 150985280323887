var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "AnnouncementList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("活動歷程")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("活動歷程")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box topBox-announceList" }, [
            _c(
              "div",
              { staticClass: "select-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("開始時間："),
                ]),
                _c("DatePicker", {
                  attrs: { propValue: _vm.searchData.開始時間 },
                  on: {
                    "update:propValue": function ($event) {
                      return _vm.$set(_vm.searchData, "開始時間", $event)
                    },
                    "update:prop-value": function ($event) {
                      return _vm.$set(_vm.searchData, "開始時間", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "select-group" },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("結束時間："),
                ]),
                _c("DatePicker", {
                  attrs: { propValue: _vm.searchData.結束時間 },
                  on: {
                    "update:propValue": function ($event) {
                      return _vm.$set(_vm.searchData, "結束時間", $event)
                    },
                    "update:prop-value": function ($event) {
                      return _vm.$set(_vm.searchData, "結束時間", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("活動類型："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.event_type,
                      expression: "searchData.event_type",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "event_type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("新創")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("每日報到")]),
                  _c("option", { attrs: { value: "3-5" } }, [
                    _vm._v("連續登入五天"),
                  ]),
                  _c("option", { attrs: { value: "3-10" } }, [
                    _vm._v("連續登入十天"),
                  ]),
                  _c("option", { attrs: { value: "3-15" } }, [
                    _vm._v("連續登入十五天"),
                  ]),
                  _c("option", { attrs: { value: "3-20" } }, [
                    _vm._v("連續登入二十天"),
                  ]),
                  _c("option", { attrs: { value: "3-28" } }, [
                    _vm._v("連續登入二十八天"),
                  ]),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("盟友")]),
                  _c("option", { attrs: { value: "5" } }, [_vm._v("i禮贈")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [_vm._v("獎項：")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.award_type,
                      expression: "searchData.award_type",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "award_type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("銘謝惠顧")]),
                  _c("option", { attrs: { value: "2-1" } }, [
                    _vm._v("(綁定)超級米勒藥水選擇箱"),
                  ]),
                  _c("option", { attrs: { value: "2-2" } }, [
                    _vm._v("(綁定)掉落率卷軸 20%"),
                  ]),
                  _c("option", { attrs: { value: "2-3" } }, [
                    _vm._v("(綁定)全能聖水 7日"),
                  ]),
                  _c("option", { attrs: { value: "2-4" } }, [
                    _vm._v("(綁定)戰場的符文 7日兌換箱"),
                  ]),
                  _c("option", { attrs: { value: "3-1" } }, [
                    _vm._v("特色保溫瓶"),
                  ]),
                  _c("option", { attrs: { value: "3-2" } }, [
                    _vm._v("造型小夜燈"),
                  ]),
                  _c("option", { attrs: { value: "3-3" } }, [
                    _vm._v("精美海報"),
                  ]),
                  _c("option", { attrs: { value: "3-4" } }, [
                    _vm._v("周邊小物組"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("寶箱領取狀態："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.get_award_status,
                      expression: "searchData.get_award_status",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "get_award_status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("未領取")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("已領取")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("獎項配發狀態："),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.allot_status,
                      expression: "searchData.allot_status",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "allot_status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.clickSearch,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("全部")]),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("未配發")]),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("已配發")]),
                ]
              ),
            ]),
            _c("div", { staticClass: "input-group sW" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("輸入MoloId："),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.moloId,
                    expression: "searchData.moloId",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋MoloId",
                  type: "text",
                },
                domProps: { value: _vm.searchData.moloId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "moloId", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn verAlign-bottom" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clickSearch()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text snmMT" }, [
              _c("p", { staticClass: "data-num" }, [
                (_vm.searchShow = 1)
                  ? _c("span", [_vm._v("資料總筆數：")])
                  : _vm._e(),
                _vm.searchShow != 1
                  ? _c("span", [_vm._v("搜尋結果筆數：")])
                  : _vm._e(),
                _c("span", { attrs: { id: "total" } }, [
                  _vm._v(_vm._s(_vm.activityRecord.total)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-group fr snmMT w100-md mt-15-md" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success w100-md",
                  attrs: { type: "button" },
                  on: { click: _vm.exportOrders },
                },
                [_vm._v("匯出")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              {
                staticClass:
                  "table table-hover table-bordered min-800 AdListTable",
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm.activityRecord.total > 0
                      ? _vm._l(
                          _vm.activityRecord.datas,
                          function (item, index) {
                            return _c("tr", { key: item.id }, [
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.event_id)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  _vm._s(_vm.GetEventType(item.event_type))
                                ),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.molo_id)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  _vm._s(_vm.GetStatus(item.get_award_status))
                                ),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.award_name)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.award_code)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  _vm._s(_vm.GetStatus(item.allot_status))
                                ),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(_vm._s(item.get_chest_time)),
                              ]),
                              _c("td", { attrs: { align: "center" } }, [
                                _vm._v(
                                  _vm._s(_vm.FormatTime(item.create_time))
                                ),
                              ]),
                            ])
                          }
                        )
                      : [_vm._m(1)],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activityRecord.total > 0,
                expression: "activityRecord.total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.activityRecord.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "bg-gray" }, [
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "5%" } }, [
        _vm._v("活動編號"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("活動類型"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "10%" } }, [
        _vm._v("molo id"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("是否寶箱領取"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("獎項名稱"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("獎項序號"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("是否獎項配發"),
      ]),
      _c("th", { staticStyle: { "min-width": "90px", width: "8%" } }, [
        _vm._v("領取寶箱時間"),
      ]),
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("新建時間"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("沒有資料"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }