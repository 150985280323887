var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-editorTable" }, [
    _c("div", { staticClass: "top-box with-border" }, [
      _vm.isEdit
        ? _c("h4", { staticClass: "text text-title" }, [_vm._v("編輯內容")])
        : _c("h4", { staticClass: "text text-title" }, [_vm._v("新增內容")]),
    ]),
    _c("form", { staticClass: "form-horizontal" }, [
      _c(
        "div",
        { staticClass: "box-body" },
        _vm._l(_vm.editorLayout, function (layout, row) {
          return _c("div", { key: row, staticClass: "form-group" }, [
            _c(
              "label",
              {
                staticClass: "col-sm-2 control-label text-nowrap",
                attrs: { for: layout.name },
              },
              [
                _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                _vm._v(" " + _vm._s(layout.name) + "： "),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-sm-10" },
              _vm._l(layout.fields, function (field, col) {
                return _c(
                  "div",
                  { key: col },
                  [
                    field.type == "text"
                      ? [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit[field.content],
                                expression: "edit[field.content]",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: layout.name,
                              type: "text",
                              required: "required",
                            },
                            domProps: { value: _vm.edit[field.content] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.edit,
                                  field.content,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      : field.type == "select"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.edit[field.content],
                                expression: "edit[field.content]",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { name: "", id: layout.name },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.edit,
                                  field.content,
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "",
                                  disabled: "disabled",
                                  selected: "selected",
                                  hidden: "",
                                },
                              },
                              [_vm._v("請選擇" + _vm._s(layout.name))]
                            ),
                            _vm._l(
                              _vm.bannerTypes.datas,
                              function (item, index) {
                                return _c("option", {
                                  key: index,
                                  domProps: {
                                    value: item.classTypeId,
                                    textContent: _vm._s(item.className),
                                  },
                                })
                              }
                            ),
                          ],
                          2
                        )
                      : field.type == "file"
                      ? [
                          _c("div", { staticClass: "pic" }, [
                            _c("img", {
                              attrs: {
                                draggable: "false",
                                src: _vm.edit.filesUrl,
                              },
                            }),
                          ]),
                          _c("input", {
                            attrs: {
                              accept: "image/*",
                              id: layout.name,
                              type: "file",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onFileChange($event)
                              },
                            },
                          }),
                        ]
                      : field.type == "checkbox"
                      ? _c("div", { staticClass: "checkbox-list" }, [
                          _c("label", { staticStyle: { padding: "0" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit[field.content],
                                  expression: "edit[field.content]",
                                },
                              ],
                              staticClass: "contactUs-checkbox",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.edit[field.content])
                                  ? _vm._i(_vm.edit[field.content], null) > -1
                                  : _vm.edit[field.content],
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.edit[field.content],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.edit,
                                          field.content,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.edit,
                                          field.content,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.edit, field.content, $$c)
                                  }
                                },
                              },
                            }),
                            _vm._m(0, true),
                            _vm._v(" "),
                            _vm._v(" " + _vm._s(field.label) + " "),
                          ]),
                        ])
                      : field.type == "radio"
                      ? _c(
                          "div",
                          { staticClass: "radio-list" },
                          _vm._l(
                            field.value.split(":"),
                            function (item, index) {
                              return _c("label", { key: index }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.edit[field.content],
                                      expression: "edit[field.content]",
                                    },
                                  ],
                                  staticClass: "contactUs-radio",
                                  attrs: {
                                    type: "radio",
                                    name: `Radio${row}-${col}`,
                                  },
                                  domProps: {
                                    value: item.trim(),
                                    checked: _vm._q(
                                      _vm.edit[field.content],
                                      item.trim()
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.$set(
                                        _vm.edit,
                                        field.content,
                                        item.trim()
                                      )
                                    },
                                  },
                                }),
                                _c("span"),
                                _vm._v(" "),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      field.label.split(":")[index].trim()
                                    ) +
                                    " "
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : field.type == "help"
                      ? _c("div", {
                          staticClass: "help-block text-red",
                          domProps: { textContent: _vm._s(field.value) },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "box-footer" }, [
        _c("div", { staticClass: "btns" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger btn-lg",
              attrs: { type: "button" },
              on: { click: _vm.ResetData },
            },
            [_vm._v("取消")]
          ),
          _vm._v("   "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-lg",
              attrs: { type: "button" },
              on: { click: _vm.Save },
            },
            [_vm._v("確認送出")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa fa-check" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }