var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "g-img",
        on: {
          click: function ($event) {
            return _vm.goContent(_vm.item)
          },
        },
      },
      [_c("img", { attrs: { src: _vm.item.封面, alt: "" } })]
    ),
    _c(
      "div",
      {
        staticClass: "g-name mt-5 mb-10",
        on: {
          click: function ($event) {
            return _vm.goContent(_vm.item)
          },
        },
      },
      [_vm._v(_vm._s(_vm.item.文章標題))]
    ),
    _c(
      "div",
      {
        staticClass: "g-detail",
        on: {
          click: function ($event) {
            return _vm.goContent(_vm.item)
          },
        },
      },
      [_vm._v(_vm._s(_vm.item.文章摘要))]
    ),
    _c(
      "div",
      {
        staticClass: "g-link",
        on: {
          click: function ($event) {
            return _vm.goApp(_vm.item.遊戲編號)
          },
        },
      },
      [
        _c("div", { staticClass: "c-img mr-10" }, [
          _c("img", { attrs: { src: _vm.item.圖示, alt: "" } }),
        ]),
        _c("div", [
          _c("p", { staticClass: "c-name" }, [
            _vm._v(_vm._s(_vm.item.遊戲名稱)),
          ]),
          _c("p", { staticClass: "g-time" }, [
            _vm._v(
              _vm._s(_vm._f("dateTimeFormat")(_vm.item.發佈時間)) + "發佈"
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }