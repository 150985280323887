var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "popForm", staticClass: "modal fade" }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c(
        "div",
        { staticClass: "modal-content", staticStyle: { width: "900px" } },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  "aria-label": "Close",
                  "data-dismiss": "modal",
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    return _vm.hide()
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.$t("詳細內容"))),
            ]),
          ]),
          _c("div", { staticClass: "box-body" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "30%",
                  display: "inline-block",
                  float: "left",
                },
              },
              [
                _c(
                  "table",
                  { staticStyle: { margin: "0 auto", width: "100%" } },
                  [
                    _vm._l(_vm.edit, function (item, key) {
                      return _c("tr", { key: key }, [
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            staticStyle: {
                              "padding-right": "10px",
                              width: "80px",
                            },
                          },
                          [_c("label", [_vm._v(_vm._s(_vm.$t(key)))])]
                        ),
                        _c("td", [_vm._v(_vm._s(item))]),
                      ])
                    }),
                    _c("tr", [
                      _c("td", { attrs: { colspan: "2", align: "right" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.exportData()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("匯出")))]
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              { staticStyle: { width: "70%", display: "inline-block" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "box box-warning direct-chat direct-chat-warning",
                  },
                  [
                    _c("ChatMessageForm", {
                      ref: "chatMessageForm",
                      on: { LoadNextToken: _vm.LoadNextToken },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }