var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "normal", attrs: { id: "MftdContactUsEdit" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("聯繫客服資訊")]),
        _c(
          "ol",
          { staticClass: "breadcrumb" },
          [
            _c(
              "li",
              [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
              1
            ),
            _c("li", [_vm._v("聯繫客服列表")]),
            _c("router-link", { attrs: { to: "/MFTD/ContactUsList" } }, [
              _vm._v("聯繫客服列表"),
            ]),
            _c("li", { staticClass: "active" }, [_vm._v("聯繫客服資訊")]),
          ],
          1
        ),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-primary" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "form-horizontal",
                attrs: { name: "announcementForm" },
              },
              [
                _c("div", { staticClass: "box-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.name) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.companyName) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.email) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.phoneNumber) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(4),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.data.subject) + " ")]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("div", { staticClass: "contactNote" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.note,
                                expression: "data.note",
                              },
                            ],
                            staticStyle: { width: "100%", height: "200px" },
                            attrs: { disabled: "" },
                            domProps: { value: _vm.data.note },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.data, "note", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._l(_vm.data.files, function (file, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: {
                                  target: "_blank",
                                  href: file.filesUrl,
                                },
                              },
                              [_vm._v(" " + _vm._s(file.name) + " ")]
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("div", { staticClass: "col-sm-2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.data.status,
                              expression: "data.status",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.$set(
                                _vm.data,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("待處理"),
                          ]),
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("已處理"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "box-footer" }, [
                  _c("div", { staticClass: "btns" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.saveData()
                          },
                        },
                      },
                      [_vm._v(" 確認送出 ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 角色名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 玩家ID： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 聯絡Email： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label text-nowrap" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 聯絡電話： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 主旨： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "contactNote" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 內容： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態： "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }