var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "PaymentOwedEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("購點欠費紀錄" + _vm._s(_vm.isEdit ? "修改" : "新增"))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("購點欠費紀錄")]),
        _c("li", { staticClass: "active" }, [
          _vm._v("購點欠費紀錄" + _vm._s(_vm.isEdit ? "修改" : "新增")),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-primary" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v("內容"),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.backList()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-reply" }), _vm._v(" 返回列表 ")]
            ),
          ]),
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { name: "announcementForm" },
            },
            [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.服務編號,
                            expression: "edit.服務編號",
                          },
                        ],
                        staticClass: "form-control select-part",
                        attrs: { disabled: _vm.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "服務編號",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇服務")]
                        ),
                        _vm._l(_vm.serviceOptions, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.訂單編號,
                          expression: "edit.訂單編號",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "inputTitle",
                        required: "required",
                        type: "text",
                        disabled: _vm.isEdit,
                      },
                      domProps: { value: _vm.edit.訂單編號 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "訂單編號", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.isEdit
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.儲值角色,
                              expression: "edit.儲值角色",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "nickName",
                            required: "required",
                            type: "text",
                            disabled: _vm.isEdit,
                          },
                          domProps: { value: _vm.edit.儲值角色 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.edit,
                                "儲值角色",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.edit.交易方式,
                            expression: "edit.交易方式",
                          },
                        ],
                        staticClass: "form-control select-part",
                        attrs: { disabled: _vm.isEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.edit,
                              "交易方式",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "disabled", value: "" } },
                          [_vm._v("請選擇交易方式")]
                        ),
                        _c("option", { attrs: { value: "IOS" } }, [
                          _vm._v("IOS"),
                        ]),
                        _c("option", { attrs: { value: "Android" } }, [
                          _vm._v("Android"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.isEdit
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.edit.金額,
                              expression: "edit.金額",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "amount",
                            required: "required",
                            type: "number",
                            min: "0",
                            disabled: _vm.isEdit,
                          },
                          domProps: { value: _vm.edit.金額 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.edit, "金額", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.projectId != "omp_fantacity"
                  ? _c("div", { staticClass: "form-group" }, [
                      _vm._m(5),
                      _c("div", { staticClass: "col-sm-10" }, [
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "否",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.是否繳款,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "是否繳款", $$v)
                                },
                                expression: "edit.是否繳款",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "是",
                                name: "radioMode",
                              },
                              model: {
                                value: _vm.edit.是否繳款,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "是否繳款", $$v)
                                },
                                expression: "edit.是否繳款",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm._m(6),
                    _c("DateTimePicker", {
                      attrs: { propDateTime: _vm.edit.退款時間 },
                      on: {
                        "update:propDateTime": function ($event) {
                          return _vm.$set(_vm.edit, "退款時間", $event)
                        },
                        "update:prop-date-time": function ($event) {
                          return _vm.$set(_vm.edit, "退款時間", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm._m(7),
                    _c("DateTimePicker", {
                      attrs: { propDateTime: _vm.edit.凍結日期 },
                      on: {
                        "update:propDateTime": function ($event) {
                          return _vm.$set(_vm.edit, "凍結日期", $event)
                        },
                        "update:prop-date-time": function ($event) {
                          return _vm.$set(_vm.edit, "凍結日期", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm._m(8),
                    _c("DateTimePicker", {
                      attrs: { propDateTime: _vm.edit.繳費時間 },
                      on: {
                        "update:propDateTime": function ($event) {
                          return _vm.$set(_vm.edit, "繳費時間", $event)
                        },
                        "update:prop-date-time": function ($event) {
                          return _vm.$set(_vm.edit, "繳費時間", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group bannercontent-part" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.edit.備註,
                          expression: "edit.備註",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "bannercontent",
                        required: "required",
                        placeholder: "",
                      },
                      domProps: { value: _vm.edit.備註 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.edit, "備註", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "box-footer" }, [
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.Save },
                    },
                    [_vm._v(" 確認送出 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 服務名稱： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "inputTitle" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 訂單編號： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "nickName" } },
      [_c("span", { staticClass: "text-red" }), _vm._v(" 儲值角色： ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 交易方式： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-2 control-label", attrs: { for: "amount" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 金額： "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 是否繳款： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 退款時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 凍結日期： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-2 control-label" }, [
      _c("span", { staticClass: "text-red" }),
      _vm._v(" 繳費時間： "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label",
        attrs: { for: "bannercontent" },
      },
      [_c("span", { staticClass: "text-red" }), _vm._v(" 備註： ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }